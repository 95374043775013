import i18next from 'i18next';
import React from 'react';

// Commons
import Text, { ENUM_FONTS, ENUM_COLORS } from '@commons/Text';

import { Container } from './styledComponents';

const UpgradePlanOverlay = (props) => {
  const {
    title = i18next.t('ADMIN.STORES.UPGRADE_PLAN_TITLE'),
    subTitle = i18next.t('ADMIN.STORES.UPGRADE_PLAN_SUBTITLE'),
    marginTop = '0px',
  } = props;

  return (
    <Container marginTop={marginTop} onClick={(event) => event.stopPropagation()}>
      <img alt="lock-icon" src={'/images/inpulse/lock-yellow.svg'} />
      <Text font={ENUM_FONTS.H2}>{title}</Text>
      <Text color={ENUM_COLORS.DARKER}>{subTitle}</Text>
    </Container>
  );
};

export default UpgradePlanOverlay;
