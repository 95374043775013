import i18next from 'i18next';
import parse from 'html-react-parser';
import React from 'react';

import { formatTextNumberInputValue } from '@commons/utils/format';
import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_SAVE_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';

import InputContentModal from '@commons/Modals/InputContentModal';

export const getBufferStockModalConfig = ({
  handleBufferStockModalSave,
  modalNbDays,
  setModalNbDays,
  selectedIngredients,
  prevHorizon,
}) => ({
  type: 'action',
  width: '542px',
  height: 'auto',
  icon: '/images/inpulse/gear-ipblack-small.svg',
  title: i18next.t('ADMIN.STORES.MANAGE_BUFFER_STOCKS_ACTIONS'),
  component: () => (
    <InputContentModal
      autoFocus={true}
      errorMessage={
        modalNbDays > prevHorizon.future &&
        parse(
          i18next.t('ADMIN.INGREDIENTS.BUFFER_STOCK_NB_DAYS_HIGHER_THAN_PREV_HORIZON_ERROR', {
            prevHorizon: prevHorizon.future,
          }),
        )
      }
      label={i18next.t('FEATURE_NAME_ADMIN_STORES_BUFFER_STOCKS')}
      placeholder={i18next.t('FEATURE_NAME_ADMIN_STORES_BUFFER_STOCKS')}
      tooltipText={i18next.t('ADMIN.STORES.BUFFER_STOCK_COLUMN_TOOLTIP')}
      type="text"
      value={modalNbDays === null ? '' : modalNbDays}
      onChange={(value) => {
        const formattedValue = formatTextNumberInputValue(value, null);

        setModalNbDays(
          formattedValue !== null && formattedValue >= 0 ? Math.trunc(formattedValue) : null,
        );
      }}
    />
  ),
  handleCloseCleanUp: () => setModalNbDays(null),
  actions: [
    GENERIC_MODAL_CANCEL_BUTTON(),
    {
      ...GENERIC_MODAL_SAVE_BUTTON(),
      isDisabled:
        modalNbDays === null ||
        modalNbDays === undefined ||
        modalNbDays < 0 ||
        modalNbDays > prevHorizon.future,
      handleClick: () => handleBufferStockModalSave(selectedIngredients),
    },
  ],
});
