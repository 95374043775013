import i18next from 'i18next';
import moment from 'moment-timezone';
import React from 'react';

import { ACCOUNT_STATUS } from '@backoffice/BackOfficeUsersContainer/BackOfficeTeamsManagement/utils/status';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';

import { InpulseLabel } from '@commons/DeepsightComponents';

import { LabelContainer, Text } from '../styledComponents';

const renderText = (item) => <Text>{item}</Text>;

export const renderStatus = (status, row) => {
  let color = 'black';
  let tooltipContent = '';

  switch (status) {
    case 'BACKOFFICE.TEAM.ONBOARDING_STATUS_INVITED':
      color = 'orange';
      tooltipContent = i18next.t('BACKOFFICE.TEAM.ONBOARDING_INVITED_AT', {
        date: moment(row.invitationLinkSentDate).format(
          DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR,
        ),
      });
      break;
    case 'BACKOFFICE.TEAM.ONBOARDING_STATUS_INVITATION_EXPIRED':
      color = 'orange';
      tooltipContent = i18next.t('BACKOFFICE.TEAM.ONBOARDING_EXPIRED_AT', {
        date: moment(row.invitationLinkExpiredDate).format(
          DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR,
        ),
      });
      break;
    case 'BACKOFFICE.TEAM.ONBOARDING_STATUS_REGISTERED':
      color = 'green';
      tooltipContent = i18next.t('BACKOFFICE.TEAM.ONBOARDING_REGISTERED_AT', {
        date: moment(row.registrationDate).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR),
      });
      break;
    default:
      color = 'black';
      tooltipContent = i18next.t('BACKOFFICE.TEAM.ONBOARDING_CREATED_AT', {
        date: moment(row.creationDate).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR),
      });
  }

  return (
    <LabelContainer>
      <InpulseLabel
        color={color}
        text={!!status && i18next.t(status)}
        tooltipContent={tooltipContent}
      />
    </LabelContainer>
  );
};

export const getColumns = () => {
  const columns = [
    {
      id: 'name',
      propertyKey: 'name',
      name: i18next.t('ADMIN.STORES.LIST_COLUMN_NAME'),
      displayName: i18next.t('ADMIN.STORES.LIST_COLUMN_NAME'),
      render: (item) => <Text highlighted>{item}</Text>,
    },
    {
      id: 'email',
      propertyKey: 'email',
      name: i18next.t('ADMIN.STORES.LIST_COLUMN_EMAIL'),
      displayName: i18next.t('ADMIN.STORES.LIST_COLUMN_EMAIL'),
      render: (item) => renderText(item),
    },
    {
      id: 'role',
      propertyKey: 'role',
      name: i18next.t('ADMIN.STORES.LIST_COLUMN_ROLE'),
      displayName: i18next.t('ADMIN.STORES.LIST_COLUMN_ROLE'),
      filterType: 'string',
      excludeFromSearch: true,
      render: (item) => renderText(item),
    },
    {
      id: 'status',
      propertyKey: 'status',
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_STATUS'),
      excludeFromSearch: true,
      tooltipText: i18next.t('BACKOFFICE.TEAM.STATUS_COLUMN_TOOLTIP'),
      filterType: 'string',
      list: Object.values(ACCOUNT_STATUS).reduce((result, status, key) => {
        result.push({
          id: key,
          name: i18next.t(status),
          status: status,
          itemValue: status,
        });

        return result;
      }, []),
      render: (status, row) => renderStatus(status, row),
    },
    {
      name: i18next.t('GENERAL.CREATION_DATE'),
      hidden: true,
      propertyKey: 'creationDate',
      transform: (value) =>
        !!value ? moment(value).format(DATE_DISPLAY_FORMATS.DASHED_DAY_MONTH_YEAR) : '',
    },
    {
      name: i18next.t('USERS.DETAILS.INVITATION_DATE'),
      hidden: true,
      propertyKey: 'invitationLinkSentDate',
      transform: (value) =>
        !!value ? moment(value).format(DATE_DISPLAY_FORMATS.DASHED_DAY_MONTH_YEAR) : '',
    },
    {
      name: i18next.t('USERS.DETAILS.REGISTRATION_DATE'),
      hidden: true,
      propertyKey: 'registrationDate',
      transform: (value) =>
        !!value ? moment(value).format(DATE_DISPLAY_FORMATS.DASHED_DAY_MONTH_YEAR) : '',
    },
  ];

  return columns;
};

export default { getColumns, renderStatus };
