import { connect } from 'react-redux';
import _ from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { showConfirmationMessage } from '@actions/messageconfirmation';

import { Button, Checkbox, SearchBar } from '@commons/utils/styledLibraryComponents';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import EmptyState from '@commons/EmptyState';

import { getClientInfo } from '@selectors/client';

import { user as userService } from '@services/user';
import storeService from '@services/store';

import {
  Row,
  Line,
  Content,
  Container,
  ContentList,
  TopContainer,
  ContentHeader,
  SearchContainer,
  BottomContainer,
  LineHeader,
  RowHeader,
} from './styledComponents';

/**
 * Handle the save of the stores association with the user defined by the given account id
 *
 * @param {String} accountId        - The id of the account information to retrieve
 * @param {Store[]} storesUser      - The list of all the stores already associated to the user
 * @param {Store[]} selectionStores - The list of all the stores available for association
 * @param {Function} setStores      - The method to set the actual stores associated to the user on main page
 * @param {Function} pageLoading    - The method to display the loader and set the page as loading
 * @param {Function} pageLoading    - The method to not display the loader and set the page as loaded
 * @param {Function} showMessage    - The method to display message relating to the request status
 * @param {Function} closeModal     - The method to close the opened modal
 *
 * @returns {void}
 */
export async function handleSave(
  accountId,
  storesUser,
  selectionStores,
  setStores,
  pageLoading,
  pageLoaded,
  showMessage,
  closeModal,
  translatedStoreName,
) {
  pageLoading();

  try {
    const selectedStores = selectionStores.filter((store) => store.selected);
    const selectedStoreIds = selectedStores.map((store) => store.id);

    await userService.associateStoresToAccount(accountId, selectedStoreIds);

    showMessage(i18next.t('USERS.DETAILS.STORES_ASSOCIATE_SUCCESS'));

    const newStoresList = selectedStores.map((store) => {
      store.selected = false;

      return store;
    });

    const orderedNewStoresUser = _.orderBy(storesUser.concat(newStoresList), 'name');

    setStores(orderedNewStoresUser);

    closeModal();
  } catch (error) {
    showMessage(
      i18next.t('USERS.DETAILS.STORES_ASSOCIATE_FAILURE', {
        storeName: translatedStoreName,
      }),
      'error',
    );

    return {};
  } finally {
    pageLoaded();
  }
}

/**
 * Handle the stores selection by the user
 *
 * @param {Store[]} itemsToDisplay      - The actual list of stores being displayed
 * @param {Store[]} list                - The list of all the stores available for associated
 * @param {Function} setSelectionStores - The method to set the selected stores on the modal
 * @param {String} selectedId           - The id of the store selected by the user
 *
 * @returns {void}
 */
export function handleListSelection(itemsToDisplay, list, setSelectionStores, selectedId) {
  if (!selectedId) {
    const areAllSelected = itemsToDisplay.every((item) => item.selected);

    const updatedList = list.map((item) => {
      if (!itemsToDisplay.some(({ id }) => id === item.id)) {
        return item;
      }

      item.selected = areAllSelected ? false : true;

      return item;
    });

    setSelectionStores(updatedList);

    return;
  }

  const updatedList = list.map((item) => {
    if (item.id === selectedId) {
      item.selected = !item.selected;
    }

    return item;
  });

  setSelectionStores(updatedList);
}

export function handleSearchAndFilterLogic(list, headers, setSelectionStores, searchInput) {
  const updatedList = list.map((item) => {
    item.hidden = !headers.some((header) => {
      if (!item[header.baseName] || typeof item[header.baseName] !== 'string') {
        return false;
      }

      return item[header.baseName].toLowerCase().includes(searchInput.toLowerCase());
    });

    return item;
  });

  setSelectionStores(updatedList);
}

export function keyPress(e, list, headers, setSelectionStores, searchInput) {
  if (e.keyCode === 13) {
    handleSearchAndFilterLogic(list, headers, setSelectionStores, searchInput);
  }
}

/**
 * Handle the fetch of the stores available for the given client id
 *
 * @param {Function} pageLoading  - The method to display the loader and set the page as loading
 * @param {Function} pageLoading  - The method to not display the loader and set the page as loaded
 * @param {Function} showMessage  - The method to display message relating to the request status
 *
 * @returns {Store[]} The list of formatted stores associated to the given client id
 */
export async function fetchStoresOfClient(
  pageLoading,
  pageLoaded,
  showMessage,
  translatedStoreName,
) {
  pageLoading();

  try {
    const stores = await storeService.getStoresOfClient();

    const formattedStores = stores.map((store) => ({
      ..._.pick(store, ['id', 'name', 'city', 'country', 'active', 'mappedGroups']),
      brand: _.get(store, 'lnkBrandStorerel.name'),
      groups: store.mappedGroups,
      groupNames: !store.mappedGroups ? [] : store.mappedGroups.map(({ name }) => name),
    }));

    const activeStores = formattedStores.filter(({ active }) => active);

    return activeStores;
  } catch (error) {
    showMessage(
      i18next.t('GENERAL.STORES_CLIENT_FETCH_FAILURE', {
        storeName: translatedStoreName,
      }),
      'error',
    );

    return {};
  } finally {
    pageLoaded();
  }
}

export function renderContent(
  list,
  headers,
  setSelectionStores,
  searchInput,
  setSearchInput,
  storeName,
) {
  const itemsToDisplay = list.filter((item) => !item.hidden);

  const translatedStoreName = getClientStoreNameTranslation(storeName, itemsToDisplay.length > 1);

  return (
    <Content>
      <SearchContainer>
        <SearchBar
          placeholder={`${itemsToDisplay.length} ${translatedStoreName.toLowerCase()}`}
          setValue={(value) => setSearchInput(value)}
          value={searchInput}
        />
      </SearchContainer>
      <ContentHeader>
        <LineHeader className="header">
          <div style={{ paddingLeft: 3 }}>
            <Checkbox
              handleClick={() => {
                handleListSelection(itemsToDisplay, list, setSelectionStores);
              }}
              isChecked={!!itemsToDisplay.length && itemsToDisplay.every((item) => item.selected)}
              shape="square"
            />
          </div>

          {headers.map((header, indexHeader) => (
            <RowHeader key={`${indexHeader}`}>{header.displayName}</RowHeader>
          ))}
        </LineHeader>
      </ContentHeader>
      <ContentList>
        {!itemsToDisplay.length && <EmptyState />}
        {itemsToDisplay.map((item, indexList) => (
          <Line key={`${indexList}`}>
            <Checkbox
              handleClick={() => {
                handleListSelection(itemsToDisplay, list, setSelectionStores, item.id);
              }}
              isChecked={item.selected}
              shape="square"
            />

            {headers.map((header, indexHeader) => (
              <Row key={`${indexList}-${indexHeader}`}>
                {header.renderItem(item[header.baseName])}
              </Row>
            ))}
          </Line>
        ))}
      </ContentList>
    </Content>
  );
}

const StoreAssociationModal = (props) => {
  const {
    client: { storeName },
    pageLoading,
    pageLoaded,
    showMessage,
    closeModal,
    params: { accountId, storesUser, headers, setStores },
  } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [searchInput, setSearchInput] = useState('');
  const [selectionStores, setSelectionStores] = useState([]);

  useEffect(() => {
    pageLoading();
    setIsLoading(true);

    (async function loadData() {
      const storesClient = await fetchStoresOfClient(
        pageLoading,
        pageLoaded,
        showMessage,
        getClientStoreNameTranslation(storeName, true).toLowerCase(),
      );

      const storesNotAssociatedToUser = storesClient.map((store) => {
        const item = { ...store };

        if (storesUser.some((storeUser) => storeUser.id === store.id)) {
          item.selected = true;
        }

        return item;
      });

      const unselectedStores = storesNotAssociatedToUser.filter(
        (item) => !storesUser.some((storeUser) => storeUser.id === item.id),
      );

      setSelectionStores(_.orderBy(unselectedStores, 'name'));

      pageLoaded();

      setIsLoading(false);
    })();
  }, [storesUser]);

  useEffect(() => {
    handleSearchAndFilterLogic(selectionStores, headers, setSelectionStores, searchInput);
  }, [searchInput]);

  return (
    <Container>
      <TopContainer>
        {i18next.t('USERS.DETAILS.STORES_LIST_ASSOCIATION_MODAL_TITLE', {
          storeName: getClientStoreNameTranslation(storeName, true).toLowerCase(),
        })}
      </TopContainer>
      {!isLoading &&
        renderContent(
          selectionStores,
          headers,
          setSelectionStores,
          searchInput,
          setSearchInput,
          storeName,
        )}
      {!isLoading && (
        <BottomContainer>
          <Button
            color={'blue-outline'}
            handleClick={() => closeModal()}
            icon={'/images/inpulse/close-black-small.svg'}
            label={i18next.t('GENERAL.CANCEL')}
          />
          <Button
            color={'blue'}
            handleClick={() =>
              handleSave(
                accountId,
                storesUser,
                selectionStores,
                setStores,
                pageLoading,
                pageLoaded,
                showMessage,
                closeModal,
                getClientStoreNameTranslation(storeName, true).toLowerCase(),
              )
            }
            icon={'/images/inpulse/link-white-small.svg'}
            isDisabled={!selectionStores.length || !selectionStores.some((store) => store.selected)}
            label={i18next.t('GENERAL.ASSOCIATE')}
          />
        </BottomContainer>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreAssociationModal);
