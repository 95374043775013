import mixpanelProvider from '../../providers/mixpanel';

export const ENUM_EVENTS = {
  USER_CHANGED_PAGE: 'User Changed Page',
  SET_ORDER_STATUS_TO_SENT_WITHOUT_SENDING_IT: 'Set order status to sent without sending it”',
  VALIDATE_FORECAST: 'Validate forecast',
  SEND_ORDER: 'Send order',
  PREFILL_ORDER_FORM: 'Prefill order form',
  HELP_CENTER: 'User clicked on Help Center',
  REFERRAL: 'User clicked on Referral',
  UPDATE_SUPPLIER_PRODUCT_PRICE_FROM_ORDER_RECEIPT:
    'Update supplierProduct price from order receipt',
  DOWNLOAD_INVENTORY_SHEET: 'User download inventory sheet',
  OPEN_AUDIT_LOG_SIDE_PANEL: 'User opened audit log side panel',
};

export const sendMetric = (event, properties = {}) => {
  const mixPanel = mixpanelProvider.getInstance();

  mixPanel.track(event, properties);
};

export default {
  sendMetric,
  ENUM_EVENTS,
};
