import i18next from 'i18next';
import React from 'react';

import { Dropdown, INPUT_WIDTH } from '@commons/utils/styledLibraryComponents';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

import theme from '@theme';

import { CompositionDropdownContainer } from '../../styledComponents';
import {
  DropdownIcon,
  DropdownItem,
  DropdownItemMainValue,
  DropdownItemSecondaryValue,
  DropdownTitle,
} from '@admin/suppliers/supplierProducts/details/supplierProductInformations/components/SupplierProductDetails/styledComponents';

const DEFAULT_RECIPE_PICTURE = '/images/inpulse/recipe-photo-placeholder.svg';

const RecipeDropdown = (props) => {
  const {
    handleEntityChange,
    recipes,
    isReadOnly,
    composition,
    isLoading,
    errorMessage = null,
  } = props;

  const renderedRecipes = recipes.map((recipe) => ({
    ...recipe,
    value: recipe.name,
    renderValue: () => (
      <DropdownItem style={{ marginRight: '32px' }}>
        <DropdownIcon src={recipe.img || DEFAULT_RECIPE_PICTURE} />
        <DropdownTitle>
          <DropdownItemMainValue>{recipe.name}</DropdownItemMainValue>
          <DropdownItemSecondaryValue isDisabled={recipe.isDisabled}>
            {recipe.category}
          </DropdownItemSecondaryValue>
        </DropdownTitle>
      </DropdownItem>
    ),
  }));

  return (
    <CompositionDropdownContainer>
      <Dropdown
        customStyle={{ height: 'auto' }}
        isDisabled={isReadOnly || isLoading}
        isErrorState={!!errorMessage}
        items={renderedRecipes}
        label={i18next.t('GENERAL.RECIPE')}
        labelStyle={{ font: theme.fonts.textSmall }}
        placeholder={i18next.t('GENERAL.SEARCH')}
        selectedItem={composition}
        tooltipText={i18next.t('ADMIN.PRODUCTS.COMPOSITION_TOOLTIP_RECIPE')}
        isRequired
        onSelectionChange={handleEntityChange}
      />
      {errorMessage && (
        <Text
          color={ENUM_COLORS.INFO_RED}
          font={ENUM_FONTS.TEXT_SMALL_HEIGHT_14_WEIGHT_600}
          style={{ width: INPUT_WIDTH.LARGE }}
        >
          {errorMessage}
        </Text>
      )}
    </CompositionDropdownContainer>
  );
};

export default RecipeDropdown;
