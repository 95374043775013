import { connect } from 'react-redux';
import { sortBy, get, isEmpty } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useState, useEffect } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { openGenericModal, refreshGenericModal, closeGenericModal } from '@actions/modal';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { getFactorToConvertMetricToEntityUnit, convertUnits } from '@commons/utils/conversion';
import { getUserTimezone } from '@commons/utils/date';
import { NestedList, Dropdown, Button } from '@commons/utils/styledLibraryComponents';
import { PeriodDatePicker } from '@commons/DatePickers/PeriodDatePicker';
import DisplayNumber from '@commons/DisplayNumber';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

import { usePaginatorState } from '@hooks/usePaginatorState';
import { usePeriodDatePickerState } from '@hooks/usePeriodDatePickerState';

import { getClientInfo } from '@selectors/client';

import { echService } from '@services/entityCostHistory';
import { ingredient as ingredientService } from '@services/ingredient';

import { getButtonActions } from './utils/getButtonActions';
import { getColumns } from './utils/getColumns';
import { getRestartCalculationsModal } from './utils/getRestartCalculationsModal/index';

import {
  Container,
  Header,
  HeaderInputs,
  TheoriticalBoxContainer,
  TheoriticalWithLinkContainer,
  OpenInNew,
  NestedListContainer,
} from './styledComponents';

const BackOfficeIngredientCost = (props) => {
  const {
    activeStores,
    pageLoading,
    pageLoaded,
    showSuccessMessage,
    showErrorMessage,
    openGenericModal,
    refreshGenericModal,
    user,
    client: { clientId, storeName },
    match: { path },
    currency,
    modal,
    closeGenericModal,
  } = props;

  const today = moment();
  const translatedStoreName = getClientStoreNameTranslation(storeName);
  const pluralTranslatedStoreName = getClientStoreNameTranslation(storeName, true);
  const columns = getColumns(translatedStoreName);
  const userLanguageCode = get(user, 'lnkLanguageAccountrel.code', 'fr');
  const userTimezone = getUserTimezone();

  const paginatorState = usePaginatorState();

  const periodPickerState = usePeriodDatePickerState(
    moment().subtract(1, 'month'),
    today,
    userTimezone,
  );

  const [isLoading, setIsLoading] = useState(false);

  const [selectedStores, setSelectedStores] = useState(
    activeStores.length ? [activeStores[0]] : [],
  );

  const [ingredients, setIngredients] = useState([]);
  const [selectedIngredient, setSelectedIngredient] = useState({});

  const [entityCostHistories, setEntityCostHistories] = useState([]);

  const [buttonActions, setButtonActions] = useState([]);

  // Modal states
  const modalPeriodPickerState = usePeriodDatePickerState(
    moment().subtract(1, 'month'),
    today,
    userTimezone,
  );
  const [selectedModalStores, setSelectedModalStores] = useState(
    activeStores.length ? [activeStores[0]] : [],
  );
  const [selectedModalIngredients, setSelectedModalIngredients] = useState([]);
  const [isExportModalButtonDisabled, setIsExportModalButtonDisabled] = useState(false);

  const getIngredients = async () => {
    setIsLoading(true);
    pageLoading();

    try {
      const fetchedIngredients = await ingredientService.getIngredients(false);
      const activeIngredients = fetchedIngredients.filter(({ active }) => active);
      const sortedIngredients = sortBy(activeIngredients, ({ name }) => name.toLowerCase());

      const currentlySelectedIngredient = sortedIngredients.length ? sortedIngredients[0] : {};

      setIngredients(sortedIngredients);
      setSelectedIngredient(currentlySelectedIngredient);
      setSelectedModalIngredients([currentlySelectedIngredient]);
    } catch {
      showErrorMessage(i18next.t('ADMIN.INGREDIENTS.FETCH_ERROR'));
    } finally {
      pageLoaded();
      setIsLoading(false);
    }
  };

  const getEchs = async () => {
    setIsLoading(true);
    pageLoading();

    try {
      const storeIds = selectedStores.map(({ id }) => id);

      const fetchedEch = await echService.getIngredientEntityCostHistories(
        selectedIngredient.id,
        storeIds,
        moment(periodPickerState.startDate).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        moment(periodPickerState.endDate).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
      );

      const formattedECH = fetchedEch.map((ech) => ({
        ...ech,
        cost: ech.cost * getFactorToConvertMetricToEntityUnit(ech.unit),
        unit: convertUnits(ech.unit),
      }));

      setEntityCostHistories(formattedECH);
    } catch {
      showErrorMessage(i18next.t('BACKOFFICE.PRODUCTS.ENTITY_COST_HISTORY_FETCH_ERROR'));
    } finally {
      pageLoaded();
      setIsLoading(false);
    }
  };

  const handleCloseCleanUp = () => {
    setSelectedModalStores(selectedStores);
    setSelectedModalIngredients([selectedIngredient]);
    modalPeriodPickerState.handleSelectedDates({
      startDate: periodPickerState.startDate,
      endDate: periodPickerState.endDate,
    });

    closeGenericModal();
  };

  const handleCostReCalculation = async () => {
    pageLoading();
    setIsLoading(true);

    try {
      const storeIds = selectedModalStores.map(({ id }) => id);
      const ingredientIds = selectedModalIngredients.map(({ id }) => id);

      await echService.fixEntityCostHistoryForPeriod(
        clientId,
        moment(modalPeriodPickerState.startDate).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        moment(modalPeriodPickerState.endDate).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        storeIds,
        ingredientIds,
      );

      handleCloseCleanUp();

      showSuccessMessage(
        i18next.t('BACKOFFICE.PRODUCTS.RESTART_HISTORICIZED_CALCULATIONS_SUCCESS'),
      );
    } catch {
      showErrorMessage(i18next.t('BACKOFFICE.PRODUCTS.RESTART_HISTORICIZED_CALCULATIONS_ERROR'));
    } finally {
      pageLoaded();
      setIsLoading(false);
    }
  };

  const openRestartCalculationModal = () => {
    const params = getRestartCalculationsModal({
      stores: activeStores,
      selectedModalStores,
      ingredients,
      selectedModalIngredients,
      modalPeriodPickerState,
      userTimezone,
      translatedStoreName,
      setSelectedModalStores,
      setSelectedModalIngredients,
      isExportModalButtonDisabled,
      setIsExportModalButtonDisabled,
      handleCloseCleanUp,
      handleCostReCalculation,
    });

    openGenericModal(params);
  };

  useEffect(() => {
    const isModalOpened = get(modal, 'GenericModalBool', false);

    if (!isModalOpened) {
      return;
    }

    const params = getRestartCalculationsModal({
      stores: activeStores,
      selectedModalStores,
      ingredients,
      selectedModalIngredients,
      modalPeriodPickerState,
      userTimezone,
      translatedStoreName,
      setSelectedModalStores,
      setSelectedModalIngredients,
      isExportModalButtonDisabled,
      setIsExportModalButtonDisabled,
      handleCloseCleanUp,
      handleCostReCalculation,
    });

    refreshGenericModal(params);
  }, [isExportModalButtonDisabled]);

  useEffect(() => {
    if (
      !selectedStores.length ||
      isEmpty(selectedIngredient) ||
      !periodPickerState.startDate ||
      !periodPickerState.endDate
    ) {
      return;
    }

    getEchs();
  }, [selectedStores, selectedIngredient, periodPickerState.startDate, periodPickerState.endDate]);

  useEffect(() => {
    setButtonActions(
      getButtonActions(
        columns,
        entityCostHistories,
        currency,
        periodPickerState.startDate,
        periodPickerState.endDate,
        selectedStores,
        selectedIngredient,
        pluralTranslatedStoreName,
        openRestartCalculationModal,
      ),
    );
  }, [entityCostHistories]);

  useEffect(() => {
    getIngredients();
  }, []);

  return (
    <Container>
      <NavigationBreadCrumb featurePath={path} />
      <Header>
        <HeaderInputs>
          <Dropdown
            iconSrc={'/images/inpulse/store-black-small.svg'}
            isUniqueSelection={false}
            items={activeStores}
            placeholder={translatedStoreName}
            selectedItems={selectedStores}
            isRequired
            onSelectionChange={(stores) => {
              setSelectedStores(stores);
              setSelectedModalStores(stores);
            }}
          />
          <Dropdown
            items={ingredients}
            placeholder={i18next.t('GENERAL.INGREDIENT')}
            selectedItem={selectedIngredient}
            isRequired
            onSelectionChange={(ingredient) => {
              setSelectedIngredient(ingredient);
              setSelectedModalIngredients([ingredient]);
            }}
          />
          <PeriodDatePicker
            disabled={isLoading}
            endDate={periodPickerState.endDate}
            focusedDateInput={periodPickerState.focusedDateInput}
            maxFutureDate={today}
            setFocusedDateInput={periodPickerState.setFocusedDateInput}
            startDate={periodPickerState.startDate}
            timezone={userTimezone}
            onDatesChange={(selectedDates) => {
              periodPickerState.handleSelectedDates(selectedDates);
              modalPeriodPickerState.handleSelectedDates(selectedDates);
            }}
          />
          <TheoriticalBoxContainer>
            <TheoriticalWithLinkContainer>
              <Text color={ENUM_COLORS.DARK} font={ENUM_FONTS.TEXT_SMALL_HEIGHT_16}>{`${i18next.t(
                'BACKOFFICE.PRODUCTS.THEORITICAL_COST',
              )} :`}</Text>
              <OpenInNew
                src={'/images/inpulse/icon-open-in-new-black.svg'}
                onClick={() =>
                  window.open(`/admin/products/ingredients/${selectedIngredient.id}/details`)
                }
              />
            </TheoriticalWithLinkContainer>
            <DisplayNumber
              font={ENUM_FONTS.TEXT_SMALL_WEIGHT_700_HEIGHT_16_BOLD}
              number={
                get(selectedIngredient, 'cost', 0) *
                getFactorToConvertMetricToEntityUnit(selectedIngredient.unit)
              }
              displayCurrencyCode
            />
          </TheoriticalBoxContainer>
        </HeaderInputs>
        <Button
          actions={buttonActions}
          color={'inpulse-outline'}
          iconDropdown={'/images/inpulse/carret-black.svg'}
          iconOnLeft={false}
          label={i18next.t('ADMIN.RECIPES.ITEM_LIST_BUTTONS_ACTIONS')}
        />
      </Header>
      <NestedListContainer>
        <NestedList
          currentPage={paginatorState.currentPage}
          data={entityCostHistories}
          defaultOrderBy={'storeName'}
          defaultOrderType={'asc'}
          headers={columns}
          iconEmptyState={'/images/inpulse/empty-state-no-result.svg'}
          iconSizeEmptyState="160px"
          isExpandable={false}
          isLoading={isLoading}
          labelEmptyState={i18next.t('BACKOFFICE.PRODUCTS.NO_DATA_FOR_GIVEN_PARAMETERS')}
          languageCode={userLanguageCode}
          maxPerPage={paginatorState.maxPerPage}
          maxPerPageOptions={paginatorState.maxPerPageOptions}
          minWidth={'978px'}
          setCurrentPage={paginatorState.setCurrentPage}
          setMaxPerPage={paginatorState.setMaxPerPage}
          hasPagination
        />
      </NestedListContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
  activeStores: state.baseReducer.activeStores,
  user: state.baseReducer.user,
  currency: state.baseReducer.currency,
  modal: state.modalReducer,
});

const mapDispatchToProps = (dispatch) => ({
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
  closeGenericModal: (params) => {
    dispatch(closeGenericModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeIngredientCost);
