import { capitalize, get, pick } from 'lodash';
import { connect } from 'react-redux';
import { useCryptr } from '@cryptr/cryptr-react';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { Action, Container, Content, Section } from './styledComponents';

import Breadcrumb from '../../../commons/Breadcrumb';

import { loading, loadingSuccess } from '../../../actions/loading';
import { logout } from '../../../actions/logout';
import { showConfirmationMessage } from '../../../actions/messageconfirmation';
import { user as userService } from '../../../services/user';
import catalogService from '@services/catalogs';
import clientService from '../../../services/client';

import ProfileBottomBar from './components/ProfileBottomBar';
import ProfileInfoSection from './components/ProfileInfoSection';
import ProfilePassword from './components/ProfilePassword';
import ProfilePictureSection from './components/ProfilePictureSection';

import { removeLocalStorageItem } from '../../../commons/utils/localStorage';

import { getClientInfo } from '../../../selectors/client';
import { getDisableEdition, getDisableResetPassword } from '../../../selectors/featureProps';
import NavigationBar from '../../../commons/NavigationBar';

const EMPTY_STATE_USER = { lastName: '', firstName: '', email: '', img: '' };

export function renderContent({
  isCurrentUserProfile,
  profileUser,
  setProfileUser,
  clientRoles,
  isDeepsightUser,
  languages,
  catalogs,
  shouldDisableEdition,
  shouldDisableResetPassword,
  shouldDisableProfilePictureUpdate,
}) {
  return (
    <Content isCurrentUserProfile={isCurrentUserProfile}>
      <Section>
        <ProfileInfoSection
          catalogs={catalogs}
          clientRoles={clientRoles}
          isCurrentUserProfile={isCurrentUserProfile}
          isDeepsightUser={isDeepsightUser}
          isDisabled={shouldDisableEdition}
          languages={languages}
          profileUser={profileUser}
          setProfileUser={setProfileUser}
        />

        <ProfilePictureSection
          isDisabled={shouldDisableProfilePictureUpdate}
          profileUser={profileUser}
          setProfileUser={setProfileUser}
        />
      </Section>

      {!shouldDisableResetPassword && (
        <Section>
          <ProfilePassword isCurrentUserProfile={isCurrentUserProfile} profileUser={profileUser} />
        </Section>
      )}
    </Content>
  );
}

export function renderTopBar(props) {
  const { user, profileUser, storeName, isCurrentUserProfile, path, handleLogout } = props;

  // Retrieve info connected user with cryptr SDK
  const cryptrHook = useCryptr();

  const logoutUser = async () => {
    const isAuthenticated = cryptrHook.isAuthenticated();

    if (!isAuthenticated) {
      return handleLogout();
    }

    // Make sure user is logged out that when sso logout flow redirects to app
    removeLocalStorageItem('loopbackAccessToken');
    removeLocalStorageItem('userId');
    removeLocalStorageItem('isCentralMode');

    // Redirect to SSO logout flow
    cryptrHook.logOut(() => {
      handleLogout();
    });
  };

  return isCurrentUserProfile ? (
    <Breadcrumb
      action={
        <Action onClick={() => logoutUser()}>{i18next.t('USERS.DETAILS.NAV_BAR_LOGOUT')}</Action>
      }
      paths={[
        i18next.t('USERS.DETAILS.NAV_BAR_ACCOUNT'),
        `${capitalize(profileUser.firstName)} ${capitalize(profileUser.lastName)}`,
      ]}
    />
  ) : (
    <NavigationBar path={path} profileUser={profileUser} storeName={storeName} user={user} />
  );
}

export async function fetchProfileInfo(accountId, isCurrentUserProfile, history, showMessage) {
  try {
    const account = await userService.getAccountById(accountId);

    const formattedAccount = {
      ...pick(account, ['id', 'img', 'email', 'firstName', 'lastName', 'catalogId']),
      roleName: get(account, 'lnkAccountRoleAccountrel.name'),
      roleId: get(account, 'lnkAccountRoleAccountrel.id'),
      languageCode: get(account, 'lnkLanguageAccountrel.code', 'fr'),
      languageName: get(account, 'lnkLanguageAccountrel.name', 'Français'),
      languageId: get(account, 'lnkLanguageAccountrel.id'),
    };

    return formattedAccount;
  } catch (error) {
    if (get(error, 'response.status') === 403 && !isCurrentUserProfile) {
      return history.push(`/users/`);
    }

    showMessage(i18next.t('USERS.DETAILS.FETCH_PROFILE_INFO_ERROR'), 'error');

    return null;
  }
}

export async function fetchClientRoles() {
  try {
    const clientRoles = await clientService.getRolesByClientId();

    return clientRoles;
  } catch (error) {
    return [];
  }
}

const fetchCatalogsOfClient = () => {
  try {
    return catalogService.getCatalogsByClientId();
  } catch {
    return [];
  }
};

export async function fetchAllLanguages(showMessage) {
  try {
    const languages = await clientService.getAllLanguages();

    return languages;
  } catch (error) {
    showMessage(i18next.t('USERS.DETAILS.FETCH_CLIENT_LANGUAGES_ERROR'), 'error');

    return [];
  }
}

export const ProfileDetail = (props) => {
  const {
    user,
    client: { cryptrDomain, storeName, hasLocalCatalogs },
    history,
    shouldDisableEdition,
    shouldDisableResetPassword,
    showMessage,
    profileLoaded,
    profileLoading,
    match: { params, path },
    handleLogout,
  } = props;

  const isCurrentUserProfile = user.id === params.id;
  const isDeepsightUser = !!user.isDeepsight;

  const [profileUser, setProfileUser] = useState(EMPTY_STATE_USER);
  const [clientRoles, setClientRoles] = useState([]);
  const [catalogs, setCatalogs] = useState([]);

  const [languages, setLanguages] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    profileLoading();

    (async function loadData() {
      const accountDetail = await fetchProfileInfo(
        params.id,
        isCurrentUserProfile,
        history,
        showMessage,
      );

      if (accountDetail) {
        setProfileUser(accountDetail);
      }

      const roles = await fetchClientRoles();
      setClientRoles(roles);

      const languages = await fetchAllLanguages(showMessage);

      if (hasLocalCatalogs) {
        const fetchedCatalogs = await fetchCatalogsOfClient();

        const filteredCatalogs = fetchedCatalogs.filter(({ active }) => active);

        setCatalogs(filteredCatalogs);
      }

      if (languages.length) {
        setLanguages(languages);
      }

      profileLoaded();

      setIsLoading(false);
    })();
  }, [isCurrentUserProfile]);

  if (isLoading) {
    return <Container></Container>;
  }

  const isSSOSync = !!cryptrDomain;

  return (
    <Container>
      {renderTopBar({
        user,
        profileUser,
        storeName,
        path,
        isCurrentUserProfile,
        handleLogout,
      })}
      {renderContent({
        isCurrentUserProfile,
        profileUser,
        setProfileUser,
        clientRoles,
        isDeepsightUser,
        languages,
        catalogs,
        shouldDisableResetPassword,
        shouldDisableEdition: shouldDisableEdition || isSSOSync,
        shouldDisableProfilePictureUpdate: shouldDisableEdition,
      })}
      <ProfileBottomBar isCurrentUserProfile={isCurrentUserProfile} profileUser={profileUser} />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  shouldDisableResetPassword: getDisableResetPassword(state.baseReducer.userRights),
  shouldDisableEdition: getDisableEdition(state.baseReducer.userRights),
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  handleLogout: () => {
    dispatch(logout());
  },
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  profileLoading: () => {
    dispatch(loading());
  },
  profileLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetail);
