import { sortBy } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import utilsXLS from '@commons/utils/makeXLS';

const getFileName = (storeName) => {
  const date = moment().format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

  return i18next.t('BACKOFFICE.CASHIER_STORES.EXPORT_MAPPINGS_FILENAME', { storeName, date });
};

const getSheetName = (storeName) =>
  i18next.t('BACKOFFICE.CASHIER_STORES.EXPORT_MAPPINGS_SHEETNAME', { storeName });

const COLUMNS_SETTINGS = (storeName) => [
  {
    propertyKey: 'cashierStore.name',
    name: i18next.t('BACKOFFICE.CASHIER_STORES.EXPORT_MAPPINGS_CASHIER_STORE_NAME', { storeName }),
  },
  {
    propertyKey: 'store.name',
    name: i18next.t('BACKOFFICE.CASHIER_STORES.EXPORT_MAPPINGS_STORE_NAME', { storeName }),
  },
  {
    propertyKey: 'cashier.name',
    name: i18next.t('BACKOFFICE.CASHIER_STORES.EXPORT_MAPPINGS_CASHIER_NAME'),
  },
];

const handleExport = (cashierStoreMappings, { storeName, storeNamePlural }) => {
  const sheet = utilsXLS.generateDefaultSheet(
    getSheetName(storeNamePlural),
    COLUMNS_SETTINGS(storeName),
    sortBy(cashierStoreMappings, ['cashierStore.name', 'store.name', 'cashier.name']),
  );

  utilsXLS.makeXLS(getFileName(storeNamePlural), [sheet]);
};

export default handleExport;
