// React
import PropTypes from 'prop-types';

// Redux
import { useDispatch } from 'react-redux';

// External libraries
import { cloneDeep } from 'lodash';
import i18next from 'i18next';

// Actions
import { loading, loadingSuccess } from '@actions/loading';
import { openGenericModal, refreshGenericModal } from '@actions/modal';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

// Services
import { ingredient as ingredientService } from '@services/ingredient';
import { product as productService } from '@services/product';
import { supplierProduct as supplierProductService } from '@services/supplierProduct';
import centralService from '@services/central';
import clientService from '@services/client';
import recipeService from '@services/recipe';

// Commons
import {
  CATEGORY_TYPES_OBJECT,
  SUB_CATEGORY_TYPES_OBJECT,
  getPropertyNoneValue,
} from '@commons/constants/categoryTypes';
import { GENERIC_MODAL_SAVE_BUTTON } from '@commons/Modals/GenericModal/genericModalActions';

// Utils
import constants from './constants';
import format from './format';

export const useCategoriesBatchUpdateModal = ({
  onRefresh,
  loadProps: { clientId, type, propertyType },
  itemListSorterParams: { entityName, createItemLabel, disableMergeItems, disableCreateItem },
}) => {
  const dispatch = useDispatch();

  /************************/
  /* MANAGE MODAL METHODS */
  /************************/

  const onManageModalSave = async (values) => {
    dispatch(loading());

    try {
      const categoriesSubCategories = Object.values(values).map(
        ({ value, originalItem: { id } }) => ({
          id,
          name: value,
        }),
      );

      if (propertyType === 'categories') {
        await clientService.updateCategories(clientId, categoriesSubCategories, type);
      } else {
        await clientService.updateSubCategories(clientId, categoriesSubCategories, type);
      }

      if (onRefresh) {
        await onRefresh();
      }

      dispatch(
        showSuccessMessage(
          i18next.t(
            propertyType === 'categories'
              ? 'ADMIN.GENERAL.MANAGE_CATEGORIES_SUCCESS'
              : 'ADMIN.GENERAL.MANAGE_SUB_CATEGORIES_SUCCESS',
          ),
        ),
      );
    } catch {
      dispatch(
        showErrorMessage(
          i18next.t(
            propertyType === 'categories'
              ? 'ADMIN.GENERAL.MANAGE_CATEGORIES_FAILURE'
              : 'ADMIN.GENERAL.MANAGE_SUB_CATEGORIES_FAILURE',
          ),
        ),
      );
    } finally {
      dispatch(loadingSuccess());
    }
  };

  const onManageModalChange = (payload, modalParams) => {
    const updatedPayload = format.formatOnChangeCallbackData(payload);

    const params = getManageModalParams(updatedPayload, modalParams, onManageModalChange, false);

    dispatch(refreshGenericModal(params));
  };

  const getManageModalParams = (data, { title }, handleChange, isSaveDisabled = true) => {
    const params = {
      ...cloneDeep(constants.GENERIC_MODAL_CONFIG), // cloneDeep necessary to create a real copy of constant
      title,
    };

    params.actions.push({
      ...GENERIC_MODAL_SAVE_BUTTON(),
      key: 1,
      isDisabled: isSaveDisabled,
      handleClick: () => onManageModalSave(data),
    });

    params.data = {
      data,
      entityName,
      createItemLabel,
      disableMergeItems,
      disableCreateItem,
      onChange: (payload) => handleChange(payload, { title }),
      properties: Object.keys(data).sort(),
      propertyNoneLabel: getPropertyNoneValue(),
    };

    return params;
  };

  /************************/
  /* ASSIGN MODAL METHODS */
  /************************/

  const onAssignModalSave = async (selectedItem, targettedItems) => {
    const ids = targettedItems.map(({ id }) => id);

    dispatch(loading());

    const routeToCallForCategoryType = {
      categories: {
        [CATEGORY_TYPES_OBJECT.RECIPE]: () => recipeService.patchCategories(ids, selectedItem.id),
        [CATEGORY_TYPES_OBJECT.INGREDIENT]: () =>
          ingredientService.patchCategories(ids, selectedItem.id),
        [CATEGORY_TYPES_OBJECT.CENTRAL_KITCHEN_RECIPE]: () =>
          centralService.patchCentralKitchenRecipesCategories(ids, selectedItem.id),
        [CATEGORY_TYPES_OBJECT.SUPPLIER_PRODUCT]: () =>
          supplierProductService.patchCategoriesSubCategories(ids, selectedItem.id, 'category'),
        [CATEGORY_TYPES_OBJECT.PRODUCT]: () =>
          productService.patchCategoriesSubCategories(ids, selectedItem.id, 'category'),
        [CATEGORY_TYPES_OBJECT.CENTRAL_KITCHEN_PRODUCT]: () =>
          centralService.patchCentralKitchenProductCategories(ids, selectedItem.id, 'category'),
      },
      subCategories: {
        [SUB_CATEGORY_TYPES_OBJECT.SUPPLIER_PRODUCT]: () =>
          supplierProductService.patchCategoriesSubCategories(ids, selectedItem.id, 'subCategory'),
        [CATEGORY_TYPES_OBJECT.PRODUCT]: () =>
          productService.patchCategoriesSubCategories(ids, selectedItem.id, 'subCategory'),
        [CATEGORY_TYPES_OBJECT.CENTRAL_KITCHEN_PRODUCT]: () =>
          centralService.patchCentralKitchenProductCategories(ids, selectedItem.id, 'subCategory'),
      },
    };

    try {
      await routeToCallForCategoryType[propertyType][type]();

      if (onRefresh) {
        await onRefresh();
      }

      dispatch(showSuccessMessage(i18next.t('ADMIN.GENERAL.ASSIGN_CATEGORY_SUCCESS')));
    } catch {
      dispatch(showErrorMessage(i18next.t('ADMIN.GENERAL.ASSIGN_CATEGORY_FAILURE')));
    } finally {
      dispatch(loadingSuccess());
    }
  };

  const onAssignModalChange = (selectedProperty, data, properties, selectedItems, modalParams) => {
    // Compute update save method to send correct parameters and avoid using state
    const onSave = () => onAssignModalSave(selectedProperty, selectedItems);

    const params = getAssignModalParams(
      data,
      properties,
      selectedItems,
      modalParams,
      onSave,
      onAssignModalChange,
      false,
    );

    dispatch(refreshGenericModal(params));
  };

  const getAssignModalParams = (
    data,
    properties,
    selectedItems,
    { title },
    handleSave,
    handleChange,
    isSaveDisabled = true,
  ) => {
    const params = {
      ...cloneDeep(constants.GENERIC_MODAL_CONFIG), // cloneDeep necessary to create a real copy of constant
      title,
    };

    params.actions.push({
      ...GENERIC_MODAL_SAVE_BUTTON(),
      key: 1,
      isDisabled: isSaveDisabled,
      handleClick: handleSave,
    });

    params.data = {
      data,
      field: 'category',
      properties,
      entityName,
      createItemLabel,
      disableMergeItems,
      disableCreateItem,
      groupAllAtOnce: true,
      itemsCount: selectedItems.length,
      onChange: (_, selectedProperty) =>
        handleChange(selectedProperty, data, properties, selectedItems, { title }),
      propertyNoneLabel: getPropertyNoneValue(),
    };

    return params;
  };

  /**
   * @param {boolean} isAssignMode - Whether to assign or just manage categories
   * @param {object[]} selectedItems - Items to assign categories to

   * @return {Promise<void>}
   */
  const openModal = async (isAssignMode = false, selectedItems, modalParams) => {
    dispatch(loading());

    try {
      const categoriesSubCategories = await clientService.getCategoriesAndSubCategories(type, true);

      const formattedData = format.formatDataToItemListSorterPayload(
        categoriesSubCategories[propertyType],
      );

      if (!isAssignMode) {
        const params = getManageModalParams(formattedData, modalParams, onManageModalChange);

        dispatch(openGenericModal(params));

        return;
      }

      const properties = format.formatPropertiesToItemListSorterPayload(
        categoriesSubCategories[propertyType],
      );

      const params = getAssignModalParams(
        formattedData,
        properties,
        selectedItems,
        modalParams,
        () => {}, // void method
        onAssignModalChange,
      );

      dispatch(openGenericModal(params));
    } catch {
      dispatch(showErrorMessage(i18next.t('ADMIN.GENERAL.MANAGE_CATEGORIES_CANNOT_OPEN')));
    } finally {
      dispatch(loadingSuccess());
    }
  };

  return {
    open: openModal,
  };
};

useCategoriesBatchUpdateModal.propTypes = {
  // When defined, will be called on save action after successfully called the API
  onRefresh: PropTypes.func,

  // Useful to load initial client categories for requested type
  loadProps: PropTypes.shape({
    clientId: PropTypes.string.isRequired,
    type: PropTypes.oneOf([Object.values(CATEGORY_TYPES_OBJECT)]).isRequired,
    propertyType: PropTypes.oneOf(['categories', 'subCategories']).isRequired,
  }).isRequired,

  // Configure ItemListSorter
  itemListSorterParams: PropTypes.shape({
    entityName: PropTypes.string.isRequired,
    createItemLabel: PropTypes.string.isRequired,
    disableCreateItem: PropTypes.bool,
    disableMergeItems: PropTypes.bool,
  }).isRequired,
};
