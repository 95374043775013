import moment from 'moment-timezone';

/**
 * When attempting to apply an advanced filter at render, the `value` should be verified depending on its type.
 * This is due to the existing implementation filters across the application (see FiltersModal/index.js)
 *
 * Value can indeed be a number, a string or an object with minValue and maxValue properties.
 */
export const isValidFilterValue = (value) => {
  if (Number.isFinite(value)) {
    return true;
  }

  if (typeof value === 'string' && value.trim() !== '') {
    return true;
  }

  if (typeof value === 'object' && value !== null) {
    return (
      (Number.isFinite(value.minValue) && Number.isFinite(value.maxValue)) ||
      moment(value).isValid()
    );
  }

  return false;
};

// Utility function for better readability
export const hasValidAdvancedFilters = (advancedFilters) =>
  advancedFilters &&
  advancedFilters.length &&
  advancedFilters.every(({ value }) => isValidFilterValue(value));
