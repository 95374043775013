import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState, useContext } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage } from '@actions/messageconfirmation';

import { ANALYTICS_TYPE } from '@commons/constants/analyticsType';
import { TableView } from '@commons/utils/styledLibraryComponents';
import DeepsightAnalyticsHeaders from '@commons/DeepsightAnalyticsHeaders';
import EmptyState from '@commons/EmptyState';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import { FiltersContext } from '@context/FiltersContext';

import { getClientInfo } from '@selectors/client';
import { getSalesPointStores } from '@selectors/stores';

import { OfflineProductionPage } from '../../OfflinePage/OfflineProductionPage';

import { useProductsFilter } from '../hooks/useProductsFilter';
import { useStoresFilter } from '../hooks/useStoresFilter';
import METRICS from '../constants/metrics';

import { Container, ContainerContent } from './styledComponents';
import utils from './utils';

export const ProductionByReferenceAnalytics = (props) => {
  const {
    // redux props
    stores,
    currency,
    user,
    client: { clientId, hasMultipleBrands, hasOffline, storeName },
    // redux methods
    pageLoading,
    pageLoaded,
    showErrorMessage,
    // offline
    isUserOffline,
    isConnectionSlow,
    // Feature client mapping props
    analyticsColumns,
    defaultAnalyticsMetric,
    applyBrandsFilterOnProducts,
    clientBrands,
  } = props;

  const { filtersAnalyticsProduction, setFiltersAnalyticsProduction } = useContext(FiltersContext);

  // Loading states
  const [isLoading, setIsLoading] = useState(true);
  const [shouldFetchData, setShouldFetchData] = useState(false);
  const [renderEmptyState, setRenderEmptyState] = useState(false);
  const [isRetrievingAnalytics, setIsRetrievingAnalytics] = useState(true);
  const [shouldRetrieveAnalytics, setShouldRetrieveAnalytics] = useState(false);

  const productsFilters = useProductsFilter({
    clientId,
    showErrorMessage,
    useBrandsFilter: hasMultipleBrands && applyBrandsFilterOnProducts,
    clientBrands,
  });

  const storesFilters = useStoresFilter({
    stores,
    clientId,
    showErrorMessage,
    useBrandsFilter: hasMultipleBrands && !applyBrandsFilterOnProducts,
    clientBrands,
  });

  const [selectedMetric, setSelectedMetric] = useState(METRICS[0]);

  const [startDate, setStartDate] = useState(moment().subtract(1, 'days').startOf('day'));
  const [endDate, setEndDate] = useState(moment().subtract(1, 'days').endOf('day'));

  const [applyFilters, setApplyFilters] = useState(true);
  const [advancedFilters, setAdvancedFilters] = useState(null);

  // TableView commponent states
  const [columns, setColumns] = useState([]);
  const [analytics, setAnalytics] = useState(null);
  const [filteredData, setFilteredData] = useState([]);

  // Check if user loses internet to display dedicated view if client.hasOffline
  useEffect(() => {
    const updatedShouldFetchData = !hasOffline || (!isUserOffline && !isConnectionSlow);

    if (shouldFetchData !== updatedShouldFetchData) {
      setShouldFetchData(updatedShouldFetchData);
      setIsLoading(updatedShouldFetchData);
    }
  }, [hasOffline, isUserOffline, isConnectionSlow]);

  // Set default analytics metrics for client
  useEffect(() => {
    const matchingMetric = METRICS.find((metric) => metric.key === defaultAnalyticsMetric);

    if (matchingMetric) {
      setSelectedMetric(matchingMetric);
    }
  }, [defaultAnalyticsMetric]);

  // Handle columns configuration
  useEffect(() => {
    setColumns(
      utils.getColumnsTable(currency, selectedMetric.key, hasMultipleBrands, analyticsColumns),
    );
  }, [currency, selectedMetric, hasMultipleBrands, analyticsColumns]);

  // Wait until stores filters are loaded to set isLoading to false
  useEffect(() => {
    if (!shouldFetchData) {
      return;
    }

    if (storesFilters.isLoading || productsFilters.isLoading) {
      pageLoading();

      return;
    }

    setIsLoading(false);

    pageLoaded();
  }, [shouldFetchData, storesFilters.isLoading, productsFilters.isLoading]);

  // Listen to state updates that could trigger fetch of analytics and wait until all states are updated
  useEffect(() => {
    if (isLoading || !startDate || !endDate) {
      return;
    }

    // To indicate and simulate that analytics are already being loaded
    setIsRetrievingAnalytics(true);

    setTimeout(() => {
      setShouldRetrieveAnalytics(true);
    }, 250); // make sure all states are properly updated from filters component
  }, [
    startDate,
    endDate,
    isLoading,
    storesFilters.selectedStores,
    productsFilters.selectedProducts,
  ]);

  // Handle the fetch of analytics data when triggered
  useEffect(() => {
    if (!shouldRetrieveAnalytics) {
      return;
    }

    const storeIds = storesFilters.selectedStores.map((store) => store.id);
    const productIds = productsFilters.selectedProducts.map((item) => item.id);

    if (!storeIds.length) {
      return;
    }

    setAnalytics(null);
    setRenderEmptyState(false);

    (async function loadData() {
      const result = await utils.getAnalyticsData(
        storeIds,
        startDate,
        endDate,
        productIds,
        showErrorMessage,
      );

      setAnalytics(result);
      setFilteredData(result);
      setRenderEmptyState(isEmpty(result));

      setIsRetrievingAnalytics(false);
      setShouldRetrieveAnalytics(false);
    })();
  }, [shouldRetrieveAnalytics]);

  // Apply advanced filters on received analytics
  useEffect(() => {
    if (!analytics || isRetrievingAnalytics || !advancedFilters || !advancedFilters.length) {
      return;
    }

    if (!applyFilters) {
      return;
    }

    let filteredData = analytics.filter((data) => data.id !== 'total');

    advancedFilters.forEach(({ propertyKey, doFilter, value }) => {
      filteredData = doFilter(filteredData, propertyKey, value);
    });

    setFilteredData(filteredData);
    setRenderEmptyState(!filteredData || !filteredData.length);
  }, [analytics, advancedFilters, applyFilters, isRetrievingAnalytics]);

  const handleSelectedDates = (dates) => {
    if (!moment(dates.startDate).isSame(startDate, 'day')) {
      setEndDate(null);
      setStartDate(dates.startDate);
    }

    if (!moment(dates.endDate).isSame(endDate, 'day')) {
      setEndDate(dates.endDate);
    }
  };

  const handleExport = () => {
    if (isRetrievingAnalytics) {
      return;
    }

    utils.getExportFile({
      data: filteredData,
      endDate,
      currency,
      startDate,
      storeName,
      selectedMetric,
      hasMultipleBrands,
      analyticsColumns,
      selectedStores: storesFilters.selectedStores,
    });
  };

  if (isLoading) {
    return null;
  }
  if (hasOffline && (isUserOffline || isConnectionSlow)) {
    return (
      <Container>
        <NavigationBreadCrumb featurePath={props.match.path} />
        <OfflineProductionPage isAnalytics />
      </Container>
    );
  }

  return (
    <Container>
      <NavigationBreadCrumb featurePath={props.match.path} />
      <ContainerContent>
        <DeepsightAnalyticsHeaders
          advancedFilters={advancedFilters}
          analyticsType={ANALYTICS_TYPE.PRODUCTION}
          columnsFilterList={columns.filter((column) => column.filterType === 'numeric')}
          customMultipleDropDowns={[
            ...Object.values(storesFilters.filtersKeyById),
            ...Object.values(productsFilters.filtersKeyById),
          ]}
          disableExportButton={!filteredData || !filteredData.length}
          endDate={endDate}
          filters={filtersAnalyticsProduction}
          handleExport={handleExport}
          handleSelectedDates={handleSelectedDates}
          metrics={METRICS}
          readOnly={isRetrievingAnalytics}
          selectedMetric={selectedMetric}
          setAdvancedFilters={setAdvancedFilters}
          setApplyFilters={setApplyFilters}
          setFilters={setFiltersAnalyticsProduction}
          setSelectedMetric={(value) => {
            setAdvancedFilters(null);

            setSelectedMetric(value);
          }}
          startDate={startDate}
        />
        {renderEmptyState && (
          <div style={{ verticalAlign: 'middle', height: 'calc(100vh - 225px)' }}>
            <EmptyState />
          </div>
        )}
        {!renderEmptyState && (
          <TableView
            columns={columns}
            data={filteredData}
            defaultOrderBy={0}
            defaultOrderType={'asc'}
            displayColumnsFilters={false}
            highligthedRowsId={['total']}
            isLoading={isRetrievingAnalytics}
            languageCode={get(user, 'lnkLanguageAccountrel.code', 'fr')}
            maxPerPage={100}
            minWidth={1200}
            positionTop={90}
          />
        )}
      </ContainerContent>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  stores: getSalesPointStores(state.baseReducer.activeStores),
  isUserOffline: state.baseReducer.isUserOffline,
  isConnectionSlow: state.baseReducer.isConnectionSlow,
  client: getClientInfo(state.baseReducer.user),
  currency: state.baseReducer.currency,
  clientBrands: state.baseReducer.brands,
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message, type) => {
    dispatch(showErrorMessage(message, type));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductionByReferenceAnalytics);
