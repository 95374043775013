import axios from '../core/http';

import { APIcaller } from './APIcaller';
import { config } from '../config';

const baseApiUrl = `${config.baseApiUrl}/v1`;

const checkStocksValid = (storeId, date) =>
  axios
    .get(baseApiUrl + '/admin/stores/' + storeId + '/stocks/valid', {
      params: {
        date,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const getInventoriesExportDataByIds = (inventoryListIds) =>
  axios
    .post(`${baseApiUrl}/stocks/inventories/export`, {
      inventoryListIds,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const getPaginatedInventoriesListOfStores = (
  storeIds,
  search,
  skip,
  limit,
  orderBy,
  orderType,
  params,
  inventoryListTemplateIds,
) => {
  let requestUri = `${baseApiUrl}/stocks/inventories?`;

  if (skip != null) {
    requestUri = requestUri.concat('skip=', `${skip}&`);
  }

  if (limit != null) {
    requestUri = requestUri.concat('limit=', `${limit}&`);
  }

  if (orderBy != null) {
    requestUri = requestUri.concat('orderBy=', `${orderBy}&`);
  }

  if (orderType != null) {
    requestUri = requestUri.concat('orderType=', `${orderType}&`);
  }

  if (search) {
    requestUri = requestUri.concat('search=', `${search}&`);
  }

  requestUri = requestUri.replace(/(&)$/, '');

  if (params) {
    requestUri = requestUri.concat(params);
  }

  let requestBody = { storeIds, inventoryListTemplateIds };

  return axios
    .post(requestUri, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
};

const fetchInventoriesStoresCount = (storeIds) =>
  axios
    .post(
      `${baseApiUrl}/stocks/inventories/count`,
      { storeIds },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const deleteInventoryListById = (id) =>
  axios
    .delete(`${baseApiUrl}/stocks/inventories/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const createInitialInventories = (
  storeIds,
  inventoryDate,
  userTimezone,
  isCentralKitchenView = false,
) =>
  APIcaller.apiPost('/stocks/init/create', {
    storeIds,
    inventoryDate,
    userTimezone,
    isKitchen: isCentralKitchenView,
  });

const updateInventoryListsValidation = (inventoryListIds, isValidated) =>
  APIcaller.apiPatch('/stocks/inventories/validation', { inventoryListIds, isValidated });

const createTheoreticalInventories = (
  storeIds,
  inventoryDate,
  inventoryListTemplateId,
  userTimezone,
) =>
  APIcaller.apiPost('/stocks/theoretical/create', {
    storeIds,
    inventoryDate,
    inventoryListTemplateId,
    userTimezone,
  });

export const inventoryList = {
  fetchInventoriesStoresCount,
  checkStocksValid,
  getInventoriesExportDataByIds,
  getPaginatedInventoriesListOfStores,
  deleteInventoryListById,
  createInitialInventories,
  updateInventoryListsValidation,
  createTheoreticalInventories,
};
