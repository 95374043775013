import { get, isEmpty } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

import { areSPAndIngredientSameUnit } from '@commons/utils/units';
import { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';
import { InputIncrement, Label } from '@commons/utils/styledLibraryComponents';
import { REGEX_IMAGE_FILE_EXTENSION } from '@commons/utils/regex';
import DisplayNumber from '@commons/DisplayNumber';

import theme from '@theme';

import {
  getNoInventoryMoreThanThirtyDays,
  getYesterdayStock,
} from '@orders/OrderList/components/OrderForm/utils/formatYesterdayStock';
import { PRODUCT_ORDER_TYPE } from '@orders/OrderList/constants';
import ORDER_STATUS from '@orders/OrderList/components/OrderForm/constants/status';

import { getEntityUnit } from '@stocks/utils';

import {
  BorderMultipleProduct,
  ConditioningColumnBigger,
  ConditioningColumnFormat,
  DisplayImgName,
  DisplayName,
  DisplayNameHovered,
  DotAddedUnavailable,
  IconChevron,
  ItemConditioning,
  ItemMoreData,
  ItemName,
  ItemOrderProduct,
  ItemOrderRecommandation,
  ItemPrice,
  ItemTotal,
  LabelContainer,
  Name,
  OpenEntityRowButton,
  ProductImagDiv,
  ProductRow,
  SkuContainer,
  TotalAmount,
  TotalPackagingValue,
} from '../../styledComponents';

import OrderStockInfo from '../OrderStockInfo/OrderStockInfo';
import RecommandationLabel from '../RecommandationLabel';
import YesterdayStockLabel from '../YesterdayStockLabel';

const GENERIC_PRODUCT_INPULSE_ICON = '/images/icon-generic-inpulse-product.svg';
const ICON_CHEVRON_DOWN_LM_GREY = '/images/inpulse/chevron-down-lmgrey-small.svg';
const ICON_CHEVRON_DOWN_IP_BLACK = '/images/inpulse/chevron-down-black-micro.svg';
const ICON_CHEVRON_UP_IP_BLACK = '/images/inpulse/chevron-up-black-micro.svg';
const DAY_UNTIL_RECO_PRECISION = 14;

const MAXIMUM_CHARACTER_BEFORE_SHOWING_BIGGER_NAME = 22;

/**
 * All products orders should be displayed here for order status < ORDER_STATUS.SENT (ie. status < 3)
 */
const DraftProductOrder = (props) => {
  const {
    allowRowOpening,
    allProductsUnavailable,
    computedOrderedAmount,
    displayBigger,
    displayTotalPrice,
    endDate,
    entityStockEvent,
    handleInputChange,
    hasForecasts,
    indexCategory,
    indexEntity,
    isEditable,
    isFirstElement,
    isFirstElementAvailable,
    isOpen,
    isTablet,
    isLastElement,
    multiple,
    nbColumns,
    orderStatus,
    prefillInpulse,
    product,
    selectDisplayBigger,
    setDisplayProductPicture,
    setOpenStockInfo,
    setUpdateAmount,
    startDate,
    storeInfos,
    sumUpRecommendations,
    unavailable,
    userHasRightToEditOrder,
    currency,
    supplierInfos,
    shouldDisplayMaximumQty,
  } = props;

  /** STATES */
  const [productRecommendations, setProductRecommendations] = useState(0);
  const [consumption, setConsumption] = useState(null);

  /** USE EFFECT */
  useEffect(() => {
    if (isEmpty(entityStockEvent)) {
      return;
    }

    setConsumption(entityStockEvent.consumption);
    setProductRecommendations(sumUpRecommendations(product));
  }, [entityStockEvent]);

  const storeTimezone = storeInfos.timezone;

  const displayRecoAndYesterdayStock =
    (allProductsUnavailable && isFirstElement) || isFirstElementAvailable;

  const moreThanFiftyDay =
    moment.tz(endDate, storeTimezone).diff(moment.tz(storeTimezone), 'day') >=
    DAY_UNTIL_RECO_PRECISION;

  const yesterdayStock = !isEmpty(getYesterdayStock(entityStockEvent.stock, storeTimezone))
    ? getYesterdayStock(entityStockEvent.stock, storeTimezone)
    : 0;

  const noInventoryMoreThanThirtyDays = getNoInventoryMoreThanThirtyDays(entityStockEvent.stock);

  const packagingUsedInOrder = product.packagings.find(({ isUsedInOrder }) => isUsedInOrder);

  const productPrice = isNaN(product.price) || !product.price ? 0 : product.price;

  const getConvertedEntityUnit = (product) => {
    if (
      !product.entityStockEvent ||
      !product.entityStockEvent.entity ||
      !product.conversions.length
    ) {
      return getEntityUnit(product.packagingUnit);
    }

    const baseUnitConversion = product.conversions.find(({ convertedUnit }) =>
      areSPAndIngredientSameUnit(convertedUnit, entityStockEvent.entity.unit),
    );

    if (baseUnitConversion) {
      return getEntityUnit(baseUnitConversion.convertedUnit);
    }

    return getEntityUnit(product.packagingUnit);
  };

  const getMaxOrderQuantityColor = () => {
    const maxOrderQuantity = get(product, 'maxOrderQuantity', null);

    // maxOrderQuantity = 0 is allowed
    if (product.maxOrderQuantity !== null) {
      return product.ordered <= maxOrderQuantity ? theme.colors.infoGreen : theme.colors.infoRed;
    }

    return 'transparent';
  };

  return (
    <>
      <ProductRow
        cantOpen={!allowRowOpening}
        isOpen={isOpen}
        orderStatus={orderStatus}
        addBorder
        onClick={() => {
          if (allowRowOpening) {
            setOpenStockInfo({
              entity: isOpen ? null : indexEntity,
              category: isOpen ? null : indexCategory,
            });
          }
        }}
      >
        {!displayTotalPrice && <DotAddedUnavailable />}
        <ItemName nbColumns={nbColumns} orderStatus={orderStatus}>
          <DisplayImgName>
            <ProductImagDiv
              pointerCursor={!!product.img}
              src={
                `${
                  !!product.img && product.img.replace(REGEX_IMAGE_FILE_EXTENSION, '_compressed.$1')
                }` || GENERIC_PRODUCT_INPULSE_ICON
              }
              onClick={(e) => {
                e.stopPropagation();
                if (!!product.img) {
                  setDisplayProductPicture({
                    state: true,
                    img: product.img,
                  });
                }
              }}
              onError={({ currentTarget }) => {
                // if the image is not found
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = GENERIC_PRODUCT_INPULSE_ICON;
              }}
            />
            <DisplayName
              onClick={(e) => {
                if (product.name.length > MAXIMUM_CHARACTER_BEFORE_SHOWING_BIGGER_NAME) {
                  e.stopPropagation();
                }
                selectDisplayBigger('reference');
              }}
              onMouseOver={() => selectDisplayBigger('reference')}
            >
              <Name isTablet={isTablet} orderStatus={orderStatus}>
                {product.name}
              </Name>
              <SkuContainer orderStatus={orderStatus}>{product.sku}</SkuContainer>
            </DisplayName>
            {displayBigger.reference === true &&
              product.name.length > MAXIMUM_CHARACTER_BEFORE_SHOWING_BIGGER_NAME && (
                <DisplayNameHovered
                  nbColumns={nbColumns}
                  onClick={(e) => e.stopPropagation()}
                  onMouseOut={() => selectDisplayBigger('reference')}
                >
                  {product.name}
                </DisplayNameHovered>
              )}
          </DisplayImgName>
        </ItemName>
        <ItemConditioning
          maxSize={[
            ORDER_STATUS.SENT,
            ORDER_STATUS.RECEPTION,
            ORDER_STATUS.COMPLIANT,
            ORDER_STATUS.NON_COMPLIANT,
            ORDER_STATUS.INCOMPLETE,
          ].includes(orderStatus)}
          nbColumns={nbColumns}
          onClick={() => selectDisplayBigger('format')}
          onMouseEnter={() => selectDisplayBigger('format')}
          onMouseLeave={() => selectDisplayBigger('format')}
        >
          <ConditioningColumnFormat opaque={unavailable}>
            {packagingUsedInOrder && packagingUsedInOrder.name.length > 17
              ? packagingUsedInOrder.name.substring(0, 14) + ' ...'
              : packagingUsedInOrder.name}
          </ConditioningColumnFormat>
          {displayBigger.format === true &&
            packagingUsedInOrder.name &&
            packagingUsedInOrder.name.length > 17 && (
              <ConditioningColumnBigger
                className="product-title-hover"
                style={{ cursor: 'pointer' }}
              >
                {packagingUsedInOrder.name}
              </ConditioningColumnBigger>
            )}
        </ItemConditioning>
        <ItemPrice nbColumns={nbColumns} opaque={unavailable} orderStatus={orderStatus}>
          <DisplayNumber
            color={ENUM_COLORS.DARKEST}
            currency={currency}
            displayCurrencyCode={true}
            font={ENUM_FONTS.TEXT_BIG}
            number={productPrice}
          />
        </ItemPrice>

        {displayRecoAndYesterdayStock && (
          <LabelContainer nbColumns={nbColumns} weightedCoefficientWidthColumn={0.8}>
            <YesterdayStockLabel
              id={product.id}
              isAssociated={!!product.entityId}
              moreThanThirtyDay={noInventoryMoreThanThirtyDays}
              theoricalStock={yesterdayStock.realStockUnit == null}
              unit={getConvertedEntityUnit(product)}
              yesterdayStock={
                yesterdayStock.realStockUnit != null
                  ? yesterdayStock.realStockUnit
                  : yesterdayStock.theoricalStockUnit
              }
            />
          </LabelContainer>
        )}

        {!displayRecoAndYesterdayStock && (
          <LabelContainer
            nbColumns={nbColumns}
            weightedCoefficientWidthColumn={hasForecasts ? 1.6 : 0.8}
          />
        )}

        {hasForecasts && displayRecoAndYesterdayStock && (
          <ItemOrderRecommandation
            nbColumns={nbColumns}
            opaque={unavailable}
            weightedCoefficientWidthColumn={0.8}
          >
            <RecommandationLabel
              entityRecommandation={get(product, 'entityStockEvent.recommandation', 0.0)}
              id={product.id}
              isAssociated={!!product.entityId}
              moreThanFiftyDay={moreThanFiftyDay}
              unit={get(product, 'entityStockEvent.entity.unit', product.packagingUnit)}
            />
          </ItemOrderRecommandation>
        )}

        {shouldDisplayMaximumQty && (
          <LabelContainer nbColumns={nbColumns} weightedCoefficientWidthColumn={0.8}>
            <Label
              background={getMaxOrderQuantityColor}
              children={
                product.maxOrderQuantity !== null // maxOrderQuantity = 0 is allowed
                  ? `${product.ordered} / ${product.maxOrderQuantity}`
                  : ''
              }
              color={getMaxOrderQuantityColor}
              type={null}
            />
          </LabelContainer>
        )}

        <ItemOrderProduct
          nbColumns={nbColumns}
          opaque={unavailable}
          weightedCoefficientWidthColumn={1.4}
        >
          <div onClick={(e) => e.stopPropagation()}>
            <InputIncrement
              allowDecimals={supplierInfos.allowDecimalQuantitiesOrder}
              allowNull={true}
              enableNullValue={true}
              height="40px"
              isDisabled={unavailable || !isEditable || !userHasRightToEditOrder}
              isHighlighted={
                prefillInpulse &&
                product.ordered === productRecommendations &&
                get(product, 'entityStockEvent.recommandation', 0) > 0
              }
              limitValues={{
                min: 0,
                ...(product.maxOrderQuantity != null ? { max: product.maxOrderQuantity } : {}),
              }}
              placeholder={''}
              setValue={(value) => {
                handleInputChange(value, PRODUCT_ORDER_TYPE.ORDERED);
                setUpdateAmount(true);
              }}
              value={product.ordered}
              width="160px"
            />
          </div>
        </ItemOrderProduct>

        {displayTotalPrice ? (
          <ItemTotal nbColumns={nbColumns}>
            <TotalAmount>
              <DisplayNumber
                color={ENUM_COLORS.DARKEST}
                currency={currency}
                displayCurrencyCode={true}
                font={ENUM_FONTS.TEXT_BIG}
                number={productPrice * product.ordered}
              />
            </TotalAmount>

            <TotalPackagingValue>
              {`${Math.round(product.ordered * product.packagingValue * 100) / 100} ${
                product.packagingUnit === 'unit'
                  ? `${i18next.t('STOCKS.STOCKS.FORM_PACKAGING_UNIT_LABEL')}${
                      product.ordered * product.packagingValue > 1 ? 's' : ''
                    }`
                  : product.packagingUnit
              }`}
            </TotalPackagingValue>
          </ItemTotal>
        ) : (
          <ItemTotal nbColumns={nbColumns} opaque={unavailable}>
            <LabelContainer nbColumns={nbColumns} weightedCoefficientWidthColumn={0.8}>
              <Label
                background={theme.colors.infoRed}
                children={i18next.t('GENERAL.UNAVAILABLE')}
                color={theme.colors.infoRed}
                type={''}
              />
            </LabelContainer>
          </ItemTotal>
        )}

        <ItemMoreData nbColumns={nbColumns} orderStatus={orderStatus}>
          {isFirstElement && (
            <OpenEntityRowButton isDisable={!allowRowOpening} isOpen={isOpen} multiple={multiple}>
              <IconChevron
                src={
                  !isOpen
                    ? allowRowOpening
                      ? ICON_CHEVRON_DOWN_IP_BLACK
                      : ICON_CHEVRON_DOWN_LM_GREY
                    : ICON_CHEVRON_UP_IP_BLACK
                }
              />
            </OpenEntityRowButton>
          )}
        </ItemMoreData>
      </ProductRow>

      {!!multiple && <BorderMultipleProduct />}

      {isOpen && isLastElement && orderStatus < ORDER_STATUS.SENT && (
        <OrderStockInfo
          computedOrderedAmount={computedOrderedAmount}
          consumption={consumption}
          currency={currency}
          endDate={endDate}
          entityId={product.entityId}
          entityStockEvent={entityStockEvent}
          hasForecasts={hasForecasts}
          moreThanFiftyDay={moreThanFiftyDay}
          packagingValue={product.packagingValue}
          showMessage={props.showMessage}
          startDate={startDate}
          stockConvention={storeInfos.stockConvention}
          storeTimezone={storeTimezone}
        />
      )}
    </>
  );
};

export default DraftProductOrder;
