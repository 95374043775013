import { connect } from 'react-redux';
import { get, uniqBy } from 'lodash';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import { ListView } from '@commons/utils/styledLibraryComponents';
import { sortArrayOfObjectsAlphabetically } from '@commons/utils/sorting';
import EmptyState from '@commons/EmptyState';

import DeepsightFiltersButton from '@admin/components/FilterButton';

import { getClientInfo } from '@selectors/client';

import { user as userService } from '@services/user';

import { Container } from './styledComponents';
import { getColumns } from './getColumns';

const AccountMappingModal = (props) => {
  const {
    client: { clientId, storeName, hasCentralKitchens },
    accountList,
    setAccountsToMap,
    storeId,
  } = props;

  const [availableAccounts, setAvailableAccounts] = useState([]);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);

  const [columns, setColumns] = useState(getColumns([], storeName, hasCentralKitchens));

  // Filters States
  const [filters, setFilters] = useState(null);
  const [applyFilters, setApplyFilters] = useState(true);
  const [advancedFilters, setAdvancedFilters] = useState(null);

  useEffect(() => {
    if (!storeId) {
      setAccountsToMap([]);
      return;
    }

    (async () => {
      const fetchedAccounts = await userService.getAccountsByClientId(clientId, true);

      const accountIdSet = new Set(accountList.map(({ id }) => id));

      const updatedAvailableAccounts = fetchedAccounts.reduce((acc, account) => {
        if (accountIdSet.has(account.id)) {
          return acc;
        }

        acc.push({
          ...account,
          name:
            account.firstName && account.lastName
              ? `${account.firstName} ${account.lastName}`
              : '---',
          role: get(account, 'role.name', ''),
        });

        return acc;
      }, []);

      const fetchedRoles = updatedAvailableAccounts.map(({ role }) => ({
        name: role,
        propertyKey: 'role',
        filterValue: true,
      }));

      const uniqueRoles = uniqBy(fetchedRoles, 'name');

      setColumns(getColumns(uniqueRoles, storeName, hasCentralKitchens));

      setRoles(uniqueRoles);

      setAvailableAccounts(updatedAvailableAccounts);
      setFilteredAccounts(updatedAvailableAccounts);
    })();
  }, []);

  /******************************/
  /** HANDLE FILTERS LIST VIEW **/
  /******************************/
  useEffect(() => {
    if (!applyFilters) {
      return;
    }

    const selectedRolesValues = selectedRoles.map(({ name }) => name);

    const filteredAccounts = availableAccounts.filter(({ role }) =>
      selectedRolesValues.includes(role),
    );

    if (!advancedFilters || !advancedFilters.length) {
      setFilteredAccounts(filteredAccounts);

      return;
    }

    advancedFilters[0].list = sortArrayOfObjectsAlphabetically(advancedFilters[0].list, 'name');

    const filteredMappingsWithAdvancedFilters = advancedFilters.reduce(
      (result, { doFilter, propertyKey, value }) => doFilter(result, propertyKey, value),
      filteredAccounts,
    );

    setFilteredAccounts(filteredMappingsWithAdvancedFilters);
  }, [applyFilters, advancedFilters, selectedRoles, roles]);

  return (
    <Container>
      <ListView
        columns={columns}
        data={filteredAccounts}
        defaultOrderBy={'name'}
        defaultOrderType={'asc'}
        renderEmptyState={() => <EmptyState />}
        renderFilterButton={() => (
          <DeepsightFiltersButton
            advancedFilters={advancedFilters}
            applyFilters={applyFilters}
            columnsFilterList={columns.filter(({ filterType }) => !!filterType)}
            customMultipleDropDowns={[
              {
                id: 'roles',
                icon: '/images/icon-dropdown-grey.svg',
                list: roles,
                defaultSelectedItems: selectedRoles,
                selectedItems: selectedRoles,
                setSelectedItems: setSelectedRoles,
              },
            ]}
            filters={filters}
            setAdvancedFilters={setAdvancedFilters}
            setApplyFilters={setApplyFilters}
            setFilters={setFilters}
            textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
          />
        )}
        setSelectedItems={(items) => setAccountsToMap(items)}
        disableFooter
        forceEnableSelection
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
  user: state.baseReducer.user,
});

export default connect(mapStateToProps)(AccountMappingModal);
