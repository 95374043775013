import axios from '../core/http';

import { APIcaller } from './APIcaller';
import { config } from '../config';

const baseApiUrl = `${config.baseApiUrl}/v1`;

export const getInventoryTransferListByStoreIds = async (storeIds) => {
  const { data } = await axios.post(
    baseApiUrl + '/stocks/inventories/transfer/getByStoreIds',
    {
      storeIds,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    },
  );

  return data;
};

export const getInventoryTransferDates = (senderStoreId, recipientStoreId) =>
  axios
    .get(`${baseApiUrl}/stocks/inventories/transfer/dates/${senderStoreId}/${recipientStoreId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

export const deleteInventoryTransferListByIds = (inventoryTransferListIds) =>
  axios
    .delete(`${baseApiUrl}/stocks/inventories/transfer/deleteMany`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
      data: {
        inventoryTransferListIds,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

export const upsertInventoryTransfer = async (
  inventoryTransferList,
  supplierProducts,
  userTimezone,
) =>
  APIcaller.apiPost('/stocks/inventories/transfer/upsert', {
    inventoryTransferList,
    supplierProducts,
    userTimezone,
  });

export const getInventoryTransferListWithInventoriesById = async (inventoryTransferListId) =>
  axios.get(`${baseApiUrl}/stocks/inventories/transfer/${inventoryTransferListId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('loopbackAccessToken'),
    },
  });

export const validateInventoryTransferList = async (inventoryTransferListId, userTimezone) =>
  APIcaller.apiPost(`/stocks/inventories/transfer/${inventoryTransferListId}/validate`, {
    userTimezone,
  });

export default {
  getInventoryTransferListByStoreIds,
  getInventoryTransferDates,
  deleteInventoryTransferListByIds,
  upsertInventoryTransfer,
  validateInventoryTransferList,
  getInventoryTransferListWithInventoriesById,
};
