import * as yup from 'yup';
import i18next from 'i18next';

// COMMONS
import { INPUT_TYPES } from '@commons/constants/inputType';
import { INPUT_WIDTH } from '@commons/utils/styledLibraryComponents';

export const INTERNAL_INVOICE_INPUTS = {
  ISSUER: 'issuer',
  BILLED: 'billed',
  NUMBER: 'number',
  DATE: 'date',
  TOTAL: 'total',
};

export const INTERNAL_INVOICE_INFOS_FIELDS = [
  INTERNAL_INVOICE_INPUTS.ISSUER,
  INTERNAL_INVOICE_INPUTS.BILLED,
];

export const STEPS_FIELDS_TO_CHECK = [...INTERNAL_INVOICE_INFOS_FIELDS];

export const INTERNAL_INVOICE_INFOS_INPUTS = (props) => {
  const { centralKitchens, salesPointStores, invoiceDate, invoiceNumber, isCreation } = props;

  const inputs = [
    {
      type: INPUT_TYPES.SINGLE_SELECT,
      name: INTERNAL_INVOICE_INPUTS.ISSUER,
      label: i18next.t('GENERAL.ISSUER'),
      width: INPUT_WIDTH.LARGE,
      isRequired: true,
      items: centralKitchens,
      isDisabled: () => !isCreation,
      rule: yup.object().required(
        i18next.t('GENERAL.FORM_FIELD_IS_REQUIRED_ERROR', {
          field: i18next.t('GENERAL.ISSUER'),
        }),
      ),
    },
    {
      type: INPUT_TYPES.SINGLE_SELECT,
      name: INTERNAL_INVOICE_INPUTS.BILLED,
      label: i18next.t('GENERAL.BILLED'),
      width: INPUT_WIDTH.LARGE,
      isDisabled: () => !salesPointStores || salesPointStores.length === 0,
      isRequired: true,
      items: salesPointStores,
      rule: yup.object().required(
        i18next.t('GENERAL.FORM_FIELD_IS_REQUIRED_ERROR', {
          field: i18next.t('GENERAL.BILLED'),
        }),
      ),
    },
    {
      type: INPUT_TYPES.TEXT,
      name: INTERNAL_INVOICE_INPUTS.NUMBER,
      label: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_INVOICE_NUMBER'),
      width: INPUT_WIDTH.LARGE,
      isRequired: true,
      isDisabled: () => true,
      rule: yup.string().required(
        i18next.t('GENERAL.FORM_FIELD_IS_REQUIRED_ERROR', {
          field: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_INVOICE_NUMBER'),
        }),
      ),
      defaultValue: invoiceNumber,
    },
    {
      type: INPUT_TYPES.SINGLE_DATEPICKER,
      name: INTERNAL_INVOICE_INPUTS.DATE,
      label: `${i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_INVOICE_DATE')} :`,
      width: INPUT_WIDTH.LARGE,
      isDisabled: () => true,
      rule: yup.string().required(
        i18next.t('GENERAL.FORM_FIELD_IS_REQUIRED_ERROR', {
          field: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_INVOICE_DATE'),
        }),
      ),
      defaultValue: invoiceDate,
    },
    {
      type: INPUT_TYPES.TEXT_NUMBER,
      name: INTERNAL_INVOICE_INPUTS.TOTAL,
      label: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_INVOICED_TOT_EX_TAX'),
      width: INPUT_WIDTH.LARGE,
      isRequired: true,
      isDisabled: () => true,
      rule: yup.string().required(
        i18next.t('GENERAL.FORM_FIELD_IS_REQUIRED_ERROR', {
          field: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_INVOICED_TOT_EX_TAX'),
        }),
      ),
    },
  ];
  return inputs;
};

export const INTERNAL_INVOICE_FORM_INPUTS = (props) => [...INTERNAL_INVOICE_INFOS_INPUTS(props)];
