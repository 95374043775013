import { connect } from 'react-redux';
import { get, find, keyBy, sortBy } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useState, useEffect } from 'react';

import { capitalizeFirstLetter } from '@commons/utils/format';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { getIsCentralMode } from '@commons/utils/localStorage';
import { getUserTimezone } from '@commons/utils/date';

import ExportModalContent from '@lib/inpulse/ExportModal';

import { getCentralKitchenStores, getSalesPointStores } from '@selectors/stores';
import { getClientInfo } from '@selectors/client';

import { inventoryList as inventoryListService } from '@services/inventoryList';
import storageAreaService from '@services/storageArea';

import { exportInventories } from '@stocks/StocksInventories/utils/exportInventories';
import { getEntityUnit } from '@stocks/utils';

const BATCH_INVENTORIES_TO_FETCH_AT_ONCE = 5;
const MAX_SHEET_ELEMENT_SIZE = 50000;

const getRecipeInventoriesData = (inventoriesLists, stores, storageAreas, recipeInventories) => {
  const storageAreasById = keyBy(storageAreas, 'id');
  const inventoryListsById = keyBy(inventoriesLists, 'id');
  const storesById = keyBy(stores, 'id');

  const formattedRecipeInventories = recipeInventories.map(
    ({
      id,
      entityId,
      storageAreaId,
      quantity,
      cost,
      inventoryListId,
      lnkEntityRecipeInventoryrel,
      brandEntityMappings,
    }) => {
      const matchedInventoryList = inventoryListsById[inventoryListId];
      const matchedStore = storesById[matchedInventoryList.storeId];
      const recipe = lnkEntityRecipeInventoryrel;

      const brands = brandEntityMappings.map(({ brandName }) => brandName).join(',');

      const storageAreaName = get(
        storageAreasById[storageAreaId],
        'name',
        i18next.t('GENERAL.NONE_VALUE'),
      );

      const stockDate = moment
        .tz(matchedInventoryList.timestamp, matchedStore.timezone)
        .format('L');

      const unit = getEntityUnit(recipe.unit, false);

      return {
        id,
        entityId,
        storageAreaId,
        quantity,
        cost,
        totalCost: quantity * cost,
        reference: matchedInventoryList.reference,
        storeName: matchedStore.name,
        storageAreaName,
        stockDate,
        category: recipe.category,
        unit,
        brands,
        name: recipe.name,
      };
    },
  );

  return sortBy(formattedRecipeInventories, [
    (recipeInventory) => recipeInventory.storageAreaName.toLowerCase(),
    (recipeInventory) => recipeInventory.reference,
  ]);
};

/**
 * Format a given list of inventories and make sure to get all information that would be necessary for generating the export file
 *
 * @param {Object[]} inventories      - The list of inventory being processed
 * @param {Object[]} supplierProducts - The list of supplierProduct being processed
 * @param {Object[]} inventoriesLists - The list of inventoryList being processed
 *
 * @return {Object[]} The list of formatted data from the given inventories
 */
export function formatDataForXLSFile(
  inventories,
  supplierProducts,
  inventoriesLists,
  stores,
  storageAreas,
) {
  const storageAreasById = keyBy(storageAreas, 'id');

  const formattedInventories = inventories.map((inventory) => {
    const productMatched = supplierProducts.find(
      (supplierProduct) => supplierProduct.id === inventory.supplierProductId,
    );

    const inventoriesListsMatched = inventoriesLists.find(
      (inventoryList) => inventoryList.id === inventory.inventoryListId,
    );

    const packagings = get(
      inventory,
      'lnkSupplierproductInventoryrel.packagings',
      productMatched.packagings,
    );

    const masterSupplierProductPackaging = find(
      packagings,
      (packaging) => !packaging.masterSupplierProductPackagingId,
    );

    inventory.supplierProduct = {
      ...productMatched,
      packagings,
    };

    inventory.masterPackagingUnit = masterSupplierProductPackaging.unit;

    const matchedStore = stores.find(({ id }) => id === inventoriesListsMatched.storeId);

    inventory.storeName = matchedStore.name;

    inventory.reference = inventoriesListsMatched.reference;

    inventory.stockDate = moment
      .tz(inventoriesListsMatched.timestamp, matchedStore.timezone)
      .format('L');

    inventory.supplierName = capitalizeFirstLetter(
      (productMatched.lnkSupplierSupplierproductrel.name || '').toLowerCase(),
    );

    inventory.category = productMatched.category || i18next.t('GENERAL.OTHER');

    inventory.subCategory = productMatched.subCategory
      ? capitalizeFirstLetter(productMatched.subCategory.toLowerCase())
      : i18next.t('GENERAL.OTHER');

    inventory.productName = productMatched.name;

    inventory.storageAreaName = get(
      storageAreasById[inventory.storageAreaId],
      'name',
      i18next.t('GENERAL.NONE_VALUE'),
    );

    return inventory;
  });

  const filteredInventories = formattedInventories.reduce((result, inventory) => {
    if (inventory.tot == null) {
      return result;
    }

    result.push({
      ...inventory,
      productPrice: inventory.price,
      productTotalCost: inventory.price * inventory.tot,
    });

    return result;
  }, []);

  return filteredInventories
    .sort((a, b) => a.subCategory.localeCompare(b.subCategory))
    .sort((a, b) => a.supplierName.localeCompare(b.supplierName))
    .sort((a, b) => a.reference.localeCompare(b.reference));
}

/**
 * Update the progress value from the elements that are still left to treat
 *
 * @param {String[]} inventoryListIds           - The list of inventories ids that are still to be treated
 * @param {Number} inventoriesToExportCount - The number of inventories to export
 * @param {Function} setProgress            - Method to set the local state of the progress value
 *
 * @return {void}
 */
export function updateProgress(inventoryListIds, inventoriesToExportCount, setProgress) {
  const updatedProgress = 100 - (inventoryListIds.length / inventoriesToExportCount) * 100;

  setProgress(updatedProgress);
}

export async function fetchInventoriesData(inventoriesListsIds, stores, clientId) {
  const { inventories, supplierProducts, inventoryLists, recipesInventories } =
    await inventoryListService.getInventoriesExportDataByIds(inventoriesListsIds);

  const storageAreas = await storageAreaService.getStorageAreasByClientId();

  const supplierProductInventories = formatDataForXLSFile(
    inventories,
    supplierProducts,
    inventoryLists,
    stores,
    storageAreas,
  );

  const recipeInventories = getRecipeInventoriesData(
    inventoryLists,
    stores,
    storageAreas,
    recipesInventories,
  );

  return { supplierProductInventories, recipeInventories };
}

/**
 * Handle the progressive loading of inventories from the list of inventoryListIds that are still to fetch
 *
 * @returns {void}
 */
export async function loadInventoriesByBatchOfIds({
  inventoryListIds,
  detailedInventoryList,
  setLoading,
  setTitle,
  setProgress,
  setInventoriesListIds,
  setDetailedInventoryList,
  stores,
  inventoriesToExportCount,
  storeName,
  currency,
  isCentralKitchenView,
  clientId,
  recipeInventories,
  setRecipeInventories,
}) {
  if (!inventoryListIds.length && !detailedInventoryList.length && !recipeInventories.length) {
    return;
  }
  const date = moment.tz(getUserTimezone()).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

  // If no more ids, then all data has been fetched
  if (!inventoryListIds.length) {
    let inventoriesToExport = detailedInventoryList;
    let recipeInventoriesToExport = recipeInventories;

    let index = 1;

    const hasManyFiles =
      inventoriesToExport.length > MAX_SHEET_ELEMENT_SIZE ||
      recipeInventoriesToExport.length > MAX_SHEET_ELEMENT_SIZE;

    while (inventoriesToExport.length > 0 || recipeInventoriesToExport.length > 0) {
      const inventoriesForSheet = inventoriesToExport.splice(0, MAX_SHEET_ELEMENT_SIZE);
      const recipeInventoriesForSheet = recipeInventoriesToExport.splice(0, MAX_SHEET_ELEMENT_SIZE);

      const fileName = i18next.t('STOCKS.STOCKS.LIST_EXPORT_SHEET_NAME_INVENTORY_PLURAL');

      exportInventories(
        inventoriesForSheet,
        recipeInventoriesForSheet,
        hasManyFiles ? `${fileName}-${date}-${index}` : `${fileName}-${date}`,
        storeName,
        false,
        currency,
        isCentralKitchenView,
      );

      index++;
    }

    return exportReady(setLoading, setTitle);
  }

  const selectedInventoryListIds = inventoryListIds.splice(0, BATCH_INVENTORIES_TO_FETCH_AT_ONCE);

  // Perform request
  try {
    const inventoriesExportData = await fetchInventoriesData(
      selectedInventoryListIds,
      stores,
      clientId,
    );

    const inventoryItemsList = [...detailedInventoryList].concat(
      inventoriesExportData.supplierProductInventories,
    );

    setDetailedInventoryList(inventoryItemsList);

    const recipeInventoryItemsList = [...recipeInventories].concat(
      inventoriesExportData.recipeInventories,
    );

    setRecipeInventories(recipeInventoryItemsList);

    updateProgress(inventoryListIds, inventoriesToExportCount, setProgress);

    setInventoriesListIds(inventoryListIds);
  } catch {
    exportFailure(setLoading, setTitle);
  }
}

/**
 * Close the modal
 *
 * @returns {void}
 */
export function exitModal(setLoading, closeModal) {
  setLoading(false);

  closeModal();
}

/**
 * Set component with export success state
 *
 * @returns {void}
 */
export function exportReady(setLoading, setTitle) {
  setTitle(i18next.t('STOCKS.STOCKS.LIST_EXPORT_TITLE_READY'));
  setLoading(false);
}

/**
 * Set component with export failure state
 *
 * @returns {void}
 */
export function exportFailure(setLoading, setTitle) {
  setTitle(i18next.t('STOCKS.STOCKS.LIST_EXPORT_TITLE_FAILURE'));
  setLoading(false);
}

export const InventoryExportContentModal = (props) => {
  const {
    currency,
    client: { storeName, clientId },
    params: { title, inventories, isCentralKitchenView },
    closeModal,
    stores,
  } = props;

  const [progress, setProgress] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [titleModal, setTitle] = useState(title || '');
  const [inventoriesListIds, setInventoriesListIds] = useState([]);
  const [detailedInventoryList, setDetailedInventoryList] = useState([]);
  const [recipeInventories, setRecipeInventories] = useState([]);

  useEffect(() => {
    const inventoryListIds = inventories.map((inventory) => inventory.id);

    setInventoriesListIds(inventoryListIds);
  }, [inventories]);

  useEffect(() => {
    loadInventoriesByBatchOfIds({
      inventoryListIds: [...inventoriesListIds],
      detailedInventoryList,
      setLoading,
      setTitle,
      setProgress,
      setInventoriesListIds,
      setDetailedInventoryList,
      stores,
      inventoriesToExportCount: inventories.length,
      storeName,
      currency,
      isCentralKitchenView,
      clientId,
      recipeInventories,
      setRecipeInventories,
    });
  }, [inventoriesListIds]);

  return (
    <ExportModalContent
      {...props}
      closeModal={closeModal}
      exitModal={exitModal}
      isLoading={isLoading}
      progress={progress}
      setLoading={setLoading}
      titleModal={titleModal}
    />
  );
};

const mapStateToProps = (state) => ({
  currency: state.baseReducer.currency,
  stores: getIsCentralMode()
    ? getCentralKitchenStores(state.baseReducer.activeStores)
    : getSalesPointStores(state.baseReducer.activeStores),
  client: getClientInfo(state.baseReducer.user),
});

export default connect(mapStateToProps)(InventoryExportContentModal);
