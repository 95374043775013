import { SingleDatePicker as DatePicker } from 'react-dates';
import i18next from 'i18next';
import moment from 'moment-timezone';
import MomentPropTypes from 'react-moment-proptypes';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { getUserTimezone } from '../../utils/date';

import { DATE_DISPLAY_FORMATS, DATE_PICKER_DOT_COLOR } from '../constants';
import { DatePickerInputIcon } from '../DatePickerIcon';
import { renderCustomNavIconLeft, renderCustomNavIconRight } from '../utils';
import { renderDatePickerModalInfo } from '../DatePickerModalInformation';
import DatePickerDay from '../DatePickerDay';

import { Container } from './styledComponents';

/**
 * A single day DatePicker.
 */

export const SingleDatePicker = (props) => {
  const {
    date,
    displayFormat,
    authorizedDates,
    warnedDates,
    forbiddenDates,
    clickableForbiddenDates,
    highlightedDates,
    maxFutureDate,
    maxPastDate,
    disabled,
    isErrorState,
    showClearDate,
    onFocusChange,
    onDateChange,
    placeholder,
    calendarInfo,
    customStyle,
    onNextMonthClick,
    onPrevMonthClick,
    numberOfMonths,
    width,
    timezone,
  } = props;

  const [isFocused, setIsFocused] = useState(false);

  const triggerFocused = (focused) => {
    if (onFocusChange) {
      onFocusChange(focused);
    }

    setIsFocused(focused);
  };

  const isOutsideRange = (day) => {
    const currentDay = day.clone();

    if (
      !clickableForbiddenDates &&
      forbiddenDates.some((disabledDay) =>
        currentDay.startOf('day').isSame(disabledDay.startOf('day')),
      )
    ) {
      return true;
    }

    if (maxPastDate && !maxFutureDate) {
      const maxPast = maxPastDate.clone();
      return currentDay.startOf('day').isBefore(maxPast.startOf('day'));
    }

    if (!maxPastDate && maxFutureDate) {
      /**
       * Re-parsing in order to correctly compare days
       * see https://github.com/moment/moment/issues/3957#issuecomment-301261200 for reference
       *
       * Issue was, for exemple
       * (2023-07-21T12:00:00+02:00).isAfter(2023-07-20T05:45:15-10:00)
       * would not return true.
       */

      const startOfCurrentDay = moment.utc(currentDay, 'YYYY-MM-DD').startOf('day');
      const endOfMaxFutureDate = moment.utc(maxFutureDate, 'YYYY-MM-DD').endOf('day');

      return startOfCurrentDay.isAfter(endOfMaxFutureDate);
    }

    if (maxPastDate && maxFutureDate) {
      const maxPast = maxPastDate.clone();
      const maxFuture = maxFutureDate.clone();
      return !currentDay
        .startOf('day')
        .isBetween(maxPast.subtract(1, 'days').endOf('day'), maxFuture.endOf('day'));
    }

    return false;
  };

  const renderDay = (day) => {
    const currentDay = day.clone();

    /**
     * Highlights the current day in the user's local timezone
     * Using a format('L') because:
     * currentDay.isSame(moment.tz(getUserTimezone()), 'day') returns false if there is a large difference in timezone, for example:
     * the DatePicker's day         as a moment object is 2023-06-09T12:00:00-10:00
     * moment.tz(getUserTimezone()) as a moment object is 2023-06-09T09:04:04+02:00
     * We want to highlight the 2023-06-09 in the DatePicker, while currentDay.isSame(moment.tz(getUserTimezone()), 'day')
     * will return true for 2023-06-08
     * */

    const isToday = currentDay.format('L') === moment.tz(getUserTimezone()).format('L');

    if (
      forbiddenDates.some((forbiddenDay) =>
        currentDay.startOf('day').isSame(forbiddenDay.startOf('day')),
      )
    ) {
      return (
        <DatePickerDay
          color={DATE_PICKER_DOT_COLOR.RED}
          day={currentDay}
          hasCircle={isToday}
          hasDot={true}
        />
      );
    }

    if (
      warnedDates.some((warnedDay) => currentDay.startOf('day').isSame(warnedDay.startOf('day')))
    ) {
      return (
        <DatePickerDay
          color={DATE_PICKER_DOT_COLOR.ORANGE}
          day={currentDay}
          hasCircle={isToday}
          hasDot={true}
        />
      );
    }

    if (
      authorizedDates.some((authorizedDay) =>
        currentDay.isSame(moment.tz(authorizedDay, timezone), 'day'),
      )
    ) {
      return (
        <DatePickerDay
          color={DATE_PICKER_DOT_COLOR.GREEN}
          day={currentDay}
          hasCircle={isToday}
          hasDot={true}
        />
      );
    }

    if (
      highlightedDates.some((forbiddenDay) =>
        currentDay.startOf('day').isSame(forbiddenDay.startOf('day')),
      )
    ) {
      return (
        <DatePickerDay
          color={DATE_PICKER_DOT_COLOR.RED}
          day={currentDay}
          hasCircle={isToday}
          hasDot={true}
        />
      );
    }

    return <DatePickerDay day={currentDay} hasCircle={isToday} />;
  };

  return (
    <Container
      calendarInfo={!!calendarInfo}
      disabled={disabled}
      focused={isFocused}
      isErrorState={isErrorState}
      style={customStyle}
      width={width}
    >
      <DatePicker
        customInputIcon={DatePickerInputIcon(date)}
        date={date}
        disabled={disabled}
        displayFormat={displayFormat}
        focused={isFocused}
        hideKeyboardShortcutsPanel={true}
        initialVisibleMonth={() =>
          (date ? moment.tz(date, timezone).startOf('day') : moment.tz(timezone)).startOf('day')
        }
        isOutsideRange={(day) => isOutsideRange(day)}
        navNext={renderCustomNavIconRight()}
        navPrev={renderCustomNavIconLeft()}
        noBorder={true}
        numberOfMonths={numberOfMonths}
        placeholder={placeholder}
        readOnly={true}
        renderCalendarInfo={() => renderDatePickerModalInfo(calendarInfo, timezone)}
        renderDayContents={(day) => renderDay(day)}
        showClearDate={showClearDate}
        onDateChange={(date) => onDateChange(date)}
        onFocusChange={({ focused }) => triggerFocused(focused)}
        onNextMonthClick={onNextMonthClick}
        onPrevMonthClick={onPrevMonthClick}
      />
    </Container>
  );
};

SingleDatePicker.propTypes = {
  date: MomentPropTypes.momentObj, // current selected date
  disabled: PropTypes.bool, // is the datepicker disabled
  displayFormat: PropTypes.oneOf(Object.values(DATE_DISPLAY_FORMATS)), // date format in input
  forbiddenDates: PropTypes.arrayOf(MomentPropTypes.momentObj), // Array of date that are forbidden to be selected (have a red dot)
  clickableForbiddenDates: PropTypes.bool, // are the forbidden dates clickable
  warnedDates: PropTypes.arrayOf(MomentPropTypes.momentObj), // Array of date that are warned to be selected (have a orange dot)
  authorizedDates: PropTypes.arrayOf(MomentPropTypes.momentObj), // Array of date that are authorized to be selected (have a green dot)
  highlightedDates: PropTypes.arrayOf(MomentPropTypes.momentObj), // Array of date who need to be highlighted (with a red dot)
  maxFutureDate: MomentPropTypes.momentObj, // maximum selectable date in the Future
  maxPastDate: MomentPropTypes.momentObj, // maximum selectable date in the Past
  onDateChange: PropTypes.func.isRequired, // function called when date changes
  onFocusChange: PropTypes.func, // function called when focused changes
  placeholder: PropTypes.string, // Placeholder to display in input
  calendarInfo: PropTypes.shape({
    dotsInfomations: PropTypes.arrayOf(
      PropTypes.shape({
        dotColor: PropTypes.oneOf(Object.values(DATE_PICKER_DOT_COLOR)),
        dotInfo: PropTypes.string,
      }), // array of object {dotColor, dotInfo} which contains information display for a dot color
    ),
    globalInfo: PropTypes.string, // A string containing a global information on datePicker
    //renderTooltip: PropTypes.func, // TO BE IMPLEMENTED function which return a tooltip content
  }), // Object containing information to display in modal
  onNextMonthClick: PropTypes.func, // function called when switch to next month
  onPrevMonthClick: PropTypes.func, // function called when switch to previous month
  customStyle: PropTypes.shape(), // an object containing specific style for datePicker
  numberOfMonths: PropTypes.number, // number of month to display
  width: PropTypes.number, // width of the input
  isErrorState: PropTypes.bool, // should be displayed with error state
  showClearDate: PropTypes.bool, // should display cross to delete value
  timezone: PropTypes.string,
};

SingleDatePicker.defaultProps = {
  date: null,
  customStyle: {},
  disabled: false,
  displayFormat: DATE_DISPLAY_FORMATS.CONDENSED_DAY_DATE_MONTH,
  forbiddenDates: [],
  clickableForbiddenDates: false,
  warnedDates: [],
  authorizedDates: [],
  highlightedDates: [],
  maxFutureDate: null,
  maxPastDate: null,
  placeholder: i18next.t('GENERAL.DATEPICKER_PLACEHOLDER'),
  calendarInfo: null,
  numberOfMonths: 1,
  isErrorState: false,
  showClearDate: false,
  timezone: 'Europe/Paris',
  width: 240,
};
