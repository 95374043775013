import { APIcaller, APIcallerv2 } from './APIcaller';

import { CATEGORY_TYPES_OBJECT } from '@commons/constants/categoryTypes';

/**
 * Central Kitchen - Stores
 */
const getkitchenSupplierByStoreIds = (storeIds) =>
  APIcaller.apiPost(`/admin/central/suppliers-by-store`, { storeIds });

/**
 * @typedef SalesPointInfo
 * @property {uuid} id
 * @property {string} name
 *
 * @typedef KitchenSupplierInfo
 * @property {uuid} supplierId
 * @property {SalesPointInfo[]} suppliedSalespointStores
 *
 * @returns {Object.<uuid, KitchenSupplierInfo>}
 */
const getSalesPointsByKitchenStoreIds = (kitchenStoreIds) =>
  APIcaller.apiPost(`/admin/central/salespoints-by-store`, { kitchenStoreIds });

/**
 * Central Kitchen - Store Supplier Profile Mappings
 */
// SSPFMs stands for Store Supplier Profile Mapping
const getCentralKitchenSSPFMsOfUser = () => APIcallerv2.apiGet('/central/stores');

/**
 * Central Kitchen - Products
 */
const getCentralKitchenProductCategoriesByClientId = () =>
  APIcallerv2.apiGet(`/categories?type=${CATEGORY_TYPES_OBJECT.CENTRAL_KITCHEN_PRODUCT}`);

const createKitchenProduct = async (payload) =>
  APIcaller.apiPost(`/admin/central/create/product`, { ...payload });

const getCentralKitchenSupplierProductsByProductId = (productId) =>
  APIcallerv2.apiGet(`/central/products/${productId}/supplier-products`);

const updateCentralKitchenProduct = async (product) =>
  APIcaller.apiPatch('/admin/central/product/update', product);

const getCentralKitchenProductsOfClient = () => APIcallerv2.apiGet(`/central/products`);

const deleteCentralKitchenProductsByIds = (clientId, productIds) =>
  APIcaller.apiDelete(`/admin/central/products`, { clientId, productIds });

const disableKitchenProductByIds = async (productIds) =>
  APIcaller.apiPatch('/admin/central/products/disable', { productIds });

const enableKitchenProductByIds = async (productIds) =>
  APIcaller.apiPatch('/admin/central/products/enable', { productIds });

/**
 * Central Kitchen - Recipes
 */
const getKitchenRecipesOfClient = (
  clientId,
  computeRecipesCost = false,
  onlyAvailableInStocks = false,
) =>
  APIcaller.apiPost(`/admin/central/recipes`, {
    clientId,
    computeRecipesCost,
    onlyAvailableInStocks,
  });

const getCentralKitchenRecipesCategoriesOfClient = () =>
  APIcallerv2.apiGet(`/categories?type=${CATEGORY_TYPES_OBJECT.CENTRAL_KITCHEN_RECIPE}`);

const createKitchenRecipe = (
  clientId,
  {
    img,
    recipePreparationSteps,
    name,
    unit,
    value,
    category,
    hasMultipleChannels,
    hasStock,
    hasLoss,
    compositions,
    brandIds,
    inventoryListTemplates,
    categoryId,
  },
) =>
  APIcaller.apiPost('/admin/central/create/recipe', {
    recipe: {
      name,
      category,
      unit,
      hasMultipleChannels,
      hasStock,
      hasLoss,
      img,
      recipePreparationSteps,
      value,
      brandIds,
      inventoryListTemplates,
      categoryId,
    },
    compositions,
    clientId,
  });

const patchKitchenRecipeById = ({
  id,
  img,
  recipePreparationSteps,
  name,
  unit,
  value,
  category,
  categoryId,
  hasMultipleChannels,
  hasStock,
  hasLoss,
  compositions,
  storeIds,
  brandIds,
  inventoryListTemplates,
}) =>
  APIcaller.apiPatch(`/admin/central/recipes/${id}`, {
    recipe: {
      name,
      category,
      categoryId,
      unit,
      hasMultipleChannels,
      hasStock,
      hasLoss,
      img,
      recipePreparationSteps,
      value,
      brandIds,
      inventoryListTemplates,
    },
    compositions,
    storeIds,
  });

const getKitchenRecipesByCategoriesForRecipeComposition = () =>
  APIcallerv2.apiGet(`/central/recipes/composition-choices`);

const getCentralKitchenRecipeProductionMappingsByEntityId = (recipeId) =>
  APIcallerv2.apiGet(`/central/recipes/${recipeId}/stores`);

const getCentralKitchenRecipeProductionMappingsByEntityIds = async (entityIds) =>
  APIcaller.apiPost(`/admin/central/recipes/productions`, { entityIds });

const patchCentralKitchenRecipesCategories = async (recipeIds, categoryId) =>
  APIcaller.apiPatch(`/admin/central/recipes/categories`, { recipeIds, categoryId });

/**
 * Central Kitchen - Recipe Inventories
 */

const getRecipeInventoryDatesByStoresIds = async (storeIds, startDate, endDate) =>
  APIcaller.apiPost('/central-kitchen/stocks/inventories/recipes/dates', {
    storeIds,
    startDate,
    endDate,
  });

const getRecipesPastAnalyticsList = async (storeId, date, categoryIds, isStrategic = false) =>
  APIcaller.apiPost('/central-kitchen/analytics/past/recipes', {
    storeId,
    date,
    categoryIds,
    isStrategic,
  });

const getRecipePastAnalyticsGraph = async (storeId, recipeId, date) =>
  APIcaller.apiPost(`/central-kitchen/analytics/past/recipes/${recipeId}/graph`, {
    storeId,
    date,
  });

const getRecipePastAnalyticsLastRecipeInventories = async (storeId, recipeId, date) =>
  APIcaller.apiGet(
    `/central-kitchen/analytics/past/store/${storeId}/recipe/${recipeId}/recipe-inventories`,
    {
      date,
    },
  );

// Central Kitchen - Production
const getMappedRecipesListByStoreId = async (storeId) =>
  APIcallerv2.apiGet(`/central/stores/${storeId}/recipes`);

const getRecipeProductionByStoreIdAndDate = async (storeId, date) =>
  APIcallerv2.apiGet(`/central/production/${storeId}`, { date });

const createRecipeProduction = async (storeId, entityId, date, quantity) =>
  APIcaller.apiPost(`/production/central-kitchen/stores/${storeId}/production`, {
    entityId,
    date,
    quantity,
  });

const getRecipesProductionDates = async (storeId, startDate, endDate) =>
  APIcallerv2.apiGet(`/central/production/${storeId}/dates`, {
    startDate,
    endDate,
  });

const getProductionRecommendations = async (
  centralKitchenId,
  salesPointStoreIds,
  startDate,
  endDate,
) =>
  APIcaller.apiPost(`/production/central-kitchen/stores/${centralKitchenId}/recommendations`, {
    salesPointStoreIds,
    startDate,
    endDate,
  });

const getProductionPlanning = async (centralKitchenId, startDate, endDate) =>
  APIcaller.apiPost(`/production/central-kitchen/production-planning`, {
    storeId: centralKitchenId,
    startDate,
    endDate,
  });

const patchCentralKitchenProductCategories = async (productIds, itemId, itemType) =>
  APIcaller.apiPatch(`/admin/central/products/categories-sub-categories`, {
    productIds,
    itemId,
    itemType,
  });

export default {
  // Central Kitchen - Stores
  getkitchenSupplierByStoreIds,
  getSalesPointsByKitchenStoreIds,

  // Central Kitchen - Store Supplier Profile Mappings
  getCentralKitchenSSPFMsOfUser,

  // Central Kitchen - Products
  getCentralKitchenProductCategoriesByClientId,
  createKitchenProduct,
  updateCentralKitchenProduct,
  getCentralKitchenProductsOfClient,
  deleteCentralKitchenProductsByIds,
  disableKitchenProductByIds,
  enableKitchenProductByIds,
  patchCentralKitchenProductCategories,

  // Central Kitchen - Recipes
  getKitchenRecipesOfClient,
  getCentralKitchenRecipesCategoriesOfClient,
  createKitchenRecipe,
  getCentralKitchenSupplierProductsByProductId,
  patchKitchenRecipeById,
  getKitchenRecipesByCategoriesForRecipeComposition,
  getCentralKitchenRecipeProductionMappingsByEntityId,
  getCentralKitchenRecipeProductionMappingsByEntityIds,
  patchCentralKitchenRecipesCategories,

  // Recipe Inventories
  getRecipeInventoryDatesByStoresIds,
  getRecipesPastAnalyticsList,
  getRecipePastAnalyticsGraph,
  getRecipePastAnalyticsLastRecipeInventories,

  // Central Kitchen - Production
  getMappedRecipesListByStoreId,
  getRecipeProductionByStoreIdAndDate,
  createRecipeProduction,
  getRecipesProductionDates,
  getProductionRecommendations,
  getProductionPlanning,
};
