import axios from '../core/http';

import { APIcaller, APIcallerv2 } from './APIcaller';
import { config } from '../config';

const baseApiUrl = `${config.baseApiUrl}/v1`;

const getForecastsOfStore = (storeId, startDate, endDate) =>
  APIcallerv2.apiGet(`/stores/${storeId}/forecast`, { startDate, endDate });

const updateOrCreateMultipleForecastsByDate = (params) => {
  let newForecastsArray = [];
  const promiseArray = params.map((forecast) =>
    fetch(baseApiUrl + '/forecast/custom-patch-for-dates', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
      body: JSON.stringify(forecast),
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((result) => {
        newForecastsArray = newForecastsArray.concat(result);
      }),
  );
  return Promise.all(promiseArray).then(() => newForecastsArray);
};

const checkForecastsValid = (storeId, startDate, endDate) =>
  axios
    .get(`${baseApiUrl}/admin/stores/${storeId}/forecasts/valid`, {
      params: {
        startDate,
        endDate,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then((response) => response.data);

export const getPastProductMix = (storeIds, startDate, endDate) =>
  APIcaller.apiPost('/forecast/product-mix/past', { storeIds, startDate, endDate });

export const getForecastsByStoreIdAndDates = (storeIds, startDate, endDate) =>
  APIcaller.apiPost('/forecast/stores', { storeIds, startDate, endDate });

export const forecast = {
  getForecastsOfStore,
  updateOrCreateMultipleForecastsByDate,
  checkForecastsValid,
  getPastProductMix,
  getForecastsByStoreIdAndDates,
};
