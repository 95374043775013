import { dropWhile, get, groupBy, isEmpty, keyBy, sumBy, times } from 'lodash';
import i18next from 'i18next';
import moment from 'moment';

import { capitalizeFirstLetter } from '@commons/utils/format';
import { CATEGORY_TYPES_OBJECT, getPropertyNoneValue } from '@commons/constants/categoryTypes';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { formatNumberToExcelUsage } from '@commons/DisplayNumber';

import clientService from '@services/client';

import { getEntityUnit } from '@stocks/utils';
import { PAST_STOCKS_TYPE } from '@stocks/utils/constants';

import { getGraphBasicConfiguration, GRAPH_COLORS, GRAPH_TYPE } from './getGraphBasicConfiguration';

export const ENUM_CUSTOM_FILTERS = {
  IS_STRATEGIC: 'isStrategic',
  SINGLE_SELECT_CATEGORY: 'singleSelectCategory',
};

export const METRICS = [
  { name: 'GENERAL.METRIC_TURNOVER_CURRENCY', key: 'turnover' },
  { name: 'GENERAL.UNIT', key: 'unit' },
];

const NUMBER_OF_DAYS_TO_DISPLAY = 15;

export const getCustomFilterIsStrategic = (isStrategic, setIsStrategic) => ({
  id: ENUM_CUSTOM_FILTERS.IS_STRATEGIC,
  label: i18next.t('STOCKS.CURRENT_STOCKS.FILTERS_STRATEGIC_ONLY'),
  value: isStrategic,
  setToggleValue: setIsStrategic,
});

export const getCustomFilterSingleCategory = (
  categoriesToChoose,
  selectedCategory,
  setSelectedCategory,
) => ({
  id: ENUM_CUSTOM_FILTERS.SINGLE_SELECT_CATEGORY,
  icon: '/images/inpulse/category-dmgrey-small.svg',
  disabledIcon: '/images/inpulse/category-white-small.svg',
  itemSelectedIcon: '/images/inpulse/category-ipblack-small.svg',
  list: categoriesToChoose,
  defaultSelectedItem: null,
  selectedItem: selectedCategory,
  setSelectedItem: setSelectedCategory,
  placeholder: i18next.t('GENERAL.CATEGORY'),
  reliedOnFilter: {
    id: ENUM_CUSTOM_FILTERS.IS_STRATEGIC,
    filterValueKey: 'value', // can be 'selectedStores', 'selectedItem' (if customSingleDropdown), 'value' (if customToggle) etc..
    reliedOnValue: true, // What the filter's value should be for this current one to be disabled, here the category dropdown should be disabled if isStrategic is true
  },
  isRequired: false,
});

export const fetchRetailersAndSet = async (setRetailers, showErrorMessage) => {
  try {
    const clientRetailers = await clientService.getRetailersOfClient();

    setRetailers(clientRetailers);

    return clientRetailers;
  } catch (error) {
    showErrorMessage(i18next.t('PRODUCTION.PRODUCTION.TOP_ERROR_MESSAGE_RETAILERS'));
    return [];
  }
};

export const fetchLocationsAndSet = async (setLocations, showErrorMessage) => {
  try {
    const clientLocations = await clientService.getLocationsByClientId();

    setLocations(clientLocations);

    return clientLocations;
  } catch (err) {
    showErrorMessage(i18next.t('HOME.ACTIVITY_REPORT_FETCH_LOCATIONS_FAILURE'));
    return [];
  }
};

export const fetchCategoriesAndSet = async (setCategoriesToChoose, showErrorMessage) => {
  try {
    const { categories } = await clientService.getCategoriesAndSubCategories(
      CATEGORY_TYPES_OBJECT.INGREDIENT,
    );

    const formattedCategories = categories.map(({ name, id }) => ({
      id,
      name: name || getPropertyNoneValue(),
    }));

    setCategoriesToChoose(formattedCategories);

    return formattedCategories;
  } catch (err) {
    showErrorMessage(i18next.t('STOCKS.CURRENT_STOCKS.ANALYSIS_CATEGORIES_FETCH_ERROR'));
    return [];
  }
};

const addEmptyDatesForPastStocks = (stocks, entityEvents, selectedDate, stockConvention) => {
  /*
    This condition is necessary to add empty dates to the stock array because we want to constantly show 15
    dates in the graph and we sometime have less than 15 days of data resulting in a graph with less than 15
    dates
  */
  const currentNumbersOfDaysToDisplay =
    stockConvention === 'end' ? NUMBER_OF_DAYS_TO_DISPLAY : NUMBER_OF_DAYS_TO_DISPLAY + 1;

  const stocksLength = entityEvents.stock.length;

  if (stocksLength < currentNumbersOfDaysToDisplay && stocksLength > 0) {
    const numberOfDateToInsert = currentNumbersOfDaysToDisplay - stocksLength;

    times(numberOfDateToInsert, (index) => {
      const numberOfDaysToSubstract = stocksLength + index;

      const dateToPush = moment(selectedDate)
        .subtract(numberOfDaysToSubstract, 'd')
        .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

      stocks.unshift({
        date: dateToPush,
      });
    });
  }

  return stocks;
};

/**
 * Format the given data to get an object that will be used for the nestedList graph
 *
 * @param {String} metricKey - The key that we want to use, here 'Unit' or 'Turnover' (for example if we want to
 *                          display the realStockUnits of the realStockTurnover)
 * @param {String::'YYYY-MM-DD'} selectedDate
 * @param {String} type - The type of stock we want to format (for example past of current)
 * @param {Object} entityEvents - The object containing all the stockEvents associated to the entity
 * @param {String} entityName
 * @param {String::'YYYY-MM-DD'} selectedDateMinusFifteenDays - A date corresponding to the selected date minus 15 days that
 *                                                              is used to get data for the past 15 days
 *
 * @returns {graph{}} The formatted date to display in the graph of the nestedList
 */
export const formatDataForStockGraph = (
  metricKey,
  selectedDate,
  type,
  entityEvents,
  entityName = '',
  selectedDateMinusFifteenDays = null,
  stockConvention,
  currency = {},
  shouldOnlyDisplayRealStocks,
  pastStockType,
) => {
  const capitalizedMetricKey = capitalizeFirstLetter(metricKey);

  const ordersOfEntityByDate = groupBy(entityEvents.orderOfEntity, 'startOrderDate');

  const rushOrdersOfEntityByDate = groupBy(entityEvents.rushOrderOfEntity, 'rushOrderDate');

  let formattedStocks = dropWhile(entityEvents.stock, (stock) =>
    [GRAPH_TYPE.PAST, GRAPH_TYPE.PAST_CC].includes(type)
      ? moment(stock.date).isBefore(moment(selectedDateMinusFifteenDays))
      : moment(stock.date).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY) !== selectedDate,
  );

  const inventoryStockOfEntity = entityEvents.inventoryStockOfEntity || [];

  const inventoryStockOfEntityKeyByDate = keyBy(
    inventoryStockOfEntity.map((inventoryStock) => ({
      ...inventoryStock,
      inventoryDate:
        stockConvention === 'end'
          ? inventoryStock.inventoryDate
          : moment(inventoryStock.inventoryDate)
              .subtract(1, 'day')
              .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
    })),
    'inventoryDate',
  );

  const inventoryStockOfRecipeEntity = entityEvents.inventoryStockOfRecipeEntity || [];

  const inventoryStockOfRecipeEntityKeyByDate = keyBy(
    inventoryStockOfRecipeEntity.map((inventoryStock) => ({
      ...inventoryStock,
      inventoryDate:
        stockConvention === 'end'
          ? inventoryStock.inventoryDate
          : moment(inventoryStock.inventoryDate)
              .subtract(1, 'day')
              .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
    })),
    'inventoryDate',
  );

  const transferInOfEntityByDate = keyBy(entityEvents.inventoryTransferInOfEntity, 'inventoryDate');

  const transferOutOfEntityGroupedByDate = groupBy(
    entityEvents.inventoryTransferOutOfEntity,
    'inventoryDate',
  );

  // ckso stands for centraKitchenStockOut
  const cksoOfEntityGroupedByDate = !!entityEvents.centralKitchenStockOutOfEntity
    ? groupBy(entityEvents.centralKitchenStockOutOfEntity, 'startOrderDate')
    : [];

  if ([GRAPH_TYPE.PAST, GRAPH_TYPE.PAST_CC].includes(type)) {
    formattedStocks = addEmptyDatesForPastStocks(
      formattedStocks,
      entityEvents,
      selectedDate,
      stockConvention,
    );
  }

  const supplierProductsLossOfEntity = entityEvents.supplierProductsLossOfEntity || [];

  const productsLossOfEntity = entityEvents.productsLossOfEntity || [];

  const recipeProductionsOfEntityByDate = groupBy(
    entityEvents.recipeProductionOfEntity,
    'recipeProductionDate',
  );

  const recipeConsumptionsOfEntityByDate = groupBy(
    entityEvents.recipeConsumptionOfEntity,
    'recipeProductionDate',
  );

  const recipeLossOfEntityByDate = groupBy(entityEvents.recipeLossesOfEntity, 'lossDate');

  const recipeIngredientLossOfEntityByDate = groupBy(
    entityEvents.recipeIngredientLossesOfEntity,
    'lossDate',
  );

  const unit =
    metricKey === METRICS[0].key
      ? currency.alphabeticCode
      : getEntityUnit(get(entityEvents, 'entity.unit'));

  const shouldDisplayRecipeLegend = !!(entityEvents.inventoryStockOfRecipeEntity || []).length;

  const graph = getGraphBasicConfiguration(
    unit,
    type,
    [GRAPH_TYPE.PAST, GRAPH_TYPE.PAST_CC].includes(type)
      ? i18next.t('STOCKS.PAST_STOCKS.TITLE', {
          name: entityName,
          status: i18next.t('GENERAL.ACTIVE'),
        })
      : i18next.t('ORDERS.ORDERS.FORM_STOCK_CHART_TITLE', {
          unit,
        }),
    { shouldDisplayRecipeLegend },
  );

  formattedStocks.forEach((currentStock) => {
    const x = currentStock.date;

    if (
      [GRAPH_TYPE.PAST, GRAPH_TYPE.PAST_CC].includes(type) &&
      stockConvention !== 'end' &&
      x === selectedDate
    ) {
      return;
    }

    const productSaleOfCurrentDate = get(
      entityEvents,
      `productSalesOfEntityKeyByDate[${currentStock.date}]`,
      {},
    );

    const productForecastOfCurrentDate = get(
      entityEvents,
      `productForecastOfEntityKeyByDate[${currentStock.date}]`,
      {},
    );

    // If we have a product sales for the current date, we take it, otherwise we take the product forecast
    const consumption = !isEmpty(productSaleOfCurrentDate)
      ? productSaleOfCurrentDate
      : productForecastOfCurrentDate;

    const transferIn = get(
      transferInOfEntityByDate[`${currentStock.date}`],
      `theoricalStock${capitalizedMetricKey}`,
      null,
    );

    const currentDateMultipleTransferOut = transferOutOfEntityGroupedByDate[`${currentStock.date}`];

    let currentDateAggregatedTransferOut = {};

    if (!!currentDateMultipleTransferOut) {
      currentDateAggregatedTransferOut = currentDateMultipleTransferOut.reduce((acc, transfer) => {
        if (isEmpty(acc)) {
          acc = transfer;
          return acc;
        }

        acc.totTurnover = acc.totTurnover + transfer.totTurnover;
        acc.totUnit = acc.totUnit + transfer.totUnit;

        return acc;
      }, {});
    }

    const transferOut = get(currentDateAggregatedTransferOut, `tot${capitalizedMetricKey}`, null);

    const deliveryOrder =
      sumBy(ordersOfEntityByDate[`${currentStock.date}`], `tot${capitalizedMetricKey}`) || null;
    const deliveryRushOrder =
      sumBy(rushOrdersOfEntityByDate[`${currentStock.date}`], `tot${capitalizedMetricKey}`) || null;

    const supplierProductsLossesOfEntityForCurrentDate = supplierProductsLossOfEntity.filter(
      (loss) => loss.lossDate === currentStock.date,
    );

    const formattedSupplierProductsLoss = supplierProductsLossesOfEntityForCurrentDate.reduce(
      (result, loss) => {
        result = loss[`tot${capitalizedMetricKey}`]
          ? result + loss[`tot${capitalizedMetricKey}`]
          : result;

        return result;
      },
      null,
    );

    const productsLossesOfEntityForCurrentDate = productsLossOfEntity.filter(
      (loss) => loss.lossDate === currentStock.date,
    );

    const formattedProductsLoss = productsLossesOfEntityForCurrentDate.reduce((result, loss) => {
      result = loss[`tot${capitalizedMetricKey}`]
        ? result + loss[`tot${capitalizedMetricKey}`]
        : result;

      return result;
    }, null);

    const recipeProductions =
      sumBy(
        recipeProductionsOfEntityByDate[`${currentStock.date}`],
        `tot${capitalizedMetricKey}`,
      ) || null;

    let formattedCKSO = { totUnit: null, totTurnover: null };

    if (cksoOfEntityGroupedByDate[currentStock.date]) {
      formattedCKSO = cksoOfEntityGroupedByDate[currentStock.date].reduce(
        (result, centralKitchenStockOut) => {
          result.totUnit = !!centralKitchenStockOut.totUnit
            ? result.totUnit + centralKitchenStockOut.totUnit
            : result.totUnit;

          result.totTurnover = !!centralKitchenStockOut.totTurnover
            ? result.totTurnover + centralKitchenStockOut.totTurnover
            : result.totTurnover;

          return result;
        },
        { totUnit: null, totTurnover: null },
      );
    }
    const recipeConsumptions =
      sumBy(
        recipeConsumptionsOfEntityByDate[`${currentStock.date}`],
        `tot${capitalizedMetricKey}`,
      ) || null;

    // Recipe loss
    const recipeLosses =
      sumBy(recipeLossOfEntityByDate[`${currentStock.date}`], `tot${capitalizedMetricKey}`) || null;

    // Recipe ingredient loss
    const recipeIngredientLosses =
      sumBy(
        recipeIngredientLossOfEntityByDate[`${currentStock.date}`],
        `tot${capitalizedMetricKey}`,
      ) || null;

    const allLossesForDate = [
      recipeLosses,
      recipeIngredientLosses,
      formattedSupplierProductsLoss,
      formattedProductsLoss,
    ];

    // Check for null or 0 values
    const noLossesForDate = allLossesForDate.every((loss) => !loss);
    const totalLosses = noLossesForDate ? null : sumBy(allLossesForDate, (loss) => loss || 0);

    const stockValue = {
      value:
        currentStock[`theoricalStock${capitalizedMetricKey}`] == null
          ? null
          : formatNumberToExcelUsage(
              currentStock[`theoricalStock${capitalizedMetricKey}`],
              currency.numberDecimals,
            ),
      color: [GRAPH_TYPE.PAST, GRAPH_TYPE.PAST_CC].includes(type)
        ? GRAPH_COLORS.THEORICAL
        : currentStock[`theoricalStock${capitalizedMetricKey}`] > 0
        ? GRAPH_COLORS.POSITIVE
        : GRAPH_COLORS.NEGATIVE,
      isHighlighted: currentStock[`theoricalStock${capitalizedMetricKey}`] <= 0,
    };

    const realStock = inventoryStockOfEntityKeyByDate[currentStock.date];

    if (type === GRAPH_TYPE.CURRENT && realStock) {
      stockValue.isReal = true;

      // Override graph bar value when displaying "Yesterday" content
      if (moment(selectedDate).isSame(moment(currentStock.date), 'day')) {
        stockValue.isHighlighted = false;
        stockValue.value = realStock.realStockUnit;
        stockValue.color = GRAPH_COLORS.REAL;
      }
    }

    const y = {
      stock: [stockValue],
      consumption: consumption,
      order: ordersOfEntityByDate[currentStock.date] || {},
      inventories: {
        ingredients: get(
          inventoryStockOfEntityKeyByDate[currentStock.date],
          `realStock${capitalizedMetricKey}`,
          null,
        ),
        recipes: get(
          inventoryStockOfRecipeEntityKeyByDate[currentStock.date],
          `realStock${capitalizedMetricKey}`,
          null,
        ),
      },
      transfer: {
        in: transferIn,
        out: transferOut,
        total: transferIn && transferOut ? transferIn - transferOut : null,
      },
      loss: {
        recipe: recipeLosses,
        recipeIngredient: recipeIngredientLosses,
        product: formattedProductsLoss,
        supplierProduct: formattedSupplierProductsLoss,
        total: totalLosses,
      },
      delivery: {
        order: deliveryOrder,
        rushOrder: deliveryRushOrder,
      },
      production: {
        recipe: recipeProductions,
        consumptions: recipeConsumptions,
      },
      centralKitchenStockOut: formattedCKSO,
    };

    if ([GRAPH_TYPE.PAST, GRAPH_TYPE.PAST_CC].includes(type)) {
      const stock = {
        value:
          currentStock[`realStock${capitalizedMetricKey}`] == null
            ? null
            : formatNumberToExcelUsage(
                currentStock[`realStock${capitalizedMetricKey}`],
                currency.numberDecimals,
              ),
        color: GRAPH_COLORS.REAL,
      };

      // Add overlappedStocks only if real stock is available
      if (
        [GRAPH_TYPE.PAST, GRAPH_TYPE.PAST_CC].includes(type) &&
        pastStockType !== PAST_STOCKS_TYPE.CENTRAL_KITCHEN_RECIPES &&
        stock.value !== null
      ) {
        stock.overlappedStocks = [
          {
            value: y.inventories.ingredients,
            color: GRAPH_COLORS.REAL,
          },
          {
            value: y.inventories.recipes,
            color: GRAPH_COLORS.REAL_RECIPE,
          },
        ];

        /**
         * original stock.value matched y.inventories.ingredients value
         * but we add y.inventories.recipes here to integrate stock via recipes for the stock graph value
         */
        stock.value = y.inventories.ingredients + y.inventories.recipes;
      }

      /*
        We set y.stock to [stock] because we don't want any other data, even if singleBarGraph === true
        we still have two graph bars for the same date if we have more than one recipe inventories on different dates
      */
      if (shouldOnlyDisplayRealStocks) {
        y.stock = [stock];
      } else {
        y.stock.push(stock);
      }
    }

    graph.data.push({ x, y });
  });

  if ([GRAPH_TYPE.PAST, GRAPH_TYPE.PAST_CC].includes(type)) {
    graph.hideUnit = true;
  }

  if (shouldOnlyDisplayRealStocks) {
    graph.singleBarGraph = true;
  }

  return graph;
};
