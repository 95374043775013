import i18next from 'i18next';
import parse from 'html-react-parser';
import React, { useState, useEffect } from 'react';

// Commons
import { Dropdown } from '@commons/utils/styledLibraryComponents';
import { formatTextNumberInputValue } from '@commons/utils/format';
import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_SAVE_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import InputContentModal from '@commons/Modals/InputContentModal';
import Text, { ENUM_FONTS, ENUM_COLORS } from '@commons/Text';

import { Container, RowContainer } from './styledComponents';

const ModalContent = ({
  modalNbDays,
  setModalNbDays,
  selectedStores,
  setSelectedStores,
  associatedStores,
  storeName,
  prevHorizon,
}) => {
  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [storesToDisplay, setStoresToDisplay] = useState(associatedStores);

  useEffect(() => {
    const allgroupsNamesSet = associatedStores.reduce((acc, { groups }) => {
      groups.forEach(({ name }) => {
        acc.add(name);
      });

      return acc;
    }, new Set());

    const allgroupsNames = [...allgroupsNamesSet];

    if (!allgroupsNames.length) {
      setGroups([]);
      setSelectedGroups([]);
      return;
    }

    allgroupsNames.push(i18next.t('GENERAL.SELECT_NONE_MASCULINE'));

    const allGroups = allgroupsNames.map((name, index) => ({ id: index, name }));

    setGroups(allGroups);
    setSelectedGroups(allGroups);
  }, []);

  useEffect(() => {
    if (!groups.length) {
      setStoresToDisplay(associatedStores);
      setSelectedStores(associatedStores);
      return;
    }

    const filteredStores = associatedStores.filter(({ groups: storeGroups }) => {
      if (!!storeGroups.length) {
        return storeGroups.some(({ name }) =>
          selectedGroups.some(({ name: selectedName }) => selectedName === name),
        );
      }

      return selectedGroups.some(({ name }) => name === i18next.t('GENERAL.SELECT_NONE_MASCULINE'));
    });

    setStoresToDisplay(filteredStores);
    setSelectedStores(filteredStores);
  }, [selectedGroups]);

  return (
    <Container>
      <Text color={ENUM_COLORS.DARKER} font={ENUM_FONTS.TEXT_BIG_HEIGHT_24}>
        {i18next.t('ADMIN.INGREDIENTS.BUFFER_STOCKS_MODAL_TEXT')}
      </Text>
      <RowContainer>
        {!!groups.length && (
          <Dropdown
            customStyle={{ position: 'inherit' }}
            height={'68px'}
            iconSrc={'/images/inpulse/store-black-small.svg'}
            isUniqueSelection={false}
            items={groups}
            label={i18next.t('GENERAL.GROUPS')}
            selectedItems={selectedGroups}
            isRequired
            onSelectionChange={setSelectedGroups}
          />
        )}
        <Dropdown
          customStyle={{ position: 'inherit' }}
          height={'68px'}
          iconSrc={'/images/inpulse/store-black-small.svg'}
          isUniqueSelection={false}
          items={storesToDisplay}
          label={storeName}
          selectedItems={selectedStores}
          isRequired
          onSelectionChange={setSelectedStores}
        />
        <InputContentModal
          autoFocus={true}
          errorMessage={
            modalNbDays > prevHorizon.future &&
            parse(
              i18next.t('ADMIN.INGREDIENTS.BUFFER_STOCK_NB_DAYS_HIGHER_THAN_PREV_HORIZON_ERROR', {
                prevHorizon: prevHorizon.future,
              }),
            )
          }
          label={i18next.t('FEATURE_NAME_ADMIN_STORES_BUFFER_STOCKS')}
          placeholder={i18next.t('FEATURE_NAME_ADMIN_STORES_BUFFER_STOCKS')}
          tooltipText={i18next.t('ADMIN.STORES.BUFFER_STOCK_COLUMN_TOOLTIP')}
          type="text"
          value={modalNbDays === null ? '' : modalNbDays}
          onChange={(value) => {
            const formattedValue = formatTextNumberInputValue(value, null);

            setModalNbDays(
              formattedValue !== null && formattedValue >= 0 ? Math.trunc(formattedValue) : null,
            );
          }}
        />
      </RowContainer>
    </Container>
  );
};

export const getBufferStockModalConfig = ({
  handleBufferStockModalSave,
  modalNbDays,
  setModalNbDays,
  selectedStores,
  setSelectedStores,
  associatedStores,
  storeName,
  prevHorizon,
}) => ({
  type: 'action',
  width: '560px',
  height: 'auto',
  maxHeight: '480px',
  icon: '/images/inpulse/gear-ipblack-small.svg',
  title: i18next.t('ADMIN.STORES.MANAGE_BUFFER_STOCKS_ACTIONS'),
  data: {
    modalNbDays,
    setModalNbDays,
    selectedStores,
    setSelectedStores,
    associatedStores,
    storeName,
    prevHorizon,
  },
  component: ModalContent,
  handleCloseCleanUp: () => {
    setModalNbDays(null);
    setSelectedStores(associatedStores);
  },
  actions: [
    GENERIC_MODAL_CANCEL_BUTTON(),
    {
      ...GENERIC_MODAL_SAVE_BUTTON(),
      isDisabled:
        modalNbDays === null ||
        modalNbDays === undefined ||
        modalNbDays < 0 ||
        !selectedStores.length ||
        modalNbDays > prevHorizon.future,
      handleClick: handleBufferStockModalSave,
    },
  ],
});
