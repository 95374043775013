import i18next from 'i18next';
import React from 'react';

import WhiteCardForm from '@commons/WhiteCardForm';

import { INTERNAL_INVOICE_INFOS_INPUTS } from '../utils/formInputsConfigurations';

import OrderSection from '../components/OrderSection';

import { Container, FormContainer } from './styledComponents';
import { isEmpty } from 'lodash';

const InternalInvoiceInformations = (props) => {
  const {
    store,
    supplier,
    associatedOrders,
    setAssociatedOrders,
    internalInvoiceForm,
    isSaveAlreadyTriggered,
    centralKitchens,
    salesPointStores,
    invoiceDate,
    invoiceNumber,
    isCreation,
    readOnly,
  } = props;

  return (
    <Container>
      <FormContainer>
        <WhiteCardForm
          form={internalInvoiceForm}
          inputs={INTERNAL_INVOICE_INFOS_INPUTS({
            centralKitchens,
            salesPointStores,
            invoiceDate,
            invoiceNumber,
            isCreation,
          })}
          shouldDisplayError={isSaveAlreadyTriggered}
          title={i18next.t('ADMIN.SUPPLIER_PRODUCTS.FORM_GENERAL_INFOS')}
          width="100%"
        />

        <OrderSection
          associatedOrders={associatedOrders}
          internalInvoiceForm={internalInvoiceForm}
          isInError={!readOnly && isEmpty(associatedOrders) && isSaveAlreadyTriggered}
          readOnly={readOnly}
          setAssociatedOrders={setAssociatedOrders}
          store={store}
          supplier={supplier}
        />
      </FormContainer>
    </Container>
  );
};

export default InternalInvoiceInformations;
