import axios from '../../core/http';

import { config } from '../../config';

const baseApiUrl = `${config.baseApiUrl}/v1`;

function getExcelDataModel(clientId) {
  return axios
    .get(`${baseApiUrl}/backoffice/data-model/excel?clientId=${clientId}`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

function validateExcelDataModel(dataModel) {
  return axios
    .post(
      `${baseApiUrl}/backoffice/data-model/excel/validate`,
      {
        ...dataModel,
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
      { responseType: 'blob' },
    )
    .then(function (response) {
      return { status: 'success', data: response.data };
    })
    .catch(function (error) {
      if (error.response) {
        return { status: 'error', data: error.response.data };
      }

      throw error;
    });
}

function importExcelDataModel(dataModel, clientId) {
  return axios
    .post(
      `${baseApiUrl}/backoffice/data-model/excel/import?clientId=${clientId}`,
      {
        ...dataModel,
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
      { timeout: 1800000 },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export const backoffice = {
  getExcelDataModel,
  importExcelDataModel,
  validateExcelDataModel,
};
