import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const ListViewContainer = styled.div`
  width: 100%;
  padding-top: 88px;
  height: 100%;
  display: flex;
  position: relative;

  ${({ hasForecasts }) => {
    if (!hasForecasts) {
      return css`
        filter: blur(4px);
      `;
    }
  }}
`;
