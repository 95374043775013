import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: flex-end;
`;

export const ArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const ArrowContainerIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 2px solid
    ${(props) =>
      props.isDisable ? props.theme?.colors?.greys?.light : props.theme?.colors?.greys?.darkest};
  border-radius: 50%;
  cursor: ${(props) => (props.isDisable ? 'not-allowed' : 'pointer')};

  &:hover {
    border-color: ${(props) => (!props.isDisable ? props.theme?.colors?.brand?.primary : '')};
  }

  &:active {
    border-color: ${(props) => (!props.isDisable ? props.theme?.colors?.brand?.primaryDark : '')};
  }
`;

export const ArrowIcon = styled.img`
  transform: ${(props) => (!!props.right ? 'rotate(90deg)' : 'rotate(-90deg)')};
`;

export const ChartContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  padding: 0 8px;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 10%;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
`;

export const ChartValuesContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

export const ItemColorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  border-radius: ${(props) => props.border.radius};
  border-width: ${(props) => props.border.width};
  border-color: ${(props) =>
    props.isInPeriod ? props.theme?.colors?.greys?.light : 'transparent'};
  border-style: ${(props) => (props.isInPeriod ? 'solid' : '')};
  background-color: ${(props) =>
    props.isInPeriod ? props.theme.colors?.greys?.lighter : 'transparent'};
  padding-top: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid ${(props) => props.theme?.colors?.greys?.light};
`;

export const BufferStockLine = styled.div`
  width: 100%;
  height: 1px;
  position: absolute;
  top: 0;
  // + 4 px to compensate the 5px padding-top of ItemColorContainer - 1px of border-bottom of ItemColorContainer
  top: ${(props) => `calc(100% - ${props.bufferStockLineHeight}% + 4px)`};

  background-color: ${(props) => props.theme.colors.infoRed};
`;

export const ChartValue = styled.div`
  color: ${(props) => (props.isLast ? '#FFFFFF' : props.theme.colors?.greys.darkest)};
  font: ${(props) => props.theme.fonts.textMicro};
  background-color: ${(props) => (props.isLast ? props.color : '')};
  border-radius: 4px;
  margin-bottom: 2px;
  padding: 0px 4px;
`;

export const ChartBar = styled.div`
  height: ${(props) => `${props.barHeight}%`};
  background-color: ${(props) => props.color};
  width: 14px;
  border-radius: 8px 8px 0 0;
`;

export const DisplayDayContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.isYesterdayReal &&
    css`
      position: relative;
      right: 5px;
    `}
`;

export const DisplayDay = styled.div`
  font: ${(props) =>
    props.theme.fonts?.textMicroHeight12 || `normal ${props.weight} 10px/17px proximanovaregular`};
  color: ${(props) => props.theme.colors?.greys.dark || '#a8b1c4'};
`;

export const DisplayStockIcon = styled.img`
  position: relative;
  right: 2px;
  bottom: 2px;
`;

export default {
  Container,
  ArrowContainer,
  ArrowContainerIcon,
  ArrowIcon,
  ChartContainer,
  ItemContainer,
  ItemColorContainer,
  ChartBar,
  DisplayDay,
  DisplayDayContainer,
  DisplayStockIcon,
  ChartValue,
  BufferStockLine,
  ChartValuesContainer,
};
