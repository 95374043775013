import axios from '../core/http';

import { config } from '../config';

const baseApiUrl = `${config.baseApiUrl}/v1`;

function getPriceInputsOfSupplier(clientId, supplierId) {
  return axios
    .get(`${baseApiUrl}/admin/clients/${clientId}/suppliers/${supplierId}/inputs-price`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

function updatePriceInputsOfSupplier(clientId, supplierId, priceInputs) {
  return axios
    .post(
      `${baseApiUrl}/admin/clients/${clientId}/suppliers/${supplierId}/inputs-price/batch`,
      { inputs: priceInputs },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export const supplierPriceInputsService = {
  getPriceInputsOfSupplier,
  updatePriceInputsOfSupplier,
};
