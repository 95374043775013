import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const LabelContainer = styled.div`
  height: 100%;
  min-width: 114px;

  display: flex;
  align-items: center;

  padding: 4px 0px;
  margin-left: 2px;
`;
