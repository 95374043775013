import i18next from 'i18next';
import React from 'react';

import { Tags } from '@commons/Tags';

export const getColumns = (hasCentralKitchens, hasGroups) => {
  const columns = [
    {
      id: 'name',
      name: i18next.t('GENERAL.NAME'),
      propertyKey: 'name',
      // The render method is necessary otherwise the text will overflow if the name is too long
      render: (item) => item,
    },
    {
      id: 'nbDays',
      name: i18next.t('ADMIN.STORES.BUFFER_STOCK_COLUMN_TITLE'),
      tooltipText: i18next.t('ADMIN.INGREDIENTS.BUFFER_STOCK_COLUMN_TOOLTIP'),
      propertyKey: 'nbDays',
      excludeFromSearch: true,

      render: (days) => i18next.t('ADMIN.STORES.BUFFER_STOCK_COLUMN_VALUE', { days }),
    },
  ];

  if (hasCentralKitchens) {
    columns.splice(1, 0, {
      id: 'isKitchen',
      name: i18next.t('GENERAL.STORE_TYPE'),
      propertyKey: 'isKitchen',
      isHidden: !hasCentralKitchens,
      render: (isKitchen) =>
        isKitchen ? i18next.t('GENERAL.CENTRAL') : i18next.t('GENERAL.SALE_POINT'),
    });
  }

  if (hasGroups) {
    columns.splice(hasCentralKitchens ? 2 : 1, 0, {
      id: 'groups',
      propertyKey: 'groups',
      name: i18next.t('GENERAL.GROUPS'),
      disableSort: true,
      render: (groups) => {
        if (!groups) {
          return <Tags items={[]} />;
        }

        return <Tags items={groups.map(({ name }) => name)} />;
      },
      transform: (groups) => groups.map(({ name }) => name).join(', '),
    });
  }

  return columns;
};

export const getActions = ({
  openBufferStockModal,
  exportBufferStocksXLS,
  associatedStores,
  storeName,
  hasCentralKitchens,
}) => [
  {
    label: i18next.t('ADMIN.STORES.MANAGE_BUFFER_STOCKS_ACTIONS'),
    handleClick: openBufferStockModal,
    icon: () => '/images/inpulse/gear-black-small.svg',
  },
  {
    label: i18next.t('GENERAL.EXPORT_LIST'),
    handleClick: () => exportBufferStocksXLS(associatedStores, storeName, hasCentralKitchens),
    icon: () => '/images/inpulse/file-download-black-small.svg',
  },
];

// Do not delete, this fake data is necessary to show the upgrade plan view
export const FAKE_DATA_FOR_PLAN_UPGRADE = [
  {
    id: 1,
    name: 'Paris',
    isKitchen: false,
    groups: [{ name: 'IDF' }],
    nbDays: 1,
  },
  {
    id: 2,
    name: 'Brest',
    isKitchen: false,
    groups: [{ name: 'Bretagne' }],
    nbDays: 2,
  },
  {
    id: 3,
    name: 'Angers',
    isKitchen: false,
    groups: [{ name: 'Maine-et-Loire' }, { name: 'France' }],
    nbDays: 3,
  },
  {
    id: 4,
    name: 'Marseille',
    isKitchen: false,
    groups: [{ name: 'Provence' }, { name: 'France' }],
    nbDays: 4,
  },
  {
    id: 5,
    name: 'Lyon',
    isKitchen: true,
    groups: [{ name: 'Auvergne' }],
    nbDays: 5,
  },
];
