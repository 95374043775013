import { cloneDeep, flatten, get, head, isEmpty, keyBy } from 'lodash';
import { connect } from 'react-redux';
import i18next from 'i18next';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { openGenericModal, openModal, openSmallModal } from '@actions/modal';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { Button, Dropdown, ListView } from '@commons/utils/styledLibraryComponents';
import { CHANNELS, CHANNEL_ID_KEY_BY_NAME } from '@commons/constants/channel';
import { DATE_DISPLAY_FORMATS, PRODUCTS_CALENDAR_INFO } from '@commons/DatePickers/constants';
import { DeepsightComponentLoader } from '@commons/DeepsightComponents';
import { formatNumber } from '@commons/DisplayNumber';
import { PastDayDatePicker } from '@commons/DatePickers/PastDayDatePicker';
import { STANDARD_LISTVIEW_PADDING } from '@commons/constants/listViewProps';
import DisplayImgInFullScreen from '@commons/DisplayImgInFullScreen';

import {
  canCorrectProductLoss,
  canCorrectSupplierProductLoss,
  canCreateProductLoss,
  canCreateSupplierProductLoss,
} from '@selectors/actions/lossActions';
import { getAuthorizedActions } from '@selectors/featureProps';
import { getClientInfo } from '@selectors/client';
import { getSalesPointStores } from '@selectors/stores';

import { getChannels } from '@services/channel';
import { lossService } from '@services/loss';

import theme from '@theme';

import EmptyState from '@stocks/StocksInventories/StocksInventoriesTransfer/components/TransferForm/components/EmptyState';

import {
  formatPayloadIntoLossData,
  getDatesWithLossesForMonth,
  handleLossesExport,
} from '../../utils/commons';

import LossForm from '../LossForm';

import { Container, HeaderContainer, TotalLossContainer, TotalLossValue } from './styledComponents';
import {
  DELETE_LOSSES_FAILURE,
  DELETE_LOSSES_SUCCESS,
  EMPTY_STATE_ICON,
  EXPORT_FAILURE,
  LOSS_TYPE,
  RETRIEVE_DATES_FAILURE,
  RETRIEVE_LOSSES_FAILURE,
} from './constants';
import { getActionsByType, getRowActions } from './getActions';
import { getColumnsByType } from './getColumns';
import ExportLossesModal from './ExportLossesModal';

const LossListView = (props) => {
  const {
    // --- mapped state props
    user,
    stores,
    client: { hasMultipleChannels, defaultChannelId, storeName },
    currency,
    authorizedActions,
    // --- mapped dispatch props
    openFullscreenModal,
    pageLoading,
    pageLoaded,
    showSuccessMessage,
    showErrorMessage,
    // --- props
    lossType,
  } = props;

  // ==================
  // ===== States =====
  // ==================

  // Used to easily set the "category" value for a loss
  const [lossCategoriesById, setLossCategoriesById] = useState(null);
  // Used to easily set the "channel" value for a product loss
  const [channelsById, setChannelsById] = useState(null);

  const [losses, setLosses] = useState([]);

  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [firstLoadingOfStores, setFirstLoadingOfStores] = useState(true);
  const [currentDateWithTz, setCurrentDateWithTz] = useState(moment());
  const [selectedLosses, setSelectedLosses] = useState([]);
  const [totalLoss, setTotalLoss] = useState(0);

  // The list of dates on which losses have been reported in the last 48h
  const [recentDatesWithLosses, setRecentDatesWithLosses] = useState([]);

  // The list of dates on which losses have been reported more than 48h ago
  const [oldDatesWithLosses, setOldDatesWithLosses] = useState([]);

  const [focusedMonth, setFocusedMonth] = useState(moment());

  const [displayExportModal, setDisplayExportModal] = useState(false);

  // If the selectedDate has reported losses and is from more than 48h ago, interaction is limited.
  const [disableActions, setDisableActions] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [displayProductPicture, setDisplayProductOrSPPicture] = useState({ state: false, img: '' });

  const [markerConfiguration] = useState({
    isHidden: (loss) => !loss.isCashierLoss,
    backgroundColor: theme.colors.green.light,
    icon: {
      src: '/images/inpulse/sync-black-small.svg',
    },
  });

  // ======================
  // ===== UseEffects =====
  // ======================

  // Fetch all loss categories and all channels
  useEffect(() => {
    (async function fetch() {
      try {
        const allLossCategories = await lossService.getLossCategoriesByClientIdAndLossType(
          lossType,
        );

        setLossCategoriesById(keyBy(allLossCategories, 'id'));
      } catch {
        showErrorMessage(i18next.t('GENERAL.FETCH_LOSS_CATEGORIES_FAILURE'));
      }

      try {
        const allChannels = await getChannels();

        const channelsNameTranslations = {
          [CHANNEL_ID_KEY_BY_NAME[CHANNELS.ON_SITE]]: i18next.t('ADMIN.RECIPES.ONSITE'),
          [CHANNEL_ID_KEY_BY_NAME[CHANNELS.DELIVERY]]: i18next.t('ADMIN.RECIPES.DELIVERY'),
        };

        const allChannelsWithTranslationKey = allChannels.map((channel) => ({
          ...channel,
          name: channelsNameTranslations[channel.id],
        }));

        if (!hasMultipleChannels) {
          const defaultChannel = allChannelsWithTranslationKey.find(
            ({ id }) => id === defaultChannelId,
          );

          const formattedChannels = [defaultChannel];

          setChannelsById(keyBy(formattedChannels, 'id'));

          return;
        }

        setChannelsById(keyBy(allChannelsWithTranslationKey, 'id'));
      } catch {
        showErrorMessage(i18next.t('GENERAL.FETCH_CHANNELS_FAILURE'));
      }
    })();
  }, []);

  // Set initial value for selectedStore (stores prop is empty at first load)
  useEffect(() => {
    if (isEmpty(stores) || !firstLoadingOfStores) {
      return;
    }

    setSelectedStore(head(stores));

    setSelectedDate(moment.tz(head(stores).timezone));
    setCurrentDateWithTz(moment.tz(head(stores).timezone));

    setFirstLoadingOfStores(false);
  }, [stores]);

  // Fetch loss data when changing store or date
  useEffect(() => {
    if (!!lossCategoriesById && !!channelsById && !!selectedStore) {
      fetchAndSetPageData();
    }
  }, [selectedStore, selectedDate, lossCategoriesById, channelsById]);

  // Fetch dates with losses whenever changing month in the DatePicker
  useEffect(() => {
    if (!!selectedStore) {
      (async function fetchDates() {
        const dates = await getPastDatesWithLossesForMonth(focusedMonth, selectedStore);

        setOldDatesWithLosses(get(dates, 'oldDatesWithLosses', []));
        setRecentDatesWithLosses(get(dates, 'recentDatesWithLosses', []));
      })();
    }
  }, [focusedMonth, selectedStore, selectedDate, disableActions]);

  useEffect(() => {
    // check if selectedDate is possible for the new store
    if (!!selectedStore) {
      setCurrentDateWithTz(moment.tz(selectedStore.timezone));

      const selectedDateFormatted = moment(
        selectedDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
      );

      // we use moment of another moment because we have to compare the day and we can't use
      // isAfter method (it doesn't takes in account properly the timezone)

      const dateTodayOfSelectedStore = moment(
        moment.tz(selectedStore.timezone).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
      );

      if (selectedDateFormatted.isAfter(dateTodayOfSelectedStore)) {
        setSelectedDate(moment.tz(selectedStore.timezone));
      }
    }
  }, [selectedStore]);

  // ====================
  // ===== Methods ======
  // ====================

  const fetchAndSetPageData = async () => {
    setIsLoading(true);

    const startDate = moment(selectedDate)
      .startOf('day')
      .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);
    const endDate = moment(selectedDate).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

    (async function call() {
      try {
        const lossesPayload = await lossService.getLossesList(
          lossType,
          [selectedStore.id],
          startDate,
          endDate,
        );

        let lossesPayloadClone = cloneDeep(lossesPayload);

        if (!hasMultipleChannels) {
          lossesPayloadClone = lossesPayload.map((loss) => ({
            ...loss,
            channelId: defaultChannelId,
          }));
        }

        const data = formatPayloadIntoLossData(
          lossesPayloadClone,
          lossType,
          stores,
          lossCategoriesById,
          {
            channelsById,
          },
        );

        const lossesOldestCreatedAt = data.map((loss) => loss.oldestCreatedAt);

        const disabledDate = lossesOldestCreatedAt.some((date) =>
          moment(date).add(1, 'days').isBefore(moment(), 'day'),
        );

        setDisableActions(disabledDate);

        setLosses(data);

        const computedTotalLoss = data.reduce((acc, { total }) => acc + parseFloat(total), 0);

        setTotalLoss(computedTotalLoss);

        setSelectedLosses([]);
      } catch (err) {
        showErrorMessage(
          i18next.t(RETRIEVE_LOSSES_FAILURE[lossType], {
            storeName: selectedStore.name,
            date: selectedDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
          }),
        );
      } finally {
        setIsLoading(false);
      }
    })();
  };

  const createLoss = () => {
    const params = {
      component: LossForm,
      initialStore: selectedStore,
      initialDate: selectedDate,
      currentDateWithTz,
      lossType,
      initialOldDatesWithLosses: oldDatesWithLosses,
      initialRecentDatesWithLosses: recentDatesWithLosses,
      getDatesWithLossesForMonth: getPastDatesWithLossesForMonth,
      handleCloseCleanUp: async (formSelectedStore, formSelectedDate) => {
        setSelectedStore({ ...formSelectedStore });
        setSelectedDate(moment(formSelectedDate));
        setFocusedMonth(moment(formSelectedDate));
      },
    };

    openFullscreenModal(params);
  };

  const exportLosses = () => {
    setDisplayExportModal(true);
  };

  const deleteLosses = (items) => {
    pageLoading();

    (async function call() {
      try {
        const aggregatedLossIds = items.map((item) => item.lossIds);
        const lossIds = flatten(aggregatedLossIds);

        await lossService.deleteLosses(lossIds);

        fetchAndSetPageData();
        showSuccessMessage(i18next.t(DELETE_LOSSES_SUCCESS[lossType]));
      } catch (err) {
        showErrorMessage(i18next.t(DELETE_LOSSES_FAILURE[lossType]));
      } finally {
        pageLoaded();
      }
    })();
  };

  const renderEmptyStateByType = (lossType) => {
    const icon = EMPTY_STATE_ICON[lossType];

    const isAddButtonDisplayed =
      (!disableActions &&
        (lossType === LOSS_TYPE.PRODUCT
          ? canCreateProductLoss(authorizedActions)
          : canCreateSupplierProductLoss(authorizedActions))) ||
      (disableActions &&
        (lossType === LOSS_TYPE.PRODUCT
          ? canCorrectProductLoss(authorizedActions)
          : canCorrectSupplierProductLoss(authorizedActions)));

    return (
      <EmptyState
        button={
          isAddButtonDisplayed ? (
            <Button
              color={'inpulse-default'}
              handleClick={createLoss}
              icon={'/images/inpulse/add-white-small.svg'}
              label={i18next.t('GENERAL.ADD')}
            />
          ) : null
        }
        icon={icon}
        subtitle={i18next.t('LOSSES.PRODUCTS.NO_LOSSES_SUBTITLE', {
          date: selectedDate.format(DATE_DISPLAY_FORMATS.DATE_MONTH),
          storeName: !!selectedStore && selectedStore.name,
        })}
        title={i18next.t('LOSSES.PRODUCTS.NO_LOSSES_TITLE')}
      />
    );
  };

  const getPastDatesWithLossesForMonth = async (
    currentFocusedMonth,
    currentSelectedStore,
    showLoadingState = true,
  ) => {
    if (showLoadingState) {
      pageLoading();
    }

    try {
      const datesWithLosses = await getDatesWithLossesForMonth(
        currentFocusedMonth,
        currentSelectedStore,
        lossType,
      );

      return datesWithLosses;
    } catch {
      showErrorMessage(i18next.t(RETRIEVE_DATES_FAILURE[lossType]));
    } finally {
      if (showLoadingState) {
        pageLoaded();
      }
    }
  };

  const handleExport = async (stores, dateRange) => {
    pageLoading();

    try {
      handleLossesExport(stores, dateRange, lossType, lossCategoriesById, currency, storeName, {
        channelsById,
        hasMultipleChannels,
        defaultChannelId,
      });
    } catch {
      showErrorMessage(i18next.t(EXPORT_FAILURE[lossType]));
    } finally {
      pageLoaded();
    }
  };

  // ListView props
  const [dataColumns] = useState(getColumnsByType(lossType, setDisplayProductOrSPPicture));

  const actions = getActionsByType({
    lossType,
    allItems: losses,
    selectedItems: selectedLosses,
    createLoss,
    exportLosses,
    deleteLosses,
    disableActions,
    authorizedActions,
  });
  const rowActions = getRowActions({ deleteLosses, disableActions, lossType, authorizedActions });

  const userLanguageCode = get(user, 'lnkLanguageAccountrel.code', 'fr');

  return (
    <>
      {!!displayProductPicture.state && (
        <DisplayImgInFullScreen
          image={displayProductPicture.img}
          setDisplayProductPicture={setDisplayProductOrSPPicture}
        />
      )}
      <Container>
        <ListView
          actions={!isLoading && actions}
          columns={dataColumns}
          data={losses}
          isLoading={isLoading}
          languageCode={userLanguageCode}
          markerConfiguration={markerConfiguration}
          padding={STANDARD_LISTVIEW_PADDING}
          placeholderShape={i18next.t('GENERAL.SEARCH')}
          renderEmptyState={() => renderEmptyStateByType(lossType)}
          renderFilterButton={() => (
            <HeaderContainer>
              <Dropdown
                iconSrc={'/images/inpulse/store-black-small.svg'}
                isDisabled={isLoading}
                isRequired={true}
                isUniqueSelection={true}
                items={stores}
                selectedItem={selectedStore}
                onSelectionChange={(newSelectedStore) => {
                  if (newSelectedStore.id === selectedStore.id) {
                    return;
                  }
                  setSelectedStore(newSelectedStore);
                }}
              />
              <PastDayDatePicker
                authorizedDates={recentDatesWithLosses}
                calendarInfo={PRODUCTS_CALENDAR_INFO()}
                date={selectedDate}
                disabled={isLoading}
                forbiddenDates={oldDatesWithLosses}
                maxFutureDate={currentDateWithTz}
                specialLoss={true}
                timezone={!!selectedStore ? selectedStore.timezone : 'Europe/Paris'}
                clickableForbiddenDates
                onDateChange={setSelectedDate}
                onNextMonthClick={(nextMonth) => setFocusedMonth(nextMonth)}
                onPrevMonthClick={(prevMonth) => setFocusedMonth(prevMonth)}
              />

              <TotalLossContainer>
                {i18next.t('GENERAL.TOTAL_EXCL_TAXES_WITH_CURRENCY', {
                  currencyCode: currency.alphabeticCode,
                })}
                <TotalLossValue>
                  {isLoading ? (
                    <DeepsightComponentLoader height={16} width={16} />
                  ) : (
                    formatNumber(totalLoss, currency.numberDecimals)
                  )}
                </TotalLossValue>
              </TotalLossContainer>
            </HeaderContainer>
          )}
          rowActions={rowActions}
          setSelectedItems={setSelectedLosses}
        />
        {displayExportModal && (
          <ExportLossesModal
            handleExport={handleExport}
            lossType={lossType}
            resetExportingLosses={() => setDisplayExportModal(false)}
          />
        )}
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  stores: getSalesPointStores(state.baseReducer.activeStores),
  client: getClientInfo(state.baseReducer.user),
  currency: state.baseReducer.currency,
  authorizedActions: [
    ...getAuthorizedActions(state.baseReducer.userRights, '/losses/losses/supplier-products'),
    ...getAuthorizedActions(state.baseReducer.userRights, '/losses/losses/products'),
  ],
});

const mapDispatchToProps = (dispatch) => ({
  openFullscreenModal: (params) => {
    dispatch(openModal(params, true));
  },
  openModalExportInfo: (params) => {
    dispatch(openSmallModal(params));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

LossListView.propTypes = {
  lossType: PropTypes.oneOf(Object.values(LOSS_TYPE)),
};

export default connect(mapStateToProps, mapDispatchToProps)(LossListView);
