import * as Sentry from '@sentry/react';

const SENTRY_DSN_TEST =
  'https://e24709a93e761ec1673b3488694147e5@o197688.ingest.sentry.io/4506552922013696';

const SENTRY_DSN_STAGING =
  'https://b6ce55e0673e195351476f7e4283a196@o197688.ingest.sentry.io/4506552917950464';

const SENTRY_DSN_PRODUCTION =
  'https://9fed54c22e654ee7bdf12f988f1e503c@o197688.ingest.sentry.io/1369312';

const _getDsnPath = () => {
  if (process.env.REACT_APP_BUILD_ENV === 'production') {
    return SENTRY_DSN_PRODUCTION;
  }

  if (process.env.REACT_APP_BUILD_ENV === 'staging') {
    return SENTRY_DSN_STAGING;
  }

  if (process.env.REACT_APP_BUILD_ENV === 'test') {
    return SENTRY_DSN_TEST;
  }

  return null;
};

export const init = () => {
  const dsnPath = _getDsnPath();

  Sentry.init({
    integrations: [
      new Sentry.Integrations.TryCatch({
        eventTarget: false, // https://github.com/getsentry/sentry-javascript/issues/3040#issuecomment-913549441
      }),
    ],
    release: process.env.REACT_APP_GIT_SHA,
    ignoreErrors: [
      'The operation is insecure.', // https://github.com/apollographql/subscriptions-transport-ws/issues/332
      'Non-Error promise rejection captured',
      /Network Error/,
      'timeout exceeded',
      'Request aborted',
      'ResizeObserver loop completed with undelivered notifications.', // Nothing to worry about here (https://github.com/juggle/resize-observer/issues/103)
      /__gCrWeb\.instantSearch\.clearHighlight/,
      /__gCrWeb\.instantSearch\.setIOSParameters/,
      `ReferenceError: Can't find variable: gmo`, // It's a google error for IOS, nothing to worry about (https://github.com/The-Best-Codes/bestcodes-react-site/issues/65)
    ],
    dsn: dsnPath,
    enabled: !!dsnPath,
  });
};

export const scope = (userInformation) => {
  Sentry.configureScope((scope) => {
    scope.setUser(userInformation);
  });
};

export const send = (message) => {
  Sentry.captureMessage(message);
};

export default {
  init,
  send,
  scope,
};
