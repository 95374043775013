import { APIcaller, APIcallerv2 } from './APIcaller';

const ALL_ENTITY_AND_SP_IDS = 'allEntityAndSpIds';

const getStorageAreasByClientId = async ({
  withAllEntityAndSpIds = false,
  withoutMappings = false,
  getAllStorageAreas = false,
  filterByUserCatalog = false,
} = {}) => {
  const storageAreas = await APIcallerv2.apiGet(`/storage-areas/client`, {
    withoutMappings,
    getAllStorageAreas,
    filterByUserCatalog,
  });

  return withAllEntityAndSpIds
    ? storageAreas
    : storageAreas.filter(({ id }) => id !== ALL_ENTITY_AND_SP_IDS);
};

const disableStorageAreas = async (clientId, storageAreaIds) =>
  APIcaller.apiDelete(`/admin/storage-area/client/${clientId}`, { storageAreaIds });

const assignSupplierProductsToStorageAreas = async (clientId, supplierProductIds, storageAreaIds) =>
  APIcaller.apiPost(`/admin/storage-area/client/${clientId}/mappings`, {
    supplierProductIds,
    storageAreaIds,
  });

const getSPIdsMappedToStorageAreas = async (clientId, storageAreaIds) =>
  APIcaller.apiPost(`/admin/storage-area/client/${clientId}/mapped-supplier-products`, {
    storageAreaIds,
  });

const getStorageAreaById = async (storageAreaId) =>
  APIcallerv2.apiGet(`/storage-areas/${storageAreaId}`);

const createStorageArea = async (clientId, name, supplierProductIds = [], entityIds = []) =>
  APIcaller.apiPost(`/admin/storage-area/client/${clientId}`, {
    name,
    supplierProductIds,
    entityIds,
  });

const updateStorageArea = async (clientId, storageAreaId, name, supplierProductIds, entityIds) =>
  APIcaller.apiPatch(`/admin/storage-area/client/${clientId}/update/${storageAreaId}`, {
    name,
    supplierProductIds,
    entityIds,
  });

const getStorageAreasBySupplierProductAndEntityIds = async (
  clientId,
  supplierProductIds = [],
  entityIds = [],
  storeId = null,
) =>
  APIcaller.apiPost(`/admin/storage-area/client/${clientId}/storage-areas`, {
    supplierProductIds,
    entityIds,
    storeId,
  });

const createStorageAreas = async (name, storeIds, supplierProductIds, entityIds) =>
  APIcaller.apiPost('/admin/storage-area/', {
    name,
    storeIds,
    supplierProductIds,
    entityIds,
  });

const copyStorageAreaParams = async (sourceSAId, targetSAIds) =>
  APIcaller.apiPost('/admin/storage-area/clone-mappings', {
    sourceSAId,
    targetSAIds,
  });

const addStorageAreaMappings = async (storageAreaIds, supplierProductIds = [], entityIds = []) =>
  APIcaller.apiPost('/admin/storage-area/mappings', {
    storageAreaIds,
    supplierProductIds,
    entityIds,
  });

const deleteStorageAreaMappings = async (storageAreaIds, supplierProductIds = [], entityIds = []) =>
  APIcaller.apiDelete('/admin/storage-area/mappings', {
    storageAreaIds,
    supplierProductIds,
    entityIds,
  });

export default {
  getStorageAreasByClientId,
  disableStorageAreas,
  assignSupplierProductsToStorageAreas,
  getSPIdsMappedToStorageAreas,
  getStorageAreaById,
  createStorageArea,
  updateStorageArea,
  getStorageAreasBySupplierProductAndEntityIds,
  createStorageAreas,
  copyStorageAreaParams,
  addStorageAreaMappings,
  deleteStorageAreaMappings,
};
