import React from 'react';

import _ from 'lodash';
import i18next from 'i18next';

import { Text } from '../components/styledComponents';

import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_IMPORT_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import entityXlsConstants from '@commons/Excels/constants/entity.xlsx.constant';
import importTypeXlsConstants from '@commons/Excels/constants/importType.xslx.constant';
import normalizeStringValue from '@commons/utils/normalizeStringValue';
import supplierProductXlsConstants from '@commons/Excels/constants/supplierProduct.xlsx.constant';
import supplierXlsConstants from '@commons/Excels/constants/supplier.xlsx.constant';

import { mapColumnIdsAndNormalizedColumnIds, normalizeJsonObject } from '@backoffice/utils';
import ImportExcelFileModal from '@backoffice/components/ImportExcelFileModal';

import { IMPORT_FILE_SHEETS_CONSTANTS, IMPORT_MODAL_STEPS, IMPORT_TYPES } from './constants';

const defaultModalConfig = {
  width: '542px',
  height: 'auto',
  component: ImportExcelFileModal,
};

const getSelectFileConfig = (modalConfigProps, importType) => {
  const { selectedFile, handleFileChange } = modalConfigProps;

  let title = i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_EDIT_SUPPLIER_ITEMS_TITLE');
  let subTitle = i18next.t(
    'ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_EDIT_SUPPLIER_ITEMS_SUBTITLE',
  );

  if (importType === IMPORT_TYPES.SUPPLIER_PRODUCTS_IMPORT) {
    title = i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_BATCH_TITLE');
    subTitle = i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_BATCH_SUBTITLE');
  }

  return {
    ...defaultModalConfig,
    type: 'action',
    title,
    icon: '/images/inpulse/file-upload-black-small.svg',
    data: {
      subTitle,
      selectedFile,
      setSelectedFile: (file) => handleFileChange(file, importType),
    },
    actions: [
      {
        ...GENERIC_MODAL_CANCEL_BUTTON(),
        handleClick: () => handleFileChange(null, importType),
      },
      {
        ...GENERIC_MODAL_IMPORT_BUTTON(),
        isDisabled: true,
        handleClick: () => false,
      },
    ],
  };
};

const getValidateFileConfig = (modalConfigProps, importType) => {
  const { updatedFile, handleFileChange, handleFileValidation } = modalConfigProps;

  const title = i18next.t(
    'ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_EDIT_SUPPLIER_ITEMS_TITLE',
  );
  const subTitle = i18next.t(
    'ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_EDIT_SUPPLIER_ITEMS_SUBTITLE',
  );

  return {
    ...defaultModalConfig,
    type: 'action',
    title,
    icon: '/images/inpulse/file-upload-black-small.svg',
    data: {
      subTitle,
      selectedFile: updatedFile,
      setSelectedFile: (file) => handleFileChange(file, importType),
    },
    actions: [
      {
        ...GENERIC_MODAL_CANCEL_BUTTON(),
        handleClick: () => handleFileChange(null, importType),
      },
      {
        ...GENERIC_MODAL_IMPORT_BUTTON(),
        handleClick: () => handleFileValidation(updatedFile),
      },
    ],
  };
};

const getErrorFileConfig = (modalConfigProps, importType, props) => {
  const { updatedFile, handleFileChange, exportSupplierProductErrors, statusCode } =
    modalConfigProps;

  const subTitle = i18next.t(
    statusCode === 422
      ? 'ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_SELECT_FILE_ERROR_SUBTITLE'
      : 'ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_SELECT_FILE_ERROR_CONTENT',
  );

  const additionalText = i18next.t(
    statusCode === 422
      ? 'ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_BATCH_SELECT_FILE_UNVALIDATED_LINES_ERROR_ADDITIONAL_TEXT'
      : 'ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_BATCH_SELECT_FILE_ERROR_ADDITIONAL_TEXT',
  );

  const configData = {
    subTitle,
    additionalText,
    validatedFile: false,
    selectedFile: updatedFile,
  };

  if (importType === IMPORT_TYPES.SUPPLIER_PRODUCTS_IMPORT) {
    return {
      ...defaultModalConfig,
      type: 'error',
      title: i18next.t(
        'ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_BATCH_SELECT_FILE_ERROR_TITLE',
      ),
      icon: '/images/inpulse/info-white-small.svg',
      data:
        statusCode === 422
          ? { ...configData, exportXLS: () => exportSupplierProductErrors() }
          : configData,
      actions: [
        {
          key: 0,
          color: 'inpulse-default',
          label: i18next.t('GENERAL.CLOSE'),
          icon: '/images/inpulse/close-white-small.svg',
          handleClick: () => {
            handleFileChange(null, importType);
          },
        },
      ],
    };
  }

  return {
    ...defaultModalConfig,
    type: 'error',
    title: i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_EDIT_SUPPLIER_ITEMS_TITLE_BIS'),
    icon: '/images/inpulse/info-white-small.svg',
    data: {
      subTitle: i18next.t(
        'ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_SELECT_FILE_ERROR_CONTENT',
      ),
      additionalText: i18next.t(
        'ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_EDIT_SUPPLIER_ITEMS_SELECT_FILE_ERROR_ADDITIONAL_TEXT',
      ),
      validatedFile: false,
      selectedFile: updatedFile,
    },
    actions: [
      {
        key: 0,
        color: 'inpulse-default',
        label: i18next.t('GENERAL.CLOSE'),
        icon: '/images/inpulse/close-white-small.svg',
        handleClick: () => {
          handleFileChange(null, importType);
          props.showErrorMessage(
            i18next.t(
              'ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_EDIT_SUPPLIER_ITEMS_VALIDATION_ERROR',
            ),
          );
        },
      },
    ],
  };
};

const getValidatedFileConfig = (modalConfigProps, importType, props) => {
  const {
    updatedFile = null,
    checkedSupplierProducts,
    handleFileChange,
    exportSupplierProductErrors,
    handleFileImport,
    batchSPdata,
  } = modalConfigProps;

  if (importType === IMPORT_TYPES.SUPPLIER_PRODUCTS_IMPORT) {
    return {
      ...defaultModalConfig,
      type: 'success',
      title: i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_BATCH_TITLE'),
      icon: '/images/inpulse/check-white-small.svg',
      data: {
        validatedFile: true,
        selectedFile: updatedFile,
      },
      actions: [
        {
          key: 0,
          color: 'inpulse-default',
          label: i18next.t('GENERAL.IMPORT'),
          icon: '/images/inpulse/check-white-small.svg',
          handleClick: () => handleFileImport(batchSPdata),
        },
      ],
    };
  }

  if (_.isEmpty(_.get(checkedSupplierProducts, 'validated'))) {
    return {
      ...defaultModalConfig,
      type: 'warning',
      title: i18next.t(
        'ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_EDIT_SUPPLIER_ITEMS_TITLE_BIS',
      ),
      icon: '/images/inpulse/check-white-small.svg',
      data: {
        validatedFile: true,
        unValidatedLines: _.get(checkedSupplierProducts, 'unValidated'),
        subTitle: i18next.t(
          'ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_EDIT_SUPPLIER_ITEMS_NO_VALIDATED_LINES_CONTENT',
        ),
        selectedFile: updatedFile,
        exportXLS: () => exportSupplierProductErrors(checkedSupplierProducts),
      },
      actions: [
        {
          key: 0,
          color: 'inpulse-default',
          label: i18next.t('GENERAL.CLOSE'),
          icon: '/images/inpulse/close-white-small.svg',
          handleClick: () => handleFileChange(null, importType),
        },
      ],
    };
  }

  if (!_.isEmpty(_.get(checkedSupplierProducts, 'unValidated'))) {
    return {
      ...defaultModalConfig,
      type: 'warning',
      title: i18next.t(
        'ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_EDIT_SUPPLIER_ITEMS_TITLE_BIS',
      ),
      icon: '/images/inpulse/warning-white-small.svg',
      data: {
        validatedFile: true,
        unValidatedLines: _.get(checkedSupplierProducts, 'unValidated'),
        subTitle: i18next.t(
          'ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_EDIT_PRICE_UNVALIDATED_LINES_CONTENT',
        ),
        selectedFile: updatedFile,
        exportXLS: () => exportSupplierProductErrors(checkedSupplierProducts),
      },
      actions: [
        {
          ...GENERIC_MODAL_CANCEL_BUTTON(),
          handleClick: () => handleFileChange(null, importType),
        },
        {
          key: 1,
          preventClosing: true,
          color: 'inpulse-default',
          label: i18next.t('GENERAL.IMPORT'),
          icon: '/images/inpulse/check-white-small.svg',
          handleClick: () => handleFileImport(_.get(checkedSupplierProducts, 'validated')),
        },
      ],
    };
  }

  return {
    ...defaultModalConfig,
    type: 'success',
    title: i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_EDIT_SUPPLIER_ITEMS_TITLE_BIS'),
    icon: '/images/inpulse/check-white-small.svg',
    data: {
      validatedFile: true,
      selectedFile: updatedFile,
    },
    actions: [
      {
        key: 0,
        preventClosing: true,
        color: 'inpulse-default',
        label: i18next.t('GENERAL.IMPORT'),
        icon: '/images/inpulse/check-white-small.svg',
        handleClick: () => handleFileImport(_.get(checkedSupplierProducts, 'validated')),
      },
    ],
  };
};

const getWarningFileConfig = (modalConfigProps, importType, props) => {
  const {
    client: { storeName },
  } = props;
  const { checkedSupplierProducts, handleFileChange, handleFileImport } = modalConfigProps;

  if (importType === IMPORT_TYPES.SUPPLIER_PRODUCTS_IMPORT) {
    window.alert('TO BE IMPLEMENTED WARNING SP');
    return false;
  }

  return {
    ...defaultModalConfig,
    type: 'warning',
    title: i18next.t(
      'ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_EDIT_SUPPLIER_ITEMS_WARNING_TITLE',
    ),
    icon: '/images/inpulse/warning-white-small.svg',
    data: {
      customContent: (
        <Text grey height24>
          {i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_IMPORT_TEMPLATE_EDIT_PRICE_WARNING_CONTENT', {
            storeName: getClientStoreNameTranslation(storeName, true).toLowerCase(),
          })}
        </Text>
      ),
    },
    actions: [
      {
        ...GENERIC_MODAL_CANCEL_BUTTON(),
        handleClick: () => handleFileChange(null, importType),
      },
      {
        key: 1,
        preventClosing: true,
        color: 'inpulse-default',
        label: i18next.t('GENERAL.SAVE'),
        icon: '/images/inpulse/save-white-small.svg',
        handleClick: () => handleFileImport(_.get(checkedSupplierProducts, 'validated')),
      },
    ],
  };
};

/**
 * Modal configuations for imports
 * @param {object} modalConfigProps Import modal configurations props
 * @param {string} importType Import type need to be one of IMPORT_TYPES
 * @param {object} props Import modal parent component props
 * @returns Import modal configurations
 */
export const getSupplierProductModalConfig = (modalConfigProps, importType, props) => {
  const { type } = modalConfigProps;

  if (type === IMPORT_MODAL_STEPS.SELECT_FILE) {
    return getSelectFileConfig(modalConfigProps, importType);
  }

  if (type === IMPORT_MODAL_STEPS.VALIDATE_FILE) {
    return getValidateFileConfig(modalConfigProps, importType);
  }

  if (type === IMPORT_MODAL_STEPS.ERROR_FILE) {
    return getErrorFileConfig(modalConfigProps, importType, props);
  }

  if (type === IMPORT_MODAL_STEPS.VALIDATED_FILE) {
    return getValidatedFileConfig(modalConfigProps, importType, props);
  }

  if (type === IMPORT_MODAL_STEPS.WARNING) {
    return getWarningFileConfig(modalConfigProps, importType, props);
  }

  return defaultModalConfig;
};

export const refreshImportModal = (modalConfigProps, importType, props) => {
  props.refreshGenericModal(getSupplierProductModalConfig(modalConfigProps, importType, props));
};

export const formatSPPriceEditJson = (supplierProductsWorkbook) => {
  const normalizedSupplierProductsWorkbook = normalizeJsonObject(supplierProductsWorkbook);

  const sheetConfiguration = supplierProductXlsConstants.getSheetConfiguration();

  const columnIds = supplierProductXlsConstants.getNormalizedColumnIds();

  return Object.keys(normalizedSupplierProductsWorkbook).reduce((acc, sheetName) => {
    if (sheetName === sheetConfiguration.SUPPLIER_PRODUCT.name) {
      acc[sheetConfiguration.SUPPLIER_PRODUCT.id] = normalizedSupplierProductsWorkbook[
        sheetName
      ].map((row) => mapColumnIdsAndNormalizedColumnIds(row, columnIds));
    }

    if (sheetName === sheetConfiguration.INGREDIENT.name) {
      acc[entityXlsConstants.SHEET.id] = normalizedSupplierProductsWorkbook[sheetName].map(
        (current) => ({
          [entityXlsConstants.COLUMN_IDS.ID]: _.get(
            current,
            normalizeStringValue(i18next.t('SUPPLIER_PRODUCTS.IMPORTS.ID')),
          ),
          [entityXlsConstants.COLUMN_IDS.NAME]: _.get(
            current,
            normalizeStringValue(i18next.t('SUPPLIER_PRODUCTS.IMPORTS.NAME')),
          ),
          [entityXlsConstants.COLUMN_IDS.UNIT]: _.get(
            current,
            normalizeStringValue(i18next.t('SUPPLIER_PRODUCTS.IMPORTS.UNIT')),
          ),
        }),
      );
    }

    if (sheetName === sheetConfiguration.SUPPLIERS.name) {
      acc[supplierXlsConstants.SHEET.id] = normalizedSupplierProductsWorkbook[sheetName].map(
        (current) => ({
          [columnIds.ID.key]: _.get(
            current,
            normalizeStringValue(i18next.t('SUPPLIER_PRODUCTS.IMPORTS.ID')),
          ),
          [columnIds.NAME.key]: _.get(
            current,
            normalizeStringValue(i18next.t('SUPPLIER_PRODUCTS.IMPORTS.NAME')),
          ),
        }),
      );
    }

    return acc;
  }, {});
};

export const formatJson = (supplierProducts) => {
  const normalizedSupplierProductsWorkbook = normalizeJsonObject(supplierProducts);

  const sheetConfiguration = supplierProductXlsConstants.getSheetConfiguration();

  const columnIds = supplierProductXlsConstants.getNormalizedColumnIds();

  return Object.keys(normalizedSupplierProductsWorkbook).reduce((acc, sheetName) => {
    if (!acc[importTypeXlsConstants.KEY]) {
      acc[importTypeXlsConstants.KEY] = _.get(
        normalizedSupplierProductsWorkbook,
        'ImportType[0].importtype',
        null,
      );
    }

    if (sheetName === sheetConfiguration.SUPPLIER_PRODUCT.name) {
      acc[sheetConfiguration.SUPPLIER_PRODUCT.id] = normalizedSupplierProductsWorkbook[
        sheetName
      ].map((row) => mapColumnIdsAndNormalizedColumnIds(row, columnIds));
    }

    if (sheetName === sheetConfiguration.CATEGORIES.name) {
      acc[IMPORT_FILE_SHEETS_CONSTANTS.CATEGORIES.id] = normalizedSupplierProductsWorkbook[
        sheetName
      ].map((current) => ({
        [IMPORT_FILE_SHEETS_CONSTANTS.CATEGORIES.columns.NAME]: _.get(
          current,
          normalizeStringValue(
            i18next.t('_BACKEND_TRANSLATIONS.SUPPLIER_PRODUCTS.IMPORTS.CATEGORY'),
          ),
        ),
      }));
    }

    if (sheetName === sheetConfiguration.SUB_CATEGORIES.name) {
      acc[IMPORT_FILE_SHEETS_CONSTANTS.SUB_CATEGORIES.id] = normalizedSupplierProductsWorkbook[
        sheetName
      ].map((current) => ({
        [IMPORT_FILE_SHEETS_CONSTANTS.SUB_CATEGORIES.columns.NAME]: _.get(
          current,
          normalizeStringValue(
            i18next.t('_BACKEND_TRANSLATIONS.SUPPLIER_PRODUCTS.IMPORTS.SUB_CATEGORIES_NAME'),
          ),
        ),
      }));
    }

    if (sheetName === sheetConfiguration.INGREDIENT.name) {
      acc[entityXlsConstants.SHEET.id] = normalizedSupplierProductsWorkbook[sheetName].map(
        (current) => ({
          [entityXlsConstants.COLUMN_IDS.ID]: _.get(
            current,
            normalizeStringValue(i18next.t('_BACKEND_TRANSLATIONS.SUPPLIER_PRODUCTS.IMPORTS.ID')),
          ),
          [entityXlsConstants.COLUMN_IDS.NAME]: _.get(
            current,
            normalizeStringValue(i18next.t('_BACKEND_TRANSLATIONS.SUPPLIER_PRODUCTS.IMPORTS.NAME')),
          ),
          [entityXlsConstants.COLUMN_IDS.UNIT]: _.get(
            current,
            normalizeStringValue(i18next.t('_BACKEND_TRANSLATIONS.SUPPLIER_PRODUCTS.IMPORTS.UNIT')),
          ),
        }),
      );
    }

    if (sheetName === sheetConfiguration.SUPPLIERS.name) {
      acc[supplierXlsConstants.SHEET.id] = normalizedSupplierProductsWorkbook[sheetName].map(
        (current) => ({
          [columnIds.ID.key]: _.get(
            current,
            normalizeStringValue(i18next.t('_BACKEND_TRANSLATIONS.SUPPLIER_PRODUCTS.IMPORTS.ID')),
          ),
          [columnIds.NAME.key]: _.get(
            current,
            normalizeStringValue(i18next.t('_BACKEND_TRANSLATIONS.SUPPLIER_PRODUCTS.IMPORTS.NAME')),
          ),
        }),
      );
    }

    return acc;
  }, {});
};
