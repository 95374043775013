import { APIcallerv2 } from './APIcaller';

const getWeatherForecast = (weatherStationId, storeTimezone, startDate, endDate) =>
  APIcallerv2.apiGet(`/forecasts/weather-days/${weatherStationId}`, {
    storeTimezone,
    startDate,
    endDate,
  });

export const weatherday = {
  getWeatherForecast,
};
