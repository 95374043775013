import axios from '../core/http';

import { APIcaller } from './APIcaller';
import { config } from '../config';

const baseApiUrl = `${config.baseApiUrl}/v1`;

const getProductionData = (storeId, date, startHour, endHour) =>
  axios
    .get(`${baseApiUrl}/production/stores/${storeId}`, {
      params: { date, startHour, endHour },
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });

const postPlanningRecommendation = (
  storeId,
  productId,
  planningDate,
  startTime,
  endTime,
  tot,
  enableProductMixModification,
) =>
  APIcaller.apiPost('/production/PlanningRecommendations', {
    storeId,
    productId,
    planningDate,
    startTime,
    endTime,
    tot,
    enableProductMixModification,
  });

export const planningRecommendation = {
  getProductionData,
  postPlanningRecommendation,
};
