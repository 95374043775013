// Add this specific import to avoid random error when initialized date picker
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import i18next from 'i18next';
import moment from 'moment-timezone';
import MomentPropTypes from 'react-moment-proptypes';
import PropTypes from 'prop-types';
import React from 'react';

import { DATE_DISPLAY_FORMATS, DATE_PICKER_DOT_COLOR } from '../constants';
import { DatePickerInputIcon } from '../DatePickerIcon';
import { renderCustomArrowIcon, renderCustomNavIconLeft, renderCustomNavIconRight } from '../utils';
import { renderDatePickerModalInfo } from '../DatePickerModalInformation';
import DatePickerDay from '../DatePickerDay';

import { Container } from './styledComponents';

/**
 * A single day DatePicker.
 */

export const PeriodDatePicker = (props) => {
  const {
    startDate,
    endDate,
    onDatesChange,
    displayFormat,
    authorizedDates,
    warnedDates,
    forbiddenDates,
    maxFutureDate,
    maxPastDate,
    disabled,
    calendarInfo,
    customStyle,
    onNextMonthClick,
    onPrevMonthClick,
    focusedDateInput,
    setFocusedDateInput,
    minimumNights,
    timezone,
  } = props;

  const isOutsideRange = (day) => {
    const currentDay = day.clone();

    if (
      forbiddenDates.some((forbiddenDay) =>
        currentDay.startOf('day').isSame(forbiddenDay.startOf('day')),
      )
    ) {
      return true;
    }

    if (maxPastDate && !maxFutureDate) {
      const maxPast = maxPastDate.clone();
      return currentDay.startOf('day').isBefore(maxPast.startOf('day'));
    }

    if (!maxPastDate && maxFutureDate) {
      const maxFuture = maxFutureDate.clone();
      return currentDay.startOf('day').isAfter(maxFuture.endOf('day'));
    }

    if (maxPastDate && maxFutureDate) {
      const maxPast = maxPastDate.clone();
      const maxFuture = maxFutureDate.clone();
      return !currentDay
        .startOf('day')
        .isBetween(maxPast.subtract(1, 'days').endOf('day'), maxFuture.endOf('day'));
    }

    return false;
  };

  const renderDay = (day) => {
    const currentDay = day.clone();

    const isToday = currentDay.startOf('day').isSame(moment.tz(timezone).startOf('day'));

    if (
      forbiddenDates.some((forbiddenDay) =>
        currentDay.startOf('day').isSame(forbiddenDay.startOf('day')),
      )
    ) {
      return (
        <DatePickerDay
          color={DATE_PICKER_DOT_COLOR.RED}
          day={currentDay}
          hasCircle={isToday}
          hasDot={true}
        />
      );
    }

    if (
      warnedDates.some((warnedDay) => currentDay.startOf('day').isSame(warnedDay.startOf('day')))
    ) {
      return (
        <DatePickerDay
          color={DATE_PICKER_DOT_COLOR.ORANGE}
          day={currentDay}
          hasCircle={isToday}
          hasDot={true}
        />
      );
    }

    if (
      authorizedDates.some((authorizedDay) =>
        currentDay.startOf('day').isSame(authorizedDay.startOf('day')),
      )
    ) {
      return (
        <DatePickerDay
          color={DATE_PICKER_DOT_COLOR.GREEN}
          day={currentDay}
          hasCircle={isToday}
          hasDot={true}
        />
      );
    }

    return <DatePickerDay day={currentDay} hasCircle={isToday} />;
  };

  return (
    <Container
      calendarInfo={!!calendarInfo}
      disabled={disabled}
      focused={focusedDateInput}
      style={customStyle}
    >
      <DateRangePicker
        calendarInfoPosition={'top'}
        customArrowIcon={renderCustomArrowIcon()}
        customInputIcon={DatePickerInputIcon(startDate)}
        disabled={disabled}
        displayFormat={displayFormat}
        endDate={endDate}
        endDateId="your_unique_end_date_id"
        endDatePlaceholderText={i18next.t('GENERAL.ANALYTICS_HEADER_DATE_PICKER_END_PLACEHOLDER')}
        focusedInput={focusedDateInput}
        hideKeyboardShortcutsPanel={true}
        isOutsideRange={(day) => isOutsideRange(day)}
        minimumNights={minimumNights || 0}
        navNext={renderCustomNavIconRight()}
        navPrev={renderCustomNavIconLeft()}
        noBorder={true}
        numberOfMonths={2}
        readOnly={true}
        renderCalendarInfo={() => renderDatePickerModalInfo(calendarInfo, timezone)}
        renderDayContents={(day) => renderDay(day)}
        startDate={startDate}
        startDateId="your_unique_start_date_id"
        startDatePlaceholderText={i18next.t(
          'GENERAL.ANALYTICS_HEADER_DATE_PICKER_START_PLACEHOLDER',
        )}
        onDatesChange={(dates) => onDatesChange(dates)}
        onFocusChange={(focusedDateInput) => setFocusedDateInput(focusedDateInput)}
        onNextMonthClick={onNextMonthClick}
        onPrevMonthClick={onPrevMonthClick}
      />
    </Container>
  );
};

PeriodDatePicker.propTypes = {
  startDate: MomentPropTypes.momentObj, // current selected date
  endDate: MomentPropTypes.momentObj, // current selected date
  disabled: PropTypes.bool, // is the datepicker disabled
  displayFormat: PropTypes.oneOf(Object.values(DATE_DISPLAY_FORMATS)), // date format in input
  forbiddenDates: PropTypes.arrayOf(MomentPropTypes.momentObj), // Array of date that are forbidden to be selected (have a red dot)
  warnedDates: PropTypes.arrayOf(MomentPropTypes.momentObj), // Array of date that are warned to be selected (have a orange dot)
  authorizedDates: PropTypes.arrayOf(MomentPropTypes.momentObj), // Array of date that are authorized to be selected (have a green dot)
  maxFutureDate: MomentPropTypes.momentObj, // maximum selectable date in the Future
  maxPastDate: MomentPropTypes.momentObj, // maximum selectable date in the Past
  onDatesChange: PropTypes.func.isRequired, // function called when date changes
  startDatePlaceholderText: PropTypes.string, // Placeholder to display in input
  endDatePlaceholderText: PropTypes.string, // Placeholder to display in input
  calendarInfo: PropTypes.shape({
    dotsInfomations: PropTypes.arrayOf(
      PropTypes.shape({
        dotColor: PropTypes.oneOf(Object.values(DATE_PICKER_DOT_COLOR)),
        dotInfo: PropTypes.string,
      }), // array of object {dotColor, dotInfo} which contains information display for a dot color
    ),
    globalInfo: PropTypes.string, // A string containing a global information on datePicker
    //renderTooltip: PropTypes.func, // TO BE IMPLEMENTED function which return a tooltip content
  }), // Object containing information to display in modal
  onNextMonthClick: PropTypes.func, // function called when switch to next month
  onPrevMonthClick: PropTypes.func, // function called when switch to previous month
  customStyle: PropTypes.shape(), // an object containing specific style for datePicker
  focusedDateInput: PropTypes.string, // FocusedInputShape from react-dates library ('startDate' | 'endDate')
  setFocusedDateInput: PropTypes.func, // function called when focused date input change
  minimumNights: PropTypes.number, // Number of minimum night. If 0, we can select same date for startDate and endDate
  timezone: PropTypes.string.isRequired,
};

PeriodDatePicker.defaultProps = {
  date: null,
  startDate: null,
  endDate: null,
  customStyle: {},
  disabled: false,
  displayFormat: DATE_DISPLAY_FORMATS.CONDENSED_DAY_DATE_MONTH,
  forbiddenDates: [],
  warnedDates: [],
  authorizedDates: [],
  maxFutureDate: null,
  maxPastDate: null,
  placeholder: i18next.t('GENERAL.DATEPICKER_PLACEHOLDER'),
  calendarInfo: null,
  timezone: 'Europe/Paris',
};
