import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: 1;
  background-color: transparent;
  pointer-events: auto;

  filter: blur(0);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  // We need a margin top so the component doesn't go in front of the navigation bar
  margin-top: ${(props) => props.marginTop};
  // Padding bottom = marginTop because if we want to center the content we need to have a padding-bottom equal to the margin-top
  padding-bottom: ${(props) => props.marginTop};
`;
