import { clone, find, get, groupBy, head, includes, isEqual, keyBy, sortBy } from 'lodash';
import { connect } from 'react-redux';
import { getTimezonesForCountry } from 'countries-and-timezones';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import moment from 'moment';
import React, { Component } from 'react';

import 'moment/locale/fr';

import {
  deleteStoreSupplierProfileMappings,
  deleteStoreSupplierProfileMappingsError,
  deleteStoreSupplierProfileMappingsSuccess,
  getStoreSupplierProfileMappingsOfSupplier,
  receiveStoreSupplierProfileMappingsOfSupplier,
  requestStoreSupplierProfileMappingsOfSupplierError,
} from '@actions/storeSupplierProfileMapping';
import { loading, loadingSuccess } from '@actions/loading';
import {
  patchSupplierProfile,
  patchSupplierProfileError,
  patchSupplierProfileSuccess,
  postSupplierProfileWithMappings,
  postSupplierProfileWithMappingsError,
  postSupplierProfileWithMappingsSuccess,
} from '@actions/supplierProfile';
import {
  showConfirmationMessage,
  showErrorMessage,
  showMessage,
} from '@actions/messageconfirmation';

import { Button } from '@commons/utils/styledLibraryComponents';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { getUserTimezone } from '@commons/utils/date';
import { handleAppRefresh } from '@commons/utils/refreshApp';
import { sortArrayOfObjectsAlphabetically } from '@commons/utils/sorting';
import { Tags } from '@commons/Tags';
import Text from '@commons/Text';

import { canUpdateAssociatedSupplier } from '@selectors/user';
import { getAuthorizedActions } from '@selectors/featureProps';
import { getClientInfo } from '@selectors/client';

import { group as groupService } from '@services/group';
import { supplierProduct as supplierProductService } from '@services/supplierProduct';

import { convertSPPQuantityInMasterUnit } from '@orders/utils/computePackagingToSupplierProduct.js';

import { EXCEPTIONAL_DELIVERY_TYPES } from '../utils/constants';

import { canEditSupplierFromDetailView } from '@selectors/actions/supplierActions';
import {
  FooterButtonContainer,
  SupplierModalAssociationsBodyContainer,
  SupplierProfileModalContainer,
} from './styledComponents';
import SupplierModalAssociations from './SupplierProfileModalAssociations';
import SupplierProfileModalDeletionConfirm from './SupplierProfileModalDeletionConfirm';
import SupplierProfileModalInformation from './SupplierProfileModalInformation';
import SupplierProfileModalProperties from './SupplierProfileModalProperties';

import { NONE_GROUP } from '../../supplierProducts/utils/constants';

moment.locale('fr');

export class SupplierProfileModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deliveryOptions: [],
      exceptionalDeliveryDays: [],
      editStep: 0,
      available: true,
      hasDlc: true,
      operationType: null,
      originalSupplierProfileName: '',
      hasStock: true,
      storeName: getClientStoreNameTranslation(props.client.storeName),
      storeNamePlural: getClientStoreNameTranslation(props.client.storeName, true).toLowerCase(),
      groups: [],
      storeAssociations: this.props.params.stores,
      supplier: {
        name: '',
        category: '',
        code: '',
        adress: '',
        city: '',
        postCode: '',
        country: '',
        active: true,
      },
      supplierProducts: [],
      supplierProductAssociations: [],
      supplierProfile: {
        name: '',
        supplierId: '',
        orderMinAmount: '',
        orderMinUnit: '',
        contactName: '',
        address: '',
        email1: '',
        email2: '',
        telephone: '',
        deliveryDays: '',
        deliveryLeadTimes: '',
        orderTimeLimit: '',
        city: '',
        country: '',
        postCode: '',
      },
      formCategories: {
        general: true,
        contact: true,
        localisation: true,
      },
      countryHasUniqueTimezone: true,
    };
  }

  exitModal = (message) => {
    if (message) {
      this.props.showMessage(message);
    }
    if (this.props.params.reloadData) {
      this.props.params.reloadData();
    }
    this.props.closeModal();
  };

  /**********\   DATA PROCESSING   /***********/

  handleOperationType = async (operationType) => {
    if (operationType === 'add_stores' || operationType === 'delete_stores') {
      const storeSupplierProfilemappings =
        await this.props.getStoreSupplierProfileMappingsOfSupplier(this.props.params.supplier.id);

      const supplierProfileMappings = storeSupplierProfilemappings.filter(
        ({ supplierProfileId }) => supplierProfileId === this.props.params.supplierProfile.id,
      );
      const storeSupplierProfileMappingsIds = supplierProfileMappings.map(({ storeId }) => storeId);
      const storesMappedIds = storeSupplierProfilemappings.map(({ storeId }) => storeId);
      let stores = JSON.parse(JSON.stringify(this.props.params.stores));

      if (operationType === 'add_stores') {
        stores = stores.filter(({ id }) => !storesMappedIds.includes(id));
      }
      if (operationType === 'delete_stores') {
        stores = stores.filter(({ id }) => storeSupplierProfileMappingsIds.includes(id));
      }

      const formattedStores = await this.associateStoresToGroups(stores);

      this.setState({
        editStep: 2,
        storeAssociations: formattedStores,
        supplierProfileMappings,
      });
    } else if (operationType === 'create' || operationType === 'duplicate') {
      this.props
        .getStoreSupplierProfileMappingsOfSupplier(this.props.params.supplier.id)
        .then((result) => {
          const storesMappedIds = result.map((mapping) => mapping.storeId);
          let stores = JSON.parse(JSON.stringify(this.props.params.stores));
          stores = stores.filter((store) => !storesMappedIds.includes(store.id));
          this.setState({
            editStep: 1,
            storeAssociations: stores,
          });
        });
    } else {
      this.setState({ editStep: 1 });
    }
  };

  associateStoresToGroups = async (stores) => {
    this.props.pageLoading();

    try {
      const storeIds = stores.map(({ id }) => id);

      const groups = await groupService.getGroupsOfStores(storeIds);

      const groupsGroupedByGroupId = groups.reduce(
        (acc, group) => {
          if (!acc[group.groupId]) {
            acc[group.groupId] = {
              groupId: group.groupId,
              name: get(group, 'lnkGroupStoregroupmappingrel.name', '-'),
              storeIds: [],
              id: group.id,
            };
          }

          acc[group.groupId].storeIds.push(group.storeId);
          return acc;
        },
        { [NONE_GROUP.id]: { ...NONE_GROUP, groupId: NONE_GROUP.id, storeIds: [] } },
      );

      const groupsGroupedByStoreId = groupBy(groups, 'storeId');

      const formattedStores = stores.map((store) => {
        const storeGroups = get(groupsGroupedByStoreId, `${store.id}`, []);

        const formattedStoreGroups = storeGroups.map(({ lnkGroupStoregroupmappingrel }) => ({
          ...lnkGroupStoregroupmappingrel,
        }));

        if (!formattedStoreGroups.length) {
          groupsGroupedByGroupId[NONE_GROUP.id].storeIds.push(store.id);
        }

        return {
          ...store,
          groups: formattedStoreGroups,
        };
      });

      const formattedGroups = Object.values(groupsGroupedByGroupId);

      this.setState({ groups: formattedGroups });

      this.props.pageLoaded();

      return formattedStores;
    } catch {
      this.props.pageLoaded();
      this.props.showErrorMessage(i18next.t('BACKOFFICE.GENERAL.FETCHING_ERROR'));

      return stores.map((store) => ({ ...store, groups: [] }));
    }
  };

  componentDidMount() {
    if (this.props.params.supplier) {
      this.setState({
        operationType: this.props.params.operationType,
        supplier: this.props.params.supplier,
      });
      this.getSupplierProducts([this.props.params.supplier.id]);
      if (this.props.params.supplierProfile) {
        let deliveryOptions = [];
        if (
          this.props.params.supplierProfile.deliveryDays &&
          this.props.params.supplierProfile.deliveryLeadTimes
        ) {
          const deliveryDays = this.props.params.supplierProfile.deliveryDays.split(';');
          const deliveryLeadTimes = this.props.params.supplierProfile.deliveryLeadTimes.split(';');
          deliveryOptions = deliveryDays.map((deliveryDay, index) => ({
            deliveryDay: deliveryDay,
            deliveryLeadTime: deliveryLeadTimes[index],
          }));
        }
        this.setState({
          deliveryOptions,
          originalSupplierProfileName: this.props.params.supplierProfile.name,
          supplierProfile: this.props.params.supplierProfile,
        });

        // Handle exceptional delivery days
        const { exceptionalDeliveryDays } = this.props.params.supplierProfile;

        const formattedExceptionalDeliveryDays = sortBy(exceptionalDeliveryDays, 'date').map(
          ({ id, date, leadTime }) => ({
            id,
            date: moment(date),
            type:
              leadTime == null
                ? EXCEPTIONAL_DELIVERY_TYPES.NO_DELIVERY
                : EXCEPTIONAL_DELIVERY_TYPES.EXCEPTIONAL_DELIVERY,
            leadTime: `${leadTime}`,
          }),
        );

        this.setState({
          exceptionalDeliveryDays: formattedExceptionalDeliveryDays,
        });
      }
    }

    this.handleOperationType(this.props.params.operationType);
  }

  /**********\   FORM HANDLERS   /***********/

  handleSupplierProfileChange = (value, fieldName) => {
    if (fieldName === 'country') {
      const clientHasMultipleTimezones = this.props.client.hasMultipleTimezones;
      const countryTimezones = getTimezonesForCountry(value);

      const defaultValue = clientHasMultipleTimezones ? null : this.props.client.defaultTimezone;

      this.setState({
        supplierProfile: {
          ...this.state.supplierProfile,
          country: value,
          timezone:
            clientHasMultipleTimezones && countryTimezones.length === 1
              ? head(countryTimezones).name
              : defaultValue,
        },
        countryHasUniqueTimezone: countryTimezones.length === 1,
      });
      return;
    }

    this.setState({ supplierProfile: { ...this.state.supplierProfile, [fieldName]: value } });
  };

  isSupplierFormValid = () => (this.state.supplier.name ? true : false);

  isSupplierProfileFormValid = () => {
    const areDeliveryInputsValid =
      this.areDeliveryOptionsValid()['isValid'] && this.areExceptionalDeliveryDaysValid();

    if (this.props.client.hasMultipleTimezones) {
      return (
        !!this.state.supplierProfile.name &&
        areDeliveryInputsValid &&
        !!this.state.supplierProfile.country
      );
    }

    return this.state.supplierProfile.name ? areDeliveryInputsValid : false;
  };

  /**********\   SAVING   /***********/

  getSavingSuccessMessage = (nbSelectedStores) => {
    const {
      client: { storeName },
    } = this.props;

    const clientStoreName = getClientStoreNameTranslation(
      storeName,
      nbSelectedStores > 1,
    ).toLowerCase();

    return i18next.t('ADMIN.SUPPLIERS.SUPPLIER_AND_SUPPLIER_PROFILE_SAVED', {
      storeCount: nbSelectedStores,
      storeName: clientStoreName,
      count: nbSelectedStores,
    });
  };

  handleSupplierProfileSave = () => {
    let supplierProfile = { ...this.state.supplierProfile };
    supplierProfile.supplierId = this.state.supplier.id;
    const validDeliveryOptions = this.state.deliveryOptions.filter(
      (option) => option.deliveryDay && option.deliveryLeadTime,
    );

    supplierProfile.deliveryDays = validDeliveryOptions
      .map((option) => option.deliveryDay)
      .join(';');
    supplierProfile.deliveryLeadTimes = validDeliveryOptions
      .map((option) => option.deliveryLeadTime)
      .join(';');

    const { exceptionalDeliveryDays } = this.state;

    const exceptionalDeliveryDaysPayload = exceptionalDeliveryDays.map(({ id, date, leadTime }) => {
      const commonFields = {
        date: date.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        leadTime: leadTime !== null ? +leadTime : null,
      };

      if (!!id) {
        return { ...commonFields, id };
      }

      return commonFields;
    });

    this.props
      .patchSupplierProfile(supplierProfile, exceptionalDeliveryDaysPayload, getUserTimezone())
      .then(() => {
        this.exitModal(i18next.t('ADMIN.SUPPLIERS.SAVING_SUCCESS'));
        handleAppRefresh(this.props.shouldReloadApp);
      })
      .catch(() =>
        this.props.showErrorMessage(i18next.t('ADMIN.SUPPLIERS.SUPPLIER_GLOBAL_SAVING_ERROR')),
      );
  };

  handleCreateSupplierProfileWithMappings = () => {
    let supplierProfile = this.state.supplierProfile;
    supplierProfile.supplierId = this.state.supplier.id;
    if (this.state.operationType === 'create' || this.state.operationType === 'duplicate') {
      const validDeliveryOptions = this.state.deliveryOptions.filter(
        (option) => option.deliveryDay && option.deliveryLeadTime,
      );
      supplierProfile.deliveryDays = validDeliveryOptions
        .map((option) => option.deliveryDay)
        .join(';');
      supplierProfile.deliveryLeadTimes = validDeliveryOptions
        .map((option) => option.deliveryLeadTime)
        .join(';');
    }
    const storesMapped = this.state.storeAssociations.filter((store) => store.selected);
    const storesMappedIds = storesMapped.map((store) => store.id);

    const supplierProductAssociations = this.state.supplierProductAssociations
      .filter((supplierProduct) => supplierProduct.selected)
      .map((supplierProduct) => ({
        id: supplierProduct.id,
        price: supplierProduct.price,
        available: this.state.available,
        hasDlc: this.state.hasDlc,
        hasStock: this.state.hasStock,
      }));
    this.props
      .postSupplierProfileWithMappings(
        supplierProfile,
        storesMappedIds,
        supplierProductAssociations,
      )
      .then(() => {
        this.exitModal(i18next.t('ADMIN.SUPPLIERS.SAVING_SUCCESS'));
      })
      .catch(() =>
        this.props.showErrorMessage(i18next.t('ADMIN.SUPPLIERS.SUPPLIER_GLOBAL_SAVING_ERROR')),
      );
  };

  handleDeleteMappings = () => {
    const storesUnMapped = this.state.storeAssociations.filter((store) => store.selected);
    const storesUnMappedIds = storesUnMapped.map((store) => store.id);
    const mappingsToDelete = this.state.supplierProfileMappings.filter((mapping) =>
      storesUnMappedIds.includes(mapping.storeId),
    );
    const mappingsToDeleteIds = mappingsToDelete.map((mapping) => mapping.id);

    this.props
      .deleteStoreSupplierProfileMappings(mappingsToDeleteIds)
      .then(() => {
        this.exitModal(i18next.t('ADMIN.SUPPLIERS.SAVING_SUCCESS'));
      })
      .catch(() =>
        this.props.showErrorMessage(i18next.t('ADMIN.SUPPLIERS.SUPPLIER_GLOBAL_SAVING_ERROR')),
      );
  };

  /**********\   STORES SELECTION   /***********/

  allSelected = (lines) => {
    let selected = true;
    lines.forEach((line) => {
      if (!line.selected) {
        selected = false;
      }
    });
    return selected;
  };

  unSelectAllStores = () => {
    let stores = JSON.parse(JSON.stringify(this.state.storeAssociations));
    stores = stores.map((store) => {
      store.selected = false;
      return store;
    });
    this.setState({ storeAssociations: stores });
  };

  handleSelectAllStores = (searchInput) => {
    const stores = JSON.parse(JSON.stringify(this.state.storeAssociations));

    const displayedStoresInModal = stores.filter((store) =>
      store.name.toLowerCase().includes(searchInput.toLowerCase()),
    );

    const allSelected = this.allSelected(displayedStoresInModal);

    let newLines = stores.map((store) => {
      if (displayedStoresInModal.some((displayedStore) => displayedStore.id === store.id)) {
        store.selected = !allSelected;
      }

      return store;
    });

    this.setState({ storeAssociations: newLines });
  };

  handleSelectStore = (line) => {
    const stores = JSON.parse(JSON.stringify(this.state.storeAssociations));
    let newLines = stores.map((store) => {
      if (store.id === line.id) store.selected = !store.selected;
      return store;
    });
    this.setState({ storeAssociations: newLines });
  };

  handleSelectStoreBis = (selectedStores) => {
    const selectedStoresByIds = keyBy(selectedStores, 'id');

    const formattedStores = this.state.storeAssociations.map((store) => {
      if (selectedStoresByIds[store.id]) {
        return { ...store, isRowSelected: true, selected: true };
      }
      return { ...store, isRowSelected: false, selected: false };
    });

    if (!isEqual(this.state.storeAssociations, formattedStores)) {
      this.setState({ storeAssociations: formattedStores });
    }
  };

  handleSwitchChange = (name) => {
    this.setState({ [name]: !this.state[name] });
  };

  /**********\   SUPPLIER PRODUCTS SELECTION   /***********/

  handleSelectAllSupplierProducts = () => {
    const supplierProducts = JSON.parse(JSON.stringify(this.state.supplierProductAssociations));
    const allSelected = this.allSelected(supplierProducts);
    let newLines = supplierProducts.map((supplierProduct) => {
      supplierProduct.selected = !allSelected;
      return supplierProduct;
    });
    this.setState({ supplierProductAssociations: newLines });
  };

  handleSelectSupplierProduct = (line) => {
    const supplierProducts = JSON.parse(JSON.stringify(this.state.supplierProductAssociations));
    let newLines = supplierProducts.map((supplierProduct) => {
      if (supplierProduct.id === line.id) supplierProduct.selected = !supplierProduct.selected;
      return supplierProduct;
    });
    this.setState({ supplierProductAssociations: newLines });
  };

  handleSelectSupplierProductBis = (selectedSupplierProducts) => {
    const selectedSupplierProductByIds = keyBy(selectedSupplierProducts, 'id');

    const formattedSupplierProducts = this.state.supplierProductAssociations.map(
      (supplierProduct) => {
        if (selectedSupplierProductByIds[supplierProduct.id]) {
          return { ...supplierProduct, isRowSelected: true, selected: true };
        }
        return { ...supplierProduct, isRowSelected: false, selected: false };
      },
    );

    if (!isEqual(this.state.supplierProductAssociations, formattedSupplierProducts)) {
      this.setState({ supplierProductAssociations: formattedSupplierProducts });
    }
  };

  buildPackagingFormattedName = (supplierProduct) => {
    const supplierProductPackagingUsedInOrder = find(
      supplierProduct.packagings,
      (packaging) => packaging.isUsedInOrder,
    );

    const masterSPP = find(
      supplierProduct.packagings,
      (packaging) => packaging.parentSupplierProductPackagingId === null,
    );

    const packagingUnit =
      masterSPP.unit === 'unit' ? i18next.t('GENERAL.UNIT').toLowerCase() : masterSPP.unit;

    const quantityInMasterUnit = convertSPPQuantityInMasterUnit(
      supplierProductPackagingUsedInOrder.id,
      supplierProduct.packagings,
    );

    const packagingFormattedName = `${supplierProductPackagingUsedInOrder.name} (${quantityInMasterUnit} ${packagingUnit})`;

    return packagingFormattedName;
  };

  getSupplierProducts = async (supplierIds) => {
    try {
      const supplierProductsOfClient =
        await supplierProductService.getSupplierProductsGroupedByProperty(
          this.props.client.clientId,
          null,
          true,
        );

      const supplierProducts = get(supplierProductsOfClient, 'supplierProducts');

      const supplierProductsOfSuppliers = supplierProducts.filter((supplierProduct) =>
        includes(supplierIds, supplierProduct.supplierId),
      );

      let supplierProductAssociations = sortArrayOfObjectsAlphabetically(
        supplierProductsOfSuppliers,
        'name',
      );

      supplierProductAssociations = supplierProductAssociations
        .map((supplierProduct) => ({
          ...supplierProduct,
          selected: true,
          packagingFormattedName: this.buildPackagingFormattedName(supplierProduct),
        }))
        .filter((supplierProductAssociation) => supplierProductAssociation.active);

      this.setState({
        supplierProducts: supplierProductsOfSuppliers,
        supplierProductAssociations,
      });
    } catch {
      showMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.FETCH_ERROR'), 'error');
    }
  };

  /**********\   DELIVERY OPTIONS   /***********/

  addDeliveryOption = () => {
    let valid = this.areDeliveryOptionsValid();
    valid = valid.isValid;
    if (valid) {
      let updatedDeliveryOptions = this.state.deliveryOptions;
      updatedDeliveryOptions.push({ deliveryDay: '', deliveryLeadTime: '1' });
      this.setState({ deliveryOptions: [...updatedDeliveryOptions] });
    }
  };

  deleteDeliveryOption = (index) => {
    let updatedDeliveryOptions = this.state.deliveryOptions;
    updatedDeliveryOptions.splice(index, 1);
    this.setState({ deliveryOptions: [...updatedDeliveryOptions] });
  };

  handleDeliveryOptionChange = (id, index, field) => {
    const deliveryOptions = this.state.deliveryOptions;
    let res = deliveryOptions;
    res[index][field] = id;
    this.setState({ deliveryOptions: [...res] });
  };

  areExceptionalDeliveryDaysValid = () => {
    const { exceptionalDeliveryDays } = this.state;

    if (!exceptionalDeliveryDays.length) {
      return true;
    }

    return exceptionalDeliveryDays.every((edd) => edd.date && edd.type);
  };

  addExceptionalDeliveryDay = () => {
    const { exceptionalDeliveryDays } = this.state;

    this.setState({
      exceptionalDeliveryDays: [
        ...exceptionalDeliveryDays,
        { date: null, type: null, leadTime: null },
      ],
    });
  };

  deleteExceptionalDeliveryDay = (index) => {
    const { exceptionalDeliveryDays } = this.state;

    let updatedEDD = clone(exceptionalDeliveryDays);

    updatedEDD.splice(index, 1);
    this.setState({ exceptionalDeliveryDays: updatedEDD });
  };

  handleExceptionalDeliveryDays = (selectedItemId, index, field) => {
    const { exceptionalDeliveryDays } = this.state;

    let updatedEDD = clone(exceptionalDeliveryDays);

    updatedEDD[index][field] = selectedItemId;

    if (field === 'type' && selectedItemId === EXCEPTIONAL_DELIVERY_TYPES.EXCEPTIONAL_DELIVERY) {
      updatedEDD[index]['leadTime'] = '1';
    }

    this.setState({ exceptionalDeliveryDays: [...updatedEDD] });
  };

  areDeliveryOptionsValid = () => {
    let isValid = true;
    let hasDouble = false;
    let deliveryDays = [];
    this.state.deliveryOptions.forEach((delivery) => {
      if (delivery.deliveryDay === '') {
        isValid = false;
      } else {
        if (deliveryDays.includes(delivery.deliveryDay)) {
          isValid = false;
          hasDouble = true;
        }
        deliveryDays.push(delivery.deliveryDay);
      }
    });
    return { isValid, message: hasDouble ? i18next.t('ADMIN.SUPPLIERS.UNIQUE_DELIVERY_DAYS') : '' };
  };

  /**********\   RENDER   /***********/

  getPageTitle = () => {
    const { editStep, storeName, storeNamePlural } = this.state;
    const operationType = this.props.params.operationType;
    if (editStep === 1) {
      return operationType === 'create'
        ? i18next.t('ADMIN.SUPPLIERS.CREATION_MODAL_TITLE')
        : this.state.originalSupplierProfileName;
    } else if (editStep === 2) {
      if (operationType === 'delete_stores') {
        return `${i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_REMOVE_ASSOCIATED_STORES', {
          supplierProfileName: this.state.supplierProfile.name,
        })}`;
      } else if (
        (operationType === 'add_stores' ||
          operationType === 'create' ||
          operationType === 'duplicate') &&
        storeName
      ) {
        return i18next.t('ADMIN.SUPPLIERS.STORES_ASSOCIATION_MODAL_TITLE', {
          supplierProfileName: this.state.supplierProfile.name,
          storeNamePlural,
        });
      }
    } else if (editStep === 3) {
      if (operationType === 'delete_stores') {
        return i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_CONFIRM_STORES_DELETION');
      } else {
        const storesSelected = this.state.storeAssociations.filter((store) => store.selected);

        return i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PRODUCTS_ASSOCIATION_MODAL_TITLE', {
          storeName: storesSelected.length > 1 ? storeNamePlural : storeName,
          count: storesSelected.length,
        });
      }
    } else if (editStep === 4) {
      return i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PRODUCTS_PROPERTIES_MODAL_TITLE');
    }
    return '';
  };

  renderButton = (buttonStyle, onClickFunction, value, isDisabled) => (
    <Button
      buttonCustomStyle={value === 'Sauvegarder' ? { width: 136 } : { width: 110 }}
      color={buttonStyle}
      handleClick={() => onClickFunction()}
      isDisabled={isDisabled}
      label={value}
    />
  );

  handleStep1Buttons = () => {
    if (
      !canEditSupplierFromDetailView(this.props.authorizedActions) ||
      !canUpdateAssociatedSupplier(this.props.user, this.state.supplier, this.props.client)
    ) {
      return null;
    }

    if (this.state.operationType === 'edit') {
      return (
        <Button
          color={this.isSupplierProfileFormValid() ? 'blue' : 'grey'}
          handleClick={
            this.isSupplierProfileFormValid() ? () => this.handleSupplierProfileSave() : () => {}
          }
          icon={'/images/inpulse/save-white-small.svg'}
          isDisabled={!this.isSupplierProfileFormValid()}
          label={i18next.t('GENERAL.SAVE')}
        />
      );
    } else {
      return (
        <Button
          color={this.isSupplierProfileFormValid() ? 'blue' : 'grey'}
          handleClick={() => this.setState({ editStep: this.state.editStep + 1 })}
          icon={'/images/inpulse/arrow-right-white.svg'}
          isDisabled={!this.isSupplierProfileFormValid()}
          label={i18next.t('GENERAL.NEXT')}
        />
      );
    }
  };

  handleStep2Buttons = () => {
    const { operationType, storeAssociations } = this.state;
    const hasSelectedStores = storeAssociations.filter((store) => store.selected).length > 0;
    return (
      <FooterButtonContainer>
        {(operationType === 'create' || operationType === 'duplicate') && (
          <Button
            color={'blue-outline'}
            handleClick={() => this.setState({ editStep: this.state.editStep - 1 })}
            icon={'/images/inpulse/arrow-left-ip-black.svg'}
            label={i18next.t('GENERAL.BACK')}
          />
        )}
        {hasSelectedStores && (
          <Button
            color={'blue'}
            handleClick={() => this.setState({ editStep: this.state.editStep + 1 })}
            icon={'/images/inpulse/arrow-right-white.svg'}
            label={i18next.t('GENERAL.NEXT')}
          />
        )}

        {!hasSelectedStores &&
          (operationType === 'add_stores' || operationType === 'delete_stores') && (
            <Button
              color={'inpulse-outline'}
              handleClick={() => this.exitModal()}
              icon={'/images/inpulse/close-black-small.svg'}
              label={i18next.t('GENERAL.CLOSE')}
            />
          )}

        {!hasSelectedStores && (operationType === 'create' || operationType === 'duplicate') && (
          <Button
            color={'blue'}
            handleClick={() => this.handleSupplierProfileSave()}
            icon={'/images/inpulse/save-white-small.svg'}
            label={i18next.t('GENERAL.SAVE')}
          />
        )}
      </FooterButtonContainer>
    );
  };

  handleStep3Buttons = () => {
    const { operationType, supplierProductAssociations } = this.state;
    const hasSelectedSupplierProducts =
      supplierProductAssociations.filter((supplierProduct) => supplierProduct.selected).length > 0;
    return (
      <FooterButtonContainer>
        {
          <Button
            color={'blue-outline'}
            handleClick={() => {
              this.setState({ editStep: this.state.editStep - 1 });
            }}
            icon={'/images/inpulse/arrow-left-ip-black.svg'}
            label={i18next.t('GENERAL.BACK')}
          />
        }
        {operationType === 'delete_stores' && (
          <Button
            color={'blue'}
            handleClick={() => this.handleDeleteMappings()}
            icon={'/images/inpulse/save-white-small.svg'}
            label={i18next.t('GENERAL.SAVE')}
          />
        )}
        {(operationType === 'add_stores' ||
          operationType === 'create' ||
          operationType === 'duplicate') &&
          hasSelectedSupplierProducts && (
            <Button
              color={'blue'}
              handleClick={() => this.setState({ editStep: this.state.editStep + 1 })}
              icon={'/images/inpulse/arrow-right-white.svg'}
              label={i18next.t('GENERAL.NEXT')}
            />
          )}
        {(operationType === 'add_stores' ||
          operationType === 'create' ||
          operationType === 'duplicate') &&
          !hasSelectedSupplierProducts && (
            <Button
              color={'blue'}
              handleClick={() => this.handleCreateSupplierProfileWithMappings()}
              icon={'/images/inpulse/save-white-small.svg'}
              label={i18next.t('GENERAL.SAVE')}
            />
          )}
      </FooterButtonContainer>
    );
  };

  handleStep4Buttons = () => (
    <FooterButtonContainer>
      <Button
        color={'blue-outline'}
        handleClick={() => {
          this.unSelectAllStores();
          this.setState({ editStep: this.state.editStep - 1 });
        }}
        icon={'/images/inpulse/arrow-left-ip-black.svg'}
        label={i18next.t('GENERAL.BACK')}
      />
      <Button
        color={'blue'}
        handleClick={() => this.handleCreateSupplierProfileWithMappings()}
        icon={'/images/inpulse/save-white-small.svg'}
        label={i18next.t('GENERAL.SAVE')}
      />
    </FooterButtonContainer>
  );

  render() {
    const {
      deliveryOptions,
      exceptionalDeliveryDays,
      editStep,
      available,
      hasDlc,
      operationType,
      hasStock,
      storeName,
      storeNamePlural,
      storeAssociations,
      supplier,
      supplierProductAssociations,
      supplierProfile,
      countryHasUniqueTimezone,
      groups,
    } = this.state;

    return (
      <SupplierProfileModalContainer>
        <div style={{ width: '100%', height: '100%' }}>
          <div className="medium-modal-form-header">
            <div className="medium-modal-form-header-title">
              <span style={{ marginLeft: '10px' }}>{this.getPageTitle()}</span>
            </div>
          </div>
          {/************* BODY *************/}
          {editStep === 1 && (
            <SupplierProfileModalInformation
              addDeliveryOption={this.addDeliveryOption}
              addExceptionalDeliveryDay={this.addExceptionalDeliveryDay}
              areDeliveryOptionsValid={this.areDeliveryOptionsValid}
              countryHasUniqueTimezone={countryHasUniqueTimezone}
              deleteDeliveryOption={this.deleteDeliveryOption}
              deleteExceptionalDeliveryDay={this.deleteExceptionalDeliveryDay}
              deliveryOptions={deliveryOptions}
              exceptionalDeliveryDays={exceptionalDeliveryDays}
              handleDeliveryOptionChange={this.handleDeliveryOptionChange}
              handleExceptionalDeliveryDays={this.handleExceptionalDeliveryDays}
              handleSupplierProfileChange={this.handleSupplierProfileChange}
              isEditing={this.state.operationType === 'edit'}
              operationType={operationType}
              supplier={supplier}
              supplierProfile={supplierProfile}
            />
          )}
          {editStep === 2 && (
            <SupplierModalAssociationsBodyContainer>
              <SupplierModalAssociations
                columns={[
                  {
                    id: 'name',
                    propertyKey: 'name',
                    name: i18next.t('GENERAL.NAME'),
                    render: (it) => <Text highlighted>{it}</Text>,
                  },
                  {
                    id: 'isKitchen',
                    propertyKey: 'isKitchen',
                    name: i18next.t('GENERAL.STORE_TYPE'),
                    render: (isKitchen) => (
                      <Text highlighted Text>
                        {isKitchen ? i18next.t('GENERAL.CENTRAL') : i18next.t('GENERAL.SALE_POINT')}
                      </Text>
                    ),
                  },
                  {
                    id: 'groups',
                    propertyKey: 'groups',
                    name: i18next.t('GENERAL.GROUPS'),
                    filterType: 'string',
                    render: (groups) => <Tags items={groups.map(({ name }) => name)} />,
                    transform: (groups) => groups.map(({ name }) => name).join(', '),
                  },
                  {
                    id: 'country',
                    propertyKey: 'country',
                    name: i18next.t('GENERAL.COUNTRY'),
                    filterType: 'string',
                    render: (item) => <Text>{item}</Text>,
                  },
                  {
                    id: 'city',
                    propertyKey: 'city',
                    name: i18next.t('GENERAL.CITY'),
                    filterType: 'string',
                    render: (item) => <Text>{item}</Text>,
                  },
                ]}
                data={storeAssociations}
                defaultOrderBy={'name'}
                defaultOrderType={'asc'}
                emptyStateText={i18next.t(
                  ['delete_stores'].includes(operationType)
                    ? 'ADMIN.SUPPLIERS.ASSOCIATION_MODAL_NONE_EMPTY_STATE'
                    : 'ADMIN.SUPPLIERS.NO_AVAILABLE_STORE_EMPTY_STATE',
                  {
                    objectName: storeNamePlural,
                    storeName,
                  },
                )}
                groups={groups}
                setSelectedItems={this.handleSelectStoreBis}
                forceEnableSelection
              />
            </SupplierModalAssociationsBodyContainer>
          )}
          {editStep === 3 && operationType === 'delete_stores' && (
            <SupplierProfileModalDeletionConfirm
              storeName={storeName}
              stores={storeAssociations}
              supplier={supplier}
              supplierProfile={supplierProfile}
            />
          )}
          {editStep === 3 &&
            (operationType === 'add_stores' ||
              operationType === 'create' ||
              operationType === 'duplicate') && (
              <SupplierModalAssociations
                columns={[
                  {
                    id: 'name',
                    propertyKey: 'name',
                    name: i18next.t('GENERAL.NAME'),
                    render: (it) => <Text highlighted>{it}</Text>,
                  },
                  {
                    id: 'sku',
                    propertyKey: 'sku',
                    name: i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_REFERENCE_COLUMN_TITLE'),
                    filterType: 'string',
                    render: (item) => <Text>{item}</Text>,
                  },
                  {
                    id: 'packagingFormattedName',
                    propertyKey: 'packagingFormattedName',
                    name: i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_PACKAGING_COLUMN_TITLE'),
                    filterType: 'string',
                    render: (item) => <Text>{item}</Text>,
                  },
                ]}
                data={supplierProductAssociations}
                defaultOrderBy={'name'}
                defaultOrderType={'asc'}
                emptyStateText={i18next.t(
                  ['delete_stores'].includes(operationType)
                    ? 'ADMIN.SUPPLIERS.ASSOCIATION_MODAL_NONE_EMPTY_STATE'
                    : 'ADMIN.SUPPLIERS.ASSOCIATION_MODAL_ALL_EMPTY_STATE',
                  {
                    objectName: i18next.t('GENERAL.SUPPLIER_PRODUCT_PLURAL').toLowerCase(),
                  },
                )}
                setSelectedItems={this.handleSelectSupplierProductBis}
              />
            )}
          {editStep === 4 && (
            <SupplierProfileModalProperties
              available={available}
              handleSwitchChange={this.handleSwitchChange}
              hasDlc={hasDlc}
              hasStock={hasStock}
            />
          )}
          {/************* FOOTER BUTTONS *************/}
          <div className="medium-modal-form-footer">
            {editStep === 1 && (
              <FooterButtonContainer>
                <Button
                  color={'blue-outline-no-shadow'}
                  handleClick={() => this.props.closeModal()}
                  icon={'/images/inpulse/close-black-small.svg'}
                  label={this.props.t('GENERAL.CANCEL')}
                />
                {this.handleStep1Buttons()}
              </FooterButtonContainer>
            )}
            {editStep === 2 && this.handleStep2Buttons()}
            {editStep === 3 && this.handleStep3Buttons()}
            {editStep === 4 && this.handleStep4Buttons()}
          </div>
        </div>
      </SupplierProfileModalContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  supplierProducts: state.baseReducer.supplierProducts,
  client: getClientInfo(state.baseReducer.user),
  shouldReloadApp: state.baseReducer.shouldReloadApp,
  authorizedActions: getAuthorizedActions(
    state.baseReducer.userRights,
    '/admin/suppliers/:id/details',
  ),
  user: state.baseReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  getStoreSupplierProfileMappingsOfSupplier: (supplierId) =>
    dispatch(getStoreSupplierProfileMappingsOfSupplier(supplierId)).then(
      (result) => {
        dispatch(receiveStoreSupplierProfileMappingsOfSupplier(result));
        return result;
      },
      (error) => dispatch(requestStoreSupplierProfileMappingsOfSupplierError(error)),
    ),
  patchSupplierProfile: (
    supplierProfile,
    exceptionalDeliveryDays = [],
    userTimezone = 'Europe/Paris',
  ) =>
    dispatch(patchSupplierProfile(supplierProfile, exceptionalDeliveryDays, userTimezone)).then(
      (result) => {
        dispatch(patchSupplierProfileSuccess(result));
        return result;
      },
      (error) => {
        dispatch(patchSupplierProfileError(error));
        throw 'Error';
      },
    ),
  postSupplierProfileWithMappings: (
    supplierProfile,
    storeIds,
    supplierProducts,
    cloneFromStoreId,
  ) =>
    dispatch(
      postSupplierProfileWithMappings(
        supplierProfile,
        storeIds,
        supplierProducts,
        cloneFromStoreId,
      ),
    ).then(
      (result) => {
        dispatch(postSupplierProfileWithMappingsSuccess(result));
      },
      (error) => {
        dispatch(postSupplierProfileWithMappingsError(error));
        throw 'Error';
      },
    ),
  deleteStoreSupplierProfileMappings: (supplierProfileMappingIds) =>
    dispatch(deleteStoreSupplierProfileMappings(supplierProfileMappingIds)).then(
      (result) => {
        dispatch(deleteStoreSupplierProfileMappingsSuccess(result));
      },
      (error) => {
        dispatch(deleteStoreSupplierProfileMappingsError(error));
        throw 'Error';
      },
    ),
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default SupplierProfileModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(SupplierProfileModal));
