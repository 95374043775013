import axios from '../core/http';

import { config } from '../config';

const baseApiUrl = `${config.baseApiUrl}/v1`;

export const getAllEventSubCategories = () =>
  axios
    .get(`${baseApiUrl}/admin/events/sub-categories`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

export const eventSubCategory = {
  getAllEventSubCategories,
};
