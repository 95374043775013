import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import sentryService from './providers/sentry';

import translationCN from './translations/cn.json';
import translationEN from './translations/en.json';
import translationFR from './translations/fr.json';
import translationIT from '../src/translations/it.json';
import translationTH from './translations/th.json';
import translationVI from '../src/translations/vi.json';

const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  th: {
    translation: translationTH,
  },
  vi: {
    translation: translationVI,
  },
  cn: {
    translation: translationCN,
  },
  it: {
    translation: translationIT,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    saveMissing: true,
    missingKeyHandler: (lng, ns, key, fallbackValue) => {
      sentryService.send(`Missing translation key : ${key} in fallback language resource (${lng})`);

      const isProduction =
        process.env.NODE_ENV === 'production' && process.env.REACT_APP_BUILD_ENV === 'production';

      if (!isProduction) {
        console.log(`Missing translation key : ${key} in fallback language resource (${lng})`);
      }
    },
  });

export default i18n;
