import { config } from '../config';
import { setLocalStorageItem } from '../commons/utils/localStorage';
import axios from '../core/http';

const baseApiUrl = `${config.baseApiUrl}/v1`;

const verifyEligibility = async (email) => {
  try {
    const response = await axios.post(
      baseApiUrl + '/signin/sso/cryptr/verify',
      {
        email,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

const login = async (accessToken) => {
  try {
    const response = await axios.post(
      baseApiUrl + '/signin/sso/cryptr/generate-token',
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    setLocalStorageItem('loopbackAccessToken', response.data.token);
    setLocalStorageItem('userId', response.data.user.id);

    return response.data.user;
  } catch (error) {
    throw error;
  }
};

export default {
  verifyEligibility,
  login,
};
