import { isEmpty, get, cloneDeep, isEqualWith, isEqual } from 'lodash';
import i18next from 'i18next';
import onClickOutside from 'react-onclickoutside';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { ANALYTICS_TYPE } from '@commons/constants/analyticsType';
import { Button } from '@commons/utils/styledLibraryComponents';
import { getFiltersParams } from '@commons/DeepsightAnalyticsHeaders/constants';
import { sortArrayOfObjectsAlphabetically } from '@commons/utils/sorting';

import { Container, Footer, AdvancedFiltersButtonContainer } from './styledComponents';
import AdvancedFiltersBlock from './components/AdvancedFiltersBlock';
import AppliedFiltersBlock from './components/AppliedFiltersBlock';

function DeepsightFiltersModal(props) {
  const {
    setFiltersModalOpen,
    setIsFilterActive,
    params: {
      countries,
      selectedCountry,
      setSelectedCountry,
      locations,
      selectedLocations,
      setSelectedLocations,
      brands,
      selectedBrands,
      setSelectedBrands,
      retailers,
      selectedRetailers,
      setSelectedRetailers,
      groups,
      selectedGroups,
      setSelectedGroups,
      stores,
      selectedStore,
      setSelectedStore,
      selectedStores,
      setSelectedStores,
      selectedCatalogs,
      setSelectedCatalogs,
      catalogs,
      suppliers,
      selectedSupplier,
      setSelectedSupplier,
      selectedSuppliers,
      setSelectedSuppliers,
      fetchCustomSupplierList,
      readOnly,
      filters,
      setFilters,
      columnsFilterList,
      advancedFilters,
      setAdvancedFilters,
      analyticsType,
      setApplyFilters,
      metrics,
      customMultipleDropDowns,
      customSingleDropDowns,
      customToggles,
      disableAdvancedFilter,
    },
  } = props;

  const [filtersInitialized, setFiltersInitialized] = useState(false);

  const [isFiltersModified, setIsFiltersModified] = useState(false);

  const [displayAdvancedFilter, setDisplayAdvancedFilter] = useState(false);

  const [activeColumnsList, setActiveColumnsList] = useState([]);

  const [isFiltersApplying, setIsFiltersApplying] = useState(false);

  const hasAllowsNullDateFilter = columnsFilterList
    ? columnsFilterList.find((column) => column.filterType === 'date' && column.filterAllowsNull)
    : false;

  const advancedFiltersParams = getFiltersParams(hasAllowsNullDateFilter);

  const [originalAdvancedFilters] = useState(advancedFilters);

  const filterOnCatalogs = catalogs && !!catalogs.length;

  useEffect(() => {
    setApplyFilters(false);

    if (!advancedFilters) {
      return;
    }

    const updatedAdvancedFilters = advancedFilters.reduce((updatedFilters, filter) => {
      if (filter.hasOwnProperty('value')) {
        updatedFilters.push({
          ...filter,
          display: true,
        });
      }
      return updatedFilters;
    }, []);

    setAdvancedFilters(updatedAdvancedFilters);
  }, []);

  useEffect(() => {
    if (!advancedFilters) {
      return;
    }

    const activeAdvancedFilters = advancedFilters.filter((filter) => filter.display);

    const activeColumns = activeAdvancedFilters.map((filter) => filter.columnName);

    const activeColumnsList = columnsFilterList.reduce((activeColumnsList, column) => {
      if (!activeColumns.includes(column.name)) {
        activeColumnsList.push(column);
      }
      return activeColumnsList;
    }, []);

    setActiveColumnsList(activeColumnsList);
  }, [advancedFilters, columnsFilterList]);

  // Pre select first advanced filter when not global filter is set
  useEffect(() => {
    if (
      filtersInitialized &&
      (!filters || isEmpty(filters)) &&
      columnsFilterList &&
      columnsFilterList.length &&
      (!advancedFilters || !advancedFilters.length)
    ) {
      initializeAdvancedFilters();
    }
  }, [filtersInitialized, filters, advancedFilters]);

  useEffect(() => {
    if (filters !== null) {
      return;
    }

    initializeFilters();
  }, [selectedStore, analyticsType]);

  useEffect(() => {
    const activeAdvancedFilters = advancedFilters
      ? advancedFilters.filter((filter) => filter.display && (filter.value || filter.value === 0))
      : [];

    const isFiltersSetupClean =
      JSON.stringify(originalAdvancedFilters) ===
      JSON.stringify(activeAdvancedFilters.length ? activeAdvancedFilters : null);

    if (
      !advancedFilters ||
      (advancedFilters && !advancedFilters.length) ||
      !advancedFilters.some((filter) => filter.display)
    ) {
      setDisplayAdvancedFilter(false);
      setIsFiltersModified(!isFiltersSetupClean);
      setIsFilterActive(false);
      return;
    }
    if (advancedFilters[0] && !advancedFilters[0].value && advancedFilters[0].value !== 0) {
      setDisplayAdvancedFilter(true);
      setIsFiltersModified(!isFiltersSetupClean);
      setIsFilterActive(false);
      return;
    }

    setDisplayAdvancedFilter(true);
    setIsFiltersModified(!isFiltersSetupClean);
    setIsFilterActive(true);
  }, [advancedFilters]);

  const isFormValid = () => {
    if (!isFiltersModified) {
      return false;
    }

    const areAdvancedFilterClean = advancedFilters
      ? advancedFilters.every((advancedFilter) => {
          if (!advancedFilter.display) {
            return true;
          }
          if (advancedFilter.paramName !== i18next.t('GENERAL.VALUE_SELECTOR_FILTER_BETWEEN')) {
            return !!advancedFilter.value || advancedFilter.value === 0;
          }

          return (
            (!!advancedFilter.limitValues.minValue || advancedFilter.limitValues.minValue === 0) &&
            (!!advancedFilter.limitValues.maxValue || advancedFilter.limitValues.maxValue === 0)
          );
        })
      : true;

    if (!areAdvancedFilterClean) {
      return false;
    }

    if (
      !!filters.catalogs &&
      !!filters.suppliers &&
      !get(filters, 'suppliers.selectedSuppliers.length', 0) &&
      !get(filters, 'suppliers.selectedSupplier')
    ) {
      return false;
    }

    if (get(filters, 'stores.selectedStore')) {
      return true;
    }

    if (get(filters, 'stores.selectedStores', []).length >= 1) {
      return true;
    }

    if (isEmpty(filters)) {
      return true;
    }

    if (!customMultipleDropDowns) {
      return true;
    }

    return customMultipleDropDowns.every(
      ({ id }) => get(filters, `${id}.selectedItems`, []).length >= 1,
    );
  };

  const initializeAdvancedFilters = () => {
    const defaultColumn = columnsFilterList[0];
    const defaultFilterParam = advancedFiltersParams[defaultColumn.filterType].content[0];

    if (!setAdvancedFilters) {
      return;
    }

    setAdvancedFilters([
      {
        activeColumnsList: columnsFilterList,
        filterId: 0,
        columnName: defaultColumn.name,
        propertyKey: defaultColumn.propertyKey,
        filterType: defaultColumn.filterType,
        filterStringAllowNone: defaultColumn.filterStringAllowNone,
        list: defaultColumn.list,
        paramName: defaultFilterParam.name,
        doFilter: defaultFilterParam.doFilter,
        getQueryParam: defaultFilterParam.getQueryParam,
        value: '',
        limitValues: { minValue: '', maxValue: '' },
        display: true,
      },
    ]);
    setDisplayAdvancedFilter(true);
  };

  const updateAdvancedFilters = (filterId, name, propertyKey, value) => {
    const updatedAdvancedFilters = cloneDeep(advancedFilters);

    if (name === 'remove') {
      updatedAdvancedFilters[filterId] = {
        ...updatedAdvancedFilters[filterId],
        display: false,
      };
      setAdvancedFilters(updatedAdvancedFilters);
      return;
    }

    if (name === 'value') {
      updatedAdvancedFilters[filterId] = {
        ...updatedAdvancedFilters[filterId],
        [name]: value,
        [propertyKey]: value,
      };
    } else {
      updatedAdvancedFilters[filterId] = {
        ...updatedAdvancedFilters[filterId],
        list: value.list,
        [name]: value.name,
        [propertyKey]: value[propertyKey],
        // Reinitialize selection
        value: '',
        values: [],
        filterStringAllowNone: value.filterStringAllowNone,
      };

      if (propertyKey === 'doFilter') {
        updatedAdvancedFilters[filterId].getQueryParam = value.getQueryParam;
      }

      // Update filter whenever we change its type
      if (value.filterType && updatedAdvancedFilters[filterId].filterType !== value.filterType) {
        const defaultFilterParam = advancedFiltersParams[value.filterType].content[0];

        updatedAdvancedFilters[filterId].value = '';
        updatedAdvancedFilters[filterId].filterType = value.filterType;
        updatedAdvancedFilters[filterId].limitValues = { minValue: '', maxValue: '' };
        updatedAdvancedFilters[filterId].filterStringAllowNone = value.filterStringAllowNone;

        updatedAdvancedFilters[filterId].list = value.list;
        updatedAdvancedFilters[filterId].paramName = defaultFilterParam.name;
        updatedAdvancedFilters[filterId].doFilter = defaultFilterParam.doFilter;
        updatedAdvancedFilters[filterId].getQueryParam = defaultFilterParam.getQueryParam;
      }
    }
    setAdvancedFilters(updatedAdvancedFilters);
  };

  const addNewAdvancedFilter = () => {
    const activeAdvancedFilters = advancedFilters.filter((filter) => filter.display);
    const activeColumns = activeAdvancedFilters.map((filter) => filter.columnName);

    const activeColumnsList = columnsFilterList.reduce((activeColumnsList, column) => {
      if (!activeColumns.includes(column.name)) {
        activeColumnsList.push(column);
      }
      return activeColumnsList;
    }, []);

    const defaultColumn = activeColumnsList[0];
    const defaultFilterParam = advancedFiltersParams[defaultColumn.filterType].content[0];
    const filterIds = advancedFilters.map((filter) => filter.filterId);
    const newFilterId = Math.max(...filterIds) + 1;

    setAdvancedFilters([
      ...advancedFilters,
      {
        filterId: newFilterId,
        columnName: defaultColumn.name,
        propertyKey: defaultColumn.propertyKey,
        filterType: defaultColumn.filterType,
        list: defaultColumn.list,
        filterStringAllowNone: defaultColumn.filterStringAllowNone,
        paramName: defaultFilterParam.name,
        doFilter: defaultFilterParam.doFilter,
        getQueryParam: defaultFilterParam.getQueryParam,
        value: '',
        limitValues: { minValue: '', maxValue: '' },
        display: true,
      },
    ]);
  };

  const saveAdvancedFilters = () => {
    if (!advancedFilters) {
      return;
    }

    const updatedAdvancedFilters = advancedFilters.filter(
      (filter) => (filter.value || filter.value === 0) && filter.display,
    );

    const reIndexedAdvancedFilters = updatedAdvancedFilters.map((filter, index) => ({
      ...filter,
      filterId: index,
    }));

    setAdvancedFilters(reIndexedAdvancedFilters);
  };

  const initializeFilters = () => {
    const updatedFilters = {};

    if (countries && !!countries.length) {
      updatedFilters.countries = {
        list: countries,
        selectedCountry,
      };
    }

    if (locations && !!locations.length) {
      updatedFilters.locations = {
        list: locations,
        selectedLocations,
      };
    }

    if (brands && !!brands.length) {
      updatedFilters.brands = {
        list: brands,
        selectedBrands,
      };
    }

    if (retailers && !!retailers.length) {
      updatedFilters.retailers = {
        list: retailers,
        selectedRetailers,
      };
    }

    if (groups && !!groups.length) {
      updatedFilters.groups = {
        list: groups,
        selectedGroups,
      };
    }

    if (stores) {
      const activeStores =
        analyticsType !== ANALYTICS_TYPE.ORDER
          ? filterStores(
              stores,
              selectedLocations,
              selectedBrands,
              selectedRetailers,
              selectedGroups,
            )
          : stores;
      updatedFilters.stores = {
        list: stores,
        activeList: activeStores,
        selectedStore,
        selectedStores: selectedStores && activeStores,
      };
    }

    if (filterOnCatalogs) {
      updatedFilters.catalogs = {
        list: catalogs,
        selectedCatalogs,
      };
    }

    if (suppliers) {
      const activeSuppliers =
        analyticsType === ANALYTICS_TYPE.ORDER || (!!catalogs && catalogs.length)
          ? filterSuppliers(
              suppliers,
              selectedStores ? selectedStores : !!selectedStore ? [selectedStore] : [],
              selectedCatalogs,
            )
          : suppliers;

      updatedFilters.suppliers = {
        list: suppliers,
        activeList: activeSuppliers || suppliers,
        selectedSupplier: selectedSupplier && activeSuppliers && (activeSuppliers[0] || {}),
        selectedSuppliers:
          selectedSuppliers && selectedSuppliers.length ? selectedSuppliers : activeSuppliers,
      };
    }

    if (customMultipleDropDowns) {
      customMultipleDropDowns.forEach((customMultipleDropDown) => {
        updatedFilters[customMultipleDropDown.id] = {
          ...customMultipleDropDown,
        };
      });
    }

    if (customSingleDropDowns) {
      customSingleDropDowns.forEach((customSingleDropDown) => {
        updatedFilters[customSingleDropDown.id] = {
          ...customSingleDropDown,
        };
      });
    }

    if (customToggles) {
      customToggles.forEach((customToggle) => {
        updatedFilters[customToggle.id] = {
          ...customToggle,
        };
      });
    }

    setFilters(updatedFilters);

    setFiltersInitialized(true);
  };

  const cancel = () => {
    const initialFilters = {};

    if (countries && !!countries.length) {
      initialFilters.countries = {
        list: countries,
        selectedCountry,
      };
    }

    if (locations && !!locations.length) {
      initialFilters.locations = {
        list: locations,
        selectedLocations,
      };
    }

    if (brands && !!brands.length) {
      initialFilters.brands = {
        list: brands,
        selectedBrands,
      };
    }

    if (retailers && !!retailers.length) {
      initialFilters.retailers = {
        list: retailers,
        selectedRetailers,
      };
    }

    if (groups && !!groups.length) {
      initialFilters.groups = {
        list: groups,
        selectedGroups,
      };
    }

    if (stores) {
      initialFilters.stores = {
        list: stores,
        activeList: filterStores(
          stores,
          selectedLocations,
          selectedBrands,
          selectedRetailers,
          selectedGroups,
        ),
        selectedStore: selectedStore || undefined,
        selectedStores,
      };
    }

    if (filterOnCatalogs) {
      initialFilters.catalogs = {
        list: catalogs,
        selectedCatalogs,
      };
    }

    if (suppliers) {
      const activeSuppliers =
        analyticsType === ANALYTICS_TYPE.ORDER || (!!catalogs && catalogs.length)
          ? filterSuppliers(suppliers, selectedStores, selectedCatalogs)
          : suppliers;

      initialFilters.suppliers = {
        list: suppliers,
        activeList: activeSuppliers,
        selectedSupplier: selectedSupplier && activeSuppliers && (activeSuppliers[0] || {}),
        selectedSuppliers:
          selectedSuppliers && selectedSuppliers.length ? selectedSuppliers : activeSuppliers,
      };
    }

    if (customMultipleDropDowns) {
      customMultipleDropDowns.forEach((customMultipleDropDown) => {
        initialFilters[customMultipleDropDown.id] = {
          ...customMultipleDropDown,
          selectedItems:
            customMultipleDropDown.originalSelectedItems ||
            customMultipleDropDown.defaultSelectedItems ||
            [],
        };
      });
    }

    if (customSingleDropDowns) {
      customSingleDropDowns.forEach((customSingleDropDown) => {
        initialFilters[customSingleDropDown.id] = {
          ...customSingleDropDown,
          selectedItem: customSingleDropDown.selectedItem
            ? customSingleDropDown.selectedItem
            : customSingleDropDown.defaultSelectedItem,
        };
      });
    }

    if (customToggles) {
      customToggles.forEach((customToggle) => {
        initialFilters[customToggle.id] = {
          ...customToggle,
        };
      });
    }

    setFilters(initialFilters);

    if (!!advancedFilters && !!setAdvancedFilters) {
      setAdvancedFilters(originalAdvancedFilters);
    }
  };

  const resetFilters = () => {
    const initialFilters = {};

    if (countries && !!countries.length) {
      initialFilters.countries = {
        list: countries,
        selectedCountry: [],
      };
    }

    if (locations && !!locations.length) {
      initialFilters.locations = {
        list: locations,
        selectedLocations: [],
      };
    }

    if (brands && !!brands.length) {
      initialFilters.brands = {
        list: brands,
        selectedBrands: [],
      };
    }

    if (retailers && !!retailers.length) {
      initialFilters.retailers = {
        list: retailers,
        selectedRetailers: [],
      };
    }

    if (groups && !!groups.length) {
      initialFilters.groups = {
        list: groups,
        selectedGroups: [],
      };
    }

    if (stores) {
      initialFilters.stores = {
        list: stores,
        activeList: stores,
        selectedStore: (selectedStore && stores[0]) || undefined,
        selectedStores: selectedStores && stores,
      };
    }

    if (filterOnCatalogs) {
      initialFilters.catalogs = {
        list: catalogs,
        selectedCatalogs: [],
      };
    }

    if (suppliers) {
      const activeSuppliers =
        analyticsType === ANALYTICS_TYPE.ORDER || (!!catalogs && catalogs.length)
          ? filterSuppliers(
              suppliers,
              selectedStores ? selectedStores : stores ? [stores[0]] : [],
              selectedCatalogs,
            )
          : suppliers;

      initialFilters.suppliers = {
        list: suppliers,
        activeList: activeSuppliers,
        selectedSupplier: selectedSupplier && activeSuppliers && (activeSuppliers[0] || {}),
        selectedSuppliers: selectedSuppliers && activeSuppliers,
      };
    }

    if (customMultipleDropDowns) {
      customMultipleDropDowns.forEach((customMultipleDropDown) => {
        initialFilters[customMultipleDropDown.id] = {
          ...customMultipleDropDown,
          selectedItems: !!customMultipleDropDown.defaultSelectedItems
            ? customMultipleDropDown.defaultSelectedItems
            : [],
        };
      });
    }

    if (customSingleDropDowns) {
      customSingleDropDowns.forEach((customSingleDropDown) => {
        initialFilters[customSingleDropDown.id] = {
          ...customSingleDropDown,
          selectedItem: !!customSingleDropDown.defaultSelectedItem
            ? customSingleDropDown.defaultSelectedItem
            : [],
        };
      });
    }

    if (customToggles) {
      customToggles.forEach((customToggle) => {
        initialFilters[customToggle.id] = {
          ...customToggle,
          value: true,
        };
      });
    }

    setFilters(initialFilters);

    if (!!advancedFilters && !!setAdvancedFilters) {
      setAdvancedFilters(null);
    }

    setIsFiltersModified(true);
  };

  const filterStores = (
    stores,
    selectedLocations,
    selectedBrands,
    selectedRetailers,
    selectedGroups,
  ) => {
    let filteredStores = stores;

    if (selectedLocations && selectedLocations.length) {
      const locationsIds = selectedLocations.map((location) => location.id);

      filteredStores = filteredStores.filter((store) => locationsIds.includes(store.locationId));
    }

    if (selectedBrands && selectedBrands.length) {
      const brandIdsSet = new Set(selectedBrands.map((brand) => brand.id));

      filteredStores = filteredStores.filter(
        ({ brandId }) => brandIdsSet.has(brandId) || (brandIdsSet.has(-1) && !brandId), // -1 is the "None" option
      );
    }

    if (selectedRetailers && selectedRetailers.length) {
      const retailersIds = selectedRetailers.map((retailer) => retailer.id);

      filteredStores = filteredStores.filter((store) => retailersIds.includes(store.retailerId));
    }

    if (selectedGroups && selectedGroups.length) {
      const groupStoreIds = selectedGroups.reduce(
        (storeIds, group) => storeIds.concat(group.storeIds),
        [],
      );

      filteredStores = filteredStores.filter((store) => groupStoreIds.includes(store.id));
    }

    return filteredStores;
  };

  const filterSuppliers = (suppliers, selectedStores, selectedCatalogs) => {
    let filteredSuppliers = suppliers.filter((supplier) => supplier.active);

    if (selectedCatalogs && selectedCatalogs.length) {
      const selectedCatalogsIds = new Set(selectedCatalogs.map(({ id }) => id));

      filteredSuppliers = filteredSuppliers.filter(({ catalogId }) =>
        selectedCatalogsIds.has(catalogId),
      );
    }

    if (selectedStores && selectedStores.length) {
      const selectedStoresIds = selectedStores.map((store) => store.id);

      filteredSuppliers = filteredSuppliers.reduce((activeSuppliers, supplier) => {
        const storesMappedIds = supplier.storesMapped.map((mapping) => mapping.storeId);

        if (selectedStoresIds.some((storeId) => storesMappedIds.includes(storeId))) {
          activeSuppliers.push(supplier);
        }

        return activeSuppliers;
      }, []);
    }

    return filteredSuppliers;
  };

  const updateFilters = async (filterName, propertyKey, value) => {
    setIsFiltersApplying(true);
    setIsFiltersModified(true);
    const updatedFilters = {
      ...filters,
      [filterName]: {
        ...filters[filterName],
        [propertyKey]: value,
      },
    };

    const filterNamesCustomMultipleDropDowns = customMultipleDropDowns
      ? customMultipleDropDowns.map(({ id }) => id)
      : [];

    const filterNameCustomSingleDropDowns = customSingleDropDowns
      ? customSingleDropDowns.map(({ id }) => id)
      : [];

    const filterNamesCustomToggles = customToggles ? customToggles.map(({ id }) => id) : [];

    // update the list of activeStores (stores to display in stores filter) according to the selection of the other filters (brands, retailers, groups)
    if (
      filterName !== 'stores' &&
      filterName !== 'suppliers' &&
      !filterNamesCustomMultipleDropDowns.includes(filterName) &&
      !filterNameCustomSingleDropDowns.includes(filterName) &&
      !filterNamesCustomToggles.includes(filterName) &&
      !!updatedFilters.stores
    ) {
      const filteredStores = filterStores(
        updatedFilters.stores.list,
        get(updatedFilters, 'locations.selectedLocations', []),
        get(updatedFilters, 'brands.selectedBrands', []),
        get(updatedFilters, 'retailers.selectedRetailers', []),
        get(updatedFilters, 'groups.selectedGroups', []),
      );

      updatedFilters.stores.activeList = filteredStores;

      if (updatedFilters.stores.selectedStore) {
        updatedFilters.stores.selectedStore = filteredStores[0] || {};
      }

      if (updatedFilters.stores.selectedStores) {
        updatedFilters.stores.selectedStores = filteredStores;
      }
    }

    // update the list of activeSuppliers (suppliers to display in suppliers filter) according to the selection of stores
    if (
      filterName !== 'suppliers' &&
      (analyticsType === ANALYTICS_TYPE.ORDER || (!!catalogs && !!catalogs.length)) &&
      !isEmpty(updatedFilters.suppliers)
    ) {
      const currentlySelectedStores = get(updatedFilters, 'stores.selectedStores', []);
      const currentlySelectedStore = get(updatedFilters, 'stores.selectedStore');

      const currentlySelectedCatalogs = get(updatedFilters, 'catalogs.selectedCatalogs', []);

      let storesToFilterOn = [];

      if (currentlySelectedStores.length) {
        storesToFilterOn = currentlySelectedStores;
      } else if (!!currentlySelectedStore) {
        storesToFilterOn = [currentlySelectedStore];
      }

      const filteredSuppliers = filterSuppliers(
        updatedFilters.suppliers.list,
        storesToFilterOn,
        currentlySelectedCatalogs,
      );

      updatedFilters.suppliers.activeList = filteredSuppliers;

      if (updatedFilters.suppliers.selectedSupplier) {
        updatedFilters.suppliers.selectedSupplier = filteredSuppliers[0] || {};
      }

      if (updatedFilters.suppliers.selectedSuppliers) {
        updatedFilters.suppliers.selectedSuppliers = filteredSuppliers;
      }
    }

    if (filterName !== 'suppliers' && fetchCustomSupplierList) {
      const storeIds = updatedFilters.stores.selectedStores.map((store) => store.id);

      const customSuppliersList = await fetchCustomSupplierList(storeIds);

      const areAllSuppliersSelected =
        updatedFilters.suppliers.activeList.length ===
        updatedFilters.suppliers.selectedSuppliers.length;

      const sortedCustomSuppliersList = sortArrayOfObjectsAlphabetically(customSuppliersList, [
        'name',
      ]);

      updatedFilters.suppliers.list = sortedCustomSuppliersList;
      updatedFilters.suppliers.activeList = sortedCustomSuppliersList;

      if (updatedFilters.suppliers.selectedSupplier) {
        const shouldKeepSelection = sortedCustomSuppliersList.some(
          (supplier) => supplier.id === updatedFilters.suppliers.selectedSupplier.id,
        );

        if (!shouldKeepSelection) {
          updatedFilters.suppliers.selectedSupplier = sortedCustomSuppliersList[0] || {};
        }
      }

      if (updatedFilters.suppliers.selectedSuppliers) {
        const shouldKeepSelection =
          updatedFilters.suppliers.selectedSuppliers.every((selectedSupplier) =>
            sortedCustomSuppliersList.some((supplier) => supplier.id === selectedSupplier.id),
          ) && !areAllSuppliersSelected;

        if (!shouldKeepSelection) {
          updatedFilters.suppliers.selectedSuppliers = sortedCustomSuppliersList;
        }
      }
    }

    setFilters(updatedFilters);
    setIsFiltersApplying(false);
  };

  const save = () => {
    if (customMultipleDropDowns) {
      customMultipleDropDowns.forEach(({ id }) => {
        if (filters[id]) {
          filters[id].setSelectedItems(filters[id].selectedItems);
        }
      });
    }

    if (customSingleDropDowns) {
      customSingleDropDowns.forEach(({ id }) => {
        if (filters[id]) {
          filters[id].setSelectedItem(filters[id].selectedItem);
        }
      });
    }

    if (customToggles) {
      customToggles.forEach(({ id }) => {
        if (filters[id]) {
          filters[id].setToggleValue(filters[id].value);
        }
      });
    }

    if (filters.countries) {
      setSelectedCountry(filters.countries.selectedCountry);
    }

    if (filters.locations) {
      setSelectedLocations(filters.locations.selectedLocations);
    }

    if (filters.brands) {
      setSelectedBrands(filters.brands.selectedBrands);
    }

    if (filters.retailers) {
      setSelectedRetailers(filters.retailers.selectedRetailers);
    }

    if (filters.groups) {
      setSelectedGroups(filters.groups.selectedGroups);
    }

    if (filters.catalogs) {
      setSelectedCatalogs(filters.catalogs.selectedCatalogs);
    }

    if (filters.stores) {
      if (filters.stores.selectedStore) {
        setSelectedStore(filters.stores.selectedStore);
      } else if (
        filters.stores.selectedStores &&
        !isEqualWith(selectedStores, filters.stores.selectedStores, isEqual)
      ) {
        setSelectedStores(filters.stores.selectedStores);
      }
    }

    if (filters.suppliers) {
      if (filters.suppliers.selectedSupplier) {
        setSelectedSupplier(filters.suppliers.selectedSupplier);
      } else if (filters.suppliers.selectedSuppliers) {
        setSelectedSuppliers(filters.suppliers.selectedSuppliers);
      }
    }

    saveAdvancedFilters();
    setApplyFilters(true);
    setFiltersModalOpen(false);
  };

  const checkAdvancedFiltersExistence = () => {
    if (!advancedFilters) {
      return true;
    }

    return advancedFilters.some((filter) => filter.display === false);
  };

  DeepsightFiltersModal.handleClickOutside = (event) => {
    // To not override onClick on button to open modal that checks if modal is already opened
    setTimeout(() => {
      cancel();
      setFiltersModalOpen(false);
    }, 100);
  };

  const filterNamesCustomMultipleDropDowns = customMultipleDropDowns
    ? customMultipleDropDowns.map(({ id }) => id)
    : [];

  const filterNamesCustomSingleDropDowns = customSingleDropDowns
    ? customSingleDropDowns.map(({ id }) => id)
    : [];

  const filterNamesCustomToggles = customToggles ? customToggles.map(({ id }) => id) : [];

  return (
    <Container positionLeft={!metrics} slide={!!metrics}>
      {filters && !isEmpty(filters) && (
        <AppliedFiltersBlock
          filterNamesCustomMultipleDropDowns={filterNamesCustomMultipleDropDowns}
          filterNamesCustomSingleDropDowns={filterNamesCustomSingleDropDowns}
          filterNamesCustomToggles={filterNamesCustomToggles}
          filters={filters}
          readOnly={readOnly}
          setFilters={updateFilters}
        />
      )}
      {!disableAdvancedFilter && (
        <>
          {columnsFilterList &&
            !displayAdvancedFilter &&
            (!advancedFilters ||
              (advancedFilters && !advancedFilters.length) ||
              advancedFilters.every((filter) => !filter.display)) && (
              <AdvancedFiltersButtonContainer>
                <Button
                  color={'inpulse-link'}
                  formatText={false}
                  handleClick={() => initializeAdvancedFilters()}
                  icon={'/images/inpulse/add-black.svg'}
                  iconOnLeft={false}
                  label={i18next.t('GENERAL.ANALYTICS_ADVANCED_FILTERS')}
                  minWidth={165}
                />
              </AdvancedFiltersButtonContainer>
            )}
          {displayAdvancedFilter && advancedFilters && !!advancedFilters.length && (
            <AdvancedFiltersBlock
              activeColumnsList={activeColumnsList}
              addNewAdvancedFilter={addNewAdvancedFilter}
              borderTopRadius={!filters || isEmpty(filters)}
              columnsList={columnsFilterList}
              filters={advancedFilters}
              filtersParams={advancedFiltersParams}
              setFilters={updateAdvancedFilters}
            />
          )}
        </>
      )}
      <Footer>
        <Button
          color={'inpulse-grey'}
          handleClick={() => {
            cancel();
            setFiltersModalOpen(false);
          }}
          icon={'/images/inpulse/close-white-small.svg'}
          label={i18next.t('GENERAL.CANCEL')}
        />
        <div>
          <Button
            color={'inpulse-outline'}
            handleClick={() => resetFilters()}
            icon={'/images/inpulse/sync-black-small.svg'}
            isDisabled={!isFormValid() && checkAdvancedFiltersExistence()}
            label={i18next.t('GENERAL.RESET')}
          />
          <Button
            color={'inpulse-default'}
            handleClick={() => (isFormValid() ? save() : {})}
            icon={'/images/inpulse/check-white-small.svg'}
            isDisabled={!isFormValid() || isFiltersApplying}
            label={i18next.t('GENERAL.APPLY')}
          />
        </div>
      </Footer>
    </Container>
  );
}

DeepsightFiltersModal.propTypes = {
  //filters props
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  //countries filter
  selectedCountry: PropTypes.object,
  setSelectedCountry: PropTypes.func,
  countries: PropTypes.arrayOf(PropTypes.object),
  // locations filter
  selectedLocations: PropTypes.arrayOf(PropTypes.object),
  setSelectedLocations: PropTypes.func,
  locations: PropTypes.arrayOf(PropTypes.object),
  // brands filter
  selectedBrands: PropTypes.arrayOf(PropTypes.object),
  setSelectedBrands: PropTypes.func,
  brands: PropTypes.arrayOf(PropTypes.object),
  // retailers filter
  selectedRetailers: PropTypes.arrayOf(PropTypes.object),
  setSelectedRetailers: PropTypes.func,
  retailers: PropTypes.arrayOf(PropTypes.object),
  // groups filter
  selectedGroups: PropTypes.arrayOf(PropTypes.object),
  setSelectedGroups: PropTypes.func,
  groups: PropTypes.arrayOf(PropTypes.object),
  // stores filter
  stores: PropTypes.arrayOf(PropTypes.object),
  selectedStores: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
  setSelectedStores: PropTypes.func,
  selectedStore: PropTypes.object,
  setSelectedStore: PropTypes.func,
  // Catalogs filter
  selectedCatalogs: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
  setSelectedCatalogs: PropTypes.func,
  catalogs: PropTypes.arrayOf(PropTypes.object),
  // suppliers filter
  selectedSuppliers: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
  setSelectedSuppliers: PropTypes.func,
  supplier: PropTypes.arrayOf(PropTypes.object),
  fetchCustomSupplierList: PropTypes.func,
  // custom multiple selection dropdown
  customMultipleDropDowns: PropTypes.arrayOf(PropTypes.object),
  // custom single selection dropdown
  customSingleDropDowns: PropTypes.arrayOf(PropTypes.object),
  // ...props
  columnsFilterList: PropTypes.array, // used by advancedFilters
  setFiltersModalOpen: PropTypes.func,
  readOnly: PropTypes.bool,
  analyticsType: PropTypes.string,
  isFilterButtonCenter: PropTypes.bool,
};

DeepsightFiltersModal.defaultProps = {
  readOnly: false,
  isFilterButtonCenter: false,
};

const clickOutsideConfig = {
  handleClickOutside: () => DeepsightFiltersModal.handleClickOutside,
};

export default onClickOutside(DeepsightFiltersModal, clickOutsideConfig);
