import i18next from 'i18next';
import React from 'react';

import { Tags } from '@commons/Tags';
import { Text } from '../styledComponents';

export const getColumns = (stores, hasMultipleBrands) => {
  const columns = [
    {
      id: 'name',
      baseName: 'name',
      propertyKey: 'name',
      name: i18next.t('GENERAL.NAME'),
      displayName: i18next.t('GENERAL.NAME'),
      renderItem: (item) => <div>{item}</div>,
      render: (item) => <Text highlighted>{item}</Text>,
    },
    {
      id: 'city',
      baseName: 'city',
      propertyKey: 'city',
      name: i18next.t('GENERAL.CITY'),
      displayName: i18next.t('GENERAL.CITY'),
      filterType: 'string',
      list: Object.values(
        stores.reduce((result, store, key) => {
          if (store.city && !result[store.city]) {
            result[store.city] = {
              id: key,
              name: store.city,
              status: store.city,
              value: store.city,
              city: store.city,
            };
          }
          return result;
        }, {}),
      ),
      excludeFromSearch: true,
      renderItem: (item) => <div>{item}</div>,
    },
    {
      id: 'brand',
      baseName: 'brand',
      propertyKey: 'brand',
      name: i18next.t('GENERAL.BRAND'),
      displayName: i18next.t('GENERAL.BRAND'),
      renderItem: (item) => <div>{item}</div>,
    },
    {
      id: 'country',
      baseName: 'country',
      propertyKey: 'country',
      name: i18next.t('GENERAL.COUNTRY'),
      displayName: i18next.t('GENERAL.COUNTRY'),
      filterType: 'string',
      list: Object.values(
        stores.reduce((result, store, key) => {
          if (store.country && !result[store.country]) {
            result[store.country] = {
              id: key,
              name: store.country,
              status: store.country,
              value: store.country,
              country: store.country,
            };
          }
          return result;
        }, {}),
      ),
      excludeFromSearch: true,
      renderItem: (item) => <div>{item}</div>,
    },
    {
      id: 'groups',
      baseName: 'mappedGroups',
      propertyKey: 'groups',
      name: i18next.t('GENERAL.GROUPS'),
      displayName: i18next.t('GENERAL.GROUPS'),
      filterType: 'string',
      list: Object.values(
        stores.reduce(
          (result, store) => {
            if (store.groups && store.groups.length) {
              store.groups.forEach(({ id, name }) => {
                if (!result[name]) {
                  result[name] = {
                    id,
                    name: name,
                    status: name,
                    value: name,
                    groups: name,
                    groupNames: name,
                  };
                }
              });
            }
            return result;
          },
          {
            none: {
              id: -1,
              name: i18next.t('GENERAL.SELECT_NONE_MASCULINE'),
              groupNames: i18next.t('GENERAL.SELECT_NONE_MASCULINE'),
            },
          },
        ),
      ),
      render: (groups) => groups && <Tags items={groups.map(({ name }) => name)} />,
      renderItem: (groups) => (groups ? <Tags items={groups.map(({ name }) => name)} /> : '-'),
      transform: (groups) => groups.map(({ name }) => name).join(', '),
    },
  ];

  if (hasMultipleBrands) {
    return columns;
  }

  columns.splice(2, 1);

  return columns;
};

export default getColumns;
