import axios from '../core/http';

import { APIcaller } from './APIcaller';
import { config } from '../config';

const baseApiUrl = `${config.baseApiUrl}/v1`;

const getStoresAnalytics = (storeIds, startDate, endDate, productIds) =>
  axios
    .post(baseApiUrl + '/production/analytics-by-day', {
      storeIds,
      startDate,
      endDate,
      productIds,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });

const getProductsAnalytics = (storeIds, startDate, endDate, productIds) =>
  axios
    .post(baseApiUrl + '/production/analytics-by-reference', {
      storeIds,
      startDate,
      endDate,
      productIds,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });

const getAnalyticsByStores = (storeIds, startDate, endDate, productIds) =>
  axios
    .post(baseApiUrl + '/production/analytics-by-stores', {
      storeIds,
      startDate,
      endDate,
      productIds,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });

const getAnalyticsByBrands = (storeIds, startDate, endDate, productIds) =>
  APIcaller.apiPost('/production/analytics-by-brands', {
    storeIds,
    startDate,
    endDate,
    productIds,
  });

export const productionAnalytics = {
  getStoresAnalytics,
  getProductsAnalytics,
  getAnalyticsByStores,
  getAnalyticsByBrands,
};
