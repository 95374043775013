import theme from '@theme';

const _getColorFromBufferStock = (value, bufferStock, bufferStockIsCovered) => {
  if (bufferStockIsCovered || value >= bufferStock) {
    return theme.colors.infoGreen;
  }

  return theme.colors.infoOrange;
};

const getStyleAndColor = (
  isInPeriod,
  isYesterday,
  date,
  periodStartDate,
  periodEndDate,
  isValidForecast,
  isYesterdayReal,
  value,
  { bufferStock = 0, bufferStockIsCovered = true } = {},
) => {
  if (isYesterday) {
    return {
      color: isYesterdayReal
        ? theme.colors.greys.light
        : value > 0
        ? theme.colors.infoGreen
        : theme.colors.infoRed,
      weight: 400,
    };
  }

  if (isInPeriod && (date === periodStartDate || date === periodEndDate)) {
    if (isValidForecast) {
      return {
        color: _getColorFromBufferStock(value, bufferStock, bufferStockIsCovered),
        weight: 700,
      };
    }

    return {
      color: theme.colors.infoRed,
      weight: 700,
    };
  }

  if (isInPeriod) {
    if (isValidForecast) {
      return {
        color: _getColorFromBufferStock(value, bufferStock, bufferStockIsCovered),
        weight: 400,
      };
    }

    return {
      color: theme.colors.infoRed,
      weight: 400,
    };
  }

  if (value > 0 && date > periodEndDate) {
    return {
      color: _getColorFromBufferStock(value, bufferStock, bufferStockIsCovered),
      weight: 400,
    };
  }

  return { color: value > 0 ? theme.colors.infoGreen : theme.colors.infoRed, weight: 400 };
};

export default getStyleAndColor;
