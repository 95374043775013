import { connect } from 'react-redux';
import { get } from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useRef, useState } from 'react';

import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_DELETE_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { getConfirmationModal } from '@commons/Modals/ConfirmationModal';
import { ListView } from '@commons/utils/styledLibraryComponents';
import { NONE_VALUE } from '@commons/constants/none';
import { STANDARD_LISTVIEW_PADDING } from '@commons/constants/listViewProps';
import EmptyState from '../../../commons/EmptyState';
import NavigationBreadCrumb from '../../../commons/Breadcrumb/NavigationBreadCrumb';

import rushOrderService from '../../../services/rushOrders';

import { closeGenericModal, openGenericModal, openModal } from '@actions/modal';
import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import DeepsightFiltersButton from '../components/FilterButton';

import { exportContent } from './utils/ExportConfigs/exportContent';
import { exportList } from './utils/ExportConfigs/exportList';
import getRushOrderInformations from './utils/getRushOrderInformations';
import RenderEmptyState from './components/EmptyState';
import RushOrderForm from './components/RushOrderForm';
import rushOrdersUtils from './utils';

import { Container } from './styledComponents';
import { getAuthorizedActions } from '@selectors/featureProps';
import { getClientInfo } from '@selectors/client';
import { getSalesPointStores } from '@selectors/stores';

export const RushOrderModulesContainer = (props) => {
  const {
    user,
    client: { storeName, hasMultipleBrands },
    stores,
    match,
    currency,
    pageLoading,
    pageLoaded,
    showSuccessMessage,
    showErrorMessage,
    openGenericModal,
    closeGenericModal,
    openFullscreenModal,
    authorizedActions,
    clientBrands,
  } = props;

  const path = get(match, 'path');

  const listViewRef = useRef();

  const [isLoading, setIsLoading] = useState(true);

  const [rushOrders, setRushOrders] = useState(null);
  const [filteredRushOrders, setFilteredRushOrders] = useState(null);

  const [columns, setColumns] = useState([]);
  const [actions, setActions] = useState([]);
  const [rowActions, setRowActions] = useState([]);

  const [selectedRushOrders, setSelectedRushOrders] = useState([]);

  const [applyFilters, setApplyFilters] = useState(true);
  const [advancedFilters, setAdvancedFilters] = useState(null);
  const [filters, setFilters] = useState(null);

  const [selectedBrands, setSelectedBrands] = useState([]);

  const [selectedStores, setSelectedStores] = useState(stores);

  const [rushSuppliers, setRushSuppliers] = useState(null);
  const [selectedRushSuppliers, setSelectedRushSuppliers] = useState([]);

  const [columnsFilterList, setColumnsFilterList] = useState([]);

  const userLanguageCode = get(user, 'lnkLanguageAccountrel.code', 'fr');

  /* USE EFFECT */
  useEffect(() => {
    const formattedColumns = rushOrdersUtils.getColumns(storeName);

    setColumns(formattedColumns);

    setColumnsFilterList(rushOrdersUtils.updateColumnsFilterList(formattedColumns));
  }, []);

  useEffect(() => {
    setActions(
      rushOrdersUtils.getActions({
        filteredRushOrders,
        selectedRushOrders,
        createRushOrder,
        handleDeletionConfirmationModal,
        handleExportList,
        handleExportContent,
        authorizedActions,
      }),
    );
    setRowActions(
      rushOrdersUtils.getRowActions({
        handleDeletionConfirmationModal,
        handleExportList,
        handleExportContent,
        authorizedActions,
      }),
    );
  }, [filteredRushOrders, selectedRushOrders]);

  /*
   ** Handle fetch of rush suppliers and brands
   */
  useEffect(() => {
    (async function loadData() {
      await fetchRushOrders();
    })();

    setSelectedStores(stores);
  }, []);

  useEffect(() => {
    if (!rushOrders || !applyFilters) {
      return;
    }

    // Filters with selected stores and selected suppliers
    const filteredRushOrders = rushOrders.filter(
      (rushOrder) =>
        selectedStores.some((store) => store.id === rushOrder.storeId) &&
        selectedRushSuppliers.some((supplier) => {
          if (!supplier.name) {
            return !rushOrder.rushSupplier;
          }

          return supplier.name.toUpperCase() === rushOrder.rushSupplier.toUpperCase();
        }),
    );

    if (!advancedFilters || !advancedFilters.length) {
      setFilteredRushOrders(filteredRushOrders);

      return;
    }

    let filteredData = [...filteredRushOrders];

    advancedFilters.forEach(({ propertyKey, doFilter, value }) => {
      filteredData = doFilter(filteredData, propertyKey, value);
    });

    setFilteredRushOrders(filteredData);
  }, [applyFilters, selectedRushSuppliers]);

  /**
   * Work-around to make filters work correctly, with an updated list of RushSuppliers after a creation
   * This is a special situation of filters where a filter's itemlist is updated by the user flow.
   */
  useEffect(() => {
    if (get(filters, 'stores', null)) {
      const updatedFilters = {
        ...filters,
        suppliers: {
          activeList: rushSuppliers,
          list: rushSuppliers,
          selectedSuppliers: selectedRushSuppliers,
        },
      };
      setFilters(updatedFilters);
    }
  }, [rushSuppliers, selectedRushSuppliers]);

  /* METHODS */

  const fetchRushOrders = async () => {
    if (!stores.length) {
      return;
    }

    pageLoading();

    try {
      const storeIds = stores.map(({ id }) => id);
      const fetchedRushOrders = await getRushOrderInformations.getRushOrders({
        storeIds,
        showErrorMessage,
      });

      // Set rush orders
      const formattedRushOrders = fetchedRushOrders.map((rushOrder) => ({
        ...rushOrder,
        storeName: get(rushOrder, 'lnkStoreRushOrderrel.name', ''),
        storeTimezone: get(rushOrder, 'lnkStoreRushOrderrel.timezone', ''),
        rushSupplier:
          rushOrder.rushSupplier === NONE_VALUE
            ? i18next.t('GENERAL.SELECT_NONE_MASCULINE')
            : rushOrder.rushSupplier,
      }));

      setRushOrders(formattedRushOrders);
      setFilteredRushOrders(formattedRushOrders);

      // Set rush suppliers
      const uniqueRushSupplierName = fetchedRushOrders.reduce((acc, { rushSupplier }) => {
        if (acc[rushSupplier]) {
          return acc;
        }

        acc[rushSupplier] = {
          id: rushSupplier,
          name:
            rushSupplier === NONE_VALUE ? i18next.t('GENERAL.SELECT_NONE_MASCULINE') : rushSupplier,
        };

        return acc;
      }, {});

      const rushSuppliersWithNoneValue = Object.values(uniqueRushSupplierName);

      setRushSuppliers(rushSuppliersWithNoneValue);
      setSelectedRushSuppliers(rushSuppliersWithNoneValue);
    } catch {
      showErrorMessage(i18next.t('ORDERS.RUSH_ORDERS.FETCH_LIST_ERROR'));
    } finally {
      setIsLoading(false);
      pageLoaded();
    }
  };

  const handleRushOrderDeletion = async (rushOrder) => {
    pageLoading();

    const rushOrderId = rushOrder.id;

    try {
      await rushOrderService.deleteById(rushOrderId);

      showSuccessMessage(i18next.t('ORDERS.RUSH_ORDERS.DELETION_SUCCESS'));

      fetchRushOrders();
    } catch {
      showErrorMessage(i18next.t('ORDERS.RUSH_ORDERS.DELETION_ERROR'));
    }

    closeGenericModal();
    pageLoaded();
  };

  const handleDeletionConfirmationModal = (selectedRushOrder) => {
    const actions = [
      GENERIC_MODAL_CANCEL_BUTTON(),
      {
        ...GENERIC_MODAL_DELETE_BUTTON(),
        handleClick: () => handleRushOrderDeletion(selectedRushOrder),
      },
    ];

    const params = getConfirmationModal({
      title: i18next.t('ORDERS.RUSH_ORDERS.DELETE_MODAL_TITLE'),
      icon: '/images/inpulse/trash-white.svg',
      actions,
      content: i18next.t('ORDERS.RUSH_ORDERS.DELETE_MODAL_CONTENT'),
      shouldInterpretHtml: true,
    });

    openGenericModal(params);
  };

  const handleExportList = (rushOrdersToExport) => {
    pageLoading();

    try {
      exportList(rushOrdersToExport, storeName, currency);
    } catch (error) {
      showErrorMessage(i18next.t('ORDERS.RUSH_ORDERS.EXPORT_FAILED'));
    } finally {
      pageLoaded();
    }
  };

  const handleExportContent = async (rushOrdersToExport) => {
    pageLoading();

    try {
      const rushOrderIds = rushOrdersToExport.map(({ id }) => id);
      const rushOrderContents = await rushOrderService.getRushOrderByIds(rushOrderIds);

      const formattedRushOrderContents = rushOrderContents.map((rushOrderContent) => {
        const rushOrderEntities = rushOrderContent.rushOrderEntities;

        const formattedRushOrderEntities = rushOrderEntities.map((rushOrderEntity) => {
          const lnkEntityrel = rushOrderEntity.lnkEntityrel;

          return {
            ...rushOrderEntity,
            lnkEntityrel: {
              ...lnkEntityrel,
              category: lnkEntityrel.category || i18next.t('GENERAL.NONE_VALUE'),
            },
          };
        });

        return { ...rushOrderContent, rushOrderEntities: formattedRushOrderEntities };
      });

      exportContent(formattedRushOrderContents, storeName, currency);
    } catch (error) {
      showErrorMessage(i18next.t('ORDERS.RUSH_ORDERS.EXPORT_FAILED'));
    } finally {
      pageLoaded();
    }
  };

  const createRushOrder = () => {
    openFullscreenModal({
      isCreation: true,
      component: RushOrderForm,
      fetchRushOrders,
    });
  };

  const updateRushOrder = (rushOrder) => {
    openFullscreenModal({
      isCreation: false,
      component: RushOrderForm,
      fetchRushOrders,
      rushOrderId: rushOrder.id,
    });
  };

  return (
    <div>
      <NavigationBreadCrumb featurePath={path} />
      {(!rushOrders || !rushOrders.length) && !isLoading ? (
        <Container>
          <RenderEmptyState createRushOrder={createRushOrder} />
        </Container>
      ) : (
        <Container>
          <ListView
            actionOnClick={(rushOrder) => updateRushOrder(rushOrder)}
            actions={actions}
            columns={columns}
            data={filteredRushOrders}
            defaultOrderBy={'createdAt'}
            defaultOrderType={'desc'}
            hideAllPerPageOption={true}
            isLoading={isLoading}
            languageCode={userLanguageCode}
            padding={STANDARD_LISTVIEW_PADDING}
            placeholderShape={i18next.t('GENERAL.SEARCH')}
            ref={listViewRef}
            renderEmptyState={() => <EmptyState />}
            renderFilterButton={() => (
              <DeepsightFiltersButton
                advancedFilters={advancedFilters}
                applyFilters={applyFilters}
                brands={hasMultipleBrands ? clientBrands : null}
                columnsFilterList={columnsFilterList}
                filters={filters}
                isLoading={isLoading}
                readOnly={isLoading}
                selectedBrands={selectedBrands}
                selectedStores={selectedStores}
                selectedSuppliers={selectedRushSuppliers}
                setAdvancedFilters={setAdvancedFilters}
                setApplyFilters={setApplyFilters}
                setFilters={setFilters}
                setSelectedBrands={setSelectedBrands}
                setSelectedStores={setSelectedStores}
                setSelectedSuppliers={setSelectedRushSuppliers}
                stores={stores}
                suppliers={rushSuppliers}
                textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
              />
            )}
            rowActions={rowActions}
            setSelectedItems={setSelectedRushOrders}
          />
        </Container>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  currency: state.baseReducer.currency,
  client: getClientInfo(state.baseReducer.user),
  stores: getSalesPointStores(state.baseReducer.activeStores),
  authorizedActions: getAuthorizedActions(state.baseReducer.userRights, '/order/rush-orders'),
  clientBrands: state.baseReducer.brands,
});

const mapDispatchToProps = (dispatch) => ({
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  closeGenericModal: (params) => {
    dispatch(closeGenericModal(params));
  },
  openFullscreenModal: (params) => {
    dispatch(openModal(params, true));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RushOrderModulesContainer);
