import i18next from 'i18next';
import React from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';
import sortActionsList from '@commons/utils/sortActionsList';

export const getActions = (
  selectedStoresList,
  handleExport,
  confirmDissociation,
  fetchList,
  removeAssociation,
  openModal,
  params,
) => {
  const actions = [
    {
      id: 'export-list',
      actionLabel: i18next.t(
        !selectedStoresList.length ? 'GENERAL.EXPORT_LIST' : 'GENERAL.EXPORT_SELECTION',
      ),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
      handleAction: () => handleExport(selectedStoresList),
    },
    {
      id: 'delete',
      actionLabel: i18next.t('GENERAL.DELETE_ASSOCIATION', { count: selectedStoresList.length }),
      actionIcon: () => '/images/inpulse/delete-ip-black-small.svg',
      handleAction: () => {
        confirmDissociation(selectedStoresList, fetchList, removeAssociation);
      },
      isHidden: () => !selectedStoresList.length,
    },
    {
      id: 'action-associate',
      fixed: true,
      actionLabel: i18next.t('GENERAL.ASSOCIATE'),
      handleAction: () => openModal(params),
      render: () => (
        <Button
          color={'blue'}
          fontSize={14}
          formatText={false}
          handleClick={() => openModal(params)}
          icon={'/images/inpulse/add-white-small.svg'}
          label={i18next.t('GENERAL.ASSOCIATE')}
          width={115}
        />
      ),
    },
  ];

  return sortActionsList(actions);
};

export const getRowActions = (confirmDissociation, fetchList, removeAssociation) => [
  {
    id: 'delete',
    actionLabel: () => i18next.t('GENERAL.DELETE_ASSOCIATION', { count: 1 }),
    actionIcon: () => '/images/inpulse/delete-ip-black-small.svg',
    handleAction: (selectedStore) => {
      confirmDissociation([selectedStore], fetchList, removeAssociation);
    },
  },
];
