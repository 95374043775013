import { INPUT_TYPES } from '@commons/constants/inputType';
import { INPUT_WIDTH } from '@commons/utils/styledLibraryComponents';
import i18next from 'i18next';

const INPUTS = {
  INVOICE_NUMBER: 'invoiceNumber',
  EXCLUDING_TAXES_INVOICE_TOTAL: 'excludingTaxesInvoiceTotal',
  INVOICE_DATE: 'invoiceDate',
  DELIVERY_DATE: 'deliveryDate',
  ORDER_REFERENCE: 'orderReference',
  DELIVERY_REFERENCE: 'deliveryReference',
  CODE: 'code',
  NAME: 'name',
  QUANTITY: 'quantity',
  UNIT_PRICE: 'unitPrice',
  PACKAGING: 'packaging',
};

export const getInputs = (props) => {
  const { isDisabled } = props || {};

  return [
    {
      name: INPUTS.INVOICE_NUMBER,
      // To avoid double line due to gap of 24px
      parentContainerStyle: {
        width: `calc(${INPUT_WIDTH.HALF} - 12px)`,
      },
      width: INPUT_WIDTH.FULL,
      type: INPUT_TYPES.MUI_MUI_TEXT_FIELD_FIELD,
      label: i18next.t('ADMIN.SUPPLIERS.CUSTOM_PROMPT_INVOICE_NUMBER'),
      isDisabled: () => isDisabled,
    },
    {
      name: INPUTS.EXCLUDING_TAXES_INVOICE_TOTAL,
      parentContainerStyle: {
        width: `calc(${INPUT_WIDTH.HALF} - 12px)`,
      },
      width: INPUT_WIDTH.FULL,
      type: INPUT_TYPES.MUI_MUI_TEXT_FIELD_FIELD,
      label: i18next.t('ADMIN.SUPPLIERS.CUSTOM_PROMPT_EXCLUDING_TAXES_INVOICE_TOTAL'),
      isDisabled: () => isDisabled,
    },
    {
      name: INPUTS.INVOICE_DATE,
      parentContainerStyle: {
        width: `calc(${INPUT_WIDTH.HALF} - 12px)`,
      },
      width: INPUT_WIDTH.FULL,
      type: INPUT_TYPES.MUI_TEXT_FIELD,
      label: i18next.t('ADMIN.SUPPLIERS.CUSTOM_PROMPT_INVOICE_DATE'),
      isDisabled: () => isDisabled,
    },
    {
      name: INPUTS.DELIVERY_DATE,
      parentContainerStyle: {
        width: `calc(${INPUT_WIDTH.HALF} - 12px)`,
      },
      width: INPUT_WIDTH.FULL,
      type: INPUT_TYPES.MUI_TEXT_FIELD,
      label: i18next.t('ADMIN.SUPPLIERS.CUSTOM_PROMPT_DELIVERY_DATE'),
      isDisabled: () => isDisabled,
    },
    {
      name: INPUTS.ORDER_REFERENCE,
      parentContainerStyle: {
        width: `calc(${INPUT_WIDTH.HALF} - 12px)`,
      },
      width: INPUT_WIDTH.FULL,
      type: INPUT_TYPES.MUI_TEXT_FIELD,
      label: i18next.t('ADMIN.SUPPLIERS.CUSTOM_PROMPT_ORDER_REFERENCE'),
      isDisabled: () => isDisabled,
    },
    {
      name: INPUTS.DELIVERY_REFERENCE,
      parentContainerStyle: {
        width: `calc(${INPUT_WIDTH.HALF} - 12px)`,
      },
      width: INPUT_WIDTH.FULL,
      type: INPUT_TYPES.MUI_TEXT_FIELD,
      label: i18next.t('ADMIN.SUPPLIERS.CUSTOM_PROMPT_DELIVERY_REFERENCE'),
      isDisabled: () => isDisabled,
    },
    {
      name: INPUTS.CODE,
      parentContainerStyle: {
        width: `calc(${INPUT_WIDTH.HALF} - 12px)`,
      },
      width: INPUT_WIDTH.FULL,
      type: INPUT_TYPES.MUI_TEXT_FIELD,
      label: i18next.t('ADMIN.SUPPLIERS.CUSTOM_PROMPT_CODE'),
      isDisabled: () => isDisabled,
    },
    {
      name: INPUTS.NAME,
      parentContainerStyle: {
        width: `calc(${INPUT_WIDTH.HALF} - 12px)`,
      },
      width: INPUT_WIDTH.FULL,
      type: INPUT_TYPES.MUI_TEXT_FIELD,
      label: i18next.t('ADMIN.SUPPLIERS.CUSTOM_PROMPT_NAME'),
      isDisabled: () => isDisabled,
    },
    {
      name: INPUTS.QUANTITY,
      parentContainerStyle: {
        width: `calc(${INPUT_WIDTH.HALF} - 12px)`,
      },
      width: INPUT_WIDTH.FULL,
      type: INPUT_TYPES.MUI_TEXT_FIELD,
      label: i18next.t('ADMIN.SUPPLIERS.CUSTOM_PROMPT_QUANTITY'),
      isDisabled: () => isDisabled,
    },
    {
      name: INPUTS.UNIT_PRICE,
      parentContainerStyle: {
        width: `calc(${INPUT_WIDTH.HALF} - 12px)`,
      },
      width: INPUT_WIDTH.FULL,
      type: INPUT_TYPES.MUI_TEXT_FIELD,
      label: i18next.t('ADMIN.SUPPLIERS.CUSTOM_PROMPT_UNIT_PRICE'),
      isDisabled: () => isDisabled,
    },
    {
      name: INPUTS.PACKAGING,
      parentContainerStyle: {
        width: `calc(${INPUT_WIDTH.HALF} - 12px)`,
      },
      width: INPUT_WIDTH.FULL,
      type: INPUT_TYPES.MUI_TEXT_FIELD,
      label: i18next.t('ADMIN.SUPPLIERS.CUSTOM_PROMPT_PACKAGING'),
      isDisabled: () => isDisabled,
    },
  ];
};

export default {
  getInputs,
};
