import { connect } from 'react-redux';
import i18next from 'i18next';
import React from 'react';
import styled from 'styled-components';

import { formatTextNumberInputValue } from '@commons/utils/format';
import { Label, ToggleSwitch, Tooltip } from '@commons/utils/styledLibraryComponents';
import { SingleDatePicker } from '@commons/DatePickers/SingleDatePicker';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';
import WhiteCard from '@commons/WhiteCard';

import theme from '@theme';

import { Input } from '@lib/inpulse/Input';

import { getClientInfo } from '@selectors/client';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import {
  SectionInfo,
  SectionLabel,
  Container,
  ContentContainer,
  ToggleContainer,
} from './styledComponents';

const INPUTS_CUSTOM_STYLE = {
  width: '240px',
};

const StepProductionPlanning = (props) => {
  const { productionParameters, setProductionParameters, hasMultipleServices, currency } = props;

  const handleMinimumProductionChange = (event, key) => {
    const value = event.currentTarget.value;

    let valueToApply = parseInt(value);

    if (!value || isNaN(value) || isNaN(parseInt(value)) || parseInt(value) < 0) {
      valueToApply = null;
    }

    setProductionParameters({
      ...productionParameters,
      [key]: valueToApply,
    });
  };

  const renderProductionSettings = () => (
    <>
      <ContentContainer
        displayErrorMessage={productionParameters.endDate && !productionParameters.launchDate}
      >
        <SectionInfo>
          <Input
            label={`* ${i18next.t('GENERAL.PRICE_TTC_WITH_CURRENCY', {
              currency: currency.alphabeticCode,
            })}`}
            style={INPUTS_CUSTOM_STYLE}
            type="text"
            value={productionParameters.price || ''}
            onChange={(event) =>
              setProductionParameters({
                ...productionParameters,
                price: formatTextNumberInputValue(event.target.value),
              })
            }
          />
        </SectionInfo>
        <SectionInfo>
          <SectionLabel>
            {i18next.t('ADMIN.STORES.LIST_COLUMN_LAUNCH_DATE')} :
            <Tooltip text={`${i18next.t('ADMIN.PRODUCTS.CONFIG_PRODUCTION_START_TOOLTIP')}`} />
          </SectionLabel>
          <SingleDatePicker
            date={productionParameters.launchDate}
            displayFormat={DATE_DISPLAY_FORMATS.DATE_MONTH_YEAR}
            maxFutureDate={productionParameters.endDate}
            showClearDate
            onDateChange={(date) =>
              setProductionParameters({
                ...productionParameters,
                launchDate: date,
              })
            }
          />
        </SectionInfo>
        <SectionInfo>
          <SectionLabel>
            {i18next.t('ADMIN.STORES.LIST_COLUMN_END_DATE')} :
            <Tooltip text={`${i18next.t('ADMIN.PRODUCTS.CONFIG_PRODUCTION_END_TOOLTIP')}`} />
          </SectionLabel>
          <SingleDatePicker
            date={productionParameters.endDate}
            displayFormat={DATE_DISPLAY_FORMATS.DATE_MONTH_YEAR}
            maxPastDate={productionParameters.launchDate}
            showClearDate
            onDateChange={(date) =>
              setProductionParameters({
                ...productionParameters,
                endDate: date,
              })
            }
          />
          {productionParameters.endDate && !productionParameters.launchDate && (
            <Text
              color={ENUM_COLORS.INFO_RED}
              font={ENUM_FONTS.TEXT_SMALL_HEIGHT_14_WEIGHT_600}
              style={{ paddingTop: '8px' }}
            >
              {i18next.t('ADMIN.STORES.UPDATE_PRODUCT_EMPTY_LAUNCH_DATE_ERROR')}
            </Text>
          )}
        </SectionInfo>
      </ContentContainer>
      <ContentContainer>
        <SectionInfo>
          <ToggleContainer>
            <ToggleSwitch
              checked={productionParameters.hasStock}
              handleClick={() =>
                setProductionParameters({
                  ...productionParameters,
                  hasStock: !productionParameters.hasStock,
                })
              }
              id={'hasStock'}
            />
            <Label background={theme.colors.greys.lightest} color={theme.colors.greys.darkest}>
              {i18next.t('ADMIN.RECIPES.HAS_STOCK_LABEL')}
            </Label>
            <Tooltip text={`${i18next.t('ADMIN.PRODUCTS.CREATION_STOCK_INVENTORY_TOOLTIP')}`} />
          </ToggleContainer>
        </SectionInfo>
        {hasMultipleServices ? (
          <>
            <SectionInfo>
              <Input
                label={i18next.t('ADMIN.PRODUCTS.CONFIG_PRODUCTION_MINIMUM_NOON')}
                name={'noon-mininum'}
                style={INPUTS_CUSTOM_STYLE}
                type={'text'}
                value={productionParameters.noonMinimum}
                onChange={(value) => handleMinimumProductionChange(value, 'noonMinimum')}
              />
            </SectionInfo>
            <SectionInfo>
              <Input
                label={i18next.t('ADMIN.PRODUCTS.CONFIG_PRODUCTION_MINIMUM_EVENING')}
                name={'evening-mininum'}
                style={INPUTS_CUSTOM_STYLE}
                type={'text'}
                value={productionParameters.eveningMinimum}
                onChange={(value) => handleMinimumProductionChange(value, 'eveningMinimum')}
              />
            </SectionInfo>
          </>
        ) : (
          <SectionInfo>
            <Input
              label={i18next.t('ADMIN.PRODUCTS.CONFIG_PRODUCTION_MINIMUM')}
              name={'daily-mininum'}
              style={INPUTS_CUSTOM_STYLE}
              type={'text'}
              value={productionParameters.dailyMinimum}
              onChange={(value) => handleMinimumProductionChange(value, 'dailyMinimum')}
            />
          </SectionInfo>
        )}
      </ContentContainer>
    </>
  );

  return (
    <Container>
      <WhiteCard
        renderContent={renderProductionSettings()}
        title={i18next.t('ADMIN.PRODUCTS.CREATION_PRODUCTION_SETTINGS')}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
  currency: state.baseReducer.currency,
});

export default connect(mapStateToProps)(styled(StepProductionPlanning)`
  & .orderform-date-input-icon,
  .multiple-dropdown-icon {
    margin-left: 15px;
  }

  & .DateInput_fang {
    display: none !important;
  }

  & .DateRangePickerInput__disabled {
    background-color: lightgrey !important;
  }

  & .CalendarMonth_caption {
    color: ${(props) => props.theme.colors?.greys?.darkest} !important;
    font: ${(props) => props.theme.fonts?.h3} !important;
  }

  & .DateInput_input {
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    text-align: center !important;
    cursor: pointer;
  }

  & .DateInput_input__focused {
    border-bottom: none !important;
  }

  & .DayPicker_weekHeader_li {
    font: ${(props) => props.theme.fonts?.textMicro} !important;
  }

  & .CalendarDay {
    background: ${(props) => props.theme.colors?.greys.lightest} !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
  }

  & .CalendarDay__default:hover {
    background: ${(props) => props.theme.colors?.greys.lighter} !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
  }

  & .CalendarDay__highlighted_calendar {
    background: #fff !important;
    border: 1px double #0a3eff !important;
  }

  & .CalendarDay__highlighted_calendar:hover {
    background: #e4e7e7 !important;
  }

  & .CalendarDay__selected_span {
    background: ${(props) => props.theme.colors?.brand.primaryLight} !important;
    border: 1px double #e2e2e2 !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
  }

  & .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: ${(props) => props.theme.colors?.brand.primaryLight} !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    border: 1px double #e2e2e2 !important;
  }

  & .CalendarDay__selected,
  .CalendarDay__selected:active .CalendarDay__selected:hover {
    background: ${(props) => props.theme.colors?.greys.darkest} !important;
    color: ${(props) => props.theme.colors?.greys.lightest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    border: 1px double #e2e2e2 !important;
  }

  & .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: ${(props) => props.theme.colors?.greys.lighter} !important;
    border: 1px double #e2e2e2 !important;
  }

  & .CalendarDay__selected_start,
  .CalendarDay__selected_start:hover,
  .CalendarDay__selected_end,
  .CalendarDay__selected_end:hover {
    background: ${(props) => props.theme.colors?.greys.darkest} !important;
    color: ${(props) => props.theme.colors?.greys.lightest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    border: 1px double #e2e2e2 !important;
  }

  & .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:hover {
    color: ${(props) => props.theme.colors?.greys.dark} !important;
    background: ${(props) => props.theme.colors?.greys.lighter} !important;
  }
`);
