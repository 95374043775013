import axios from '../core/http';

import { config } from '../config';

const baseApiUrl = `${config.baseApiUrl}/v1`;

function uploadFile(file, params) {
  //File must be a javascript file
  //Params is a json of parameters
  let data = new FormData();
  data.append('file', file);
  Object.keys(params).forEach((key) => {
    data.append(key, params[key]);
  });
  return axios
    .post(baseApiUrl + '/file/upload', data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      throw error.response;
    });
}

export const upload = {
  uploadFile,
};
