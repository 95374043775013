import { connect } from 'react-redux';
import i18next from 'i18next';
import parse from 'html-react-parser';
import React from 'react';

import { loading, loadingSuccess } from '@actions/loading';

import { Button } from '@commons/utils/styledLibraryComponents';
import { getTheme } from '@commons/utils/theme';
import WhiteCard from '@commons/WhiteCard';
import WysiwygEditor from '@commons/WysiwygEditor';

import {
  ButtonContainer,
  ContainerTextArea,
  OrderCartContainer,
  SubTitleWhiteCard,
  TitleWhiteCard,
  WhiteCardContainer,
} from '../../styledComponents';

const DeliveryNotePreview = (props) => {
  const { htmlPreview, receptionComment, setReceptionComment, handlePreparationValidation } = props;

  const theme = getTheme();

  const customWhiteCardStyle = { border: `1px solid ${theme.colors.greys.light}` };

  return (
    <OrderCartContainer>
      <WhiteCardContainer width={'65%'}>
        <WhiteCard
          customStyle={customWhiteCardStyle}
          maxWidth={'inherit'}
          renderContent={
            <>
              <TitleWhiteCard>{i18next.t('ORDERS.PREPARATIONS.PREVIEW_TITLE')}</TitleWhiteCard>
              {!!htmlPreview && <div>{parse(htmlPreview)}</div>}
            </>
          }
          width={'100%'}
        />
      </WhiteCardContainer>
      <WhiteCardContainer width={'35%'}>
        <WhiteCard
          customStyle={customWhiteCardStyle}
          renderContent={
            <>
              <TitleWhiteCard>
                {i18next.t('ORDERS.PREPARATIONS.VALIDATE_DELIVERY_NOTE')}
              </TitleWhiteCard>

              <ContainerTextArea>
                <SubTitleWhiteCard>
                  {i18next.t('ORDERS.PREPARATIONS.WYSIWYG_TITLE')}
                </SubTitleWhiteCard>
                <WysiwygEditor content={receptionComment} setter={setReceptionComment} />
              </ContainerTextArea>

              <ButtonContainer>
                <Button
                  color={'inpulse-default'}
                  handleClick={handlePreparationValidation}
                  icon={'/images/inpulse/check-white-small.svg'}
                  label={i18next.t('GENERAL.VALIDATE')}
                />
              </ButtonContainer>
            </>
          }
          width={'100%'}
        />
      </WhiteCardContainer>
    </OrderCartContainer>
  );
};

const mapDispatchToProps = (dispatch) => ({
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(null, mapDispatchToProps)(DeliveryNotePreview);
