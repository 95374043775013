import i18next from 'i18next';
import moment from 'moment-timezone';
import React from 'react';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import DisplayNumber from '@commons/DisplayNumber';

export const formatDate = (date, userLanguageCode) =>
  moment(date).format(
    userLanguageCode === 'us'
      ? DATE_DISPLAY_FORMATS.SLASHED_MONTH_DAY_YEAR
      : DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR,
  );

export const getListColumns = (userLanguageCode, storeName) => [
  {
    id: 'issuerStore.name',
    propertyKey: 'issuerStore.name',
    name: i18next.t('GENERAL.ISSUER'),
  },
  {
    id: 'billedStore.name',
    propertyKey: 'billedStore.name',
    name: i18next.t('GENERAL.BILLED'),
  },
  {
    id: 'date',
    propertyKey: 'date',
    filterType: 'date',
    excludeFromSearch: true,
    name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_INVOICE_DATE'),
    render: (date) => formatDate(date, userLanguageCode),
  },
  {
    id: 'number',
    propertyKey: 'number',
    name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_INVOICE_NUMBER'),
    render: (number) => (!!number ? number : '-'),
  },
  {
    id: 'total',
    propertyKey: 'total',
    filterType: 'numeric',
    excludeFromSearch: true,
    name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_INVOICED_TOT_EX_TAX'),
    render: (item) => (!!item ? <DisplayNumber displayCurrencyCode={true} number={item} /> : '-'),
  },
];
