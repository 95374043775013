import { connect } from 'react-redux';
import { get } from 'lodash';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import { openGenericModal, refreshGenericModal } from '@actions/modal';
import { showConfirmationMessage } from '@actions/messageconfirmation';

import { Button } from '@commons/utils/styledLibraryComponents';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { getLinearGradientButtonForProgressStyling } from '@commons/utils/cssTricks';
import LastAction from '@commons/LastAction';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';
import WhiteCard from '@commons/WhiteCard';

import { getClientInfo } from '@selectors/client';

import backofficeService from '@services/backoffice/general';
import storeService from '@services/store';

import { SYNC_STATE } from '../commons/syncStatus';

import { Container } from './styledComponents';
import { getRelaunchAnalyticsModalParams, ANALYTICS_TYPE } from './utils/modals';

const DATE_FORMAT = 'YYYY-MM-DD';
const TIMEOUT_GET_SYNC_STATUS = 5000; // in ms

const ERROR_MESSAGES = {
  [ANALYTICS_TYPE.ORDER]: i18next.t('BACKOFFICE.ANALYTICS.RELAUNCH_ORDER_FAILURE'),
  [ANALYTICS_TYPE.PRODUCTION]: i18next.t('BACKOFFICE.ANALYTICS.RELAUNCH_PRODUCTION_FAILURE'),
};

const SUCCESS_MESSAGES = {
  [ANALYTICS_TYPE.ORDER]: i18next.t('BACKOFFICE.ANALYTICS.RELAUNCH_ORDER_SUCCESS'),
  [ANALYTICS_TYPE.PRODUCTION]: i18next.t('BACKOFFICE.ANALYTICS.RELAUNCH_PRODUCTION_SUCCESS'),
};

const IN_PROGRESS = 'inProgress';
const INFO = 'info';
const INITIAL_STATE = {
  [IN_PROGRESS]: false,
  [INFO]: {},
};

export const BackOfficeAnalytics = (props) => {
  const {
    client: { clientId, storeName },
    match,
  } = props;

  const [selectedStores, setSelectedStores] = useState([]);

  const [dateRange, setDateRange] = useState({});

  const [analytics, setAnalytics] = useState({
    [ANALYTICS_TYPE.ORDER]: INITIAL_STATE,
    [ANALYTICS_TYPE.PRODUCTION]: INITIAL_STATE,
  });

  /**
   * Handle the fetch of stores associated to the given client id
   *
   * @returns {Promise<Store[]>} The list of stores associated to the client
   */
  const retrieveAvailableStores = async () => {
    try {
      const stores = await storeService.getStoresOfClient();

      return stores;
    } catch (err) {
      return [];
    }
  };

  /**
   * Handle the opening of the selection modal to relaunch analytics for a set of stores on a specific range of time
   *
   * @param {enum<RELAUNCH_ANALYTICS_TYPE>} type - The type of analytics to relaunch
   *
   * @returns {void}
   */
  const openRelaunchAnalyticsModal = async (type) => {
    const stores = await retrieveAvailableStores();

    const translatedStoreName = getClientStoreNameTranslation(storeName, true);

    const params = getRelaunchAnalyticsModalParams({
      type,
      stores,
      setDateRange,
      dateRange: {},
      setSelectedStores,
      selectedStores: [],
      storeName: translatedStoreName,
      handleSave: triggerRelaunchAnalytics,
    });

    props.openGenericModal(params);
  };

  /**
   * Handle the call to API to trigger the relaunch of analytics for the selected information
   *
   * @param {enum<RELAUNCH_ANALYTICS_TYPE>} type - The type of analytics to relaunch
   *
   * @returns {Promise<void>}
   */
  const triggerRelaunchAnalytics = async (type) => {
    try {
      const storeIds = selectedStores.map(({ id }) => id);

      const { progress, estimatedEndDate } = await backofficeService.triggerRelaunchAnalytics({
        clientId,
        storeIds,
        type: type.toLowerCase(),
        startDate: dateRange.startDate.format(DATE_FORMAT),
        endDate: dateRange.endDate.format(DATE_FORMAT),
      });

      setAnalytics({
        ...analytics,
        [type]: {
          [IN_PROGRESS]: true,
          [INFO]: {
            progress,
            estimatedEndDate,
          },
        },
      });
    } catch (err) {
      props.showErrorMessage(ERROR_MESSAGES[type]);
    }
  };

  /**
   * Handle the call to API to retrieve status of the pipeline taking care of relaunching analytics
   * for the given clientId and type
   *
   * @param {uuid} clientId                       - The id of the client on which check the status of the pipeline
   * @param {enum<RELAUNCH_ANALYTICS_TYPE>} type  - The type of analytics to relaunch
   *
   * @returns {Promise<void>}
   */
  const checkRelaunchAnalyticsStatusByType = async (clientId, type) => {
    try {
      const { state, progress, estimatedEndDate } =
        await backofficeService.getRelaunchAnalyticsStatus(clientId, type.toLowerCase());

      if ([SYNC_STATE.RETRY, SYNC_STATE.PENDING, SYNC_STATE.STARTED].includes(state)) {
        setAnalytics({
          ...analytics,
          [type]: {
            [IN_PROGRESS]: true,
            [INFO]: {
              progress,
              estimatedEndDate,
            },
          },
        });

        return;
      }

      const oneAnalyticInProgress =
        analytics[ANALYTICS_TYPE.PRODUCTION][IN_PROGRESS] ||
        analytics[ANALYTICS_TYPE.ORDER][IN_PROGRESS];

      if (oneAnalyticInProgress && state === SYNC_STATE.SUCCESS) {
        props.showSuccessMessage(SUCCESS_MESSAGES[type]);
      }

      if (state === SYNC_STATE.FAILURE || state === SYNC_STATE.REVOKED) {
        props.showErrorMessage(ERROR_MESSAGES[type]);
      }
    } catch (error) {
      props.showErrorMessage(ERROR_MESSAGES[type]);
    }

    setAnalytics({
      ...analytics,
      [type]: INITIAL_STATE,
    });
  };

  // Keep track of Production Sync
  useEffect(() => {
    let intervalSync;

    if (analytics[ANALYTICS_TYPE.PRODUCTION][IN_PROGRESS]) {
      intervalSync = setInterval(() => {
        checkRelaunchAnalyticsStatusByType(clientId, ANALYTICS_TYPE.PRODUCTION);
      }, TIMEOUT_GET_SYNC_STATUS);
    }

    (async function fetchSyncStatus() {
      await checkRelaunchAnalyticsStatusByType(clientId, ANALYTICS_TYPE.PRODUCTION);
    })();

    return () => clearInterval(intervalSync);
  }, [analytics[ANALYTICS_TYPE.PRODUCTION][IN_PROGRESS]]);

  // Keep track of Order Sync
  useEffect(() => {
    let intervalSync;

    if (analytics[ANALYTICS_TYPE.ORDER][IN_PROGRESS]) {
      intervalSync = setInterval(() => {
        checkRelaunchAnalyticsStatusByType(clientId, ANALYTICS_TYPE.ORDER);
      }, TIMEOUT_GET_SYNC_STATUS);
    }

    (async function fetchSyncStatus() {
      await checkRelaunchAnalyticsStatusByType(clientId, ANALYTICS_TYPE.ORDER);
    })();

    return () => clearInterval(intervalSync);
  }, [analytics[ANALYTICS_TYPE.ORDER][IN_PROGRESS]]);

  // Handle the refresh of the selection modal
  useEffect(() => {
    const isModalOpened = get(props, 'modal.GenericModalBool', false);

    if (!isModalOpened) {
      return;
    }

    const params = getRelaunchAnalyticsModalParams({
      dateRange,
      setDateRange,
      selectedStores,
      setSelectedStores,
      handleSave: triggerRelaunchAnalytics,
      // To avoid keeping this information in a state
      type: get(props.modal, 'params.data.type'),
      stores: get(props.modal, 'params.data.stores', []),
      storeName: get(props.modal, 'params.data.storeName', ''),
    });

    props.refreshGenericModal(params);
  }, [dateRange, selectedStores]);

  return (
    <Container>
      <NavigationBreadCrumb featurePath={match.path} />
      <WhiteCard
        buttonFlexColumn={false}
        buttonFlexDirection="row"
        content={i18next.t('BACKOFFICE.ANALYTICS.RELAUNCH_PRODUCTION_SUBTITLE')}
        renderContent={
          <Button
            animation={{ rotate: analytics[ANALYTICS_TYPE.PRODUCTION][IN_PROGRESS] }}
            buttonCustomStyle={{ width: 'fit-content' }}
            color={'inpulse-default'}
            customBackground={getLinearGradientButtonForProgressStyling(
              analytics[ANALYTICS_TYPE.PRODUCTION][INFO].progress,
            )}
            handleClick={() => openRelaunchAnalyticsModal(ANALYTICS_TYPE.PRODUCTION)}
            icon={'/images/inpulse/sync-white.svg'}
            isDisabled={analytics[ANALYTICS_TYPE.PRODUCTION][IN_PROGRESS]}
            label={i18next.t('BACKOFFICE.ANALYTICS.RELAUNCH_ACTION')}
          />
        }
        renderLastAction={
          !!analytics[ANALYTICS_TYPE.PRODUCTION][INFO].estimatedEndDate && (
            <LastAction
              date={analytics[ANALYTICS_TYPE.PRODUCTION][INFO].estimatedEndDate}
              displayCountDown={!!analytics[ANALYTICS_TYPE.PRODUCTION][INFO].estimatedEndDate}
              label={i18next.t('GENERAL.TIME_LEFT')}
            />
          )
        }
        title={i18next.t('BACKOFFICE.ANALYTICS.RELAUNCH_PRODUCTION_TITLE')}
      />
      <WhiteCard
        buttonFlexColumn={false}
        buttonFlexDirection="row"
        content={i18next.t('BACKOFFICE.ANALYTICS.RELAUNCH_ORDER_SUBTITLE')}
        renderContent={
          <Button
            animation={{ rotate: analytics[ANALYTICS_TYPE.ORDER][IN_PROGRESS] }}
            buttonCustomStyle={{ width: 'fit-content' }}
            color={'inpulse-default'}
            customBackground={getLinearGradientButtonForProgressStyling(
              analytics[ANALYTICS_TYPE.ORDER][INFO].progress,
            )}
            handleClick={() => openRelaunchAnalyticsModal(ANALYTICS_TYPE.ORDER)}
            icon={'/images/inpulse/sync-white.svg'}
            isDisabled={analytics[ANALYTICS_TYPE.ORDER][IN_PROGRESS]}
            label={i18next.t('BACKOFFICE.ANALYTICS.RELAUNCH_ACTION')}
          />
        }
        renderLastAction={
          !!analytics[ANALYTICS_TYPE.ORDER][INFO].estimatedEndDate && (
            <LastAction
              date={analytics[ANALYTICS_TYPE.ORDER][INFO].estimatedEndDate}
              displayCountDown={!!analytics[ANALYTICS_TYPE.ORDER][INFO].estimatedEndDate}
              label={i18next.t('GENERAL.TIME_LEFT')}
            />
          )
        }
        title={i18next.t('BACKOFFICE.ANALYTICS.RELAUNCH_ORDER_TITLE')}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  modal: state.modalReducer,
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showConfirmationMessage(message, 'error'));
  },
  showSuccessMessage: (message) => {
    dispatch(showConfirmationMessage(message, 'success'));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeAnalytics);
