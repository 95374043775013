import axios from '../core/http';

import { APIcaller, APIcallerv2 } from './APIcaller';

import { config } from '../config';

const baseApiUrl = `${config.baseApiUrl}/v1`;

export const getRecipesOfClient = ({
  computeRecipesCost = false,
  onlyAvailableInStocks = false,
} = {}) => APIcallerv2.apiGet(`/recipes`, { computeRecipesCost, onlyAvailableInStocks });

export const getRecipeParents = (id) => APIcallerv2.apiGet(`/recipes/${id}/parents-mappings`);

export const getRecipesByCategoriesForRecipeComposition = () =>
  APIcallerv2.apiGet(`/recipes/composition-choices`);

export const getRecipe = (recipeId, shouldFetchComposition = true) =>
  APIcallerv2.apiGet(`/recipes/${recipeId}`, { shouldFetchComposition });

export const getCompositions = (recipeId) =>
  APIcallerv2.apiGet(`/recipes/${recipeId}/compositions`);

export const getAssociatedRecipesByIds = (
  recipeIds,
  shouldFetchComposition = false,
  shouldFetchIngredientsRecursively = false,
) =>
  APIcaller.apiPost(`/admin/recipes/list`, {
    recipeIds,
    shouldFetchComposition,
    shouldFetchIngredientsRecursively,
  });

export const getRecipeCost = (recipeId, { withAllergens = true } = {}) =>
  APIcallerv2.apiGet(`/recipes/${recipeId}/cost`, { withAllergens });

export const create = (
  clientId,
  {
    img,
    recipePreparationSteps,
    name,
    unit,
    value,
    category,
    hasMultipleChannels,
    hasStock,
    hasLoss,
    batchProduction,
    compositions,
    brandIds,
    inventoryListTemplates,
    categoryId,
  },
) =>
  APIcaller.apiPost('/admin/recipes', {
    img,
    recipePreparationSteps,
    name,
    unit,
    value,
    clientId,
    category,
    hasMultipleChannels,
    hasStock,
    hasLoss,
    batchProduction,
    compositions,
    brandIds,
    inventoryListTemplates,
    categoryId,
  });

export const patchById = ({
  id,
  img,
  recipePreparationSteps,
  name,
  unit,
  value,
  hasMultipleChannels,
  hasStock,
  hasLoss,
  batchProduction,
  compositions,
  brandIds,
  inventoryListTemplates,
  categoryId,
}) =>
  APIcaller.apiPatch(`/admin/recipes/${id}`, {
    img,
    name,
    recipePreparationSteps,
    unit,
    value,
    hasMultipleChannels,
    hasStock,
    hasLoss,
    batchProduction,
    compositions,
    brandIds,
    inventoryListTemplates,
    categoryId,
  });

export const disableRecipes = (recipeIds) =>
  APIcaller.apiPatch('/admin/recipes/disable', { recipeIds });

export const enableRecipe = (recipeId) =>
  axios.patch(
    baseApiUrl + '/admin/recipes/enable',
    {
      recipeIds: [recipeId],
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    },
  );

export const deleteDisabledRecipes = (recipeIds) =>
  axios.delete(baseApiUrl + '/admin/recipes', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('loopbackAccessToken'),
    },
    data: {
      recipeIds,
    },
  });

export const checkForCircularRecipeMappings = (parentId, childId) =>
  APIcallerv2.apiGet('/recipes/check/circular-mappings', { parentId, childId });

export const activateMulitpleChannelsByIds = (entityIds) =>
  APIcaller.apiPost('/admin/recipes/activate-multiple-channels', {
    entityIds,
  });

export const downloadRecipeDetails = (
  clientId,
  recipeId,
  name,
  img,
  recipePreparationSteps,
  category,
  value,
  unit,
  compositions,
) =>
  APIcaller.apiPost('/admin/recipes/download-pdf', {
    clientId,
    recipeId,
    name,
    img,
    recipePreparationSteps,
    category,
    value,
    unit,
    compositions,
  });

export const updateRecipesAvailabilities = (entityIds, hasStock, hasLoss) =>
  APIcaller.apiPatch('/admin/recipes/availability', { entityIds, hasStock, hasLoss });

export const patchCategories = (recipeIds, categoryId) =>
  APIcaller.apiPatch('/admin/recipes/categories', {
    recipeIds,
    categoryId,
  });

export const getRecipesByStoresTypes = (storeIds) =>
  APIcaller.apiPost('/admin/recipes/list/by-stores-types', { storeIds });

export default {
  getRecipesOfClient,
  getRecipeParents,
  getRecipe,
  getCompositions,
  getRecipeCost,
  create,
  patchById,
  disableRecipes,
  deleteDisabledRecipes,
  getAssociatedRecipesByIds,
  getRecipesByCategoriesForRecipeComposition,
  enableRecipe,
  checkForCircularRecipeMappings,
  activateMulitpleChannelsByIds,
  downloadRecipeDetails,
  updateRecipesAvailabilities,
  patchCategories,
  getRecipesByStoresTypes,
};
