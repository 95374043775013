import { get } from 'lodash';

import importTypeXlsConstants from '@commons/Excels/constants/importType.xslx.constant';
import productsXlsConstants from '@commons/Excels/constants/products.xlsx.constant';

import { normalizeJsonObject } from '@backoffice/utils';

export const formatProductBatchCreationFile = (products) => {
  const formattedProducts = normalizeJsonObject(products);

  const normalizedColumnIds = productsXlsConstants.getNormalizedColumnIds();

  // Sheet configuration
  const sheetConfig = productsXlsConstants.getSheetConfiguration();

  // Categories
  const hiddenSheetCategoryConfig = productsXlsConstants.getHiddenSheetCategoryConfig();

  // SubCategories
  const hiddenSheetSubCategoryConfig = productsXlsConstants.getHiddenSheetSubCategoryConfig();

  // Brands
  const hiddenSheetBrandConfig = productsXlsConstants.getHiddenSheetBrandConfig();

  // Associated Ingredients
  const hiddenSheetAssociatedIngredientConfig =
    productsXlsConstants.getHiddenSheetAssociatedIngredientConfig();

  // Associated Recipes
  const hiddenSheetAssociatedRecipeConfig =
    productsXlsConstants.getHiddenSheetAssociatedRecipeConfig();

  return Object.keys(formattedProducts).reduce((result, sheetName) => {
    if (!result[importTypeXlsConstants.KEY]) {
      result[importTypeXlsConstants.KEY] = get(formattedProducts, 'ImportType[0].importtype', null);
    }

    if (sheetName === sheetConfig.NAME) {
      result[sheetConfig.ID] = formattedProducts[sheetName].map((current) => ({
        [productsXlsConstants.COLUMN_IDS.NAME]: current[normalizedColumnIds.NAME],
        [productsXlsConstants.COLUMN_IDS.BRAND]: current[normalizedColumnIds.BRAND],
        [productsXlsConstants.COLUMN_IDS.CATEGORY]: current[normalizedColumnIds.CATEGORY],
        [productsXlsConstants.COLUMN_IDS.SUB_CATEGORY]: current[normalizedColumnIds.SUB_CATEGORY],
        [productsXlsConstants.COLUMN_IDS.SKU]: get(
          current,
          `${normalizedColumnIds.SKU}`,
          '',
        ).toString(),
        [productsXlsConstants.COLUMN_IDS.SHELF_LIFE]: current[normalizedColumnIds.SHELF_LIFE],
        [productsXlsConstants.COLUMN_IDS.PRICE_WITH_TAXES]: get(
          current,
          `${normalizedColumnIds.PRICE_WITH_TAXES}`,
          0,
        ),
        [productsXlsConstants.COLUMN_IDS.VAT_RATE]: get(
          current,
          `${normalizedColumnIds.VAT_RATE}`,
          0,
        ),
        [productsXlsConstants.COLUMN_IDS.DELIVERY_PRICE_WITH_TAXES]: get(
          current,
          `${normalizedColumnIds.DELIVERY_PRICE_WITH_TAXES}`,
          0,
        ),
        [productsXlsConstants.COLUMN_IDS.DELIVERY_VAT_RATE]: get(
          current,
          `${normalizedColumnIds.DELIVERY_VAT_RATE}`,
          0,
        ),
        [productsXlsConstants.COLUMN_IDS.COMPOSITION_TYPE]:
          current[normalizedColumnIds.COMPOSITION_TYPE],
        [productsXlsConstants.COLUMN_IDS.ASSOCIATED_RECIPE]:
          current[normalizedColumnIds.ASSOCIATED_RECIPE],
        [productsXlsConstants.COLUMN_IDS.ASSOCIATED_INGREDIENT]:
          current[normalizedColumnIds.ASSOCIATED_INGREDIENT],
      }));
    }

    // Category
    if (sheetName === hiddenSheetCategoryConfig.SHEET.NAME) {
      result[hiddenSheetCategoryConfig.SHEET.ID] = formattedProducts[sheetName].map((current) => ({
        [hiddenSheetCategoryConfig.COLUMN_ID.NAME]:
          current[hiddenSheetCategoryConfig.NORMALIZE_COLUMN_ID.NAME],
      }));
    }

    // Subcategory
    if (sheetName === hiddenSheetSubCategoryConfig.SHEET.NAME) {
      result[hiddenSheetSubCategoryConfig.SHEET.ID] = formattedProducts[sheetName].map(
        (current) => ({
          [hiddenSheetSubCategoryConfig.COLUMN_ID.NAME]:
            current[hiddenSheetSubCategoryConfig.NORMALIZE_COLUMN_ID.NAME],
        }),
      );
    }

    // Brand
    if (sheetName === hiddenSheetBrandConfig.SHEET.NAME) {
      result[hiddenSheetBrandConfig.SHEET.ID] = formattedProducts[sheetName].map((current) => ({
        [hiddenSheetBrandConfig.COLUMN_ID.ID]:
          current[hiddenSheetBrandConfig.NORMALIZE_COLUMN_ID.ID],
        [hiddenSheetBrandConfig.COLUMN_ID.NAME]:
          current[hiddenSheetBrandConfig.NORMALIZE_COLUMN_ID.NAME],
      }));
    }

    // Associated ingredient
    if (sheetName === hiddenSheetAssociatedIngredientConfig.SHEET.NAME) {
      result[hiddenSheetAssociatedIngredientConfig.SHEET.ID] = formattedProducts[sheetName].map(
        (current) => ({
          [hiddenSheetAssociatedIngredientConfig.COLUMN_ID.ID]:
            current[hiddenSheetAssociatedIngredientConfig.NORMALIZE_COLUMN_ID.ID],
          [hiddenSheetAssociatedIngredientConfig.COLUMN_ID.NAME]:
            current[hiddenSheetAssociatedIngredientConfig.NORMALIZE_COLUMN_ID.NAME],
        }),
      );
    }

    // Associated recipe
    if (sheetName === hiddenSheetAssociatedRecipeConfig.SHEET.NAME) {
      result[hiddenSheetAssociatedRecipeConfig.SHEET.ID] = formattedProducts[sheetName].map(
        (current) => ({
          [hiddenSheetAssociatedRecipeConfig.COLUMN_ID.ID]:
            current[hiddenSheetAssociatedRecipeConfig.NORMALIZE_COLUMN_ID.ID],
          [hiddenSheetAssociatedRecipeConfig.COLUMN_ID.NAME]:
            current[hiddenSheetAssociatedRecipeConfig.NORMALIZE_COLUMN_ID.NAME],
        }),
      );
    }

    return result;
  }, {});
};

export default { formatProductBatchCreationFile };
