import { isEmpty } from 'lodash';

import { ENTITY_UNITS } from '../constants/units';
import { SMALL_UNITS_DROPDOWN_ITEMS } from '@admin/utils/DropdownItems';

/**
 * @typedef {object} Conversion
 * @property {string} convertedQuantity - Is a number from an input so the type is string
 * @property {string} convertedUnit - 'kg' | 'L' | 'unit'
 * @property {boolean} isBaseUnit - Is true if it matches the master packaging unit
 */

/**
 * Assert than a supplier product is the same unit than the ingredient itself
 *
 *
 * @param {string} supplierProductUnit  - the supplier product unit to check
 * @param {string} ingredientUnit       - the ingredient's unit to match
 *
 * @returns {boolean} Whether the supplier product is the same unit than the ingredient unit
 */
export function areSPAndIngredientSameUnit(supplierProductUnit, ingredientUnit) {
  if (!supplierProductUnit) {
    return false;
  }

  if (supplierProductUnit === ENTITY_UNITS.KILOGRAM && ingredientUnit !== ENTITY_UNITS.GRAM) {
    return false;
  }

  if (supplierProductUnit === ENTITY_UNITS.LITER && ingredientUnit !== ENTITY_UNITS.MILLILITER) {
    return false;
  }

  if (supplierProductUnit === ENTITY_UNITS.UNIT && ingredientUnit !== ENTITY_UNITS.UNIT) {
    return false;
  }

  return true;
}

const areUnitsInSameFamily = (unitA, unitB) => {
  const unitToFamily = {
    g: 'mass',
    kg: 'mass',
    mL: 'volume',
    L: 'volume',
    unit: 'count',
  };

  const familyA = unitToFamily[unitA];
  const familyB = unitToFamily[unitB];

  if (!familyA || !familyB) {
    return false;
  }

  return familyA === familyB;
};

/**
 * Check that the package unit and ingredient are either the same, or that a conversion exist for this package unit.
 *
 * @param packageUnit
 * @param ingredientUnit
 * @param conversions
 *
 * @returns {boolean}
 */
export const doesIngredientsHaveApplicableUnits = (packageUnit, ingredientUnit, conversions) => {
  // Whether there are conversions or not, if no packaging unit are set, we consider that the unit is the same
  if (!packageUnit) {
    return true;
  }

  const areSameUnit = areSPAndIngredientSameUnit(packageUnit, ingredientUnit);
  const haveConversions = doesPackageUnitHaveConversions(packageUnit, ingredientUnit, conversions);

  return areSameUnit || haveConversions;
};

/**
 * Checks if a given unit is present in the available conversions.
 *
 * @param {string} unit - The unit to check ('kg', 'L', or 'unit').
 * @param {Conversion[]} conversions - Array of conversions
 * @returns {boolean} - Returns `true` if the unit is present in the conversions, `false` otherwise.
 */
export const canUnitBeConverted = (unit, conversions) => {
  if (isEmpty(conversions)) {
    return false;
  }

  const hasValidConversion = conversions.some(
    ({ convertedUnit }) =>
      areSPAndIngredientSameUnit(convertedUnit, unit) || convertedUnit === unit,
  );

  return hasValidConversion;
};

/**
 * Check that the package unit has an existing conversion to the ingredient unit
 *
 * @param packageUnit
 * @param ingredientUnit
 * @param conversions
 *
 * @returns {boolean}
 */
export const doesPackageUnitHaveConversions = (packageUnit, ingredientUnit, conversions) => {
  if (isEmpty(conversions)) {
    return false;
  }

  const packageIsBaseUnit = conversions.some(
    ({ isBaseUnit, convertedUnit }) => isBaseUnit && convertedUnit === packageUnit,
  );

  const hasAConversionForIngredient = conversions.some(({ convertedUnit }) =>
    areSPAndIngredientSameUnit(convertedUnit, ingredientUnit),
  );

  return packageIsBaseUnit && hasAConversionForIngredient;
};

export const spUnitByIngredientUnit = new Map([
  ['unit', 'unit'],
  ['g', 'kg'],
  ['mL', 'L'],
]);

export const getIngredientUnitName = (value) => {
  const smallUnitItem = SMALL_UNITS_DROPDOWN_ITEMS().find(({ id }) => id === value);
  return isEmpty(smallUnitItem) ? '' : smallUnitItem.value;
};

/**
 * There are a few scenarios to consider regarding units consistency
 * First is Central Kitchen Products, then Supplier Product (based on its packaging) and Entity
 * Each letters represent a unit (either kg|g, L|mL or unit)
 *
 * A, A, A → OK
 * A, B, A → OK if SP has conversions
 * A, A, B → OK if SP has conversions
 * A, B, B → Forbidden
 * A, B, C → Forbidden
 *
 * @param {string} centralProductUnit - Unit from the entity associated with the product, converted to a SP unit ('kg', 'L' or 'unit')
 * @param {string} ingredientUnit - Unit of the ingredient associated with the SP
 * @param {Conversion[]} conversionsToUse
 * @param {object[]} spPackagings - Array of object with a unit ('kg', 'L' or 'unit)
 *
 * @return {boolean}
 */
export const handleUnitsValidity = (
  centralProductUnit,
  ingredientUnit,
  conversionsToUse,
  spPackagings,
) => {
  const masterPackaging = spPackagings.find(
    ({ masterSupplierProductPackagingId }) => !masterSupplierProductPackagingId,
  );

  if (!masterPackaging) {
    return false;
  }

  // Does the ingredient have the same unit than the central product or are there conversions existing ?
  const doesIngredientMatchProductUnit = doesIngredientsHaveApplicableUnits(
    centralProductUnit,
    ingredientUnit,
    conversionsToUse,
  );

  const doesIngredientHaveValidConversions = canUnitBeConverted(ingredientUnit, conversionsToUse);

  // If masterPackaging unit is different from the packaging unit or if the ingredient unit is not valid it means
  // that we need to check if a conversion exists (A, B, A or A, A, B cases)
  if (
    !areUnitsInSameFamily(masterPackaging.unit, centralProductUnit) ||
    !doesIngredientMatchProductUnit
  ) {
    // No conversions mean that the units are not valid
    if (!conversionsToUse.length) {
      return false;
    }

    const doSPAndIngredientHaveSameUnit = areSPAndIngredientSameUnit(
      masterPackaging.unit,
      ingredientUnit,
    );

    // SP and ingredient have the same unit but different from the central product unit (A, B, B case)
    if (doSPAndIngredientHaveSameUnit) {
      return false;
    }

    // Ingredient and Product does not have the same unit and there are no valid conversions (A, A, B case without conversions)
    if (!doesIngredientMatchProductUnit && !doesIngredientHaveValidConversions) {
      return false;
    }

    const doesProductHaveValidConversions = canUnitBeConverted(
      centralProductUnit,
      conversionsToUse,
    );

    // Ingredient has no valid conversions for SP and same for Product (A, B, A case without conversions)
    if (!doesIngredientHaveValidConversions || !doesProductHaveValidConversions) {
      return false;
    }
  }

  // Turn ingredient unit ('g', 'mL', 'unit') into a SP unit ('kg', 'L', 'unit')`
  const ingredientUnitToSPUnit = spUnitByIngredientUnit.get(ingredientUnit);
  const centralProductUnitUnitToSpUnit = spUnitByIngredientUnit.get(centralProductUnit);

  // If all 3 units are different then it's invalid (A, B, C case)
  if (
    ingredientUnitToSPUnit !== masterPackaging.unit &&
    masterPackaging.unit !== centralProductUnit &&
    // Need to compare with both type of units (e.g. 'g' or 'kg') since
    // the unit of a recipe is 'kg' and ingredient is 'g'
    centralProductUnit !== ingredientUnitToSPUnit &&
    centralProductUnit !== ingredientUnit &&
    centralProductUnitUnitToSpUnit !== masterPackaging.unit &&
    centralProductUnitUnitToSpUnit !== ingredientUnitToSPUnit
  ) {
    return false;
  }

  return true;
};
