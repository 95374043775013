import { DATE_DISPLAY_FORMATS } from '../commons/DatePickers/constants';

import { APIcallerv2 } from './APIcaller';

const getForecastsProductMix = (storeId, date) =>
  APIcallerv2.apiGet(`/product-mix/${storeId}/forecasts`, {
    date: date.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
  });

export const productMix = {
  getForecastsProductMix,
};
