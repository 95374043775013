import { product } from '../services/product';

export const receiveProducts = (products) => ({
  type: 'PRODUCTS_SUCCESS',
  products,
});

export const requestProductsError = (error) => ({
  type: 'PRODUCTS_FAILURE',
  error,
});

export const fetchProductsOfClient = () =>
  function (dispatch) {
    dispatch({
      type: 'PRODUCTS_REQUEST',
    });
    return product.getProductsOfClient();
  };

export const updateIsSynchronizingCashierProductsStatus = (isSynchronizingCashierProducts) => ({
  type: 'IS_SYNCHRONIZING_CASHIER_PRODUCTS_UPDATE',
  isSynchronizingCashierProducts,
});

// Get Products Categories

export const receivePatchMultipleProducts = (products) => ({
  type: 'PATCH_MANY_PRODUCTS_SUCCESS',
  products,
});

export const requestPatchMultipleProductsError = (error) => ({
  type: 'PATCH_MANY_PRODUCTS_FAILURE',
  error,
});

export const patchMultipleProducts = (products) =>
  function (dispatch) {
    dispatch({
      type: 'PATCH_MANY_PRODUCTS_REQUEST',
    });
    return product.patchMultipleProducts(products);
  };
