import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { Input } from '@lib/inpulse/Input';
import InputEmailChip from '@lib/inpulse/InputEmailChip';

import {
  Container,
  DetailsContainer,
  EmailContainer,
  InputContainer,
  TitleContainer,
} from './styledComponents';

const getContactInputSettings = () => [
  {
    propertyKey: 'contactName',
    label: i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_CONTACT_DETAILS_NAME'),
    placeholder: i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_CONTACT_DETAILS_NAME'),
    type: 'text',
  },
  {
    propertyKey: 'telephone',
    label: i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_CONTACT_DETAILS_PHONE'),
    placeholder: i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_CONTACT_DETAILS_PHONE'),
    type: 'text',
  },
];

/******************************************************************/
/* IMPORTANT / WARNING !!!!!!!!!!!!!!!!!!!!!!!!!!                 */
/* FOR SUPPLIER PROFILE EMAIL1 AND EMAIL2 ARE RESPECTIVELY FOR :  */
/* EMAIL1: Order email (envoie de commande)                       */
/* EMAIL2: Credit email (envoie de demande d'avoir)               */
/******************************************************************/

const SupplierProfileContactDetails = (props) => {
  const { supplierProfileDetails, handleSupplierProfileChange, areInputsDisabled } = props;

  const [orderEmails, setOrderEmails] = useState([]);
  const [creditEmails, setCreditEmails] = useState([]);
  const [bccEmails, setBccEmails] = useState([]);

  const contactInputSettings = getContactInputSettings();

  const updateEmails = (emailField, setEmails) => {
    if (!!supplierProfileDetails[emailField]) {
      setEmails(supplierProfileDetails[emailField].split(','));
    } else {
      setEmails([]);
    }
  };

  useEffect(() => {
    updateEmails('email1', setOrderEmails);
  }, [supplierProfileDetails.email1]);

  useEffect(() => {
    updateEmails('email2', setCreditEmails);
  }, [supplierProfileDetails.email2]);

  useEffect(() => {
    updateEmails('bccEmails', setBccEmails);
  }, [supplierProfileDetails.bccEmails]);

  return (
    <Container>
      <TitleContainer>
        {i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_CONTACT_DETAILS')}
      </TitleContainer>
      <DetailsContainer>
        {contactInputSettings.map((input, index) => (
          <InputContainer key={`input-${index}`}>
            <Input
              label={input.label}
              placeholder={input.placeholder}
              readOnly={areInputsDisabled}
              type={input.type}
              value={supplierProfileDetails[input.propertyKey] || ''}
              onChange={(ev) => handleSupplierProfileChange(ev.target.value, input.propertyKey)}
            />
          </InputContainer>
        ))}
      </DetailsContainer>
      <EmailContainer>
        <InputEmailChip
          emails={orderEmails}
          isDisabled={areInputsDisabled}
          placeholder={i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_CONTACT_ORDERS_EMAILS')}
          propertyKey="email1"
          setEmails={(emails) => handleSupplierProfileChange(emails, 'email1')}
          title={i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_CONTACT_ORDERS_EMAILS')}
        />
      </EmailContainer>
      <EmailContainer>
        <InputEmailChip
          emails={creditEmails}
          isDisabled={areInputsDisabled}
          placeholder={i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_CONTACT_CREDITS_EMAILS')}
          propertyKey="email2"
          setEmails={(emails) => handleSupplierProfileChange(emails, 'email2')}
          title={i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_CONTACT_CREDITS_EMAILS')}
        />
      </EmailContainer>
      <EmailContainer>
        <InputEmailChip
          emails={bccEmails}
          isDisabled={areInputsDisabled}
          placeholder={i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_CONTACT_BCC_EMAILS')}
          propertyKey="bccEmails"
          setEmails={(emails) => handleSupplierProfileChange(emails, 'bccEmails')}
          title={i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_CONTACT_BCC_EMAILS')}
        />
      </EmailContainer>
    </Container>
  );
};

export default SupplierProfileContactDetails;
