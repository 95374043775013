import { connect } from 'react-redux';
import { orderBy } from 'lodash';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import utilsXLS, { generateDatesSheetFromStartEndDate } from '@commons/utils/makeXLS';

import ExportModalContent from '@lib/inpulse/ExportModal';

const getMainSheetColumnsSettings = (currency = {}) => [
  {
    name: i18next.t('GENERAL.NAME'),
    propertyKey: 'store.name',
  },
  {
    type: 'currency',
    name: `${i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_REVENUE')} (${currency.alphabeticCode})`,
    propertyKey: 'turnover',
  },
  {
    type: 'percentage',
    name: i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_PERCENT_ACTUAL_FC'),
    propertyKey: 'orderOverTurnoverRatio',
  },
  {
    type: 'currency',
    name: `${i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_ACTUAL_FC')} (${currency.alphabeticCode})`,
    propertyKey: 'foodCost.realConsumption',
  },
  {
    type: 'percentage',
    name: i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_PERCENT_THEORETICAL_FC'),
    propertyKey: 'materialRatio',
  },
  {
    type: 'currency',
    name: `${i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_THEORETICAL_FC')} (${
      currency.alphabeticCode
    })`,
    propertyKey: 'theoricalConsumption',
  },
  {
    type: 'currency',
    name: `${i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_FC_DEVIATION')} (${
      currency.alphabeticCode
    })`,
    propertyKey: 'foodCost.gapFromTheoretical',
  },
  {
    type: 'percentage',
    name: i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_PERCENT_FC_DEVIATION'),
    propertyKey: 'realFromTheoricalRatio',
  },
  {
    name: i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_WASTES'),
    propertyKey: 'losses.total',
    type: 'currency',
  },
  {
    type: 'percentage',
    name: i18next.t('ANALYSIS.FOOD_COST.LOSS_RATE'),
    propertyKey: 'lossRatio',
  },
  {
    name: i18next.t('ANALYSIS.FOOD_COST.COLUMN_NAME_INVENTORY_SHRINKAGE'),
    propertyKey: 'inventoryShrinkage',
    type: 'currency',
  },
];

const createMainSheet = (analytics, currency) => {
  const headersSettings = getMainSheetColumnsSettings(currency);

  const formattedDataToExport = analytics.map((item) => {
    if (item.id === 'total') {
      return {
        ...item,
        storeName: i18next.t('GENERAL.ANALYTICS_LABEL_TOTAL'),
      };
    }

    return item;
  });

  return utilsXLS.generateDefaultSheet(
    i18next.t('ORDERS.BY_CATEGORY.EXPORT_MAIN_SHEET_NAME'),
    headersSettings,
    formattedDataToExport,
    currency,
  );
};

export const OrderAnalyticsExportModal = (props) => {
  const {
    currency,
    params: { title, analytics, startDate, endDate },
    closeModal,
  } = props;

  const [progress, setProgress] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [titleModal, setTitle] = useState(title || '');

  const exportReady = () => {
    setTitle(i18next.t('GENERAL.EXPORT_SUCCESS'));

    setLoading(false);
  };

  const exitModal = () => {
    setLoading(false);

    closeModal();
  };

  const exportAnalytics = () => {
    if (!analytics) {
      return;
    }

    const mainSheet = createMainSheet(orderBy(analytics, 'store.name'), currency);

    const datesSheet = generateDatesSheetFromStartEndDate(
      startDate.format('YYYY-MM-DD'),
      endDate.format('YYYY-MM-DD'),
    );

    utilsXLS.makeXLS(i18next.t('ORDERS.BY_CATEGORY.EXPORT_FILENAME'), [mainSheet, datesSheet]);
  };

  useEffect(() => {
    exportAnalytics();

    setProgress(100);

    exportReady();
  }, [analytics]);

  return (
    <ExportModalContent
      {...props}
      closeModal={closeModal}
      exitModal={exitModal}
      isLoading={isLoading}
      progress={progress}
      setLoading={setLoading}
      titleModal={titleModal}
    />
  );
};

const mapStateToProps = (state) => ({
  currency: state.baseReducer.currency,
});

export default connect(mapStateToProps)(OrderAnalyticsExportModal);
