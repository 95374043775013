import { APIcaller, APIcallerv2 } from './APIcaller';

const getCatalogsByClientId = () => APIcallerv2.apiGet('/catalogs');

const createCatalog = (clientId, name, accountIds) =>
  APIcaller.apiPost(`/admin/${clientId}/catalogs`, { name, accountIds });

const updateCatalogStatus = (clientId, active, catalogIds) =>
  APIcaller.apiPatch(`/admin/${clientId}/catalogs/status`, { active, catalogIds });

const getCatalogById = (catalogId) => APIcallerv2.apiGet(`/catalogs/${catalogId}`);

const updateCatalog = (catalogId, name, accountIds = null) =>
  APIcaller.apiPatch(`/admin/catalogs/${catalogId}`, { name, accountIds });

export default {
  getCatalogsByClientId,
  createCatalog,
  updateCatalogStatus,
  getCatalogById,
  updateCatalog,
};
