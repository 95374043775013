import React, { createContext, useEffect, useState } from 'react';

import { getListViewState } from './utils';

export const InvoiceControlContext = createContext({});

export const InvoiceControlProvider = ({ children }) => {
  const listViewStates = getListViewState();

  // Applied filters
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);

  // Advanced filters
  const [advancedFilters, setAdvancedFilters] = useState(null);

  useEffect(() => {
    listViewStates.setOrderBy('createdAt');
    listViewStates.setOrderType('desc');
  }, []);

  useEffect(() => {
    listViewStates.setCurrentPage(1);
  }, [selectedSuppliers, advancedFilters]);

  return (
    <InvoiceControlContext.Provider
      value={{
        ...listViewStates,
        selectedSuppliers,
        setSelectedSuppliers,
        advancedFilters,
        setAdvancedFilters,
      }}
    >
      {children}
    </InvoiceControlContext.Provider>
  );
};

export default InvoiceControlContext;
