import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';

import { showConfirmationMessage } from '@actions/messageconfirmation';

import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';
import WhiteCard from '@commons/WhiteCard';

import { getClientInfo } from '@selectors/client';

import { Container, Content } from './styledComponents';
import { getRecipes, getStores } from './services';
import Ingredient from './components/IngredientStats';
import RecipeStats from './components/RecipeStats';

export const BackOfficeRecipeDebugger = ({ client, match, showMessage }) => {
  const [stores, setStores] = useState([]);
  const [recipes, setRecipes] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const handleFetchStoresOfClient = async () => {
    try {
      const result = await getStores();

      setStores(result);
    } catch (err) {
      setStores([]);

      showMessage('Impossible de récupérer la liste des stores', 'error');
    }
  };

  const handleFetchRecipesOfClient = async () => {
    try {
      const result = await getRecipes();

      setRecipes(result);
    } catch (err) {
      setRecipes([]);

      showMessage('Impossible de récupérer la liste des recettes', 'error');
    }
  };

  useEffect(() => {
    (async function loadData() {
      setIsLoading(true);

      await handleFetchStoresOfClient();
      await handleFetchRecipesOfClient();

      setIsLoading(false);
    })();
  }, []);

  return (
    <Container>
      <NavigationBreadCrumb featurePath={match.path} />
      <Content>
        <WhiteCard
          maxWidth={'1200px'}
          renderContent={<RecipeStats isLoading={isLoading} recipes={recipes} stores={stores} />}
          title={'Les recettes'}
        />
        <WhiteCard
          maxWidth={'1200px'}
          renderContent={
            <Ingredient
              clientId={client && client.clientId}
              isLoading={isLoading}
              recipes={recipes}
              stores={stores}
            />
          }
          title={'Les ingrédients'}
        />
      </Content>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeRecipeDebugger);
