import { Toaster } from 'deepsight-react-components';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import i18next from 'i18next';
import moment from 'moment';
import React, { Component, Suspense } from 'react';

import 'moment/min/locales.min';

import '@trendmicro/react-tooltip/dist/react-tooltip.css';

import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';

import { StyledErrorBoundary } from '../commons/ErrorBoundary';
import MaintenancePage from '../commons/MaintenancePage';
import Notifications from '../commons/Notifications';

import { closeConfirmationModal } from '../actions/confirmationmodal';
import {
  closeGenericModal,
  closeMediumModal,
  closeModal,
  closeSlidingModal,
  closeSupplierIngredientAlreadyAssociatedWarningModal,
} from '../actions/modal';
import { fetchUser, switchCentralMode } from '../actions/user';
import { hideMessage, showErrorMessage } from '../actions/messageconfirmation';
import { logout } from '../actions/logout';
import { newAppVersionReceived } from '../actions/refresh';

import { getAppVersion } from '../services/appVersion';
import { getCurrencyById, requestCurrency, requestCurrencyError } from '../actions/currency';
import { getHealthStatus } from '../services/healthCheck';
import { getStoresOfUser, receiveStores, requestStoresError } from '../actions/store';
import {
  receiveBrandsOfClient,
  requestBrandsOfClientError,
  getBrandsOfClient,
} from '@actions/brand';

import { updateSlowConnectionStatus } from '../actions/offline';

import { config } from '../config';
import { fetchUserRights } from '../commons/utils/features';
import { getClientInfo } from '../selectors/client';
import { getIsCentralMode, setLocalStorageItem } from '../commons/utils/localStorage';
import { getSalesPointStores } from '../selectors/stores';
import { withTheme } from 'styled-components';
import GenericModal from '../commons/Modals/GenericModal';
import StyledClientContainer from './StyledClientContainer';
import StyledConfirmationModalContainer from './ConfirmationModalContainer';
import StyledLoader from '../commons/DeepsightComponents/Loader/Loader';
import StyledMediumModalContainer from './MediumModal';
import StyledModalContainer from './ModalContainer';
import StyledSlideModalContainer from './SlideModalContainer';
import SupplierIngredientAlreadyAssociatedWarningModal from '../commons/Modals/SupplierIngredientAlreadyAssociatedWarningModal';

const INTERVAL_CHECK_STATUS_APP_VERSION_IN_MS = 2 * 60 * 1000; // 2 minutes

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stores: [],
      isLoadingStores: true,
      isInMaintenance: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.modal !== prevState.modal) {
      return { modal: nextProps.modal };
    } else {
      return null;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { history, shouldReloadApp } = this.props;

    if (prevProps.shouldReloadApp !== this.props.shouldReloadApp) {
      // Auto Refresh app when user changes its URL
      history.listen((_, action) => {
        const hasPathnameChanged = action !== 'REPLACE'; // 'REPLACE' often refers to query params being updated

        if (shouldReloadApp && hasPathnameChanged) {
          window.location.reload();
        }
      });
    }

    if (!_.isEqual(this.props.user, prevProps.user)) {
      const userLanguageCode = _.get(this.props.user, 'lnkLanguageAccountrel.code', 'fr');

      moment.locale(userLanguageCode, {
        week: {
          dow: 1, // For the sake of the order feature, monday is the first day of the week.
        },
      });
      this.props.i18n.changeLanguage(userLanguageCode);

      this.props.getStoresOfUser();

      if (this.props.client.hasMultipleBrands) {
        this.props.getBrandsOfClient();
      }

      if (this.props.client.currencyId) {
        this.props.getCurrencyById(this.props.client.currencyId);
      }

      if (this.props.user.id) {
        this.props.getCurrentUserRights(this.props.user.id, this.props.user.hasForecasts);
      }
    }

    if (
      this.state.stores !== prevState.stores &&
      this.state.stores.length > 0 &&
      this.state.stores.every(({ isKitchen }) => isKitchen)
    ) {
      setLocalStorageItem('isCentralMode', true);
    }

    if (!_.isEqual(this.props.user, prevProps.user) && !this.props.user.id) {
      //Token expired for instance, redirect user to login
      if (this.state.stores.length > 0) {
        this.setState({ stores: [] });
      }
      this.props.handleAuthenticatedChange();
    }
    if (prevProps.stores !== this.props.stores) {
      this.setState({ stores: this.props.stores, isLoadingStores: false });
    }
  }

  getLastAppVersion = async () => {
    try {
      const lastAppVersion = await getAppVersion();
      const currentAppVersion = process.env.REACT_APP_GIT_SHA;

      this.props.setNewAppVersionReceived(
        lastAppVersion ? lastAppVersion !== currentAppVersion : false,
      );
    } catch (e) {
      console.info('Silently ignore this Error');

      this.props.setNewAppVersionReceived(false);
    }
  };

  getHealthStatusAPI = async () => {
    try {
      const { duration, status } = await getHealthStatus();

      const isConnectionSlow = duration >= config.limitResponseTime;

      if (this.props.client.hasOffline && this.props.isConnectionSlow != isConnectionSlow) {
        this.props.updateSlowConnectionStatus(isConnectionSlow);
      }

      if (status.isInMaintenance && !this.state.isInMaintenance) {
        this.setState({ isInMaintenance: true });
      } else if (!status.isInMaintenance && this.state.isInMaintenance) {
        this.setState({ isInMaintenance: false });
      }
    } catch (e) {
      console.info('[#getHealthStatusAPI] - Silently ignore this Error');
    }
  };

  componentDidMount = async () => {
    const userId = localStorage.getItem('userId');
    this.props.getCurrentUser(userId);

    // Set check app version interval
    const getLastAppVersionJob = setInterval(
      this.getLastAppVersion,
      INTERVAL_CHECK_STATUS_APP_VERSION_IN_MS,
    );
    this.setState({ getLastAppVersionJob });

    // initial status to get
    this.getHealthStatusAPI();

    // Set Health Status interval
    const getHealthStatusAPIJob = setInterval(
      this.getHealthStatusAPI,
      INTERVAL_CHECK_STATUS_APP_VERSION_IN_MS,
    );
    this.setState({ getHealthStatusAPIJob });
  };

  componentWillUnmount = () => {
    clearInterval(this.state.getLastAppVersionJob);
    clearInterval(this.state.getHealthStatusAPIJob);
  };

  render() {
    return (
      <div className={`app-container ${this.props.className}`}>
        <StyledErrorBoundary>
          <StyledLoader />
          <Notifications />
          {/* ******* MODALS ******* */}
          {this.state.modal.modalBool && (
            <StyledModalContainer
              closeModal={this.props.closeModal}
              component={this.state.modal.params.component}
              deactivateBackgroundClick={this.state.modal.deactivateBackgroundClick}
              fullscreen={this.state.modal.fullscreen}
              params={this.state.modal.params}
            />
          )}
          {this.state.modal.SupplierIngredientAlreadyAssociatedWarningModalBool && (
            <SupplierIngredientAlreadyAssociatedWarningModal
              closeSupplierIngredientAlreadyAssociatedWarningModal={
                this.props.closeSupplierIngredientAlreadyAssociatedWarningModal
              }
              component={this.state.modal.params.component}
              params={this.state.modal.params}
            />
          )}
          {this.state.modal.GenericModalBool && (
            <GenericModal
              closeGenericModal={this.props.closeGenericModal}
              component={this.state.modal.params.component}
              params={this.state.modal.params}
            />
          )}
          {this.state.modal.mediumModalBool && (
            <StyledMediumModalContainer
              closeModal={this.props.closeMediumModal}
              component={this.state.modal.params.component}
              customStyle={this.state.modal.params.customStyle}
              deactivateBackgroundClick={this.state.modal.deactivateBackgroundClick}
              params={this.state.modal.params}
              sizeModal={this.state.modal.sizeModal}
            />
          )}
          <StyledSlideModalContainer
            closeModal={this.props.closeSlidingModal}
            component={
              typeof this.state.modal.params !== 'undefined'
                ? this.state.modal.params.component
                : null
            }
            params={this.state.modal.params}
            shouldRender={this.state.modal.slideModalBool}
          />
          {this.state.modal.confModalBool && (
            <StyledConfirmationModalContainer
              closeConfirmationModal={this.props.closeConfirmationModal}
              component={this.state.modal.params.component}
              deactivateBackgroundClick={this.state.modal.params.deactivateBackgroundClick}
              params={this.state.modal.params}
            />
          )}
          {
            <Toaster
              autoDelete={!this.props.confirmationMessageReducer.messageToDismissManually}
              dismissTime={this.props.confirmationMessageReducer.messageDismissTime}
              handleClose={this.props.hideMessage}
              hidden={!this.props.confirmationMessageReducer.messageBool}
              icon={this.props.confirmationMessageReducer.messageCustomIcon}
              status={this.props.confirmationMessageReducer.messageType}
              text={this.props.confirmationMessageReducer.message}
            />
          }
          {this.props.user.clientId && !this.state.isLoadingStores && (
            <MaintenancePage isInMaintenance={this.state.isInMaintenance}>
              <StyledClientContainer
                client={this.props.client}
                handleLogout={this.props.handleLogout}
                isCentralView={getIsCentralMode()}
                stores={this.state.stores}
                user={this.props.user}
                userRights={this.props.userRights}
              />
            </MaintenancePage>
          )}
        </StyledErrorBoundary>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  modal: state.modalReducer,
  confirmationMessageReducer: state.confirmationMessageReducer,
  isConnectionSlow: state.baseReducer.isConnectionSlow,
  user: state.baseReducer.user,
  userRights: state.baseReducer.userRights,
  stores: getSalesPointStores(state.baseReducer.activeStores),
  client: getClientInfo(state.baseReducer.user),
  shouldReloadApp: state.baseReducer.shouldReloadApp,
  isCentralView: state.baseReducer.isCentralView,
});

const mapDispatchToProps = (dispatch) => ({
  handleLogout: () => {
    dispatch(logout());
  },
  setNewAppVersionReceived: (hasNewVersionAvailable) => {
    dispatch(newAppVersionReceived(hasNewVersionAvailable));
  },
  hideMessage: () => {
    dispatch(hideMessage());
  },
  getCurrentUser: (id) => {
    dispatch(fetchUser(id));
  },
  getCurrentUserRights: (userId, hasForecasts) => {
    dispatch(fetchUserRights(userId, hasForecasts));
  },
  closeModal: () => {
    dispatch(closeModal());
  },
  closeMediumModal: () => {
    dispatch(closeMediumModal());
  },
  closeSlidingModal: () => {
    dispatch(closeSlidingModal());
  },
  closeConfirmationModal: () => {
    dispatch(closeConfirmationModal());
  },
  closeGenericModal: () => {
    dispatch(closeGenericModal());
  },
  closeSupplierIngredientAlreadyAssociatedWarningModal: () => {
    dispatch(closeSupplierIngredientAlreadyAssociatedWarningModal());
  },
  getCurrencyById: (currencyId) =>
    dispatch(getCurrencyById(currencyId)).then(
      (result) => {
        dispatch(requestCurrency(result));
      },
      (error) => {
        dispatch(requestCurrencyError(error));
      },
    ),
  getStoresOfUser: () =>
    dispatch(getStoresOfUser()).then(
      (result) => {
        dispatch(receiveStores(result));
      },
      (error) => {
        dispatch(requestStoresError(error));
      },
    ),
  getBrandsOfClient: () =>
    dispatch(getBrandsOfClient()).then(
      (result) => {
        dispatch(receiveBrandsOfClient(result));
      },
      () => {
        dispatch(showErrorMessage(i18next.t('GENERAL.BRANDS_FETCH_ERROR')));
        dispatch(requestBrandsOfClientError());
      },
    ),
  updateSlowConnectionStatus: (isConnectionSlow) => {
    dispatch(updateSlowConnectionStatus(isConnectionSlow));
  },
  switchCentralMode: (centralMode) => {
    dispatch(switchCentralMode(centralMode));
  },
});

const connectedHome = connect(mapStateToProps, mapDispatchToProps)(Home);

const TranslatedHome = withTranslation()(connectedHome);

const HomeComponent = (props) => (
  <Suspense fallback={<div>Loading...</div>}>
    <TranslatedHome {...props} />
  </Suspense>
);

export default withRouter(withTheme(HomeComponent));
