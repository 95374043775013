import { connect } from 'react-redux';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { openGenericModal, closeGenericModal, refreshGenericModal } from '@actions/modal';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { Button, Dropdown } from '@commons/utils/styledLibraryComponents';
import { GENERIC_MODAL_CANCEL_BUTTON } from '@commons/Modals/GenericModal/genericModalActions';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';
import WhiteCard from '@commons/WhiteCard';

import { getClientInfo } from '@selectors/client';

import { ingredient } from '@services/ingredient';
import { product } from '@services/product';
import recipeService from '@services/recipe';

import theme from '@theme';

import { getClients } from '@backoffice/BackOfficeGeneralContainer/BackOfficeRecipeDebugger/services';

import { duplicateClientSetupFromAnotherOne } from '../../services';

import { Container, InputContainer, ContentWhiteCard } from './styledComponents';

const ContentModal = (props) => {
  const { clients, selectedClient, onSelectionChange, isDefaultChannelDifferent } = props;

  return (
    <>
      <Container>
        <InputContainer>
          <Dropdown
            customListStyle={{
              position: 'fixed',
              width: 'inherit',
              top: 'inherit',
              left: 'inherit',
            }}
            isErrorState={isDefaultChannelDifferent}
            isRequired={true}
            isUniqueSelection={true}
            items={clients}
            label={`${i18next.t('BACKOFFICE.PRODUCTS.DUPLICATE_SETUP_CONTENT_INPUT_NAME')} :`}
            placeholder={i18next.t('GENERAL.CHOOSE_PLACEHOLDER')}
            selectedItem={selectedClient}
            onSelectionChange={onSelectionChange}
          />
          {isDefaultChannelDifferent && (
            <Text color={ENUM_COLORS.INFO_RED} font={ENUM_FONTS.TEXT_SMALL_HEIGHT_14_WEIGHT_600}>
              {i18next.t('BACKOFFICE.PRODUCTS.DUPLICATE_DIFFERENT_DEFAULT_CHANNEL_IDS_ERROR')}
            </Text>
          )}
        </InputContainer>
      </Container>
    </>
  );
};

export const DuplicateSetupWhiteCard = (props) => {
  const {
    client: { clientId, defaultChannelId },
    pageLoaded,
    pageLoading,
    openGenericModal,
    closeGenericModal,
    showErrorMessage,
    showSuccessMessage,
  } = props;

  const [isActionDisabled, setIsActionDisabled] = useState(true);

  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);

  const getEligibility = async () => {
    try {
      const [products, recipes, ingredients] = await Promise.all([
        product.getProductsOfClient(),
        recipeService.getRecipesOfClient(),
        ingredient.getIngredients(false),
      ]);

      const isClientEligible = !products.length && !recipes.length && !ingredients.length;

      setIsActionDisabled(!isClientEligible);
    } catch (err) {
      showErrorMessage(i18next.t('BACKOFFICE.PRODUCTS.DUPLICATE_SETUP_ELIGIBILITY_CHECK_ERROR'));

      setIsActionDisabled(true);
    }
  };

  const getEligibleClients = async () => {
    try {
      const clients = await getClients();

      const eligibleClients = clients.filter(
        ({ useCase, status }) => (useCase === 'client' && status === 'run') || useCase === 'demo',
      );

      setClients(eligibleClients);
    } catch (err) {
      showErrorMessage(i18next.t('BACKOFFICE.PRODUCTS.DUPLICATE_SETUP_GET_CLIENT_LIST_ERROR'));
    }
  };

  useEffect(() => {
    getEligibility();

    getEligibleClients();
  }, []);

  useEffect(() => {
    if (!selectedClient) {
      return;
    }

    handleDuplicationSetupModal();
  }, [selectedClient]);

  const closeModal = () => {
    closeGenericModal();

    setSelectedClient(null);
  };

  const launchDuplication = async () => {
    pageLoading();

    try {
      await duplicateClientSetupFromAnotherOne(clientId, selectedClient.id);

      showSuccessMessage(i18next.t('BACKOFFICE.PRODUCTS.DUPLICATE_SETUP_SUCCESS'));
    } catch (err) {
      showErrorMessage(i18next.t('BACKOFFICE.PRODUCTS.DUPLICATE_SETUP_ERROR'));
    } finally {
      closeModal();

      pageLoaded();

      getEligibility();
    }
  };

  const handleDuplicationSetupModal = () => {
    const isDefaultChannelDifferent =
      !!selectedClient && selectedClient.defaultChannelId !== defaultChannelId;

    const modalProps = {
      width: '560px',
      height: 'auto',
      component: ContentModal,
      type: 'action',
      data: {
        clients,
        selectedClient,
        onSelectionChange: setSelectedClient,
        isDefaultChannelDifferent,
      },
      icon: '/images/inpulse/content-paste-go-black.svg',
      title: i18next.t('BACKOFFICE.PRODUCTS.DUPLICATE_SETUP_TITLE'),
      handleCloseCleanUp: closeModal,
    };

    const actions = [
      GENERIC_MODAL_CANCEL_BUTTON(),
      {
        key: 1,
        isDisabled: !selectedClient || isDefaultChannelDifferent,
        preventClosing: true,
        color: 'inpulse-default',
        label: i18next.t('BACKOFFICE.PRODUCTS.DUPLICATE_SETUP_ACTION'),
        icon: !selectedClient
          ? '/images/inpulse/content-paste-go-black.svg'
          : '/images/inpulse/content-paste-go-white.svg',
        handleClick: launchDuplication,
      },
    ];

    openGenericModal({ ...modalProps, actions });
  };

  return (
    <WhiteCard
      content={i18next.t('BACKOFFICE.PRODUCTS.DUPLICATE_SETUP_CONTENT')}
      contentColor={theme.colors?.greys.darker}
      renderContent={
        <ContentWhiteCard>
          <Button
            buttonCustomStyle={{
              width: 'fit-content',
            }}
            color={'inpulse-default'}
            fontSize={14}
            formatText={false}
            handleClick={handleDuplicationSetupModal}
            icon={'/images/inpulse/content-paste-go-white.svg'}
            iconCustomStyle={{ width: '16px', height: '16px' }}
            isDisabled={isActionDisabled}
            label={i18next.t('BACKOFFICE.PRODUCTS.DUPLICATE_SETUP_ACTION')}
          />
          {isActionDisabled ? (
            <Text color={ENUM_COLORS.DARKER}>
              {i18next.t('BACKOFFICE.PRODUCTS.DUPLICATE_SETUP_NOT_ELIGIBLE')}
            </Text>
          ) : (
            <></>
          )}
        </ContentWhiteCard>
      }
      title={i18next.t('BACKOFFICE.PRODUCTS.DUPLICATE_SETUP_TITLE')}
    />
  );
};

const mapStateToProps = (state) => ({
  modal: state.modalReducer,
  user: state.baseReducer.user,
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  closeGenericModal: (params) => {
    dispatch(closeGenericModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DuplicateSetupWhiteCard);
