import i18next from 'i18next';

import { product as productService } from '@services/product';
import centralService from '@services/central';

/**********************/
/* Fetch Data Methods */
/**********************/

/**
 * Handle the fetch of product categories for the given clientId associated to the user
 *
 * @param {String} clientId                 - The client id on which retrieve the products categories
 * @param {Function} setProductsCategories  - Method used to set the local state of the productsCategories variable
 *
 * @returns {Object} - Object of two arrays corresponding to categories and subCategories of this client
 */
export const fetchProductsCategoriesOfClient = async (
  setProductsCategories,
  setProductsSubCategories,
  isKitchen = false,
) =>
  (isKitchen
    ? centralService.getCentralKitchenProductCategoriesByClientId()
    : productService.getProductsCategories()
  ).then((result) => {
    const formattedCategories = result.categories.map((category) => ({
      ...category,
      name: category.name || i18next.t('GENERAL.NONE_VALUE'),
    }));

    const formattedSubCategories = result.subCategories.map((subCategory, index) => ({
      ...subCategory,
      name: subCategory.name || i18next.t('GENERAL.NONE_VALUE'),
    }));

    setProductsCategories(formattedCategories);

    setProductsSubCategories(formattedSubCategories);

    return { categories: formattedCategories, subCategories: formattedSubCategories };
  });

export default {
  fetchProductsCategoriesOfClient,
};
