import {
  GENERIC_MODAL_SAVE_BUTTON,
  GENERIC_MODAL_CANCEL_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';

import OrderMappingsModal from '../OrderMappingsModal';

const i18next = require('i18next');

export const getOrderMappingsModalConfig = ({
  invoiceControlList,
  associateOrders,
  ordersToBeAdded,
  setOrdersToBeAdded,
  alreadyMappedOrdersKeyById,
}) => ({
  type: 'actionWhite',
  isFullscreen: true,
  title: i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ORDER_MAPPINGS_ASSOCIATION_MODAL_TITLE'),
  icon: '/images/inpulse/add-black-small.svg',
  customPadding: '24px 0px 0px 0px',
  component: OrderMappingsModal,
  data: {
    invoiceControlList,
    ordersToBeAdded,
    setOrdersToBeAdded,
    alreadyMappedOrdersKeyById,
  },
  actions: [
    GENERIC_MODAL_CANCEL_BUTTON(),
    {
      ...GENERIC_MODAL_SAVE_BUTTON(),
      isDisabled: !ordersToBeAdded.length,
      handleClick: associateOrders,
    },
  ],
});

export default { getOrderMappingsModalConfig };
