import { isEmpty } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';

import { getEntityUnit } from '@stocks/utils';

import { Label } from '@commons/utils/styledLibraryComponents';
import DisplayNumber from '@commons/DisplayNumber';

import theme from '@theme';

import {
  getNoInventoryMoreThanThirtyDays,
  getYesterdayStock,
} from '@orders/OrderList/components/OrderForm/utils/formatYesterdayStock';

import YesterdayStockLabel from '../../../YesterdayStockLabel';

import {
  RecoContent,
  RecoTitle,
  RecoContentHeader,
  ItemHeader,
  ItemText,
  TooltipContainer,
  RecoContentBody,
  ContentItem,
  BodyContent,
} from './styledComponents';

const INFO_TOOLTIP = '/images/inpulse/info-dgrey-small.svg';
const ICON_THUNDER_BLACK = '/images/inpulse/lightning-black-small.svg';
const ICON_THUNDER_GREY = '/images/inpulse/lightning-dmgrey-small.svg';

const forecastOnlyColumns = ['recommandation', 'consumption', 'bufferStock'];

const getColumns = (storeTimezone, infoStock) => [
  {
    id: 1,
    propertyKey: 'consumption',
    name: i18next.t('ORDERS.ORDERS.LIST_LABEL_CONSUMPTION'),
    tooltip: i18next.t('ORDERS.ORDERS.FORM_INFO_STOCK_RECOMMENDATION_CONSUMPTION_TOOLTIP'),
    hasLabel: true,
    hasCurrency: false,
    hasUnit: true,
  },
  {
    id: 2,
    propertyKey: 'bufferStock',
    name: i18next.t('GENERAL.BUFFER_STOCK'),
    tooltip: i18next.t('ORDERS.ORDERS.FORM_RECOMMANDATION_BUFFER_STOCK_TOOLTIP'),
    hasLabel: true,
    hasCurrency: false,
    hasUnit: true,
    isHidden: infoStock.bufferStock === null,
  },
  {
    id: 3,
    propertyKey: 'recommandation',
    name: i18next.t('ORDERS.ORDERS.FORM_ROW_INPULSE_RECOMMANDATION'),
    tooltip: i18next.t('ORDERS.ORDERS.FORM_RECOMMANDATION_COLUMN_TOOLTIP_LABEL'),
    hasLabel: true,
    hasCurrency: false,
    hasUnit: true,
  },
  {
    id: 4,
    propertyKey: 'yesterdayStock',
    name: i18next.t('ORDERS.ORDERS.FORM_YESTERDAY_STOCK_COLUMN_TITLE'),
    tooltip: i18next.t('ORDERS.ORDERS.FORM_YESTERDAY_STOCK_COLUMN_TOOLTIP', {
      yesterdayDate: moment.tz(storeTimezone).subtract(1, 'days').format('L'),
    }),
    hasLabel: true,
    hasCurrency: false,
    hasUnit: true,
  },
  {
    id: 5,
    propertyKey: 'order',
    name: i18next.t('GENERAL.ORDER_NOUN'),
    hasLabel: false,
    hasCurrency: false,
    hasUnit: true,
  },
  {
    id: 6,
    propertyKey: 'total',
    name: i18next.t('ORDERS.ORDERS.LIST_LABEL_TOTAL_EX_TAX'),
    hasLabel: false,
    hasCurrency: true,
    hasUnit: false,
  },
];

const OrderStockInfoRecommendation = (props) => {
  const {
    consumption,
    bufferStock,
    entityUnit,
    computedOrderedAmount,
    isLoading,
    entityRecommandation,
    moreThanFiftyDay,
    entityStock,
    entityId,
    hasForecasts,
    currency,
    storeTimezone,
  } = props;

  const [infoStock, setInfoStock] = useState({
    recommandation: 0,
    consumption: 0,
    bufferStock: null,
    total: 0,
    order: 0,
    currency: '',
    unit: '',
  });
  const [recommandationColor, setRecommandationColor] = useState({ color: '', background: '' });

  useEffect(() => {
    setInfoStock({
      ...infoStock,
      order: Math.round(computedOrderedAmount.orderedTotUnit * 100) / 100,
      total: Math.round(computedOrderedAmount.orderedTotTurnover * 100) / 100,
      consumption: consumption || 0,
      recommandation: Math.round(entityRecommandation * 100) / 100 || 0,
      unit: getEntityUnit(entityUnit) || '',
      bufferStock: bufferStock,
    });
  }, [computedOrderedAmount, entityRecommandation, consumption, bufferStock, entityUnit]);

  useEffect(() => {
    if (!entityRecommandation && entityRecommandation !== 0) {
      return;
    }

    if (moreThanFiftyDay) {
      setRecommandationColor({
        color: theme.colors.greys.darkest,
        background: theme.colors.infoLightOrange,
      });
      return;
    }

    if (entityRecommandation === 0) {
      setRecommandationColor({
        color: theme.colors.greys.dark,
        background: theme.colors.lgrey,
      });
      return;
    }

    setRecommandationColor({
      color: theme.colors.greys.darkest,
      background: theme.colors.infoBlue,
    });
  }, []);

  const isRecommandation = (propertyKey) => {
    if (propertyKey === 'recommandation') {
      if (!moreThanFiftyDay && entityRecommandation === 0) {
        return ICON_THUNDER_GREY;
      }
    }

    return ICON_THUNDER_BLACK;
  };

  const renderColumn = (column) => {
    switch (column.propertyKey) {
      case 'consumption':
        return (
          <Label
            background={theme.colors.infoBlue}
            children={`${infoStock[column.propertyKey].toFixed(2)} ${infoStock.unit}`}
            color={theme.colors.greys.darkest}
            icon={isRecommandation(column.propertyKey)}
            position={'left'}
            type="plain"
          />
        );
      case 'bufferStock': {
        return (
          <Label
            background={theme.colors.infoBlue}
            children={`${infoStock[column.propertyKey].toFixed(2)} ${infoStock.unit}`}
            color={theme.colors.greys.darkest}
            icon={ICON_THUNDER_BLACK}
            position={'left'}
            type="plain"
          />
        );
      }
      case 'recommandation':
        return (
          <Label
            background={recommandationColor.background}
            children={`${infoStock[column.propertyKey].toFixed(2)} ${infoStock.unit}`}
            color={recommandationColor.color}
            icon={isRecommandation(column.propertyKey)}
            position={'left'}
            type="plain"
          />
        );
      case 'yesterdayStock':
        return (
          <YesterdayStockLabel
            id={entityId}
            isAssociated={!!entityId}
            moreThanThirtyDay={noInventoryMoreThanThirtyDays}
            theoricalStock={yesterdayStock.realStockUnit == null}
            unit={getEntityUnit(entityUnit)}
            yesterdayStock={
              yesterdayStock.realStockUnit != null
                ? yesterdayStock.realStockUnit
                : yesterdayStock.theoricalStockUnit
            }
          />
        );
      case 'order':
        return (
          <BodyContent>{`${infoStock[column.propertyKey].toFixed(2)} ${
            !!column.hasUnit ? infoStock.unit : ''
          }`}</BodyContent>
        );
      case 'total':
        return (
          <DisplayNumber currency={currency} displayCurrencyCode={true} number={infoStock.total} />
        );
    }
  };

  const yesterdayStock = !isEmpty(getYesterdayStock(entityStock), storeTimezone)
    ? getYesterdayStock(entityStock, storeTimezone)
    : 0;

  const noInventoryMoreThanThirtyDays = getNoInventoryMoreThanThirtyDays(entityStock);

  const columns = getColumns(storeTimezone, infoStock);

  return (
    <div>
      <RecoTitle isHidden={isLoading}>
        {i18next.t('ORDERS.ORDERS.FORM_INFO_STOCK_RECOMMENDATION_TITLE_NO_NAME')}
      </RecoTitle>
      <RecoContent isHidden={isLoading}>
        <RecoContentHeader>
          {columns.map((column, index) => {
            if (
              (forecastOnlyColumns.includes(column.propertyKey) && !hasForecasts) ||
              column.isHidden
            ) {
              return;
            }

            return (
              <ItemHeader key={`${column.propertyKey}-${index}`}>
                <ItemText>{column.name}</ItemText>
                {!!column.tooltip && (
                  <TooltipContainer>
                    <div
                      data-for={`info-tooltip-${column.propertyKey}`}
                      data-html={true}
                      data-tip={
                        `<div style="padding: 16px 24px; color: #FFFFFF; outline: 0; background-color: #1D1D1B; border-radius: 4px; font: normal 500 14px/16px inter; max-width: 300px; text-align: center; display: flex">` +
                        `${column.tooltip}` +
                        `</div>`
                      }
                    >
                      <img alt="tooltip-picture" src={INFO_TOOLTIP} />
                    </div>
                  </TooltipContainer>
                )}
                <ReactTooltip
                  backgroundColor={'transparent'}
                  effect={'solid'}
                  id={`info-tooltip-${column.propertyKey}`}
                  overridePosition={({ top, left }) => ({ top: top + 2, left })}
                  place={'top'}
                />
              </ItemHeader>
            );
          })}
        </RecoContentHeader>

        <RecoContentBody>
          {columns.map((column, index) => {
            if (
              (forecastOnlyColumns.includes(column.propertyKey) && !hasForecasts) ||
              column.isHidden
            ) {
              return;
            }

            return (
              <ContentItem key={`${column.propertyKey}-${index}-${index}`}>
                {renderColumn(column)}
              </ContentItem>
            );
          })}
        </RecoContentBody>
      </RecoContent>
    </div>
  );
};

export default OrderStockInfoRecommendation;
