import { APIcaller, APIcallerv2 } from './APIcaller';
import { CATEGORY_TYPES_OBJECT } from '@commons/constants/categoryTypes';

const getProductsOfClient = () => APIcallerv2.apiGet('/products');

const exportProducts = (productIds) => APIcaller.apiPost('/admin/products/export', { productIds });

const getProductsCategories = () =>
  APIcallerv2.apiGet(`/categories?type=${CATEGORY_TYPES_OBJECT.PRODUCT}`);

const getById = (id) => APIcallerv2.apiGet(`/products/${id}`);

const patchMultipleProducts = (products) =>
  APIcaller.apiPost(`/admin/products/batch`, {
    products,
  });

const getProductMappings = (productId) => APIcallerv2.apiGet(`/products/${productId}/recipes`);

const updateProduct = (product, productCashierProductMappings) =>
  APIcaller.apiPatch(`/admin/products/${product.id}`, {
    product,
    productCashierProductMappings,
  });

const saveProductWithProductionParameters = (
  product,
  storeIds,
  price,
  hasStock,
  launchDate,
  endDate,
  noonMinimum,
  dailyMinimum,
  eveningMinimum,
) => {
  const payload = {
    product: {
      ...product,
      priceWithTaxes: product.price,
      deliveryPriceWithTaxes: product.deliveryPrice,
    },
    storeIds,
    price,
    hasStock,
    launchDate,
    endDate,
  };

  if (dailyMinimum !== '') {
    payload.dailyMinimum = dailyMinimum;
  }

  if (noonMinimum !== '') {
    payload.noonMinimum = noonMinimum;
  }

  if (eveningMinimum !== '') {
    payload.eveningMinimum = eveningMinimum;
  }

  return APIcaller.apiPost('/admin/products/production', payload);
};

const updateStoreProductionParameters = (
  productId,
  storeId,
  price,
  hasStock,
  launchDate,
  endDate,
  noonMinimum,
  dailyMinimum,
  eveningMinimum,
) => {
  const payload = {
    price,
    hasStock,
    launchDate,
    endDate,
  };

  if (dailyMinimum !== '') {
    payload.dailyMinimum = dailyMinimum;
  }

  if (noonMinimum !== '') {
    payload.noonMinimum = noonMinimum;
  }

  if (eveningMinimum !== '') {
    payload.eveningMinimum = eveningMinimum;
  }

  return APIcaller.apiPatch(`/admin/products/${productId}/stores/${storeId}/production`, payload);
};

const getProductionStores = (productId) => APIcallerv2.apiGet(`/products/${productId}/stores`);

const getCashierProducts = (productId) =>
  APIcallerv2.apiGet(`/products/${productId}/cashier-products`);

const deleteStoreProductMapping = (storeId, productId) =>
  APIcaller.apiDelete(`/admin/products/${productId}/stores/${storeId}`);

const deleteCashierProductAssociation = (productId, cashierProductId) =>
  APIcaller.apiDelete(`/admin/products/${productId}/cashier-products/${cashierProductId}`);

const associateCashierProductsToProduct = (productId, cashierProductIds) =>
  APIcaller.apiPost(`/admin/products/${productId}/cashier-products`, { cashierProductIds });

const associateCashierProductToProduct = (cashierProductId, productId) =>
  APIcaller.apiPost(`/admin/products/${productId}/cashier-products/${cashierProductId}`);

const getProductsOfClientWithCashierProductsMappingRelevance = (clientId, cashierProductIds) =>
  APIcaller.apiPost(`/admin/products/with-cashier-product-mapping-relevance`, {
    clientId,
    cashierProductIds,
  });

const deleteProductByIds = (clientId, productIds) =>
  APIcaller.apiDelete(`/admin/products`, { clientId, productIds });

const disableProductByIds = (productIds) =>
  APIcaller.apiPatch(`/admin/products/disable`, { productIds });

const enableProduct = (productId) => APIcaller.apiPatch(`/admin/products/${productId}/enable`);

const patchCategoriesSubCategories = (productIds, itemId, itemType) =>
  APIcaller.apiPatch('/admin/products/categories-sub-categories', {
    productIds,
    itemId,
    itemType,
  });

const getCashierProductsWithProductMatchingScores = async (productId) =>
  APIcallerv2.apiGet(`/products/${productId}/cashier-products/matching-scores`);

export const product = {
  getProductsOfClient,
  getProductsCategories,
  getById,
  patchMultipleProducts,
  getProductMappings,
  updateProduct,
  saveProductWithProductionParameters,
  updateStoreProductionParameters,
  getProductionStores,
  deleteStoreProductMapping,
  deleteCashierProductAssociation,
  getCashierProducts,
  associateCashierProductToProduct,
  associateCashierProductsToProduct,
  deleteProductByIds,
  disableProductByIds,
  enableProduct,
  getProductsOfClientWithCashierProductsMappingRelevance,
  exportProducts,
  patchCategoriesSubCategories,
  getCashierProductsWithProductMatchingScores,
};
