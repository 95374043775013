import { connect } from 'react-redux';
import _ from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

import { showConfirmationMessage } from '@actions/messageconfirmation';

import { Button, Dropdown, Tooltip } from '@commons/utils/styledLibraryComponents';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';

import { supplier as supplierService } from '@services/supplier';
import { user as userService } from '@services/user';
import accountService from '@services/account';
import orderService from '@services/order';

import {
  ActionsContainer,
  CloseButton,
  Container,
  DropdownContainer,
  Title,
  TitleContainer,
} from './styledComponents';

const getSuppliers = async (storesIds, showMessage) => {
  try {
    return await supplierService.getSuppliersOfStores(storesIds);
  } catch (err) {
    showMessage(i18next.t('ADMIN.SUPPLIERS.FETCH_FAILURE'), 'error');
    return [];
  }
};

const getLocations = async (accountId, showMessage) => {
  try {
    return await accountService.getLocationsOfAccount(accountId);
  } catch (err) {
    showMessage(i18next.t('ORDERS.ORDERS.GROUP_LOCATION_LOADING_FAILURE'), 'error');
    return [];
  }
};

const getStores = async (accountId, showMessage) => {
  try {
    const stores = await userService.getStoresOfAccount(accountId);

    return stores;
  } catch (err) {
    showMessage(i18next.t('PRODUCTION.PRODUCTION.TOP_ERROR_MESSAGE_STORES'), 'error');
    return [];
  }
};

const getOrders = async (storesIds, supplierId, params, showMessage) => {
  try {
    return await orderService.getOrdersOfStoresPaginate(
      storesIds,
      supplierId,
      null,
      null,
      null,
      null,
      null,
      params,
    );
  } catch (err) {
    showMessage(i18next.t('ORDERS.ORDERS.LOADING_FAILURE'), 'error');
    return [];
  }
};

const GroupOrderHeader = (props) => {
  const {
    user,
    closeModal,
    showMessage,
    setOrders,
    setIsLoading,
    selectedSupplier,
    setSelectedSupplier,
    setIsEmpty,
    selectedOrders,
    setSelectedOrders,
    setDisplayConfirmationModal,
    selectedStores,
    setSelectedStores,
    supplierProfile,
    orderedUnit,
    selectedOrdersTotalPrice,
    orderTimeLimit,
    storeTimezone,
  } = props;

  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [stores, setStores] = useState([]);
  const [filteredStores, setFilteredStores] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [filteredSuppliers, setFilteredSuppliers] = useState([]);
  const [cantSendOrder, setCantSendOrder] = useState(true);

  useEffect(() => {
    (async () => {
      const userId = _.get(user, 'id');
      const fetchedLocations = await getLocations(userId, showMessage);
      setLocations(fetchedLocations);

      const fetchedStores = await getStores(userId, showMessage);
      setStores(fetchedStores);
    })();
  }, []);

  useEffect(() => {
    if (locations.length) {
      setSelectedLocation(locations[0]);
    }
  }, [locations]);

  useEffect(() => {
    if (!selectedLocation) {
      return;
    }

    const storesFiltered = stores.filter((store) => store.locationId === selectedLocation.id);
    setFilteredStores(storesFiltered);
    setSelectedStores(storesFiltered);
  }, [selectedLocation, stores]);

  useEffect(() => {
    (async () => {
      const storesIds = selectedStores.map((store) => store.id);

      if (!storesIds.length) {
        return;
      }

      const fetchSuppliers = await getSuppliers(storesIds, showMessage);

      setSuppliers(_.sortBy(fetchSuppliers, 'name'));
    })();
  }, [selectedStores]);

  useEffect(() => {
    if (!selectedStores.length || !suppliers.length) {
      setFilteredSuppliers([]);
      return;
    }

    const selectedStoreIds = selectedStores.map((store) => store.id);
    const matchedSuppliers = suppliers.reduce((acc, supplier) => {
      if (!supplier.storesMapped || !supplier.storesMapped.length) {
        return acc;
      }

      const storesMappedToSupplier = [];
      supplier.storesMapped.forEach((storesMapped) => {
        if (selectedStoreIds.includes(storesMapped.storeId)) {
          storesMappedToSupplier.push(storesMapped.storeId);
        }
      });

      if (storesMappedToSupplier.length === selectedStoreIds.length) {
        acc.push(supplier);
      }

      return acc;
    }, []);

    setFilteredSuppliers(_.sortBy(matchedSuppliers, 'name'));
  }, [selectedStores, suppliers]);

  useEffect(() => {
    setSelectedSupplier(null);
  }, [selectedLocation, selectedStores]);

  useEffect(() => {
    if (!(!!selectedLocation && !!selectedStores.length && !!selectedSupplier)) {
      return;
    }

    (async () => {
      setIsLoading(true);

      const storesIds = selectedStores.map((store) => store.id);
      const supplierId = [selectedSupplier.id];

      const currentDate = moment
        .tz(storeTimezone)
        .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);
      const params = `&startOrderDate={"gt":["${currentDate}"]}`;

      const orders = await getOrders(storesIds, supplierId, params, showMessage);

      setOrders(orders);

      orders.totalCount > 0 ? setIsEmpty(false) : setIsEmpty(true);

      setIsLoading(false);
    })();
  }, [selectedSupplier]);

  useEffect(() => {
    if (selectedOrders.length < 2) {
      if (!cantSendOrder) {
        setCantSendOrder(true);
      }
      return;
    }

    setCantSendOrder(false);
  }, [selectedOrders]);

  const prepareToOpenModal = () => {
    if (!supplierProfile) {
      setDisplayConfirmationModal(true);

      return;
    }

    if (
      selectedOrdersTotalPrice < supplierProfile.orderMinAmount ||
      orderedUnit < supplierProfile.orderMinUnit
    ) {
      props.showMessage(
        i18next.t('ORDERS.ORDERS.GROUP_SEND_FRANCO_LOWER_THAN_ORDERED_ERROR', {
          orderMinAmount: supplierProfile.orderMinAmount,
        }),
        'error',
      );

      return;
    }

    if (
      orderTimeLimit !== null
        ? !moment.tz(storeTimezone).isBefore(moment.tz(orderTimeLimit, storeTimezone))
        : false
    ) {
      props.showMessage(
        i18next.t('ORDERS.ORDERS.GROUP_SEND_TIME_AFTER_SUPPLIER_PROFILE_ERROR', {
          orderMinAmount: supplierProfile.orderMinAmount,
        }),
        'error',
      );

      return;
    }

    setDisplayConfirmationModal(true);
  };

  return (
    <Container>
      <TitleContainer>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Title>{i18next.t('ORDERS.ORDERS.GROUP_GENERAL')}</Title>
          <Tooltip text={i18next.t('ORDERS.ORDERS.GROUP_TOOLTIP')} />
        </div>
        <CloseButton src={'/images/inpulse/close-black-small.svg'} onClick={() => closeModal()} />
      </TitleContainer>
      <ActionsContainer>
        <DropdownContainer>
          <Dropdown
            iconSrc={'/images/inpulse/location-black-small.svg'}
            isRequired={true}
            items={locations}
            selectedItem={selectedLocation}
            onSelectionChange={(selectedItem) => setSelectedLocation(selectedItem)}
          />
          <Dropdown
            iconSrc={
              !selectedStores.length
                ? '/images/inpulse/pin-dmgrey-small.svg'
                : '/images/inpulse/pin-black-small.svg'
            }
            isUniqueSelection={false}
            items={filteredStores}
            selectedItems={selectedStores}
            onSelectionChange={(selectedItems) => setSelectedStores(selectedItems)}
          />
          <Dropdown
            iconSrc={'/images/inpulse/supplier-black-small.svg'}
            items={filteredSuppliers}
            placeholder={i18next.t('GENERAL.SUPPLIER')}
            selectedItem={selectedSupplier}
            onSelectionChange={(selectedItem) => {
              setSelectedSupplier(selectedItem);
              setSelectedOrders([]);
            }}
          />
        </DropdownContainer>
        <Button
          color={'inpulse-default'}
          handleClick={prepareToOpenModal}
          icon={'/images/inpulse/check-white-small.svg'}
          isDisabled={cantSendOrder}
          label={'Envoyer'}
        />
      </ActionsContainer>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
});

export default connect(null, mapDispatchToProps)(GroupOrderHeader);
