import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { useForm } from 'react-hook-form';
import i18next from 'i18next';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

// ACTIONS
import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

// COMMONS
import { Button } from '@commons/utils/styledLibraryComponents';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { downloadFileFromUrl, getFileNameFromUrl } from '@commons/utils/fileUtils';
import { ENUM_MODULE_NAME } from '@commons/utils/features';
import { formatNewItemsOfDropdownSelection } from '@commons/utils/format';
import { getAuditButtonLabel } from '@commons/HistoryDrawer/utils';
import Drawer from '@commons/Drawer';
import Footer from '@commons/Footer/Footer';
import HistoryDrawerContent from '@commons/HistoryDrawer/HistoryDrawerContent';
import NavigationBar from '@commons/NavigationBar';

// SELECTORS
import { getClientInfo } from '@selectors/client';

// SERVICES
import { supplierProduct as supplierProductService } from '@services/supplierProduct';
import { supplier as supplierService } from '@services/supplier';
import { upload as uploadService } from '@services/upload';
import auditService from '@services/auditLogs';

// UTILS
import { handleNewDataCreation } from '../../utils/creations';
import { isForbiddenAPIError } from '@commons/utils/errors';
import mixPanelUtils, { ENUM_EVENTS } from '@commons/utils/mixpanel';

// COMPONENTS
import SupplierProductDetails from './components/SupplierProductDetails';

// STYLE
import { ButtonsContainer, ContentContainer, PageContainer } from './styledComponents';

const MAX_ATTACHMENT_SIZE = 5000000;

const SupplierProductInformations = (props) => {
  const {
    match: { path, params },
    user: { id: userId, email: userEmail },
    client: { storeName, clientId },
    pageLoaded,
    pageLoading,
    history,
    showErrorMessage,
    showSuccessMessage,
  } = props;

  const [supplierProduct, setSupplierProduct] = useState({});
  const [updatedSupplierProduct, setUpdatedSupplierProduct] = useState({});
  const [isFooterDisplay, setIsFooterDisplay] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [form, setForm] = useState(useForm());
  const [isSaveAlreadyTriggered, setIsSaveAlreadyTriggered] = useState(false);
  const [createdCategories, setCreatedCategories] = useState([]);
  const [createdSubCategories, setCreatedSubCategories] = useState([]);
  const [createdInventoryListTemplates, setCreatedInventoryListTemplates] = useState([]);

  // AuditLogs states
  const [isLoading, setIsLoading] = useState(true);
  const [displaySupplierProductHistory, setDisplaySupplierProductHistory] = useState(false);
  const [auditLogs, setAuditLogs] = useState([]);
  const [labelUpdatedAt, setLabelUpdatedAt] = useState('');

  useEffect(() => {
    if (!params.id) {
      return;
    }

    pageLoading();

    (async () => {
      try {
        const supplierProductFetched = await supplierProductService.getSingleSupplierProduct(
          params.id,
          true,
        );

        const currency = supplierProductFetched.currency || {};

        const attachmentLinkName = getFileNameFromUrl(supplierProductFetched.attachmentLink, {
          withExtension: true,
        });

        setSupplierProduct({
          ...supplierProductFetched,
          currency: {
            ...currency,
            name: `${currency.name} (${currency.alphabeticCode})`,
          },
          attachmentLinkName,
        });

        fetchSupplierProductHistory(supplierProductFetched.id);
      } catch (error) {
        pageLoaded();
        showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.SINGLE_FETCH_ERROR'));

        if (isForbiddenAPIError(error)) {
          history.goBack();
        }
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const fieldsValidation = await form.trigger();
      setIsSaveDisabled(
        (!fieldsValidation || isEmpty(updatedSupplierProduct.packagings)) && isSaveAlreadyTriggered,
      );
    })();
  }, [updatedSupplierProduct]);

  useEffect(() => {
    const auditButtonLabel = getAuditButtonLabel(auditLogs);

    setLabelUpdatedAt(auditButtonLabel);
  }, [auditLogs]);

  const validateForm = async () => {
    const fieldsValidation = await form.trigger();

    setIsSaveAlreadyTriggered(true);

    if (!fieldsValidation || isEmpty(updatedSupplierProduct.packagings)) {
      setIsSaveDisabled(true);

      return;
    }

    form.handleSubmit(saveSupplierProduct)();
  };

  const redirectToSupplierProductList = () => history.goBack();

  const uploadPicture = async () => {
    const params = {
      clientId,
      // Add timestamp to avoid delay between two changes
      supplierProductId: `${supplierProduct.id}_${moment().format(
        DATE_DISPLAY_FORMATS.UNDERSCORED_MONTH_DAY_HOUR_MINUTE_SECOND,
      )}`,
    };

    return uploadService.uploadFile(updatedSupplierProduct.selectedPictureFile, params);
  };

  const uploadAttachment = async (file) => {
    if (file.size > MAX_ATTACHMENT_SIZE) {
      showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.MAX_ATTACHMENT_SIZE_ERROR'));
      return;
    }

    pageLoading();

    try {
      const params = {
        clientId,
        // Add timestamp to avoid delay between two changes
        supplierProductId: `${supplierProduct.id}_${moment().format(
          DATE_DISPLAY_FORMATS.UNDERSCORED_MONTH_DAY_HOUR_MINUTE_SECOND,
        )}`,
        keepOriginalFileName: true,
      };

      const { data } = await uploadService.uploadFile(file, params);

      const attachmentLink = get(data, 'fileUri', null);

      await supplierProductService.patchSupplierProductById(supplierProduct.id, {
        attachmentLink,
        supplierId: supplierProduct.supplierId, // Required to check for catalog rights
      });

      setSupplierProduct({
        ...supplierProduct,
        attachmentLink,
        attachmentLinkName: file.name,
      });

      showSuccessMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.UPLOAD_FILE_SUCCESS'));
    } catch {
      showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.UPLOAD_FILE_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const deleteAttachment = async () => {
    pageLoading();

    try {
      await supplierProductService.patchSupplierProductById(supplierProduct.id, {
        attachmentLink: null,
        supplierId: supplierProduct.supplierId, // Required to check for catalog rights
      });

      setSupplierProduct({
        ...supplierProduct,
        attachmentLink: null,
        attachmentLinkName: null,
      });

      showSuccessMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.DELETE_FILE_SUCCESS'));
    } catch {
      showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.DELETE_FILE_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const downloadAttachment = async () => {
    try {
      const attachmentLink = supplierProduct.attachmentLink;

      const fileName = getFileNameFromUrl(attachmentLink);

      await downloadFileFromUrl(attachmentLink, fileName);
    } catch {
      showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.FILE_DOWNLOAD_ERROR'));
    }
  };

  const saveSupplierProduct = async () => {
    pageLoading();

    try {
      const { inputs: inputPrices } = await supplierService.getSupplierPriceInputs(
        updatedSupplierProduct.supplierId,
      );

      const supplierProductPayload = {
        supplierId: updatedSupplierProduct.supplier.id,
        conversions: updatedSupplierProduct.conversions,
        name: updatedSupplierProduct.name,
        sku: updatedSupplierProduct.sku === '' ? null : updatedSupplierProduct.sku,
        category: updatedSupplierProduct.category.value,
        categoryId: updatedSupplierProduct.category.id,
        subCategory: updatedSupplierProduct.subCategory.value,
        subCategoryId: updatedSupplierProduct.subCategory.id,
        entityId: isEmpty(updatedSupplierProduct.entity) ? null : updatedSupplierProduct.entity.id,
        loss: updatedSupplierProduct.loss === '' ? null : updatedSupplierProduct.loss,
        packagings: updatedSupplierProduct.packagings,
        vatRate: updatedSupplierProduct.vatRate === '' ? null : updatedSupplierProduct.vatRate,
        packagingUnit: updatedSupplierProduct.packagingUnit,
        packagingWeight:
          updatedSupplierProduct.packagingWeight === ''
            ? null
            : updatedSupplierProduct.packagingWeight,
        priceEditableAtReception: updatedSupplierProduct.priceEditableAtReception,
        price: updatedSupplierProduct.price === '' ? null : updatedSupplierProduct.price,
        manufacturerName: updatedSupplierProduct.manufacturerName,
        inventoryListTemplates: formatNewItemsOfDropdownSelection(
          updatedSupplierProduct.inventoryListTemplates,
        ),
        clientId,
        ean: updatedSupplierProduct.ean,
      };

      if (
        updatedSupplierProduct.scheduledPriceValue >= 0 &&
        updatedSupplierProduct.scheduledPriceValue !== null &&
        !!updatedSupplierProduct.scheduledPriceDate
      ) {
        supplierProductPayload.scheduledPrice = {
          ...updatedSupplierProduct.scheduledPrice,
          price: updatedSupplierProduct.scheduledPriceValue,
          startDate: updatedSupplierProduct.scheduledPriceDate,
        };
      }

      if (!!updatedSupplierProduct.selectedPictureFile) {
        const url = await uploadPicture();

        supplierProductPayload.img = get(url, 'data.fileUri', supplierProduct.img);
      } else {
        supplierProductPayload.img = updatedSupplierProduct.img;
      }

      const customPricesPayload = inputPrices.reduce((res, { id }) => {
        if (!!updatedSupplierProduct[id]) {
          res.push({
            price: updatedSupplierProduct[id] === '' ? null : updatedSupplierProduct[id],
            inputId: id,
          });
        }

        return res;
      }, []);

      await supplierProductService.patchSupplierProductById(
        supplierProduct.id,
        supplierProductPayload,
        [],
        customPricesPayload,
      );

      redirectToSupplierProductList();
    } catch {
      showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.UPDATE_SUPPLIER_PRODUCT_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const fetchSupplierProductHistory = async (supplierProductId) => {
    setIsLoading(true);
    try {
      const supplierProductAuditLogs = await auditService.getTargetIdAuditLogs(supplierProductId);
      setAuditLogs(supplierProductAuditLogs);
    } catch {
      showErrorMessage(i18next.t('ORDERS.AUDIT_EVENTS.ERROR_FETCHING'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenDrawer = () => {
    if (!supplierProduct || !supplierProduct.id) {
      return;
    }

    const shouldDisplayDrawer = !displaySupplierProductHistory;

    setDisplaySupplierProductHistory(shouldDisplayDrawer);

    if (shouldDisplayDrawer) {
      mixPanelUtils.sendMetric(ENUM_EVENTS.OPEN_AUDIT_LOG_SIDE_PANEL, {
        userId,
        userEmail,
        path,
        clientId,
        supplierProductId: supplierProduct.id,
      });
    }
  };

  return (
    <>
      <NavigationBar
        module={ENUM_MODULE_NAME.SUPPLIER_PRODUCT_DETAIL}
        path={path}
        rightButtonLabel={labelUpdatedAt}
        rightButtonLoading={isLoading}
        rightButtonOnClick={handleOpenDrawer}
        storeName={storeName}
        supplierProduct={supplierProduct}
        bigTopBar
        enableActionBottomOrder
      />
      <PageContainer>
        <ContentContainer isFooterDisplay={isFooterDisplay}>
          <SupplierProductDetails
            createdCategories={createdCategories}
            createdInventoryListTemplates={createdInventoryListTemplates}
            createdSubCategories={createdSubCategories}
            deleteAttachment={deleteAttachment}
            downloadAttachment={downloadAttachment}
            handleNewDataCreation={(item, type) =>
              handleNewDataCreation(item, type, {
                createdCategories,
                setCreatedCategories,
                createdSubCategories,
                setCreatedSubCategories,
                createdInventoryListTemplates,
                setCreatedInventoryListTemplates,
              })
            }
            isSaveAlreadyTriggered={isSaveAlreadyTriggered}
            setForm={setForm}
            setIsFooterDisplay={setIsFooterDisplay}
            setUpdatedSupplierProduct={setUpdatedSupplierProduct}
            supplierProduct={supplierProduct}
            uploadAttachment={uploadAttachment}
          />
        </ContentContainer>
        {isFooterDisplay && (
          <Footer>
            <ButtonsContainer>
              <Button
                color={'inpulse-outline'}
                handleClick={() => {
                  redirectToSupplierProductList();
                }}
                icon={'/images/inpulse/close-black-small.svg'}
                label={i18next.t('GENERAL.CANCEL')}
              />
              <Button
                color={'inpulse-default'}
                handleClick={() => validateForm()}
                icon={'/images/inpulse/save-white-small.svg'}
                isDisabled={isSaveDisabled}
                label={i18next.t('GENERAL.SAVE')}
              />
            </ButtonsContainer>
          </Footer>
        )}
      </PageContainer>

      <Drawer
        isOpened={displaySupplierProductHistory}
        overlayOpacity={0}
        onClick={() => {
          setDisplaySupplierProductHistory(false);
        }}
      >
        <HistoryDrawerContent
          auditLogs={auditLogs}
          subtitle={supplierProduct.name}
          onClose={() => setDisplaySupplierProductHistory(false)}
        />
      </Drawer>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SupplierProductInformations);
