import _ from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { Dropdown, INPUT_WIDTH } from '@commons/utils/styledLibraryComponents';
import { formatNumber } from '@commons/DisplayNumber';
import { formatTextNumberInputValue, sortPackagings } from '@commons/utils/format';
import { getTheme } from '@commons/utils/theme';
import InputContentModal from '@commons/Modals/InputContentModal';

import { getUnitValue, UNITS_DROPDOWN_ITEMS } from '@admin/utils/DropdownItems';

import { PACKAGING_MODAL_TYPE } from '../ListRow';

import { Container, InputContainer } from './styledComponents';

export const handleUpdateModalPackaging = ({
  packagings,
  currentPackaging,
  setCurrentPackaging,
}) => {
  const parentPackaging = _.find(
    packagings,
    (packaging) => packaging.id === currentPackaging.parentSupplierProductPackagingId,
  );

  if (!_.isEmpty(parentPackaging)) {
    setCurrentPackaging({ ...currentPackaging, unit: parentPackaging.name });
    return;
  }

  setCurrentPackaging(currentPackaging);
};

export const formatToPositiveIntegerValue = (value) => {
  if (!value || isNaN(value) || isNaN(parseInt(value))) {
    return null;
  }

  if (value < 0) {
    return 0;
  }

  return +formatNumber(value, 0);
};

export const handleModalInputChange = ({
  fieldName,
  value,
  packagingId,
  currentPackaging,
  supplierProduct,
  setCurrentPackaging,
}) => {
  const updatedCurrentPackaging = { ...currentPackaging, [fieldName]: value };

  if (fieldName === 'unit' && packagingId) {
    const concernedParentPackaging =
      supplierProduct.packagings.find((packaging) => packaging.id === packagingId) || {};

    updatedCurrentPackaging.parentSupplierProductPackagingId = concernedParentPackaging.id;
  }

  setCurrentPackaging({ ...updatedCurrentPackaging, [fieldName]: value });
};

export const handlePackagingSaving = ({
  supplierProduct,
  handleSupplierProductChange,
  setUnitDisabled,
  currentPackaging,
  packagingModalType,
}) => {
  setUnitDisabled(false);

  let packagingToUpdate = _.cloneDeep(currentPackaging);

  if (currentPackaging.parentSupplierProductPackagingId) {
    // Here we know the packaging is not the master packaging
    packagingToUpdate = {
      ...currentPackaging,
      unit: null,
      quantity: parseFloat(currentPackaging.quantity),
    };
  }

  let existingPackagings = supplierProduct.packagings || [];

  if (packagingModalType === PACKAGING_MODAL_TYPE.UPDATE) {
    existingPackagings = _.remove(
      supplierProduct.packagings || [],
      (SPPs) => SPPs.id !== currentPackaging.id,
    );
  }

  const updatedPackagings = sortPackagings([...existingPackagings, packagingToUpdate]);

  handleSupplierProductChange(updatedPackagings, 'packagings');
};

export const getCurrentPackagingUnits = ({
  supplierProductPackagings,
  packagingModalType,
  currentPackaging,
}) => {
  if (_.isEmpty(supplierProductPackagings)) {
    // It is the first packaging, need to get recipe unit or kg, L, unit
    return UNITS_DROPDOWN_ITEMS();
  }

  let formattedSPPs = _.cloneDeep(supplierProductPackagings);

  // This condition remove all the childs of the currentPackaging from the array so we dont encounter strange cases where we chose a currentPackaging's child as a parent
  if (packagingModalType === PACKAGING_MODAL_TYPE.UPDATE) {
    const sortedSPPs = sortPackagings(supplierProductPackagings);

    const SPPsWithoutCurrentSPPChilds = _.dropRightWhile(
      sortedSPPs,
      (sortedSPP) => sortedSPP.id !== currentPackaging.id,
    );

    formattedSPPs = _.remove(
      SPPsWithoutCurrentSPPChilds,
      (SPPs) => SPPs.id !== currentPackaging.id,
    );
  }

  // Get parents packagings units
  return _.reduce(
    formattedSPPs,
    (result, packaging) => {
      if (!!packaging.name) {
        result.push({
          id: packaging.name,
          value: getUnitValue(packaging.name),
          packagingId: packaging.id,
        });
      }

      return result;
    },
    [],
  );
};

const getSelectedItem = ({ modalType, currentPackaging, availableUnits, isMasterSPP }) => {
  if (modalType === PACKAGING_MODAL_TYPE.CREATE) {
    return {
      id: currentPackaging.unit,
      value: currentPackaging.unit ? getUnitValue(currentPackaging.unit) : '',
    };
  }

  if (!!isMasterSPP) {
    return { id: currentPackaging.unit, value: getUnitValue(currentPackaging.unit) };
  }

  const unit = _.find(
    availableUnits,
    (availableUnit) =>
      availableUnit.packagingId === currentPackaging.parentSupplierProductPackagingId,
  );

  if (_.isEmpty(unit)) {
    return { id: currentPackaging.unit, value: currentPackaging.value || '' };
  }

  return { id: currentPackaging.unit, value: unit.value };
};

const InputPackagingContentModal = ({
  availableUnits,
  currentPackaging,
  entity,
  unitDisabled,
  onChange,
  isDuplicated,
  modalType,
}) => {
  const isMasterSPP =
    !currentPackaging.parentSupplierProductPackagingId && !currentPackaging.masterSupplierProductId;

  return (
    <Container>
      <InputContentModal
        label={`* ${i18next.t(
          'ADMIN.SUPPLIER_PRODUCTS.MODAL_CONDITIONINGS_CONDITIONING_NAME_FULL',
        )}`}
        value={currentPackaging.name}
        autoFocus
        onChange={(value) => onChange('name', value, null)}
      />
      <InputContainer>
        <Dropdown
          customListStyle={{ position: 'fixed' }}
          isDisabled={
            modalType === PACKAGING_MODAL_TYPE.CREATE || isDuplicated
              ? unitDisabled
              : !!isMasterSPP && !_.isEmpty(entity)
          }
          isRequired={true}
          items={isMasterSPP ? UNITS_DROPDOWN_ITEMS() : availableUnits}
          label={`${i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_CONDITIONINGS_CONDITIONING_UNIT')} :`}
          labelStyle={{ font: getTheme().fonts.textSmall }}
          placeholder=""
          selectedItem={getSelectedItem({
            modalType,
            currentPackaging,
            availableUnits,
            isMasterSPP,
          })}
          sortBy={sortPackagings}
          width={INPUT_WIDTH.LARGE}
          onSelectionChange={(selectedItem) => {
            onChange('unit', selectedItem.id, selectedItem.packagingId);
          }}
        />
      </InputContainer>
      <InputContainer>
        <InputContentModal
          autoFocus={false}
          label={`* ${i18next.t(
            'ADMIN.SUPPLIER_PRODUCTS.MODAL_CONDITIONINGS_CONDITIONING_QUANTITY',
          )}`}
          type="text"
          value={currentPackaging.quantity || ''}
          onChange={(value) => onChange('quantity', formatTextNumberInputValue(value), null)}
        />
      </InputContainer>
      <InputContentModal
        autoFocus={false}
        label={i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_CONDITIONINGS_CONDITIONING_MAX_ORDER_QTY')}
        readOnly={!currentPackaging.isUsedInOrder}
        tooltipText={i18next.t(
          'ADMIN.SUPPLIER_PRODUCTS.MODAL_CONDITIONINGS_CONDITIONING_MAX_ORDER_QTY_TOOLTIP',
        )}
        type="number"
        value={currentPackaging.maxOrderQuantity}
        onChange={(value) =>
          onChange('maxOrderQuantity', formatToPositiveIntegerValue(value), null)
        }
      />
    </Container>
  );
};

export default InputPackagingContentModal;
