import axios from '../core/http';

import { APIcaller, APIcallerv2 } from './APIcaller';

import { config } from '../config';

const baseApiUrl = `${config.baseApiUrl}/v1`;

const getIngredients = (withLinkedData = true) =>
  APIcallerv2.apiGet('/ingredients', {
    withLinkedData,
  });

const getIngredientById = (id) => APIcallerv2.apiGet(`/ingredients/${id}`);

const getIngredientByIds = (ingredientIds) =>
  axios
    .post(baseApiUrl + '/admin/ingredients/get-by-ids', {
      ingredientIds,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const getIngredientParents = (id) => APIcallerv2.apiGet(`/ingredients/${id}/parents-mappings`);

const createIngredient = (
  clientId,
  { name, unit, categoryId, code, cost, supplierProducts, allergenIds, isStrategic },
) =>
  axios
    .post(baseApiUrl + '/admin/ingredients', {
      clientId,
      name,
      unit,
      categoryId,
      code,
      cost,
      supplierProducts,
      allergenIds,
      isStrategic,
      categoryId,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const updateIngredient = (
  clientId,
  id,
  name,
  unit,
  categoryId,
  code,
  cost,
  supplierProducts,
  allergenIds,
  isStrategic,
) =>
  axios
    .patch(
      baseApiUrl + '/admin/ingredients',
      {
        clientId,
        id,
        name,
        unit,
        categoryId,
        code,
        cost,
        supplierProducts,
        allergenIds,
        isStrategic,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const deleteIngredients = (ingredientIds) =>
  axios
    .post(baseApiUrl + '/admin/ingredients/delete', {
      ingredientIds: JSON.stringify(ingredientIds),
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const checkMetricsBeforeDelete = (ingredientIds) =>
  axios
    .post(baseApiUrl + '/admin/ingredients/check-existing-data-metrics', {
      ingredientIds,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });

const getIngredientCostFromSupplierProductIds = (ingredientUnit, supplierProductIds) =>
  APIcallerv2.apiPost('/ingredients/cost', { supplierProductIds, unit: ingredientUnit });

const deleteIngredientByIdsWithLinkedData = (ingredientIds, clientId) =>
  axios
    .delete(`${baseApiUrl}/admin/clients/${clientId}/ingredients`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
      data: {
        ingredientIds,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

export const disableIngredients = (ingredientIds) =>
  axios.patch(
    baseApiUrl + '/admin/ingredients/disable',
    {
      ingredientIds,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    },
  );

export const enableIngredientsByIds = async (ingredientIds) =>
  axios.patch(
    baseApiUrl + '/admin/ingredients/enable',
    {
      ingredientIds,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    },
  );

export const patchCategories = (ingredientIds, categoryId) =>
  APIcaller.apiPatch('/admin/ingredients/categories', {
    ingredientIds,
    categoryId,
  });

export const getIngredientBufferStocks = (ingredientId) =>
  APIcallerv2.apiGet(`/ingredients/${ingredientId}/buffer-stocks`);

export const upsertBufferStocks = (storeIds, ingredientId, nbDays) =>
  APIcaller.apiPost(`/admin/ingredients/${ingredientId}/buffer-stocks`, {
    storeIds,
    nbDays,
  });

export const ingredient = {
  getIngredients,
  getIngredientById,
  getIngredientByIds,
  getIngredientParents,
  createIngredient,
  updateIngredient,
  deleteIngredients,
  disableIngredients,
  getIngredientCostFromSupplierProductIds,
  checkMetricsBeforeDelete,
  deleteIngredientByIdsWithLinkedData,
  enableIngredientsByIds,
  patchCategories,
  getIngredientBufferStocks,
  upsertBufferStocks,
};
