import { connect } from 'react-redux';
import _ from 'lodash';
import i18next from 'i18next';
import React, { useState } from 'react';

import { closeGenericModal, openGenericModal, refreshGenericModal } from '@actions/modal';
import { loading, loadingSuccess } from '@actions/loading';
import { showConfirmationMessage } from '@actions/messageconfirmation';

import { Button } from '@commons/utils/styledLibraryComponents';
import { clientStatusCheck } from '@commons/utils/clientStatusCheck';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';
import normalizeStringValue from '@commons/utils/normalizeStringValue';
import WhiteCard from '@commons/WhiteCard';

import { getClientInfo } from '@selectors/client';

import { backoffice as dataModelService } from '@services/backoffice/dataModel';

import {
  downloadFile,
  formatXlsFileToJson,
  mapColumnIdsAndNormalizedColumnIds,
  normalizeJsonObject,
} from '@backoffice/utils';
import ImportExcelFileModal from '@backoffice/components/ImportExcelFileModal';

import { Container } from './styledComponents';
import supplierProductXlsConstants from '@commons/Excels/constants/supplierProduct.xlsx.constant';

const defaultModalConfig = {
  width: '542px',
  height: 'auto',
  component: ImportExcelFileModal,
};

const BackOfficeDataModel = (props) => {
  const {
    match,
    client: { clientName, clientId, clientStatus },
  } = props;

  const path = _.get(match, 'path');

  const [selectedFile, setSelectedFile] = useState(null);

  const [isDownloadingExcel, setIsDownloadingExcel] = useState(false);

  const formatJson = (dataModel) => {
    const formattedDataModel = normalizeJsonObject(dataModel);

    return Object.keys(formattedDataModel).reduce((result, sheetName) => {
      if (sheetName === 'entities') {
        result[sheetName] = formattedDataModel[sheetName].map((current) => {
          const associatedAllergens = formattedDataModel['Allergènes'].filter(({ allergenes }) => {
            // Replaces the accents ( céleri -> celeri )
            const normalizedAllergens = normalizeStringValue(allergenes);

            return !!current[normalizedAllergens] && current[normalizedAllergens] === 'true';
          });

          const allergensIds = associatedAllergens.map(({ id }) => id);

          return {
            name: current['nom'],
            unit: current['unite'],
            category: current['categorie'],
            isStrategic: current['ingredient strategique'] === 'OUI',
            allergensIds,
          };
        });
      }

      if (sheetName === 'suppliers') {
        result[sheetName] = formattedDataModel[sheetName].map((current) => ({
          name: current['nom du fournisseur'],
          documentsLanguage: current['langue documents'],
        }));
      }

      if (sheetName === 'supplierProducts') {
        result[sheetName] = formattedDataModel[sheetName].map((current) => ({
          ...mapColumnIdsAndNormalizedColumnIds(current, supplierProductXlsConstants.COLUMN_IDS),
          loss: current['perte (%)'] || 0,
          vatRate: current['tva %'] || 0,
        }));
      }

      return result;
    }, {});
  };

  const handleFileValidation = async (file) => {
    props.pageLoading();

    try {
      const dataModel = await formatXlsFileToJson(file);

      const formattedJsonDataModel = formatJson(dataModel);

      const { status, data } = await dataModelService.validateExcelDataModel(
        formattedJsonDataModel,
      );

      if (status === 'success') {
        props.refreshGenericModal(getModalModalConfig('validated-file', file));

        return;
      }

      props.refreshGenericModal(getModalModalConfig('error-file', file, data));
    } catch (error) {
      props.closeGenericModal();
      props.showMessage(i18next.t('BACKOFFICE.DATA_MODEL.EXCEL_VALIDATION_ERROR'), 'error');
    } finally {
      props.pageLoaded();
    }
  };

  const handleFileImport = async (file) => {
    props.pageLoading();

    const updateUserOnImportStatusTimeout = setTimeout(
      () => props.showMessage(i18next.t('BACKOFFICE.DATA_MODEL.LONG_IMPORT_TOASTER_INFO'), 'info'),
      120000,
    );

    try {
      const dataModel = await formatXlsFileToJson(file);

      const formattedJsonDataModel = formatJson(dataModel);

      await dataModelService.importExcelDataModel(formattedJsonDataModel, clientId);

      props.showMessage(i18next.t('BACKOFFICE.DATA_MODEL.EXCEL_IMPORT_SUCCESS'));
    } catch (error) {
      props.showMessage(i18next.t('BACKOFFICE.DATA_MODEL.EXCEL_IMPORT_ERROR'), 'error');
    } finally {
      clearTimeout(updateUserOnImportStatusTimeout);

      props.pageLoaded();

      props.closeGenericModal();
    }
  };

  const onFileChange = (file) => {
    setSelectedFile(file);

    if (!file) {
      props.refreshGenericModal(getModalModalConfig('select-file'));

      return;
    }

    props.refreshGenericModal(getModalModalConfig('validate-file', file));
  };
  const retrieveBlobDataModel = async () => {
    setIsDownloadingExcel(true);

    try {
      const response = await dataModelService.getExcelDataModel(clientId);

      downloadFile(response, clientName);
    } catch (error) {
      props.showMessage(i18next.t('BACKOFFICE.DATA_MODEL.EXCEL_RETRIEVING_ERROR'), 'error');
    } finally {
      setIsDownloadingExcel(false);
    }
  };

  const getModalModalConfig = (type, updatedFile = null, errorFile = null) => {
    if (type === 'select-file') {
      return {
        ...defaultModalConfig,
        type: 'action',
        title: i18next.t('BACKOFFICE.DATA_MODEL.MODAL_IMPORT_SELECT_FILE_TITLE'),
        icon: '/images/inpulse/file-upload-black-small.svg',
        data: {
          subTitle: i18next.t('BACKOFFICE.DATA_MODEL.MODAL_IMPORT_SELECT_FILE_CONTENT'),
          selectedFile,
          setSelectedFile: onFileChange,
        },
        actions: [
          {
            key: 0,
            color: 'inpulse-outline',
            label: i18next.t('GENERAL.CANCEL'),
            icon: '/images/inpulse/close-black-small.svg',
            handleClick: setSelectedFile(null),
          },
          {
            key: 1,
            isDisabled: true,
            preventClosing: true,
            color: 'inpulse-default',
            label: i18next.t('GENERAL.IMPORT'),
            icon: '/images/inpulse/file-upload-white-small.svg',
            handleClick: () => false,
          },
        ],
      };
    }

    if (type === 'validate-file') {
      return {
        ...defaultModalConfig,
        type: 'action',
        title: i18next.t('BACKOFFICE.DATA_MODEL.MODAL_IMPORT_SELECT_FILE_TITLE'),
        icon: '/images/inpulse/file-upload-black-small.svg',
        data: {
          subTitle: i18next.t('BACKOFFICE.DATA_MODEL.MODAL_IMPORT_SELECT_FILE_CONTENT'),
          selectedFile: updatedFile,
          setSelectedFile: onFileChange,
        },
        actions: [
          {
            key: 0,
            color: 'inpulse-outline',
            label: i18next.t('GENERAL.CANCEL'),
            icon: '/images/inpulse/close-black-small.svg',
            handleClick: setSelectedFile(null),
          },
          {
            key: 1,
            preventClosing: true,
            color: 'inpulse-default',
            label: i18next.t('GENERAL.IMPORT'),
            icon: '/images/inpulse/file-upload-white-small.svg',
            handleClick: () => handleFileValidation(updatedFile),
          },
        ],
      };
    }

    if (type === 'error-file') {
      return {
        ...defaultModalConfig,
        type: 'error',
        title: i18next.t('BACKOFFICE.DATA_MODEL.MODAL_IMPORT_SELECT_FILE_TITLE_VALIDATION'),
        icon: '/images/inpulse/info-white-small.svg',
        data: {
          subTitle: i18next.t('BACKOFFICE.CLIENT.MODAL_IMPORT_SELECT_FILE_CONTENT_ERROR'),
          validatedFile: false,
          selectedFile: updatedFile,
        },
        actions: [
          {
            key: 0,
            label: 'Fichier erreur',
            color: 'inpulse-default',
            icon: '/images/inpulse/file-download-white-small.svg',
            handleClick: () => downloadFile(errorFile, clientName, true),
          },
        ],
      };
    }

    if (type === 'validated-file') {
      return {
        ...defaultModalConfig,
        type: 'success',
        title: i18next.t('BACKOFFICE.DATA_MODEL.MODAL_IMPORT_SELECT_FILE_TITLE_VALIDATION'),
        icon: '/images/inpulse/check-white-small.svg',
        data: {
          validatedFile: true,
          selectedFile: updatedFile,
        },
        actions: [
          {
            key: 0,
            preventClosing: true,
            color: 'inpulse-default',
            label: i18next.t('GENERAL.IMPORT'),
            icon: '/images/inpulse/check-white-small.svg',
            handleClick: () => handleFileImport(updatedFile),
          },
        ],
      };
    }

    return defaultModalConfig;
  };

  return (
    <Container>
      <NavigationBreadCrumb featurePath={path} />
      <WhiteCard
        content={i18next.t('BACKOFFICE.DATA_MODEL.DOWNLOAD_INPULSE_DATA_MODEL')}
        renderContent={
          <Button
            animation={{ rotate: isDownloadingExcel }}
            buttonCustomStyle={{ width: 'fit-content' }}
            color={'inpulse-default'}
            handleClick={() => {
              if (isDownloadingExcel) {
                return;
              }

              retrieveBlobDataModel();
            }}
            icon={
              isDownloadingExcel
                ? '/images/inpulse/loader-white-small.svg'
                : '/images/inpulse/file-download-white-small.svg'
            }
            isDisabled={isDownloadingExcel}
            label={i18next.t('GENERAL.DOWNLOAD')}
          />
        }
        title={i18next.t('BACKOFFICE.DATA_MODEL.DOWNLOAD_DATA_MODEL')}
      ></WhiteCard>
      <WhiteCard
        content={i18next.t('BACKOFFICE.DATA_MODEL.IMPORT_INPULSE_DATA_MODEL')}
        renderContent={
          <Button
            buttonCustomStyle={{ width: 'fit-content' }}
            color={'inpulse-default'}
            handleClick={() => props.openGenericModal(getModalModalConfig('select-file', null))}
            icon={'/images/inpulse/file-upload-white-small.svg'}
            isDisabled={!clientStatusCheck(clientStatus, 'onboarding')}
            label={i18next.t('GENERAL.IMPORT')}
          />
        }
        title={i18next.t('BACKOFFICE.DATA_MODEL.IMPORT_DATA_MODEL')}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  modal: state.modalReducer,
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  closeGenericModal: (params) => {
    dispatch(closeGenericModal(params));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeDataModel);
