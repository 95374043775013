import { connect } from 'react-redux';
import { get, sortBy } from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

// Commons
import { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';
import { ListView } from '@commons/utils/styledLibraryComponents';
import { STANDARD_LISTVIEW_PADDING } from '@commons/constants/listViewProps';
import EmptyState from '@commons/EmptyState';
import UpgradePlanOverlay from '@commons/UpgradePlanOverlay';

// Actions
import { loading, loadingSuccess } from '@actions/loading';
import { openGenericModal, refreshGenericModal } from '@actions/modal';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

// Services
import storeService from '@services/store';

import DeepsightFiltersButton from '@orders/components/FilterButton';

import { getAuthorizedActions, getPrevHorizon } from '@selectors/featureProps';

// Utils
import { exportBufferStocksXLS } from './utils/export';
import { getBufferStockModalConfig } from './utils/bufferStockModal';
import { getColumns, getActions, getRowActions, FAKE_DATA_FOR_PLAN_UPGRADE } from './utils/utils';

import { Container, ListViewContainer } from './styledComponents';

const StoreBufferStocks = (props) => {
  const {
    storeParams: { id: storeId },
    user: { hasForecasts },
    showErrorMessage,
    showSuccessMessage,
    openGenericModal,
    refreshGenericModal,
    pageLoading,
    pageLoaded,
    authorizedActions,
    prevHorizon,
  } = props;

  const [actions, setActions] = useState([]);
  const [rowActions, setRowActions] = useState([]);

  const [columns] = useState(getColumns());
  const [ingredients, setIngredients] = useState([]);
  const [filteredIngredients, setFilteredIngredients] = useState([]);
  const [modalNbDays, setModalNbDays] = useState(null);
  const [selectedIngredients, setSelectedIngredients] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  // Filters
  const [applyFilters, setApplyFilters] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState([]);
  const [filters, setFilters] = useState(null);
  const [columnsFilterList, setColumnsFilterList] = useState([]);

  useEffect(() => {
    if (!applyFilters) {
      return;
    }

    if (!advancedFilters || !advancedFilters.length) {
      setFilteredIngredients(ingredients);
      return;
    }

    const filteredIngredientsWithAdvancedFilters = advancedFilters.reduce(
      (result, { doFilter, propertyKey, value }) => doFilter(result, propertyKey, value),
      filteredIngredients,
    );

    setFilteredIngredients(filteredIngredientsWithAdvancedFilters);
  }, [applyFilters, advancedFilters]);

  useEffect(() => {
    updateColumnsFilterList();
  }, [ingredients]);

  useEffect(() => {
    getAssociatedIngredients();
  }, []);

  const getAssociatedIngredients = async () => {
    setIsLoading(true);

    if (!hasForecasts) {
      setIngredients(FAKE_DATA_FOR_PLAN_UPGRADE);
      setFilteredIngredients(FAKE_DATA_FOR_PLAN_UPGRADE);
      setIsLoading(false);
      return;
    }

    try {
      const bufferStocks = await storeService.getStoreBufferStocks(storeId);

      /*
       We do this formatting because the listView needs an id to properly work and we use the ingredientId because
       bufferStock.id can be null in this case ( meaning the associated ingredient doesn't have a buffer stock )
      */
      const formattedBufferStocks = bufferStocks.map((bufferStock) => ({
        ...bufferStock,
        id: bufferStock.ingredientId,
      }));

      setIngredients(formattedBufferStocks);
      setFilteredIngredients(formattedBufferStocks);
    } catch {
      setIngredients([]);
      setFilteredIngredients([]);

      showErrorMessage(i18next.t('ADMIN.STORES.ASSOCIATED_INGREDIENTS_FETCH_ERROR'));
    } finally {
      setIsLoading(false);
    }
  };

  const updateColumnsFilterList = () => {
    const columnsList = columns.filter(({ filterType }) => !!filterType);

    const updateColumnsFilterList = columnsList.reduce((result, column) => {
      const updatedColumn = { ...column };

      const { propertyKey } = column;

      if (propertyKey === 'category') {
        const list = Object.values(
          ingredients.reduce((result, ingredient, key) => {
            if (ingredient[propertyKey]) {
              result[ingredient[propertyKey]] = {
                id: key,
                name: ingredient[propertyKey],
                status: ingredient[propertyKey],
                value: ingredient[propertyKey],
                [propertyKey]: ingredient[propertyKey],
              };
            }

            return result;
          }, {}),
        );

        updatedColumn.list = sortBy(list, 'name');
      }

      result.push(updatedColumn);

      return result;
    }, []);

    setColumnsFilterList(updateColumnsFilterList);
  };

  useEffect(() => {
    const isModalOpened = get(props, 'modal.GenericModalBool', false);

    if (!isModalOpened) {
      return;
    }

    const config = getBufferStockModalConfig({
      handleBufferStockModalSave,
      modalNbDays,
      setModalNbDays,
      selectedIngredients,
      prevHorizon,
    });

    refreshGenericModal(config);
  }, [modalNbDays]);

  useEffect(() => {
    setActions(
      getActions({
        openBufferStockModal,
        filteredIngredients,
        selectedIngredients,
        handleExportXLS: exportBufferStocksXLS,
        authorizedActions,
      }),
    );
    setRowActions(getRowActions(openBufferStockModal, authorizedActions));
  }, [filteredIngredients, selectedIngredients]);

  const openBufferStockModal = (ingredientsToUpdate) => {
    setSelectedIngredients(ingredientsToUpdate);

    const config = getBufferStockModalConfig({
      handleBufferStockModalSave,
      modalNbDays,
      setModalNbDays,
      selectedIngredients: ingredientsToUpdate,
      prevHorizon,
    });

    openGenericModal(config);
  };

  const handleBufferStockModalSave = async (ingredients) => {
    const ingredientIds = ingredients.map(({ ingredientId }) => ingredientId);

    pageLoading();

    try {
      await storeService.upsertBufferStocks(storeId, ingredientIds, modalNbDays);

      showSuccessMessage(i18next.t('ADMIN.INGREDIENTS.BUFFER_STOCKS_UPDATE_SUCCESS'));

      await getAssociatedIngredients();
    } catch {
      showErrorMessage(i18next.t('ADMIN.STORES.UPDATE_BUFFER_STOCK_ERROR'));
    } finally {
      setModalNbDays(null);
      setSelectedIngredients([]);
      pageLoaded();
    }
  };

  if (!isLoading && !filteredIngredients.length) {
    return (
      <EmptyState
        label={i18next.t('ADMIN.STORES.NO_INGREDIENT_EMPTY_STATE_TITLE')}
        labelColor={ENUM_COLORS.IP_BLACK_1}
        labelFont={ENUM_FONTS.H2_INTER}
        subtitle={i18next.t('ADMIN.STORES.NO_INGREDIENT_EMPTY_STATE_SUBTITLE')}
        subtitleMargin={'8px'}
      />
    );
  }

  return (
    <Container>
      {!hasForecasts && <UpgradePlanOverlay marginTop={'88px'} />}
      <ListViewContainer hasForecasts={hasForecasts}>
        <ListView
          actions={actions}
          columns={columns}
          data={filteredIngredients}
          defaultMaxPerPage={100}
          defaultOrderBy={'nbDays'}
          defaultOrderType={'desc'}
          forceEnableSelection={true}
          isLoading={isLoading}
          minActionsInActionsDropdown={1}
          padding={STANDARD_LISTVIEW_PADDING}
          renderEmptyState={() => <EmptyState />}
          renderFilterButton={() => (
            <DeepsightFiltersButton
              advancedFilters={advancedFilters}
              applyFilters={applyFilters}
              columnsFilterList={columnsFilterList}
              filters={filters}
              isLoading={isLoading}
              readOnly={isLoading}
              setAdvancedFilters={setAdvancedFilters}
              setApplyFilters={setApplyFilters}
              setFilters={setFilters}
              textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
            />
          )}
          rowActions={rowActions}
          setSelectedItems={setSelectedIngredients}
        />
      </ListViewContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  modal: state.modalReducer,
  authorizedActions: getAuthorizedActions(
    state.baseReducer.userRights,
    '/admin/stores/:id/buffer-stocks',
  ),
  prevHorizon: getPrevHorizon(state.baseReducer.userRights),
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreBufferStocks);
