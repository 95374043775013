import SUPPLIER_PROFILES_XLSX_CONSTANT from '@commons/Excels/constants/supplierProfile.xlsx.constant';

import { normalizeJsonObject } from '@backoffice/utils';
/**
 * This function aim to format excel of supplier profile
 * to send it to the API and validate or insert in database
 * @param {object} supplierProfiles - The supplier profile batch creation to format to validate or insert in database
 * @return {object}
 */
export const formatSupplierProfilesCreationFile = (supplierProfiles) => {
  const supplierProfileSheetConfig =
    SUPPLIER_PROFILES_XLSX_CONSTANT.getSupplierProfileSheetConfig();

  const suppliersSheetConfig = SUPPLIER_PROFILES_XLSX_CONSTANT.getSuppliersSheetConfig();

  const formattedSupplierProfiles = normalizeJsonObject(supplierProfiles);

  return Object.keys(formattedSupplierProfiles).reduce((result, sheetName) => {
    if (sheetName === supplierProfileSheetConfig.SHEET.NAME) {
      result[supplierProfileSheetConfig.SHEET.ID] = formattedSupplierProfiles[sheetName].map(
        (current) => ({
          // Nom du Fournisseur
          [supplierProfileSheetConfig.COLUMN_IDS.SUPPLIER]:
            current[supplierProfileSheetConfig.NORMALIZED_COLUMN_IDS.SUPPLIER],
          // Nom du profil fournisseur
          [supplierProfileSheetConfig.COLUMN_IDS.SUPPLIER_PROFILE_NAME]:
            current[supplierProfileSheetConfig.NORMALIZED_COLUMN_IDS.SUPPLIER_PROFILE_NAME],
          // Nom du contact
          [supplierProfileSheetConfig.COLUMN_IDS.CONTACT_NAME]:
            current[supplierProfileSheetConfig.NORMALIZED_COLUMN_IDS.CONTACT_NAME],
          // Email de commande
          [supplierProfileSheetConfig.COLUMN_IDS.ORDER_EMAIL]:
            current[supplierProfileSheetConfig.NORMALIZED_COLUMN_IDS.ORDER_EMAIL],
          // Email de demande d'avoir
          [supplierProfileSheetConfig.COLUMN_IDS.CREDIT_EMAIL]:
            current[supplierProfileSheetConfig.NORMALIZED_COLUMN_IDS.CREDIT_EMAIL],
          // Email CCI de commande
          [supplierProfileSheetConfig.COLUMN_IDS.BCC_EMAILS]:
            current[supplierProfileSheetConfig.NORMALIZED_COLUMN_IDS.BCC_EMAILS],
          // Telephone
          [supplierProfileSheetConfig.COLUMN_IDS.PHONE]:
            current[supplierProfileSheetConfig.NORMALIZED_COLUMN_IDS.PHONE],
          // Adresse postal
          [supplierProfileSheetConfig.COLUMN_IDS.ADDRESS]:
            current[supplierProfileSheetConfig.NORMALIZED_COLUMN_IDS.ADDRESS] || '',
          // Code postal
          [supplierProfileSheetConfig.COLUMN_IDS.POSTAL_CODE]: !!current[
            supplierProfileSheetConfig.NORMALIZED_COLUMN_IDS.POSTAL_CODE
          ]
            ? String(current[supplierProfileSheetConfig.NORMALIZED_COLUMN_IDS.POSTAL_CODE])
            : '',
          // Ville
          [supplierProfileSheetConfig.COLUMN_IDS.CITY]:
            current[supplierProfileSheetConfig.NORMALIZED_COLUMN_IDS.CITY],
          // Pays
          [supplierProfileSheetConfig.COLUMN_IDS.COUNTRY]:
            current[supplierProfileSheetConfig.NORMALIZED_COLUMN_IDS.COUNTRY] || '',
          // Fuseau horaire
          [supplierProfileSheetConfig.COLUMN_IDS.TIMEZONE]:
            current[supplierProfileSheetConfig.NORMALIZED_COLUMN_IDS.TIMEZONE] || '',
          // Minimum de commande (Prix)
          [supplierProfileSheetConfig.COLUMN_IDS.ORDER_MIN_AMOUNT]:
            current[supplierProfileSheetConfig.NORMALIZED_COLUMN_IDS.ORDER_MIN_AMOUNT],
          // Minimum de commande (colis)
          [supplierProfileSheetConfig.COLUMN_IDS.ORDER_MIN_UNIT]:
            current[supplierProfileSheetConfig.NORMALIZED_COLUMN_IDS.ORDER_MIN_UNIT],
          // Delais de livraison (lundi)
          [supplierProfileSheetConfig.COLUMN_IDS.MONDAY_DELIVERY]:
            current[supplierProfileSheetConfig.NORMALIZED_COLUMN_IDS.MONDAY_DELIVERY],
          // Delais de livraison (mardi)
          [supplierProfileSheetConfig.COLUMN_IDS.TUESDAY_DELIVERY]:
            current[supplierProfileSheetConfig.NORMALIZED_COLUMN_IDS.TUESDAY_DELIVERY],
          // Delais de livraison (mercredi)
          [supplierProfileSheetConfig.COLUMN_IDS.WEDNESDAY_DELIVERY]:
            current[supplierProfileSheetConfig.NORMALIZED_COLUMN_IDS.WEDNESDAY_DELIVERY],
          // Delais de livraison (jeudi)
          [supplierProfileSheetConfig.COLUMN_IDS.THURSDAY_DELIVERY]:
            current[supplierProfileSheetConfig.NORMALIZED_COLUMN_IDS.THURSDAY_DELIVERY],
          // Delais de livraison (vendredi)
          [supplierProfileSheetConfig.COLUMN_IDS.FRIDAY_DELIVERY]:
            current[supplierProfileSheetConfig.NORMALIZED_COLUMN_IDS.FRIDAY_DELIVERY],
          // Delais de livraison (samedi)
          [supplierProfileSheetConfig.COLUMN_IDS.SATURDAY_DELIVERY]:
            current[supplierProfileSheetConfig.NORMALIZED_COLUMN_IDS.SATURDAY_DELIVERY],
          // Delais de livraison (dimanche)
          [supplierProfileSheetConfig.COLUMN_IDS.SUNDAY_DELIVERY]:
            current[supplierProfileSheetConfig.NORMALIZED_COLUMN_IDS.SUNDAY_DELIVERY],
          // Heure limite de commande
          [supplierProfileSheetConfig.COLUMN_IDS.ORDER_TIME_LIMIT]:
            current[supplierProfileSheetConfig.NORMALIZED_COLUMN_IDS.ORDER_TIME_LIMIT],
        }),
      );
    }

    if (sheetName === suppliersSheetConfig.SHEET.NAME) {
      result[suppliersSheetConfig.SHEET.ID] = formattedSupplierProfiles[sheetName].map(
        (current) => ({
          // supplierId
          [suppliersSheetConfig.COLUMN_IDS.ID]:
            current[suppliersSheetConfig.NORMALIZED_COLUMN_IDS.id],
          // Name
          [suppliersSheetConfig.COLUMN_IDS.NAME]:
            current[suppliersSheetConfig.NORMALIZED_COLUMN_IDS.name],
        }),
      );
    }

    return result;
  }, {});
};

export default { formatSupplierProfilesCreationFile };
