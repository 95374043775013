import { connect } from 'react-redux';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useState, useEffect } from 'react';

import { showErrorMessage } from '@actions/messageconfirmation';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { Dropdown, ListView } from '@commons/utils/styledLibraryComponents';
import { LISTVIEW_NO_TOP_PADDING } from '@commons/constants/listViewProps';
import { PeriodDatePicker } from '@commons/DatePickers/PeriodDatePicker';
import EmptyState from '@commons/EmptyState';

import { usePeriodDatePickerState } from '@hooks/usePeriodDatePickerState';

import internalInvoiceService from '@services/internalInvoice';

import utilsColumns from './utils/columns';

import { Container, FilterButtonContainer } from './styledComponents';

const AssociateOrdersModal = (props) => {
  const { store, supplier, showErrorMessage, setOrdersToBeAdded, associatedOrders } = props;

  // List view states
  const [listViewColumns] = useState(utilsColumns.get());

  // Data states
  const [isLoading, setIsLoading] = useState(true);
  const [availableOrders, setAvailableOrders] = useState([]);

  const periodPickerState = usePeriodDatePickerState(
    moment().startOf('month'),
    moment().endOf('month'),
  );

  // Loading
  useEffect(() => {
    if (!periodPickerState.startDate || !periodPickerState.endDate) {
      return;
    }

    setOrdersToBeAdded([]);

    loadOrders();
  }, [periodPickerState.startDate, periodPickerState.endDate]);

  const loadOrders = async () => {
    setIsLoading(true);

    try {
      const formattedStartDate = periodPickerState.startDate.format(
        DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY,
      );
      const formattedEndDate = periodPickerState.endDate.format(
        DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY,
      );

      const orders = await internalInvoiceService.getAvailableOrdersToAssociate(
        store.id,
        supplier.supplierId,
        formattedStartDate,
        formattedEndDate,
      );

      const formattedOrders = orders.map((order) => ({
        ...order,
        isNotSelectable: order.orderInvoiceInformationId, // already associated orders should be disabled
      }));

      const availableOrders = associatedOrders?.length
        ? formattedOrders.filter(
            (order) => !associatedOrders.find((associatedOrder) => associatedOrder.id === order.id),
          )
        : formattedOrders;

      setAvailableOrders(availableOrders);
    } catch {
      setAvailableOrders([]);

      showErrorMessage(
        i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ORDER_MAPPINGS_ASSOCIATION_FETCH_ERROR'),
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <ListView
        columns={listViewColumns}
        data={availableOrders}
        defaultOrderBy={'startOrderDate'}
        defaultOrderType={'desc'}
        isLoading={isLoading}
        padding={LISTVIEW_NO_TOP_PADDING}
        renderEmptyState={() => <EmptyState />}
        renderFilterButton={() => (
          <FilterButtonContainer>
            <Dropdown selectedItem={supplier} isDisabled isUniqueSelection />
            <Dropdown selectedItem={store} isDisabled isUniqueSelection />
            <PeriodDatePicker
              disabled={isLoading}
              endDate={periodPickerState.endDate}
              focusedDateInput={periodPickerState.focusedDateInput}
              setFocusedDateInput={periodPickerState.setFocusedDateInput}
              startDate={periodPickerState.startDate}
              onDatesChange={periodPickerState.handleSelectedDates}
            />
          </FilterButtonContainer>
        )}
        setSelectedItems={setOrdersToBeAdded}
        forceEnableSelection
        hideAllPerPageOption
        hideSearchbar
      />
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

export default connect(null, mapDispatchToProps)(AssociateOrdersModal);
