import { cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import i18next from 'i18next';
import React, { useState } from 'react';

import { Input } from '@lib/inpulse/Input';

import { Dropdown, INPUT_WIDTH } from '@commons/utils/styledLibraryComponents';
import GenericModal from '@commons/Modals/GenericModal';
import normalizeStringValue from '@commons/utils/normalizeStringValue';

import { showErrorMessage } from '@actions/messageconfirmation';

import { canSeeAllCatalogs } from '@selectors/user';
import { getClientInfo } from '@selectors/client';

import sortSupplierProductProperties from '@admin/utils/sortSupplierProductProperties';

import { getNewCategoryModalParams } from '../utils/modalConfigurations';

import { BodyRow, GenericModalContainer, InputContainer } from './styledComponents';

const SupplierModalInformation = (props) => {
  const {
    client,
    supplier,
    userHasRoleInpulse,
    catalogs,
    selectedCatalog,
    handleCatalogChange,
    currencies,
    selectedCurrency,
    suppliersCategories,
    handleCurrencyChange,
    handleSupplierChange,
    handleSuppliersCategoriesChange,
    selectedLanguage,
    languagesDropdownItems,
    handleLanguageChange,
    user,
  } = props;

  const [openModalAddNewItemDropdown, setOpenModalAddNewItemDropdown] = useState(false);

  const [inputValue, setInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSaveNewItemDropdown = () => {
    const updatedField = cloneDeep(suppliersCategories);

    updatedField.push({
      id: Math.random(),
      name: inputValue.trim(),
    });

    handleSuppliersCategoriesChange(
      sortSupplierProductProperties(
        updatedField.map((field) => field.name),
        true,
      ),
    );

    handleSupplierChange(inputValue, 'category');

    setOpenModalAddNewItemDropdown(false);

    setInputValue('');
    setErrorMessage(null);
  };

  const handleInputChange = (value) => {
    setInputValue(value);

    if (!value) {
      setErrorMessage(i18next.t('GENERAL.REQUIRED_FILED_ERROR_MESSAGE'));

      return;
    }

    const alreadyExists = suppliersCategories.some(
      ({ name }) => normalizeStringValue(name) === normalizeStringValue(value),
    );

    if (alreadyExists) {
      setErrorMessage(i18next.t('GENERAL.MODAL_CATEGORY_NAME_ALREADY_USED'));

      return;
    }

    setErrorMessage(null);
  };

  const closeCleanUp = () => {
    setErrorMessage(null);
    setInputValue('');
  };

  const getCreateCategoryButtonParams = () => {
    if (!canSeeAllCatalogs(user, client)) {
      return null;
    }

    return {
      id: 1,
      text: i18next.t('GENERAL.LIST_CREATE_CATEGORY'),
      handleClick: () => {
        setOpenModalAddNewItemDropdown(true);
      },
      iconSrc: '/images/inpulse/add-black-small.svg',
    };
  };

  const modalCreateNewCategoryParams = getNewCategoryModalParams(
    inputValue,
    errorMessage,
    closeCleanUp,
    handleInputChange,
    handleSaveNewItemDropdown,
  );

  const supplierCategory = supplier.category
    ? suppliersCategories.find(
        (category) => category.name.trim().toLowerCase() === supplier.category.trim().toLowerCase(),
      )
    : supplier.category;

  return (
    <div
      className="medium-modal-form-main-container"
      style={{ height: 'calc(100% - 176px)', overflow: 'auto' }}
    >
      <div className="medium-modal-form-category-block">
        <div className="medium-modal-form-category-header">
          {i18next.t('ADMIN.SUPPLIERS.CREATE_SUPPLIER_GENERAL_INFO')}
        </div>
        <div className="medium-modal-form-category-body">
          <BodyRow childCount={3}>
            <div className="medium-modal-form-category-body-row">
              <InputContainer>
                <Input
                  label={`* ${i18next.t('GENERAL.NAME')}`}
                  placeholder={i18next.t('GENERAL.NAME')}
                  type="text"
                  value={supplier.name || ''}
                  onChange={(ev) => handleSupplierChange(ev.target.value, 'name')}
                />
              </InputContainer>
              <InputContainer>
                <Dropdown
                  button={getCreateCategoryButtonParams()}
                  items={suppliersCategories}
                  label={`${i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_CATEGORY')} :`}
                  placeholder={i18next.t('GENERAL.CHOOSE_PLACEHOLDER')}
                  selectedItem={supplierCategory}
                  width={INPUT_WIDTH.FULL}
                  isLabelTextSmall
                  onSelectionChange={(selectedItem, _, isUnselected) => {
                    handleSupplierChange(isUnselected ? null : selectedItem.value, 'category');
                  }}
                />
              </InputContainer>
              <InputContainer>
                <Input
                  label={i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_SUPPLIER_CODE')}
                  placeholder={i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_SUPPLIER_CODE')}
                  type="text"
                  value={supplier.code || ''}
                  onChange={(ev) => handleSupplierChange(ev.target.value, 'code')}
                />
              </InputContainer>
            </div>
          </BodyRow>

          {(client.hasMultipleCurrencies || client.hasLocalCatalogs) && (
            <BodyRow childCount={client.hasMultipleCurrencies && client.hasLocalCatalogs ? 2 : 1}>
              <div className="medium-modal-form-category-body-row">
                {client.hasLocalCatalogs && (
                  <InputContainer style={{ height: '68px' }}>
                    <Dropdown
                      isDisabled={!userHasRoleInpulse}
                      items={catalogs}
                      label={`${i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_CATALOG')} :`}
                      selectedItem={selectedCatalog}
                      width={INPUT_WIDTH.FULL}
                      isLabelTextSmall
                      isRequired
                      onSelectionChange={handleCatalogChange}
                    />
                  </InputContainer>
                )}
                {client.hasMultipleCurrencies && (
                  <InputContainer style={{ height: '68px' }}>
                    <Dropdown
                      items={currencies}
                      label={`${i18next.t('GENERAL.CURRENCY')} :`}
                      selectedItem={selectedCurrency}
                      width={INPUT_WIDTH.FULL}
                      isDisabled
                      isLabelTextSmall
                      isRequired
                      onSelectionChange={handleCurrencyChange}
                    />
                  </InputContainer>
                )}
              </div>
            </BodyRow>
          )}
        </div>
      </div>
      <div className="medium-modal-form-category-block">
        <div className="medium-modal-form-category-header">
          {i18next.t('ADMIN.SUPPLIERS.SUPPLIER_DETAIL_LOCATION')}
        </div>
        <div className="medium-modal-form-category-body">
          <BodyRow childCount={3}>
            <div className="medium-modal-form-category-body-row">
              <InputContainer>
                <Input
                  label={i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_ADDRESS')}
                  placeholder={i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_ADDRESS')}
                  type="text"
                  value={supplier.adress || ''}
                  onChange={(ev) => handleSupplierChange(ev.target.value, 'adress')}
                />
              </InputContainer>
              <InputContainer>
                <Input
                  label={i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_POSTAL_CODE')}
                  placeholder={i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_POSTAL_CODE')}
                  type="number"
                  value={supplier.postCode || ''}
                  onChange={(ev) => handleSupplierChange(ev.target.value, 'postCode')}
                />
              </InputContainer>
              <InputContainer>
                <Input
                  label={i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_CITY')}
                  placeholder={i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_CITY')}
                  type="text"
                  value={supplier.city || ''}
                  onChange={(ev) => handleSupplierChange(ev.target.value, 'city')}
                />
              </InputContainer>
            </div>
          </BodyRow>
          <BodyRow childCount={2}>
            <div className="medium-modal-form-category-body-row">
              <InputContainer>
                <Input
                  label={i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_COUNTRY')}
                  placeholder={i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_COUNTRY')}
                  type="text"
                  value={supplier.country || ''}
                  onChange={(ev) => handleSupplierChange(ev.target.value, 'country')}
                />
              </InputContainer>
              <InputContainer>
                <Dropdown
                  iconSrc={selectedLanguage ? selectedLanguage.iconSrc : null}
                  items={languagesDropdownItems}
                  label={`${i18next.t('ADMIN.SUPPLIERS.DOCUMENTS_LANGUAGE')} :`}
                  selectedItem={selectedLanguage}
                  tooltipText={i18next.t('ADMIN.SUPPLIERS.DOCUMENTS_LANGUAGE_TOOLTIP')}
                  width={INPUT_WIDTH.FULL}
                  isLabelTextSmall
                  isRequired
                  onSelectionChange={handleLanguageChange}
                />
              </InputContainer>
            </div>
          </BodyRow>
        </div>
      </div>
      {openModalAddNewItemDropdown && (
        <GenericModalContainer>
          <GenericModal
            closeGenericModal={() => setOpenModalAddNewItemDropdown(false)}
            component={modalCreateNewCategoryParams.component}
            params={modalCreateNewCategoryParams}
          />
        </GenericModalContainer>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
  user: state.baseReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(SupplierModalInformation);
