import _ from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';

import productOrderContainerUtils from '../../../../utils/index';

import OrderStockChart from './components/OrderStockChart';
import OrderStockInfoRecommendation from './components/OrderStockInfoRecommendation/OrderStockInfoRecommendation';

import utilLibs from './utils';

import { Container, RecommendationContainer, StockContainer } from './styledComponents';

const OrderStockInfo = (props) => {
  const {
    startDate,
    endDate,
    computedOrderedAmount,
    entityStockEvent,
    moreThanFiftyDay,
    consumption,
    stockConvention,
    entityId,
    hasForecasts,
    currency,
    storeTimezone,
  } = props;

  const [entityUnit, setEntityUnit] = useState(null);
  const [entityName, setEntityName] = useState('');
  const [forecastStock, setForecastStock] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const formattedStartDate = moment
    .tz(storeTimezone)
    .subtract(1, 'day')
    .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);
  const recoStartDate = moment(startDate).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);
  const recoEndDate = moment(endDate).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

  const diffBetweenStartAndEnd = moment
    .tz(recoEndDate, storeTimezone)
    .diff(formattedStartDate, 'days');
  const formattedEndDate = moment
    .tz(formattedStartDate, storeTimezone)
    .add(!moreThanFiftyDay ? 16 : diffBetweenStartAndEnd, 'days')
    .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

  useEffect(() => {
    if (_.isEmpty(entityStockEvent)) {
      return;
    }

    setEntityName(entityStockEvent.entity.name);
    setEntityUnit(productOrderContainerUtils.getEntityUnit(entityStockEvent.entity));

    setIsLoading(false);
  }, [entityStockEvent]);

  useEffect(() => {
    if (_.isEmpty(entityStockEvent) || !computedOrderedAmount) {
      return;
    }

    setForecastStock(
      utilLibs.computedForecastStock(
        entityStockEvent,
        recoStartDate,
        computedOrderedAmount,
        formattedStartDate,
        formattedEndDate,
        recoEndDate,
        moreThanFiftyDay,
        stockConvention,
        storeTimezone,
      ),
    );

    setIsLoading(false);
  }, [entityStockEvent, computedOrderedAmount]);

  return (
    <>
      <Container>
        <RecommendationContainer isLoading={isLoading}>
          <OrderStockInfoRecommendation
            bufferStock={entityStockEvent.bufferStock || null}
            computedOrderedAmount={computedOrderedAmount}
            consumption={consumption}
            currency={currency}
            entityId={entityId}
            entityName={entityName}
            entityRecommandation={entityStockEvent.recommandation}
            entityStock={entityStockEvent.stock}
            entityUnit={entityUnit}
            hasForecasts={hasForecasts}
            isLoading={isLoading}
            moreThanFiftyDay={moreThanFiftyDay}
            storeTimezone={storeTimezone}
          />
        </RecommendationContainer>
        <StockContainer>
          <OrderStockChart
            bufferStock={entityStockEvent.bufferStock || null}
            forecastStock={forecastStock}
            hasForecasts={hasForecasts}
            isLoading={isLoading}
            periodEndDate={recoEndDate}
            periodStartDate={recoStartDate}
            storeTimezone={storeTimezone}
            unit={entityUnit}
            yesterday={formattedStartDate}
          />
        </StockContainer>
      </Container>
    </>
  );
};

export default OrderStockInfo;
