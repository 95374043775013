import { connect } from 'react-redux';
import _ from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useState, useRef } from 'react';

import { closeGenericModal, openGenericModal, refreshGenericModal } from '@actions/modal';
import { loading, loadingSuccess } from '@actions/loading';
import { openConfirmationModal } from '@actions/confirmationmodal';
import { showConfirmationMessage, showErrorMessage } from '@actions/messageconfirmation';

import { DeepsightConfirmationModal } from '@commons/DeepsightComponents';
import { ListView } from '@commons/utils/styledLibraryComponents';
import { sortArrayOfObjectsAlphabetically } from '@commons/utils/sorting';
import { STANDARD_LISTVIEW_PADDING } from '@commons/constants/listViewProps';
import utilsXLS from '@commons/utils/makeXLS';

import { ENUM_QUERY_PARAMS, useListViewQueryParams } from '@hooks/useListViewQueryParams';

import { user as userService } from '@services/user';
import storeService from '@services/store';

import { getAuthorizedActions } from '@selectors/featureProps';
import { getClientInfo } from '@selectors/client';

import DeepsightFiltersButton from '@admin/components/FilterButton';
import EmptyState from '@admin/suppliers/suppliers/components/EmptyState';

import { Container } from './styledComponents';
import { getActions, getRowActions } from './utils/getActions';
import { getColumns } from './utils/columns';
import getAccountMappingModalConfig from './components/AccountMappingModal/getModalConfig';

/**
 * Retrieve the list of accounts associated to the given storeId
 *
 * @param {String} storeId           - The store id on which retrieve the associated account
 * @param {Method} showErrorMessage  - The method to display message on page
 *
 * @returns {Object[]} The list of accounts associated to the storeId
 */
export async function fetchAccountsOfStore(storeId, showErrorMessage) {
  try {
    const accounts = await storeService.getAccountsOfStore(storeId);

    const formattedAccounts = accounts.map((account) => ({
      id: account.id,
      name: `${account.firstName} ${account.lastName}`,
      email: account.email,
      role: _.get(account, 'lnkAccountRoleAccountrel.name', ''),
      status: account.status,
      invitationLinkSentDate: account.invitationLinkSentDate,
      invitationLinkExpiredDate: account.invitationLinkExpiredDate,
      registrationDate: account.registrationDate,
      creationDate: account.creationDate,
    }));

    return formattedAccounts;
  } catch (error) {
    showErrorMessage(i18next.t('USERS.DETAILS.PROFILE_LIST_ACCOUNTS_FETCH_FAILURE'));

    return [];
  }
}

export const StoreAssociatedUsers = (props) => {
  const {
    client: { storeName },
    storeParams,
    authorizedActions,
    pageLoading,
    pageLoaded,
    showMessage,
    showErrorMessage,
    openConfirmationModal,
    openGenericModal,
    refreshGenericModal,
    closeGenericModal,
  } = props;
  const storeId = storeParams.id;

  const listViewRef = useRef(null);

  const [accountList, setAccountList] = useState([]);
  const [filteredAccountList, setFilteredAccountList] = useState([]);
  const [columns] = useState(getColumns());

  const [filters, setFilters] = useState(null);
  const [applyFilters, setApplyFilters] = useState(true);
  const [advancedFilters, setAdvancedFilters] = useState(null);
  const [columnsFilterList, setColumnsFilterList] = useState([]);
  const [actions, setActions] = useState([]);
  const [rowActions, setRowActions] = useState([]);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [accountsToMap, setAccountsToMap] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [listViewQueryParams, setListViewQueryParams] = useListViewQueryParams();

  const updateColumnsFilterList = (accounts) => {
    const statusColumn = columns.find((column) => column.propertyKey === 'status');

    const updatedColumnFilterList = [statusColumn];

    const roleColumn = columns.find((column) => column.propertyKey === 'role');

    if (!roleColumn) {
      return setColumnsFilterList(updatedColumnFilterList);
    }

    const roles = Object.values(
      accounts.reduce((result, account, key) => {
        if (account.role) {
          result[account.role] = {
            id: key,
            name: account.role,
            status: account.role,
            value: account.role,
            role: account.role,
          };
        }

        return result;
      }, {}),
    );

    roleColumn.list = sortArrayOfObjectsAlphabetically(roles, 'name');

    return setColumnsFilterList([...updatedColumnFilterList, roleColumn]);
  };

  const processAdvancedFilter = (accounts) => {
    if (!applyFilters) {
      return;
    }

    const filteredAccountList = accounts;

    if ((!advancedFilters || !advancedFilters.length) && applyFilters) {
      setFilteredAccountList(filteredAccountList);
      return;
    }

    const filteredAccountListWithAdvancedFilters = advancedFilters.reduce(
      (result, { doFilter, propertyKey, value }) => doFilter(result, propertyKey, value),
      filteredAccountList,
    );

    return setFilteredAccountList(filteredAccountListWithAdvancedFilters);
  };

  const handleExport = async (accountsToExport) => {
    const currentaccountsToExport = accountsToExport.length
      ? accountsToExport
      : listViewRef.current.getFilteredData();

    const data = currentaccountsToExport.map((account) => ({
      ...account,
      status: i18next.t(account.status),
    }));

    const sheetTitle = i18next.t('ADMIN.SUB_FEATURES.MAPPED_USERS');

    const sheet = utilsXLS.generateDefaultSheet(sheetTitle, columns, data);

    utilsXLS.makeXLS(sheetTitle, [sheet]);
  };

  const confirmDissociation = (selectedAccounts, email) => {
    const titleModal = i18next.t('USERS.DETAILS.STORES_DELETE_MODAL_CONFIRMATION_TITLE_STORE', {
      count: selectedAccounts.length,
    });
    let textModal = '';

    if (selectedAccounts.length === 1) {
      textModal = i18next.t('USERS.DETAILS.STORES_DELETE_MODAL_CONFIRMATION_CONTENT_SINGLE_STORE', {
        email,
        name: storeParams.name,
      });
    } else {
      textModal = i18next.t(
        'USERS.DETAILS.STORES_DELETE_MODAL_CONFIRMATION_CONTENT_SINGLE_STORE_MULTIPLE_ACCOUNTS',
        { name: storeParams.name },
      );
    }

    const params = {
      component: DeepsightConfirmationModal,
      props,
      colorsheme: 'blue',
      eventId: selectedAccounts,
      handleEventDeleteConfirmation: () => deleteAccountsAssociation(selectedAccounts),
      title: titleModal,
      text: textModal,
      styleTitle: { fontFamily: 'proximanovaregular' },
      deactivateBackgroundClick: true,
    };

    openConfirmationModal(params);
  };

  const deleteAccountsAssociation = async (selectedAccounts) => {
    const accountsIds = selectedAccounts.map((account) => account.id);

    try {
      pageLoading();

      await userService.deleteStoreAssociationByAccountIds(storeId, accountsIds);

      showMessage(i18next.t('USERS.DETAILS.STORES_DELETE_SUCCESS'));
      await fetchAccounts();
    } catch {
      showErrorMessage(i18next.t('USERS.DETAILS.STORES_DELETE_FAILURE'));
    } finally {
      pageLoaded();
    }
  };

  const handleCreateMappings = async (accountsToMap) => {
    const accountIds = accountsToMap.map(({ id }) => id);

    try {
      pageLoading();

      await userService.createStoreAssociationByAccountIds(storeId, accountIds);

      showMessage(i18next.t('USERS.DETAILS.STORES_CREATE_SUCCESS'));

      await fetchAccounts();
    } catch {
      showMessage(i18next.t('USERS.DETAILS.STORES_CREATE_FAILURE'), 'error');
    } finally {
      closeGenericModal();
      pageLoaded();
      setAccountsToMap([]);
    }
  };

  const openAssociationModal = () => {
    const params = getAccountMappingModalConfig({
      accountList,
      accountsToMap,
      setAccountsToMap,
      storeId,
      handleCreateMappings,
      storeName,
    });

    openGenericModal(params);
  };

  const fetchAccounts = async () => {
    const accounts = await fetchAccountsOfStore(storeId, showErrorMessage);

    setAccountList(accounts);
    setFilteredAccountList(accounts);
    updateColumnsFilterList(accounts);
  };

  useEffect(() => {
    pageLoading();
    setIsLoading(true);

    (async function loadData() {
      await fetchAccounts();
      setIsLoading(false);
      pageLoaded();
    })();
  }, []);

  useEffect(() => {
    processAdvancedFilter(accountList);
  }, [applyFilters, advancedFilters]);

  useEffect(() => {
    const updatedActions = getActions(
      selectedAccounts,
      handleExport,
      confirmDissociation,
      openAssociationModal,
      authorizedActions,
    );

    setActions(updatedActions);

    setRowActions(getRowActions(confirmDissociation, authorizedActions));
  }, [accountList, selectedAccounts, accountsToMap]);

  useEffect(() => {
    const params = getAccountMappingModalConfig({
      accountList,
      accountsToMap,
      setAccountsToMap,
      storeId,
      handleCreateMappings,
      storeName,
    });

    refreshGenericModal(params);
  }, [accountsToMap]);

  const userLanguageCode = _.get(props.user, 'lnkLanguageAccountrel.code', 'fr');

  return (
    <Container>
      <ListView
        actions={actions}
        columns={columns}
        data={filteredAccountList}
        defaultCurrentPage={listViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE]}
        defaultMaxPerPage={listViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE]}
        defaultOrderBy={listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY]}
        defaultOrderType={listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE]}
        defaultSearchInput={listViewQueryParams[ENUM_QUERY_PARAMS.SEARCH]}
        handleCurrentPageChange={(input) =>
          setListViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE](input)
        }
        handleMaxPerPageChange={(input) =>
          setListViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE](input)
        }
        handleOrderByChange={(input) => setListViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY](input)}
        handleOrderTypeChange={(input) =>
          setListViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE](input)
        }
        handleSearchInputChange={(input) => setListViewQueryParams[ENUM_QUERY_PARAMS.SEARCH](input)}
        isLoading={isLoading}
        languageCode={userLanguageCode}
        padding={STANDARD_LISTVIEW_PADDING}
        placeholderShape={i18next.t('GENERAL.SEARCH')}
        ref={listViewRef}
        renderEmptyState={() => <EmptyState />}
        renderFilterButton={() => {
          if (!columnsFilterList || !columnsFilterList.length) {
            return null;
          }

          return (
            <DeepsightFiltersButton
              advancedFilters={advancedFilters}
              applyFilters={applyFilters}
              columnsFilterList={columnsFilterList}
              filters={filters}
              isLoading={isLoading}
              minWidth={120}
              readOnly={isLoading}
              setAdvancedFilters={(it) => setAdvancedFilters(it)}
              setApplyFilters={(it) => setApplyFilters(it)}
              setFilters={(it) => setFilters(it)}
              textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
            />
          );
        }}
        rowActions={rowActions}
        setSelectedItems={(items) => setSelectedAccounts(items)}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  client: getClientInfo(state.baseReducer.user),
  authorizedActions: getAuthorizedActions(
    state.baseReducer.userRights,
    '/admin/stores/:id/details-users',
  ),
});

const mapDispatchToProps = (dispatch) => ({
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
  closeGenericModal: () => {
    dispatch(closeGenericModal());
  },
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  openConfirmationModal: (params) => {
    dispatch(openConfirmationModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreAssociatedUsers);
