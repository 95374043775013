import { connect } from 'react-redux';
import { get, upperFirst } from 'lodash';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import clientService from '@services/client';
import storeService from '@services/store';

import { getStoresOfUser, receiveStores, requestStoresError } from '@actions/store';
import { loading, loadingSuccess } from '@actions/loading';
import { showConfirmationMessage } from '@actions/messageconfirmation';

import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { ListView, Button } from '@commons/utils/styledLibraryComponents';
import EmptyState from '@commons/EmptyState';

import { getClientInfo } from '@selectors/client';

import { CHOICES_DROPDOWN_ACTIVE } from '@admin/utils/DropdownItems';

import DeepsightFiltersButton from '@orders/components/FilterButton';

import {
  Content,
  Container,
  Header,
  HeaderTitle,
  CloseImage,
  HeaderLeftPart,
  Footer,
} from './styledComponents';
import utilsColumns from './utils/columns';

const isStoreAlreadyAssociated = (groupId, mappedGroups) => {
  const mappedGroupIds = mappedGroups.map((group) => group.id);

  return mappedGroupIds.includes(groupId);
};

const getStoresOfClient = async (group, hasMultipleBrands, translatedStoreName, showMessage) => {
  try {
    const stores = await storeService.getStoresOfClient();

    return stores.map((store) => ({
      ...store,
      ...(hasMultipleBrands ? { brandName: get(store, 'lnkBrandStorerel.name') || '' } : {}),
      isRowSelected: isStoreAlreadyAssociated(group.id, store.mappedGroups),
    }));
  } catch (err) {
    showMessage(
      i18next.t('GENERAL.STORES_CLIENT_FETCH_FAILURE', {
        storeName: translatedStoreName,
      }),
      'error',
    );

    return [];
  }
};

const handleSave = async (
  clientId,
  group,
  selectedItems,
  fetchClientGroups,
  pageLoading,
  pageLoaded,
  showMessage,
  closeModal,
  translatedStoreName,
  getStoresOfUser,
) => {
  pageLoading();

  try {
    const selectedItemsIds = selectedItems.map((store) => store.id);

    await clientService.createGroupStoreGroupMappings(clientId, group.id, selectedItemsIds);

    await fetchClientGroups();

    showMessage(
      i18next.t('BACKOFFICE.GROUPS.ASSOCIATION_MODAL_ASSOCIATE_SUCCESS', {
        storeName: upperFirst(translatedStoreName.toLowerCase()),
      }),
    );

    closeModal();

    getStoresOfUser();
  } catch (error) {
    showMessage(
      i18next.t('BACKOFFICE.GROUPS.ASSOCIATION_MODAL_ASSOCIATE_FAILURE', {
        storeName: translatedStoreName.toLowerCase(),
      }),
      'error',
    );
  } finally {
    pageLoaded();
  }
};

const StoreAssociationModal = (props) => {
  const {
    user,
    client: { storeName, hasMultipleBrands, clientId },
    pageLoading,
    pageLoaded,
    showMessage,
    closeModal,
    params: { group, fetchClientGroups },
  } = props;

  const [stores, setClientStores] = useState([]);
  const [filteredStores, setFilteredStores] = useState([]);
  const [selectedItems, onStoresSelectedChange] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // States for filters management
  const [filters, setFilters] = useState(null);
  const [applyFilters, setApplyFilters] = useState(true);
  const [columnsFilterList, setColumnsFilterList] = useState([]);
  const [activeKeysForActiveDropdown, setActiveKeysForActiveDropdown] = useState([
    CHOICES_DROPDOWN_ACTIVE()[0],
  ]);

  const userLanguageCode = get(user, 'lnkLanguageAccountrel.code', 'fr');
  const translatedStoreName = getClientStoreNameTranslation(storeName, true);

  const headers = utilsColumns.getColumnsSettings(hasMultipleBrands);

  useEffect(() => {
    setColumnsFilterList(
      headers.filter((column) => ['date', 'string', 'numeric'].includes(column.filterType)),
    );

    (async () => {
      const stores = await getStoresOfClient(
        group,
        hasMultipleBrands,
        translatedStoreName,
        props.showMessage,
      );

      const filteredSelectedStores = stores.filter((store) =>
        activeKeysForActiveDropdown.some((key) => key.filterValue === store.active),
      );

      setClientStores(stores);
      setFilteredStores(filteredSelectedStores);
      setIsLoading(false);
    })();
  }, [group]);

  /******************************/
  /** Handle Filters List View **/
  /******************************/
  useEffect(() => {
    if (!applyFilters) {
      return;
    }

    const filteredSelectedStores = stores.filter((store) =>
      activeKeysForActiveDropdown.some((key) => key.filterValue === store.active),
    );

    setFilteredStores(filteredSelectedStores);
  }, [applyFilters]);

  return (
    <Container>
      <Header>
        <HeaderLeftPart>
          <img src="/images/inpulse/inpulse-link-black-small.svg" />
          <HeaderTitle>
            {i18next.t('BACKOFFICE.GROUPS.ASSOCIATION_MODAL_TITLE', {
              groupName: get(group, 'name'),
              storeName: translatedStoreName.toLowerCase(),
            })}
          </HeaderTitle>
        </HeaderLeftPart>
        <CloseImage src="/images/inpulse/close-black-small.svg" onClick={() => closeModal()} />
      </Header>
      <Content>
        <ListView
          columns={headers}
          data={filteredStores}
          defaultOrderBy={'name'}
          defaultOrderType={'asc'}
          disableFooter={true}
          forceEnableSelection={true}
          isLoading={isLoading}
          languageCode={userLanguageCode}
          padding={'24px 24px 0px 24px'}
          renderEmptyState={() => <EmptyState />}
          renderFilterButton={() => (
            <DeepsightFiltersButton
              advancedFilters={[]}
              applyFilters={applyFilters}
              columnsFilterList={columnsFilterList}
              customMultipleDropDowns={[
                {
                  id: 'status',
                  icon: '/images/icon-dropdown-grey.svg',
                  list: CHOICES_DROPDOWN_ACTIVE(),
                  defaultSelectedItems: [CHOICES_DROPDOWN_ACTIVE()[0]],
                  selectedItems: activeKeysForActiveDropdown,
                  setSelectedItems: (selectedItems) =>
                    setActiveKeysForActiveDropdown(selectedItems),
                },
              ]}
              filters={filters}
              isLoading={isLoading}
              readOnly={isLoading}
              setAdvancedFilters={() => null}
              setApplyFilters={setApplyFilters}
              setFilters={setFilters}
              textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
              disableAdvancedFilter
            />
          )}
          setSelectedItems={onStoresSelectedChange}
        />
      </Content>
      {!isLoading && (
        <Footer>
          <Button
            color={'inpulse-outline'}
            handleClick={() => closeModal()}
            icon="/images/inpulse/close-black-small.svg"
            label={i18next.t('GENERAL.CANCEL')}
          />
          <Button
            buttonCustomStyle={{ marginLeft: 24 }}
            color={'inpulse-default'}
            handleClick={() =>
              handleSave(
                clientId,
                group,
                selectedItems,
                fetchClientGroups,
                pageLoading,
                pageLoaded,
                showMessage,
                closeModal,
                translatedStoreName.toLowerCase(),
                props.getStoresOfUser,
              )
            }
            icon="/images/inpulse/save-white-small.svg"
            label={i18next.t('GENERAL.SAVE')}
          />
        </Footer>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  getStoresOfUser: () =>
    dispatch(getStoresOfUser()).then(
      (result) => {
        dispatch(receiveStores(result));
      },
      (error) => {
        dispatch(requestStoresError(error));
      },
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreAssociationModal);
