import { connect } from 'react-redux';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import i18next from 'i18next';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { closeGenericModal, openGenericModal, refreshGenericModal } from '@actions/modal';
import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { ENUM_ROLES_NAME, isUserDeepsight } from '@commons/utils/roles';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { ListView } from '@commons/utils/styledLibraryComponents';
import { makeXLS } from '@commons/utils/makeXLS';
import { NOTIFICATION_TYPES } from '@commons/constants/notificationTypes';
import { sortArrayOfObjectsAlphabetically } from '@commons/utils/sorting';
import EmptyState from '@commons/EmptyState';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import { ENUM_QUERY_PARAMS, useListViewQueryParams } from '@hooks/useListViewQueryParams';

import { getClientInfo } from '@selectors/client';
import { getDisableCreation } from '@selectors/featureProps';

import { checkEmailAvailability } from '@services/backoffice/onBoarding';
import { user as userService } from '@services/user';
import accountNotificationService from '@services/accountNotifications';
import catalogService from '@services/catalogs';
import clientService from '@services/client';
import storeService from '@services/store';

import DeepsightFiltersButton from '@orders/components/FilterButton';

import { Container, ListViewContainer } from './styledComponents';
import { createSheet } from './utils/export';
import {
  ENUM_MODAL_STEP_NAME,
  ENUM_WARNING_MODAL_TYPE,
  getModalConfig,
  getWarningModalConfig,
} from './utils/modalConfigurations';
import { ENUM_WHEN_IS_SENDING_MAIL } from './ModalComponents/SendInvitationModal';
import { isUserNotInvited } from './utils/status';
import utilsActions from './utils/actions';
import utilsColumns from './utils/columns';

export const BackOfficeTeamsManagement = (props) => {
  const {
    match,
    user,
    client: {
      storeName,
      hasMultipleBrands,
      clientId,
      clientName,
      hasCentralKitchens,
      hasLocalCatalogs,
    },
    disableCreation,
    openGenericModal,
    refreshGenericModal,
    pageLoaded,
    pageLoading,
    closeGenericModal,
    showErrorMessage,
    showSuccessMessage,
    clientBrands,
  } = props;

  const history = useHistory();

  const userId = get(user, 'id');

  const path = get(match, 'path');
  const isUserPath = path === '/users/team';

  const [isLoading, setIsLoading] = useState(true);
  const [userInformations, setUserInformations] = useState({
    email: '',
    roleId: null,
    catalogId: null,
    associatedStores: [],
  });
  const [currentStep, setCurrentStep] = useState(null);
  const [isEmailAvailable, setIsEmailAvailable] = useState(true);

  const translatedStoreName = getClientStoreNameTranslation(storeName, false);

  const [columns, setColumns] = useState(
    utilsColumns.getColumnsSettings({
      roles: [],
      clientStoreNameTranslation: translatedStoreName,
    }),
  );

  const [accounts, setAccounts] = useState([]);
  const [filteredAccounts, setFilteredAccounts] = useState([]);

  const [roles, setRoles] = useState([]);
  const [stores, setStores] = useState([]);
  const [catalogs, setCatalogs] = useState([]);

  /* Applied filters */
  const [selectedCatalogs, setSelectedCatalogs] = useState([]);

  const [selectedItems, setSelectedItems] = useState([]);
  const [actions, setActions] = useState(
    utilsActions.getActionsSettings({ disableCreation, selectedItems, userId }),
  );
  const [rowActions, setRowActions] = useState(utilsActions.getRowActionsSettings({ isUserPath }));

  const [filters, setFilters] = useState(null);
  const [applyFilters, setApplyFilters] = useState(true);
  const [advancedFilters, setAdvancedFilters] = useState(null);

  const [isSwitchingStep, setIsSwitchingStep] = useState(false);

  const [listViewQueryParams, setListViewQueryParams] = useListViewQueryParams();

  const fetchCatalogsOfClient = () => {
    try {
      return catalogService.getCatalogsByClientId();
    } catch {
      showErrorMessage(i18next.t('HOME.ACTIVITY_REPORT_FETCH_CATALOGS_FAILURE'));
      return [];
    }
  };

  const fetchAccountList = async (clientId) => {
    try {
      const accounts = await userService.getAccountsByClientId(clientId);
      const languages = await clientService.getAllLanguages();

      const formattedAccounts = accounts.map((account) => {
        const language = languages.find((language) => language.id === account.languageId);

        return {
          id: account.id,
          email: account.email,
          status: account.status,
          storesCount: account.storesCount,
          kitchensCount: account.kitchensCount,
          creationDate: account.creationDate,
          registrationDate: account.registrationDate,
          invitationLinkSentDate: account.invitationLinkSentDate,
          invitationLinkExpiredDate: account.invitationLinkExpiredDate,
          name:
            account.firstName && account.lastName
              ? `${account.firstName} ${account.lastName}`
              : '---',
          role: get(account, 'role.name', ''),
          catalogId: account.catalogId || -1,
          catalog: get(account, 'catalog.name', i18next.t('GENERAL.SELECT_NONE_MASCULINE')),
          hasForecasts: account.hasForecasts,
          language,
          languageName: language.name,
          hasPaymentBanner: account.hasPaymentBanner,
        };
      });

      if (!isUserPath) {
        return formattedAccounts;
      }

      return formattedAccounts.filter(
        ({ role }) =>
          ![ENUM_ROLES_NAME.API_CONSUMER, ENUM_ROLES_NAME.PUBLIC_API_CONSUMER].includes(role),
      );
    } catch {
      showErrorMessage(i18next.t('USERS.DETAILS.PROFILE_LIST_ACCOUNTS_FETCH_FAILURE'));

      return [];
    }
  };

  const fetchClientStore = async () => {
    try {
      const stores = await storeService.getStoresOfClient();

      return stores.map((store) => ({
        ...store,
        ...(hasMultipleBrands ? { brandName: get(store, 'lnkBrandStorerel.name') || '' } : {}),
        createdAt: moment(store.createdAt),
        launchDate: store.launchDate ? moment(store.launchDate) : null,
        mappedAccounts: store.mappedAccounts.length,
        isRowSelected: false,
      }));
    } catch {
      return [];
    }
  };

  const renderEmptyState = () => {
    if (!isLoading && !accounts.length) {
      return (
        <EmptyState
          icon={'/images/inpulse/no-data.svg'}
          label={i18next.t('BACKOFFICE.TEAM.NO_ACCOUNTS')}
        />
      );
    }

    if (!isLoading && !filteredAccounts.length) {
      return (
        <EmptyState
          icon={'/images/inpulse/empty-state.svg'}
          label={i18next.t('GENERAL.NO_RESULT_FROM_FILTERS')}
        />
      );
    }

    return (
      <EmptyState icon={'/images/inpulse/empty-state.svg'} label={i18next.t('GENERAL.NO_RESULT')} />
    );
  };

  const handleStoreSelection = (selectedStores) => {
    if (currentStep !== ENUM_MODAL_STEP_NAME.USER_STORE_ASSOCIATION) {
      return;
    }

    if (isSwitchingStep) {
      setIsSwitchingStep(false);
    }

    const selectedStoreIds = selectedStores.map((store) => store.id);

    updateUserInformations('associatedStores', selectedStoreIds);
  };

  const openWarningModal = (type, selectedUsers, shouldActivateForecasts = true) => {
    const params = getWarningModalConfig({
      type,
      selectedUsers,
      inviteUser,
      deleteUsers,
      shouldActivateForecasts,
      handleHasForecasts,
      handleUserPaymentNotification,
    });

    openGenericModal(params);
  };

  const handleHasForecasts = async (selectedUsers, shouldActivateForecasts) => {
    pageLoading();

    try {
      await userService.updateAccountsByIds(
        selectedUsers.map((user) => user.id),
        {
          hasForecasts: shouldActivateForecasts,
        },
      );

      const accountList = await fetchAccountList(clientId);
      setAccounts(accountList);
      setFilteredAccounts(accountList);
      showSuccessMessage(
        i18next.t(
          shouldActivateForecasts
            ? 'BACKOFFICE.DATA.FORECAST_STATUS_ENABLED'
            : 'BACKOFFICE.DATA.FORECAST_STATUS_DISABLED',
        ),
      );
    } catch {
      showErrorMessage(
        i18next.t(
          shouldActivateForecasts
            ? 'BACKOFFICE.DATA.ACTIVATE_FORECAST_ACTION_ERROR'
            : 'BACKOFFICE.DATA.DEACTIVATE_FORECAST_ACTION_ERROR',
        ),
      );
    }

    pageLoaded();
  };

  const deleteUsers = async (selectedUsers) => {
    pageLoading();

    try {
      await userService.deleteAccountByIds(selectedUsers.map((user) => user.id));

      const accountList = await fetchAccountList(clientId);
      setAccounts(accountList);
      setFilteredAccounts(accountList);
      showSuccessMessage(i18next.t('BACKOFFICE.TEAM.USER_DELETION_SUCCESS'));
    } catch {
      showErrorMessage(i18next.t('BACKOFFICE.TEAM.USER_DELETION_ERROR'));
    }

    pageLoaded();
  };

  const inviteUser = async (selectedUser) => {
    pageLoading();

    try {
      await clientService.sendInvitationEmailByAccountId(clientId, selectedUser.id);

      const accountList = await fetchAccountList(clientId);

      setAccounts(accountList);
      setFilteredAccounts(accountList);

      showSuccessMessage(
        i18next.t(
          isUserNotInvited(selectedUser)
            ? 'BACKOFFICE.TEAM.SEND_INVIATION_SUCCESS'
            : 'BACKOFFICE.TEAM.RESEND_INVIATION_SUCCESS',
        ),
      );
    } catch {
      showErrorMessage(
        i18next.t(
          isUserNotInvited(selectedUser)
            ? 'BACKOFFICE.TEAM.SEND_INVIATION_ERROR'
            : 'BACKOFFICE.TEAM.RESEND_INVIATION_ERROR',
        ),
      );
    }

    pageLoaded();
  };

  const handleUserCreationModalSave = async (userInformations) => {
    pageLoading();

    try {
      const informationsToSend = {
        ...userInformations,
        catalogId: userInformations.catalogId === -1 ? null : userInformations.catalogId,
        email: userInformations.email.toLowerCase(),
        associatedStoreIds: userInformations.associatedStores,
        sendInvitation: userInformations.isSendingEmailNow === ENUM_WHEN_IS_SENDING_MAIL.NOW,
      };

      await clientService.createAndInviteUser(clientId, informationsToSend);

      const accountList = await fetchAccountList(clientId);

      setAccounts(accountList);
      setFilteredAccounts(accountList);

      showSuccessMessage(i18next.t('BACKOFFICE.TEAM.USER_CREATION_SUCCESS'));
    } catch {
      showErrorMessage(i18next.t('BACKOFFICE.TEAM.USER_CREATION_ERROR'));
    }

    cleanUserInformation();
    closeGenericModal();

    pageLoaded();
  };

  const verifyEmailAvailability = async (email) => {
    try {
      const { available } = await checkEmailAvailability(email);
      setIsEmailAvailable(available);

      if (available) {
        setCurrentStep(ENUM_MODAL_STEP_NAME.USER_STORE_ASSOCIATION);
      }
    } catch {
      setIsEmailAvailable(false);
      showErrorMessage(i18next.t('BACKOFFICE.TEAM.FIRST_STEP_EMAIL_CHECK_ERROR'));
    }
  };

  const handleEmailChoiceChange = (userInformations, isSendingEmailNow) => {
    setUserInformations({ ...userInformations, isSendingEmailNow });
  };

  const updateUserInformations = (propertyKey, value) => {
    setUserInformations({
      ...userInformations,
      [propertyKey]: value,
    });
  };

  const openUserCreationModal = () => {
    setCurrentStep(ENUM_MODAL_STEP_NAME.USER_CREATION);
  };

  const cleanUserInformation = () => {
    const cleanedStores = stores.map((store) => ({ ...store, isRowSelected: false }));

    setStores(cleanedStores);

    setUserInformations({
      email: '',
      roleId: null,
      catalogId: null,
      associatedStores: [],
    });
    setCurrentStep(null);
  };

  useEffect(() => {
    if (!currentStep) {
      return;
    }

    const isModalOpened = get(props, 'modal.GenericModalBool', false);

    const params = getModalConfig({
      currentStep,
      roles,
      userInformations,
      isEmailAvailable,
      stores,
      handleStoreSelection,
      updateUserInformations,
      verifyEmailAvailability,
      handleUserCreationModalSave,
      handleSwitchStep: setCurrentStep,
      hasMultipleBrands,
      brands: clientBrands,
      setIsSwitchingStep,
      cleanUserInformation,
      handleEmailChoiceChange,
      countSelectedStores: userInformations.associatedStores.length,
      catalogs,
    });

    if (isModalOpened) {
      refreshGenericModal(params);
      return;
    }

    openGenericModal(params);
  }, [currentStep, isSwitchingStep, isEmailAvailable, userInformations]);

  const loadData = async () => {
    setIsLoading(true);

    try {
      const [accounts, roles, stores] = await Promise.all([
        fetchAccountList(clientId),
        clientService.getRolesByClientId(),
        fetchClientStore(),
      ]);

      if (hasLocalCatalogs) {
        const fetchedCatalogs = await fetchCatalogsOfClient();

        const filteredCatalogs = fetchedCatalogs.filter(({ active }) => active);

        setCatalogs(filteredCatalogs);

        setSelectedCatalogs([
          {
            id: -1,
            name: i18next.t('GENERAL.SELECT_NONE_MASCULINE'),
          },
          ...filteredCatalogs,
        ]);
      }

      setAccounts(accounts);
      setFilteredAccounts(accounts);

      setRoles(roles);
      setStores(stores);

      setColumns(
        utilsColumns.getColumnsSettings({
          roles,
          clientStoreNameTranslation: storeName,
          isUserPath,
          isInpulseUser: !!isUserDeepsight(user),
          hasCentralKitchens,
          hasLocalCatalogs,
        }),
      );

      setRowActions(
        utilsActions.getRowActionsSettings({
          openWarningModal,
          userId: userId,
          isUserPath,
          handleOpenUserDetail,
          isInpulseUser: !!isUserDeepsight(user),
          handleExport,
        }),
      );
    } catch {
      showErrorMessage(i18next.t('GENERAL.GLOBAL_FETCH_ERROR'));
    } finally {
      setIsLoading(false);
    }
  };

  /*********************************/
  /** FETCH DATA & INITIALISATION **/
  /*********************************/
  useEffect(() => {
    loadData();
  }, [user]);

  /************************************************/
  /** UPDATE ACTIONS WHEN ROLES OR STORES CHANGES **/
  /**************************************************/

  useEffect(() => {
    if (!user || !clientId) {
      return;
    }

    setActions(
      utilsActions.getActionsSettings({
        isLoading,
        disableCreation,
        openUserCreationModal,
        selectedItems,
        userId,
        openWarningModal,
        isInpulseUser: !!isUserDeepsight(user),
        accounts: filteredAccounts,
        handleExport,
        isUserPath,
      }),
    );
  }, [user, roles, stores, isLoading, selectedItems]);

  /******************************/
  /** HANDLE FILTERS LIST VIEW **/
  /******************************/
  useEffect(() => {
    if (advancedFilters && advancedFilters.length) {
      advancedFilters[0].list = sortArrayOfObjectsAlphabetically(advancedFilters[0].list, 'name');
    }

    if (!applyFilters) {
      return;
    }

    const filteredAccounts = !hasLocalCatalogs
      ? accounts
      : accounts.filter(({ catalogId }) => selectedCatalogs.some(({ id }) => id === catalogId));

    if (!advancedFilters || !advancedFilters.length) {
      setFilteredAccounts(filteredAccounts);

      return;
    }

    const filteredAccountListWithAdvancedFilters = advancedFilters.reduce(
      (result, { doFilter, propertyKey, value }) => doFilter(result, propertyKey, value),
      filteredAccounts,
    );

    setFilteredAccounts(filteredAccountListWithAdvancedFilters);
  }, [applyFilters, advancedFilters, selectedCatalogs]);

  const handleExport = (accounts) => {
    const sheet = createSheet(accounts, clientName, hasLocalCatalogs);
    makeXLS(sheet.title, [sheet]);
  };

  const handleOpenUserDetail = (user) => {
    if (!isUserPath) {
      return;
    }

    history.push({
      pathname: '/users/' + user.id + '/details',
    });
  };

  const handleUserPaymentNotification = async (selectedUsers, actionType) => {
    pageLoading();

    const accountNotificationsServiceFunctions = {
      [ENUM_WARNING_MODAL_TYPE.SHOW_PAYMENT_BANNER]:
        accountNotificationService.createUsersNotificationsByBatch,
      [ENUM_WARNING_MODAL_TYPE.HIDE_PAYMENT_BANNER]:
        accountNotificationService.deleteUsersNotificationsByBatch,
    };

    try {
      const selectedUserIds = selectedUsers.map(({ id }) => id);

      await accountNotificationsServiceFunctions[actionType](
        NOTIFICATION_TYPES.PAYMENT,
        selectedUserIds,
      );

      const accountList = await fetchAccountList(clientId);

      setAccounts(accountList);
      setFilteredAccounts(accountList);

      showSuccessMessage(
        i18next.t(
          actionType === ENUM_WARNING_MODAL_TYPE.SHOW_PAYMENT_BANNER
            ? 'BACKOFFICE.TEAM.SHOW_PAYMENT_BANNER_SUCCESS'
            : 'BACKOFFICE.TEAM.HIDE_PAYMENT_BANNER_SUCCESS',
        ),
      );
    } catch {
      showErrorMessage(
        i18next.t(
          actionType === ENUM_WARNING_MODAL_TYPE.SHOW_PAYMENT_BANNER
            ? 'BACKOFFICE.TEAM.SHOW_PAYMENT_BANNER_ERROR'
            : 'BACKOFFICE.TEAM.HIDE_PAYMENT_BANNER_ERROR',
        ),
      );
    } finally {
      pageLoaded();
    }
  };

  const getCustomMultipleDropdowns = () => {
    const filters = [];

    const catalogsWithNoneValue = [
      {
        id: -1,
        name: i18next.t('GENERAL.SELECT_NONE_MASCULINE'),
      },
      ...catalogs,
    ];

    filters.push({
      id: 'catalog',
      icon: '/images/inpulse/menu-book-black-small.svg',
      list: catalogsWithNoneValue,
      defaultSelectedItems: selectedCatalogs,
      selectedItems: selectedCatalogs,
      setSelectedItems: setSelectedCatalogs,
    });

    return filters;
  };

  return (
    <Container lowerMargin={isUserPath}>
      <NavigationBreadCrumb featurePath={path} />
      <ListViewContainer>
        <ListView
          actionOnClick={(user) => handleOpenUserDetail(user)}
          actions={actions}
          columns={columns}
          data={filteredAccounts}
          defaultCurrentPage={listViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE]}
          defaultMaxPerPage={listViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE]}
          defaultOrderBy={listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY]}
          defaultOrderType={listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE]}
          defaultSearchInput={listViewQueryParams[ENUM_QUERY_PARAMS.SEARCH]}
          handleCurrentPageChange={(input) =>
            setListViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE](input)
          }
          handleMaxPerPageChange={(input) =>
            setListViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE](input)
          }
          handleOrderByChange={(input) => setListViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY](input)}
          handleOrderTypeChange={(input) =>
            setListViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE](input)
          }
          handleSearchInputChange={(input) =>
            setListViewQueryParams[ENUM_QUERY_PARAMS.SEARCH](input)
          }
          isLoading={isLoading}
          languageCode={get(user, 'lnkLanguageAccountrel.code', 'fr')}
          padding={'24px 24px 0px 24px'}
          placeholderShape={i18next.t('GENERAL.SEARCH')}
          renderEmptyState={() => renderEmptyState()}
          renderFilterButton={() => (
            <DeepsightFiltersButton
              advancedFilters={advancedFilters}
              applyFilters={applyFilters}
              columnsFilterList={columns.filter((column) => !!column.filterType)}
              customMultipleDropDowns={hasLocalCatalogs ? getCustomMultipleDropdowns() : null}
              filters={filters}
              isLoading={isLoading}
              minWidth={120}
              readOnly={isLoading}
              setAdvancedFilters={(it) => setAdvancedFilters(it)}
              setApplyFilters={(it) => setApplyFilters(it)}
              setFilters={(it) => setFilters(it)}
              textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
            />
          )}
          rowActions={rowActions}
          setSelectedItems={(items) => setSelectedItems(items)}
        />
      </ListViewContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  modal: state.modalReducer,
  disableCreation: getDisableCreation(state.baseReducer.userRights),
  client: getClientInfo(state.baseReducer.user),
  clientBrands: state.baseReducer.brands,
});

const mapDispatchToProps = (dispatch) => ({
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  closeGenericModal: (params) => {
    dispatch(closeGenericModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeTeamsManagement);
