import i18next from 'i18next';
import moment from 'moment-timezone';
import React from 'react';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { InpulseLabel } from '@commons/DeepsightComponents';
import DisplayNumber from '@commons/DisplayNumber';
import Text, { ENUM_COLORS } from '@commons/Text';

import {
  preparationStatusLegend,
  preparationStatusColorCodes,
} from '@orders/OrderList/common/constants';

export const get = () => [
  {
    id: 'reference',
    propertyKey: 'reference',
    name: i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ASSOCIATED_ORDER_COLUMN_REFERENCE'),
    render: (reference) => <Text color={ENUM_COLORS.DARKEST}>{reference}</Text>,
  },
  {
    id: 'startOrderDate',
    filterType: 'date',
    name: i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ASSOCIATED_ORDER_COLUMN_DELIVERY_DATE'),
    propertyKey: 'startOrderDate',
    render: (date) => (
      <Text color={ENUM_COLORS.DARKEST}>
        {moment(date).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR)}
      </Text>
    ),
  },
  {
    id: 'totalPrice',
    name: i18next.t('ORDERS.ORDERS.LIST_LABEL_TOTAL_EX_TAX_BDL'),
    propertyKey: 'totalPrice',
    filterType: 'numeric',
    render: (totalPrice) => <DisplayNumber number={totalPrice} displayCurrencyCode />,
  },
  {
    id: 'preparationStatus',
    propertyKey: 'preparationStatus',
    filterType: 'string',
    name: i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ASSOCIATED_ORDER_COLUMN_STATUS'),
    list: Object.keys(preparationStatusLegend).reduce((result, key) => {
      result.push({
        id: key,
        status: key,
        itemValue: key,
        name: i18next.t(preparationStatusLegend[key].name),
        renderValue: () => {
          const statusColor = preparationStatusColorCodes.find(({ id }) => id == key);

          return (
            <div className="supplier-label-render">
              <InpulseLabel
                color={statusColor.inpulseColor}
                style={{
                  padding: '0',
                  border: 'none',
                  backgroundColor: 'transparent',
                }}
                text={i18next.t(preparationStatusLegend[key].name)}
                type={'tertiary'}
              />
            </div>
          );
        },
      });

      return result;
    }, []),
    render: (status) => {
      const label = Object.keys(preparationStatusLegend).includes(status)
        ? i18next.t(preparationStatusLegend[status].name)
        : i18next.t('ORDERS.ORDERS.LIST_LABEL_STATUS_NOT_DEFINED');

      const statusColor = preparationStatusColorCodes.find(({ id }) => id == status);

      return <InpulseLabel color={statusColor.inpulseColor} text={label} type={'tertiary'} />;
    },
  },
];

export default { get };
