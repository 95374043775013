import i18next from 'i18next';

import normalizeStringValue from '@commons/utils/normalizeStringValue';

const SHEET = {
  id: 'entities',
  name: 'Ingrédients',
};

const COLUMN_IDS = {
  CATEGORY: 'category',
  ID: 'id',
  NAME: 'name',
  UNIT: 'unit',
  COST: 'cost',
  IS_STRATEGIC: 'isStrategic',
};

const HIDDEN_SHEET_CATEGORY = {
  ID: 'categories',
  NAME: 'Catégories',
};

const HIDDEN_CATEGORY_COLUMN_ID = {
  NAME: 'name',
};

const getHiddenSheetCategory = () => ({
  SHEET: {
    ID: 'categories',
    NAME: i18next.t('_BACKEND_TRANSLATIONS.ENTITIES.INGREDIENTS_IMPORTS.CATEGORIES_SHEET_NAME'),
  },
  COLUMN_ID: {
    NAME: 'name',
  },
  NORMALIZE_COLUMN_ID: {
    NAME: normalizeStringValue(
      i18next.t('_BACKEND_TRANSLATIONS.ENTITIES.INGREDIENTS_IMPORTS.CATEGORY_COLUMN'),
    ),
  },
});

const getHiddenSheetAllergen = () => ({
  SHEET: {
    ID: 'allergens',
    NAME: i18next.t('_BACKEND_TRANSLATIONS.ENTITIES.INGREDIENTS_IMPORTS.ALLERGENS_SHEET_NAME'),
  },
  COLUMN_ID: {
    NAME: 'name',
    ID: 'id',
  },
  NORMALIZE_COLUMN_ID: {
    ID: 'id',
    NAME: normalizeStringValue(
      i18next.t('_BACKEND_TRANSLATIONS.ENTITIES.INGREDIENTS_IMPORTS.ALLERGENS_COLUMN'),
    ),
  },
});

const normalizeKeys = (object) =>
  Object.entries(object).reduce((acc, [key, value]) => {
    acc[key] = normalizeStringValue(value);
    return acc;
  }, {});

const getSheetConfig = () => {
  const columns = {
    NAME: i18next.t('_BACKEND_TRANSLATIONS.ENTITIES.INGREDIENTS_IMPORTS.NAME_COLUMN'),
    CATEGORY: i18next.t('_BACKEND_TRANSLATIONS.ENTITIES.INGREDIENTS_IMPORTS.CATEGORY_COLUMN'),
    ID: i18next.t('_BACKEND_TRANSLATIONS.ENTITIES.INGREDIENTS_IMPORTS.ID_COLUMN'),
    UNIT: i18next.t('_BACKEND_TRANSLATIONS.ENTITIES.INGREDIENTS_IMPORTS.UNIT_COLUMN'),
    COST: i18next.t(
      '_BACKEND_TRANSLATIONS.ENTITIES.INGREDIENTS_IMPORTS.COST_EXCLUDING_TAXES_COLUMN',
    ),
    IS_STRATEGIC: i18next.t(
      '_BACKEND_TRANSLATIONS.ENTITIES.INGREDIENTS_IMPORTS.IS_STRATEGIC_COLUMN',
    ),
  };

  const normalizedColumns = normalizeKeys(columns);

  return {
    SHEET: {
      id: 'entities',
      name: i18next.t('_BACKEND_TRANSLATIONS.ENTITIES.INGREDIENTS_IMPORTS.INGREDIENTS_SHEET_NAME'),
    },
    COLUMN_ID: {
      CATEGORY: 'category',
      ID: 'id',
      NAME: 'name',
      UNIT: 'unit',
      COST: 'cost',
      IS_STRATEGIC: 'isStrategic',
    },
    COLUMNS_CONFIG: normalizedColumns,
  };
};

export default {
  SHEET,
  COLUMN_IDS,
  HIDDEN_SHEET_CATEGORY,
  HIDDEN_CATEGORY_COLUMN_ID,
  getSheetConfig,
  getHiddenSheetCategory,
  getHiddenSheetAllergen,
};
