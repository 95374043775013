import i18next from 'i18next';

import {
  GENERIC_MODAL_ASSOCIATE_BUTTON,
  GENERIC_MODAL_CANCEL_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { getClientStoreNameTranslation } from '@commons/utils/translations';

import AccountMappingModal from './index';

export const getAccountMappingModalConfig = ({
  accountList,
  accountsToMap,
  setAccountsToMap,
  storeId,
  handleCreateMappings,
  storeName,
}) => ({
  type: 'actionWhite',
  isFullscreen: true,
  title: i18next.t('USERS.DETAILS.STORES_LIST_ASSOCIATION_MODAL_TITLE', {
    storeName: getClientStoreNameTranslation(storeName, true).toLowerCase(),
  }),
  icon: '/images/inpulse/add-black-small.svg',
  customPadding: '24px 24px 0px 24px',
  component: AccountMappingModal,
  data: {
    accountList,
    setAccountsToMap,
    storeId,
  },
  actions: [
    GENERIC_MODAL_CANCEL_BUTTON(),
    {
      ...GENERIC_MODAL_ASSOCIATE_BUTTON(),
      icon: '/images/inpulse/add-white-small.svg',
      isDisabled: !accountsToMap.length,
      handleClick: () => {
        handleCreateMappings(accountsToMap);
      },
    },
  ],
});

export default getAccountMappingModalConfig;
