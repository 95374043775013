import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 280px);
  display: flex;
  position: relative;
`;

export const FilterButtonContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 24px;
`;
