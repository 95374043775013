import { connect } from 'react-redux';
import { get, upperFirst, isEmpty } from 'lodash';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { showSuccessMessage, showErrorMessage } from '@actions/messageconfirmation';

import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { InpulseLabel } from '@commons/DeepsightComponents';
import { ListView, Button } from '@commons/utils/styledLibraryComponents';
import EmptyState from '@commons/EmptyState';

import { getClientInfo } from '@selectors/client';

import { cashierStoreService } from '@services/cashierStore';
import clientService from '@services/client';
import storeService from '@services/store';

import { LabelContainer } from '@backoffice/BackOfficeStoresContainer/BackOfficeGroupsManagement/styledComponents';

import {
  Content,
  Container,
  Header,
  HeaderTitle,
  CloseImage,
  HeaderLeftPart,
  Footer,
} from './styledComponents';
import utilsColumns from './utils/columns';

const isStoreAlreadyAssociated = (cashierStores, mappedCashierStoreIds) => {
  const cashierStoreIds = cashierStores.map(({ id }) => id);
  return cashierStoreIds.every((cashierStoreId) => mappedCashierStoreIds.includes(cashierStoreId));
};

const getStoresOfClient = async (
  cashierStores,
  hasMultipleBrands,
  translatedStoreName,
  showErrorMessage,
  onStoresSelectedChange,
) => {
  try {
    const stores = await storeService.getStoresOfClient();

    return stores.map((store) => {
      const isStoreAssociated = isStoreAlreadyAssociated(cashierStores, store.mappedCashierStores);
      const formattedStore = {
        ...store,
        ...(hasMultipleBrands ? { brandName: get(store, 'lnkBrandStorerel.name') || '' } : {}),
        active: store.active ? i18next.t('GENERAL.ACTIVE') : i18next.t('GENERAL.INACTIVE'),
        isRowSelected: isStoreAssociated,
      };

      if (isStoreAssociated) {
        onStoresSelectedChange([formattedStore]);
      }

      return formattedStore;
    });
  } catch (err) {
    showErrorMessage(
      i18next.t('GENERAL.STORES_CLIENT_FETCH_FAILURE', {
        storeName: translatedStoreName,
      }),
    );

    return [];
  }
};

const handleSave = async (
  cashierStores,
  selectedItems,
  handleGetCashierStores,
  pageLoading,
  pageLoaded,
  closeModal,
  translatedStoresName,
  showErrorMessage,
  showSuccessMessage,
) => {
  pageLoading();

  // Check if is disassociating
  if (isEmpty(selectedItems) && cashierStores.length === 1) {
    try {
      const cashierStoreId = get(cashierStores, '[0].id', null);
      await cashierStoreService.deleteStoreCashierStoreMappingByCashierStoreId(cashierStoreId);

      await handleGetCashierStores();

      showSuccessMessage(
        i18next.t('BACKOFFICE.CASHIER_STORES.SINGLE_ASSOCIATION_MODAL_DISSOCIATE_SUCCESS'),
      );
      closeModal();
    } catch {
      showErrorMessage(
        i18next.t('BACKOFFICE.CASHIER_STORES.SINGLE_ASSOCIATION_MODAL_DISSOCIATE_ERROR'),
      );
    } finally {
      pageLoaded();
    }
    return;
  }

  try {
    const selectedItemsIds = selectedItems.map((store) => store.id);
    await clientService.createCashierStoreStoreCashierStoreMappings(
      cashierStores.map((store) => store.id),
      selectedItemsIds,
    );

    await handleGetCashierStores();

    showSuccessMessage(
      i18next.t('BACKOFFICE.CASHIER_STORES.SINGLE_ASSOCIATION_MODAL_ASSOCIATE_SUCCESS', {
        storeName: upperFirst(translatedStoresName.toLowerCase()),
      }),
    );

    closeModal();
  } catch (error) {
    showErrorMessage(
      i18next.t('BACKOFFICE.CASHIER_STORES.SINGLE_ASSOCIATION_MODAL_ASSOCIATE_FAILURE', {
        storeName: translatedStoresName.toLowerCase(),
      }),
    );
  } finally {
    pageLoaded();
  }
};

const renderNumberOfStores = (item) => (
  <LabelContainer>
    <InpulseLabel color="black" text={String(get(item, 'length', 0))} />
  </LabelContainer>
);
const StoreAssociationModal = (props) => {
  const {
    user,
    client: { storeName, hasMultipleBrands },
    pageLoading,
    pageLoaded,
    closeModal,
    params: { cashierStores, handleGetCashierStores },
    showErrorMessage,
    showSuccessMessage,
  } = props;

  const [stores, setClientStores] = useState([]);
  const [selectedItems, onStoresSelectedChange] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const userLanguageCode = get(user, 'lnkLanguageAccountrel.code', 'fr');
  const translatedStoresName = getClientStoreNameTranslation(storeName, true);
  const translatedStoreName = getClientStoreNameTranslation(storeName, false);

  const headers = utilsColumns.getColumnsSettings(translatedStoresName);

  useEffect(() => {
    (async () => {
      const stores = await getStoresOfClient(
        cashierStores,
        hasMultipleBrands,
        translatedStoresName,
        showErrorMessage,
        onStoresSelectedChange,
      );

      setClientStores(stores);

      setIsLoading(false);
    })();
  }, [cashierStores]);

  return (
    <Container>
      <Header>
        <HeaderLeftPart>
          <img src="/images/inpulse/inpulse-link-black-small.svg" />
          <HeaderTitle>
            {i18next.t(
              cashierStores.length > 1
                ? 'BACKOFFICE.CASHIER_STORES.MULTIPLE_ASSOCIATION_MODAL_TITLE_MULTIPLE'
                : 'BACKOFFICE.CASHIER_STORES.MULTIPLE_ASSOCIATION_MODAL_TITLE_SINGLE',

              cashierStores.length > 1
                ? { storeName: translatedStoresName.toLowerCase() }
                : { storeName: translatedStoreName.toLowerCase() },
            )}
            {renderNumberOfStores(cashierStores)}
          </HeaderTitle>
        </HeaderLeftPart>
        <CloseImage src="/images/inpulse/close-black-small.svg" onClick={() => closeModal()} />
      </Header>
      <Content>
        <ListView
          columns={headers}
          data={stores}
          defaultOrderBy={'name'}
          defaultOrderType={'asc'}
          disableFooter={true}
          disableMultipleSelection={true}
          forceEnableSelection={true}
          isLoading={isLoading}
          languageCode={userLanguageCode}
          padding={'24px 24px 0px 24px'}
          renderEmptyState={() => <EmptyState />}
          setSelectedItems={onStoresSelectedChange}
        />
      </Content>
      {!isLoading && (
        <Footer>
          <Button
            color={'inpulse-outline'}
            handleClick={() => closeModal()}
            icon="/images/inpulse/close-black-small.svg"
            label={i18next.t('GENERAL.CANCEL')}
          />
          <Button
            buttonCustomStyle={{ marginLeft: 24 }}
            color={'inpulse-default'}
            handleClick={() =>
              handleSave(
                cashierStores,
                selectedItems,
                handleGetCashierStores,
                pageLoading,
                pageLoaded,
                closeModal,
                translatedStoresName.toLowerCase(),
                showErrorMessage,
                showSuccessMessage,
              )
            }
            icon="/images/inpulse/save-white-small.svg"
            isDisabled={isEmpty(selectedItems) && cashierStores.length > 1}
            label={i18next.t('GENERAL.SAVE')}
          />
        </Footer>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreAssociationModal);
