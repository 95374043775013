import i18next from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';

import { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';
import DisplayNumber from '@commons/DisplayNumber';
import PaginatedSupplierProductsDropdown from '@commons/PaginatedSupplierProductsDropdown';

import {
  Container,
  DropdownContainer,
  SupplierProductDeletionContentContainer,
  SupplierProductDetailsContainer,
  SupplierProductPriceContainer,
} from './styledComponents';

const SupplierProductDetails = ({ supplierProductToAdd }) => {
  const spPackagingName = supplierProductToAdd.packagings.find(
    (packaging) => packaging.isUsedInInvoice,
  ).name;
  const spPrice = supplierProductToAdd.price;

  return (
    <SupplierProductDetailsContainer>
      <div>
        {i18next.t('GENERAL.PACKAGING')} : {spPackagingName}
      </div>
      <SupplierProductPriceContainer>
        {i18next.t('ORDERS.ORDERS.LIST_LABEL_PRICE_EX_TAX')} :
        <DisplayNumber
          color={ENUM_COLORS.DARKER}
          displayCurrencyCode={true}
          font={ENUM_FONTS.TEXT_SMALL}
          number={spPrice}
        />
      </SupplierProductPriceContainer>
    </SupplierProductDetailsContainer>
  );
};

/**
 * First of the 2-step modal to add a SP to a transfer between two stores.
 * Allows to select a SP mapped to the sender store.
 * Displays a Dropdown with items showing both their name and supplier name.
 * When a SP is selected, also displays its order packaging, price.
 *
 * @param {PropTypes} props
 */
export const AddSupplierProductModal = (props) => {
  const {
    supplierProductToAdd,
    setSupplierProductToAdd,
    setStocksOfSupplierProductToAdd,
    suppliers,
    selectedSupplierProducts,
    senderStoreId,
  } = props;

  const selectedSupplierProductsIds = selectedSupplierProducts.map(({ id }) => id);
  const supplierIds = suppliers.map(({ id }) => id);

  return (
    <Container>
      <DropdownContainer>
        <PaginatedSupplierProductsDropdown
          customStyle={{ position: 'inherit' }} // for the list to appear over the modal (instead of inside)
          filterByUserCatalog={false}
          label={i18next.t('GENERAL.SUPPLIER_PRODUCT')}
          selectedSupplierProduct={supplierProductToAdd}
          spIdsToExclude={selectedSupplierProductsIds}
          storeId={senderStoreId}
          supplierIds={supplierIds}
          width={'360px'}
          isRequired
          onSelectionChange={(selectedItem) => {
            const totByPackagingId = selectedItem.packagings.reduce((acc, packaging) => {
              acc[packaging.id] = null;
              return acc;
            }, {});

            setSupplierProductToAdd({ ...selectedItem, totByPackagingId });
            setStocksOfSupplierProductToAdd(totByPackagingId);
          }}
        />
      </DropdownContainer>
      {supplierProductToAdd && (
        <SupplierProductDetails supplierProductToAdd={supplierProductToAdd} />
      )}
    </Container>
  );
};

AddSupplierProductModal.propTypes = {
  supplierProductToAdd: PropTypes.object,
  setSupplierProductToAdd: PropTypes.func,
  setStocksOfSupplierProductToAdd: PropTypes.func,
};

export const RemoveSupplierProduct = () => (
  <SupplierProductDeletionContentContainer>
    {i18next.t('STOCKS.TRANSFERS.REMOVE_SUPPLIER_PRODUCT_CONTENT')}
  </SupplierProductDeletionContentContainer>
);
