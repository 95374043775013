import i18next from 'i18next';

export const DETAILS = {
  isFullScreen: true,
  shouldReplacePath: true,
  getText: () => i18next.t('FEATURE.INVOICE.INVOICE_CONTROL_DETAILS_INFORMATION'),
  path: '/invoice/invoice-controls/:id/details',
  getLink: ({ invoiceControlList }) => `/invoice/invoice-controls/${invoiceControlList.id}/details`,
};

export const LINE_BY_LINE = {
  isFullScreen: true,
  shouldReplacePath: true,
  getText: () => i18next.t('FEATURE.INVOICE.INVOICE_CONTROL_DETAILS_LINE_BY_LINE'),
  path: '/invoice/invoice-controls/:id/line-by-line',
  getLink: ({ invoiceControlList }) =>
    `/invoice/invoice-controls/${invoiceControlList.id}/line-by-line`,
  isDisabled: ({ hasAssociatedOrders }) => !hasAssociatedOrders,
};

export const BATCH_IMPORT = {
  isFullScreen: true,
  getText: () => i18next.t('FEATURE.INVOICE.INVOICE_CONTROL_BATCH_IMPORT'),
  path: '/invoice/invoice-controls/batch-import',
  isVisible: () => false,
};

export const INTERNAL_INVOICE_CREATE = {
  isFullScreen: true,
  getText: () => i18next.t('FEATURE.INVOICE.INVOICE_CONTROL_DETAILS'),
  path: '/invoice/internal-invoices/create',
  isVisible: () => true,
};

export const INTERNAL_INVOICE_DETAILS = {
  isFullScreen: true,
  getText: () => i18next.t('ADMIN.SUB_FEATURES.INFORMATIONS'),
  path: '/invoice/internal-invoices/:id/details',
  getLink: ({ internalInvoiceId }) => `/invoice/invoice-controls/${internalInvoiceId}/details`,
  isVisible: () => false,
};
