import { isEmpty } from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';

export const getListActions = ({
  filteredInternalInvoices,
  selectedInternalInvoices,
  handleExportPDF,
  handleExportXLS,
  goToCreateInvoice,
  currency,
}) => {
  const areItemsSelected = !isEmpty(selectedInternalInvoices);

  const actions = [
    {
      id: 'create',
      actionLabel: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_ACTION_NEW_CONTROL'),
      fixed: true,
      handleAction: () => {},
      render: () => (
        <Button
          color={'inpulse-default'}
          handleClick={goToCreateInvoice}
          icon="/images/inpulse/add-white-small.svg"
          label={i18next.t('GENERAL.CREATE')}
        />
      ),
    },
    {
      id: 'export_list',
      actionLabel: i18next.t(areItemsSelected ? 'GENERAL.EXPORT_SELECTION' : 'GENERAL.EXPORT_LIST'),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
      handleAction: () =>
        handleExportXLS(
          areItemsSelected ? selectedInternalInvoices : filteredInternalInvoices,
          currency,
        ),
    },
    {
      id: 'export-pdf',
      actionLabel: i18next.t('INVOICE.INTERNAL_INVOICES.EXPORT_TO_PDF'),
      handleAction: () =>
        handleExportPDF(areItemsSelected ? selectedInternalInvoices : filteredInternalInvoices),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
    },
  ];

  return actions;
};

export const getRowActions = ({ handleExportPDF }) => [
  {
    id: 'export-pdf',
    actionLabel: () => i18next.t('INVOICE.INTERNAL_INVOICES.DOWNLOAD'),
    actionIcon: () => '/images/inpulse/file-download-black-small.svg',
    handleAction: (item) => handleExportPDF([item]),
  },
];

export default {
  getListActions,
  getRowActions,
};
