import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

// ACTIONS
import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

// COMMONS
import { buildSchema } from '@commons/GenericForm';
import { getTheme } from '@commons/utils/theme';
import Footer from '@commons/Footer/Footer';
import NavigationBar from '@commons/NavigationBar';
import Text, { ENUM_FONTS } from '@commons/Text';

// SELECTORS
import { getClientInfo } from '@selectors/client';

// SERVICES
import centralService from '@services/central';
import internalInvoiceService from '@services/internalInvoice';
import storeService from '@services/store';

import navigationUtils from './navigation.utils';

import {
  INTERNAL_INVOICE_FORM_INPUTS,
  INTERNAL_INVOICE_INPUTS,
  STEPS_FIELDS_TO_CHECK,
} from '../utils/formInputsConfigurations';
import InternalInvoiceInformation from '../internalInvoiceInformation';
import InternalInvoiceSummary from '../internalInvoiceSummary';

import {
  ButtonsContainer,
  ContentContainer,
  LabelContainer,
  LabelIcon,
  PageContainer,
  Step,
  StepsContainer,
} from './styledComponents';
import { formatNumberWithCurrency } from '@commons/DisplayNumber';
import { getCentralKitchenStores } from '@selectors/stores';

const STEP_1_DETAILS = 'details';
const STEP_2_SUMMARY = 'summary';

const InternalInvoiceCreation = (props) => {
  const {
    match: { path },
    centralKitchens,
    history,
    showSuccessMessage,
    showErrorMessage,
    pageLoading,
    pageLoaded,
    currency,
    user,
  } = props;

  const theme = getTheme();

  const today = moment();

  const STEPS_INTERNAL_INVOICE_CREATION = [
    { key: STEP_1_DETAILS, label: i18next.t('GENERAL.INFORMATIONS') },
    {
      key: STEP_2_SUMMARY,
      label: i18next.t('GENERAL.SUMMARY'),
    },
  ];
  const STEPS_MAX_INDEX = STEPS_INTERNAL_INVOICE_CREATION.length - 1;

  const [invoiceNumber, setInvoiceNumber] = useState(null);

  // Allows to easily know, for each kitchen, its supplierId and supplied salespoints
  const [supplyInfoByKitchen, setSupplyInfoByKitchen] = useState(null);
  // List of salespoints for the dropdown, based on the selected kitchen
  const [suppliedSalesPointStores, setSuppliedSalesPointStores] = useState([]);

  const [associatedOrders, setAssociatedOrders] = useState([]);

  const [emailRecipients, setEmailRecipients] = useState([]);
  const [emailRecipientsCopy, setEmailRecipientsCopy] = useState([]);

  const allInputs = [
    ...INTERNAL_INVOICE_FORM_INPUTS({
      centralKitchens,
      salesPointStores: suppliedSalesPointStores,
      invoiceDate: today,
      invoiceNumber,
      isCreation: true,
    }),
  ];

  const internalInvoiceForm = useForm({
    defaultValues: {},
    resolver: yupResolver(buildSchema(allInputs)),
  });

  const formFields = useWatch({
    control: internalInvoiceForm.control,
  });

  const [isSaveAlreadyTriggered, setIsSaveAlreadyTriggered] = useState(false);
  const [currentStep, setCurrentStep] = useState(STEPS_INTERNAL_INVOICE_CREATION[0].key);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  // By default, assume the billed store has invoice information
  const [billedStoreHasInvoiceInformation, setBilledStoreHasInvoiceInformation] = useState(true);

  /** USE EFFECTS */
  useEffect(() => {
    (async () => {
      switch (currentStep) {
        case STEPS_INTERNAL_INVOICE_CREATION[0].key:
          const fieldsValidation = await internalInvoiceForm.trigger(
            STEPS_FIELDS_TO_CHECK[currentStep],
          );

          setIsSaveDisabled(
            (!fieldsValidation || !billedStoreHasInvoiceInformation || isEmpty(associatedOrders)) &&
              isSaveAlreadyTriggered,
          );
          break;
        case STEPS_INTERNAL_INVOICE_CREATION[1].key:
          setIsSaveDisabled(isEmpty(emailRecipients));
          break;
        default:
          return;
      }
    })();
  }, [formFields, emailRecipients]);

  useEffect(() => {
    pageLoading();

    (async () => {
      try {
        const invoiceNumber = await internalInvoiceService.getNewInvoiceNumber();

        const kitchenStoreIds = centralKitchens.map(({ id }) => id);
        const supplyInfoOfKitchens = await centralService.getSalesPointsByKitchenStoreIds(
          kitchenStoreIds,
        );

        setSupplyInfoByKitchen(supplyInfoOfKitchens);

        setInvoiceNumber(invoiceNumber);
        internalInvoiceForm.setValue(INTERNAL_INVOICE_INPUTS.NUMBER, invoiceNumber);
      } catch {
        setSupplyInfoByKitchen({});
        setInvoiceNumber('');
        internalInvoiceForm.setValue(INTERNAL_INVOICE_INPUTS.NUMBER, '');
        showErrorMessage(i18next.t('INVOICE.INTERNAL_INVOICES.INTERNAL_INVOICES_INIT_ERROR'));
      } finally {
        pageLoaded();
      }
    })();
  }, []);

  useEffect(() => {
    // Reset associated orders when changing issuer store
    setAssociatedOrders([]);

    const selectedIssuer = internalInvoiceForm.getValues(INTERNAL_INVOICE_INPUTS.ISSUER);
    if (!selectedIssuer) {
      return;
    }

    const suppliedSalesPointStores = get(
      supplyInfoByKitchen,
      [selectedIssuer.id, 'suppliedSalesPointStores'],
      null,
    );

    internalInvoiceForm.resetField(INTERNAL_INVOICE_INPUTS.BILLED);

    if (!suppliedSalesPointStores) {
      showErrorMessage(
        i18next.t('INVOICE.INTERNAL_INVOICES.INTERNAL_INVOICES_NO_STORES_MAPPED_TO_CENTRAL_ERROR'),
      );

      setSuppliedSalesPointStores([]);

      return;
    }

    setSuppliedSalesPointStores(supplyInfoByKitchen[selectedIssuer.id].suppliedSalesPointStores);
  }, [internalInvoiceForm.watch(INTERNAL_INVOICE_INPUTS.ISSUER)]);

  // Retrieve billed store's invoice email addresses when changing it
  useEffect(() => {
    if (!selectedBilledStore) {
      return;
    }

    // Reset associated orders when changing billed store
    setAssociatedOrders([]);
    (async () => {
      try {
        const selectedBilledStore =
          internalInvoiceForm.getValues(INTERNAL_INVOICE_INPUTS.BILLED) || null;

        await checkBilledStoreInvoiceInformation(selectedBilledStore);

        const billedStore = await storeService.getById(selectedBilledStore.id);
        const userEmail = get(user, 'email', '');

        setEmailRecipients(billedStore.email);
        setEmailRecipientsCopy(billedStore.cc ? `${billedStore.cc},${userEmail}` : userEmail);
      } catch {
        showErrorMessage(
          i18next.t('INVOICE.INTERNAL_INVOICES.INTERNAL_INVOICES_ISSUER_FETCH_ERROR'),
        );
      }
    })();
  }, [internalInvoiceForm.watch(INTERNAL_INVOICE_INPUTS.BILLED)]);

  useEffect(() => {
    if (!associatedOrders.length) {
      internalInvoiceForm.setValue(INTERNAL_INVOICE_INPUTS.TOTAL, '');
      return;
    }

    const total = associatedOrders.reduce((acc, { totalPrice }) => acc + totalPrice, 0);

    const formattedTotal = `${formatNumberWithCurrency({
      displayCurrencyCode: true,
      withoutDecimals: false,
      number: total,
      currency,
    })}`;

    internalInvoiceForm.setValue(INTERNAL_INVOICE_INPUTS.TOTAL, formattedTotal);
  }, [associatedOrders]);

  // Not the cleanest, but it's the only way to trigger the error message when the store has no invoice information
  useEffect(() => {
    const billedStoreError = get(
      internalInvoiceForm.formState.errors,
      INTERNAL_INVOICE_INPUTS.BILLED,
      null,
    );

    // The error in billed input might be cleared since technically there is a value and yup overrule this
    if (!billedStoreHasInvoiceInformation && !billedStoreError) {
      internalInvoiceForm.setError(INTERNAL_INVOICE_INPUTS.BILLED, {
        type: 'custom',
        message: i18next.t('INVOICE.INTERNAL_INVOICES.STORE_INVOICE_INFORMATION_ERROR'),
      });
    }
  }, [internalInvoiceForm.formState.errors]);

  /** FUNCTIONS */
  const checkBilledStoreInvoiceInformation = async (selectedBilledStore) => {
    if (!selectedBilledStore) {
      return false;
    }

    const hasInvoiceInformation = await storeService.checkStoreInvoiceInformation(
      selectedBilledStore.id,
    );

    setBilledStoreHasInvoiceInformation(hasInvoiceInformation);
  };

  const returnToInternalInvoicesList = () => history.goBack();

  const createInternalInvoice = async () => {
    pageLoading();

    try {
      const { issuer, billed, orderIds, recipientEmails, copyEmails, intraCommunitySupply } =
        formFields;

      const splitRecipientEmails = recipientEmails.split(',');
      const splitCopyEmails = copyEmails ? copyEmails.split(',') : [];

      const issuerStoreId = issuer.id;
      const billedStoreId = billed.id;

      await internalInvoiceService.sendInvoices({
        issuerStoreId,
        billedStoreId,
        orderIds,
        recipientEmails: splitRecipientEmails,
        copyEmails: splitCopyEmails,
        intraCommunitySupply,
      });

      // Can't call it in a 'finally' because function returnToInternalInvoicesList redirect to another page and it might create an infinite loading
      pageLoaded();

      showSuccessMessage(i18next.t('INVOICE.INTERNAL_INVOICES.CREATION_SUCCESS'));

      returnToInternalInvoicesList();
    } catch {
      showErrorMessage(i18next.t('INVOICE.INTERNAL_INVOICES.CREATION_FAILURE'));

      pageLoaded();
    }
  };

  const nextStepHandler = async () => {
    const fieldsValidation = await internalInvoiceForm.trigger(STEPS_FIELDS_TO_CHECK[currentStep]);

    switch (currentStep) {
      case STEPS_INTERNAL_INVOICE_CREATION[0].key:
        setIsSaveAlreadyTriggered(true);

        if (!fieldsValidation || !billedStoreHasInvoiceInformation || isEmpty(associatedOrders)) {
          setIsSaveDisabled(true);

          return;
        }

        break;
      case STEPS_INTERNAL_INVOICE_CREATION[1].key:
        setIsSaveDisabled(isEmpty(emailRecipients));

        break;
      default:
    }

    const currentStepIndex = STEPS_INTERNAL_INVOICE_CREATION.findIndex(
      ({ key }) => key === currentStep,
    );

    if (currentStepIndex < STEPS_MAX_INDEX) {
      setCurrentStep(STEPS_INTERNAL_INVOICE_CREATION[currentStepIndex + 1].key);
      setIsSaveAlreadyTriggered(false);
    }

    if (currentStepIndex === STEPS_MAX_INDEX) {
      await createInternalInvoice();
    }
  };

  const previousStepHandler = () => {
    setIsSaveDisabled(false);

    const currentStepIndex = STEPS_INTERNAL_INVOICE_CREATION.findIndex(
      ({ key }) => key === currentStep,
    );

    if (currentStepIndex > 0) {
      setCurrentStep(STEPS_INTERNAL_INVOICE_CREATION[currentStepIndex - 1].key);
    }

    if (currentStepIndex === 0) {
      returnToInternalInvoicesList();
    }
  };

  const selectedIssuerId = get(
    internalInvoiceForm.getValues(INTERNAL_INVOICE_INPUTS.ISSUER),
    'id',
    null,
  );
  const selectedIssuerSupplierId = get(
    supplyInfoByKitchen,
    `[${selectedIssuerId}].supplierId`,
    null,
  );
  const selectedIssuerStore =
    {
      ...internalInvoiceForm.getValues(INTERNAL_INVOICE_INPUTS.ISSUER),
      supplierId: selectedIssuerSupplierId,
    } || null;
  const selectedBilledStore = internalInvoiceForm.getValues(INTERNAL_INVOICE_INPUTS.BILLED) || null;

  return (
    <>
      <NavigationBar
        displaySubFeatures={false}
        internalInvoice={{ invoiceNumber }}
        path={path}
        bigTopBar
        enableActionBottomOrder
        fullWidth
      />
      <PageContainer>
        <ContentContainer>
          {currentStep === STEPS_INTERNAL_INVOICE_CREATION[0].key && (
            <InternalInvoiceInformation
              associatedOrders={associatedOrders}
              centralKitchens={centralKitchens}
              internalInvoiceForm={internalInvoiceForm}
              invoiceDate={today}
              invoiceNumber={invoiceNumber}
              isSaveAlreadyTriggered={isSaveAlreadyTriggered}
              salesPointStores={suppliedSalesPointStores}
              setAssociatedOrders={setAssociatedOrders}
              store={selectedBilledStore}
              supplier={selectedIssuerStore}
              isCreation
            />
          )}
          {currentStep === STEPS_INTERNAL_INVOICE_CREATION[1].key && (
            <InternalInvoiceSummary
              associatedOrders={associatedOrders}
              emailRecipients={emailRecipients}
              emailRecipientsCopy={emailRecipientsCopy}
              internalInvoiceForm={internalInvoiceForm}
              setEmailRecipients={setEmailRecipients}
              setEmailRecipientsCopy={setEmailRecipientsCopy}
              showErrorMessage={showErrorMessage}
            />
          )}
        </ContentContainer>
        <Footer>
          <StepsContainer>
            {STEPS_INTERNAL_INVOICE_CREATION.map(({ key, label }, index) => (
              <Step key={`step-${index}`}>
                <LabelContainer isActive={currentStep === key}>
                  <LabelIcon isActive={currentStep === key}>{index + 1}</LabelIcon>
                  <Text
                    children={label}
                    color={
                      currentStep === key
                        ? theme.colors.modalHeaders.green.text
                        : theme.colors.greys.dark
                    }
                    font={ENUM_FONTS.TEXT_MIDDLE_BOLD}
                  />
                </LabelContainer>
                {index !== STEPS_INTERNAL_INVOICE_CREATION.length - 1 && (
                  <img src={'/images/inpulse/chevron-right-dmgrey-small.svg'} />
                )}
              </Step>
            ))}
            <ButtonsContainer>
              {navigationUtils.renderPreviousButton(
                currentStep === STEP_1_DETAILS,
                previousStepHandler,
              )}
              {navigationUtils.renderNextButton(
                currentStep === STEP_2_SUMMARY,
                isSaveDisabled,
                nextStepHandler,
              )}
            </ButtonsContainer>
          </StepsContainer>
        </Footer>
      </PageContainer>
    </>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
  centralKitchens: getCentralKitchenStores(state.baseReducer.activeStores),
  currency: state.baseReducer.currency,
  user: state.baseReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InternalInvoiceCreation);
