import i18next from 'i18next';
import React from 'react';

import { Dropdown, INPUT_WIDTH } from '@commons/utils/styledLibraryComponents';
import { getIngredientUnitName } from '@commons/utils/units';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

import theme from '@theme';

import { CompositionDropdownContainer } from '../../styledComponents';
import {
  DropdownIcon,
  DropdownItem,
  DropdownItemMainValue,
  DropdownTitle,
} from '@admin/suppliers/supplierProducts/details/supplierProductInformations/components/SupplierProductDetails/styledComponents';

const DEFAULT_INGREDIENT_PICTURE = '/images/inpulse/ingredient-photo-placeholder.svg';

const IngredientDropdown = (props) => {
  const {
    handleEntityChange,
    ingredients,
    isReadOnly,
    composition,
    isKitchenProduct,
    isLoading,
    handleIngredientCreation,
    errorMessage = null,
  } = props;

  const renderedIngredients = ingredients.map((ingredient) => ({
    ...ingredient,
    tags: [getIngredientUnitName(ingredient.unit)],
    value: ingredient.name,
    renderValue: () => (
      <DropdownItem>
        <DropdownIcon src={ingredient.img || DEFAULT_INGREDIENT_PICTURE} />
        <DropdownTitle>
          <DropdownItemMainValue>{ingredient.name}</DropdownItemMainValue>
        </DropdownTitle>
      </DropdownItem>
    ),
  }));

  return (
    <CompositionDropdownContainer>
      <Dropdown
        button={
          isKitchenProduct
            ? {
                id: 1,
                text: i18next.t('ADMIN.INGREDIENTS.CREATE'),
                handleClick: handleIngredientCreation,
                iconSrc: '/images/inpulse/add-black-small.svg',
              }
            : null
        }
        customStyle={{ height: 'auto' }}
        isDisabled={isReadOnly || isLoading}
        isErrorState={!!errorMessage}
        items={renderedIngredients}
        label={i18next.t('GENERAL.INGREDIENT')}
        labelStyle={{ font: theme.fonts.textSmall }}
        placeholder={i18next.t('GENERAL.SEARCH')}
        selectedItem={composition}
        tooltipText={
          isKitchenProduct ? '' : i18next.t('ADMIN.PRODUCTS.COMPOSITION_TOOLTIP_INGREDIENT')
        }
        isRequired
        onSelectionChange={handleEntityChange}
      />
      {errorMessage && (
        <Text
          color={ENUM_COLORS.INFO_RED}
          font={ENUM_FONTS.TEXT_SMALL_HEIGHT_14_WEIGHT_600}
          style={{ width: INPUT_WIDTH.LARGE }}
        >
          {errorMessage}
        </Text>
      )}
    </CompositionDropdownContainer>
  );
};

export default IngredientDropdown;
