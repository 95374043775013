import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_ADD_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';

import AssociateOrdersModal from '../AssociateOrdersModal';

export const getAssociateOrdersModalConfig = ({
  supplier,
  store,
  associateOrders,
  ordersToBeAdded,
  setOrdersToBeAdded,
  alreadyMappedOrdersKeyById,
  associatedOrders,
  title,
}) => ({
  type: 'actionWhite',
  isFullscreen: true,
  title,
  icon: '/images/inpulse/add-black-small.svg',
  customPadding: '24px 0px 0px 0px',
  component: AssociateOrdersModal,
  data: {
    supplier,
    store,
    ordersToBeAdded,
    setOrdersToBeAdded,
    alreadyMappedOrdersKeyById,
    associatedOrders,
  },
  actions: [
    GENERIC_MODAL_CANCEL_BUTTON(),
    {
      ...GENERIC_MODAL_ADD_BUTTON(),
      isDisabled: !ordersToBeAdded.length,
      handleClick: associateOrders,
    },
  ],
});

export default { getAssociateOrdersModalConfig };
