import { connect } from 'react-redux';
import { get } from 'lodash';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

// ACTIONS
import { loading, loadingSuccess } from '@actions/loading';
import { openGenericModal } from '@actions/modal';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

// COMMONS
import { buildSchema } from '@commons/GenericForm';
import NavigationBar from '@commons/NavigationBar';

// SELECTORS
import { getCentralKitchenStores } from '@selectors/stores';
import { getClientInfo } from '@selectors/client';

// SERVICES
import centralService from '@services/central';
import internalInvoiceService from '@services/internalInvoice';

import { ContentContainer, PageContainer } from './styledComponents';
import { formatNumberWithCurrency } from '@commons/DisplayNumber';
import {
  INTERNAL_INVOICE_FORM_INPUTS,
  INTERNAL_INVOICE_INPUTS,
} from '../utils/formInputsConfigurations';
import InternalInvoiceInformation from '../internalInvoiceInformation';

const InternalInvoiceDetails = (props) => {
  const {
    match: { path, params },
    centralKitchens,
    pageLoaded,
    pageLoading,
    currency,
  } = props;

  const [internalInvoice, setInternalInvoice] = useState({});

  const [associatedOrders, setAssociatedOrders] = useState([]);

  // Allows to easily know, for each kitchen, its supplierId and supplied salespoints
  const [supplyInfoByKitchen, setSupplyInfoByKitchen] = useState(null);

  const allInputs = [
    ...INTERNAL_INVOICE_FORM_INPUTS({
      centralKitchens: [],
      salesPointStores: [],
      invoiceDate: moment(),
      invoiceNumber: '',
    }),
  ];

  const internalInvoiceForm = useForm({
    defaultValues: {},
    resolver: yupResolver(buildSchema(allInputs)),
  });

  useEffect(() => {
    if (!params.id) {
      return;
    }

    pageLoading();

    (async () => {
      try {
        const fetchedInternalInvoice = await internalInvoiceService.getInternalInvoice(params.id);

        setInternalInvoice(fetchedInternalInvoice);

        const formattedTotal = `${formatNumberWithCurrency({
          displayCurrencyCode: true,
          withoutDecimals: false,
          number: fetchedInternalInvoice.total,
          currency,
        })}`;

        internalInvoiceForm.setValue('issuer', fetchedInternalInvoice.issuerStore);
        internalInvoiceForm.setValue('billed', fetchedInternalInvoice.billedStore);
        internalInvoiceForm.setValue('number', fetchedInternalInvoice.number);
        internalInvoiceForm.setValue('date', moment(fetchedInternalInvoice.date));

        internalInvoiceForm.setValue('total', formattedTotal);

        const kitchenStoreIds = centralKitchens.map(({ id }) => id);
        const supplyInfoOfKitchens = await centralService.getSalesPointsByKitchenStoreIds(
          kitchenStoreIds,
        );

        setSupplyInfoByKitchen(supplyInfoOfKitchens);

        setAssociatedOrders(fetchedInternalInvoice.associatedOrders);
      } catch {
        showErrorMessage(i18next.t('FETCH_ERROR'));
      } finally {
        pageLoaded();
      }
    })();
  }, []);

  /** FUNCTIONS */

  const selectedIssuerId = get(
    internalInvoiceForm.getValues(INTERNAL_INVOICE_INPUTS.ISSUER),
    'id',
    null,
  );
  const selectedIssuerSupplierId = get(
    supplyInfoByKitchen,
    `[${selectedIssuerId}].supplierId`,
    null,
  );
  const selectedIssuerStore =
    {
      ...internalInvoiceForm.getValues(INTERNAL_INVOICE_INPUTS.ISSUER),
      supplierId: selectedIssuerSupplierId,
    } || null;
  const selectedBilledStore = internalInvoiceForm.getValues(INTERNAL_INVOICE_INPUTS.BILLED) || null;

  return (
    <>
      <NavigationBar
        displaySubFeatures={false}
        internalInvoice={{
          invoiceNumber: internalInvoice.number,
        }}
        path={path}
        bigTopBar
        fullWidth
      />
      <PageContainer>
        <ContentContainer isFooterDisplayed>
          <InternalInvoiceInformation
            associatedOrders={associatedOrders}
            internalInvoiceForm={internalInvoiceForm}
            isCreation={false}
            setAssociatedOrders={setAssociatedOrders}
            store={selectedBilledStore}
            supplier={selectedIssuerStore}
            isSaveAlreadyTriggered
            readOnly
          />
        </ContentContainer>
      </PageContainer>
    </>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
  centralKitchens: getCentralKitchenStores(state.baseReducer.activeStores),
  currency: state.baseReducer.currency,
});

const mapDispatchToProps = (dispatch) => ({
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InternalInvoiceDetails);
