import { connect } from 'react-redux';
import { get, keyBy, isEqual, isEmpty } from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import {
  closeGenericModal,
  openGenericModal,
  openSmallModal,
  refreshGenericModal,
} from '@actions/modal';
import { loading, loadingSuccess } from '@actions/loading';
import { showConfirmationMessage } from '@actions/messageconfirmation';
import { updateIsSynchronizingCashierProductsSales } from '@actions/cashierProduct';

import { Button, ToggleSwitch } from '@commons/utils/styledLibraryComponents';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { getLinearGradientButtonForProgressStyling } from '@commons/utils/cssTricks';
import LastAction from '@commons/LastAction';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';
import WhiteCard from '@commons/WhiteCard';

import { getClientInfo } from '@selectors/client';

import { cashierProductSalesService } from '@services/cashierProductSales';
import storeService from '@services/store';

import theme from '@theme';

import { Container, WhiteCardsContainer, ButtonWithLabel, ButtonLabel } from './styledComponents';
import { SYNC_STATE } from '../commons/syncStatus';
import ExportRangeProductSales from './component/exportRangeProductSales';
import getModalConfig, { SYNC_CONFIG_MODAL_STEPS } from './SyncModal/utils/getConfiguration';

const ACTIONS_TYPES = {
  SYNC_CASHIER_PRODUCT_SALES: 'syncCashierProductSales',
  DOWNLOAD_CASHIER_PRODUCT_SALES: 'downloadCashierProductSales',
};

const TIMEOUT_GET_SYNC_STATUS = 5000; // in ms

const LAST_FORECAST_INIT_STATE = { active: false, lastRunDate: null };

const BackOfficeSales = (props) => {
  const {
    match,
    user,
    client: { clientId, clientName, hasMultipleBrands, storeName },
    openGenericModal,
    openModalExportInfo,
    refreshGenericModal,
    closeGenericModal,
    pageLoaded,
    pageLoading,
    showMessage,
    isSynchronizingCashierProductsSales,
    updateIsSynchronizingCashierProductsSales,
  } = props;

  const path = match.path || null;

  /*********************************/
  /** PAGE DATA **/
  /*********************************/
  const [initSync, setInitSync] = useState(true);

  const [lastSyncDate, setLastSyncDate] = useState(null);
  const [ongoingSyncInfo, setOngoingSyncInfo] = useState({});
  const [lastForecast, setLastForecast] = useState(LAST_FORECAST_INIT_STATE);

  /*********************************/
  /** MODAL DATA **/
  /*********************************/
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(null);
  const [stores, setStores] = useState([]);
  const [selectedStores, setSelectedStores] = useState([]);
  const [actionType, setActionType] = useState(null);
  const [salesDateRange, setSalesDateRange] = useState();

  const handleStoresSelection = (storesToSelect) => {
    const selectableStores = storesToSelect.filter((store) => !store.isNotSelectable);

    handleSelectedStoresChange(selectableStores);
    setSelectedStores(selectableStores);
  };

  const handleSelectedStoresChange = (storesToSelect) => {
    const selectedStoreByIds = keyBy(storesToSelect, 'id');

    const formattedStores = stores.map((store) => {
      if (selectedStoreByIds[store.id]) {
        return { ...store, isRowSelected: true };
      }
      return { ...store, isRowSelected: false };
    });

    if (!isEqual(stores, formattedStores)) {
      setStores(formattedStores);
    }
  };

  const getLastIngestion = async () => {
    try {
      const { lastRunDate } = await cashierProductSalesService.getIngestionPipelineStatus(clientId);

      setLastSyncDate(lastRunDate || null);
    } catch (error) {
      setLastSyncDate(null);
    }
  };

  const getLastForecast = async (clientId) => {
    try {
      const { lastRunDate, active } = await cashierProductSalesService.getForecastPipelineStatus(
        clientId,
      );

      setLastForecast({ active: active, lastRunDate: lastRunDate });
    } catch (error) {
      setLastForecast(LAST_FORECAST_INIT_STATE);
    }
  };

  const setModalFirstStep = async (actionType) => {
    setIsLoading(true);
    setActionType(actionType);
    setCurrentStep(SYNC_CONFIG_MODAL_STEPS.STORE_SELECTION);
    const clientStores = await fetchClientStore();
    setStores(clientStores);
    setIsLoading(false);
  };

  const resetData = async () => {
    setSelectedStores([]);
    setStores([]);
    setCurrentStep(null);
    setActionType(null);
    setSalesDateRange(null);
  };

  const syncCashierProductSales = async (
    selectedStoresIds,
    formattedStartDate,
    formattedEndDate,
  ) => {
    pageLoading();
    closeGenericModal();

    try {
      const { progress, estimatedEndDate } =
        await cashierProductSalesService.syncCashierProductSales(
          selectedStoresIds,
          formattedStartDate,
          formattedEndDate,
        );
      showMessage(i18next.t('BACKOFFICE.DATA.SYNC_START_SUCCESS'), 'success');
      updateIsSynchronizingCashierProductsSales(true);

      setOngoingSyncInfo({ progress, estimatedEndDate });
    } catch (error) {
      showMessage(i18next.t('BACKOFFICE.DATA.SYNC_START_ERROR'), 'error');
    } finally {
      pageLoaded();
    }
  };

  const exportCashierProductSales = async (
    formattedStartDate,
    formattedEndDate,
    selectedStoresIds,
  ) => {
    try {
      closeGenericModal(); // This is here and not outside of the if because i want to close the modal before calling the API

      const params = {
        component: ExportRangeProductSales,
        clientName,
        storeName,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        selectedStoresIds,
      };

      openModalExportInfo(params);
    } catch {
      showMessage(i18next.t('BACKOFFICE.DATA.DOWNLOAD_CASHIER_PRODUCT_SALES_ERROR'), 'error');
    }
  };

  const handleValidation = async () => {
    const selectedStoresIds = selectedStores.map((store) => store.id);
    const formattedStartDate = salesDateRange.startDate
      ? salesDateRange.startDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY)
      : null;
    const formattedEndDate = salesDateRange.endDate
      ? salesDateRange.endDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY)
      : null;

    /* Since we close the modal at validation (when we call the api), it is technically possible to re-open the modal while the api call is running
     *  As we do not want the data from the previous action to remain, we reset it now.
     */
    resetData();

    if (actionType === ACTIONS_TYPES.SYNC_CASHIER_PRODUCT_SALES) {
      await syncCashierProductSales(selectedStoresIds, formattedStartDate, formattedEndDate);
      return;
    }

    await exportCashierProductSales(formattedStartDate, formattedEndDate, selectedStoresIds);
  };

  const fetchClientStore = async () => {
    try {
      const stores = await storeService.getStoresOfClient();

      const activeStores = stores.filter((store) => store.active);

      return activeStores.map((store) => ({
        ...store,
        ...(hasMultipleBrands ? { brandName: get(store, 'lnkBrandStorerel.name') || '' } : {}),
        mappedAccounts: get(store, 'mappedAccounts.length'),
        isRowSelected: false,
        isNotSelectable:
          !get(store, 'mappedCashierStores.length') ||
          get(store, 'mappedCashierStores.length') === 0,
      }));
    } catch (error) {
      props.showMessage(i18next.t('BACKOFFICE.TEAM.ASSOCIATE_STORES_FETCH_ERROR'), 'error');
      return [];
    }
  };

  useEffect(() => {
    if (!user) {
      return;
    }

    (async function loadData() {
      await getLastIngestion();
      await getLastForecast(clientId);
    })();
  }, []);

  const checkSyncStatus = async () => {
    try {
      const { state, progress, estimatedEndDate } =
        await cashierProductSalesService.getCashierProductsSalesSyncStatus();

      if ([SYNC_STATE.RETRY, SYNC_STATE.PENDING, SYNC_STATE.STARTED].includes(state)) {
        updateIsSynchronizingCashierProductsSales(true);

        setOngoingSyncInfo({ progress, estimatedEndDate });

        return true;
      }

      if (!!isSynchronizingCashierProductsSales) {
        if (state === SYNC_STATE.SUCCESS) {
          showMessage(i18next.t('BACKOFFICE.DATA.SYNC_SUCCESS'), 'success');
          updateIsSynchronizingCashierProductsSales(false);

          setOngoingSyncInfo({});

          return false;
        }

        if (state === SYNC_STATE.FAILURE || state === SYNC_STATE.REVOKED) {
          showMessage(i18next.t('BACKOFFICE.DATA.SYNC_ERROR'), 'error');

          setOngoingSyncInfo({});
        }
      }
    } catch (error) {
      showMessage(i18next.t('BACKOFFICE.DATA.SYNC_ERROR'), 'error');
    }

    updateIsSynchronizingCashierProductsSales(false);

    setOngoingSyncInfo({});

    return false;
  };

  useEffect(() => {
    let intervalSync;

    if (isSynchronizingCashierProductsSales) {
      intervalSync = setInterval(() => {
        checkSyncStatus();
      }, TIMEOUT_GET_SYNC_STATUS);
    }

    (async function fetchSyncStatus() {
      const sync = await checkSyncStatus();

      setInitSync(sync);
    })();

    return () => clearInterval(intervalSync);
  }, [isSynchronizingCashierProductsSales]);

  useEffect(() => {
    const storesToSelectById = keyBy(selectedStores, 'id');

    const formattedStores = stores.map((store) => {
      if (storesToSelectById[store.id]) {
        return { ...store, isRowSelected: true };
      }
      return { ...store };
    });

    setStores(formattedStores);
  }, [currentStep]);

  useEffect(() => {
    if (!currentStep) {
      return;
    }

    const isModalOpened = get(props, 'modal.GenericModalBool', false);

    const modalConfiguration = getModalConfig({
      currentStep,
      user,
      stores,
      selectedStores,
      salesDateRange,
      isLoading,
      handleSelectedStoresChange,
      setCurrentStep,
      handleStoresSelection,
      resetData,
      handleValidation,
      setSalesDateRange,
    });

    if (isModalOpened) {
      refreshGenericModal(modalConfiguration);
      return;
    }

    openGenericModal(modalConfiguration);
  }, [currentStep, selectedStores, salesDateRange, isLoading]);

  const shouldDisplayCountDown = !isEmpty(ongoingSyncInfo);

  return (
    <Container>
      <NavigationBreadCrumb featurePath={path} />
      <WhiteCardsContainer>
        <WhiteCard
          buttonFlexColumn={false}
          buttonFlexDirection="row"
          content={i18next.t('BACKOFFICE.DATA.SYNC_SALES_DATA_CONTENT')}
          contentColor={theme.colors.greys.darker}
          renderContent={
            <Button
              animation={{ rotate: initSync }}
              buttonCustomStyle={{ width: 'fit-content' }}
              customBackground={getLinearGradientButtonForProgressStyling(ongoingSyncInfo.progress)}
              handleClick={() => {
                setModalFirstStep(ACTIONS_TYPES.SYNC_CASHIER_PRODUCT_SALES);
              }}
              icon={'/images/inpulse/sync-white-small.svg'}
              isDisabled={initSync}
              label={i18next.t('GENERAL.SYNC')}
            />
          }
          renderLastAction={
            <LastAction
              date={shouldDisplayCountDown ? ongoingSyncInfo.estimatedEndDate : lastSyncDate}
              displayCountDown={shouldDisplayCountDown}
              label={i18next.t(shouldDisplayCountDown ? 'GENERAL.TIME_LEFT' : 'GENERAL.LAST_SYNC')}
            />
          }
          title={i18next.t('BACKOFFICE.DATA.SYNC_SALES_DATA_TITLE')}
        />
        <WhiteCard
          renderContent={
            <ButtonWithLabel>
              <ToggleSwitch
                checked={lastForecast.active}
                disabled={true}
                handleClick={() => true}
                id={'forecast-toggle'}
              />
              <ButtonLabel>
                {i18next.t(
                  lastForecast.active
                    ? 'BACKOFFICE.DATA.FORECAST_STATUS_ENABLED'
                    : 'BACKOFFICE.DATA.FORECAST_STATUS_DISABLED',
                )}
              </ButtonLabel>
            </ButtonWithLabel>
          }
          renderLastAction={
            <LastAction date={lastForecast.lastRunDate} label={i18next.t('GENERAL.LAST_EXEC')} />
          }
          title={i18next.t('BACKOFFICE.DATA.FORECAST_TITLE')}
        />
      </WhiteCardsContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  modal: state.modalReducer,
  isSynchronizingCashierProductsSales: state.baseReducer.isSynchronizingCashierProductsSales,
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  openModalExportInfo: (params) => {
    dispatch(openSmallModal(params));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
  closeGenericModal: (params) => {
    dispatch(closeGenericModal(params));
  },
  updateIsSynchronizingCashierProductsSales: (isSynchronizingCashierProductsSales) => {
    dispatch(updateIsSynchronizingCashierProductsSales(isSynchronizingCashierProductsSales));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeSales);
