import { capitalize, sortBy } from 'lodash';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useCryptr } from '@cryptr/cryptr-react';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import {
  Container,
  Icon,
  NameContainer,
  UserContainer,
  SwichContainer,
  AccountContainer,
  textWithEllipsis,
  StyledLink,
} from './styledComponents';

import { logout } from '../../../actions/logout';

import Text, { ENUM_COLORS, ENUM_FONTS } from '../../Text';

import { removeLocalStorageItem, setLocalStorageItem } from '../../utils/localStorage';

/** SERVICES */
import { switchAccount } from '../../../services/user';

const UserSection = ({
  user,
  hasMultipleAccounts,
  isSwitchAccountOpened,
  setIsSwitchAccountOpened,
  triggerDrawer,
  handleLogout,
  updateReferential,
}) => {
  // Retrieve info connected user with cryptr SDK
  const cryptrHook = useCryptr();
  const history = useHistory();

  const userDetailPath = `/users/${user.id}/details`;

  const [groupOtherAccounts, setGroupOtherAccounts] = useState([]);

  useEffect(() => {
    if (!user || !hasMultipleAccounts) {
      return;
    }

    const { accountGroupMappings } = user;

    const otherAccounts = accountGroupMappings.filter(({ accountId }) => accountId !== user.id);

    setGroupOtherAccounts(sortBy(otherAccounts, 'clientName'));
  }, [user]);

  const logoutUser = async () => {
    const isAuthenticated = cryptrHook.isAuthenticated();

    if (!isAuthenticated) {
      return handleLogout();
    }

    // Make sure user is logged out that when sso logout flow redirects to app
    removeLocalStorageItem('loopbackAccessToken');
    removeLocalStorageItem('userId');
    removeLocalStorageItem('isCentralMode');

    // Redirect to SSO logout flow
    cryptrHook.logOut(() => {
      handleLogout();
    });
  };

  const handleSwitchAccount = async (targetAccountId) => {
    const targetAccount = await switchAccount(targetAccountId);

    setLocalStorageItem('loopbackAccessToken', targetAccount.id);
    setLocalStorageItem('userId', targetAccount.userId);

    // Change to Sales Point mode
    updateReferential(false);

    history.go('/home');
  };

  const renderCurrentAccount = () => (
    <>
      <NameContainer>
        <Text color={isSwitchAccountOpened ? ENUM_COLORS.INFO_GREEN : ENUM_COLORS.LIGHTEST}>
          {capitalize(user.firstName)} {capitalize(user.lastName)}
        </Text>
        <Text
          color={isSwitchAccountOpened ? ENUM_COLORS.INFO_GREEN : ENUM_COLORS.DGREY}
          font={ENUM_FONTS.TEXT_SMALL_HEIGHT_16}
        >
          {user.lnkClientAccountrel.name}
        </Text>
      </NameContainer>
    </>
  );

  return (
    <Container
      hasMultipleAccounts={hasMultipleAccounts}
      isSwitchAccountOpened={isSwitchAccountOpened}
    >
      {!hasMultipleAccounts ? (
        <>
          <UserContainer>
            <NameContainer>
              <Link to={userDetailPath} onClick={() => triggerDrawer({ closeDrawer: true })}>
                <Text color={ENUM_COLORS.LIGHTEST}>
                  {capitalize(user.firstName)} {capitalize(user.lastName)}
                </Text>
              </Link>
              <Link to={userDetailPath} onClick={() => triggerDrawer({ closeDrawer: true })}>
                <Text
                  color={ENUM_COLORS.DGREY}
                  font={ENUM_FONTS.TEXT_COMPONENT_LABEL}
                  style={textWithEllipsis}
                >
                  {user.lnkClientAccountrel.name}
                </Text>
              </Link>
            </NameContainer>
          </UserContainer>
          <Icon src={'/images/inpulse/logout.svg'} onClick={logoutUser} />
        </>
      ) : (
        <SwichContainer>
          {!isSwitchAccountOpened ? (
            <AccountContainer onClick={() => setIsSwitchAccountOpened(!isSwitchAccountOpened)}>
              {renderCurrentAccount()}
              <Icon
                src={
                  isSwitchAccountOpened
                    ? '/images/inpulse/chevron-down-green-small.svg'
                    : '/images/inpulse/chevron-up-white-small.svg'
                }
                onClick={() => setIsSwitchAccountOpened(!isSwitchAccountOpened)}
              />
            </AccountContainer>
          ) : (
            <>
              <AccountContainer onClick={() => setIsSwitchAccountOpened(!isSwitchAccountOpened)}>
                {renderCurrentAccount()}
                <Icon
                  src={
                    isSwitchAccountOpened
                      ? '/images/inpulse/chevron-down-green-small.svg'
                      : '/images/inpulse/chevron-up-white-small.svg'
                  }
                  onClick={() => setIsSwitchAccountOpened(!isSwitchAccountOpened)}
                />
              </AccountContainer>
              <StyledLink to={userDetailPath} onClick={() => triggerDrawer({ closeDrawer: true })}>
                <AccountContainer>
                  <NameContainer>
                    <Text color={ENUM_COLORS.LIGHTEST} font={ENUM_FONTS.TEXT_SMALL_HEIGHT_16}>
                      {i18next.t('GENERAL.MY_ACCOUNT')}
                    </Text>
                  </NameContainer>
                  <Icon src={'/images/inpulse/user-white-small.svg'} onClick={() => {}} />
                </AccountContainer>
              </StyledLink>
              {groupOtherAccounts.map((account) => (
                <AccountContainer
                  key={account.accountId}
                  onClick={() => handleSwitchAccount(account.accountId)}
                >
                  <NameContainer>
                    <Text
                      color={ENUM_COLORS.LIGHTEST}
                      font={ENUM_FONTS.TEXT_SMALL_HEIGHT_16}
                      style={textWithEllipsis}
                    >
                      {account.clientName}
                    </Text>
                    <Text color={ENUM_COLORS.DGREY} font={ENUM_FONTS.TEXT_SMALL_HEIGHT_16}>
                      {i18next.t('AUTHENTICATION.LOGIN.CONNECT')}
                    </Text>
                  </NameContainer>
                </AccountContainer>
              ))}

              <AccountContainer isBottom onClick={logoutUser}>
                <NameContainer>
                  <Text color={ENUM_COLORS.LIGHTEST} font={ENUM_FONTS.TEXT_SMALL_HEIGHT_16}>
                    {i18next.t('GENERAL.NO_ASSOCIATED_STORE_DETECTED_ACTION')}
                  </Text>
                </NameContainer>
                <Icon src={'/images/inpulse/logout.svg'} onClick={logoutUser} />
              </AccountContainer>
            </>
          )}
        </SwichContainer>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  handleLogout: () => {
    dispatch(logout());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserSection);
