import { connect } from 'react-redux';
import { findKey, get, pick } from 'lodash';
import { getAllCountries } from 'countries-and-timezones';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { showErrorMessage } from '@actions/messageconfirmation';

import { ENUM_MODULE_NAME } from '@commons/utils/features';
import NavBar from '@commons/NavigationBar';

import { cashierStoreService } from '@services/cashierStore';
import cashierConfigService from '@services/cashierConfig';

import InputCardSection from '@backoffice/BackOfficeStoresContainer/BackOfficeStoresManagement/components/InputCardSection';

import { Container } from './styledComponents';
import BottomBar from './components/BottomBar';
import utilsStoreCreation from './utils';

const BackOfficeCashierStoresDetail = (props) => {
  const { match, user, showErrorMessage } = props;

  const path = get(match, 'path');
  const [isCreation, setIsCreation] = useState(false);
  const [cashierConfigs, setCashierConfigs] = useState([]);

  const [hasLatitudeError, setHasLatitudeError] = useState(false);
  const [hasLongitudeError, setHasLongitudeError] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [cashierStoreName, setCashierStoreName] = useState([]);

  const [cashierStoreInformations, setCashierStoreInformations] = useState({
    name: null,
    telephone: null,
    active: true,
    partnerId: null,
    cashierConfigId: null,
    isSynchronized: false,
  });

  const [cashierStoreLocation, setcashierStoreLocation] = useState({
    country: 'FR',
    timezone: 'Europe/Paris',
    address: null,
    city: null,
    latitude: null,
    longitude: null,
    postCode: null,
  });

  const cashierStoreInformationsSettings =
    utilsStoreCreation.getCashierStoreInformationCardSettings({
      cashierConfigs,
      cashierStoreInformations,
    });

  const cashierStoreLocationSettings = utilsStoreCreation.getCashierStoreLocationCardSettings(
    cashierStoreLocation,
    hasLatitudeError,
    hasLongitudeError,
  );

  const checkValidPosition = (value, min, max) => {
    if (!!value) {
      if (value < min || value > max) {
        return true;
      }
    }
    return false;
  };

  const fetchCashierConfigs = async () => {
    try {
      const result = await cashierConfigService.getCashierConfigByClientId();
      setCashierConfigs(result);
    } catch {
      showErrorMessage(i18next.t('BACKOFFICE.CASHIERS.FETCH_CASHIER_CONFIGS_FAILURE'));
      setCashierConfigs([]);
    }
  };

  const getCashierStoreByIdAndModifyInputs = async (cashierStoreId) => {
    const allCountries = getAllCountries();

    try {
      const result = await cashierStoreService.getCashierStoreInformations();
      const cashierStore = result.find((cashierStore) => cashierStore.id == cashierStoreId);

      const getCodeCountry = (countryName) => {
        const countryKey = findKey(
          allCountries,
          (currentCountry) =>
            currentCountry.id === countryName || currentCountry.name === countryName,
        );
        return countryKey || 'FR';
      };

      setCashierStoreName(cashierStore.name);
      setCashierStoreInformations({
        ...pick(cashierStore, ['name', 'telephone', 'active', 'partnerId', 'isSynchronized']),
        cashierConfigId: cashierStore.cashierConfigId,
      });

      setcashierStoreLocation({
        ...pick(cashierStore, ['address', 'city', 'latitude', 'longitude', 'postCode', 'country']),
        country: getCodeCountry(cashierStore.country),
        timezone: 'Europe/Paris',
      });
    } catch {
      showErrorMessage(i18next.t('BACKOFFICE.CASHIERS.FETCH_CASHIER_CONFIGS_FAILURE'));
    }
  };

  const handleUpdateCashierStoreInformations = (property, value, state, setter) => {
    const updatedState = { ...state, [property]: value };
    if (property === 'cashierConfigId') {
      const matchingCashierConfig = cashierConfigs.find(
        (cashierConfig) => cashierConfig.id === value,
      );
      updatedState.cashierConfigId = matchingCashierConfig.id;
    }

    setter(updatedState);

    if (property === 'latitude') {
      setHasLatitudeError(checkValidPosition(value, -90, 90));
    }
    if (property === 'longitude') {
      setHasLongitudeError(checkValidPosition(value, -180, 180));
    }
  };

  // Initial data fetching
  useEffect(() => {
    (async () => {
      await fetchCashierConfigs();
    })();
  }, []);

  // Handle param id
  useEffect(() => {
    const cashierStoreId = get(match, 'params.id', null);

    if (!cashierStoreId) {
      history.goBack();

      return;
    }

    (async () => {
      await fetchCashierConfigs();

      const shouldFetchCashierStoreDetails = cashierStoreId !== 'create';

      if (shouldFetchCashierStoreDetails) {
        await getCashierStoreByIdAndModifyInputs(cashierStoreId);
      }

      setIsCreation(!shouldFetchCashierStoreDetails);
      setIsLoading(false);
    })();
  }, [match]);

  return (
    <>
      <NavBar
        action={
          <BottomBar
            cashierStoreId={get(match, 'params.id', null)}
            cashierStoreInformations={cashierStoreInformations}
            cashierStoreLocation={cashierStoreLocation}
            history={props.history}
            isCreation={isCreation}
            user={user}
          />
        }
        bigTopBar={true}
        cashierStore={{ name: cashierStoreName }}
        isCreation={isCreation}
        isLoading={isLoading}
        module={ENUM_MODULE_NAME.CASHIER_STORE_DETAIL}
        path={path}
        user={user}
      />

      <Container>
        <InputCardSection
          cardInformations={cashierStoreInformations}
          handleCardInformations={handleUpdateCashierStoreInformations}
          inputs={cashierStoreInformationsSettings.inputs}
          setter={setCashierStoreInformations}
          title={cashierStoreInformationsSettings.title}
        />
        <InputCardSection
          cardInformations={cashierStoreLocation}
          handleCardInformations={handleUpdateCashierStoreInformations}
          inputs={cashierStoreLocationSettings.inputs}
          setter={setcashierStoreLocation}
          title={cashierStoreLocationSettings.title}
        />
      </Container>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

export default connect(null, mapDispatchToProps)(BackOfficeCashierStoresDetail);
