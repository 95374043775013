import { connect } from 'react-redux';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import { showErrorMessage } from '@actions/messageconfirmation';

import { getTheme } from '@commons/utils/theme';
import { ListView } from '@commons/utils/styledLibraryComponents';
import { LISTVIEW_NO_TOP_PADDING } from '@commons/constants/listViewProps';
import EmptyState from '@commons/EmptyState';

import invoiceService from '@services/invoice';

import { getClientInfo } from '@selectors/client';

import DeepsightFiltersButton from '@orders/components/FilterButton';

import utilsColumns from './utils/columns';

import { Container } from './styledComponents';

const OrderMappingsModal = (props) => {
  const {
    invoiceControlList: { store, supplier, id: invoiceControlListId },
    showErrorMessage,
    setOrdersToBeAdded,
    alreadyMappedOrdersKeyById = {},
    // Redux
    client: { storeName },
  } = props;

  const theme = getTheme();

  // List view states
  const [filters, setFilters] = useState(null);
  const [applyFilters, setApplyFilters] = useState(true);
  const [advancedFilters, setAdvancedFilters] = useState(null);
  const [listViewColumns] = useState(utilsColumns.get(store, supplier, storeName));

  // Data states
  const [isLoading, setIsLoading] = useState(true);
  const [availableOrders, setAvailableOrders] = useState([]);
  const [displayedOrders, setDisplayedOrders] = useState([]);

  // Loading
  useEffect(() => {
    setOrdersToBeAdded([]);

    loadOrders();
  }, []);

  // Handle filters
  useEffect(() => {
    if (!applyFilters) {
      return;
    }

    if (!advancedFilters || !advancedFilters.length) {
      return setDisplayedOrders(availableOrders);
    }

    const filteredOrders = advancedFilters.reduce(
      (result, { doFilter, propertyKey, value }) => doFilter(result, propertyKey, value),
      availableOrders,
    );

    setDisplayedOrders(filteredOrders);
  }, [applyFilters, advancedFilters, availableOrders]);

  const loadOrders = async () => {
    setIsLoading(true);

    try {
      const orders = await invoiceService.getAvailableOrdersToAssociate(
        store.id,
        supplier.id,
        invoiceControlListId,
      );

      const formattedOrders = orders.map((order) => ({
        ...order,
        isNotSelectable: order.associatedInvoice, // already associated order should be disable
      }));

      setAvailableOrders(formattedOrders);
    } catch {
      setAvailableOrders([]);

      showErrorMessage(
        i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ORDER_MAPPINGS_ASSOCIATION_FETCH_ERROR'),
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <ListView
        columns={listViewColumns}
        data={displayedOrders}
        defaultOrderBy={'startOrderDate'}
        defaultOrderType={'desc'}
        isLoading={isLoading}
        markerConfiguration={{
          isHidden: ({ id }) => !alreadyMappedOrdersKeyById[id],
          backgroundColor: theme.colors.brand.secondary,
          icon: { src: '/images/inpulse/central-black-small.svg' },
        }}
        padding={LISTVIEW_NO_TOP_PADDING}
        renderEmptyState={() => <EmptyState />}
        renderFilterButton={() => (
          <DeepsightFiltersButton
            advancedFilters={advancedFilters}
            applyFilters={applyFilters}
            columnsFilterList={listViewColumns.filter(({ filterType }) => !!filterType)}
            filters={filters}
            isLoading={isLoading}
            readOnly={isLoading}
            setAdvancedFilters={setAdvancedFilters}
            setApplyFilters={setApplyFilters}
            setFilters={setFilters}
            textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
          />
        )}
        setSelectedItems={setOrdersToBeAdded}
        forceEnableSelection
        hideAllPerPageOption
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderMappingsModal);
