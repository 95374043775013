import { connect } from 'react-redux';
import { get, find } from 'lodash';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import { getStoresOfUser, receiveStores, requestStoresError } from '@actions/store';
import { loading, loadingSuccess } from '@actions/loading';
import { openMediumModal, openGenericModal, refreshGenericModal } from '@actions/modal';
import { showConfirmationMessage } from '@actions/messageconfirmation';

import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { ListView } from '@commons/utils/styledLibraryComponents';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import { getClientInfo } from '@selectors/client';

import clientService from '@services/client';

import EmptyState from '../BackOfficeStoresManagement/components/EmptyState';

import { Container, ListViewContainer } from './styledComponents';
import StoreAssociationModal from './components/StoreAssociationModal';
import utilsActions from './utils/actions';
import utilsColumns from './utils/columns';
import utilsModal from './utils/modal';

export const BackOfficeGroups = (props) => {
  const {
    user,
    match,
    client: { clientId, storeName },
  } = props;

  const path = get(match, 'path');

  const userLanguageCode = get(props.user, 'lnkLanguageAccountrel.code', 'fr');

  // States for list view setup
  const [actions, setActions] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  // States for global data
  const [clientGroups, setClientGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const translatedStoreName = getClientStoreNameTranslation(storeName, true);

  const columns = utilsColumns.getColumnsSettings({
    clientStoreNameTranslation: translatedStoreName,
  });

  /*******************/
  /** Fetch Methods **/
  /*******************/

  const fetchClientGroups = async () => {
    try {
      const groups = await clientService.getGroupsOfClient();

      setClientGroups(groups);
    } catch (error) {
      props.showMessage(i18next.t('BACKOFFICE.GROUPS.FETCHING_ERROR'), 'error');
    }
  };

  /************************************************/
  /** Initial setup of the component **/
  /**************************************************/
  useEffect(() => {
    if (!user) {
      return;
    }

    (async () => {
      await fetchClientGroups();

      setIsLoading(false);
    })();
  }, [user]);

  /*************************/
  /** Handle Group Edition **/
  /*************************/

  const handleEditGroup = async (groupName, group) => {
    props.pageLoading();

    try {
      await clientService.updateGroupByGroupId(clientId, group.id, {
        group: { name: groupName },
      });

      props.showMessage(i18next.t('BACKOFFICE.GROUPS.NAME_SUCCESSFULLY_UPDATED'), 'success');

      await fetchClientGroups();

      props.getStoresOfUser();
    } catch (error) {
      props.showMessage(i18next.t('BACKOFFICE.GROUPS.NAME_UPDATE_ERROR'), 'error');
    } finally {
      props.pageLoaded();
    }
  };

  const onGroupNameEditionChange = (groupName, group) => {
    const alreadyExists = find(clientGroups, (clientGroup) => clientGroup.name === groupName);

    const errorMessage =
      (alreadyExists && i18next.t('BACKOFFICE.GROUPS.CREATE_MODAL_DUPLICATE_NAME')) ||
      (!groupName && i18next.t('GENERAL.REQUIRED_FILED_ERROR_MESSAGE')) ||
      '';

    const params = utilsModal.getGroupEditModalParams({
      group,
      groupName,
      errorMessage,
      handleEditGroup,
      onGroupNameEditionChange,
    });

    props.refreshGenericModal(params);
  };

  const displayModalEditGroup = (group) => {
    const params = utilsModal.getGroupEditModalParams({
      group,
      handleEditGroup,
      groupName: group.name,
      onGroupNameEditionChange,
    });

    props.openGenericModal(params);
  };

  /**************************/
  /** Handle Group Creation **/
  /**************************/

  const handleCreateGroup = async (name) => {
    props.pageLoading();

    try {
      await clientService.createClientGroup(clientId, name);

      props.showMessage(i18next.t('BACKOFFICE.GROUPS.CREATE_GROUP_SUCCESS'));

      await fetchClientGroups();

      props.getStoresOfUser();
    } catch (err) {
      props.showMessage(i18next.t('BACKOFFICE.GROUPS.CREATE_GROUP_FAILURE'), 'error');
    } finally {
      props.pageLoaded();
    }
  };

  const onGroupToCreateChange = (value) => {
    const forbiddenNames = clientGroups.map(({ name }) => name.toLowerCase());

    const params = utilsModal.getCreateGroupModalConfig({
      name: value,
      forbiddenNames,
      handleCreateGroup,
      onInputChange: onGroupToCreateChange,
    });

    props.refreshGenericModal({ data: params.data, actions: params.actions });
  };

  const displayModalCreateGroup = () => {
    const forbiddenNames = clientGroups.map(({ name }) => name.toLowerCase());

    const params = utilsModal.getCreateGroupModalConfig({
      name: '',
      forbiddenNames,
      handleCreateGroup,
      onInputChange: onGroupToCreateChange,
    });

    props.openGenericModal(params);
  };

  /**************************/
  /** Handle Group Deletion **/
  /**************************/

  const handleDeleteGroupById = async (groupIds) => {
    props.pageLoading();

    try {
      const promises = [];
      groupIds.map((groupId) => {
        promises.push(clientService.deleteGroupById(clientId, groupId));
      });

      await Promise.all(promises);

      if (groupIds.length > 1) {
        props.showMessage(i18next.t('BACKOFFICE.GROUPS.DELETE_GROUPS_SUCCESS'));
      } else {
        props.showMessage(i18next.t('BACKOFFICE.GROUPS.DELETE_GROUP_SUCCESS'));
      }

      await fetchClientGroups();

      props.getStoresOfUser();
    } catch (err) {
      if (groupIds.length > 1) {
        props.showMessage(i18next.t('BACKOFFICE.GROUPS.DELETE_GROUPS_FAILURE'), 'error');
      } else {
        props.showMessage(i18next.t('BACKOFFICE.GROUPS.DELETE_GROUP_FAILURE'), 'error');
      }
    } finally {
      props.pageLoaded();
    }
  };

  const displayModalDeleteGroup = (groups) => {
    const params = utilsModal.getDeleteGroupModalConfig({
      groups,
      handleDeleteGroupById,
    });

    props.openGenericModal(params);
  };

  /******************************/
  /** Handle Store Association **/
  /******************************/

  const openStoresAssociationModal = (item) => {
    const params = {
      fetchClientGroups,
      isLoading,
      group: item,
      deactivateBackgroundClick: true,
      component: StoreAssociationModal,
    };

    props.openModal(params);
  };

  /************************************************/
  /** Update actions when selected groups change **/
  /**************************************************/

  const rowActions = utilsActions.getRowActionsSettings({
    translatedStoreName: translatedStoreName.toLocaleLowerCase(),
    displayModalEditGroup,
    displayModalDeleteGroup,
    openStoresAssociationModal,
  });

  useEffect(() => {
    if (!user) {
      return;
    }

    setActions(
      utilsActions.getActionsSettings({
        selectedItems,
        isLoading,
        translatedStoreName: translatedStoreName.toLocaleLowerCase(),
        displayModalCreateGroup,
        displayModalEditGroup,
        displayModalDeleteGroup,
        openStoresAssociationModal,
      }),
    );
  }, [user, selectedItems, isLoading]);

  return (
    <Container>
      <NavigationBreadCrumb featurePath={path} />
      <ListViewContainer>
        <ListView
          actions={actions}
          columns={columns}
          data={clientGroups}
          defaultOrderBy={'name'}
          defaultOrderType={'asc'}
          isLoading={isLoading}
          languageCode={userLanguageCode}
          padding={'24px 24px 0px 24px'}
          placeholderShape={i18next.t('GENERAL.SEARCH')}
          renderEmptyState={() => <EmptyState />}
          rowActions={rowActions}
          setSelectedItems={setSelectedItems}
        />
      </ListViewContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  openModal: (params) => {
    dispatch(openMediumModal(params));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  getStoresOfUser: () =>
    dispatch(getStoresOfUser()).then(
      (result) => {
        dispatch(receiveStores(result));
      },
      (error) => {
        dispatch(requestStoresError(error));
      },
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeGroups);
