import { REGEX_MATCH_EMAIL } from '@commons/utils/regex';

export const SCHEMA_FEATURES_PROPS_VALIDATION = {
  '/admin/suppliers/supplier-products': {
    schema: {
      type: 'object',
      properties: {
        displayManufacturerName: { type: 'boolean' },
      },
      required: ['displayManufacturerName'],
      additionalProperties: false,
    },
  },
  '/forecast/forecast/turnover': {
    schema: {
      type: 'object',
      properties: {
        forecastAccuracyChart: { type: 'boolean' },
        forecastPrecisionChart: { type: 'boolean' },
        prevHorizon: {
          type: 'object',
          properties: {
            past: { type: 'number', minimum: 0 },
            future: { type: 'number', minimum: 0 },
          },
          required: ['past', 'future'],
          additionalProperties: false,
        },
        waste: {
          enum: ['productionWasteRate', 'turnoverWasteRate'],
        },
      },
      required: ['forecastAccuracyChart', 'forecastPrecisionChart', 'prevHorizon', 'waste'],
      additionalProperties: false,
    },
  },
  '/home': {
    schema: {
      type: 'object',
      properties: {
        disableTopFlop: { type: 'boolean' },
        wasteRate: {
          enum: ['productionWasteRate', 'turnoverWasteRate'],
        },
      },
      required: ['disableTopFlop', 'wasteRate'],
      additionalProperties: false,
    },
  },
  '/home/analytics': {
    schema: {
      type: 'object',
      properties: {
        activityColumns: {
          type: 'object',
          properties: {
            storeName: { type: 'boolean' },
            storeBrand: { type: 'boolean' },
            currentYearTurnover: { type: 'boolean' },
            lastYearTurnover: { type: 'boolean' },
            turnoverEvolution: { type: 'boolean' },
            productionWasteRate: { type: 'boolean' },
            turnoverWasteRate: { type: 'boolean' },
            stockRate: { type: 'boolean' },
            productionRate: { type: 'boolean' },
            assortmentRate: { type: 'boolean' },
          },
          additionalProperties: false,
          required: [
            'storeName',
            'storeBrand',
            'currentYearTurnover',
            'lastYearTurnover',
            'turnoverEvolution',
            'productionWasteRate',
            'turnoverWasteRate',
            'stockRate',
            'productionRate',
            'assortmentRate',
          ],
        },
      },
      required: ['activityColumns'],
      additionalProperties: false,
    },
  },
  '/stocks/inventories': {
    schema: {
      type: 'object',
      properties: {
        constraints: {
          type: 'array',
          minLength: 0,
          items: {
            type: 'string',
            enum: ['stockReassemblyMandatory'],
          },
        },
      },
      required: ['constraints'],
      additionalProperties: false,
    },
  },
  '/stocks/inventories/stocks': {
    schema: {
      type: 'object',
      properties: {
        inventoryValidation: { type: 'boolean' },
        theoreticalInventory: { type: 'boolean' },
      },
      additionalProperties: false,
    },
  },
  '/production/operations': {
    schema: {
      type: 'object',
      properties: {
        printOption: { type: 'boolean' },
        enableProductMixModification: { type: 'boolean' },
      },
      required: ['printOption', 'enableProductMixModification'],
      additionalProperties: false,
    },
  },
  '/production/analytics/by-store': {
    schema: {
      type: 'object',
      properties: {
        defaultAnalyticsMetric: { enum: ['turnover', 'unit'] },
        analyticsColumns: {
          type: 'object',
          properties: {
            storeName: { type: 'boolean' },
            forecastedTurnover: { type: 'boolean' },
            forecastedQuantity: { type: 'boolean' },
            stockedTurnover: { type: 'boolean' },
            stockedQuantity: { type: 'boolean' },
            toProduceTurnover: { type: 'boolean' },
            toProduceQuantity: { type: 'boolean' },
            producedTurnover: { type: 'boolean' },
            producedQuantity: { type: 'boolean' },
            turnoverProductionRatio: { type: 'boolean' },
            quantityProductionRatio: { type: 'boolean' },
            lossQuantity: { type: 'boolean' },
            loss: { type: 'boolean' },
            quantityProductionWasteRate: { type: 'boolean' },
            productionWasteRate: { type: 'boolean' },
            quantityTurnoverWasteRate: { type: 'boolean' },
            turnoverWasteRate: { type: 'boolean' },
            assortmentRate: { type: 'boolean' },
            initialAssortmentRate: { type: 'boolean' },
            assortmentGapRate: { type: 'boolean' },
            realTurnover: { type: 'boolean' },
            realSales: { type: 'boolean' },
            targetProductionWasteRate: { type: 'boolean' },
            nbProducedReferences: { type: 'boolean' },
            realTurnoverInclTax: { type: 'boolean' },
          },
          additionalProperties: false,
          required: [
            'storeName',
            'forecastedTurnover',
            'forecastedQuantity',
            'stockedTurnover',
            'stockedQuantity',
            'toProduceTurnover',
            'toProduceQuantity',
            'producedTurnover',
            'producedQuantity',
            'turnoverProductionRatio',
            'quantityProductionRatio',
            'lossQuantity',
            'loss',
            'quantityProductionWasteRate',
            'productionWasteRate',
            'quantityTurnoverWasteRate',
            'turnoverWasteRate',
            'assortmentRate',
            'initialAssortmentRate',
            'assortmentGapRate',
            'realTurnover',
            'realSales',
            'targetProductionWasteRate',
            'nbProducedReferences',
            'realTurnoverInclTax',
          ],
        },
        applyBrandsFilterOnProducts: { type: 'boolean' },
      },
      required: ['analyticsColumns', 'defaultAnalyticsMetric', 'applyBrandsFilterOnProducts'],
      additionalProperties: false,
    },
  },
  '/production/analytics/stores': {
    schema: {
      type: 'object',
      properties: {
        defaultAnalyticsMetric: { enum: ['turnover', 'unit'] },
        analyticsColumns: {
          type: 'object',
          properties: {
            date: { type: 'boolean' },
            forecastedTurnover: { type: 'boolean' },
            forecastedQuantity: { type: 'boolean' },
            stockedTurnover: { type: 'boolean' },
            stockedQuantity: { type: 'boolean' },
            toProduceTurnover: { type: 'boolean' },
            toProduceQuantity: { type: 'boolean' },
            producedTurnover: { type: 'boolean' },
            producedQuantity: { type: 'boolean' },
            lossQuantity: { type: 'boolean' },
            loss: { type: 'boolean' },
            productionWasteRate: { type: 'boolean' },
            turnoverWasteRate: { type: 'boolean' },
            quantityProductionWasteRate: { type: 'boolean' },
            quantityTurnoverWasteRate: { type: 'boolean' },
            turnoverProductionRatio: { type: 'boolean' },
            quantityProductionRatio: { type: 'boolean' },
            realSales: { type: 'boolean' },
            realTurnover: { type: 'boolean' },
            conformity: { type: 'boolean' },
            cadence: { type: 'boolean' },
            targetProductionWasteRate: { type: 'boolean' },
            realTurnoverInclTax: { type: 'boolean' },
          },
          additionalProperties: false,
          required: [
            'date',
            'forecastedTurnover',
            'forecastedQuantity',
            'stockedTurnover',
            'stockedQuantity',
            'toProduceTurnover',
            'toProduceQuantity',
            'producedTurnover',
            'producedQuantity',
            'lossQuantity',
            'loss',
            'productionWasteRate',
            'turnoverWasteRate',
            'quantityProductionWasteRate',
            'quantityTurnoverWasteRate',
            'turnoverProductionRatio',
            'quantityProductionRatio',
            'realSales',
            'realTurnover',
            'conformity',
            'cadence',
            'targetProductionWasteRate',
            'realTurnoverInclTax',
          ],
        },
        applyBrandsFilterOnProducts: { type: 'boolean' },
      },
      required: ['analyticsColumns', 'defaultAnalyticsMetric', 'applyBrandsFilterOnProducts'],
      additionalProperties: false,
    },
  },
  '/production/analytics/references': {
    schema: {
      type: 'object',
      properties: {
        defaultAnalyticsMetric: { enum: ['turnover', 'unit'] },
        analyticsColumns: {
          type: 'object',
          properties: {
            sku: { type: 'boolean' },
            productName: { type: 'boolean' },
            forecastedTurnover: { type: 'boolean' },
            forecastedQuantity: { type: 'boolean' },
            stockedTurnover: { type: 'boolean' },
            stockedQuantity: { type: 'boolean' },
            toProduceTurnover: { type: 'boolean' },
            toProduceQuantity: { type: 'boolean' },
            producedTurnover: { type: 'boolean' },
            producedQuantity: { type: 'boolean' },
            lossQuantity: { type: 'boolean' },
            loss: { type: 'boolean' },
            productionWasteRate: { type: 'boolean' },
            turnoverWasteRate: { type: 'boolean' },
            quantityProductionWasteRate: { type: 'boolean' },
            quantityTurnoverWasteRate: { type: 'boolean' },
            turnoverProductionRatio: { type: 'boolean' },
            quantityProductionRatio: { type: 'boolean' },
            realSales: { type: 'boolean' },
            realTurnover: { type: 'boolean' },
            conformity: { type: 'boolean' },
            cadence: { type: 'boolean' },
          },
          additionalProperties: false,
          required: [
            'sku',
            'productName',
            'forecastedTurnover',
            'forecastedQuantity',
            'stockedTurnover',
            'stockedQuantity',
            'toProduceTurnover',
            'toProduceQuantity',
            'producedTurnover',
            'producedQuantity',
            'lossQuantity',
            'loss',
            'productionWasteRate',
            'turnoverWasteRate',
            'quantityProductionWasteRate',
            'quantityTurnoverWasteRate',
            'turnoverProductionRatio',
            'quantityProductionRatio',
            'realSales',
            'realTurnover',
            'conformity',
            'cadence',
          ],
        },
        applyBrandsFilterOnProducts: { type: 'boolean' },
      },
      required: ['analyticsColumns', 'defaultAnalyticsMetric', 'applyBrandsFilterOnProducts'],
      additionalProperties: false,
    },
  },
  '/production/analytics/real-time': {
    schema: {
      type: 'object',
      properties: {
        applyBrandsFilterOnProducts: { type: 'boolean' },
      },
      required: ['applyBrandsFilterOnProducts'],
      additionalProperties: false,
    },
  },
  '/production/analytics/by-brand': {
    schema: {
      type: 'object',
      properties: {
        applyBrandsFilterOnProducts: { type: 'boolean' },
      },
      required: ['applyBrandsFilterOnProducts'],
      additionalProperties: false,
    },
  },
  '/order/orders': {
    schema: {
      type: 'object',
      properties: {
        createOrdersXLS: { type: 'boolean' },
        displayStocks: { type: 'boolean' },
        groupedOrder: { type: 'boolean' },
        canOrderWithMin: { type: 'boolean' },
        displaySupplierProductPackagingWeight: { type: 'boolean' },
        resetStockEveryDayForCentralKitchen: { type: 'boolean' },
        categoriesOrder: {
          type: 'array',
          minLength: 0,
          items: {
            type: 'string',
          },
        },
        constraints: {
          type: 'array',
          minLength: 0,
          items: {
            type: 'string',
            enum: ['receptionPicture', 'forecastsValidated', 'stockReassemblyMandatory'],
          },
        },
        send: {
          type: 'object',
          properties: {
            email: {
              type: 'object',
              properties: {
                order: {
                  type: 'object',
                  properties: {
                    default_cc: {
                      type: 'array',
                      minLength: 0,
                      items: {
                        type: 'string',
                        pattern: REGEX_MATCH_EMAIL,
                      },
                    },
                    default_body: {
                      type: 'string',
                    },
                    default_bcc: {
                      type: 'array',
                      minLength: 0,
                      items: {
                        type: 'string',
                        pattern: REGEX_MATCH_EMAIL,
                      },
                    },
                  },
                  required: ['default_cc', 'default_body', 'default_bcc'],
                  additionalProperties: false,
                },
                credit: {
                  type: 'object',
                  properties: {
                    default_cc: {
                      type: 'array',
                      minLength: 0,
                      items: {
                        type: 'string',
                        pattern: REGEX_MATCH_EMAIL,
                      },
                    },
                    default_body: {
                      type: 'string',
                    },
                    default_bcc: {
                      type: 'array',
                      minLength: 0,
                      items: {
                        type: 'string',
                        pattern: REGEX_MATCH_EMAIL,
                      },
                    },
                  },
                  required: ['default_cc', 'default_body', 'default_bcc'],
                  additionalProperties: false,
                },
              },
              required: ['order', 'credit'],
              additionalProperties: false,
            },
            edi: {
              type: 'object',
              properties: {
                partner_name: { type: 'string' },
                mode: {
                  type: 'string',
                  enum: ['multiple', 'simple'],
                },
              },
              required: ['partner_name', 'mode'],
              additionalProperties: false,
            },
          },
          oneOf: [
            {
              required: ['email'],
            },
            {
              required: ['edi'],
            },
          ],
          additionalProperties: false,
        },
      },
      required: [
        'createOrdersXLS',
        'displaySupplierProductPackagingWeight',
        'resetStockEveryDayForCentralKitchen',
        'displayStocks',
        'groupedOrder',
        'canOrderWithMin',
        'categoriesOrder',
        'constraints',
        'send',
      ],
      additionalProperties: false,
    },
  },
  '/users': {
    schema: {
      type: 'object',
      properties: {
        disableResetPassword: { type: 'boolean' },
        disableEdition: { type: 'boolean' },
      },
      required: ['disableEdition', 'disableResetPassword'],
      additionalProperties: false,
    },
  },
  '/backoffice/users/team': {
    schema: {
      type: 'object',
      properties: {
        disableCreation: { type: 'boolean' },
      },
      required: ['disableCreation'],
      additionalProperties: false,
    },
  },
};

export const getSchemaByPath = (path) => {
  const matchingFeature = SCHEMA_FEATURES_PROPS_VALIDATION[path];

  if (!matchingFeature) {
    return {};
  }

  return matchingFeature.schema;
};

export default {
  getSchemaByPath,
  SCHEMA_FEATURES_PROPS_VALIDATION,
};
