import { connect } from 'react-redux';
import { get } from 'lodash';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import {
  openSmallModal,
  openGenericModal,
  refreshGenericModal,
  closeGenericModal,
} from '@actions/modal';
import {
  showConfirmationMessage,
  showErrorMessage,
  showSuccessMessage,
} from '@actions/messageconfirmation';
import { updateIsSynchronizingCashierProductsStatus } from '@actions/product';

import { Button, Label } from '@commons/utils/styledLibraryComponents';
import { clientStatusCheck } from '@commons/utils/clientStatusCheck';
import { getBatchProductsValidation } from '@commons/Modals/ImportModal/utils/getBatchProductsValidation';
import { handleOpenedImportFileModal } from '@commons/Modals/ImportModal/utils/config';
import { IMPORT_STATE, IMPORT_TYPE, SWITCH_STEP } from '@commons/Modals/ImportModal/utils/constant';
import { postBatchProductsToCreate } from '@commons/Modals/ImportModal/utils/postBatchProductsToCreate';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';
import WhiteCard from '@commons/WhiteCard';

import { getClientInfo } from '@selectors/client';

import { cashierProductService } from '@services/cashierProduct';
import { productsService } from '@services/backoffice/products';

import theme from '@theme';

import { IMPORT_MODAL_STEPS } from '@admin/suppliers/supplierProducts/utils/constants';

import { downloadFile } from '@backoffice/utils';

import { ProductsFromCashierProductsExportModal } from '@backoffice/components/ProductsFromCashierProductsExportModal';

import { Container, WhiteCardsContainer, ModalTextContent } from './styledComponents';
import DuplicateSetupWhiteCard from './components/DuplicateSetupWhiteCard';

const TIMEOUT_GET_SYNC_STATUS = 5000; // in ms

const ENUM_SYNCHRO_CASHIER_PRODUCT = {
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
  REVOKED: 'REVOKED',
};

const createProductsFromCashierProductsModalContent = () => (
  <ModalTextContent>
    {i18next.t('BACKOFFICE.PRODUCTS.CREATE_INPULSE_PRODUCTS_MODAL_CONTENT')}
  </ModalTextContent>
);

export const exportProductsFromCashierProducts = async (props) => {
  const {
    user,
    client: { clientId, clientName },
    openModal,
    showMessage,
  } = props;

  openModal({
    component: ProductsFromCashierProductsExportModal,
    title: i18next.t('BACKOFFICE.PRODUCTS.CASHIER_PRODUCTS_BUTTON_EXPORT_IN_PROGRESS'),
    user,
    clientId,
    ClientName: clientName,
    showMessage: showMessage,
  });
};

export const BackOfficeProductsAssociation = (props) => {
  const {
    user,
    client: { clientId, clientStatus },
    match,
    showMessage,
    openGenericModal,
    isSynchronizingCashierProducts,
    updateIsSynchronizingCashierProductsStatus,
  } = props;

  const path = get(match, 'path');

  const [cashierProducts, setCashierProducts] = useState([]);
  const [displayExportButton, setDisplayExportButton] = useState(false);
  const [isCreatingProductsFromCashierProducts, setIsCreatingProductsFromCashierProducts] =
    useState(false);
  const [isDownloadingExcel, setIsDownloadingExcel] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [currentStep, setCurrentStep] = useState(IMPORT_MODAL_STEPS.SELECT_FILE);
  const [errorFile, setErrorFile] = useState(null);
  const [batchProductsToCreate, setBatchProductsToCreate] = useState(null);

  const getCashierProductsOfClient = async () => {
    try {
      const result = await productsService.getCashierProductsOfClient(clientId);
      setCashierProducts(result);
      setDisplayExportButton(!!result.length);
    } catch (err) {
      showMessage(i18next.t('BACKOFFICE.PRODUCTS.CASHIER_PRODUCTS_RETRIEVING_ERROR'), 'error');
    }
  };

  useEffect(() => {
    (async function loadData() {
      await getCashierProductsOfClient(clientId);
    })();
  }, []);

  const syncCashierProduct = async () => {
    try {
      await cashierProductService.syncCashierProduct(clientId);

      updateIsSynchronizingCashierProductsStatus(true);

      showMessage(i18next.t('ADMIN.CASHIER_PRODUCTS.RESTART_SYNC_SUCCESS'), 'success');
    } catch (error) {
      showMessage(i18next.t('ADMIN.CASHIER_PRODUCTS.RESTART_SYNC_ERROR'), 'error');
    }
  };

  useEffect(() => {
    if (!isSynchronizingCashierProducts) {
      return;
    }

    const getClientInterval = setInterval(() => {
      (async () => {
        try {
          const syncStatus = await cashierProductService.getCashierProductSyncStatus();

          if (syncStatus.state === ENUM_SYNCHRO_CASHIER_PRODUCT.SUCCESS) {
            showMessage(i18next.t('ADMIN.CASHIER_PRODUCTS.SYNC_STATUS_SUCCESS'), 'success');
            updateIsSynchronizingCashierProductsStatus(false);
          }
          if (
            syncStatus.state === ENUM_SYNCHRO_CASHIER_PRODUCT.FAILURE ||
            syncStatus.state === ENUM_SYNCHRO_CASHIER_PRODUCT.REVOKED
          ) {
            showMessage(i18next.t('ADMIN.CASHIER_PRODUCTS.SYNC_STATUS_ERROR'), 'error');
            updateIsSynchronizingCashierProductsStatus(false);
          }
        } catch (error) {
          showMessage(i18next.t('ADMIN.CASHIER_PRODUCTS.SYNC_STATUS_ERROR'), 'error');
          updateIsSynchronizingCashierProductsStatus(false);
        }
      })();
    }, TIMEOUT_GET_SYNC_STATUS);

    return () => clearInterval(getClientInterval);
  }, [isSynchronizingCashierProducts]);

  const createProductsFromCashierProducts = async () => {
    try {
      setIsCreatingProductsFromCashierProducts(true);

      showMessage(
        i18next.t('BACKOFFICE.PRODUCTS.CREATE_INPULSE_PRODUCTS_FROM_CASHIER_PRODUCTS_IN_PROGRESS'),
        'success',
      );

      await productsService.createProductsFromCashierProducts(clientId);

      showMessage(
        i18next.t('BACKOFFICE.PRODUCTS.CREATE_INPULSE_PRODUCTS_FROM_CASHIER_PRODUCTS_SUCCESS'),
        'success',
      );
    } catch (error) {
      showMessage(
        i18next.t('BACKOFFICE.PRODUCTS.CREATE_INPULSE_PRODUCTS_FROM_CASHIER_PRODUCTS_ERROR'),
        'error',
      );
    } finally {
      setIsCreatingProductsFromCashierProducts(false);
    }
  };

  const createProductsFromCashierProductsModal = () => {
    const params = {
      type: 'warning',
      width: '560px',
      height: 'auto',
      icon: '/images/inpulse/add-white-small.svg',
      title: i18next.t('BACKOFFICE.PRODUCTS.CREATE_INPULSE_PRODUCTS'),
      component: createProductsFromCashierProductsModalContent,
      actions: [
        {
          key: 0,
          color: 'inpulse-outline',
          label: i18next.t('GENERAL.CANCEL'),
          icon: '/images/inpulse/close-black-small.svg',
        },
        {
          key: 1,
          color: 'inpulse-default',
          label: i18next.t('GENERAL.CREATE'),
          icon: '/images/inpulse/add-white-small.svg',
          handleClick: () => createProductsFromCashierProducts(),
        },
      ],
    };
    openGenericModal(params);
  };

  const downloadProductsCreationTemplate = async () => {
    setIsDownloadingExcel(true);

    try {
      const data = await productsService.downloadProductsCreationTemplate();

      downloadFile(data, `creation_multiple_products`);
    } catch (error) {
      props.showMessage(i18next.t('BACKOFFICE.PRODUCTS.DOWNLOAD_TEMPLATE_ERROR'), 'error');
    }

    setIsDownloadingExcel(false);
  };

  useEffect(() => {
    if (!selectedFile) {
      return;
    }

    handleOpenedImportFileModal(
      {
        currentStep: currentStep,
        importType: IMPORT_TYPE.PRODUCTS,
        handleSwitchStep: handleSwitchStep,
        handleFileChange: handleFileChange,
        selectedFile: selectedFile,
        exportErrorData: errorFile,
        cleanImportModal: cleanUpModalImport,
      },
      props.openGenericModal,
      props.refreshGenericModal,
      get(props, 'modal.GenericModalBool', false),
    );
  }, [selectedFile, currentStep]);

  const handleSwitchStep = async (changeStep) => {
    if (changeStep === SWITCH_STEP.PREVIOUS) {
      cleanUpModalImport();
      return;
    }

    if (currentStep === IMPORT_STATE.SELECT_FILE) {
      await getBatchProductsValidation(
        {
          pageLoaded: props.pageLoaded,
          pageLoading: props.pageLoading,
          user,
        },
        {
          setBatchProductsToCreate,
          setErrorFile,
          setCurrentStep,
        },
        selectedFile,
      );
    }

    if (currentStep === IMPORT_STATE.ERROR_FILE) {
      cleanUpModalImport();
    }

    if (currentStep === IMPORT_STATE.VALIDATED_FILE) {
      await postBatchProductsToCreate(
        {
          pageLoaded: props.pageLoaded,
          pageLoading: props.pageLoading,
          user,
          showSuccessMessage: props.showSuccessMessage,
          showErrorMessage: props.showErrorMessage,
          cleanUpModalImport,
        },
        batchProductsToCreate,
      );
    }
  };

  const handleFileChange = (file) => {
    setSelectedFile(file);
  };

  const cleanUpModalImport = () => {
    setSelectedFile(null);
    setErrorFile(null);
    setCurrentStep(IMPORT_STATE.SELECT_FILE);
    setBatchProductsToCreate(null);
    props.closeGenericModal();
  };

  const openImportProductsModal = () => {
    handleOpenedImportFileModal(
      {
        currentStep: currentStep,
        importType: IMPORT_TYPE.PRODUCTS,
        handleSwitchStep: handleSwitchStep,
        handleFileChange: handleFileChange,
        selectedFile: selectedFile,
        exportErrorData: errorFile,
        cleanImportModal: cleanUpModalImport,
      },
      props.openGenericModal,
      props.refreshGenericModal,
      get(props, 'modal.GenericModalBool', false),
    );
  };

  return (
    <Container>
      <NavigationBreadCrumb featurePath={path} />
      <WhiteCardsContainer>
        <DuplicateSetupWhiteCard />
        <WhiteCard
          content={i18next.t('BACKOFFICE.PRODUCTS.CASHIER_PRODUCTS_START_SYNCHRONIZATION')}
          contentColor={theme.colors?.greys.darker}
          renderContent={
            <Button
              animation={{
                rotate: isSynchronizingCashierProducts,
              }}
              buttonCustomStyle={{
                width: 'fit-content',
              }}
              color={'inpulse-default'}
              fontSize={14}
              formatText={false}
              handleClick={() => {
                if (isSynchronizingCashierProducts) {
                  return;
                }

                syncCashierProduct(
                  clientId,
                  updateIsSynchronizingCashierProductsStatus,
                  showMessage,
                );
              }}
              icon={'/images/inpulse/sync-white-small.svg'}
              iconCustomStyle={{ width: '20px', height: '20px' }}
              isDisabled={isSynchronizingCashierProducts}
              label={i18next.t('GENERAL.SYNC')}
            />
          }
          title={i18next.t('BACKOFFICE.PRODUCTS.CASHIER_PRODUCTS_SYNCHRONIZATION_TITLE')}
        />
        <WhiteCard
          content={i18next.t(
            'BACKOFFICE.PRODUCTS.CASHIER_PRODUCTS_GENERATE_PRODUCTS_FIRST_SUBTITLE',
          )}
          contentColor={theme.colors?.greys.darker}
          renderContent={
            <Button
              buttonCustomStyle={{
                width: 'fit-content',
              }}
              fontSize={14}
              formatText={false}
              handleClick={() => {
                if (displayExportButton) {
                  return exportProductsFromCashierProducts(props);
                }

                return true;
              }}
              icon={displayExportButton ? '/images/inpulse/file-download-white-small.svg' : ''}
              isDisabled={!displayExportButton}
              label={
                displayExportButton
                  ? i18next.t('GENERAL.DOWNLOAD')
                  : i18next.t('BACKOFFICE.PRODUCTS.CASHIER_PRODUCTS_BUTTON_NO_CASHIER_PRODUCTS')
              }
            />
          }
          title={
            <>
              {i18next.t('BACKOFFICE.PRODUCTS.CASHIER_PRODUCTS_GENERATE_PRODUCTS_TITLE')}
              <Label
                background={theme.colors?.greys.darkest}
                color={theme.colors?.greys.lightest}
                type="plain"
              >
                {cashierProducts.length}
              </Label>
            </>
          }
        />
        <WhiteCard
          content={i18next.t(
            'BACKOFFICE.PRODUCTS.CREATE_INPULSE_PRODUCTS_FROM_INPULSE_CASHIER_PRODUCTS',
          )}
          contentColor={theme.colors?.greys.darker}
          renderContent={
            <Button
              animation={{
                rotate: isCreatingProductsFromCashierProducts,
              }}
              buttonCustomStyle={{
                width: 'fit-content',
              }}
              color={'inpulse-default'}
              fontSize={14}
              formatText={false}
              handleClick={() => {
                cashierProducts.length && createProductsFromCashierProductsModal();
              }}
              icon={
                isCreatingProductsFromCashierProducts
                  ? '/images/inpulse/sync-white-small.svg'
                  : '/images/inpulse/add-white-small.svg'
              }
              isDisabled={
                !cashierProducts.length ||
                isCreatingProductsFromCashierProducts ||
                !clientStatusCheck(clientStatus, 'onboarding')
              }
              label={i18next.t('GENERAL.CREATE')}
            />
          }
          title={
            <>
              {i18next.t('BACKOFFICE.PRODUCTS.CREATE_INPULSE_PRODUCTS_FROM_CASHIER_PRODUCTS')}
              <Label
                background={theme.colors?.greys.darkest}
                color={theme.colors?.greys.lightest}
                type="plain"
              >
                {cashierProducts.length}
              </Label>
            </>
          }
        />
        <WhiteCard
          content={`${i18next.t('BACKOFFICE.PRODUCTS.DOWNLOAD_TEMPLATE_TITLE')}.`}
          contentColor={theme.colors?.greys.darker}
          renderContent={
            <Button
              animation={{ rotate: isDownloadingExcel }}
              buttonCustomStyle={{
                width: 'fit-content',
              }}
              color={'inpulse-default'}
              fontSize={14}
              formatText={false}
              handleClick={() => downloadProductsCreationTemplate()}
              icon={
                isDownloadingExcel
                  ? '/images/inpulse/loader-white-small.svg'
                  : '/images/inpulse/file-download-white-small.svg'
              }
              isDisabled={isDownloadingExcel}
              label={i18next.t('GENERAL.DOWNLOAD')}
            />
          }
          title={i18next.t('BACKOFFICE.PRODUCTS.DOWNLOAD_TEMPLATE_TITLE')}
        />
        <WhiteCard
          content={`${i18next.t('BACKOFFICE.PRODUCTS.IMPORT_TEMPLATE_TITLE')}.`}
          renderContent={
            <Button
              buttonCustomStyle={{ width: 'fit-content' }}
              color={'inpulse-default'}
              handleClick={() => openImportProductsModal()}
              icon={'/images/inpulse/file-upload-white-small.svg'}
              isDisabled={!clientStatusCheck(clientStatus, 'onboarding')}
              label={i18next.t('GENERAL.IMPORT')}
            />
          }
          title={i18next.t('BACKOFFICE.PRODUCTS.IMPORT_TEMPLATE_TITLE')}
        />
      </WhiteCardsContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  isSynchronizingCashierProducts: state.baseReducer.isSynchronizingCashierProducts,
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  openModal: (params) => {
    dispatch(openSmallModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
  updateIsSynchronizingCashierProductsStatus: (isSynchronizingCashierProducts) => {
    dispatch(updateIsSynchronizingCashierProductsStatus(isSynchronizingCashierProducts));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  closeGenericModal: (params) => {
    dispatch(closeGenericModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeProductsAssociation);
