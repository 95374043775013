import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;

  height: 500px;
  margin-bottom: 60px;
  flex: 1;
  padding: 24px;
  background: ${(props) => props.theme.colors.greys.lightest};
  box-shadow: ${(props) => props.theme.shadows.short};
  border-radius: ${(props) => props.theme.borders.radius.strong};
`;

export const ListContainer = styled.div`
  width: 100%;
  height: 100%;

  > div > div > div {
    overflow-x: visible;
  }

  ${({ hasForecasts }) => {
    if (!hasForecasts) {
      return css`
        filter: blur(4px);
      `;
    }
  }}
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
`;
