import { connect } from 'react-redux';
import { get, intersection } from 'lodash';
import i18next from 'i18next';
import React, { useContext, useEffect, useState } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { openGenericModal, refreshGenericModal } from '@actions/modal';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { Button, Dropdown, ListView } from '@commons/utils/styledLibraryComponents';
import { ConfirmationModal } from '@commons/Modals/ConfirmationModal';
import { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';
import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_CONFIRM_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { STANDARD_LISTVIEW_PADDING } from '@commons/constants/listViewProps';
import EmptyState from '@commons/EmptyState';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import { canCreateStorageArea } from '@selectors/actions/storageAreaActions';
import { getAuthorizedActionsForPaths } from '@selectors/featureProps';
import { getClientInfo } from '@selectors/client';

import { storeSupplierProductMapping as storeSupplierProductMappingService } from '@services/storeSupplierProductMapping';
import storageAreaService from '@services/storageArea';

import { Container, HeaderContainer, ListViewContainer } from './styledComponents';
import actionsUtils from './utils/actionsUtils';
import columnsUtils from './utils/columnsUtils';
import exportUtils from './utils/exportUtils';
import modalsUtils, { ADD_REFERENCE_TO_STORAGE_AREA_MODAL_STEPS } from './utils/modalsUtils';

import StorageAreaProviderContext from '@context/StorageAreaContext';

const StorageAreas = (props) => {
  const {
    match: { path },
    history,
    client: { clientId, storeName, hasLocalCatalogs, hasMultipleBrands },
    pageLoading,
    pageLoaded,
    showErrorMessage,
    showSuccessMessage,
    openGenericModal,
    refreshGenericModal,
    stores,
    authorizedActions,
  } = props;

  const clientStoreName = getClientStoreNameTranslation(storeName, false);

  const { selectedStores, setSelectedStores } = useContext(StorageAreaProviderContext);

  const [isLoading, setIsLoading] = useState(true);
  const [actions, setActions] = useState([]);
  const [rowActions, setRowActions] = useState([]);
  const [columns] = useState(columnsUtils.getListColumns(clientStoreName));
  const [displayEmptyState, setDisplayEmptyState] = useState(false);

  const [storageAreas, setStorageAreas] = useState([]);
  const [filteredStorageAreas, setFilteredStorageAreas] = useState([]);
  const [selectedStorageAreas, setSelectedStorageAreas] = useState([]);

  const [storageAreaToCopy, setStorageAreaToCopy] = useState(null);

  // Adding reference action
  const [supplierProductsToAdd, setSupplierProductsToAdd] = useState([]);
  const [spAvailableForAssociation, setSPAvailableForAssociation] = useState([]);
  const [recipesToAdd, setRecipesToAdd] = useState([]);
  const [currentStep, setCurrentStep] = useState(
    ADD_REFERENCE_TO_STORAGE_AREA_MODAL_STEPS.ADD_SUPPLIER_PRODUCTS,
  );

  /** USE EFFECT */

  useEffect(() => {
    (async () => {
      await getStorageAreas();
    })();
  }, []);

  useEffect(() => {
    if (!stores.length) {
      return;
    }

    // Use those from context if previously set
    setSelectedStores(selectedStores.length > 0 ? selectedStores : stores);
  }, [stores]);

  useEffect(() => {
    const selectedStoreIdsSet = new Set(selectedStores.map(({ id }) => id));

    const filteredSA = storageAreas.filter(({ storeId }) => selectedStoreIdsSet.has(storeId));

    setFilteredStorageAreas(filteredSA);
  }, [selectedStores, storageAreas]);

  useEffect(() => {
    if (!isLoading) {
      setDisplayEmptyState(!storageAreas.length);
    }
  }, [isLoading, storageAreas]);

  useEffect(() => {
    setActions(
      actionsUtils.getGlobalActions({
        authorizedActions,
        handleCopyParamModal,
        handleAddReferences,
        handleCreation: goToCreateStorageArea,
        handleDeletion: handleDeleteStorageAreas,
        handleExport: exportStorageAreas,
        selectedStorageAreas,
        storageAreas: filteredStorageAreas,
      }),
    );

    setRowActions(actionsUtils.getRowActions(handleDeleteStorageAreas, authorizedActions));
  }, [filteredStorageAreas, selectedStorageAreas]);

  useEffect(() => {
    if (!storageAreaToCopy) {
      return;
    }

    handleCopyParamModal(true);
  }, [storageAreaToCopy]);

  useEffect(() => {
    if (!selectedStorageAreas.length) {
      return;
    }

    const storeIds = selectedStorageAreas.map(({ storeId }) => storeId);

    const alreadyAssociatedEntityIds = intersection(
      ...selectedStorageAreas.map(({ entityIds }) => entityIds),
    );

    openAddReferencesModal(storeIds, spAvailableForAssociation, alreadyAssociatedEntityIds, true);
    // recipesToAdd dependency is required when refreshing modal params for the handleAddingReferences callback
  }, [currentStep, recipesToAdd]);

  /** FUNCTIONS */

  const handleCopyParamModal = (shouldRefreshModal = false) => {
    const selectedStorageAreaIds = new Set(selectedStorageAreas.map(({ id }) => id));

    const params = modalsUtils.getCopyParamModalConfig({
      handleParamsCopy,
      storageAreaToCopy,
      availableStorageAreas: storageAreas.filter(({ id }) => !selectedStorageAreaIds.has(id)),
      setStorageAreaToCopy,
      stores,
    });

    if (shouldRefreshModal) {
      refreshGenericModal(params);
    }

    openGenericModal(params);
  };

  const handleAddReferences = async () => {
    const storeIds = selectedStorageAreas.map(({ storeId }) => storeId);

    if (!storeIds) {
      return;
    }

    const alreadyAssociatedSupplierProductIds = intersection(
      ...selectedStorageAreas.map(({ supplierProductIds }) => supplierProductIds),
    );

    const alreadyAssociatedEntityIds = intersection(
      ...selectedStorageAreas.map(({ entityIds }) => entityIds),
    );

    const supplierProducts = await getSPAvailableForAssociation(
      storeIds,
      alreadyAssociatedSupplierProductIds,
    );

    setSPAvailableForAssociation(supplierProducts);

    openAddReferencesModal(storeIds, supplierProducts, alreadyAssociatedEntityIds);
  };

  const openAddReferencesModal = (
    storeIds,
    supplierProducts,
    alreadyAssociatedEntityIds,
    shouldRefreshModal = false,
  ) => {
    const params = modalsUtils.getAddReferenceStepModalConfig({
      // Handle steps
      currentStep,
      setCurrentStep,
      // SupplierProduct steps
      hasLocalCatalogs,
      setSpToBeAdded: setSupplierProductsToAdd,
      availableSPForAssociation: supplierProducts,
      alreadySelectedSupplierProducts: supplierProductsToAdd,
      // Recipe steps
      clientId,
      hasMultipleBrands,
      setRecipesToBeAdded: setRecipesToAdd,
      storeIds,
      alreadyMappedRecipes: alreadyAssociatedEntityIds.map((id) => ({ id })),
      // Final callbacks
      handleAddingReferences: () => {
        addStorageAreaMappings(
          selectedStorageAreas.map(({ id }) => id),
          supplierProductsToAdd.map(({ id }) => id),
          recipesToAdd.map(({ entityId }) => entityId),
        );
      },
      resetModal: () => {
        setSupplierProductsToAdd([]);
        setRecipesToAdd([]);
      },
    });

    if (shouldRefreshModal) {
      refreshGenericModal(params);
      return;
    }

    openGenericModal(params);
  };

  const getSPAvailableForAssociation = async (storeIds, supplierProductsToIgnore = []) => {
    pageLoading();

    try {
      const supplierProducts = await storeSupplierProductMappingService.getByStoreIds(
        storeIds,
        supplierProductsToIgnore,
      );

      return supplierProducts;
    } catch {
      showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.FETCH_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const handleParamsCopy = async () => {
    pageLoading();
    setIsLoading(true);

    try {
      await storageAreaService.copyStorageAreaParams(
        storageAreaToCopy.id,
        selectedStorageAreas.map(({ id }) => id),
      );

      setStorageAreaToCopy(null);

      showSuccessMessage(i18next.t('ADMIN.STORAGE_AREAS.COPY_STORAGE_AREA_PARAMS_SUCCESS'));

      getStorageAreas();
    } catch {
      showErrorMessage(i18next.t('ADMIN.STORAGE_AREAS.COPY_STORAGE_AREA_PARAMS_ERROR'));
    }
  };

  const getStorageAreas = async () => {
    pageLoading();

    try {
      const storageAreas = await storageAreaService.getStorageAreasByClientId();
      const formattedStorageAreas = storageAreas.map(
        ({ id, name, supplierProductIds, entityIds, storeId, storeName }) => ({
          id,
          name,
          associatedSPsCount: supplierProductIds.length,
          associatedEntitiesCount: entityIds.length,
          storeId,
          storeName,
          supplierProductIds,
          entityIds,
        }),
      );

      setStorageAreas(formattedStorageAreas);
    } catch {
      showErrorMessage(i18next.t('ADMIN.STORAGE_AREAS.FETCHING_ERROR'));
    } finally {
      setIsLoading(false);
      pageLoaded();
    }
  };

  const deleteStorageAreas = async (storageAreas) => {
    pageLoading();
    setIsLoading(true);

    try {
      const storageAreaIds = storageAreas.map(({ id }) => id);
      await storageAreaService.disableStorageAreas(clientId, storageAreaIds);

      showSuccessMessage(
        i18next.t('ADMIN.STORAGE_AREAS.DELETION_SUCCESS', { count: storageAreas.length }),
      );

      await getStorageAreas();
    } catch {
      showErrorMessage(i18next.t('ADMIN.STORAGE_AREAS.DELETION_ERROR'));
    } finally {
      setIsLoading(false);
      pageLoaded();
    }
  };

  const addStorageAreaMappings = async (storageAreaIds, supplierProductIds, entityIds) => {
    pageLoading();
    setIsLoading(true);

    try {
      await storageAreaService.addStorageAreaMappings(
        storageAreaIds,
        supplierProductIds,
        entityIds,
      );

      showSuccessMessage(i18next.t('ADMIN.STORAGE_AREAS.ADD_REFERENCES_STORAGE_AREA_SUCCESS'));

      await getStorageAreas();
    } catch {
      showErrorMessage(i18next.t('ADMIN.STORAGE_AREAS.ADD_REFERENCES_STORAGE_AREA_ERROR'));
    } finally {
      setIsLoading(false);
      pageLoaded();
    }
  };

  const goToCreateStorageArea = () => {
    history.push('/admin/inventories/storage-areas/create');
  };

  const goToStorageAreaDetailsPage = (storageArea) => {
    history.push(`/admin/inventories/storage-areas/${storageArea.id}/details`);
  };

  const handleDeleteStorageAreas = (items) => {
    const count = items.length;

    const params = {
      type: 'warning',
      width: '542px',
      height: 'auto',
      icon: '/images/inpulse/warning-white-small.svg',
      title: i18next.t('ADMIN.STORAGE_AREAS.LABEL_DELETE_ACTION', {
        count,
      }),
      component: ConfirmationModal,
      data: {
        content: i18next.t('ADMIN.STORAGE_AREAS.DELETE_CONFIRMATION_MESSAGE', {
          count,
          name: get(items, '[0].name', ''),
        }),
      },
      actions: [
        GENERIC_MODAL_CANCEL_BUTTON(),
        {
          ...GENERIC_MODAL_CONFIRM_BUTTON(),
          handleClick: async () => await deleteStorageAreas(items),
        },
      ],
    };
    openGenericModal(params);
  };

  const exportStorageAreas = () => {
    exportUtils.exportStorageAreas(
      selectedStorageAreas.length ? selectedStorageAreas : filteredStorageAreas,
      clientStoreName,
    );
  };

  const renderNoStorageAreas = () => (
    <EmptyState
      label={i18next.t('ADMIN.STORAGE_AREAS.EMPTY_STATE_TITLE')}
      labelColor={ENUM_COLORS.IP_BLACK_1}
      labelFont={ENUM_FONTS.H2_INTER}
      renderActionButton={() =>
        canCreateStorageArea(authorizedActions) && (
          <Button
            color={'inpulse-default'}
            handleClick={goToCreateStorageArea}
            icon={'/images/inpulse/add-white-small.svg'}
            label={i18next.t('GENERAL.CREATE')}
          />
        )
      }
      subtitle={i18next.t('ADMIN.STORAGE_AREAS.EMPTY_STATE_SUBTITLE')}
      subtitleMargin={'8px'}
    />
  );

  return (
    <ListViewContainer>
      <NavigationBreadCrumb featurePath={path} />
      <Container>
        {displayEmptyState ? (
          renderNoStorageAreas()
        ) : (
          <ListView
            actionOnClick={goToStorageAreaDetailsPage}
            actions={!isLoading && actions}
            columns={columns}
            data={filteredStorageAreas}
            isLoading={isLoading}
            padding={STANDARD_LISTVIEW_PADDING}
            renderEmptyState={() => <EmptyState />}
            renderFilterButton={() => (
              <HeaderContainer>
                <Dropdown
                  iconSrc={'/images/inpulse/store-black-small.svg'}
                  isDisabled={isLoading}
                  isUniqueSelection={false}
                  items={stores}
                  selectedItems={selectedStores}
                  isRequired
                  onSelectionChange={setSelectedStores}
                />
              </HeaderContainer>
            )}
            rowActions={rowActions}
            setSelectedItems={setSelectedStorageAreas}
          />
        )}
      </Container>
    </ListViewContainer>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
  stores: state.baseReducer.activeStores,
  authorizedActions: getAuthorizedActionsForPaths(state.baseReducer.userRights, [
    '/admin/inventories/storage-areas',
    '/admin/inventories/storage-areas/create',
    '/admin/inventories/storage-areas/:id/details',
  ]),
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StorageAreas);
