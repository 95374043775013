import { connect } from 'react-redux';
import { get } from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

// Commons
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { getTheme } from '@commons/utils/theme';
import { ListView, Button } from '@commons/utils/styledLibraryComponents';
import EmptyState from '@commons/EmptyState';
import Text, { ENUM_FONTS, ENUM_COLORS } from '@commons/Text';
import UpgradePlanOverlay from '@commons/UpgradePlanOverlay';

// Actions
import { loading, loadingSuccess } from '@actions/loading';
import { openGenericModal, refreshGenericModal } from '@actions/modal';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

// Selectors
import { getClientInfo } from '@selectors/client';
import { getPrevHorizon } from '@selectors/featureProps';

// Services
import { ingredient as ingredientService } from '@services/ingredient';

// Utils
import { exportBufferStocksXLS } from './utils/export';
import { getActions, getColumns, FAKE_DATA_FOR_PLAN_UPGRADE } from './utils/utils';
import { getBufferStockModalConfig } from './utils/bufferStockModal';

import { Container, HeaderContainer, TitleContainer, ListContainer } from './styledComponents';

const IngredientBufferStocks = (props) => {
  const {
    ingredient,
    client: { hasCentralKitchens, storeName },
    user: { hasForecasts },
    showErrorMessage,
    showSuccessMessage,
    openGenericModal,
    refreshGenericModal,
    pageLoading,
    pageLoaded,
    prevHorizon,
  } = props;

  const theme = getTheme();
  const [actions, setActions] = useState([]);

  const clientStoreNameSingular = getClientStoreNameTranslation(storeName);
  const clientStoreNamePlural = getClientStoreNameTranslation(storeName, true);

  const [columns, setColumns] = useState(getColumns(hasCentralKitchens, false));
  const [associatedStores, setAssociatedStores] = useState([]);

  // Modal props
  const [selectedStores, setSelectedStores] = useState([]);
  const [modalNbDays, setModalNbDays] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getAssociatedStores();
  }, []);

  useEffect(() => {
    const isModalOpened = get(props, 'modal.GenericModalBool', false);

    if (!isModalOpened) {
      return;
    }

    const config = getBufferStockModalConfig({
      handleBufferStockModalSave,
      modalNbDays,
      setModalNbDays,
      selectedStores,
      setSelectedStores,
      associatedStores,
      storeName: clientStoreNamePlural,
      prevHorizon,
    });

    refreshGenericModal(config);
  }, [modalNbDays, selectedStores]);

  useEffect(() => {
    setActions(
      getActions({
        openBufferStockModal,
        exportBufferStocksXLS,
        storeName: clientStoreNamePlural,
        associatedStores,
        hasCentralKitchens,
      }),
    );
  }, [selectedStores]);

  const getAssociatedStores = async () => {
    setIsLoading(true);

    if (!hasForecasts) {
      setAssociatedStores(FAKE_DATA_FOR_PLAN_UPGRADE);
      setColumns(getColumns(hasCentralKitchens, true)); //  has groups
      setIsLoading(false);
      return;
    }

    try {
      const storesBufferStocks = await ingredientService.getIngredientBufferStocks(ingredient.id);

      const formattedStoresBufferStocks = storesBufferStocks.map((bufferStock) => ({
        ...bufferStock,
        id: bufferStock.storeId,
      }));

      setAssociatedStores(formattedStoresBufferStocks);
      setSelectedStores(formattedStoresBufferStocks);

      const hasGroups = formattedStoresBufferStocks.some(({ groups }) => groups && groups.length);

      setColumns(getColumns(hasCentralKitchens, hasGroups));
    } catch {
      setAssociatedStores([]);
      showErrorMessage(
        i18next.t('ADMIN.INGREDIENTS.ASSOCIATED_STORES_FETCH_ERROR', {
          storeName: clientStoreNamePlural.toLowerCase(),
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleBufferStockModalSave = async () => {
    pageLoading();

    const storeIds = selectedStores.map(({ id }) => id);
    try {
      await ingredientService.upsertBufferStocks(storeIds, ingredient.id, modalNbDays);

      showSuccessMessage(i18next.t('ADMIN.INGREDIENTS.BUFFER_STOCKS_UPDATE_SUCCESS'));

      await getAssociatedStores();
    } catch {
      showErrorMessage(i18next.t('ADMIN.STORES.UPDATE_BUFFER_STOCK_ERROR'));
    } finally {
      setModalNbDays(null);
      setSelectedStores(associatedStores);
      pageLoaded();
    }
  };

  const openBufferStockModal = () => {
    const config = getBufferStockModalConfig({
      handleBufferStockModalSave,
      modalNbDays,
      setModalNbDays,
      selectedStores,
      setSelectedStores,
      associatedStores,
      storeName: clientStoreNamePlural,
      prevHorizon,
    });

    openGenericModal(config);
  };

  if (hasForecasts && !isLoading && !associatedStores.length) {
    return (
      <Container>
        <EmptyState
          backgroundColor={theme.colors.greys.lightest}
          label={i18next.t('ADMIN.INGREDIENTS.BUFFER_STOCK_EMPTY_STATE_TITLE', {
            storeName: clientStoreNameSingular,
          })}
          labelColor={ENUM_COLORS.IP_BLACK_1}
          labelFont={ENUM_FONTS.H2_INTER}
          subtitle={i18next.t('ADMIN.INGREDIENTS.BUFFER_STOCK_EMPTY_STATE_SUBTITLE', {
            storeName: clientStoreNamePlural,
          })}
          subtitleMargin={'8px'}
        />
      </Container>
    );
  }

  return (
    <Container>
      {!hasForecasts && <UpgradePlanOverlay />}

      <ListContainer hasForecasts={hasForecasts}>
        <HeaderContainer>
          <TitleContainer>
            <Text font={ENUM_FONTS.H2}>
              {i18next.t('ADMIN.INGREDIENTS.ASSOCIATED_STORE_WHITECARD_TITLE', {
                storeName: clientStoreNamePlural,
              })}
            </Text>
            <Text font={ENUM_FONTS.H2}>{associatedStores.length}</Text>
          </TitleContainer>
          <Button
            actions={actions}
            color={'inpulse-outline'}
            iconDropdown={'/images/inpulse/carret-black.svg'}
            iconOnLeft={false}
            label={i18next.t('GENERAL.ACTIONS')}
          />
        </HeaderContainer>
        <ListView
          columns={columns}
          data={associatedStores}
          defaultOrderBy={'nbDays'}
          defaultOrderType={'desc'}
          isLoading={isLoading}
          renderEmptyState={() => <EmptyState backgroundColor={theme.colors.greys.lightest} />}
          disableFooter
          hideSearchbar
        />
      </ListContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
  user: state.baseReducer.user,
  modal: state.modalReducer,
  prevHorizon: getPrevHorizon(state.baseReducer.userRights),
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(IngredientBufferStocks);
