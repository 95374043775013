import axios from '../core/http';

import { APIcaller, APIcallerv2 } from './APIcaller';

import { config } from '../config';

const baseApiUrl = `${config.baseApiUrl}/v1`;

const syncCashierStore = (clientId) =>
  axios
    .post(
      `${baseApiUrl}/admin/cashier-stores/sync`,
      {
        clientId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });

const createCashierStore = (cashierStore) =>
  axios
    .post(`${baseApiUrl}/admin/cashier-stores`, {
      cashierStores: [cashierStore],
      cashierConfigId: cashierStore.cashierConfigId,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const patchCashierStore = (cashierStore) =>
  axios
    .patch(
      `${baseApiUrl}/admin/cashier-stores/${cashierStore.id}`,
      { cashierStore },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });

const getCashierStoreSyncStatus = () => APIcallerv2.apiGet('/cashier-stores/sync/status');

const getCashierStoreInformations = () => APIcallerv2.apiGet('/cashier-stores');

const deleteStoreCashierStoreMappingByCashierStoreId = (cashierStoreId) =>
  APIcaller.apiDelete(`/admin/cashier-stores/store-mapping/${cashierStoreId}`, {
    cashierStoreId,
  });

const getCashierStoreMappingsByClientId = () =>
  APIcallerv2.apiGet('/cashier-stores/export-mappings');

export const cashierStoreService = {
  syncCashierStore,
  getCashierStoreSyncStatus,
  getCashierStoreInformations,
  createCashierStore,
  patchCashierStore,
  deleteStoreCashierStoreMappingByCashierStoreId,
  getCashierStoreMappingsByClientId,
};
