import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { useForm, useWatch } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { buildSchema } from '@commons/GenericForm';
import { Button } from '@commons/utils/styledLibraryComponents';
import { ENUM_MODULE_NAME } from '@commons/utils/features';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { getTheme } from '@commons/utils/theme';
import { handleUnitsValidity } from '@commons/utils/units';
import Footer from '@commons/Footer/Footer';
import NavigationBar from '@commons/NavigationBar';
import Text, { ENUM_FONTS } from '@commons/Text';

import { getClientInfo } from '@selectors/client';

import { ingredient as ingredientService } from '@services/ingredient';
import centralService from '@services/central';

import { fetchProductsCategoriesOfClient } from '@admin/products/products/detail/components/ProductInfo/common/services';
import { getPriceIncludingVAT } from '@admin/products/products/detail/components/ProductInfo/utils/getPriceIncludingVAT';

import {
  KITCHEN_PRODUCT_FORM_INPUTS,
  SUPPLIER_PRODUCT_FORM_INPUTS,
} from '../utils/formInputsConfigurations';
import { uploadPicture } from '../utils/uploadPicture';

import {
  ButtonsContainer,
  ContentContainer,
  LabelContainer,
  LabelIcon,
  Step,
  StepsContainer,
} from './styledComponents';
import KitchenProductInformationsSection from './KitchenProductInformationsSection';
import KitchenProductSSPMSection from './KitchenProductSSPMSection';
import KitchenProductSupplierProductSection from './KitchenProductSupplierProductSection';

const CreateKitchenProduct = (props) => {
  const {
    client: { clientId, storeName },
    match,
    showSuccessMessage,
    showErrorMessage,
    pageLoading,
    pageLoaded,
    currency,
  } = props;

  const path = get(match, 'path');

  const theme = getTheme();

  const clientStoreNamePlural = getClientStoreNameTranslation(storeName, true);

  const history = useHistory();

  const STEPS_KITCHEN_PRODUCT_CREATION = [
    {
      key: 'details',
      label: i18next.t('GENERAL.INFORMATIONS'),
      Component: KitchenProductInformationsSection,
    },
    {
      key: 'associatedSupplierProduct',
      label: i18next.t('ADMIN.CENTRAL_PRODUCTS.ASSOCIATED_SUPPLIER_PRODUCT'),
      Component: KitchenProductSupplierProductSection,
    },
    {
      key: 'deliveredStore',
      label: i18next.t('ADMIN.CENTRAL_PRODUCTS.DELIVERED_STORE', {
        storeName: clientStoreNamePlural,
      }),
      Component: KitchenProductSSPMSection,
    },
  ];

  const STEPS_KITCHEN_PRODUCT_FIELDS = [
    ['name', 'category', 'subCategory', 'sku', 'shelfLife'],
    ['entity', 'loss', 'price', 'currency', 'vatRate', 'conversionsDisplayed'],
  ];

  const STEPS_MAX_INDEX = STEPS_KITCHEN_PRODUCT_CREATION.length - 1;

  const [product, setProduct] = useState({});
  const [productsCategories, setProductsCategories] = useState([]);
  const [productsSubCategories, setProductsSubCategories] = useState([]);
  const [availableStores, setAvailableStores] = useState([]);

  // Product picture
  const [picture, setPicture] = useState(null);
  const [selectedPictureFile, setSelectedPictureFile] = useState(null);

  // Product composition
  const [composition, setComposition] = useState({});
  const [selectedCompoType, setSelectedCompoType] = useState({});

  // Footer states
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [currentStep, setCurrentStep] = useState(STEPS_KITCHEN_PRODUCT_CREATION[0].key);
  const [stepIndex, setStepIndex] = useState(0);
  const [isSaveAlreadyTriggered, setIsSaveAlreadyTriggered] = useState(false);

  // Form
  const [supplierProduct, setSupplierProduct] = useState({});
  const [ingredients, setIngredients] = useState([]);

  // Supplier Product form inputs needs to be reactive since validators are based on user inputs (packaging & conversions)
  const [supplierProductFormInput, setSupplierProductFormInput] = useState({
    ingredients,
    packagingUnit: '',
    entityId: '',
    storeName,
    packagings: [],
    conversion: [],
    currency,
  });

  const allInputs = [
    ...KITCHEN_PRODUCT_FORM_INPUTS({
      productsCategories,
      setProductsCategories,
      productsSubCategories,
      setProductsSubCategories,
    }),
    ...SUPPLIER_PRODUCT_FORM_INPUTS(supplierProductFormInput),
  ];

  const productForm = useForm({
    defaultValues: {},
    resolver: yupResolver(buildSchema(allInputs)),
  });

  const formFields = useWatch({
    control: productForm.control,
  });

  // Retrieve all necessary data to configure the product
  useEffect(() => {
    pageLoading();

    (async function loadData() {
      try {
        await fetchProductsCategoriesOfClient(
          setProductsCategories,
          setProductsSubCategories,
          true,
        );

        await fetchEntities();

        productForm.setValue('currency', currency);
        productForm.setValue('entity', {});

        const stores = await centralService.getCentralKitchenSSPFMsOfUser();

        const activeStoresWithMappings = stores.filter(
          ({ active, supplierProfile, isKitchen }) =>
            active && !isEmpty(supplierProfile) && !isKitchen,
        );

        /*
          This formatting is needed because the ListView rely on the id to compose itself and its fragments
          and if we have the same id multiple times (which we can have because if we have multiple centrals with the same stores
          mapped to them we'll have the same stores with the same ids multiple times in this array) the ListView will encounter
          multiple problems and won't work properly
        */
        const formattedStores = activeStoresWithMappings.map((store) => ({
          ...store,
          storeId: store.id,
          id: `${store.id}|${store.supplierProfile.id}`,
        }));

        setAvailableStores(formattedStores);
      } catch {
        showErrorMessage(i18next.t('ADMIN.PRODUCTS.CATEGORIES_FETCH_ERROR'));
      } finally {
        pageLoaded();
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const fieldsToCheck = getFieldsToCheck();

      switch (stepIndex) {
        case 0:
          const fieldsValidation = await productForm.trigger(fieldsToCheck);
          setIsSaveDisabled((!fieldsValidation || isEmpty(composition)) && isSaveAlreadyTriggered);
          break;
        case 1:
          let isSaveCurrentlyDisabled = false;

          if (isSaveAlreadyTriggered) {
            const fieldsValidation = await productForm.trigger(fieldsToCheck);
            isSaveCurrentlyDisabled = !fieldsValidation || isEmpty(formFields.packagings);

            setIsSaveDisabled(isSaveCurrentlyDisabled);
          }

          setIsSaveAlreadyTriggered(isSaveCurrentlyDisabled);
          break;
        default:
          return;
      }
    })();
  }, [formFields, composition]);

  useEffect(() => {
    const areProductSPAndCompositionSameUnit = handleCompositionUnitCheck();
    setIsSaveDisabled(!areProductSPAndCompositionSameUnit);
  }, [supplierProduct, composition]);

  useEffect(() => {
    if (!isEmpty(composition) && composition.isIngredient) {
      productForm.setValue('entity', composition);
    }
  }, [composition]);

  useEffect(() => {
    const { price, vatRate, entity, packagingUnit, conversions } = formFields;
    const fieldsToUpdate = { priceHT: price };

    fieldsToUpdate.priceWithTaxes =
      price && vatRate
        ? getPriceIncludingVAT({
            priceHT: price,
            vatRate: vatRate,
          })
        : null;

    setProduct({ ...product, ...fieldsToUpdate });

    setSupplierProductFormInput({
      ...supplierProductFormInput,
      entityUnit: entity ? entity.unit : null,
      packagingUnit,
      conversions,
    });
  }, [formFields]);

  /**
   * Checks if the composition unit is the same as the central kitchen product associated supplier product's unit
   * or if it can be converted using the supplier product's conversions.
   *
   * @returns {boolean} Returns true if the units are the same or can be converted, false otherwise.
   */
  const handleCompositionUnitCheck = () => {
    if (isEmpty(supplierProduct) || isEmpty(composition)) {
      return;
    }

    const isUnitValid = handleUnitsValidity(
      composition.unit,
      get(supplierProduct, ['entity', 'unit'], null),
      get(supplierProduct, 'conversions', []),
      get(supplierProduct, 'packagings', []),
    );

    return isUnitValid;
  };

  const getFieldsToCheck = () => {
    if (stepIndex > STEPS_KITCHEN_PRODUCT_FIELDS.length - 1) {
      return;
    }

    return STEPS_KITCHEN_PRODUCT_FIELDS[stepIndex];
  };

  const fetchEntities = async () => {
    pageLoading();

    try {
      const clientIngredients = await ingredientService.getIngredients();
      const activeIngredients = clientIngredients.filter(({ active }) => active);

      setIngredients(activeIngredients);
    } catch {
      showErrorMessage(i18next.t('ADMIN.INGREDIENTS.FETCH_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const redirectToKitchenProductsList = () => {
    history.push({
      pathname: '/admin/kitchen-products/products',
    });
  };

  const handleSave = async () => {
    pageLoading();

    try {
      const pictureUrl = !isEmpty(selectedPictureFile)
        ? await uploadPicture(clientId, formFields.name, selectedPictureFile)
        : null;

      const formattedPayload = {
        ...formFields,
        clientId,
        product: {
          ...product,
          name: formFields.name,
          category: get(formFields, 'category.name', null),
          categoryId: get(formFields, 'category.id', null),
          subCategory: get(formFields, 'subCategory.name', null),
          subCategoryId: get(formFields, 'subCategory.id', null),
          sku: formFields.sku || null,
          shelfLife: formFields.shelfLife || null,
          vatRate: supplierProduct.vatRate,
          img: pictureUrl,
        },
        supplierProduct: {
          ...supplierProduct,
          entityId: supplierProduct.entity.id,
          loss: formFields.loss || null,
          img: pictureUrl,
          conversions: supplierProduct.conversions,
        },
      };

      await centralService.createKitchenProduct(formattedPayload);

      showSuccessMessage(i18next.t('ADMIN.CENTRAL_PRODUCTS.CREATION_SUCCESS'));

      redirectToKitchenProductsList();
    } catch {
      showErrorMessage(i18next.t('ADMIN.CENTRAL_PRODUCTS.CREATION_SAVE_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const nextStepHandler = async () => {
    const fieldsToCheck = getFieldsToCheck();
    const fieldsValidation = await productForm.trigger(fieldsToCheck);

    switch (stepIndex) {
      case 0:
        setIsSaveAlreadyTriggered(true);

        if (!fieldsValidation || isEmpty(composition)) {
          setIsSaveDisabled(true);

          return;
        }

        break;
      case 1:
        const isSaveCurrentlyDisabled = !fieldsValidation || isEmpty(formFields.packagings);

        setIsSaveDisabled(isSaveCurrentlyDisabled);

        if (isSaveCurrentlyDisabled) {
          setIsSaveAlreadyTriggered(true);
          return;
        }

        break;
      default:
    }

    if (stepIndex < STEPS_MAX_INDEX) {
      setCurrentStep(STEPS_KITCHEN_PRODUCT_CREATION[stepIndex + 1].key);
      setStepIndex(stepIndex + 1);
      setIsSaveAlreadyTriggered(false);
    }

    if (stepIndex === STEPS_MAX_INDEX) {
      handleSave();
    }
  };

  const previousStepHandler = () => {
    const areProductSPAndCompositionSameUnit = handleCompositionUnitCheck();
    setIsSaveDisabled(!areProductSPAndCompositionSameUnit);

    if (stepIndex > 0) {
      setCurrentStep(STEPS_KITCHEN_PRODUCT_CREATION[stepIndex - 1].key);
      setStepIndex(stepIndex - 1);
    }

    if (stepIndex === 0) {
      redirectToKitchenProductsList();
    }
  };

  const Component = get(STEPS_KITCHEN_PRODUCT_CREATION, `[${stepIndex}].Component`);

  return (
    <>
      <NavigationBar
        displaySubFeatures={false}
        enableActionBottomOrder={true}
        module={ENUM_MODULE_NAME.KITCHEN_PRODUCT_DETAILS}
        path={path}
        storeName={storeName}
        bigTopBar
      />
      <ContentContainer>
        <Component
          availableStores={availableStores}
          composition={composition}
          compositionError={isEmpty(composition) && isSaveAlreadyTriggered}
          currency={currency}
          formFields={formFields}
          ingredients={ingredients}
          isSaveAlreadyTriggered={isSaveAlreadyTriggered}
          picture={picture}
          product={product}
          productForm={productForm}
          productsCategories={productsCategories}
          productsSubCategories={productsSubCategories}
          selectedCompoType={selectedCompoType}
          selectedPictureFile={selectedPictureFile}
          setComposition={setComposition}
          setPicture={setPicture}
          setProduct={setProduct}
          setProductsCategories={setProductsCategories}
          setProductsSubCategories={setProductsSubCategories}
          setSelectedCompoType={setSelectedCompoType}
          setSelectedPictureFile={setSelectedPictureFile}
          setSupplierProduct={setSupplierProduct}
          storeName={storeName}
          supplierProduct={supplierProduct}
        />
      </ContentContainer>
      <Footer>
        <StepsContainer>
          {STEPS_KITCHEN_PRODUCT_CREATION.map((step, index) => (
            <Step key={`step-${index}`}>
              <LabelContainer isActive={currentStep === step.key}>
                <LabelIcon isActive={currentStep === step.key}>{index + 1}</LabelIcon>
                <Text
                  children={step.label}
                  color={
                    currentStep === step.key
                      ? theme.colors.modalHeaders.green.text
                      : theme.colors.greys.dark
                  }
                  font={ENUM_FONTS.TEXT_MIDDLE_BOLD}
                />
              </LabelContainer>
              {index !== STEPS_MAX_INDEX && (
                <img src={'/images/inpulse/chevron-right-dmgrey-small.svg'} />
              )}
            </Step>
          ))}
        </StepsContainer>
        <ButtonsContainer>
          <Button
            color={'inpulse-outline'}
            handleClick={previousStepHandler}
            icon={
              stepIndex === 0
                ? '/images/inpulse/close-black-small.svg'
                : '/images/inpulse/arrow-left-ip-black.svg'
            }
            label={stepIndex === 0 ? i18next.t('GENERAL.BACK') : i18next.t('GENERAL.PREVIOUS')}
          />
          <Button
            color={'inpulse-default'}
            handleClick={nextStepHandler}
            icon={
              stepIndex === STEPS_MAX_INDEX
                ? '/images/inpulse/save-white-small.svg'
                : '/images/inpulse/arrow-right-white.svg'
            }
            isDisabled={stepIndex > 0 && isSaveDisabled}
            label={
              stepIndex === STEPS_MAX_INDEX ? i18next.t('GENERAL.SAVE') : i18next.t('GENERAL.NEXT')
            }
          />
        </ButtonsContainer>
      </Footer>
    </>
  );
};

const mapStateToProps = (state) => ({
  currency: state.baseReducer.currency,
  client: getClientInfo(state.baseReducer.user),
  user: state.baseReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateKitchenProduct);
