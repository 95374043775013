import { uniqBy, difference, isEmpty } from 'lodash';
import i18next from 'i18next';

import { group as groupService } from '@services/group';
import { product as productService } from '@services/product';
import accountService from '@services/account';
import clientService from '@services/client';

export const getLocationsByAccountId = async (accountId, showErrorMessage) => {
  try {
    const locations = await accountService.getLocationsOfAccount(accountId);

    return locations;
  } catch (err) {
    showErrorMessage(i18next.t('HOME.ACTIVITY_REPORT_FETCH_LOCATIONS_FAILURE'));

    return [];
  }
};

export const getRetailersByClientId = async (clientId, showErrorMessage) => {
  try {
    const retailers = await clientService.getRetailersOfClient();

    if (!!retailers.length) {
      retailers.push({
        id: null,
        clientId,
        name: i18next.t('GENERAL.SELECT_NONE_FEMININE'),
      });
    }

    return retailers;
  } catch (err) {
    showErrorMessage(i18next.t('GENERAL.GROUPS_CLIENT_FETCH_FAILURE'));

    return [];
  }
};

export const getProductsByClientId = async (showErrorMessage) => {
  try {
    const products = await productService.getProductsOfClient();

    const activeProducts = products.filter(({ active }) => active);

    const formattedProducts = activeProducts.map((product) => ({
      ...product,
      category: product.category || i18next.t('GENERAL.NONE_VALUE'),
      subCategory: product.subCategory || i18next.t('GENERAL.NONE_VALUE'),
    }));

    const { categories, subCategories } = formattedProducts.reduce(
      (result, { category, subCategory }, index) => {
        if (category) {
          result.categories.push({ id: index, name: category });
        }

        if (subCategory) {
          result.subCategories.push({ id: index, name: subCategory });
        }

        return result;
      },
      { categories: [], subCategories: [] },
    );

    const formattedCategories = uniqBy(categories, (category) => category.name);
    const formattedSubCategories = uniqBy(subCategories, (subCategory) => subCategory.name);

    return {
      products: formattedProducts,
      categories: formattedCategories,
      subCategories: formattedSubCategories,
    };
  } catch (err) {
    if (showErrorMessage) {
      showErrorMessage(i18next.t('PRODUCTION.BY_REFERENCE.FETCH_PRODUCTS_AND_CATEGORIES_ERROR'));
    }

    return {
      products: [],
      categories: [],
      subCategories: [],
    };
  }
};

export const getGroupsByStoreIds = async (storeIds, showErrorMessage) => {
  const formattedGroups = {};
  const storeWithGroupId = [];

  try {
    const groupMappings = await groupService.getGroupsOfStores(storeIds);

    groupMappings.forEach((groupMapping) => {
      const groupId = groupMapping.lnkGroupStoregroupmappingrel.id;
      const groupName = groupMapping.lnkGroupStoregroupmappingrel.name;

      if (!formattedGroups[groupId]) {
        formattedGroups[groupId] = {
          groupId,
          storeIds: [],
          name: groupName,
          id: groupMapping.id,
        };
      }

      formattedGroups[groupId].storeIds.push(groupMapping.storeId);
      storeWithGroupId.push(groupMapping.storeId);
    });
  } catch (err) {
    showErrorMessage(i18next.t('GENERAL.GROUPS_CLIENT_FETCH_FAILURE'));

    return [];
  } finally {
    const noneGroup = {
      id: null,
      name: i18next.t('GENERAL.SELECT_NONE_MASCULINE'),
      storeIds: difference(storeIds, storeWithGroupId),
    };

    const clientGroup = Object.values(formattedGroups);
    if (!isEmpty(clientGroup) && storeIds.length !== storeWithGroupId.length) {
      clientGroup.unshift(noneGroup);
    }

    return clientGroup;
  }
};

export default {
  getGroupsByStoreIds,
  getProductsByClientId,
  getRetailersByClientId,
  getLocationsByAccountId,
};
