import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import i18next from 'i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { openGenericModal, refreshGenericModal } from '@actions/modal';
import { showConfirmationMessage, showErrorMessage } from '@actions/messageconfirmation';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { DEFAULT_FULL_INVENTORY_TEMPLATE } from '@commons/constants/dropdown';
import { getClientStoreNameTranslation } from '@commons/utils/translations';

import { getClientInfo } from '@selectors/client';
import { getSalesPointStores } from '@selectors/stores';

import clientService from '@services/client';
import orderService from '@services/order';

import { getCreateTheoreticalInventoryModalConfig } from './modalConfiguration';

const CreateTheoreticalInventoryModal = (props) => {
  const {
    handleCreateTheoreticalInventory,
    resetCreateTheoreticalInventoryModal,
    openGenericModal,
    refreshGenericModal,
    client: { storeName },
    stores,
    modal,
    showMessage,
    showErrorMessage,
  } = props;

  const [selectedStores, setSelectedStores] = useState([]);

  const [selectedDate, setSelectedDate] = useState(null);
  const [focusedMonth, setFocusedMonth] = useState(moment());
  const [forbiddenDates, setForbiddenDates] = useState([]);

  const [clientILTs, setClientILTs] = useState([]);
  const [selectedILT, setSelectedILT] = useState(DEFAULT_FULL_INVENTORY_TEMPLATE());

  const [translatedStoreName, setTranslatedStoreName] = useState(null);

  /** USE EFFECT  */
  useEffect(() => {
    const storeNameTranslation = getClientStoreNameTranslation(storeName, true);
    setTranslatedStoreName(storeNameTranslation);

    getInventoryListTemplates();
  }, []);

  useEffect(() => {
    setSelectedDate(null);
  }, [selectedStores]);

  useEffect(() => {
    if (!isEmpty(selectedStores)) {
      getDatesWithInventoriesForMonth();
    }
  }, [selectedStores, focusedMonth]);

  useEffect(() => {
    const isModalOpened = get(modal, 'GenericModalBool', false);

    const params = getCreateTheoreticalInventoryModalConfig({
      handleCreateTheoreticalInventory,
      resetCreateTheoreticalInventoryModal,
      stores,
      focusedMonth,
      setFocusedMonth,
      selectedStores,
      setSelectedStores,
      selectedDate,
      setSelectedDate,
      forbiddenDates,
      clientILTs,
      selectedILT,
      setSelectedILT,
      translatedStoreName,
    });

    if (isModalOpened) {
      refreshGenericModal(params);
      return;
    }

    openGenericModal(params);
  }, [
    selectedStores,
    selectedDate,
    focusedMonth,
    forbiddenDates,
    clientILTs,
    selectedILT,
    translatedStoreName,
  ]);

  /** FUNCTIONS */

  const getDatesWithInventoriesForMonth = async () => {
    const startDate = moment(focusedMonth)
      .startOf('month')
      .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);
    const endDate = moment(focusedMonth)
      .endOf('month')
      .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

    try {
      const selectedStoresIds = selectedStores.map((store) => store.id);

      const { dates } = await orderService.getInventoryForStores(
        selectedStoresIds,
        startDate,
        endDate,
        showMessage,
        'stock',
      );

      const existingInventoriesDates = dates.map((date) => moment(date));

      setForbiddenDates(existingInventoriesDates);
    } catch {
      showErrorMessage(i18next.t('STOCKS.STOCKS.LOADING_FAILURE'));
    }
  };

  const getInventoryListTemplates = async () => {
    const result = await clientService.getInventoryListTemplates({
      withMappings: true,
      filterByUserCatalog: false,
    });

    if (isEmpty(result)) {
      /**
       * No InventoryListTemplate configured on client, i.e:
       * - leave dropdown disabled
       * - use selectedILT state default value
       */
      return;
    }

    const clientILTs = result.map(({ id, name }) => ({ id, name }));

    setClientILTs([DEFAULT_FULL_INVENTORY_TEMPLATE(), ...clientILTs]);
  };

  return <></>;
};

const mapStateToProps = (state) => ({
  stores: getSalesPointStores(state.baseReducer.activeStores), // theoretical inventory is only used by sales point stores
  modal: state.modalReducer,
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

CreateTheoreticalInventoryModal.propTypes = {
  handleCreateTheoreticalInventory: PropTypes.func, // passed by InventoryListView.js, to perform the API call
  resetCreateTheoreticalInventoryModal: PropTypes.func, // passed by InventoryListView.js, to reset its state
  openGenericModal: PropTypes.func, // passed by redux
  refreshGenericModal: PropTypes.func, // passed by redux
  stores: PropTypes.array, // passed by redux
  modal: PropTypes.array, // passed by redux
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateTheoreticalInventoryModal);
