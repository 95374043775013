import { connect } from 'react-redux';
import { get, keyBy } from 'lodash';
import { Link, useHistory } from 'react-router-dom';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';
import { getTheme } from '@commons/utils/theme';
import { LoadingIcon } from '@commons/Metrics/components/Stats/styledComponents';

import SpinnerIcon from '@assets/icons/new-loader.svg';

import {
  ClickableImage,
  Container,
  ContainerAction,
  ContainerPages,
  CustomRenderContainer,
  DisabledSubFeature,
  Section,
  SubBar,
  TopBar,
  TopBarContainer,
} from './styledComponents';
import { getClientInfo } from '../../selectors/client';
import { getFeatureWithSubFeaturesForPath } from '../../actions/feature';
import {
  HELP_CENTER_COMPONENT_NAME,
  PATHS_AVAILABLE_IN_ALL_WORKSPACES,
  REFERRAL_COMPONENT_NAME,
} from '../constants/centralConstants';
import useLocalStorage from '../../hooks/useLocalStorage';

const NavigationBar = (props) => {
  const {
    bigTopBar,
    user,
    path,
    userRights,
    action,
    enableActionBottomOrder = false,
    displaySubFeatures = true,
    customHeader = null,
    featuresKeyByPath,
    getFeatureWithSubFeaturesForPath,
    rightButtonLabel,
    rightButtonOnClick,
    rightButtonLoading,
    disableGoBackButton = false,
  } = props;

  const history = useHistory();

  const theme = getTheme();

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [subFeatures, setSubFeatures] = useState([]);
  const [mainFeature, setMainFeature] = useState([]);

  const [storedIsCentralMode] = useLocalStorage('isCentralMode', false);

  const displayCustomRenderConfig = () => {
    if (!mainFeature || !mainFeature.customRender) {
      return false;
    }

    return mainFeature.customRender(props);
  };

  // We have to use useLocalStorage and not the redux variable 'isCentralView' because on first load it's not set
  const formatRightsByUserMode = () =>
    userRights.reduce((acc, right) => {
      const accByPath = keyBy(acc, 'path');

      const splittedPath = right.path
        ? get(right.path.split('/'), '[1]', '') // We take the first element because [0] is equal to '/'
        : '';

      // We can't add the help center path in PATHS_AVAILABLE_IN_ALL_WORKSPACES because it's equal to https://support.inpulse.ai and it
      // doesn't work with the split so we have to rely on the component name or feature name depending on the fecthed data
      if (
        (accByPath[right.path] || right.isCentral !== storedIsCentralMode) &&
        (!PATHS_AVAILABLE_IN_ALL_WORKSPACES.includes(splittedPath) ||
          right.component === HELP_CENTER_COMPONENT_NAME ||
          right.component === REFERRAL_COMPONENT_NAME)
      ) {
        return acc;
      }

      acc.push(right);
      return acc;
    }, []);

  useEffect(() => {
    const parentFeature = getFeatureWithSubFeaturesForPath(featuresKeyByPath, path);
    if (!parentFeature) {
      return;
    }

    const formattedRightByUserMode = formatRightsByUserMode();

    // Main feature path
    const userFeature = formattedRightByUserMode.find(
      ({ path: userRightPath }) => userRightPath === parentFeature.path,
    );

    const mainFeature = { ...parentFeature, ...userFeature };

    setMainFeature(mainFeature);

    // Sub features path
    // Looping through sub features since it is already ordered
    const subFeatures = parentFeature.subFeatures.filter(({ path: subFeaturePath, isVisible }) => {
      // If the feature.isVisible key not specified, then it is visible
      const subFeatureIsVisible = !isVisible || isVisible(props);

      const userCanSeeFeature = formattedRightByUserMode.some(
        ({ path: userRightPath }) => userRightPath === subFeaturePath,
      );

      return subFeatureIsVisible && userCanSeeFeature;
    });

    setSubFeatures(subFeatures);

    setIsFullScreen(
      mainFeature.isFullScreen || subFeatures.some(({ isFullScreen }) => isFullScreen),
    );
  }, [path, props]);

  const hasSubFeatures = subFeatures && !!subFeatures.length;

  const displayCustomRender = displayCustomRenderConfig();

  const handleRedirection = (backLink) => {
    const parentFeature = getFeatureWithSubFeaturesForPath(featuresKeyByPath, path);

    if (backLink) {
      history.push(backLink);

      return;
    }

    if (!!parentFeature && parentFeature.path) {
      history.push(parentFeature.path);

      return;
    }

    history.goBack();
  };

  return (
    <Container fullWidth={isFullScreen}>
      <ContainerPages>
        {/* NavBar Header */}
        {customHeader ? (
          customHeader
        ) : (
          <TopBarContainer big={bigTopBar} noBorder={!hasSubFeatures}>
            <TopBar>
              {!displayCustomRender && (
                <Section
                  isDisabled={mainFeature.getIsDisabled ? mainFeature.getIsDisabled(user) : false}
                  key={path}
                  light
                >
                  <Link to={mainFeature.getLink ? mainFeature.getLink(props) : mainFeature.path}>
                    {mainFeature.getText
                      ? mainFeature.getText(props)
                      : i18next.t(mainFeature.translationKey)}
                  </Link>
                </Section>
              )}
              {displayCustomRender && (
                <CustomRenderContainer>
                  <ClickableImage
                    className="prev-button-arrow"
                    disabled={disableGoBackButton}
                    src={
                      disableGoBackButton
                        ? '/images/inpulse/arrow-left-dmgrey.svg'
                        : '/images/inpulse/arrow-left-ip-black.svg'
                    }
                    onClick={() =>
                      !disableGoBackButton && handleRedirection(displayCustomRender.backLink)
                    }
                  />
                  <span>
                    <a
                      href={displayCustomRender.backLink}
                      style={{ color: 'inherit' }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleRedirection(displayCustomRender.backLink);
                      }}
                    >
                      {displayCustomRender.linkedText}
                    </a>{' '}
                    {displayCustomRender.extraText}
                  </span>
                </CustomRenderContainer>
              )}
            </TopBar>

            {rightButtonLoading && (
              <LoadingIcon alt={'component-loader'} height="16px" src={SpinnerIcon} width="16px" />
            )}

            {!rightButtonLoading && rightButtonLabel && (
              <Button
                buttonCustomStyle={{
                  paddingLeft: '0px',
                  height: '20px',
                  font: theme.fonts.textSmallHeight14,
                }}
                color={'inpulse-outline'}
                fontColor={theme.colors.blacks.ipBlack4}
                handleClick={rightButtonOnClick}
                icon={'/images/inpulse/history.svg'}
                iconOnLeft={false}
                label={rightButtonLabel}
                noBorder
              />
            )}
          </TopBarContainer>
        )}

        {/* NavBar Sub Header */}
        {hasSubFeatures && displaySubFeatures && (
          <SubBar>
            {subFeatures.map(
              ({
                shouldReplacePath,
                getLink,
                path: subFeaturePath,
                getText,
                translationKey,
                isDisabled,
              }) => (
                <Section
                  isDisabled={isDisabled ? isDisabled(props) : false}
                  key={subFeaturePath}
                  selected={path === subFeaturePath}
                  light
                  small
                >
                  {isDisabled && isDisabled(props) && (
                    <DisabledSubFeature>
                      {getText ? getText(props) : i18next.t(translationKey)}
                    </DisabledSubFeature>
                  )}
                  {(!isDisabled || !isDisabled(props)) && (
                    <Link
                      replace={shouldReplacePath}
                      to={getLink ? getLink(props) : subFeaturePath}
                      Link
                    >
                      {getText ? getText(props) : i18next.t(translationKey)}
                    </Link>
                  )}
                </Section>
              ),
            )}
          </SubBar>
        )}
      </ContainerPages>
      {action && (
        <ContainerAction withBottomBorder={enableActionBottomOrder}>{action}</ContainerAction>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  userRights: state.baseReducer.userRights,
  client: getClientInfo(state.baseReducer.user),
  featuresKeyByPath: state.baseReducer.featuresKeyByPath,
});

const mapDispatchToProps = (dispatch) => ({
  getFeatureWithSubFeaturesForPath: (featuresKeyByPath, path) =>
    dispatch(getFeatureWithSubFeaturesForPath(featuresKeyByPath, path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(NavigationBar));
