import { connect } from 'react-redux';
import { get } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

import {
  closeGenericModal,
  openGenericModal,
  openSmallModal,
  refreshGenericModal,
} from '@actions/modal';
import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { Button, ListView } from '@commons/utils/styledLibraryComponents';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { hasValidAdvancedFilters } from '@commons/Filters/utils';
import DeepsightFiltersButton from '@admin/components/FilterButton';
import EmptyState from '@commons/EmptyState';
import GeneralEmptyStateListView from '@commons/GeneralEmptyStateListView';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import internalInvoiceService from '@services/internalInvoice';

import { getClientInfo } from '@selectors/client';

import { getListActions, getRowActions } from './utils/actions';
import { getListColumns } from './utils/columns';

import {
  Container,
  ContentContainer,
  FilterButtonContainer,
  FilterContainer,
} from './styledComponents';

import { exportInternalInvoicesPDF, exportInternalInvoicesXLS } from './utils/exportUtils';
import { getCentralKitchenStores, getSalesPointStores } from '@selectors/stores';

const InternalInvoices = (props) => {
  const {
    match: { path },
    // redux states
    client: { storeName },
    history,
    user,
    currency,
    // dispatch methods
    showErrorMessage,
    kitchenStores,
    salesPointStores,
  } = props;

  const clientStoreNames = getClientStoreNameTranslation(storeName, true);

  const userLanguageCode = get(user, 'lnkLanguageAccountrel.code', 'fr');

  moment.locale(userLanguageCode);

  const [columns] = useState(getListColumns(userLanguageCode, storeName));

  const [actions, setActions] = useState([]);
  const [rowActions, setRowActions] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [internalInvoices, setInternalInvoices] = useState([]);
  const [selectedInternalInvoices, setSelectedInternalInvoices] = useState([]);

  // Filter states
  const [filters, setFilters] = useState(null);
  const [advancedFilters, setAdvancedFilters] = useState(null);
  const [applyFilters, setApplyFilters] = useState(true);
  const [selectedSalesPointStores, setSelectedSalesPointStores] = useState(salesPointStores);
  const [selectedKitchenStore, setSelectedKitchenStore] = useState(kitchenStores[0]);
  const [filteredInternalInvoices, setFilteredInternalInvoices] = useState([]);

  /** USE EFFECTS */
  useEffect(() => {
    if (!selectedKitchenStore) {
      return;
    }

    getInternalInvoices();
  }, [selectedKitchenStore, selectedSalesPointStores]);

  useEffect(() => {
    setActions(
      getListActions({
        filteredInternalInvoices,
        selectedInternalInvoices: selectedInternalInvoices.length ? selectedInternalInvoices : [],
        handleExportPDF: exportInternalInvoicesPDF,
        handleExportXLS: exportInternalInvoicesXLS,
        goToCreateInvoice,
        currency,
      }),
    );

    setRowActions(getRowActions({ handleExportPDF: exportInternalInvoicesPDF }));
  }, [filteredInternalInvoices, selectedInternalInvoices]);

  useEffect(() => {
    if (!hasValidAdvancedFilters(advancedFilters)) {
      setFilteredInternalInvoices(internalInvoices);
      return;
    }

    const filteredInternalInvoices = advancedFilters.reduce(
      (result, { doFilter, propertyKey, value }) => doFilter(result, propertyKey, value),
      internalInvoices,
    );

    setFilteredInternalInvoices(filteredInternalInvoices);
  }, [internalInvoices, advancedFilters]);

  /** FUNCTIONS */

  const getInternalInvoices = async () => {
    setIsLoading(true);

    try {
      const fetchedInternalInvoices = await internalInvoiceService.getInternalInvoicesList({
        issuerStoreId: selectedKitchenStore.id,
        billedStoreIds: selectedSalesPointStores.map(({ id }) => id),
      });

      setInternalInvoices(fetchedInternalInvoices);
    } catch {
      setInternalInvoices([]);

      showErrorMessage(i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_FETCH_ERROR'));
    } finally {
      setIsLoading(false);
    }
  };

  const goToDetailsPage = (invoice) => {
    history.push(`/invoice/internal-invoices/${invoice.id}/details`);
  };

  const goToCreateInvoice = () => {
    history.push('/invoice/internal-invoices/create');
  };

  if (!isLoading && internalInvoices.length === 0) {
    return (
      <Container>
        <NavigationBreadCrumb featurePath={path} />
        <ContentContainer>
          <GeneralEmptyStateListView
            icon={'/images/inpulse/catalogs.svg'}
            renderAction={() => (
              <Button
                color="inpulse-default"
                handleClick={goToCreateInvoice}
                icon={'/images/inpulse/add-white-small.svg'}
                label={i18next.t('GENERAL.ADD')}
              />
            )}
            subtitle={i18next.t(
              'INVOICE.INTERNAL_INVOICES.INTERNAL_INVOICES_EMPTY_STATE_SUBTITLE',
              { storeNames: clientStoreNames },
            )}
            title={i18next.t('INVOICE.INTERNAL_INVOICES.INTERNAL_INVOICES_EMPTY_STATE_TITLE')}
          />
        </ContentContainer>
      </Container>
    );
  }

  return (
    <Container>
      <NavigationBreadCrumb featurePath={path} />
      <ContentContainer>
        <ListView
          actionOnClick={goToDetailsPage}
          actions={actions}
          columns={columns}
          data={filteredInternalInvoices}
          defaultOrderBy={'date'}
          defaultOrderType={'desc'}
          isLoading={isLoading}
          minActionsInActionsDropdown={1}
          padding={'24px 24px 0px 24px'}
          placeholderShape={i18next.t('GENERAL.SEARCH')}
          renderEmptyState={() => <EmptyState />}
          renderFilterButton={() => (
            <FilterButtonContainer>
              <FilterContainer>
                <DeepsightFiltersButton
                  advancedFilters={advancedFilters}
                  applyFilters={applyFilters}
                  columnsFilterList={columns.filter(({ filterType }) => !!filterType)}
                  customMultipleDropDowns={[
                    {
                      id: 'salesPointStores',
                      icon: '/images/icon-dropdown-grey.svg',
                      list: salesPointStores,
                      defaultSelectedItems: selectedSalesPointStores,
                      selectedItems: selectedSalesPointStores,
                      setSelectedItems: setSelectedSalesPointStores,
                    },
                  ]}
                  filters={filters}
                  isLoading={isLoading}
                  readOnly={isLoading}
                  selectedStore={selectedKitchenStore}
                  setAdvancedFilters={setAdvancedFilters}
                  setApplyFilters={setApplyFilters}
                  setFilters={setFilters}
                  setSelectedStore={setSelectedKitchenStore}
                  stores={kitchenStores}
                  textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
                />
              </FilterContainer>
            </FilterButtonContainer>
          )}
          rowActions={rowActions}
          setSelectedItems={setSelectedInternalInvoices}
        />
      </ContentContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  currency: state.baseReducer.currency,
  client: getClientInfo(state.baseReducer.user),
  kitchenStores: getCentralKitchenStores(state.baseReducer.activeStores),
  salesPointStores: getSalesPointStores(state.baseReducer.activeStores),
  modal: state.modalReducer,
});

const mapDispatchToProps = (dispatch) => ({
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  openModalExportInfo: (params) => {
    dispatch(openSmallModal(params));
  },
  closeGenericModal: (params) => {
    dispatch(closeGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InternalInvoices);
