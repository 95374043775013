import { connect } from 'react-redux';
import { get } from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { showConfirmationMessage } from '@actions/messageconfirmation';

import { capitalizeFirstLetter } from '@commons/utils/format';
import { ToggleSwitch } from '@commons/utils/styledLibraryComponents';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';
import WhiteCard from '@commons/WhiteCard';

import clientService from '@services/client';

import { Text, Container, InlineContainer, ActivationsContainer } from './styledComponents';

export const BackOfficeIntegrations = (props) => {
  const { match, user } = props;

  const path = get(match, 'path');

  const [partners, setPartners] = useState([]);

  const loadActivatedPartners = async () => {
    try {
      const result = await clientService.getPartnershipsByClientId();

      setPartners(result);
    } catch (err) {
      props.showMessage(i18next.t('BACKOFFICE.INTEGRATIONS.FETCH_LIST_FAILURE'), 'error');

      setPartners([]);
    }
  };

  useEffect(() => {
    if (!user) {
      return;
    }

    (async function loadInitialData() {
      await loadActivatedPartners();
    })();
  }, [user]);

  const triggerPartnerIntegration = async (partner) => {
    if (!partner) {
      return;
    }

    const isActivation = !partner.activated;

    try {
      await clientService[
        isActivation
          ? 'createPartnershipByClientIdAndPartnerId'
          : 'deletePartnershipByClientIdAndPartnerId'
      ](get(user, 'clientId'), partner.id, isActivation);

      props.showMessage(
        i18next.t(
          isActivation
            ? 'BACKOFFICE.INTEGRATIONS.ACTION_ACTIVATE_SUCCESS'
            : 'BACKOFFICE.INTEGRATIONS.ACTION_DEACTIVATE_SUCCESS',
          { name: capitalizeFirstLetter(partner.name) },
        ),
      );

      await loadActivatedPartners();
    } catch (err) {
      props.showMessage(
        i18next.t(
          isActivation
            ? 'BACKOFFICE.INTEGRATIONS.ACTION_ACTIVATE_FAILURE'
            : 'BACKOFFICE.INTEGRATIONS.ACTION_DEACTIVATE_FAILURE',
          { name: capitalizeFirstLetter(partner.name) },
        ),
        'error',
      );
    }
  };

  return (
    <Container>
      <NavigationBreadCrumb featurePath={path} />
      <WhiteCard
        renderContent={
          <ActivationsContainer>
            {partners &&
              partners.map((partner, index) => (
                <InlineContainer key={index}>
                  <ToggleSwitch
                    checked={partner.activated}
                    handleClick={() => triggerPartnerIntegration(partner)}
                    id={`${partner.name}-${index}`}
                  />
                  <Text capitalize>{partner.name}</Text>
                </InlineContainer>
              ))}
          </ActivationsContainer>
        }
        title={i18next.t('BACKOFFICE.INTEGRATIONS.ACTIVATIONS')}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeIntegrations);
