import { connect } from 'react-redux';
import { DateRangePicker } from 'react-dates';
import i18next from 'i18next';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Button } from '@commons/utils/styledLibraryComponents';
import {
  renderCustomArrowIcon,
  renderCustomNavIconLeft,
  renderCustomNavIconRight,
  renderTodayDateCircle,
} from '@commons/DeepsightAnalyticsHeaders/utils';
import utilsXLS from '@commons/utils/makeXLS';

import ExportModalContent from '@lib/inpulse/ExportModal';

import { productMix as productMixService } from '@services/productMix';

import { createListingSheet, createAggregatedSheet, createToplineSheet } from './utils';
import {
  Title,
  Header,
  Footer,
  Container,
  DatePicker,
  ContentDateSelection,
} from './styledComponents';

const DEFAULT_TITLE_SELECT_DATE_RANGE = 'FORECAST.PRODUCT_MIX.EXPORT_DATE_RANGE_SELECTION_TITLE';
const DEFAULT_TITLE_EXPORT_FAILURE = 'GENERAL.EXPORT_FAILURE';
const DEFAULT_TITLE_EXPORT_IN_PROGRESS = 'FORECAST.PRODUCT_MIX.EXPORT_IN_PROGRESS';
const DEFAULT_TITLE_EXPORT_READY = 'FORECAST.PRODUCT_MIX.EXPORT_FINISHED';

const FILENAME = 'FORECAST.PRODUCT_MIX.EXPORT_FILENAME';

const DEFAULT_CATEGORY = 'none value';

/************************/
/** Components methods **/
/************************/

export function updateProgress(nbDaysLeftToFetch, nbDaysFetched, setProgress) {
  const totalDaysToFetch = nbDaysLeftToFetch + nbDaysFetched;

  const updatedProgress = 100 - (nbDaysLeftToFetch / totalDaysToFetch) * 100;

  setProgress(updatedProgress);
}

export function exitModal(setLoading, closeModal) {
  setLoading(false);

  closeModal();
}

export function exportReady(setLoading, setTitle) {
  setTitle(i18next.t(DEFAULT_TITLE_EXPORT_READY));
  setLoading(false);
}

export function exportFailure(setLoading, setTitle) {
  setTitle(i18next.t(DEFAULT_TITLE_EXPORT_FAILURE));
  setLoading(false);
}

/*******************/
/** Fetch methods **/
/*******************/

export async function loadProductMixByDate(
  storeId,
  date,
  nbDaysLeftToFetch,
  productMixListByDay,
  setLoading,
  setTitle,
  setProgress,
  setNbDaysLeftToFetch,
  setProductMixListByDay,
) {
  const updatedProductMixList = [...productMixListByDay];

  // Perform request
  try {
    const { ca, tot, turnoverExcludingTaxes, productsByCategory } =
      await productMixService.getForecastsProductMix(storeId, date);

    const formattedProductsByCategory = productsByCategory;

    const noneCategory = productsByCategory[DEFAULT_CATEGORY];

    if (noneCategory) {
      const formattedNoneCategoryProducts = noneCategory.products.map((product) => ({
        ...product,
        category: i18next.t('GENERAL.NONE_VALUE'),
      }));

      formattedProductsByCategory[i18next.t('GENERAL.NONE_VALUE')] = {
        stats: noneCategory.stats,
        products: formattedNoneCategoryProducts,
      };

      delete formattedProductsByCategory[DEFAULT_CATEGORY];
    }

    updatedProductMixList.push({
      date,
      ca,
      tot,
      turnoverExcludingTaxes,
      productsByCategory: formattedProductsByCategory,
    });

    setProductMixListByDay(updatedProductMixList);

    updateProgress(nbDaysLeftToFetch - 1, updatedProductMixList.length, setProgress);
  } catch (err) {
    exportFailure(setLoading, setTitle);
  }

  setNbDaysLeftToFetch(nbDaysLeftToFetch - 1);
}

/********************/
/** Render methods **/
/********************/

export function renderDateSelection(props) {
  const {
    endDate,
    startDate,
    closeModal,
    setLoading,
    maxDaysInFuture,
    allowPastDates,
    focusedDateInput,
    setFocusedDateInput,
    handleSelectedDates,
    setIsSettingDateRange,
    waitingForEndDate,
  } = props;

  return (
    <div>
      <Header style={{ padding: '25px' }} noShadow white>
        <Title black medium>
          {i18next.t(DEFAULT_TITLE_SELECT_DATE_RANGE)}
        </Title>
      </Header>

      <ContentDateSelection>
        <div>{i18next.t('FORECAST.PRODUCT_MIX.EXPORT_DATE_RANGE_SELECTION_TEXT')}</div>
        <DatePicker focusedDateInput={focusedDateInput}>
          <img
            alt=""
            className="orderform-date-input-icon"
            src={'/images/icon-calendar-black-inpulse.svg'}
            style={{ width: '16px', height: '16px' }}
          />
          <DateRangePicker
            calendarInfoPosition={'top'}
            customArrowIcon={renderCustomArrowIcon()}
            daySize={35}
            displayFormat={'ddd Do MMM'}
            endDate={endDate}
            endDateId="your_unique_end_date_id"
            endDatePlaceholderText={i18next.t(
              'GENERAL.ANALYTICS_HEADER_DATE_PICKER_END_PLACEHOLDER',
            )}
            focusedInput={focusedDateInput}
            hideKeyboardShortcutsPanel={true}
            isOutsideRange={(day) => {
              const isBeforeAllowedRange =
                !allowPastDates && day.isBefore(moment().subtract(1, 'days').endOf('day'));

              const isAfterAllowedRange =
                maxDaysInFuture && day.isAfter(moment().add(maxDaysInFuture, 'days').endOf('day'));

              const isTooManyDays =
                waitingForEndDate &&
                day.isAfter(moment(startDate).add(1, 'M').add(-1, 'days').endOf('day'));

              return isBeforeAllowedRange || isAfterAllowedRange || isTooManyDays;
            }}
            minimumNights={0}
            navNext={renderCustomNavIconRight()}
            navPrev={renderCustomNavIconLeft()}
            noBorder={true}
            numberOfMonths={2}
            renderDayContents={(item) => renderTodayDateCircle(item, [startDate, endDate])}
            startDate={startDate}
            startDateId="your_unique_start_date_id"
            startDatePlaceholderText={i18next.t(
              'GENERAL.ANALYTICS_HEADER_DATE_PICKER_START_PLACEHOLDER',
            )}
            onDatesChange={(dates) => handleSelectedDates(dates, focusedDateInput)}
            onFocusChange={(updatedFocusedDateInput) =>
              setFocusedDateInput(updatedFocusedDateInput)
            }
          />
        </DatePicker>
      </ContentDateSelection>

      <Footer style={{ padding: '17px 20px' }} alignLeft shadow>
        <Button
          buttonCustomStyle={{ marginRight: 18 }}
          color={'blue-outline-no-shadow'}
          handleClick={() => exitModal(setLoading, closeModal)}
          icon={'/images/inpulse/close-black-small.svg'}
          label={i18next.t('GENERAL.CANCEL')}
        />
        <Button
          buttonCustomStyle={{ marginRight: 18 }}
          color={startDate && endDate ? 'blue' : 'grey'}
          handleClick={() => {
            if (startDate && endDate) {
              setIsSettingDateRange(false);
            }
          }}
          icon={'/images/inpulse/check-white-small.svg'}
          label={i18next.t('GENERAL.VALIDATE')}
        />
      </Footer>
    </div>
  );
}

export const ProductMixExportModal = (props) => {
  const {
    currency,
    closeModal,
    params: {
      useTtc,
      storeId,
      forecastProperty,
      maxDaysInFuture,
      allowPastDates,
      turnoverName,
      currentDate,
    },
  } = props;

  const [progress, setProgress] = useState(0);
  const [productMixListByDay, setProductMixListByDay] = useState([]);

  const [title, setTitle] = useState(i18next.t(DEFAULT_TITLE_EXPORT_IN_PROGRESS));

  const [startDate, setStartDate] = useState(currentDate);
  const [waitingForEndDate, setWaitingForEndDate] = useState(false);
  const [endDate, setEndDate] = useState(currentDate);
  const [nbDaysLeftToFetch, setNbDaysLeftToFetch] = useState(0);

  const [focusedDateInput, setFocusedDateInput] = useState();

  const [isLoading, setLoading] = useState(true);
  const [isSettingDateRange, setIsSettingDateRange] = useState(true);

  const handleSelectedDates = (dates) => {
    if (!moment(dates.startDate).isSame(startDate, 'day')) {
      setEndDate(null);
      setStartDate(dates.startDate);
      setWaitingForEndDate(true);
    }

    if (!moment(dates.endDate).isSame(endDate, 'day')) {
      setEndDate(dates.endDate);
      setWaitingForEndDate(false);
    }
  };

  useEffect(() => {
    if (isSettingDateRange) {
      return;
    }

    const diffDays = moment(endDate).endOf('day').diff(moment(startDate).startOf('day'), 'days');

    setNbDaysLeftToFetch(diffDays + 1);
  }, [isSettingDateRange]);

  useEffect(() => {
    if (isSettingDateRange) {
      return;
    }

    if (nbDaysLeftToFetch <= 0) {
      setProgress(100);

      const listingDataSheet = createListingSheet(useTtc, productMixListByDay);
      const aggregatedDataSheet = createAggregatedSheet(useTtc, productMixListByDay);
      const toplineDataSheet = createToplineSheet(
        forecastProperty,
        turnoverName,
        productMixListByDay,
        currency,
      );

      utilsXLS.makeXLS(i18next.t(FILENAME), [
        utilsXLS.generateDefaultSheet(
          listingDataSheet.title,
          listingDataSheet.headers,
          listingDataSheet.data,
          currency,
        ),
        utilsXLS.generateDefaultSheet(
          aggregatedDataSheet.title,
          aggregatedDataSheet.headers,
          aggregatedDataSheet.data,
          currency,
        ),
        utilsXLS.generateDefaultSheet(
          toplineDataSheet.title,
          toplineDataSheet.headers,
          toplineDataSheet.data,
          currency,
        ),
      ]);

      return exportReady(setLoading, setTitle);
    }

    const nbDaysFetched = productMixListByDay.length;

    const date = moment(startDate).add(nbDaysFetched, 'days');

    loadProductMixByDate(
      storeId,
      date,
      nbDaysLeftToFetch,
      productMixListByDay,
      setLoading,
      setTitle,
      setProgress,
      setNbDaysLeftToFetch,
      setProductMixListByDay,
    );
  }, [nbDaysLeftToFetch]);

  return (
    <div className={props.className}>
      {isSettingDateRange && (
        <Container>
          {renderDateSelection({
            endDate,
            startDate,
            closeModal,
            setLoading,
            maxDaysInFuture,
            allowPastDates,
            focusedDateInput,
            setFocusedDateInput,
            handleSelectedDates,
            setIsSettingDateRange,
            waitingForEndDate,
          })}
        </Container>
      )}

      {!isSettingDateRange && (
        <ExportModalContent
          {...props}
          closeModal={closeModal}
          exitModal={exitModal}
          isLoading={isLoading}
          progress={progress}
          setLoading={setLoading}
          titleModal={title}
        />
      )}
    </div>
  );
};

const StyledProductMixExportModal = styled(ProductMixExportModal)`
  & .orderform-date-input-icon,
  .multiple-dropdown-icon {
    margin-left: 15px;
  }

  & .DateInput_fang {
    display: none !important;
  }

  & .DateRangePickerInput__disabled {
    background-color: lightgrey !important;
  }

  & .CalendarMonth_caption {
    color: ${(props) => props.theme.colors?.greys?.darkest} !important;
    font: ${(props) => props.theme.fonts?.h3} !important;
  }

  & .DateInput_input {
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    text-align: center !important;
    cursor: pointer;
  }

  & .DateInput_input__focused {
    border-bottom: none !important;
  }

  & .DayPicker_weekHeader_li {
    font: ${(props) => props.theme.fonts?.textMicro} !important;
  }

  & .CalendarDay {
    background: ${(props) => props.theme.colors?.greys.lightest} !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
  }

  & .CalendarDay__default:hover {
    background: ${(props) => props.theme.colors?.greys.lighter} !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
  }

  & .CalendarDay__highlighted_calendar {
    background: #fff !important;
    border: 1px double #0a3eff !important;
  }

  & .CalendarDay__highlighted_calendar:hover {
    background: #e4e7e7 !important;
  }

  & .CalendarDay__selected_span {
    background: ${(props) => props.theme.colors?.brand.primaryLight} !important;
    border: 1px double #e2e2e2 !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
  }

  & .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: ${(props) => props.theme.colors?.brand.primaryLight} !important;
    color: ${(props) => props.theme.colors?.greys.darkest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    border: 1px double #e2e2e2 !important;
  }

  & .CalendarDay__selected,
  .CalendarDay__selected:active .CalendarDay__selected:hover {
    background: ${(props) => props.theme.colors?.greys.darkest} !important;
    color: ${(props) => props.theme.colors?.greys.lightest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    border: 1px double #e2e2e2 !important;
  }

  & .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: ${(props) => props.theme.colors?.greys.lighter} !important;
    border: 1px double #e2e2e2 !important;
  }

  & .CalendarDay__selected_start,
  .CalendarDay__selected_start:hover,
  .CalendarDay__selected_end,
  .CalendarDay__selected_end:hover {
    background: ${(props) => props.theme.colors?.greys.darkest} !important;
    color: ${(props) => props.theme.colors?.greys.lightest} !important;
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    border: 1px double #e2e2e2 !important;
  }

  & .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:hover {
    color: ${(props) => props.theme.colors?.greys.dark} !important;
    background: ${(props) => props.theme.colors?.greys.lighter} !important;
  }
`;

const mapStateToProps = (state) => ({
  currency: state.baseReducer.currency,
});

export default connect(mapStateToProps)(StyledProductMixExportModal);
