import axios from '../core/http';

import { config } from '../config';

const baseApiUrl = `${config.baseApiUrl}/v1`;

function getProductOrdersOfOrders(orderIds) {
  return axios
    .post(baseApiUrl + '/order/ProductOrders', {
      orderIds,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export const productOrders = {
  getProductOrdersOfOrders,
};
