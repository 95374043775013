import axios from '../core/http';

import { config } from '../config';

const baseApiUrl = `${config.baseApiUrl}/v1`;

const getProductionRangesOfStore = (storeId, date) =>
  axios
    .get(baseApiUrl + '/production/ranges', {
      params: {
        storeId,
        date,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });

export const productionRanges = {
  getProductionRangesOfStore,
};
