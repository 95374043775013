import axios from '../core/http';

import { config } from '../config';
import { getLocalStorageItem } from '../commons/utils/localStorage';

const baseApiUrlv1 = `${config.baseApiUrl}/v1`;
const baseApiUrlv2 = `${config.baseApiUrl}/v2`;

const getStandardHeaders = async () => {
  const accessToken = await getLocalStorageItem('loopbackAccessToken');

  const isCentralInterface = await getLocalStorageItem('isCentralMode');

  return {
    'Content-Type': 'application/json',
    Authorization: accessToken,
    'X-Context': JSON.stringify({
      isCentralInterface,
    }),
  };
};

/**
 * This file aims to centralize all axios-related logic, reducing the amount of code
 * necessary to perform an API call.
 *
 * @param {string} url - The relative or absolute URL to request data from.
 * @param {Object} params - Optional query parameters to be sent with the request.
 * @param {string} responseType - The type of data to return. Default: 'json'
 * @return {Promise<any>} A Promise that resolves with the response data from the server.
 *
 * Example usage in src/services/loss.js
 */
const apiGet =
  (baseApiUrl) =>
  async (url, params, responseType = 'json') => {
    const headers = await getStandardHeaders();

    const { data } = await axios.get(`${baseApiUrl}${url}`, {
      params,
      responseType,
      headers,
    });

    return data;
  };

const apiPost =
  (baseApiUrl) =>
  async (url, bodyParams, responseType = 'json') => {
    const headers = await getStandardHeaders();

    const { data } = await axios.post(
      `${baseApiUrl}${url}`,
      { ...bodyParams },
      { headers, responseType },
    );

    return data;
  };

const apiPut =
  (baseApiUrl) =>
  async (url, bodyParams, responseType = 'json') => {
    const headers = await getStandardHeaders();

    const { data } = await axios.put(
      `${baseApiUrl}${url}`,
      { ...bodyParams },
      { headers, responseType },
    );

    return data;
  };

const apiPatch = (baseApiUrl) => async (url, bodyParams) => {
  const headers = await getStandardHeaders();

  const { data } = await axios.patch(`${baseApiUrl}${url}`, { ...bodyParams }, { headers });

  return data;
};

const apiDelete = (baseApiUrl) => async (url, payload) => {
  const headers = await getStandardHeaders();

  const { data } = await axios.delete(`${baseApiUrl}${url}`, {
    headers,
    data: payload,
  });

  return data;
};

export const APIcaller = {
  apiGet: apiGet(baseApiUrlv1),
  apiPost: apiPost(baseApiUrlv1),
  apiPut: apiPut(baseApiUrlv1),
  apiPatch: apiPatch(baseApiUrlv1),
  apiDelete: apiDelete(baseApiUrlv1),
};

export const APIcallerv2 = {
  apiGet: apiGet(baseApiUrlv2),
  apiPost: apiPost(baseApiUrlv2),
  apiPut: apiPut(baseApiUrlv2),
  apiPatch: apiPatch(baseApiUrlv2),
  apiDelete: apiDelete(baseApiUrlv2),
};
