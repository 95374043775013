import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  overflow: hidden;

  position: relative;
`;

export const Content = styled.div`
  width: 100%;
  height: calc(100% - 65px);

  overflow: auto;

  display: block;
  flex-direction: column;

  background-color: ${(props) => props.theme.colors.greys.lighter};

  overflow: scroll;

  position: relative;

  padding-bottom: 24px;
`;

export const DisplayEmptyState = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const OrderCartContainer = styled.div`
  display: flex;
  flex-direction: row;

  align-items: flex-start;
  justify-content: center;

  margin: 0px 24px;
  gap: 24px;

  height: 100%;
  width: 100%;
`;

export const TitleContainer = styled.div`
  font: ${(props) => props.theme.fonts.h2};
  margin-bottom: 24px;
  padding: 24px 24px 0 24px;
`;

export const DetailsContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 0 24px;
  align-items: center;
  margin-bottom: 24px;
`;

export const Text = styled.div`
  display: flex;
  align-items: center;

  color: ${(props) =>
    props.disabled ? props.theme.colors?.greys.dark : props.theme.colors?.greys?.darkest};
  font: ${(props) => (props.title ? props.theme.fonts?.h1Inter : props.theme.fonts?.textSmall)};
`;

export const InputContainer = styled.div`
  margin-bottom: 24px;
`;

export const EmailContainer = styled.div`
  display: flex;

  align-items: center;
  justify-content: flex-start;

  width: 100%;
  min-height: 40px;
`;

export const TitleWhiteCard = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const InvoiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const WhiteCardContainer = styled.div`
  width: ${(props) => props.width};
  margin: 0px;
  height: 100%;

  overflow-y: scroll;
`;

export const CheckBoxContainer = styled.div`
  width: 100%;

  padding: 12px 8px;

  gap: 8px;
  display: flex;
  align-items: center;

  background: ${(props) => props.theme.colors?.greys?.lightest};
`;
