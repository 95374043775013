import i18next from 'i18next';

import utilsXLS from '@commons/utils/makeXLS';

const getFileName = (storeName) => i18next.t('FEATURE.BACKOFFICE.CASHIER_STORES', { storeName });
const getSheetName = (storeName) => i18next.t('FEATURE.BACKOFFICE.CASHIER_STORES', { storeName });

const COLUMNS_SETTINGS = [
  {
    propertyKey: 'name',
    name: i18next.t('GENERAL.NAME'),
  },
  {
    propertyKey: 'cashierConfigName',
    name: i18next.t('GENERAL.CASHIER_CONNEXION'),
  },
  {
    propertyKey: 'address',
    name: i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_ADDRESS'),
  },
  {
    propertyKey: 'postCode',
    name: i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_POSTAL_CODE'),
  },
  {
    propertyKey: 'city',
    name: i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_CITY'),
  },
  {
    propertyKey: 'country',
    name: i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_COUNTRY'),
  },
  {
    propertyKey: 'associatedStores',
    name: i18next.t('GENERAL.LINKED'),
  },
];

const handleExport = (cashierStores, storeName) => {
  const formattedCashierStores = cashierStores.map((cashierStore) => ({
    ...cashierStore,
    associatedStores: cashierStore.associatedStores
      .map((associatedStore) => associatedStore.name)
      .join(' '),
  }));

  const sheet = utilsXLS.generateDefaultSheet(
    getSheetName(storeName),
    COLUMNS_SETTINGS,
    formattedCashierStores,
  );

  utilsXLS.makeXLS(getFileName(storeName), [sheet]);
};

export default handleExport;
