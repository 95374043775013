import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';

import useLocalStorage from '../../hooks/useLocalStorage';

import { switchCentralMode } from '../../actions/user';

import { getCentralKitchenStores } from '../../selectors/stores';
import { getFeaturesKeyByPath } from '../../actions/feature';
import { getTheme } from '../utils/theme';

import SideBarDrawer from './SideBarDrawer';
import SideBarDrawerContent from './SideBarContent/index';

import { filterModulesWithUserRights, filterSubModulesModuleChildren } from './utils/filters';
import { ORDERED_CENTRAL_KITCHEN_NAV_TREE, ORDERED_NAV_TREE, getCurrentFeature } from '../features';

import {
  Bottom,
  Container,
  Head,
  Image,
  Item,
  ItemImage,
  ItemImageContainer,
  ItemsContainer,
  Row,
} from './styledComponents';

const SideBar = (props) => {
  const {
    user,
    client: {
      clientName,
      clientPicture,
      storeName,
      hasCentralKitchens,
      clientStatus,
      hasLocalCatalogs,
    },
    defaultPath,
    userRights,
    featuresKeyByPath,
    getFeaturesKeyByPath,
    centralKitchenStores,
    switchCentralMode,
  } = props;

  const theme = getTheme();

  const [history, location] = [useHistory(), useLocation()];
  const [isCentralMode, setIsCentralMode] = useLocalStorage('isCentralMode', false);

  const [isOpened, setIsOpened] = useState(false);
  const [modules, setModules] = useState(ORDERED_NAV_TREE);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [currentFeature, setCurrentFeature] = useState({});
  const [selectedModule, setSelectedModule] = useState({});
  const [currentModule, setCurrentModule] = useState({}); // Different from selectedModule because it's the current active module and not not necessarily the one selected

  const [hasMultipleAccounts] = useState(get(user, 'accountGroupMappings', []).length);
  const [isSwitchAccountOpened, setIsSwitchAccountOpened] = useState(false);

  // Filtered with user rights modules
  const [filteredModules, setFilteredModules] = useState([]);

  const isSelected = (currentFeature, module) => {
    if (isEmpty(currentFeature)) {
      return false;
    }

    // If we are at the user's profile page, no module is selected
    if (
      !!currentFeature.getLink &&
      location.pathname === currentFeature.getLink({ profileUser: { id: user.id } })
    ) {
      return false;
    }

    const moduleName = module.getText({ storeName });
    const modulePath = module.path;

    return (
      modulePath === currentFeature.path || moduleName === get(currentFeature, 'moduleInfo.name')
    );
  };

  const triggerDrawer = ({ module, closeDrawer, isUserSection }) => {
    if (closeDrawer) {
      setIsOpened(false);
      setSelectedModule({});
      setIsSwitchAccountOpened(false);
      return;
    }

    if (isEmpty(selectedModule)) {
      setIsOpened((prevState) => !prevState);
      setIsSwitchAccountOpened(false);
    }

    if (module) {
      // if we do not change modules, we close the drawer and unselect the module
      if (module.path === selectedModule.path) {
        setIsOpened((prevState) => !prevState);
        setSelectedModule({});
        setIsSwitchAccountOpened(false);
        return;
      }

      setSelectedModule(module);

      if (isUserSection && hasMultipleAccounts) {
        setIsSwitchAccountOpened(true);
      }
    }
  };

  const onModuleClick = (module) => {
    if (module.isExternalLink) {
      window.open(module.path, '_blank', 'noopener,noreferrer');
      return;
    }

    const dashboardPath = '/dashboard';

    const dashboardFeaturePaths = Object.keys(featuresKeyByPath).filter((path) =>
      path.startsWith(dashboardPath),
    );

    // For dashboard module, if only linked to one dashboard should not open drawer and redirect directly
    if (module.path === dashboardPath && dashboardFeaturePaths.length === 1) {
      history.push(dashboardFeaturePaths[0]);
      return;
    }

    // For modules that are single pages without subModules
    if (module.path && isEmpty(module.children)) {
      history.push(module.path);
      return;
    }

    triggerDrawer({ module });
  };

  useEffect(() => {
    const formattedCurrentFeature = getCurrentFeature(featuresKeyByPath, location);

    // If the submodule is not visible for the client, redirect to the default path
    if (
      !isEmpty(formattedCurrentFeature) &&
      !!formattedCurrentFeature.subModuleInfo.isVisible &&
      !formattedCurrentFeature.subModuleInfo.isVisible({ clientStatus, hasLocalCatalogs }) &&
      !formattedCurrentFeature.parentSubFeaturesPath
    ) {
      history.push(defaultPath);
      return;
    }

    setCurrentFeature(formattedCurrentFeature);
    setIsFullScreen(formattedCurrentFeature ? formattedCurrentFeature.isFullScreen : false);
  }, [location, featuresKeyByPath]);

  useEffect(() => {
    if (isCentralMode) {
      setModules(ORDERED_CENTRAL_KITCHEN_NAV_TREE);
      return;
    }

    setModules(ORDERED_NAV_TREE);
  }, [isCentralMode]);

  useEffect(() => {
    // Filter sidebar modules
    const filteredModules = modules.reduce((acc, currentModule) => {
      const subModules = currentModule.children;

      if (subModules) {
        const filteredSubModules = filterModulesWithUserRights(subModules, userRights);

        acc.push({
          ...currentModule,
          children: filteredSubModules,
        });

        return acc;
      }

      acc.push(currentModule);
      return acc;
    }, []);

    // Filter sub-modules children
    const filteredModulesChildren = filteredModules.reduce((acc, currentModule) => {
      const subModules = currentModule.children;

      if (subModules) {
        const filteredSubModuleChildren = filterSubModulesModuleChildren(subModules, userRights);

        if (!isEmpty(filteredSubModuleChildren)) {
          acc.push({
            ...currentModule,
            children: filteredSubModuleChildren,
          });
        }

        return acc;
      }

      // Check features without children
      if (userRights.some(({ path }) => path === currentModule.path)) {
        acc.push(currentModule);
      }

      return acc;
    }, []);

    setFilteredModules(filteredModulesChildren);

    getFeaturesKeyByPath(filteredModulesChildren, storeName);
  }, [userRights, modules]);

  useEffect(() => {
    if (isEmpty(currentFeature)) {
      return;
    }

    const currentActiveModule = filteredModules.find((module) => {
      const moduleName = module.getText({ storeName });
      const modulePath = module.path;

      return (
        modulePath === currentFeature.path || moduleName === get(currentFeature, 'moduleInfo.name')
      );
    });

    setCurrentModule(currentActiveModule);
  }, [currentFeature]);

  const updateReferential = (shouldSwitchCentralMode) => {
    setIsCentralMode(shouldSwitchCentralMode);
    switchCentralMode(shouldSwitchCentralMode);

    triggerDrawer({ closeDrawer: true });
  };

  if (isFullScreen) {
    return <Fragment />;
  }

  return (
    <Fragment>
      <Container>
        <Row>
          <Head>
            <Image alt="Inpulse" position={'top'} src="/images/logo-inpulse.svg" />
          </Head>

          <ItemsContainer>
            {filteredModules.map((module) => (
              <Row key={module.path}>
                <Item onClick={() => onModuleClick(module)}>
                  <ItemImageContainer isSelected={isSelected(currentFeature, module)}>
                    <ItemImage isSelected={isSelected(currentFeature, module)} src={module.icon} />
                  </ItemImageContainer>
                </Item>
              </Row>
            ))}
          </ItemsContainer>
        </Row>
        <Row>
          <Bottom>
            {hasCentralKitchens && !isEmpty(centralKitchenStores) && (
              <Fragment>
                <ItemImageContainer
                  isSelected={!isCentralMode}
                  onClick={() => updateReferential(false)}
                >
                  <ItemImage
                    isSelected={!isCentralMode}
                    src={'/images/inpulse/store-black-small.svg'}
                  />
                </ItemImageContainer>
                <ItemImageContainer
                  customStyle={{ marginBottom: '16px' }}
                  focusColor={theme.colors.brand.secondary}
                  isSelected={isCentralMode}
                  onClick={() => updateReferential(true)}
                >
                  <ItemImage
                    isSelected={isCentralMode}
                    src={'/images/inpulse/central-black-small.svg'}
                  />
                </ItemImageContainer>
              </Fragment>
            )}
            <Item onClick={() => triggerDrawer({ module: currentModule, isUserSection: true })}>
              <Image
                alt={clientName}
                position={'bottom'}
                src={clientPicture || '/images/logo-inpulse.svg'}
              />
            </Item>
          </Bottom>
        </Row>
      </Container>

      <SideBarDrawer
        direction={'left'}
        isOpened={isOpened}
        size={'240px'}
        onClick={() => triggerDrawer({ closeDrawer: true })}
      >
        <SideBarDrawerContent
          clientStatus={clientStatus}
          currentFeature={currentFeature}
          hasLocalCatalogs={hasLocalCatalogs}
          hasMultipleAccounts={hasMultipleAccounts}
          isSwitchAccountOpened={isSwitchAccountOpened}
          location={location}
          selectedModule={selectedModule}
          setIsSwitchAccountOpened={setIsSwitchAccountOpened}
          storeName={storeName}
          triggerDrawer={triggerDrawer}
          updateReferential={updateReferential}
          user={user}
        />
      </SideBarDrawer>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  centralKitchenStores: getCentralKitchenStores(state.baseReducer.activeStores),
  userRights: state.baseReducer.userRights,
  featuresKeyByPath: state.baseReducer.featuresKeyByPath,
});

const mapDispatchToProps = (dispatch) => ({
  getFeaturesKeyByPath: (navTree, storeName) => {
    dispatch(getFeaturesKeyByPath(navTree, storeName));
  },
  switchCentralMode: (centralMode) => {
    dispatch(switchCentralMode(centralMode));
  },
});

SideBar.propTypes = {
  clientName: PropTypes.string,
  clientPicture: PropTypes.string,
};

SideBar.defaultProps = {
  clientName: 'Inpulse',
  clientPicture: '/images/logo-inpulse.svg',
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
