import i18next from 'i18next';
import JSZip from 'jszip';

import { orderBy } from 'lodash';
import utilsXLS from '@commons/utils/makeXLS';

const downloadBlob = (blob, filename) => {
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const exportInternalInvoicesPDF = async (internalInvoices) => {
  if (internalInvoices.length === 1) {
    // Download the single PDF directly
    const response = await fetch(internalInvoices[0].preparationInvoice);
    const blob = await response.blob();

    downloadBlob(blob, `${internalInvoices[0].number}.pdf`);

    return;
  }

  const zip = new JSZip();

  // Fetch all PDF files and add them to the ZIP
  await Promise.all(
    internalInvoices.map(async ({ preparationInvoice, number }) => {
      const response = await fetch(preparationInvoice);
      const blob = await response.blob();
      zip.file(`${number}.pdf`, blob);
    }),
  );

  // Generate the ZIP and trigger the download
  const zipBlob = await zip.generateAsync({ type: 'blob' });

  downloadBlob(zipBlob, 'internal-invoices.zip');
};

const createMainSheet = (internalInvoices, currency = {}) => {
  const columns = [
    {
      propertyKey: 'issuerStore.name',
      name: i18next.t('GENERAL.ISSUER'),
    },
    {
      propertyKey: 'billedStore.name',
      name: i18next.t('GENERAL.BILLED'),
    },
    {
      propertyKey: 'date',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_INVOICE_DATE'),
    },
    {
      propertyKey: 'number',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_INVOICE_NUMBER'),
    },
    {
      propertyKey: 'total',
      name: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_INVOICED_TOT_EX_TAX'),
      type: 'currency',
    },
  ];

  const sortedInternalInvoice = orderBy(internalInvoices, 'date', 'desc');

  return utilsXLS.generateDefaultSheet(
    i18next.t('FEATURE.INVOICE.INTERNAL_INVOICES'),
    columns,
    sortedInternalInvoice,
    currency,
  );
};

export const exportInternalInvoicesXLS = (internalInvoices, currency) => {
  const filename = i18next.t('INVOICE.INTERNAL_INVOICES.EXPORT_LIST_FILENAME');

  const mainSheet = createMainSheet(internalInvoices, currency);

  utilsXLS.makeXLS(filename, [mainSheet]);
};
