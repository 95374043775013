import axios from '../../core/http';

import { config } from '../../config';

const baseApiUrl = `${config.baseApiUrl}/v1`;

function updateIngredientsCostOfClient(clientId) {
  return axios
    .post(
      `${baseApiUrl}/backoffice/products/ingredients`,
      {
        clientId: clientId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export const backoffice = {
  updateIngredientsCostOfClient,
};
