import { config } from '../config';
import axios from '../core/http';

const baseApiUrl = `${config.baseApiUrl}/v1`;

export const checkLinkValidity = async (token) =>
  axios
    .post(
      `${baseApiUrl}/signup/invitation/check-link-validity`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

export const resendEmail = async (email, userId, clientId) =>
  axios
    .post(
      `${baseApiUrl}/signup/resend-email`,
      {
        email,
        userId,
        clientId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

export default { checkLinkValidity, resendEmail };
