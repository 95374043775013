import { get } from 'lodash';

import entityXlsConstants from '@commons/Excels/constants/entity.xlsx.constant';
import importTypeXlsConstants from '@commons/Excels/constants/importType.xslx.constant';
import normalizeStringValue from '@commons/utils/normalizeStringValue';

import { normalizeJsonObject } from '@backoffice/utils';

export const formatEntityBatchCreationFile = (entities) => {
  const sheetConfig = entityXlsConstants.getSheetConfig();

  const hiddenSheetAllergen = entityXlsConstants.getHiddenSheetAllergen();

  const hiddenSheetCategoryConfig = entityXlsConstants.getHiddenSheetCategory();

  const formattedEntities = normalizeJsonObject(entities);

  return Object.keys(formattedEntities).reduce((result, sheetName) => {
    if (!result[importTypeXlsConstants.KEY]) {
      result[importTypeXlsConstants.KEY] = get(formattedEntities, 'ImportType[0].importtype', null);
    }

    if (sheetName === sheetConfig.SHEET.name) {
      result[sheetConfig.SHEET.id] = formattedEntities[sheetName].map((current) => {
        const associatedAllergens = formattedEntities[hiddenSheetAllergen.SHEET.NAME].filter(
          ({ [hiddenSheetAllergen.NORMALIZE_COLUMN_ID.NAME]: allergen }) => {
            // Replaces the accents ( céleri -> celeri )
            const normalizedAllergens = normalizeStringValue(allergen);

            return !!current[normalizedAllergens] && current[normalizedAllergens] === 'true';
          },
        );

        const allergensIds = associatedAllergens.map(({ id }) => id);

        return {
          [sheetConfig.COLUMN_ID.NAME]: current[sheetConfig.COLUMNS_CONFIG.NAME],
          [sheetConfig.COLUMN_ID.UNIT]: current[sheetConfig.COLUMNS_CONFIG.UNIT],
          [sheetConfig.COLUMN_ID.CATEGORY]: current[sheetConfig.COLUMNS_CONFIG.CATEGORY],
          [sheetConfig.COLUMN_ID.COST]: current[sheetConfig.COLUMNS_CONFIG.COST],
          [sheetConfig.COLUMN_ID.IS_STRATEGIC]: current[sheetConfig.COLUMNS_CONFIG.IS_STRATEGIC],
          allergensIds,
        };
      });
    }

    if (sheetName === hiddenSheetCategoryConfig.SHEET.NAME) {
      result[hiddenSheetCategoryConfig.SHEET.ID] = formattedEntities[sheetName].map((current) => ({
        [hiddenSheetCategoryConfig.COLUMN_ID.NAME]:
          current[hiddenSheetCategoryConfig.NORMALIZE_COLUMN_ID.NAME],
      }));
    }

    if (sheetName === hiddenSheetAllergen.SHEET.NAME) {
      result[hiddenSheetAllergen.SHEET.ID] = formattedEntities[sheetName].map((current) => ({
        [hiddenSheetAllergen.COLUMN_ID.NAME]: current[hiddenSheetAllergen.NORMALIZE_COLUMN_ID.NAME],
        [hiddenSheetAllergen.COLUMN_ID.ID]: current[hiddenSheetAllergen.NORMALIZE_COLUMN_ID.ID],
      }));
    }

    return result;
  }, {});
};

export default {
  formatEntityBatchCreationFile,
};
