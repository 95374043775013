import { connect } from 'react-redux';
import { get, orderBy } from 'lodash';
import { useLocation } from 'react-router-dom';
import i18next from 'i18next';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// Design
import { Container, Content, Subtitle, Title } from './styledComponents';

// Commons
import { Button } from '@commons/utils/styledLibraryComponents';
import { DATE_DISPLAY_FORMATS, TIME_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { getTheme } from '@commons/utils/theme';
import { getUserTimezone, isSameDay } from '@commons/utils/date';
import { LoadingIcon } from '@commons/Metrics/components/Stats/styledComponents';
import Drawer from '@commons/Drawer';
import HistoryDrawerContent from '@commons/HistoryDrawer/HistoryDrawerContent';

// Selectors
import { getClientInfo } from '@selectors/client';

import mixPanelUtils, { ENUM_EVENTS } from '@commons/utils/mixpanel';
import ORDER_STATUS from '../../constants/status';
import utilsLib from '../../utils';

import { showErrorMessage } from '@actions/messageconfirmation';

import auditService from '@services/auditLogs';
import SpinnerIcon from '@assets/icons/new-loader.svg';

function OrderInfo(props) {
  const {
    order,
    partnerId,
    orderStatus,
    orderOpeningTime,
    // Redux
    user: { id: userId, email: userEmail },
    client: { clientId },
    showErrorMessage,
  } = props;

  const location = useLocation();

  const theme = getTheme();

  const [isLoading, setIsLoading] = useState(true);
  const [displayOrderHistory, setDisplayOrderHistory] = useState(false);
  const [auditLogs, setAuditLogs] = useState([]);
  const [labelUpdatedAt, setLabelUpdatedAt] = useState('');

  const reference = utilsLib.getOrderReference(
    get(order, 'reference'),
    partnerId,
    orderOpeningTime,
  );

  /* USE EFFECT */

  useEffect(() => {
    if (orderStatus !== ORDER_STATUS.CREATION) {
      fetchOrderHistory(order.id);
    }
  }, [order]);

  useEffect(() => {
    if (!auditLogs.length) {
      setLabelUpdatedAt('');
      return;
    }

    const sortedAuditLogs = orderBy(auditLogs, 'actionTimestamp', 'desc');

    const latestActionTimestamp = sortedAuditLogs[0].actionTimestamp;

    const today = new Date();

    const lastModifiedToday = isSameDay(latestActionTimestamp, today);

    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const lastModifiedYesterday = isSameDay(latestActionTimestamp, yesterday);

    const latestAuditLogTime = moment(latestActionTimestamp).format(
      TIME_DISPLAY_FORMATS.SLASHED_HOUR_MINUTE_SECOND,
    );
    const latestAuditLogDate = moment(latestActionTimestamp).format(
      DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR,
    );

    if (lastModifiedToday) {
      setLabelUpdatedAt(
        i18next.t('ORDERS.ORDERS.FORM_LAST_UPDATED_TODAY', {
          time: latestAuditLogTime,
        }),
      );

      return;
    }

    if (lastModifiedYesterday) {
      setLabelUpdatedAt(
        i18next.t('ORDERS.ORDERS.FORM_LAST_UPDATED_YESTERDAY', {
          time: latestAuditLogTime,
        }),
      );

      return;
    }

    setLabelUpdatedAt(
      i18next.t('ORDERS.ORDERS.FORM_LAST_UPDATED_AT', {
        date: latestAuditLogDate,
        time: latestAuditLogTime,
      }),
    );
  }, [auditLogs]);

  /* METHODS */

  const fetchOrderHistory = async (orderId) => {
    setIsLoading(true);
    try {
      const orderAuditLogs = await auditService.getTargetIdAuditLogs(orderId);
      setAuditLogs(orderAuditLogs);
    } catch {
      showErrorMessage(i18next.t('ORDERS.AUDIT_EVENTS.ERROR_FETCHING'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenDrawer = () => {
    if (!order) {
      return;
    }

    const shouldDisplayDrawer = !displayOrderHistory;

    setDisplayOrderHistory(shouldDisplayDrawer);

    if (shouldDisplayDrawer) {
      mixPanelUtils.sendMetric(ENUM_EVENTS.OPEN_AUDIT_LOG_SIDE_PANEL, {
        userId,
        userEmail,
        clientId,
        path: location.pathname,
        orderId: order.id,
        orderReference: order.reference,
      });
    }
  };

  return (
    <>
      <Container>
        <Content>
          <Title>{reference}</Title>

          {orderStatus !== ORDER_STATUS.CREATION && (
            <>
              {isLoading && (
                <LoadingIcon
                  alt={'component-loader'}
                  height="16px"
                  src={SpinnerIcon}
                  width="16px"
                />
              )}
              {!isLoading && !labelUpdatedAt && (
                <Subtitle>
                  {i18next.t('ORDERS.ORDERS.FORM_LAST_UPDATED_AT_DEPRECATED')}{' '}
                  {orderStatus !== ORDER_STATUS.CREATION
                    ? moment.tz(get(order, 'orderDate'), getUserTimezone()).calendar()
                    : '-'}
                </Subtitle>
              )}
              {!isLoading && labelUpdatedAt && (
                <Button
                  buttonCustomStyle={{
                    paddingLeft: '0px',
                    height: '20px',
                    font: theme.fonts.textSmallHeight14,
                  }}
                  color={'inpulse-outline'}
                  fontColor={theme.colors.blacks.ipBlack4}
                  handleClick={handleOpenDrawer}
                  icon={'/images/inpulse/history.svg'}
                  iconOnLeft={false}
                  label={labelUpdatedAt}
                  noBorder
                />
              )}
            </>
          )}
        </Content>
      </Container>

      <Drawer
        isOpened={displayOrderHistory}
        overlayOpacity={0}
        onClick={() => {
          setDisplayOrderHistory(false);
        }}
      >
        <HistoryDrawerContent
          auditLogs={auditLogs}
          subtitle={reference}
          onClose={() => setDisplayOrderHistory(false)}
        />
      </Drawer>
    </>
  );
}

OrderInfo.propTypes = {
  order: PropTypes.object,
  partnerId: PropTypes.string,
  orderOpeningTime: PropTypes.string,
  orderStatus: PropTypes.number.isRequired,
  receptionDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

OrderInfo.defaultProps = {
  order: null,
  partnerId: null,
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderInfo);
