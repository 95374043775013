import i18next from 'i18next';
import normalizeStringValue from '@commons/utils/normalizeStringValue';

const getSheetConfiguration = () => ({
  ID: 'products',
  NAME: i18next.t('_BACKEND_TRANSLATIONS.PRODUCTS.IMPORTS.SHEET_NAME'),
});

const SHEET = {
  ID: 'products',
  NAME: 'Produits',
};

const COLUMN_IDS = {
  NAME: 'name',
  BRAND: 'brand',
  CATEGORY: 'category',
  SUB_CATEGORY: 'subCategory',
  SKU: 'sku',
  SHELF_LIFE: 'shelfLife',
  PRICE_WITH_TAXES: 'priceWithTaxes',
  VAT_RATE: 'vatRate',
  DELIVERY_PRICE_WITH_TAXES: 'deliveryPriceWithTaxes',
  DELIVERY_VAT_RATE: 'deliveryVatRate',
  COMPOSITION_TYPE: 'compositionTypes',
  ASSOCIATED_RECIPE: 'associatedRecipe',
  ASSOCIATED_INGREDIENT: 'associatedIngredient',
};

const normalizeKeys = (object) =>
  Object.entries(object).reduce((acc, [key, value]) => {
    acc[key] = normalizeStringValue(value);
    return acc;
  }, {});

const getNormalizedColumnIds = () => {
  const columns = {
    NAME: i18next.t('_BACKEND_TRANSLATIONS.PRODUCTS.IMPORTS.NAME'),
    BRAND: i18next.t('_BACKEND_TRANSLATIONS.PRODUCTS.IMPORTS.BRANDS'),
    CATEGORY: i18next.t('_BACKEND_TRANSLATIONS.PRODUCTS.IMPORTS.CATEGORY'),
    SUB_CATEGORY: i18next.t('_BACKEND_TRANSLATIONS.PRODUCTS.IMPORTS.SUB_CATEGORY'),
    SKU: i18next.t('_BACKEND_TRANSLATIONS.PRODUCTS.IMPORTS.SKU'),
    SHELF_LIFE: i18next.t('_BACKEND_TRANSLATIONS.PRODUCTS.IMPORTS.SHELF_LIFE'),
    PRICE_WITH_TAXES: i18next.t('_BACKEND_TRANSLATIONS.PRODUCTS.IMPORTS.PRICE_EXCLUDING_TAXES'),
    VAT_RATE: i18next.t('_BACKEND_TRANSLATIONS.PRODUCTS.IMPORTS.VAT_RATE'),
    DELIVERY_PRICE_WITH_TAXES: i18next.t(
      '_BACKEND_TRANSLATIONS.PRODUCTS.IMPORTS.DELIVERY_PRICE_WITH_TAXES',
    ),
    DELIVERY_VAT_RATE: i18next.t('_BACKEND_TRANSLATIONS.PRODUCTS.IMPORTS.DELIVERY_VAT_RATE'),
    COMPOSITION_TYPE: i18next.t('_BACKEND_TRANSLATIONS.PRODUCTS.IMPORTS.COMPOSITION_TYPE'),
    ASSOCIATED_RECIPE: i18next.t('_BACKEND_TRANSLATIONS.PRODUCTS.IMPORTS.ASSOCIATED_RECIPE'),
    ASSOCIATED_INGREDIENT: i18next.t(
      '_BACKEND_TRANSLATIONS.PRODUCTS.IMPORTS.ASSOCIATED_INGREDIENT',
    ),
  };

  return normalizeKeys(columns);
};

// Category
const getHiddenSheetCategoryConfig = () => ({
  SHEET: {
    ID: 'categories',
    NAME: i18next.t('_BACKEND_TRANSLATIONS.PRODUCTS.IMPORTS.HIDDEN_SHEET_CATEGORY_NAME'),
  },
  COLUMN_ID: {
    NAME: 'name',
  },
  NORMALIZE_COLUMN_ID: {
    NAME: i18next
      .t('_BACKEND_TRANSLATIONS.PRODUCTS.IMPORTS.HIDDEN_SHEET_CATEGORY_COLUMN_NAME')
      .toLowerCase(),
  },
});

// Subcategory
const getHiddenSheetSubCategoryConfig = () => ({
  SHEET: {
    ID: 'subCategories',
    NAME: i18next.t('_BACKEND_TRANSLATIONS.PRODUCTS.IMPORTS.HIDDEN_SHEET_SUB_CATEGORY_NAME'),
  },
  COLUMN_ID: {
    NAME: 'name',
  },
  NORMALIZE_COLUMN_ID: {
    NAME: i18next
      .t('_BACKEND_TRANSLATIONS.PRODUCTS.IMPORTS.HIDDEN_SHEET_SUB_CATEGORY_COLUMN_NAME')
      .toLowerCase(),
  },
});

// Brands
const getHiddenSheetBrandConfig = () => ({
  SHEET: {
    ID: 'brands',
    NAME: i18next.t('_BACKEND_TRANSLATIONS.PRODUCTS.IMPORTS.HIDDEN_SHEET_BRAND_NAME'),
  },
  COLUMN_ID: {
    ID: 'id',
    NAME: 'name',
  },
  NORMALIZE_COLUMN_ID: {
    ID: 'brandid',
    NAME: i18next
      .t('_BACKEND_TRANSLATIONS.PRODUCTS.IMPORTS.HIDDEN_SHEET_BRAND_COLUMN_NAME')
      .toLowerCase(),
  },
});

// Associated ingredient
const getHiddenSheetAssociatedIngredientConfig = () => ({
  SHEET: {
    ID: 'associatedIngredient',
    NAME: i18next.t(
      '_BACKEND_TRANSLATIONS.PRODUCTS.IMPORTS.HIDDEN_SHEET_ASSOCIATED_INGREDIENT_NAME',
    ),
  },
  COLUMN_ID: {
    ID: 'id',
    NAME: 'name',
  },
  NORMALIZE_COLUMN_ID: {
    ID: 'ingredientid',
    NAME: i18next
      .t('_BACKEND_TRANSLATIONS.PRODUCTS.IMPORTS.HIDDEN_SHEET_ASSOCIATED_INGREDIENT_COLUMN_NAME')
      .toLowerCase(),
  },
});

// Associated recipe
const getHiddenSheetAssociatedRecipeConfig = () => ({
  SHEET: {
    ID: 'associatedRecipe',
    NAME: i18next.t('_BACKEND_TRANSLATIONS.PRODUCTS.IMPORTS.HIDDEN_SHEET_ASSOCIATED_RECIPE_NAME'),
  },
  COLUMN_ID: {
    ID: 'id',
    NAME: 'name',
  },
  NORMALIZE_COLUMN_ID: {
    ID: 'recipeid',
    NAME: i18next
      .t('_BACKEND_TRANSLATIONS.PRODUCTS.IMPORTS.HIDDEN_SHEET_ASSOCIATED_RECIPE_COLUMN_NAME')
      .toLowerCase(),
  },
});

// For central kitchen products
const HIDDEN_SHEET_CATEGORY = {
  ID: 'categories',
  NAME: 'Catégories',
};

const HIDDEN_CATEGORY_COLUMN_ID = {
  NAME: 'name',
};

const HIDDEN_CATEGORY_NORMALIZE_COLUMN_ID = {
  NAME: 'nom',
};

const HIDDEN_SHEET_SUB_CATEGORY = {
  ID: 'subCategories',
  NAME: 'SousCatégories',
};

const HIDDEN_SUB_CATEGORY_COLUMN_ID = {
  NAME: 'name',
};

const HIDDEN_SUB_CATEGORY_NORMALIZE_COLUMN_ID = {
  NAME: 'nom',
};

export default {
  SHEET,
  COLUMN_IDS,
  getSheetConfiguration,
  getNormalizedColumnIds,
  // Category
  getHiddenSheetCategoryConfig,
  // Subcategory
  getHiddenSheetSubCategoryConfig,
  // Brand
  getHiddenSheetBrandConfig,
  // Associated ingredient
  getHiddenSheetAssociatedIngredientConfig,
  // Associated recipe
  getHiddenSheetAssociatedRecipeConfig,
  // For central kitchen products
  HIDDEN_SHEET_CATEGORY,
  HIDDEN_CATEGORY_COLUMN_ID,
  HIDDEN_CATEGORY_NORMALIZE_COLUMN_ID,
  HIDDEN_SHEET_SUB_CATEGORY,
  HIDDEN_SUB_CATEGORY_COLUMN_ID,
  HIDDEN_SUB_CATEGORY_NORMALIZE_COLUMN_ID,
};
