import { isEmpty } from 'lodash';
import i18next from 'i18next';

import { canEditBufferStocks } from '@selectors/actions/storeActions';

export const getColumns = () => [
  {
    id: 'ingredientName',
    name: i18next.t('GENERAL.NAME'),
    propertyKey: 'ingredientName',
    // The render method is necessary otherwise the text will overflow if the name is too long
    render: (item) => item,
  },
  {
    id: 'category',
    name: i18next.t('GENERAL.CATEGORY'),
    propertyKey: 'category',
    filterType: 'string',
    filterStringAllowNone: true,
    render: (item) => item || i18next.t('GENERAL.NONE_VALUE'),
  },
  {
    id: 'nbDays',
    name: i18next.t('ADMIN.STORES.BUFFER_STOCK_COLUMN_TITLE'),
    tooltipText: i18next.t('ADMIN.STORES.BUFFER_STOCK_COLUMN_TOOLTIP'),
    propertyKey: 'nbDays',
    filterType: 'numeric',
    excludeFromSearch: true,
    render: (days) => i18next.t('ADMIN.STORES.BUFFER_STOCK_COLUMN_VALUE', { days }),
  },
];

export const getActions = ({
  openBufferStockModal,
  filteredIngredients,
  selectedIngredients,
  handleExportXLS,
  authorizedActions,
}) => {
  const areItemsSelected = !isEmpty(selectedIngredients);

  const actions = [
    {
      id: 'export-list',
      actionLabel: i18next.t(areItemsSelected ? 'GENERAL.EXPORT_SELECTION' : 'GENERAL.EXPORT_LIST'),
      handleAction: () =>
        handleExportXLS(areItemsSelected ? selectedIngredients : filteredIngredients),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
    },
  ];

  if (
    !!selectedIngredients &&
    !!selectedIngredients.length &&
    canEditBufferStocks(authorizedActions)
  ) {
    actions.unshift({
      id: 'manage-buffer-stock',
      actionLabel: i18next.t('ADMIN.STORES.MANAGE_BUFFER_STOCKS_ACTIONS'),
      handleAction: () => openBufferStockModal(selectedIngredients),
      actionIcon: () => '/images/inpulse/gear-black-small.svg',
    });
  }

  return actions;
};

export const getRowActions = (openBufferStockModal, authorizedActions) =>
  canEditBufferStocks(authorizedActions)
    ? [
        {
          id: 'action-link',
          actionLabel: () => i18next.t('ADMIN.STORES.MANAGE_BUFFER_STOCKS_ACTIONS'),
          handleAction: (selectedIngredient) => openBufferStockModal([selectedIngredient]),
          actionIcon: () => '/images/inpulse/gear-black-small.svg',
        },
      ]
    : [];

// Do not delete, this fake data is necessary to show the upgrade plan view
export const FAKE_DATA_FOR_PLAN_UPGRADE = [
  {
    id: 1,
    ingredientName: 'Pomme de terre',
    category: 'Féculent',
    nbDays: 1,
  },
  {
    id: 2,
    ingredientName: 'Hamburger',
    category: 'Fast food',
    nbDays: 15,
  },
  {
    id: 3,
    ingredientName: 'Glace',
    category: 'Froid',
    nbDays: 3,
  },
  {
    id: 4,
    ingredientName: 'Pomme',
    category: 'Fruit',
    nbDays: 2,
  },
  {
    id: 5,
    ingredientName: 'Orange',
    category: 'Fruit',
    nbDays: 1,
  },
  {
    id: 6,
    ingredientName: 'Agrume',
    category: 'Fruit',
    nbDays: 5,
  },
  {
    id: 7,
    ingredientName: 'Farine',
    category: null,
    nbDays: 5,
  },
  {
    id: 8,
    ingredientName: 'Banane',
    category: 'Fruit',
    nbDays: 5,
  },
  {
    id: 9,
    ingredientName: 'Pain',
    category: 'Boulangerie',
    nbDays: 5,
  },
  {
    id: 10,
    ingredientName: 'Yaourt',
    category: 'Dessert',
    nbDays: 5,
  },
];
