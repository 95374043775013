import i18next from 'i18next';
import React from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';
import sortActionsList from '@commons/utils/sortActionsList';

export const getRowActionsSettings = ({
  handleExport,
  openStoresAssociationModal,
  displayModalDeleteCashierStores,
  goCashierStoreDetailsPage,
  translatedStoreName,
}) => {
  const rowActions = [
    {
      id: 'edit',
      actionLabel: () =>
        i18next.t('BACKOFFICE.CASHIER_STORES.SINGLE_EDIT', { storeName: translatedStoreName }),
      handleAction: (item) => goCashierStoreDetailsPage(item.id),
      actionIcon: () => '/images/inpulse/pen-ip-black-small.svg',
    },
    {
      id: 'assign',
      actionLabel: () => i18next.t('GENERAL.ASSOCIATE_WITH_AN_INPULSE_ESTABLISHMENT'),
      handleAction: (item) => openStoresAssociationModal([item]),
      actionIcon: () => '/images/inpulse/link-black-small.svg',
    },
    {
      id: 'delete-one',
      actionLabel: () =>
        i18next.t('GENERAL.DELETE_CASHIER_STORE', { storeName: translatedStoreName }),
      handleAction: (item) => displayModalDeleteCashierStores([item]),
      actionIcon: () => '/images/inpulse/delete-ip-black-small.svg',
      isHidden: (item) => item.isSynchronized,
    },
    {
      id: 'export',
      actionLabel: () =>
        i18next.t('BACKOFFICE.CASHIER_STORES.EXPORT_SINGLE', { storeName: translatedStoreName }),
      handleAction: (item) => handleExport([item]),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
    },
  ];

  return sortActionsList(rowActions);
};

export const getActionsSettings = ({
  selectedItems,
  handleExport,
  handleExportMappings,
  displayModalDeleteCashierStores,
  openStoresAssociationModal,
  goCashierStoreDetailsPage,
  syncCashierStore,
  isSynchronizingCashierStores,
  translatedStoreName,
  translatedStoresName,
}) => {
  const hasSelectedItems = !!selectedItems.length;
  const hasUniqueSelection = selectedItems.length === 1;

  const actions = [
    {
      id: 'special-add',
      actionLabel: i18next.t('BACKOFFICE.CASHIER_STORES.SINGLE_CREATE', {
        storeName: translatedStoreName,
      }),
      handleAction: () => goCashierStoreDetailsPage('create'),
      actionIcon: () => '/images/inpulse/add-black-small.svg',
      isHidden: () => hasSelectedItems,
    },
    {
      id: 'edit',
      actionLabel: i18next.t('BACKOFFICE.CASHIER_STORES.SINGLE_EDIT', {
        storeName: translatedStoreName,
      }),
      handleAction: (item) => goCashierStoreDetailsPage(item[0].id),
      actionIcon: () => '/images/inpulse/pen-ip-black-small.svg',
      isHidden: () => !hasUniqueSelection,
    },
    {
      id: 'assign',
      actionLabel: i18next.t('GENERAL.ASSOCIATE_WITH_AN_INPULSE_ESTABLISHMENT'),
      handleAction: () => openStoresAssociationModal(selectedItems),
      actionIcon: () => '/images/inpulse/link-black-small.svg',
      isHidden: () => !hasSelectedItems,
    },
    {
      id: 'delete-more-than-one',
      actionLabel: i18next.t('GENERAL.DELETE_CASHIER_STORE_PLURAL', {
        storeName: translatedStoresName,
      }),
      handleAction: () => displayModalDeleteCashierStores(selectedItems),
      actionIcon: () => '/images/inpulse/delete-ip-black-small.svg',
      isHidden: () =>
        !hasSelectedItems ||
        hasUniqueSelection ||
        selectedItems.some(({ isSynchronized }) => isSynchronized),
    },
    {
      id: 'delete-one',
      actionLabel: i18next.t('GENERAL.DELETE_CASHIER_STORE', {
        storeName: translatedStoreName,
      }),
      handleAction: () => displayModalDeleteCashierStores(selectedItems),
      actionIcon: () => '/images/inpulse/delete-ip-black-small.svg',
      isHidden: () => !hasSelectedItems || !hasUniqueSelection || selectedItems[0].isSynchronized,
    },
    {
      id: 'export',
      actionLabel: i18next.t(
        hasSelectedItems ? 'STOCKS.STOCKS.FORM_EXPORT_SELECTION_BUTTON' : 'GENERAL.EXPORT_LIST',
      ),
      handleAction: () => handleExport(selectedItems),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
      isHidden: () => hasUniqueSelection,
    },
    {
      id: 'export-one',
      actionLabel: i18next.t('BACKOFFICE.CASHIER_STORES.EXPORT_SINGLE', {
        storeName: translatedStoresName,
      }),
      handleAction: () => handleExport(selectedItems),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
      isHidden: () => !hasUniqueSelection,
    },
    {
      id: 'export-mappings',
      actionLabel: i18next.t('BACKOFFICE.CASHIER_STORES.EXPORT_MAPPINGS', {
        storeName: translatedStoresName,
      }),
      handleAction: () => handleExportMappings(selectedItems),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
      isHidden: () => hasSelectedItems,
    },
    {
      id: 'sync',
      fixed: true,
      actionLabel: i18next.t('GENERAL.SYNC'),
      handleAction: syncCashierStore,
      render: () => (
        <Button
          animation={{
            rotate: isSynchronizingCashierStores,
          }}
          buttonCustomStyle={{
            width: 'fit-content',
          }}
          color={'inpulse-default'}
          fontSize={14}
          handleClick={syncCashierStore}
          icon={'/images/inpulse/sync-white.svg'}
          iconCustomStyle={{ width: '20px', height: '20px' }}
          isDisabled={isSynchronizingCashierStores}
          label={i18next.t('GENERAL.SYNC')}
        />
      ),
    },
  ];

  return sortActionsList(actions);
};

export default {
  getRowActionsSettings,
  getActionsSettings,
};
