import { connect } from 'react-redux';
import { keyBy, orderBy } from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { openGenericModal, refreshGenericModal } from '@actions/modal';
import { switchCentralMode } from '@actions/user';

import { Button, Label } from '@commons/utils/styledLibraryComponents';
import { getTheme } from '@commons/utils/theme';
import EmptyState from '@commons/EmptyState';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

import { config } from '@src/config';

import utilsColumns from './utils/columns';
import utilsModals from './utils/modals';

import {
  ButtonContainer,
  ButtonsContainer,
  ColumnsContainer,
  Container,
  ContentContainer,
  LabelContainer,
  Row,
  RowContainer,
  TitleContainer,
} from './styledComponents';

const OrderSection = (props) => {
  const {
    // Props
    supplier,
    store,
    associatedOrders,
    setAssociatedOrders,
    internalInvoiceForm,
    isInError,
    // Dispatch methods
    openGenericModal,
    refreshGenericModal,
    readOnly,
  } = props;

  const theme = getTheme();

  const [ordersToBeAdded, setOrdersToBeAdded] = useState([]);

  const [columns] = useState(utilsColumns.get());

  // Handle modal's refresh
  useEffect(() => {
    const params = utilsModals.getAssociateOrdersModalConfig({
      supplier,
      store,
      associateOrders,
      ordersToBeAdded,
      setOrdersToBeAdded,
      associatedOrders,
      title: i18next.t('INVOICE.INTERNAL_INVOICES.ASSOCIATE_ORDER_MODAL_TITLE'),
    });

    refreshGenericModal(params);
  }, [ordersToBeAdded]);

  // Redirect to order page in sales point mode
  const openOrderNewTab = (reference) => {
    window.open(
      `${config.baseAppUrl}central-kitchen-order/preparation?search=${reference}`,
      '_blank',
    );
  };

  const associateOrders = () => {
    const associatedOrdersKeyById = keyBy(associatedOrders, 'id');

    const ordersToAdd = ordersToBeAdded.reduce((acc, order) => {
      if (associatedOrdersKeyById[order.id]) {
        return acc;
      }

      acc.push(order);
      return acc;
    }, []);

    const orderIds = [...associatedOrders, ...ordersToAdd].map(({ id }) => id);

    setAssociatedOrders([...associatedOrders, ...ordersToAdd]);
    internalInvoiceForm.setValue('orderIds', orderIds);
  };

  const deleteOrderAssociation = async (orderId) => {
    const filteredAssociatedOrders = associatedOrders.filter(({ id }) => id !== orderId);
    const orderIds = filteredAssociatedOrders.map(({ id }) => id);

    setAssociatedOrders(filteredAssociatedOrders);
    internalInvoiceForm.setValue('orderIds', orderIds);
  };

  const openModalNewAssociation = async () => {
    const params = utilsModals.getAssociateOrdersModalConfig({
      supplier,
      store,
      associateOrders,
      ordersToBeAdded,
      setOrdersToBeAdded,
      associatedOrders,
      title: i18next.t('INVOICE.INTERNAL_INVOICES.ASSOCIATE_ORDER_MODAL_TITLE'),
    });

    openGenericModal(params);
  };

  // check on readOnly to avoid displaying the empty state when in InternalInvoiceDetails page
  if (!readOnly && (!associatedOrders || !associatedOrders.length)) {
    return (
      <Container isInError={isInError}>
        <TitleContainer>
          {i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ASSOCIATED_ORDERS')}
        </TitleContainer>
        <EmptyState
          backgroundColor={ENUM_COLORS.IP_BLACK_5}
          label={i18next.t('INVOICE.INTERNAL_INVOICES.ASSOCIATE_ORDER_EMPTY_STATE_TITLE')}
          labelColor={ENUM_COLORS.IP_BLACK_1}
          labelFont={ENUM_FONTS.H2}
          renderActionButton={() => (
            <Button
              color={'inpulse-default'}
              handleClick={openModalNewAssociation}
              icon={'/images/inpulse/add-white-small.svg'}
              isDisabled={!store || !supplier}
              label={i18next.t('GENERAL.ASSOCIATE')}
            />
          )}
          subtitle={i18next.t('INVOICE.INTERNAL_INVOICES.ASSOCIATE_ORDER_EMPTY_STATE_SUBTITLE')}
          subtitleMargin={'8px'}
        />
      </Container>
    );
  }

  const sortedOrders = orderBy(associatedOrders, 'startOrderDate', 'desc');

  return (
    <Container>
      <TitleContainer>
        <LabelContainer>
          {i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ASSOCIATED_ORDERS')}
          <Label
            background={theme.colors.infoGreen}
            color={theme.colors.infoGreen}
            type={null}
            width={'24px'}
          >
            {associatedOrders.length}
          </Label>
        </LabelContainer>

        {!readOnly && (
          <ButtonContainer>
            <Button
              buttonCustomStyle={{ padding: '0px' }}
              color={'inpulse-outline'}
              handleClick={openModalNewAssociation}
              icon={'/images/inpulse/add-black-small.svg'}
              isDisabled={!store || !supplier}
              label={i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.ASSOCIATE_AN_ORDER')}
              noBorder
            />
          </ButtonContainer>
        )}
      </TitleContainer>
      <ContentContainer>
        <ColumnsContainer>
          {columns.map(({ name }, index) => (
            <Text
              color={ENUM_COLORS.DARKER}
              font={ENUM_FONTS.TEXT_BIG_HEIGHT_16}
              key={`column-header-${index}`}
            >
              {name}
            </Text>
          ))}
        </ColumnsContainer>
        {sortedOrders.map((order, id) => (
          <RowContainer key={`list-container-${id}`}>
            <Row>{columns.map(({ render }) => render(order))}</Row>
            <ButtonsContainer>
              <Button
                color={'inpulse-outline'}
                handleClick={() => {
                  openOrderNewTab(order.reference);
                }}
                icon={'/images/inpulse/open-a-new-black-small.svg'}
                iconCustomStyle={{ width: '16px', height: '16px' }}
              />
              {!readOnly && (
                <Button
                  color={'inpulse-grey'}
                  handleClick={() => deleteOrderAssociation(order.id)}
                  icon={'/images/inpulse/trash-white-small.svg'}
                  iconCustomStyle={{ width: '12px', height: '12px' }}
                />
              )}
            </ButtonsContainer>
          </RowContainer>
        ))}
      </ContentContainer>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => ({
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
  switchCentralMode: (centralMode) => {
    dispatch(switchCentralMode(centralMode));
  },
});

export default connect(null, mapDispatchToProps)(OrderSection);
