import axios from '../core/http';

import { config } from '../config';

const baseApiUrl = `${config.baseApiUrl}/v1`;

const getSalesComparison = (storeIds, startDate, endDate) =>
  axios
    .post(baseApiUrl + '/activity/salesComparison', {
      storeIds: storeIds,
      startDate,
      endDate,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then((response) => response.data.data)
    .catch((error) => {
      throw error;
    });

const getStoresWithTurnoverRatio = (storeIds, startDate, endDate) =>
  axios
    .post(baseApiUrl + '/activity/storeWithTurnoverRatio', {
      storeIds,
      startDate,
      endDate,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });

const getTurnoverEvolution = (storeIds, startDate, endDate) =>
  axios
    .post(baseApiUrl + '/activity/turnoverEvolution', {
      storeIds,
      startDate,
      endDate,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then((response) => response.data.data)
    .catch((error) => {
      throw error;
    });

const getTopSalesOfStores = (storeIds, date) =>
  axios
    .post(baseApiUrl + '/activity/lastWeekProductSaleAnalytics', {
      storeIds,
      date,
      sortProperty: 'turnover',
      sortOrder: 'desc',
      limit: 10,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });

const getFlopSalesOfStores = (storeIds, date, sortProperty) =>
  axios
    .post(baseApiUrl + '/activity/lastWeekProductSaleAnalytics', {
      storeIds,
      date,
      sortProperty,
      sortOrder: 'desc',
      limit: 10,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });

export const activityAnalytics = {
  getSalesComparison,
  getStoresWithTurnoverRatio,
  getTurnoverEvolution,
  getTopSalesOfStores,
  getFlopSalesOfStores,
};
