import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const Container = styled.div`
  padding: 0 16px;
  display: flex;
  height: ${({ hasMultipleAccounts, isSwitchAccountOpened }) =>
    (!hasMultipleAccounts || !isSwitchAccountOpened) && '64px'};
  flex-direction: ${({ hasMultipleAccounts }) => (!hasMultipleAccounts ? 'row' : 'column')};
  justify-content: space-between;
  align-items: ${({ hasMultipleAccounts }) => (!hasMultipleAccounts ? 'center' : 'flex-start')};

  background: ${(props) => props.theme.colors.greys.darkest};

  ${({ hasMultipleAccounts }) => {
    if (hasMultipleAccounts) {
      return css`
        transition-property: max-height;
        transition-duration: 100ms;
      `;
    }
  }}

  /** Keep max-height property after transition properties */
  max-height: ${({ hasMultipleAccounts, isSwitchAccountOpened }) =>
    !hasMultipleAccounts || !isSwitchAccountOpened ? '64px' : '232px'};

  ::before {
    content: '';
    position: absolute;
    left: 8px;
    right: 8px;
    bottom: ${({ hasMultipleAccounts }) => !hasMultipleAccounts && '64px'};
    border-bottom: solid 1px ${(props) => props.theme.colors.greys.darker};
  }
`;

const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const SwichContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  row-gap: 8px;
  width: 100%;
  max-height: 232px;
  overflow-y: scroll;
`;

const AccountContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 40px;
  justify-content: space-between;
  padding-bottom: ${({ isBottom }) => isBottom && '16px'};
  align-items: center;

  > a:hover {
    text-decoration: none;
  }

  /** On hover, color all div text inside this component */
  &:hover > div > div {
    color: ${({ theme }) => theme.colors.infoGreen};
  }

  :hover {
    cursor: pointer;
  }

  &:hover > img {
    -webkit-filter: url(#colorize);
    filter: url(#colorize);
  }
`;

const Icon = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;

  transition: filter 0.3s; /* Add a smooth transition effect */

  &:hover {
    -webkit-filter: url(#colorize);
    filter: url(#colorize);
  }
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  > a:hover {
    text-decoration: none;
  }
`;

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

const textWithEllipsis = { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' };

export {
  Container,
  UserContainer,
  SwichContainer,
  AccountContainer,
  NameContainer,
  Icon,
  textWithEllipsis,
  StyledLink,
};
