import { cloneDeep, find, get, isEmpty, isEqual } from 'lodash';
import { connect } from 'react-redux';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { openGenericModal, refreshGenericModal } from '@actions/modal';

import { getIngredientUnitName, handleUnitsValidity } from '@commons/utils/units';
import { translatedUnitCondensed } from '@commons/utils/translateUnit';
import WhiteCardForm from '@commons/WhiteCardForm';

import { convertSPPQuantityInMasterUnit } from '@orders/utils/computePackagingToSupplierProduct';

import {
  DropdownIcon,
  DropdownTitle,
} from '@admin/suppliers/supplierProducts/details/supplierProductInformations/components/SupplierProductDetails/styledComponents';
import { getConversionModalParams } from '@admin/suppliers/supplierProducts/details/supplierProductInformations/components/InputConversionContentModal/utils/modalConfigurations';
import { getRecipeUnit } from '@admin/products/recipes/detail/components/RecipeInfo/common/inputs';
import { UNITS_DROPDOWN_ITEMS } from '@admin/utils/DropdownItems';
import PackagingSection from '@admin/suppliers/supplierProducts/components/PackagingSection';

import {
  SUPPLIER_PRODUCT_INGREDIENTS_INPUTS,
  SUPPLIER_PRODUCT_PRICE_INPUTS,
} from '../../utils/formInputsConfigurations';

import {
  Container,
  DropdownItem,
  DropdownItemMainValue,
  DropdownItemSecondaryValue,
} from './styledComponents';

const DEFAULT_INGREDIENT_PICTURE = '/images/inpulse/ingredient-photo-placeholder.svg';

const KitchenProductSupplierProductSection = (props) => {
  const {
    productForm,
    formFields,
    supplierProduct,
    setSupplierProduct,
    ingredients,
    composition,
    storeName,
    currency,
    isSaveAlreadyTriggered,
    openGenericModal,
    refreshGenericModal,
  } = props;

  const [packagings, setPackagings] = useState(supplierProduct.packagings || []);
  const [conversions, setConversions] = useState([]);
  const [associatedIngredients, setAssociatedIngredients] = useState(ingredients || []);

  const [ingredientsInput, setIngredientsInput] = useState(
    SUPPLIER_PRODUCT_INGREDIENTS_INPUTS({
      ingredients: associatedIngredients,
      packagingUnit: packagings[0] ? packagings[0].unit : null,
      entityUnit: get(formFields, 'entity.unit'),
      entityId: get(formFields, 'entity.id'),
      conversions,
      composition,
    }),
  );
  const [priceInputs, setPriceInputs] = useState(
    SUPPLIER_PRODUCT_PRICE_INPUTS({
      storeName,
      packagings,
      currency,
    }),
  );

  useEffect(() => {
    const formattedFormFields = {
      ...cloneDeep(formFields),
      packagings,
    };

    const compositionUnit = composition ? _formatPackagingUnit(composition.unit) : null;

    const entity = productForm.getValues('entity');

    if (!isEmpty(entity) && !composition.isIngredient) {
      const isEntityUnitValid = handleUnitsValidity(
        compositionUnit,
        entity.unit,
        get(formattedFormFields, 'conversions', []),
        packagings,
      );

      if (!isEntityUnitValid) {
        productForm.setValue('entity', {});
      }
    }

    setConversions(formattedFormFields.conversions || []);
    setSupplierProduct(formattedFormFields);
  }, [formFields, packagings]);

  useEffect(() => {
    setPriceInputs(
      SUPPLIER_PRODUCT_PRICE_INPUTS({
        storeName,
        packagings,
        currency,
      }),
    );
  }, [packagings, currency]);

  useEffect(() => {
    const compositionUnit = composition ? _formatPackagingUnit(composition.unit) : null;

    setIngredientsInput(
      SUPPLIER_PRODUCT_INGREDIENTS_INPUTS({
        ingredients: getIngredientsDropdownItems(compositionUnit),
        packagingUnit: packagings[0] ? packagings[0].unit : null,
        entityUnit: get(formFields, 'entity.unit'),
        entityId: get(formFields, 'entity.id'),
        conversions,
        composition,
        handleConversionCreation,
      }),
    );
  }, [packagings, ingredients, productForm.watch('entity'), conversions]);

  const _formatPackagingUnit = (unit) => (unit !== 'unit' ? getRecipeUnit(unit) : unit);

  const handlePackagingsChange = (newPackagings) => {
    const formattedNewPackagings = newPackagings.map((packaging) => ({
      ...packaging,
      /*
        Setting isUsedInInvoice to isUsedInOrder because when updating the isUsedInOrder packaging
        it automatically becomes the isUsedInInvoice packaging in the price section. So to be consistent
        and avoid any bugs we need to do the same thing here.
      */
      isUsedInInvoice: packaging.isUsedInOrder,
    }));

    const initialUsedInOrder = find(packagings, ['isUsedInOrder', true]);
    const newUsedInOrder = find(formattedNewPackagings, ['isUsedInOrder', true]);

    const newUnit = !isEmpty(formattedNewPackagings)
      ? get(formattedNewPackagings, '[0].unit')
      : null;

    productForm.setValue('packagingUnit', newUnit);

    if (
      formFields.price &&
      initialUsedInOrder &&
      newUsedInOrder &&
      !isEqual(initialUsedInOrder, newUsedInOrder)
    ) {
      const unitPrice =
        formFields.price / convertSPPQuantityInMasterUnit(initialUsedInOrder.id, packagings);
      const convertedQuantity = convertSPPQuantityInMasterUnit(newUsedInOrder.id, packagings);

      productForm.setValue('price', unitPrice * convertedQuantity);
    }

    setPackagings(formattedNewPackagings);
    productForm.setValue('packagings', formattedNewPackagings);
  };

  const getIngredientsDropdownItems = (compositionUnit) =>
    ingredients.map((ingredient) => {
      const isUnitValid = handleUnitsValidity(
        compositionUnit,
        ingredient.unit,
        conversions,
        packagings,
      );

      return {
        ...ingredient,
        id: ingredient.id,
        tags: [getIngredientUnitName(ingredient.unit)],
        value: ingredient.name,
        isDisabled: !isUnitValid,
        renderValue: () => (
          <DropdownItem>
            <DropdownIcon src={ingredient.img || DEFAULT_INGREDIENT_PICTURE} />
            <DropdownTitle>
              <DropdownItemMainValue>{ingredient.name}</DropdownItemMainValue>
              <DropdownItemSecondaryValue isDisabled={!isUnitValid}>
                {`${ingredient.supplierProductMappingCount} ${i18next.t(
                  'ADMIN.SUPPLIER_PRODUCTS.SUPPLIER_PRODUCT_ABBREVIATION',
                )}`}
              </DropdownItemSecondaryValue>
            </DropdownTitle>
          </DropdownItem>
        ),
      };
    });

  const handleConversionsUpdate = (newConversions) => {
    const matchingUnit = UNITS_DROPDOWN_ITEMS().find(
      ({ id }) => id === productForm.getValues('packagingUnit'),
    );

    if (matchingUnit) {
      const conversionModalParams = getConversionModalParams({
        packagingUnit: matchingUnit,
        existingConversions: newConversions,
        areConversionsValid,
        handleConversionsUpdate,
        handleConversionSaving,
      });

      refreshGenericModal(conversionModalParams);
    }
  };

  const handleConversionCreation = (packagingUnit) => {
    const matchingUnit = UNITS_DROPDOWN_ITEMS().find(({ id }) => id === packagingUnit);

    if (matchingUnit) {
      const conversionModalParams = getConversionModalParams({
        packagingUnit: matchingUnit,
        existingConversions: conversions,
        areConversionsValid,
        handleConversionsUpdate,
        handleConversionSaving,
      });

      openGenericModal(conversionModalParams);
    }
  };

  const areConversionsValid = (newConversions, packagingUnit) => {
    const packagingConversion = newConversions.find(
      ({ convertedUnit }) => convertedUnit === packagingUnit.id,
    );

    if (!packagingConversion) {
      return false;
    }

    const allConversionsAreNull = newConversions.every(
      ({ convertedQuantity }) => convertedQuantity === '',
    );

    const isOneConversionEqualToZero = newConversions.some(
      ({ convertedQuantity }) => convertedQuantity === '0',
    );

    return (
      !isOneConversionEqualToZero &&
      (allConversionsAreNull || packagingConversion.convertedQuantity !== '')
    );
  };

  const handleConversionSaving = (newConversions) => {
    const hasNoQuantities = (conversions) =>
      conversions.every(({ convertedQuantity }) => convertedQuantity === '');

    const allConversionsHaveNoQuantities = hasNoQuantities(newConversions);

    const conversionOtherThanBaseUnit = newConversions.filter(({ isBaseUnit }) => !isBaseUnit);
    const onlyPackagingHaveQuantity =
      conversionOtherThanBaseUnit.length >= 1 && hasNoQuantities(conversionOtherThanBaseUnit);

    const compositionUnit = composition ? _formatPackagingUnit(composition.unit) : null;

    if (allConversionsHaveNoQuantities || onlyPackagingHaveQuantity) {
      _resetConversions();

      const ingredientsDropdownItems = getIngredientsDropdownItems(compositionUnit, conversions);

      setAssociatedIngredients(ingredientsDropdownItems);
      return;
    }

    const conversionWithValues = newConversions.filter(
      ({ convertedUnit, convertedQuantity }) => convertedUnit && convertedQuantity,
    );

    const conversionsDisplayed = _getConversionsDisplay(conversionWithValues);

    productForm.setValue('conversionsDisplayed', conversionsDisplayed);
    productForm.setValue('conversions', conversionWithValues);

    setConversions(conversionWithValues);

    const ingredientsDropdownItems = getIngredientsDropdownItems(
      compositionUnit,
      ingredients,
      newConversions,
    );

    setAssociatedIngredients(ingredientsDropdownItems);
  };

  const _getConversionsDisplay = (conversions) =>
    conversions
      .map(
        ({ convertedQuantity, convertedUnit }) =>
          `${convertedQuantity} ${translatedUnitCondensed(convertedUnit)}`,
      )
      .join(' = ');

  const _resetConversions = () => {
    productForm.setValue('conversionsDisplayed', '');
    productForm.setValue('conversions', []);
    setConversions([]);
  };

  return (
    <Container>
      <PackagingSection
        handleSupplierProductChange={handlePackagingsChange}
        hasRightToEditPackagings={true}
        inRecipe={true}
        isErrorState={isEmpty(packagings) && isSaveAlreadyTriggered}
        supplierProduct={{ ...formFields, packagings }}
      />
      <WhiteCardForm
        form={productForm}
        inputs={ingredientsInput}
        shouldDisplayError={isSaveAlreadyTriggered}
        title={i18next.t('GENERAL.ASSOCIATED_INGREDIENT')}
      />
      <WhiteCardForm
        form={productForm}
        inputs={priceInputs}
        shouldDisplayError={isSaveAlreadyTriggered}
        title={i18next.t('GENERAL.PRICE')}
      />
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => ({
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params, customPropertyPathsToUpdate = []) =>
    dispatch(refreshGenericModal(params, customPropertyPathsToUpdate)),
});

export default connect(null, mapDispatchToProps)(KitchenProductSupplierProductSection);
