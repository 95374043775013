import i18next from 'i18next';
import React from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';

import { INVOICE_STATUS } from '../../commons/constants';
import { userHasRoleInpulse } from '@commons/utils/roles';

export const getListActions = ({
  user,
  selectedItems,
  handleExport,
  handleDeleteById,
  goToBatchImport,
  handleOcerizationExport,
  handleICLsExportAnalysisByICLIds,
  hideExportAnalysis,
  handleResetOrderInvoiceAssociation,
}) => {
  const isInpulseUser = userHasRoleInpulse(user);
  return [
    {
      id: 'create',
      actionLabel: i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_ACTION_NEW_CONTROL'),
      fixed: true,
      handleAction: () => {},
      render: () => (
        <Button
          color={'inpulse-default'}
          handleClick={goToBatchImport}
          icon="/images/inpulse/add-white-small.svg"
          label={i18next.t('INVOICE.INVOICE_CONTROLS.INVOICE_CONTROLS_ACTION_NEW_CONTROL')}
        />
      ),
    },
    {
      id: 'reset-order-invoice-association',
      actionLabel: i18next.t('INVOICE.INVOICE_CONTROLS.ACTION_RELAUNCH_ORDER_INVOICE_ASSOCIATION'),
      handleAction: () => handleResetOrderInvoiceAssociation(selectedItems[0]),
      actionIcon: () => '/images/inpulse/sync-black-small.svg',
      isHidden: () => selectedItems.length !== 1,
    },
    {
      id: 'export-selection',
      actionLabel: i18next.t(
        !selectedItems.length ? 'GENERAL.EXPORT_LIST' : 'GENERAL.EXPORT_SELECTION',
      ),
      handleAction: () => handleExport(selectedItems),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
    },
    {
      id: 'export-invoices-detail',
      actionLabel: i18next.t('INVOICE.INVOICE_CONTROLS.ACTION_EXPORT_GAP_ANALYSIS'),
      handleAction: handleICLsExportAnalysisByICLIds,
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
      isHidden: () => hideExportAnalysis,
    },
    {
      id: 'export-invoices-ocerization',
      actionLabel: i18next.t('INVOICE.INVOICE_CONTROLS.ACTION_EXPORT_OCERIZATION', {
        count: selectedItems.length,
      }),
      handleAction: handleOcerizationExport,
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
      isHidden: () => selectedItems.some(({ status }) => status === INVOICE_STATUS.FAILURE),
    },
    {
      id: 'delete',
      actionLabel: () => i18next.t('INVOICE.INVOICE_CONTROLS.ACTION_DELETE_INVOICE'),
      handleAction: () => handleDeleteById(selectedItems[0].id),
      actionIcon: () => '/images/inpulse/delete-black-small.svg',
      isHidden: () =>
        selectedItems.length !== 1 ||
        (selectedItems[0].status !== INVOICE_STATUS.FAILURE && !isInpulseUser),
    },
  ];
};

export const getRowActions = ({
  user,
  handleDeleteById,
  handleOpenInvoice,
  handleExportAnalysisById,
  handleInvoiceOcerizationExport,
  handleResetOrderInvoiceAssociation,
}) => {
  const isInpulseUser = userHasRoleInpulse(user);

  return [
    {
      id: 'open-invoice',
      actionLabel: () => i18next.t('INVOICE.INVOICE_CONTROLS.OPEN_INVOICE'),
      handleAction: handleOpenInvoice,
      actionIcon: () => '/images/inpulse/open-a-new-black-small.svg',
    },
    {
      id: 'reset-order-invoice-association',
      actionLabel: () =>
        i18next.t('INVOICE.INVOICE_CONTROLS.ACTION_RELAUNCH_ORDER_INVOICE_ASSOCIATION'),
      handleAction: handleResetOrderInvoiceAssociation,
      actionIcon: () => '/images/inpulse/sync-black-small.svg',
    },
    {
      id: 'export-invoice-detail',
      actionLabel: () => i18next.t('INVOICE.INVOICE_CONTROLS.ACTION_EXPORT_GAP_ANALYSIS'),
      handleAction: ({ id }) => handleExportAnalysisById(id),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
      isHidden: ({ orders }) => !orders.length,
    },
    {
      id: 'export-invoice-ocerization',
      actionLabel: () =>
        i18next.t('INVOICE.INVOICE_CONTROLS.ACTION_EXPORT_OCERIZATION', {
          count: 1,
        }),
      handleAction: ({ id }) => handleInvoiceOcerizationExport(id),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
      isHidden: ({ status }) => status !== INVOICE_STATUS.SUCCESS,
    },
    {
      id: 'delete',
      actionLabel: () => i18next.t('INVOICE.INVOICE_CONTROLS.ACTION_DELETE_INVOICE'),
      handleAction: ({ id }) => handleDeleteById(id),
      actionIcon: () => '/images/inpulse/delete-black-small.svg',
      isHidden: ({ status }) => status !== INVOICE_STATUS.FAILURE && !isInpulseUser,
    },
  ];
};
