import i18next from 'i18next';

import { Invoice } from '../salesPointNavigation/subNavigation';

const INVOICE_CONTROLS = {
  getText: () => i18next.t('FEATURE.INVOICE.INVOICES_CONTROLS'),
  path: '/invoice/invoice-controls',
  customRender: ({ invoiceControlList }) => ({
    linkedText: invoiceControlList ? i18next.t('FEATURE.INVOICE.INVOICE_CONTROL_DETAILS') : '',
    extraText: getExtraTextForInvoiceSubFeature(invoiceControlList),
  }),
  subFeatures: [Invoice.DETAILS, Invoice.LINE_BY_LINE, Invoice.BATCH_IMPORT],
};

const INTERNAL_INVOICES = {
  getText: () => i18next.t('FEATURE.INVOICE.INTERNAL_INVOICES'),
  path: '/invoice/internal-invoices',
  customRender: ({ internalInvoice: { invoiceNumber } }) => ({
    linkedText: i18next.t('GENERAL.INVOICE'),
    extraText: `/ ${invoiceNumber || ''}`,
  }),
  subFeatures: [Invoice.INTERNAL_INVOICE_CREATE, Invoice.INTERNAL_INVOICE_DETAILS],
};

const getExtraTextForInvoiceSubFeature = (invoiceControlList) => {
  if (invoiceControlList && invoiceControlList.supplier) {
    return `/ ${invoiceControlList.supplier.name} n°${invoiceControlList.invoiceNumber || '-'}`;
  }

  return `/ ${i18next.t('FEATURE.INVOICE.INVOICE_CONTROL_BATCH_IMPORT')}`;
};

const INVOICE_SUBMODULE = {
  isFullscreen: true,
  getText: () => i18next.t('FEATURE.INVOICE.INVOICES'),
  icon: '/images/invoice.svg',
  children: [INVOICE_CONTROLS, INTERNAL_INVOICES],
};

export const INVOICE = {
  getText: () => i18next.t('FEATURE.INVOICE.INVOICES'),
  path: '/invoice',
  icon: '/images/invoice.svg',
  children: [INVOICE_SUBMODULE],
};
