import axios from '../core/http';

import { APIcaller } from './APIcaller';
import { config } from '../config';

const baseApiUrl = `${config.baseApiUrl}/v1`;

function getStock(storeIds, startDate, endDate) {
  return axios
    .post(
      baseApiUrl + '/stocks/',
      {
        storeIds: storeIds,
        startDate: startDate,
        endDate: endDate,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

function getStockGraph(storeId, entityId, date, endDate = null) {
  const url = `${baseApiUrl}/stocks/stores/${storeId}/ingredients/${entityId}?date=${date}`;
  return axios
    .get(`${url}${!!endDate ? `&end=${endDate}` : ''}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

const getPastStocksOfIngredientsByCategories = (storeId, date, categoryIds, isStrategic) =>
  APIcaller.apiPost('/stocks/analytics/past', {
    date,
    storeId,
    categoryIds,
    isStrategic,
  });

const getPastStocksDates = (storeId, startDate, endDate) =>
  APIcaller.apiPost('/stocks/analytics/past/dates', {
    storeId,
    startDate,
    endDate,
  });

export const stock = {
  getStock,
  getStockGraph,
  getPastStocksOfIngredientsByCategories,
  getPastStocksDates,
};
