import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 88px;
  position: relative;

  .deepsight-item-list-bottom-navigation-bar {
    width: 100%;
    margin-left: 0px;
  }
`;

export const Text = styled.div`
  font: ${(props) => props.theme.fonts?.textBig};
  color: ${(props) => props.theme.colors?.greys?.darkest};

  ${(props) => {
    if (props.highlighted) {
      return css`
        font: ${(props) => props.theme.fonts?.textMiddleBold};
        color: ${(props) => props.theme.colors?.greys?.darkest};
      `;
    }
  }}
`;

export const LabelContainer = styled.div`
  height: 100%;
  min-width: 116px;

  display: flex;
  align-items: center;

  padding: 4px 0px;
  margin-left: 2px;
`;
