export const getCustomFieldPrompt = (fields = {}) => {
  const {
    name,
    code,
    unitPrice,
    invoiceNumber,
    excludingTaxesInvoiceTotal,
    invoiceDate,
    deliveryDate,
    deliveryReference,
    orderReference,
    quantity,
    packaging,
  } = fields;

  return `
    You will receive an invoice file sent by a supplier to a restaurant in french. This invoice can be linked to many different orders.

    ### Instructions ###
        1. Identify for the invoice:
           - invoice_number${(invoiceNumber || '').trim() ? `: ${invoiceNumber}` : ''}
           - excluding_taxes_invoice_total${
             (excludingTaxesInvoiceTotal || '').trim() ? `: ${excludingTaxesInvoiceTotal}` : ''
           }
           - invoice_date${(invoiceDate || '').trim() ? `: ${invoiceDate}` : ''}

        2. For each product line, identify:
           - delivery_date${(deliveryDate || '').trim() ? `: ${deliveryDate}` : ''}
           - delivery_reference${(deliveryReference || '').trim() ? `: ${deliveryReference}` : ''}
           - order_reference${(orderReference || '').trim() ? `: ${orderReference}` : ''}
           - code${(code || '').trim() ? `: ${code}` : ''}
           - name${(name || '').trim() ? `: ${name}` : ''}
           - quantity${(quantity || '').trim() ? `: ${quantity}` : ''}
           - unit_price${(unitPrice || '').trim() ? `: ${unitPrice}` : ''}
           - packaging${(packaging || '').trim() ? `: ${packaging}` : ''}

        3. Reorganize this information in a table respecting the order and names of the following columns:
        invoice_number | excluding_taxes_invoice_total | invoice_date | delivery_date | order_reference | delivery_reference | code | name | quantity | unit_price | packaging |

        Rules to follow : 
        - Respect the data types of each column: invoice_number (str), excluding_taxes_invoice_total (float), invoice_date (date), delivery_date (date), order_reference (str), delivery_reference (str), code (str), name (str), quantity (float), unit_price (float), packaging (str).
        - If one of the field is not indicated in the input file, leave the cells empty.
        - Information like invoice_number, excluding_taxes_invoice_total and invoice_date must appear at each line of the outputtable.
        - unit_price must be a float so one don't want the currency, for instance one want 78,12 and not 78,12 EUR
        - "BL" means "delivery order" in French, so if you don't find the delivery date, take BL date.
        - order_reference is not present on each invoice so if you don't find it, leave empty the cell. If an item on the invoice begins with "CMD-", it is neccesary the order reference.
        - the date format is DD/MM/YYYY
        - only include markdown table in the output, and leave out any additional comment
        - delivery_reference is associated to "BL" or "Bon de livraison" in French 
        ${
          !(code || '').trim()
            ? `- the code of the product can be referred to as "SKU", "Code article" or anything similar"`
            : ''
        }
        ${
          !(unitPrice || '').trim()
            ? `- unit_price can be referred to as "PU HT", "Prix unitaire" or anything similar"`
            : ''
        }
        ${
          !(name || '').trim()
            ? `- the name of the product can be referred to as "Nom d'article", "Description", "Libellé" or anything similar`
            : ''
        }
    `;
};
