import { APIcaller } from './APIcaller';

const getInternalInvoicesList = ({ issuerStoreId, billedStoreIds, startDate, endDate }) =>
  APIcaller.apiPost(`/internal-invoice/list`, {
    issuerStoreId,
    billedStoreIds,
    startDate,
    endDate,
  });

const getNewInvoiceNumber = () => APIcaller.apiGet(`/internal-invoice/invoice-number`);

const getInternalInvoice = (id) => APIcaller.apiGet(`/internal-invoice/${id}`);

const getAvailableOrdersToAssociate = (storeId, supplierId, starDate, endDate) =>
  APIcaller.apiGet(
    `/internal-invoice/available-orders?storeId=${storeId}&supplierId=${supplierId}&startDate=${starDate}&endDate=${endDate}`,
  );

const generateInternalInvoicePreview = (orderIds, intraCommunitySupply) =>
  APIcaller.apiPost(`/internal-invoice/generate-internal-invoice-preview`, {
    orderIds,
    intraCommunitySupply,
  });

const sendInvoices = (internalInvoice) =>
  APIcaller.apiPost(`/internal-invoice/send-invoices`, internalInvoice);

export default {
  getNewInvoiceNumber,
  getAvailableOrdersToAssociate,
  getInternalInvoicesList,
  getInternalInvoice,
  generateInternalInvoicePreview,
  sendInvoices,
};
