import styled, { css, keyframes } from 'styled-components';

const gradient = keyframes`
  0% { opacity: 0.4; }
  50% { opacity: 1; }
  100% { opacity: 0.4; }
`;

export const PlainText = styled.div`
  height: 40px;

  display: flex;

  align-items: center;
  color: ${(props) => props.theme.colors.greys.darkest};
  font: ${(props) => props.theme.fonts.textMiddleNormal};

  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `};
`;

export const CustomRenderContainer = styled.div`
  height: 40px;

  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `};
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 8px;
  position: relative;

  > div {
    justify-content: center;
  }
`;

export const ToggleContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;

  align-items: center;

  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 8px;
`;

export const LoadingState = styled.div`
  width: ${(props) => props.width};
  height: 24px;

  border-radius: ${(props) => props.theme.borders.radius.weak};

  background: linear-gradient(
    90deg,
    ${(props) => props.theme.colors.greys.light} 0%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: ${gradient} 1.5s linear infinite;
`;

export const ErrorText = styled.div`
  color: ${(props) => props.theme.colors.infoRed};
  font: ${(props) => props.theme.fonts.textSmallHeight14Weight600};

  width: ${(props) => props.width};
`;

export const WildCard = styled.span`
  color: ${(props) => props.theme.colors.infoRed};
`;

export const TooltipContainer = styled.div`
  margin-top: -1px;
`;

export const LabelContainer = styled.div`
  font: ${(props) => props.theme.fonts.textSmall};

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  ${(props) =>
    props.disabled &&
    css`
      color: ${props.theme.colors.greys.dark};
    `};
`;

export const DatePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  row-gap: 6px;
`;

export const InputActionButton = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 26px;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  width: ${(props) => props.width};
`;

// Attachment dropdown components
export const AttachmentDropdownContainer = styled.div`
  z-index: 100;
  margin-top: 74px;
  position: absolute;

  cursor: default;

  width: 240px;
  height: auto;

  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.greys.lightest};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
`;

export const AttachmentDropdownRown = styled.div`
  width: 100%;
  height: 40px;

  #${(props) => props.inputId} {
    display: none;
  }
`;

export const AttachmentDropdownContent = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const AttachmentDropdownItem = styled.div`
  width: 100%;

  height: 40px;

  padding: 0px 8px;

  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors.greys.lighter};

    &:first-child {
      border-radius: 8px 8px 0px 0px;
    }

    &:last-child {
      border-radius: 0px 0px 8px 8px;
    }
  }
`;

export const IconContainer = styled.img`
  width: 16px;
  height: 16px;

  justify-content: center;
  align-items: center;
`;
