import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { openGenericModal, openMediumModal, refreshGenericModal } from '@actions/modal';
import { showConfirmationMessage } from '@actions/messageconfirmation';

import { fetchUserRights } from '@commons/utils/features';
import { ListView, Button } from '@commons/utils/styledLibraryComponents';
import {
  PATHS_AVAILABLE_IN_ALL_WORKSPACES,
  HELP_CENTER_FEATURE_NAME,
  REFERRAL_FEATURE_NAME,
} from '@commons/constants/centralConstants';
import EmptyState from '@commons/EmptyState';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';
import sortActionsList from '@commons/utils/sortActionsList';

import { getClientInfo } from '@selectors/client';

import clientService from '@services/client';
import onBoardingService from '@services/backoffice/onBoarding';

import theme from '@theme';

import { Container } from './styledComponents';
import { getSchemaByPath, SCHEMA_FEATURES_PROPS_VALIDATION } from './constants/validators';
import AddFeatureModal from './components/AddFeatureModal';
import modalUtils from './utils/modal';

export const BackOfficeFeaturesAssociation = (props) => {
  const {
    user,
    userRights,
    match,
    client: { hasCentralKitchens },
  } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [features, setFeatures] = useState([]);
  const [markerConfiguration] = useState({
    isHidden: (feature) => {
      const schema = getSchemaByPath(feature.path);

      return isEmpty(schema);
    },
    backgroundColor: theme.colors.greys.darkest,
    icon: {
      src: '/images/inpulse/gear-white-small.svg',
    },
  });

  const path = get(match, 'path');
  const userLanguageCode = get(user, 'lnkLanguageAccountrel.code', 'fr');

  /*****************************/
  /** List view configuration **/
  /*****************************/

  const dataColumns = [
    {
      id: 'name',
      name: i18next.t('GENERAL.NAME'),
      propertyKey: 'name',
      baseName: 'name',
    },
    {
      id: 'path',
      name: i18next.t('GENERAL.PATH'),
      propertyKey: 'path',
      baseName: 'path',
      displayBigger: true,
    },
    {
      id: 'isCentral',
      name: i18next.t('GENERAL.WORKSPACE'),
      propertyKey: 'isCentral',
      baseName: 'isCentral',
      hidden: !hasCentralKitchens,
      render: (isCentral, feature) => {
        const featurePathMainModule = get(feature.path.split('/'), '[1]', ''); // We take the first element because [0] is equal to '/'

        if (
          PATHS_AVAILABLE_IN_ALL_WORKSPACES.includes(featurePathMainModule) ||
          feature.name === HELP_CENTER_FEATURE_NAME ||
          feature.name === REFERRAL_FEATURE_NAME
        ) {
          return i18next.t('GENERAL.COMMON');
        }

        return isCentral ? i18next.t('GENERAL.CENTRAL') : i18next.t('GENERAL.SALE_POINT');
      },
    },
  ];

  const rowActions = [
    {
      id: 'action-update-props',
      isDisabled: (item) => !SCHEMA_FEATURES_PROPS_VALIDATION[item.path],
      actionLabel: () => i18next.t('BACKOFFICE.FEATURES.HANDLE_PROPS_ACTION'),
      handleAction: (item) => displayModalPropsEdition(item),
      actionIcon: (item) =>
        !SCHEMA_FEATURES_PROPS_VALIDATION[item.path]
          ? '/images/inpulse/gear-lmgrey-small.svg'
          : '/images/inpulse/gear-black-small.svg',
    },
    {
      id: 'delete',
      actionLabel: () => i18next.t('BACKOFFICE.FEATURES.DELETE_FEATURE_ACTION'),
      handleAction: (item) => displayModalDeleteFeature(item),
      actionIcon: () => '/images/inpulse/trash-black-small.svg',
    },
  ];

  const getActions = () => {
    const params = {
      component: AddFeatureModal,
      dataColumns,
      loadFeatures: async () => {
        await loadFeatures();

        props.getCurrentUserRights(user.id, user.hasForecasts);
      },
      userLanguageCode,
      clientId: get(user, 'clientId'),
      mappedFeatures: features,
      showMessage: props.showMessage,
    };

    const actions = [
      {
        id: 'add',
        fixed: true,
        handleAction: () => props.openModal(params),
        actionLabel: i18next.t('GENERAL.ADD'),
        render: () => (
          <Button
            color={'inpulse-default'}
            handleClick={() => props.openModal(params)}
            icon={'/images/inpulse/add-white-small.svg'}
            label={i18next.t('GENERAL.ADD')}
          />
        ),
      },
    ];

    return actions;
  };

  /******************************/
  /** Feature deletion methods **/
  /******************************/

  const displayModalDeleteFeature = (feature) => {
    const params = modalUtils.getDeleteFeatureModalConfig({
      feature,
      handleDeleteAssociatedFeatureById,
    });

    props.openGenericModal(params);
  };

  const handleDeleteAssociatedFeatureById = async (featureId) => {
    props.pageLoading();

    try {
      await onBoardingService.deleteFeatureClientMappingByClientIdAndFeatureId(
        get(user, 'clientId'),
        featureId,
      );

      props.showMessage(i18next.t('BACKOFFICE.FEATURES.DELETE_FEATURE_SUCCESS'));

      await loadFeatures();

      props.getCurrentUserRights(user.id, user.hasForecasts);
    } catch (err) {
      props.showMessage(i18next.t('BACKOFFICE.FEATURES.DELETE_FEATURE_FAILURE'), 'error');
    } finally {
      props.pageLoaded();
    }
  };

  /*********************/
  /** Loading methods **/
  /*********************/

  const loadFeatures = async () => {
    setIsLoading(true);
    try {
      const features = await onBoardingService.getFeaturesOfClient();

      setFeatures(features);
    } catch (err) {
      props.showMessage(i18next.t('BACKOFFICE.FEATURES.FETCH_FEATURES_FAILURE'), 'error');
      setFeatures([]);
    }
    setIsLoading(false);
  };

  /**********************************/
  /** Feature props update methods **/
  /**********************************/

  const handleSavePropsFeature = async (feature) => {
    props.pageLoading();

    try {
      await clientService.patchPropsFeatureById(get(user, 'clientId'), feature.id, feature.props);

      props.showMessage(i18next.t('BACKOFFICE.FEATURES.UPDATE_PROPS_SUCCESS'));

      await loadFeatures();

      props.getCurrentUserRights(user.id, user.hasForecasts);
    } catch (err) {
      props.showMessage(i18next.t('BACKOFFICE.FEATURES.UPDATE_PROPS_FAILURE'), 'error');
    } finally {
      props.pageLoaded();
    }
  };

  const onPropsChange = (feature, value) => {
    const schema = getSchemaByPath(feature.path);

    const params = modalUtils.getPropsEditorModalConfig({
      feature: { ...feature, props: JSON.stringify(value) },
      props: value,
      schema,
      onPropsChange,
      handleSavePropsFeature,
    });

    props.refreshGenericModal(params);
  };

  const displayModalPropsEdition = (feature) => {
    const parsedProps = JSON.parse(feature.props);

    const schema = getSchemaByPath(feature.path);

    const params = modalUtils.getPropsEditorModalConfig({
      feature,
      props: parsedProps,
      schema,
      onPropsChange,
      handleSavePropsFeature,
    });

    props.openGenericModal(params);
  };

  useEffect(() => {
    (async function loadData() {
      await loadFeatures();
    })();
  }, [userRights]);

  return (
    <Container>
      <NavigationBreadCrumb featurePath={path} />
      <ListView
        actions={getActions()}
        columns={dataColumns}
        data={features}
        defaultOrderBy={'name'}
        defaultOrderType={'asc'}
        disableSelection={true}
        isLoading={isLoading}
        languageCode={userLanguageCode}
        markerConfiguration={markerConfiguration}
        padding={'24px 24px 0px 24px'}
        placeholderShape={i18next.t('GENERAL.SEARCH')}
        renderEmptyState={() => <EmptyState />}
        rowActions={sortActionsList(rowActions)}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  userRights: state.baseReducer.userRights,
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
  openModal: (params) => {
    dispatch(openMediumModal(params));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  getCurrentUserRights: (userId, hasForecasts) => {
    dispatch(fetchUserRights(userId, hasForecasts));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeFeaturesAssociation);
