import { connect } from 'react-redux';
import React, { useState } from 'react';

import { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';
import { getIsCentralMode } from '@commons/utils/localStorage';
import DisplayImgInFullScreen from '@commons/DisplayImgInFullScreen';
import DisplayNumber from '@commons/DisplayNumber';

import { getResetStockEveryDayForCentralKitchen } from '@selectors/featureProps';

import { getColumnsConfig } from '../../utils/getColumnsConfig';

import ColumnHeader from '../OrderColumnHeader/ColumnHeader';
import ProductOrdersContainer from '../ProductOrdersContainer';

import {
  BodyContainer,
  BorderBottomCategory,
  Category,
  CategoryHeaderContainer,
  Container,
  LeftPartHeader,
  RightPartHeader,
} from './styledComponent';

export const OrderFormCategory = (props) => {
  const {
    user,
    category,
    orderStatus,
    preparationStatus,
    isEditable,
    canStillOrder,
    handleReceivedDiff,
    handleInvoicedDiff,
    openStockInfo,
    setOpenStockInfo,
    indexCategory,
    prefillInpulse,
    productOrders,
    handleSetProductsOrder,
    handleUpdateCategory,
    startDate,
    endDate,
    storeId,
    deleteProductOrder,
    isCategoryOpen,
    isEditingPreparationOrder,
    invoicedDiffOfProductOrder,
    sumUpRecommendations,
    handleOpenCategory,
    storeInfos,
    currency,
    storeTimezone,
    receivedDiffOfProductOrder,
    selectedStore,
    supplierInfos,
    hasBLI,
    resetStockEveryDayForCentralKitchen,
    allCreditPicturesHandlers,
    shouldDisplayMaximumQty,
  } = props;

  const [displayProductPicture, setDisplayProductPicture] = useState({ state: false, img: '' });

  const columnsConfig = getColumnsConfig({
    orderStatus,
    isEditable,
    isEditingPreparationOrder,
    hasForecasts: user.hasForecasts,
    isCentralMode: getIsCentralMode() || selectedStore.isKitchen,
    currency,
    storeTimezone,
    // For some customers (Krispy Kreme), when ordering from a Central, they don't want to use the order recos but the theoretical consumption
    // (as the DLC is 1 day, they never have any stock).
    shouldUseConsumption: resetStockEveryDayForCentralKitchen && supplierInfos.isKitchen,
    shouldDisplayMaximumQty,
  });

  const getCategoryTotal = (productsOrder) => {
    let total = 0;
    if (!productsOrder) {
      return 0;
    }

    productsOrder.forEach((productGrouped) => {
      productGrouped.forEach((product) => {
        total += product.price * product.ordered;
      });
    });

    return total;
  };

  const updateProduct = (productId, type, value, category) => {
    const mappedProductsInventory = productOrders.map((productGrouped) =>
      productGrouped.map((product) => {
        if (product.id === productId) {
          product[type] = value;
        }
        return product;
      }),
    );

    handleSetProductsOrder(category.name, mappedProductsInventory);
    handleUpdateCategory(category.name, mappedProductsInventory);
  };

  const handleClick = () => {
    handleOpenCategory(category.name);
  };

  return (
    <>
      {!!displayProductPicture.state && (
        <DisplayImgInFullScreen
          image={displayProductPicture.img}
          setDisplayProductPicture={setDisplayProductPicture}
        />
      )}
      <Container key={category.name}>
        <CategoryHeaderContainer onClick={handleClick}>
          <LeftPartHeader>
            <div>
              <img
                alt="arrow-icon"
                src={
                  !!isCategoryOpen
                    ? '/images/inpulse/carret-bottom-black.svg'
                    : '/images/inpulse/carret-right-black.svg'
                }
              />
            </div>
            <Category>{category.name}</Category>
          </LeftPartHeader>

          <RightPartHeader>
            <DisplayNumber
              color={ENUM_COLORS.DARKEST}
              currency={currency}
              displayCurrencyCode={true}
              font={ENUM_FONTS.H2_INTER}
              number={getCategoryTotal(productOrders)}
            />
          </RightPartHeader>
        </CategoryHeaderContainer>
        {isCategoryOpen && (
          <BodyContainer>
            <ColumnHeader columns={columnsConfig} orderStatus={orderStatus} />
            {productOrders &&
              productOrders.map((products, index) => {
                const uniqKey = `${category.name}-${index}`;
                if (products.every((product) => product.isHidden)) {
                  return;
                }

                return (
                  <ProductOrdersContainer
                    allCreditPicturesHandlers={allCreditPicturesHandlers}
                    canStillOrder={canStillOrder}
                    category={category}
                    categoryProducts={category.products}
                    columns={columnsConfig}
                    currency={currency}
                    deleteProductOrder={deleteProductOrder}
                    endDate={endDate}
                    handleInvoicedDiff={handleInvoicedDiff}
                    handleReceivedDiff={handleReceivedDiff}
                    hasBLI={hasBLI}
                    indexCategory={indexCategory}
                    indexEntity={index}
                    invoicedDiffOfProductOrder={invoicedDiffOfProductOrder}
                    isEditable={isEditable}
                    isEditingPreparationOrder={isEditingPreparationOrder}
                    key={uniqKey}
                    openStockInfo={openStockInfo}
                    orderStatus={orderStatus}
                    prefillInpulse={prefillInpulse}
                    preparationStatus={preparationStatus}
                    products={products}
                    receivedDiffOfProductOrder={receivedDiffOfProductOrder}
                    selectedStore={selectedStore}
                    setDisplayProductPicture={setDisplayProductPicture}
                    setOpenStockInfo={setOpenStockInfo}
                    shouldDisplayMaximumQty={shouldDisplayMaximumQty}
                    startDate={startDate}
                    storeId={storeId}
                    storeInfos={storeInfos}
                    sumUpRecommendations={sumUpRecommendations}
                    supplierInfos={supplierInfos}
                    updateProduct={updateProduct}
                    user={user}
                  />
                );
              })}
          </BodyContainer>
        )}
        <BorderBottomCategory isOpen={isCategoryOpen} />
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  resetStockEveryDayForCentralKitchen: getResetStockEveryDayForCentralKitchen(
    state.baseReducer.userRights,
  ),
});

export default connect(mapStateToProps)(OrderFormCategory);
