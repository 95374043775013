import * as yup from 'yup';
import { sortBy } from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { Button, INPUT_WIDTH } from '@commons/utils/styledLibraryComponents';
import { INPUT_TYPES } from '@commons/constants/inputType';
import { PAYMENT_TERM_IN_DAYS_DROPDOWN_VALUES } from '@commons/constants/dropdown';
import RemainingControls from '@commons/RemainingControls';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

import { Input } from '@lib/inpulse/Input';
import InputEmailChip from '@lib/inpulse/InputEmailChip';

import {
  DAYS,
  ITEM_DROPDOWN_NONE_VALUE,
  STOCK_LOSS_CONVENTION_DROPDOWN_ITEMS,
} from '@admin/utils/DropdownItems';

import {
  CountsTextContainer,
  InputContainer,
  LibeoInputContainer,
  LinkContainer,
  LinkImage,
  TextContainer,
} from './styledComponents';

export const STORE_INPUTS = {
  // Informations
  NAME: 'name',
  PARTNER_ID: 'partnerId',
  TELEPHONE: 'telephone',
  BRAND: 'brand',
  GROUPS: 'groups',
  RETAILER: 'retailer',
  CLOSING_DAYS: 'closingDays',
  // Production planning
  CONSUMPTION_COMPUTATION: 'consumptionComputation',
  SHOW_YESTERDAY_STOCK: 'showYesterdayStock',
  // Location
  ADDRESS: 'adress',
  POST_CODE: 'postCode',
  CITY: 'city',
  COUNTRY: 'country',
  TIMEZONE: 'timezone',
  ADDITIONNAL_ADDRESS: 'additionnalAddress',
  // Company
  COMPANY_NAME: 'companyName',
  SIRET: 'siret',
  VAT_NUMBER: 'vatNumber',
  COMPANY_TYPE: 'companyType',
  CAPITAL: 'capital',
  COMPANY_REGISTER: 'companyRegister',
  PAYMENT_TERM_IN_DAYS: 'paymentTermInDays',
  COMPANY_EMAIL: 'email',
  COMPANY_CC: 'cc',
  // Invoice control
  PLAN: 'invoiceControlPlanId',
  // Production
  PRODUCTION_LOSS_CONVENTION: 'productionLossConvention',
  PRODUCTION_STOCK_CONVENTION: 'productionStockConvention',
  // Libeo
  LIBEO_API_KEY: 'libeoApiKey',
  // Delivery
  SUPPLIER: 'supplier',
  TYPE: 'type',
  SUPPLIER_ID: 'supplierId',
  COUNTS: 'counts',
};

export const STORE_INFORMATIONS_INPUTS = ({
  brands = [],
  retailers = [],
  clientGroups = [],
  handleGroupsCreation,
  hasMultipleBrands,
}) => {
  const inputs = [
    {
      type: INPUT_TYPES.TEXT,
      name: STORE_INPUTS.NAME,
      label: `* ${i18next.t('GENERAL.NAME')}`,
      width: INPUT_WIDTH.LARGE,
      isRequired: true,
      isDisabled: () => true,
    },
    {
      type: INPUT_TYPES.TEXT,
      name: STORE_INPUTS.PARTNER_ID,
      label: `* ${i18next.t('GENERAL.ID')}`,
      width: INPUT_WIDTH.LARGE,
      isRequired: true,
      isDisabled: () => true,
    },
    {
      type: INPUT_TYPES.TEXT,
      name: STORE_INPUTS.TELEPHONE,
      label: i18next.t('GENERAL.PHONE'),
      width: INPUT_WIDTH.LARGE,
      isRequired: false,
      rule: yup.string().nullable(),
    },
    {
      type: INPUT_TYPES.SINGLE_SELECT,
      name: STORE_INPUTS.BRAND,
      label: i18next.t('GENERAL.BRAND'),
      width: INPUT_WIDTH.LARGE,
      iconSrc: '/images/inpulse/brand-black-small.svg',
      isRequired: false,
      items: brands,
    },
    {
      type: INPUT_TYPES.MULTI_SELECT,
      name: STORE_INPUTS.GROUPS,
      label: `${i18next.t('GENERAL.GROUPS')} :`,
      width: INPUT_WIDTH.LARGE,
      iconSrc: '/images/inpulse/group-black-small.svg',
      isRequired: false,
      items: clientGroups,
      button: {
        id: 1,
        text: i18next.t('ADMIN.STORES.MANAGE_GROUPS_CREATE_LABEL'),
        handleClick: handleGroupsCreation,
        iconSrc: '/images/inpulse/add-black-small.svg',
      },
    },
    {
      type: INPUT_TYPES.SINGLE_SELECT,
      name: STORE_INPUTS.RETAILER,
      label: i18next.t('GENERAL.RETAILER'),
      width: INPUT_WIDTH.LARGE,
      iconSrc: '/images/inpulse/retailer-black-small.svg',
      isRequired: false,
      items: retailers,
    },
    {
      type: INPUT_TYPES.MULTI_SELECT,
      name: STORE_INPUTS.CLOSING_DAYS,
      label: i18next.t('GENERAL.CLOSING_DAYS'),
      width: INPUT_WIDTH.LARGE,
      iconSrc: '/images/inpulse/closingdays-black-small.svg',
      isRequired: false,
      items: DAYS(),
      sortBy: (items) => sortBy(items, 'id'),
      rule: yup.array().nullable(),
    },
  ];

  if (!hasMultipleBrands) {
    return inputs.filter(({ name }) => name !== STORE_INPUTS.BRAND);
  }

  if (!retailers.length) {
    return inputs.filter(({ name }) => name !== STORE_INPUTS.RETAILER);
  }

  return inputs;
};

export const CENTRAL_KITCHEN_PRODUCTION_PLANNING_INPUTS = () => [
  {
    type: INPUT_TYPES.SINGLE_SELECT,
    name: STORE_INPUTS.CONSUMPTION_COMPUTATION,
    label: i18next.t('BACKOFFICE.STORES.CONSUMPTION_CALCULATION'),
    width: INPUT_WIDTH.LARGE,
    isRequired: true,
    tooltipText: i18next.t('BACKOFFICE.STORES.CONSUMPTION_CALCULATION_TOOLTIP'),
    isDisabled: () => true,
  },
  {
    type: INPUT_TYPES.TOGGLE,
    name: STORE_INPUTS.SHOW_YESTERDAY_STOCK,
    label: i18next.t('BACKOFFICE.STORES.YESTERDAY_STOCK_DISPLAYED'),
    width: INPUT_WIDTH.LARGE,
    tooltipText: i18next.t('BACKOFFICE.STORES.YESTERDAY_STOCK_DISPLAYED_TOOLTIP'),
    parentContainerStyle: { height: '68px' },
  },
];

export const STORE_LOCATION_INPUTS = () => [
  {
    type: INPUT_TYPES.TEXT,
    name: STORE_INPUTS.ADDRESS,
    label: `* ${i18next.t('BACKOFFICE.STORES.ADDRESS')}`,
    width: INPUT_WIDTH.LARGE,
    isRequired: true,
    isDisabled: () => true,
  },
  {
    type: INPUT_TYPES.TEXT,
    name: STORE_INPUTS.POST_CODE,
    label: `* ${i18next.t('BACKOFFICE.STORES.POSTAL_CODE')}`,
    width: INPUT_WIDTH.LARGE,
    isRequired: true,
    isDisabled: () => true,
  },
  {
    type: INPUT_TYPES.TEXT,
    name: STORE_INPUTS.CITY,
    label: `* ${i18next.t('GENERAL.CITY')}`,
    width: INPUT_WIDTH.LARGE,
    isRequired: true,
    isDisabled: () => true,
  },
  {
    type: INPUT_TYPES.TEXT,
    name: STORE_INPUTS.COUNTRY,
    label: `* ${i18next.t('GENERAL.COUNTRY')}`,
    width: INPUT_WIDTH.LARGE,
    isRequired: true,
    isDisabled: () => true,
  },
  {
    type: INPUT_TYPES.TEXT,
    name: STORE_INPUTS.TIMEZONE,
    label: i18next.t('GENERAL.TIMEZONE'),
    width: INPUT_WIDTH.LARGE,
    isRequired: false,
    isDisabled: () => true,
  },
  {
    type: INPUT_TYPES.TEXT,
    name: STORE_INPUTS.ADDITIONNAL_ADDRESS,
    label: i18next.t('BACKOFFICE.STORES.ADDITIONNAL_ADDRESS'),
    width: INPUT_WIDTH.LARGE,
    isRequired: false,
    rule: yup.string().nullable(),
  },
];

export const STORE_COMPANY_INPUTS = ({
  companyEmails,
  companyCopies,
  setCompanyEmails,
  setCompanyCopies,
  handleEmails,
}) => [
  {
    type: INPUT_TYPES.TEXT,
    name: STORE_INPUTS.COMPANY_NAME,
    label: i18next.t('ADMIN.STORES.COMPANY_NAME'),
    width: INPUT_WIDTH.LARGE,
  },
  {
    type: INPUT_TYPES.TEXT,
    name: STORE_INPUTS.SIRET,
    label: i18next.t('ADMIN.STORES.SIRET'),
    width: INPUT_WIDTH.LARGE,
  },
  {
    type: INPUT_TYPES.TEXT,
    name: STORE_INPUTS.VAT_NUMBER,
    label: i18next.t('ADMIN.STORES.VAT_NUMBER'),
    width: INPUT_WIDTH.LARGE,
  },
  {
    type: INPUT_TYPES.TEXT,
    name: STORE_INPUTS.COMPANY_TYPE,
    label: i18next.t('ADMIN.STORES.COMPANY_TYPE'),
    width: INPUT_WIDTH.LARGE,
  },
  {
    type: INPUT_TYPES.TEXT_NUMBER,
    name: STORE_INPUTS.CAPITAL,
    label: i18next.t('ADMIN.STORES.CAPITAL'),
    width: INPUT_WIDTH.LARGE,
  },
  {
    type: INPUT_TYPES.TEXT,
    name: STORE_INPUTS.COMPANY_REGISTER,
    label: i18next.t('ADMIN.STORES.COMPANY_REGISTER'),
    width: INPUT_WIDTH.LARGE,
  },
  {
    type: INPUT_TYPES.SINGLE_SELECT,
    name: STORE_INPUTS.PAYMENT_TERM_IN_DAYS,
    label: `${i18next.t('ADMIN.STORES.PAYMENT_TERM_IN_DAYS')} :`,
    width: INPUT_WIDTH.LARGE,
    items: Object.values(PAYMENT_TERM_IN_DAYS_DROPDOWN_VALUES),
  },
  {
    type: INPUT_TYPES.CUSTOM_RENDER,
    customRender: () => (
      <InputEmailChip
        emails={companyEmails}
        placeholder={i18next.t('ADMIN.STORES.COMPANY_EMAIL')}
        required={false}
        setEmails={(emails) => handleEmails(emails, setCompanyEmails)}
        title={i18next.t('ADMIN.STORES.COMPANY_EMAIL')}
        titleProperties={{ color: ENUM_COLORS.DARKEST, font: ENUM_FONTS.TEXT_SMALL }}
        tooltipText={i18next.t('ADMIN.STORES.COMPANY_EMAIL_TOOLTIP')}
        width={INPUT_WIDTH.LARGE}
      />
    ),
  },
  {
    type: INPUT_TYPES.CUSTOM_RENDER,
    customRender: () => (
      <InputEmailChip
        emails={companyCopies}
        placeholder={i18next.t('ADMIN.STORES.COMPANY_CC')}
        required={false}
        setEmails={(emails) => handleEmails(emails, setCompanyCopies)}
        title={i18next.t('ADMIN.STORES.COMPANY_CC')}
        titleProperties={{ color: ENUM_COLORS.DARKEST, font: ENUM_FONTS.TEXT_SMALL }}
        tooltipText={i18next.t('ADMIN.STORES.COMPANY_CC_TOOLTIP')}
        width={INPUT_WIDTH.LARGE}
      />
    ),
  },
];

export const STORE_INVOICE_CONTROL_INPUTS = ({ invoiceControlPlans = [], storeParams, user }) => {
  const formattedInvoiceControlPlans = [
    { ...ITEM_DROPDOWN_NONE_VALUE(), value: i18next.t('GENERAL.SELECT_NONE_MASCULINE') },
    ...invoiceControlPlans,
  ].map((invoiceControlPlan, index) => ({ index, ...invoiceControlPlan }));

  return [
    {
      type: INPUT_TYPES.SINGLE_SELECT,
      name: STORE_INPUTS.PLAN,
      label: `${i18next.t('BACKOFFICE.STORES.PLAN')} :`,
      width: INPUT_WIDTH.LARGE,
      isDisabled: () => !user.isDeepsight,
      tooltipText: i18next.t('ADMIN.STORES.INVOICE_CONTROLS_PLAN_TOOLTIP'),
      items: formattedInvoiceControlPlans,
      sortBy: (items) => sortBy(items, 'index'),
    },
    {
      type: INPUT_TYPES.CUSTOM_RENDER,
      width: INPUT_WIDTH.LARGE,
      customRender: () => (
        <RemainingControls storeId={storeParams.id} timezone={storeParams.timezone} />
      ),
    },
  ];
};

export const STORE_PRODUCTION_INPUTS = () => [
  {
    type: INPUT_TYPES.SINGLE_SELECT,
    name: STORE_INPUTS.PRODUCTION_LOSS_CONVENTION,
    width: INPUT_WIDTH.LARGE,
    isRequired: true,
    items: STOCK_LOSS_CONVENTION_DROPDOWN_ITEMS(),
    label: i18next.t('GENERAL.LOSS_MOMENT'),
    tooltipText: i18next.t('GENERAL.LOSS_CALCULATION_TOOLTIP'),
  },
  {
    type: INPUT_TYPES.SINGLE_SELECT,
    name: STORE_INPUTS.PRODUCTION_STOCK_CONVENTION,
    width: INPUT_WIDTH.LARGE,
    isRequired: true,
    items: STOCK_LOSS_CONVENTION_DROPDOWN_ITEMS(),
    label: i18next.t('GENERAL.STOCK_MOMENT'),
    tooltipText: i18next.t('GENERAL.LOSS_CALCULATION_TOOLTIP'),
  },
];

export const STORE_LIBEO_INPUTS = (props) => {
  const { handleLibeoSync, isSynchronizing, libeoApiKey, setLibeoApiKey } = props;

  return [
    {
      type: INPUT_TYPES.PLAIN_TEXT,
      name: STORE_INPUTS.LIBEO_API_KEY,
      label: i18next.t('ADMIN.STORES.PARTNER_API_KEY', { partnerName: 'Libeo' }),
      width: INPUT_WIDTH.LARGE,
      isRequired: false,
      rule: yup.string().nullable(),
      computeValue: () => (
        <LibeoInputContainer>
          <Input
            label={i18next.t('ADMIN.STORES.PARTNER_API_KEY', { partnerName: 'Libeo' })}
            type="text"
            value={libeoApiKey}
            width={240}
            onChange={(event) => setLibeoApiKey(event.target.value)}
          />
          <Button
            animation={{ rotate: isSynchronizing }}
            buttonCustomStyle={{ marginTop: '24px', height: '4Opx' }}
            color={'inpulse-default'}
            handleClick={() => handleLibeoSync(libeoApiKey)}
            icon={
              isSynchronizing
                ? '/images/inpulse/loader-white-small.svg'
                : '/images/inpulse/sync-white.svg'
            }
            isDisabled={!libeoApiKey || !libeoApiKey.length || isSynchronizing}
            label={'Synchroniser'}
          />
        </LibeoInputContainer>
      ),
    },
  ];
};

export const STORE_KITCHEN_DELIVERY_INPUTS = () => [
  {
    type: INPUT_TYPES.SINGLE_SELECT,
    name: STORE_INPUTS.TYPE,
    label: `${i18next.t('GENERAL.STORE_TYPE')}`,
    width: INPUT_WIDTH.LARGE,
    isRequired: true,
    isDisabled: () => true,
  },
  {
    type: INPUT_TYPES.SINGLE_SELECT,
    name: STORE_INPUTS.SUPPLIER,
    label: `${i18next.t('GENERAL.SUPPLIER')} :`,
    width: INPUT_WIDTH.LARGE,
    isRequired: true,
    isDisabled: () => true,
  },
  {
    type: INPUT_TYPES.PLAIN_TEXT,
    name: STORE_INPUTS.SUPPLIER_ID,
    width: INPUT_WIDTH.LARGE,
    isRequired: false,
    computeValue: (supplierId) => (
      <InputContainer>
        <LinkContainer
          onClick={() => window.open(`/admin/suppliers/${supplierId}/details`, '_blank')}
        >
          <LinkImage src="/images/inpulse/open-a-new-black-small.svg" />
          <Text font={ENUM_FONTS.TEXT_MIDDLE_BOLD}>
            {i18next.t('ADMIN.STORES.LINK_SEE_SUPPLIER')}
          </Text>
        </LinkContainer>
      </InputContainer>
    ),
  },
  {
    type: INPUT_TYPES.PLAIN_TEXT,
    name: STORE_INPUTS.COUNTS,
    width: INPUT_WIDTH.LARGE,
    isRequired: false,
    computeValue: (counts) => {
      const supplierProfilesCount = counts.supplierProfilesCount || 0;
      const associatedStoresCount = counts.associatedStoresCount || 0;

      return (
        <CountsTextContainer>
          <TextContainer>
            <Text color={ENUM_COLORS.DARKER} font={ENUM_FONTS.TEXT_MIDDLE_NORMAL}>
              {i18next.t('ADMIN.STORES.NUMBER_OF_PROFILES')}
            </Text>
            <Text font={ENUM_FONTS.TEXT_MIDDLE_BOLD}>{supplierProfilesCount}</Text>
          </TextContainer>
          <TextContainer>
            <Text color={ENUM_COLORS.DARKER} font={ENUM_FONTS.TEXT_MIDDLE_NORMAL}>
              {i18next.t('ADMIN.STORES.NUMBER_OF_STORES')}
            </Text>
            <Text font={ENUM_FONTS.TEXT_MIDDLE_BOLD}>{associatedStoresCount}</Text>
          </TextContainer>
        </CountsTextContainer>
      );
    },
  },
];

export const STORE_DETAILS_FORM_INPUTS = (props) => [
  ...STORE_INFORMATIONS_INPUTS(props),
  ...CENTRAL_KITCHEN_PRODUCTION_PLANNING_INPUTS(),
  ...STORE_LOCATION_INPUTS(),
  ...STORE_COMPANY_INPUTS(props),
  ...STORE_INVOICE_CONTROL_INPUTS(props),
  ...STORE_PRODUCTION_INPUTS(),
  ...STORE_LIBEO_INPUTS(props),
  ...STORE_KITCHEN_DELIVERY_INPUTS(),
];
