import { isEmpty } from 'lodash';
import React, { useRef, useState, useEffect } from 'react';

import {
  CloseButton,
  CloseIcon,
  Container,
  Header,
  Icon,
  NavItemList,
  PageContainer,
  StyledLink,
  SubModuleContainer,
} from './styledComponents';
import { getTheme } from '../../utils/theme';
import sleekplan from '@services/sleekplan';
import Text, { ENUM_COLORS, ENUM_FONTS } from '../../Text';
import UserSection from '../UserSection';

const theme = getTheme();

const SideBarDrawerContent = ({
  location,
  user,
  clientStatus,
  hasLocalCatalogs,
  currentFeature,
  triggerDrawer,
  selectedModule,
  storeName,
  hasMultipleAccounts,
  isSwitchAccountOpened,
  setIsSwitchAccountOpened,
  updateReferential,
}) => {
  const navItemListRef = useRef(null);
  const [hasVerticalScrollbar, setHasVerticalScrollbar] = useState(false);
  const [hasReachedBottom, setHasReachedBottom] = useState(false);
  const [hasReachedTop, setHasReachedTop] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [requireSleekPlanToken, setRequireSleekPlanToken] = useState(false);

  const checkScrollbar = (event) => {
    if (!navItemListRef.current) {
      return;
    }
    const isScrollable = navItemListRef.current.scrollHeight > navItemListRef.current.clientHeight;

    setHasVerticalScrollbar(isScrollable);
    setHasReachedTop(true);

    if (event) {
      setHasReachedTop(event.target.scrollTop === 0);
      setHasReachedBottom(
        event.target.scrollTop ===
          navItemListRef.current.scrollHeight - navItemListRef.current.clientHeight,
      );
    }
  };

  useEffect(() => {
    checkScrollbar();
    const instance = navItemListRef.current;

    if (navItemListRef.current) {
      instance.addEventListener('scroll', checkScrollbar);

      return () => {
        instance.removeEventListener('scroll', checkScrollbar);
      };
    }
  }, [location, selectedModule, navItemListRef]);

  useEffect(() => {
    if (!redirectUrl) {
      return;
    }

    if (!requireSleekPlanToken) {
      window.open(redirectUrl, '_blank', 'noopener,noreferrer');
      return;
    }

    (async () => {
      const token = await sleekplan.generateToken(user.id);

      window.open(`${redirectUrl}?sso=${token}`, '_blank', 'noopener,noreferrer');
    })();
  }, [redirectUrl, requireSleekPlanToken]);

  const isCurrentPage = (currentFeature, page) => {
    if (isEmpty(currentFeature)) {
      return false;
    }

    // If we are at the user's profile page, no page is selected
    if (
      !!currentFeature.getLink &&
      location.pathname === currentFeature.getLink({ profileUser: { id: user.id } })
    ) {
      return false;
    }

    return (
      currentFeature.path.startsWith(page.path) ||
      (page.subFeatures && page.subFeatures.find(({ path }) => currentFeature.path === path))
    );
  };

  return (
    <Container>
      {/* SideBar Drawer Header */}
      <Header>
        <Text
          color={theme.colors.white}
          font={ENUM_FONTS.H2_INTER}
          style={{
            display: 'flex',
            alignItems: 'center',
            height: '32px',
          }}
        >
          {!isEmpty(selectedModule) && selectedModule.getText({ storeName })}
        </Text>
        <CloseButton onClick={() => triggerDrawer({ closeDrawer: true })}>
          <CloseIcon src="/images/inpulse/chevron-left-white-small.svg" />
        </CloseButton>
      </Header>

      {/* SideBar Drawer Content */}
      <NavItemList
        hasReachedBottom={hasReachedBottom}
        hasReachedTop={hasReachedTop}
        ref={navItemListRef}
        scrollable={hasVerticalScrollbar}
      >
        {!isEmpty(selectedModule.children) &&
          selectedModule.children.reduce((acc, subModule) => {
            // if isVisible method is not defined, the subModule is visible by default
            const isSubModuleVisible =
              !subModule.isVisible || subModule.isVisible({ clientStatus, hasLocalCatalogs });

            if (isSubModuleVisible) {
              acc.push(
                <>
                  {!subModule.hideSubmoduleName && (
                    <SubModuleContainer>
                      <Icon src={subModule.icon} />
                      <Text
                        color={ENUM_COLORS.DGREY}
                        key={subModule.getText({ storeName })}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          height: '32px',
                        }}
                      >
                        {subModule.getText({ storeName })}
                      </Text>
                    </SubModuleContainer>
                  )}
                  {!isEmpty(subModule.children) &&
                    subModule.children.map((page) => {
                      if (page.isVisible && !page.isVisible({ clientStatus, hasLocalCatalogs })) {
                        return;
                      }

                      return (
                        <PageContainer key={page.getText({ storeName })}>
                          <StyledLink
                            isActive={isCurrentPage(currentFeature, page)}
                            to={!page.isExternalLink && page.path}
                            onClick={() => {
                              if (page.isExternalLink) {
                                setRequireSleekPlanToken(page.requireSleekPlanToken);
                                setRedirectUrl(page.path);
                              }

                              triggerDrawer({ closeDrawer: true });
                            }}
                          >
                            {page.getText({ storeName })}
                          </StyledLink>
                        </PageContainer>
                      );
                    })}
                </>,
              );
            }
            return acc;
          }, [])}
      </NavItemList>

      {/* SideBar Drawer Footer */}
      {
        <UserSection
          hasMultipleAccounts={hasMultipleAccounts}
          isSwitchAccountOpened={isSwitchAccountOpened}
          setIsSwitchAccountOpened={setIsSwitchAccountOpened}
          triggerDrawer={triggerDrawer}
          updateReferential={updateReferential}
        />
      }
    </Container>
  );
};

export default SideBarDrawerContent;
