import { getActionAuthorization } from '../commons/utils';

const STORE_ACTIONS_RESOURCE = {
  SETUP_ASSORTMENT: 'stores:edit:setup-assortment',

  CREATE_SUPPLIER_PRODUCT_MAPPING: 'store:supplier-products:create',
  DELETE_SUPPLIER_PRODUCT_MAPPING: 'store:supplier-products:delete',
  EDIT_SUPPLIER_PRODUCT_MAPPING: 'store:supplier-products:edit',
  VIEW_SUPPLIER_PRODUCT_MAPPING: 'store:supplier-products:view',

  EDIT_ACCOUNT_MAPPING: 'store:accounts:edit',
  EDIT_BUFFER_STOCKS: 'store:buffer-stocks:edit',
};

export const canSetupAssortment = (actions) =>
  getActionAuthorization(actions, STORE_ACTIONS_RESOURCE.SETUP_ASSORTMENT);

export const canCreateSupplierProductMapping = (actions) =>
  getActionAuthorization(actions, STORE_ACTIONS_RESOURCE.CREATE_SUPPLIER_PRODUCT_MAPPING);

export const canDeleteSupplierProductMapping = (actions) =>
  getActionAuthorization(actions, STORE_ACTIONS_RESOURCE.DELETE_SUPPLIER_PRODUCT_MAPPING);

export const canEditSupplierProductMapping = (actions) =>
  getActionAuthorization(actions, STORE_ACTIONS_RESOURCE.EDIT_SUPPLIER_PRODUCT_MAPPING);

export const canViewSupplierProductMapping = (actions) =>
  getActionAuthorization(actions, STORE_ACTIONS_RESOURCE.VIEW_SUPPLIER_PRODUCT_MAPPING);

export const canEditAccountMapping = (actions) =>
  getActionAuthorization(actions, STORE_ACTIONS_RESOURCE.EDIT_ACCOUNT_MAPPING);

export const canEditBufferStocks = (actions) =>
  getActionAuthorization(actions, STORE_ACTIONS_RESOURCE.EDIT_BUFFER_STOCKS);
