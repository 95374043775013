import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 816px;

  padding: 24px;

  display: flex;
  flex-direction: column;
  row-gap: 24px;

  background-color: ${(props) => props.theme.colors?.greys?.lightest};

  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08) !important;
  border-radius: 8px;

  ${(props) =>
    props.isInError &&
    css`
      border: 1px solid ${(props) => props.theme.colors.red};
    `}
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  justify-content: space-between;

  font: ${(props) => props.theme.fonts.h2Inter};
  color: ${(props) => props.theme.colors?.greys?.darkest};
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  column-gap: 8px;

  font: ${(props) => props.theme.fonts.textMiddleNormal};
  color: ${(props) => props.theme.colors?.greys?.darkest};
`;

export const ButtonContainer = styled.div`
  width: fit-content;

  row-gap: 24px;

  display: flex;
`;

export const ColumnsContainer = styled.div`
  width: calc(100% - 96px);
  padding: 16px;

  display: flex;
  flex-direction: row;
  align-items: flex-start;

  > div {
    flex: 1;
  }

  > div:last-child {
    flex: 2;
  }
`;

export const RowContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0px;
  }

  padding: 16px;

  border-radius: ${(props) => props.theme.borders.radius.weak};
  border: solid 1px ${(props) => props.theme.colors.greys.light};
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  > div {
    flex: 1;
  }

  > div:last-child {
    flex: 2;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  width: 120px;

  justify-content: flex-end;
`;

export const LabelContainer = styled.div`
  min-width: 40px;
  display: flex;

  column-gap: 16px;
`;
