import { connect } from 'react-redux';
import _ from 'lodash';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { showConfirmationMessage } from '@actions/messageconfirmation';

import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { ListView, Button, Label } from '@commons/utils/styledLibraryComponents';
import { sortArrayOfObjectsAlphabetically } from '@commons/utils/sorting';

import { getClientInfo } from '@selectors/client';

import clientService from '@services/client';
import storeAccountMappingService from '@services/storeAccountMapping';

import theme from '@theme';

import { Content, Container, TopContainer, BottomContainer } from './styledComponents';

const getAccountsOfClient = async (setClientAccounts, showMessage, stores) => {
  try {
    const accounts = await clientService.getAccountsOfClient();

    const selectedStoreIds = stores.map((selectedStore) => selectedStore.id);

    const formattedAccounts = accounts.map((account) => {
      const alreadyAssociatedStoresCount = account.stores.reduce((result, store) => {
        if (selectedStoreIds.includes(store.id)) {
          result += 1;
        }

        return result;
      }, 0);

      return {
        ...account,
        name: `${account.firstName} ${account.lastName}`,
        role: _.get(account, 'lnkAccountRoleAccountrel.name'),
        alreadyAssociatedStoresCount,
        associatedStores: `${alreadyAssociatedStoresCount}/${stores.length}`,
        isNotSelectable: alreadyAssociatedStoresCount === stores.length,
        selected: false,
      };
    });

    setClientAccounts(formattedAccounts);
  } catch (err) {
    showMessage(i18next.t('USERS.DETAILS.PROFILE_LIST_ACCOUNTS_FETCH_FAILURE'), 'error');
    setClientAccounts([]);
  }
};

const handleSave = async (
  selectedAccounts,
  stores,
  reload,
  pageLoading,
  pageLoaded,
  showMessage,
  closeModal,
  translatedStoreName,
) => {
  pageLoading();

  try {
    const selectedAccountsIds = selectedAccounts.map((account) => account.id);
    const selectedStoresIds = stores.map((store) => store.id);

    await storeAccountMappingService.mapAccountsToStores(selectedAccountsIds, selectedStoresIds);

    await reload();

    showMessage(i18next.t('USERS.DETAILS.STORES_ASSOCIATE_SUCCESS'));

    closeModal();
  } catch (error) {
    showMessage(
      i18next.t('USERS.DETAILS.STORES_ASSOCIATE_FAILURE', {
        storeName: translatedStoreName,
      }),
      'error',
    );
  } finally {
    pageLoaded();
  }
};

const renderContent = (
  isLoading,
  headers,
  setSelectedAccounts,
  userLanguageCode,
  accountsToDisplay,
) => (
  <Content>
    <ListView
      columns={headers}
      data={accountsToDisplay}
      defaultOrderBy={'name'}
      defaultOrderType={'asc'}
      disableFooter={true}
      forceEnableSelection={true}
      isLoading={isLoading}
      languageCode={userLanguageCode}
      padding={'24px 24px 0px 24px'}
      setSelectedItems={setSelectedAccounts}
    />
  </Content>
);

const AccountAssociationModal = (props) => {
  const {
    user,
    client: { storeName },
    pageLoading,
    pageLoaded,
    showMessage,
    closeModal,
    params: { stores, reload },
  } = props;

  const [accounts, setClientAccounts] = useState([]);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [accountsToDisplay, setAccountsToDisplay] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const userLanguageCode = _.get(user, 'lnkLanguageAccountrel.code', 'fr');
  const translatedStoreName = getClientStoreNameTranslation(storeName, true);

  const headers = [
    {
      propertyKey: 'name',
      name: i18next.t('GENERAL.NAME'),
      render: (item) => <span style={{ textTransform: 'capitalize' }}>{item}</span>,
    },
    {
      propertyKey: 'email',
      name: i18next.t('USERS.DETAILS.PROFILE_EMAIL_INPUT_LABEL'),
    },
    {
      propertyKey: 'role',
      name: i18next.t('USERS.DETAILS.PROFILE_ROLE_INPUT_LABEL'),
      render: (item) => <span style={{ textTransform: 'capitalize' }}>{item}</span>,
    },
    {
      propertyKey: 'associatedStores',
      name: i18next.t('USERS.DETAILS.NAV_BAR_SUB_PAGE_ASSOCIATED_STORES', {
        storeName: translatedStoreName,
      }),
      render: (item) => (
        <Label
          background={theme.colors.greys.darkest}
          color={theme.colors.greys.darkest}
          type="tertiary"
          width={'fit-content'}
        >
          {item}
        </Label>
      ),
    },
  ];

  useEffect(() => {
    (async () => {
      await getAccountsOfClient(setClientAccounts, props.showMessage, stores);
      setIsLoading(false);
    })();
  }, [stores]);

  useEffect(() => {
    setAccountsToDisplay(
      sortArrayOfObjectsAlphabetically(accounts, ['alreadyAssociatedStoresCount', 'name']),
    );
  }, [accounts]);

  return (
    <Container>
      <TopContainer>
        {i18next.t('BACKOFFICE.STORES.LIST_ASSOCIATION_MODAL_TITLE', {
          storeName: getClientStoreNameTranslation(storeName, true).toLowerCase(),
        })}
      </TopContainer>
      {renderContent(isLoading, headers, setSelectedAccounts, userLanguageCode, accountsToDisplay)}
      {!isLoading && (
        <BottomContainer>
          <Button
            color={'inpulse-outline'}
            fitContent={true}
            fontSize={14}
            fontWeight={600}
            formatText={false}
            handleClick={closeModal}
            icon={'/images/inpulse/close-black-small.svg'}
            label={i18next.t('GENERAL.CANCEL')}
            minWidth={110}
          />
          <Button
            color={selectedAccounts.length >= 1 ? 'blue' : 'locked'}
            fitContent={true}
            fontSize={14}
            fontWeight={600}
            formatText={false}
            handleClick={() => {
              if (!selectedAccounts.length) {
                return;
              }

              handleSave(
                selectedAccounts,
                stores,
                reload,
                pageLoading,
                pageLoaded,
                showMessage,
                closeModal,
                getClientStoreNameTranslation(storeName, true).toLowerCase(),
              );
            }}
            icon={'/images/inpulse/save-white-small.svg'}
            isDisabled={!selectedAccounts.length}
            label={i18next.t('GENERAL.SAVE')}
            minWidth={110}
          />
        </BottomContainer>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountAssociationModal);
