import { get, forEach } from 'lodash';
import i18next from 'i18next';
import moment from 'moment';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import utilsXLS from '@commons/utils/makeXLS';

/**
 * Generate columns settings to display in the main sheet of the xls file
 * matching some rules to apply on value formatting and associated key name property
 *
 * @return {Array} the list of columns settings that should appear on export file
 */
export function getMainSheetColumnsSettings(supplierProducts) {
  const columns = [
    {
      name: i18next.t('GENERAL.NAME'),
      propertyKey: 'name',
      transform: (value) => (value ? value.trim() : ''),
    },
    {
      name: 'SKU',
      propertyKey: 'sku',
      transform: (value) => (value ? value.trim() : i18next.t('GENERAL.SELECT_UNDEFINED')),
    },
    {
      name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.EAN_CODE'),
      propertyKey: 'ean',
    },
    {
      name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_SUPPLIER'),
      propertyKey: 'supplier.name',
      transform: (value) => (value ? value.trim() : ''),
    },
    {
      name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_MANUFACTURER_NAME'),
      propertyKey: 'manufacturerName',
    },
    {
      name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_INGREDIENT'),
      propertyKey: 'entityName',
      transform: (value) => (value ? value.trim() : i18next.t('GENERAL.SELECT_NONE_MASCULINE')),
    },
    {
      name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_INGREDIENT_ASSOCIATED_ON'),
      propertyKey: 'entityAssociatedAt',
      transform: (value) =>
        value && moment(value).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR),
    },
    {
      name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_CATEGORY'),
      propertyKey: 'category',
      transform: (value) => (value ? value.trim() : i18next.t('GENERAL.SELECT_NONE_FEMININE')),
    },
    {
      name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_SUB_CATEGORY'),
      propertyKey: 'subCategory',
      transform: (value) => (value ? value.trim() : i18next.t('GENERAL.SELECT_NONE_FEMININE')),
    },
    {
      type: 'currency',
      name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_PRICE'),
      propertyKey: 'price',
    },
    {
      name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_VAT_RATE'),
      propertyKey: 'vatRate',
    },
    {
      name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_RECIPES_UNIT'),
      propertyKey: 'packagings[0].unit',
    },
    {
      name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_CONDITIONNING_PACKAGING_WEIGHT'),
      propertyKey: 'packagingWeight',
    },
    {
      name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_LOSS'),
      propertyKey: 'loss',
    },
    {
      name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_STATUS'),
      propertyKey: 'active',
      transform: (value) => i18next.t(value ? 'GENERAL.ACTIVE' : 'GENERAL.INACTIVE'),
    },
    {
      name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.SPECIFICATION_SHEET'),
      propertyKey: 'attachmentLink',
      transform: (value) => i18next.t(value ? 'GENERAL.YES' : 'GENERAL.NO'),
    },
  ];

  let numberOfSupplierInputs = 0;

  supplierProducts.forEach((supplierProduct) => {
    if (get(supplierProduct, 'supplierPriceInputs.length', 0) > numberOfSupplierInputs) {
      numberOfSupplierInputs = supplierProduct.supplierPriceInputs.length;
    }
  });

  for (let index = 0; index < numberOfSupplierInputs; index++) {
    columns.splice(9, 0, {
      propertyKey: `inputPriceName${index + 2}`,
      name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.PRICE_NAME', { value: index + 2 }),
    });
    columns.splice(10, 0, {
      propertyKey: `price${index + 2}`,
      name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.PRICE', { value: index + 2 }),
    });
  }

  return columns;
}

/**
 * Generate columns settings to display in the contect sheet of the xls file
 * matching some rules to apply on value formatting and associated key name property
 *
 * @return {Array} the list of columns settings that should appear on export file
 */
export function getContextSheetColumnsSettings() {
  return [
    {
      displayName: i18next.t('GENERAL.NAME'),
    },
    {
      displayName: 'SKU',
    },
    {
      displayName: i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_SUPPLIER'),
    },
    {
      displayName: i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_CATEGORY'),
    },
    {
      displayName: i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_SUB_CATEGORY'),
    },
    {
      displayName: i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_PACKAGING_NAME'),
    },
    {
      displayName: i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_PACKAGING_VALUE'),
    },
    {
      displayName: i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_PACKAGING_UNIT'),
    },
    {
      displayName: i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_ORDER_PACKAGING'),
    },
    {
      displayName: i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_INVOICE_PACKAGING'),
    },
    {
      displayName: i18next.t(
        'ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_PACKAGING_AVAILABLE_TO_INVENTORY',
      ),
    },
  ];
}

/**
 * Create context sheet information with its content to feed
 * (store name, supplier name, start date, end date)
 *
 * @param {Array<SupplierProduct>} supplierProducts - an appropriately formatted array of SupplierProducts
 *
 * @returns {void}
 */
export function createContextSheet(supplierProducts) {
  const headersSettings = getContextSheetColumnsSettings();

  const headers = headersSettings.map(({ displayName }) => displayName);

  let mappedData = [];

  forEach(supplierProducts, (supplierProduct) => {
    let lastPackagingName = null;
    forEach(supplierProduct.packagings, (packaging) => {
      mappedData.push([
        supplierProduct.name,
        supplierProduct.sku,
        (supplierProduct.supplier || {}).name,
        supplierProduct.category,
        supplierProduct.subCategory,
        packaging.name,
        packaging.quantity,
        lastPackagingName ? lastPackagingName : supplierProduct.packagingUnit,
        packaging.isUsedInOrder ? i18next.t('GENERAL.YES') : i18next.t('GENERAL.NO'),
        packaging.isUsedInInvoice ? i18next.t('GENERAL.YES') : i18next.t('GENERAL.NO'),
        packaging.isUsedInStock ? i18next.t('GENERAL.YES') : i18next.t('GENERAL.NO'),
      ]);
      lastPackagingName = packaging.name;
    });
  });

  return {
    title: i18next.t('ADMIN.SUPPLIER_PRODUCTS.EXPORT_PACKAGING_SHEET_NAME'),
    headers,
    data: mappedData,
  };
}

/**
 * Handle the export of the analytics reference data into a XLS file
 *
 * @returns {void}
 */
export function exportSupplierProducts(supplierProducts, currency) {
  if (!supplierProducts) {
    return;
  }

  const mainSheet = utilsXLS.generateDefaultSheet(
    i18next.t('ADMIN.SUPPLIER_PRODUCTS.EXPORT_SUPPLIER_PRODUCT_SHEET_NAME'),
    getMainSheetColumnsSettings(supplierProducts),
    supplierProducts,
    currency,
  );

  const contextSheet = createContextSheet(supplierProducts);

  utilsXLS.makeXLS(i18next.t('ADMIN.SUPPLIER_PRODUCTS.EXPORT_FILE_NAME'), [
    mainSheet,
    contextSheet,
  ]);
}
