import { forecast } from '../services/forecast';

export const receiveForecasts = (forecasts) => ({
  type: 'FORECASTS_SUCCESS',
  forecasts,
});

export const receivePastForecasts = (pastForecasts) => ({
  type: 'PAST_FORECASTS_SUCCESS',
  pastForecasts,
});

export const receiveWeeklyForecasts = (weeklyForecasts) => ({
  type: 'WEEKLY_FORECASTS_SUCCESS',
  weeklyForecasts,
});

export const receiveRangeForecasts = (rangeForecasts) => ({
  type: 'RANGE_FORECASTS_SUCCESS',
  rangeForecasts,
});

export const requestForecastsError = (error) => ({
  type: 'FORECASTS_FAILURE',
  error,
});

export const requestPastForecastsError = (error) => ({
  type: 'PAST_FORECASTS_FAILURE',
  error,
});

export const requestWeeklyForecastsError = (error) => ({
  type: 'WEEKLY_FORECASTS_FAILURE',
  error,
});

export const requestRangeForecastsError = (error) => ({
  type: 'RANGE_FORECASTS_FAILURE',
  error,
});

export const recieveupdateOrCreateMultipleForecastsByDateResult = (forecastToAmend) => ({
  type: 'FORECASTS_PATCH_BY_DATE_SUCCESS',
  forecastToAmend,
});

export const requestupdateOrCreateMultipleForecastsByDateError = (error) => ({
  type: 'FORECASTS_PATCH_BY_DATE_FAILURE',
  error,
});

export const fetchWeeklyForecastsOfStore = (store_id, start_date, end_date) =>
  function (dispatch) {
    dispatch({
      type: 'WEEKLY_FORECASTS_REQUEST',
    });
    return forecast.getForecastsOfStore(store_id, start_date, end_date);
  };

export const fetchRangeForecastsOfStore = (store_id, start_date, end_date) =>
  function (dispatch) {
    dispatch({
      type: 'RANGE_FORECASTS_REQUEST',
    });
    return forecast.getForecastsOfStore(store_id, start_date, end_date);
  };

export const fetchForecastsOfStore = (store_id, start_date, end_date) =>
  function (dispatch) {
    dispatch({
      type: 'FORECASTS_REQUEST',
    });
    return forecast.getForecastsOfStore(store_id, start_date, end_date);
  };

export const fetchPastForecastsOfStore = (store_id, start_date, end_date) =>
  function (dispatch) {
    dispatch({
      type: 'PAST_FORECASTS_REQUEST',
    });
    return forecast.getForecastsOfStore(store_id, start_date, end_date);
  };

export const updateOrCreateMultipleForecastsByDate = (params) =>
  function (dispatch) {
    dispatch({
      type: 'FORECASTS_PATCH_BY_DATE_REQUEST',
    });
    return forecast.updateOrCreateMultipleForecastsByDate(params);
  };

export const clearForecasts = () => ({
  type: 'CLEAR_FORECASTS',
});
