import { connect } from 'react-redux';
import { get, keyBy, pick } from 'lodash';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { openGenericModal, openMediumModal, refreshGenericModal } from '@actions/modal';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { ListView } from '@commons/utils/styledLibraryComponents';
import EmptyState from '@commons/EmptyState';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';
import sortActionsList from '@commons/utils/sortActionsList';

import cashierConfigService from '@services/cashierConfig';

import { Container } from './styledComponents';
import { getColumns } from './utils/getColumns';
import { getRowActions, getActions } from './utils/actions';
import buildSchema from './utils/schema';
import modalUtils from './utils/modal';

export const BackOfficeCashiers = (props) => {
  const {
    user,
    match,
    showSuccessMessage,
    showErrorMessage,
    pageLoaded,
    pageLoading,
    openModal,
    openGenericModal,
    refreshGenericModal,
  } = props;

  const [isLoadingStatus, setIsLoadingStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [cashierConfigs, setCashierConfigs] = useState([]);
  const [columns, setColumns] = useState(getColumns(isLoadingStatus));
  const [rowActions, setRowActions] = useState([]);
  const [actions, setActions] = useState([]);

  const path = get(match, 'path');
  const userLanguageCode = get(user, 'lnkLanguageAccountrel.code', 'fr');
  const clientId = get(user, 'clientId');

  /************************************/
  /** CashierConfig deletion methods **/
  /************************************/

  const updateCashierConfigIsActiveStatus = async (cashierConfigId, isActive) => {
    setIsLoading(true);
    try {
      await cashierConfigService.patchCashierConfig(
        clientId,
        { id: cashierConfigId, isActive },
        true,
      );

      await loadCashierConfigs();

      showSuccessMessage(
        i18next.t(
          !!isActive
            ? 'BACKOFFICE.CASHIERS.ACTIVATE_SALES_INGESTION_SUCCESS'
            : 'BACKOFFICE.CASHIERS.DEACTIVATE_SALES_INGESTION_SUCCESS',
        ),
      );
    } catch {
      showErrorMessage(
        i18next.t(
          !!isActive
            ? 'BACKOFFICE.CASHIERS.ACTIVATE_SALES_INGESTION_ERROR'
            : 'BACKOFFICE.CASHIERS.DEACTIVATE_SALES_INGESTION_ERROR',
        ),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const displayModalDeleteCashierConfig = (cashierConfig) => {
    const params = modalUtils.getDeleteCashierConfigModalConfig({
      cashierConfig,
      handleDeleteCashierConfigById,
    });

    openGenericModal(params);
  };

  const handleDeleteCashierConfigById = async (cashierConfigId) => {
    pageLoading();

    try {
      await cashierConfigService.deleteCashierConfig(clientId, cashierConfigId);

      showSuccessMessage(i18next.t('BACKOFFICE.CASHIERS.DELETE_CASHIER_CONFIG_SUCCESS'));

      await loadCashierConfigs();
    } catch (err) {
      showErrorMessage(i18next.t('BACKOFFICE.CASHIERS.DELETE_CASHIER_CONFIG_FAILURE'));
    } finally {
      pageLoaded();
    }
  };

  /*********************/
  /** Loading methods **/
  /*********************/

  const loadCashierConfigs = async () => {
    setIsLoading(true);
    let fetchedCashierConfigs = cashierConfigs;

    try {
      fetchedCashierConfigs = await cashierConfigService.getCashierConfigByClientId();

      setCashierConfigs(fetchedCashierConfigs);
    } catch {
      showErrorMessage(i18next.t('BACKOFFICE.CASHIERS.FETCH_CASHIER_CONFIGS_FAILURE'));
      fetchedCashierConfigs = [];

      setCashierConfigs([]);
    } finally {
      setIsLoading(false);
      getCashierConfigStatus(fetchedCashierConfigs);
    }
  };

  const getCashierConfigStatus = async (cashierConfigs) => {
    if (!cashierConfigs.length) {
      return;
    }

    setIsLoadingStatus(true);

    try {
      const cashierConfigIds = cashierConfigs.map(({ id }) => id);

      const cashierConfigConnexions = await cashierConfigService.getConnexionStatusByIds(
        cashierConfigIds,
      );

      const cashierConfigConnexionsByIds = keyBy(cashierConfigConnexions, 'id');

      const formattedCashierConfigs = cashierConfigs.map((config) => {
        const associatedConnexion = cashierConfigConnexionsByIds[config.id];

        return {
          ...config,
          ...associatedConnexion,
          status: associatedConnexion.operational,
        };
      });

      setCashierConfigs(formattedCashierConfigs);
    } catch {
      showErrorMessage(i18next.t('BACKOFFICE.CASHIERS.FETCH_CONNECTION_STATUS_ERROR'));
    } finally {
      setIsLoadingStatus(false);
    }
  };

  /****************************************/
  /** CashierConfig props update methods **/
  /****************************************/

  const handleSavePropsCashierConfig = async (cashierConfig) => {
    pageLoading();
    const isCreation = !cashierConfig.id;
    try {
      await cashierConfigService[isCreation ? 'createCashierConfig' : 'patchCashierConfig'](
        clientId,
        cashierConfig,
      );
      showSuccessMessage(
        i18next.t(
          isCreation
            ? 'BACKOFFICE.CASHIERS.CREATE_CASHIER_CONFIG_SUCCESS'
            : 'BACKOFFICE.CASHIERS.UPDATE_CASHIER_CONFIG_SUCCESS',
        ),
      );
      await loadCashierConfigs();
    } catch (err) {
      showErrorMessage(
        i18next.t(
          isCreation
            ? 'BACKOFFICE.CASHIERS.CREATE_CASHIER_CONFIG_FAILURE'
            : 'BACKOFFICE.CASHIERS.UPDATE_CASHIER_CONFIG_FAILURE',
        ),
      );
    } finally {
      pageLoaded();
    }
  };

  const onPropsChange = (cashierConfig, value) => {
    const { schema } = buildSchema(cashierConfig.lnkCashierCashierConfig);

    const params = modalUtils.getPropsEditorModalConfig({
      cashierConfig: { ...cashierConfig, ...value },
      props: value,
      schema,
      onPropsChange,
      handleSavePropsCashierConfig,
    });

    refreshGenericModal(params);
  };

  const displayModalConfigEdition = (cashierConfig) => {
    const value = pick(cashierConfig, ['credentials', 'config', 'name']);
    const { schema } = buildSchema(cashierConfig.lnkCashierCashierConfig);

    const params = modalUtils.getPropsEditorModalConfig({
      cashierConfig,
      props: value,
      schema,
      onPropsChange,
      handleSavePropsCashierConfig,
    });

    openGenericModal(params);
  };

  useEffect(() => {
    (async function loadData() {
      await loadCashierConfigs();
    })();
  }, []);

  useEffect(() => {
    setColumns(getColumns(isLoadingStatus));
  }, [isLoadingStatus]);

  useEffect(() => {
    const rawRowActions = getRowActions(
      displayModalConfigEdition,
      displayModalDeleteCashierConfig,
      updateCashierConfigIsActiveStatus,
    );

    setRowActions(sortActionsList(rawRowActions));

    setActions(getActions(userLanguageCode, displayModalConfigEdition, clientId, openModal));
  }, []);

  return (
    <Container>
      <NavigationBreadCrumb featurePath={path} />
      <ListView
        actions={actions}
        columns={columns}
        data={cashierConfigs}
        defaultOrderBy={'name'}
        defaultOrderType={'asc'}
        disableSelection={true}
        isLoading={isLoading}
        languageCode={userLanguageCode}
        padding={'24px 24px 0px 24px'}
        placeholderShape={i18next.t('GENERAL.SEARCH')}
        renderEmptyState={() => <EmptyState />}
        rowActions={rowActions}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
  openModal: (params) => {
    dispatch(openMediumModal(params));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeCashiers);
