import { cloneDeep, includes } from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { ListView } from '@commons/utils/styledLibraryComponents';

import { CHOICES_DROPDOWN_ACTIVE, CHOICES_STORE_TYPE } from '@admin/utils/DropdownItems';

import DeepsightFiltersButton from '@orders/components/FilterButton';

import { Container } from './styledComponents';
import { getStoreColumnConfig } from './utils';

export const StoreAssociationModal = (props) => {
  const { stores, handleStoreSelection, hasMultipleBrands, brands, userInformations } = props;

  const [filteredStores, setFilteredStores] = useState(stores);
  const [selectedBrands, setSelectedBrands] = useState(brands || []);

  const [filters, setFilters] = useState(null);
  const [applyFilters, setApplyFilters] = useState(true);

  const [activeKeyForActiveDropdown, setActiveKeyForActiveDropdown] = useState(
    CHOICES_DROPDOWN_ACTIVE()[0],
  );

  const [selectedStoreTypes, setSelectedStoreTypes] = useState(CHOICES_STORE_TYPE());

  const columns = getStoreColumnConfig({ hasMultipleBrands });

  useEffect(() => {
    if (!stores) {
      return;
    }

    const formattedSelectedStores = cloneDeep(stores).map((store) => ({
      ...store,
      isRowSelected: includes(userInformations.associatedStores, store.id),
    }));

    setFilteredStores(formattedSelectedStores);
  }, []);

  /******************************/
  /** HANDLE FILTERS LIST VIEW **/
  /******************************/
  useEffect(() => {
    if (!applyFilters) {
      return;
    }
    const storeTypeFilterValues = selectedStoreTypes.map(({ filterValue }) => filterValue);

    let filteredSelectedStores = stores.filter(
      ({ active, isKitchen }) =>
        activeKeyForActiveDropdown.filterValue === active &&
        storeTypeFilterValues.includes(isKitchen),
    );

    if (hasMultipleBrands) {
      filteredSelectedStores = filteredSelectedStores.filter(({ brandId }) =>
        selectedBrands.some(
          ({ id }) => id === brandId || (id === -1 && !brandId), // -1 is the id for the "None" brand
        ),
      );
    }

    const formattedSelectedStores = cloneDeep(filteredSelectedStores).map((store) => ({
      ...store,
      isRowSelected: includes(userInformations.associatedStores, store.id),
    }));

    setFilteredStores(formattedSelectedStores);
  }, [applyFilters]);

  useEffect(() => {
    const filteredSelectedStores = stores.filter(
      ({ active }) => activeKeyForActiveDropdown.filterValue === active,
    );

    const formattedSelectedStores = cloneDeep(filteredSelectedStores).map((store) => ({
      ...store,
      isRowSelected: includes(userInformations.associatedStores, store.id),
    }));

    setFilteredStores(formattedSelectedStores);
  }, [stores]);

  return (
    <Container>
      <ListView
        columns={columns}
        data={filteredStores}
        defaultOrderBy={'name'}
        defaultOrderType={'asc'}
        disableFooter={true}
        forceEnableSelection={true}
        isLoading={false}
        languageCode="fr"
        padding={'0px'}
        placeholderShape={i18next.t('GENERAL.SEARCH')}
        renderFilterButton={() => (
          <DeepsightFiltersButton
            advancedFilters={[]}
            applyFilters={applyFilters}
            brands={brands}
            columnsFilterList={columns.filter((column) => !!column.filterType)}
            customMultipleDropDowns={[
              {
                id: 'type',
                icon: '/images/inpulse/pin-black-small.svg',
                list: CHOICES_STORE_TYPE(),
                defaultSelectedItems: CHOICES_STORE_TYPE(),
                selectedItems: selectedStoreTypes,
                setSelectedItems: (selectedItems) => setSelectedStoreTypes(selectedItems),
              },
            ]}
            customSingleDropDowns={[
              {
                id: 'status',
                itemSelectedIcon: '/images/inpulse/power-ip-black.svg',
                list: CHOICES_DROPDOWN_ACTIVE(),
                defaultSelectedItem: CHOICES_DROPDOWN_ACTIVE()[0],
                selectedItem: activeKeyForActiveDropdown,
                setSelectedItem: (selectedItem) => setActiveKeyForActiveDropdown(selectedItem),
              },
            ]}
            filters={filters}
            isLoading={false}
            readOnly={false}
            selectedBrands={selectedBrands}
            setAdvancedFilters={() => null}
            setApplyFilters={setApplyFilters}
            setFilters={setFilters}
            setSelectedBrands={setSelectedBrands}
            textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
            disableAdvancedFilter
          />
        )}
        setSelectedItems={handleStoreSelection}
      />
    </Container>
  );
};

export default StoreAssociationModal;
