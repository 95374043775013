import { connect } from 'react-redux';
import { orderBy, round, sumBy } from 'lodash';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { Franco, ToggleSwitch } from '@commons/utils/styledLibraryComponents';
import { getTheme } from '@commons/utils/theme';
import DisplayNumber from '@commons/DisplayNumber';
import NavigationBar from '@commons/NavigationBar';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

import invoiceService from '@services/invoice';

import { INVOICE_CONTROL_STATUS } from '../commons/constants';
import { NavBarContainer } from '../invoiceControlDetails/styledComponents';

import {
  Container,
  EmptyDiv,
  EmptyDivContainer,
  HeaderContainer,
  ToggleContainer,
} from './styledComponents';

export const InvoiceControlLineByLine = (props) => {
  const {
    match: { path, params },
    currency,
    pageLoading,
    pageLoaded,
    showErrorMessage,
  } = props;

  const [invoiceControlList, setInvoiceControlList] = useState(null);
  const [hasAssociatedOrders, setHasAssociatedOrders] = useState(false);
  const [fetchedLineByLineData, setFetchedLineByLineData] = useState([]);
  const [displayProductOrderNotMapped, setDisplayProductOrderNotMapped] = useState(false);
  const [invoiceTotalGap, setInvoiceTotalGap] = useState(0);
  const [conformLines, setConformLines] = useState(0);
  const [formattedLineByLineData, setFormattedLineByLineData] = useState([]);

  const theme = getTheme();

  useEffect(() => {
    (async () => {
      await getICLAndLineByLineData();
    })();
  }, []);

  useEffect(() => {
    formatLineByLineData(fetchedLineByLineData);
  }, [displayProductOrderNotMapped]);

  const formatLineByLineData = (fetchedData) => {
    const invoiceControlLinesLinkedToOrders = fetchedData.filter(
      ({ invoiceControlId, spId, orderId }) =>
        displayProductOrderNotMapped ? spId && orderId : invoiceControlId && spId && orderId,
    );

    const invoiceControlLinesNotLinkedToOrders = fetchedData.filter(
      ({ invoiceControlId, spId, orderId }) => invoiceControlId && (!spId || !orderId),
    );

    const allFormattedLines = [
      ...orderBy(
        invoiceControlLinesLinkedToOrders,
        ['invoiceNumber', 'orderDeliveryDate', 'orderId', 'totalGap'],
        ['asc', 'asc', 'asc', 'asc'],
      ),
      ...orderBy(invoiceControlLinesNotLinkedToOrders, ['totalGap'], ['asc']),
    ];

    const totalGap = sumBy(allFormattedLines, 'totalGap');

    setFormattedLineByLineData(allFormattedLines);
    setInvoiceTotalGap(totalGap);
    setConformLines(
      allFormattedLines.filter(({ status }) => status === INVOICE_CONTROL_STATUS.NO_GAP).length,
    );
  };

  const getICLAndLineByLineData = async () => {
    pageLoading();

    try {
      const [icl, lineByLinePayload, associatedOrders] = await Promise.all([
        invoiceService.getById(params.id),
        invoiceService.aggregatedAnalyseAndExport([params.id]),
        invoiceService.getOrdersByInvoiceControlListId(params.id),
      ]);

      setInvoiceControlList(icl);
      setFetchedLineByLineData(lineByLinePayload);
      setHasAssociatedOrders(!!associatedOrders.length);
      formatLineByLineData(lineByLinePayload);
    } catch {
      showErrorMessage(
        i18next.t('INVOICE.INVOICE_CONTROL_LINE_BY_LINE.FETCH_LINE_BY_LINE_DATA_ERROR'),
      );
    } finally {
      pageLoaded();
    }
  };

  return (
    <>
      <NavBarContainer>
        <NavigationBar
          bigTopBar={true}
          hasAssociatedOrders={hasAssociatedOrders}
          invoiceControlList={invoiceControlList || {}}
          path={path}
        />
      </NavBarContainer>
      <Container>
        <HeaderContainer>
          <Franco
            background={invoiceTotalGap < 0 ? theme?.colors?.infoRed : theme?.colors?.infoGreen}
            color={`${theme.colors.greys.lightest}`}
            content={<></>}
            labelContent={
              <DisplayNumber
                color={ENUM_COLORS.LIGHTEST}
                currency={currency}
                font={ENUM_FONTS.TEXT_COMPONENT_LABEL}
                number={invoiceTotalGap}
              />
            }
            title={i18next.t('INVOICE.INVOICE_CONTROL_LINE_BY_LINE.TOTAL_GAP')}
            hasBorder
          />
          <Franco
            background={
              conformLines !== formattedLineByLineData.length
                ? theme?.colors?.infoRed
                : theme?.colors?.infoGreen
            }
            color={`${theme.colors.greys.lightest}`}
            content={
              <Text font={ENUM_FONTS.TEXT_SMALL_HEIGHT_16}>
                {conformLines}/{formattedLineByLineData.length}
              </Text>
            }
            labelContent={
              <Text color={ENUM_COLORS.LIGHTEST} font={ENUM_FONTS.TEXT_COMPONENT_LABEL}>
                {round((conformLines / formattedLineByLineData.length) * 100)}%
              </Text>
            }
            title={i18next.t('INVOICE.INVOICE_CONTROL_LINE_BY_LINE.NB_CONFORM_LINES')}
            hasBorder
          />
          <ToggleContainer>
            <ToggleSwitch
              checked={displayProductOrderNotMapped}
              handleClick={() => {
                setDisplayProductOrderNotMapped(!displayProductOrderNotMapped);
              }}
              id={`display-product-order-not-mapped-toggle`}
              key={'display-product-order-not-mapped-toggle'}
            />
            <Text>
              {i18next.t('INVOICE.INVOICE_CONTROL_LINE_BY_LINE.DISPLAY_ALL_ORDERS_LINES')}
            </Text>
          </ToggleContainer>
        </HeaderContainer>
        <TableContainer
          component={Paper}
          sx={{
            maxHeight: '100%',
            overflowY: 'auto',
            borderRadius: '8px',
            border: '1px solid #D7D7D7',
          }}
        >
          <Table stickyHeader>
            <TableHead
              sx={{
                position: 'sticky',
                top: 0,
                backgroundColor: `${theme.colors.greys.lightest}`,
                zIndex: 1,
              }}
            >
              <TableRow>
                <TableCell align="center" colSpan={4}>
                  {i18next.t('INVOICE.INVOICE_CONTROL_LINE_BY_LINE.INVOICE')}
                </TableCell>
                <TableCell align="center" colSpan={1} sx={{ padding: 0 }}>
                  <EmptyDivContainer>
                    <EmptyDiv style={{ borderRight: `1px solid ${theme.colors.greys.medium}` }} />
                    <EmptyDiv />
                  </EmptyDivContainer>
                </TableCell>
                <TableCell align="center" colSpan={4}>
                  {i18next.t('INVOICE.INVOICE_CONTROL_LINE_BY_LINE.ORDERS')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{i18next.t('GENERAL.NAME')}</TableCell>
                <TableCell>{i18next.t('GENERAL.QUANTITY')}</TableCell>
                <TableCell>{i18next.t('GENERAL.PRICE_EX_TAX')}</TableCell>
                <TableCell>{i18next.t('GENERAL.TOTAL_EX_TAX')}</TableCell>
                <TableCell
                  align="center"
                  sx={{ backgroundColor: `${theme.colors.greys.lightyMedium}` }}
                >
                  {i18next.t('INVOICE.INVOICE_CONTROL_LINE_BY_LINE.GAP')}
                </TableCell>
                <TableCell>{i18next.t('GENERAL.NAME')}</TableCell>
                <TableCell>{i18next.t('GENERAL.QUANTITY')}</TableCell>
                <TableCell>{i18next.t('GENERAL.PRICE_EX_TAX')}</TableCell>
                <TableCell>{i18next.t('GENERAL.TOTAL_EX_TAX')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formattedLineByLineData.map((row, index, array) => (
                <React.Fragment key={index}>
                  {row.orderId &&
                    row.spId &&
                    (index === 0 || row.orderId !== array[index - 1].orderId) && (
                      <TableRow>
                        <TableCell
                          colSpan={4}
                          sx={{
                            backgroundColor: `${theme.colors.greys.lightyMedium}`,
                          }}
                        ></TableCell>
                        <TableCell
                          colSpan={1}
                          sx={{
                            backgroundColor: `${theme.colors.greys.lightyMedium}`,
                          }}
                        ></TableCell>
                        <TableCell
                          colSpan={4}
                          sx={{
                            backgroundColor: `${theme.colors.greys.lightyMedium}`,
                          }}
                        >
                          {row.orderReference}
                        </TableCell>
                      </TableRow>
                    )}
                  {(!row.orderId || !row.spId) &&
                    (index === 0 ||
                      (array[index - 1].orderId && array[index - 1].spId) ||
                      row.invoiceNumber !== array[index - 1].invoiceNumber) && (
                      <TableRow>
                        <TableCell
                          colSpan={4}
                          sx={{
                            backgroundColor: `${theme.colors.greys.lightyMedium}`,
                          }}
                        >
                          {row.invoiceNumber}
                        </TableCell>
                        <TableCell
                          colSpan={1}
                          sx={{
                            backgroundColor: `${theme.colors.greys.lightyMedium}`,
                          }}
                        ></TableCell>
                        <TableCell
                          colSpan={4}
                          sx={{
                            backgroundColor: `${theme.colors.greys.lightyMedium}`,
                          }}
                        ></TableCell>
                      </TableRow>
                    )}
                  <TableRow>
                    <TableCell>
                      <Text font={ENUM_FONTS.TEXT_SMALL_HEIGHT_16}>{row.invoiceSpName || '-'}</Text>
                      <Text color={ENUM_COLORS.DGREY} font={ENUM_FONTS.CHART_LABEL}>
                        {row.invoiceSku || '-'}
                      </Text>
                    </TableCell>
                    <TableCell>
                      <Text font={ENUM_FONTS.TEXT_SMALL_HEIGHT_16}>
                        {row.invoiceSpQuantity != null ? round(row.invoiceSpQuantity, 3) : '-'}
                      </Text>
                      <Text color={ENUM_COLORS.DGREY} font={ENUM_FONTS.CHART_LABEL}>
                        {row.invoicePackaging || '-'}
                      </Text>
                    </TableCell>
                    <TableCell>
                      {row.invoiceSpPrice != null ? round(row.invoiceSpPrice, 3) : '-'}
                    </TableCell>
                    <TableCell>
                      {row.invoicedTotal != null ? round(row.invoicedTotal, 3) : '-'}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color:
                          row.status === INVOICE_CONTROL_STATUS.NO_GAP
                            ? `${theme.colors.infoGreen}`
                            : `${theme.colors.infoRed}`,
                        font: `${theme.fonts.textSmallHeight14Weight700}`,
                        backgroundColor: `${theme.colors.greys.lightyMedium}`,
                      }}
                    >
                      {row.totalGap != null ? round(row.totalGap, 3) : '-'}
                    </TableCell>
                    <TableCell>
                      <Text font={ENUM_FONTS.TEXT_SMALL_HEIGHT_16}>{row.spName || '-'}</Text>
                      <Text color={ENUM_COLORS.DGREY} font={ENUM_FONTS.CHART_LABEL}>
                        {row.spSku || '-'}
                      </Text>
                    </TableCell>
                    <TableCell>
                      <Text
                        color={
                          round(row.spReceivedQuantity, 3) !== round(row.invoiceSpQuantity, 3)
                            ? ENUM_COLORS.INFO_RED
                            : ENUM_COLORS.DARKEST
                        }
                        font={ENUM_FONTS.TEXT_SMALL_HEIGHT_16}
                      >
                        {row.spReceivedQuantity != null ? round(row.spReceivedQuantity, 3) : '-'}
                      </Text>
                      <Text color={ENUM_COLORS.DGREY} font={ENUM_FONTS.CHART_LABEL}>
                        {row.spPackagingName || '-'}
                      </Text>
                    </TableCell>
                    <TableCell
                      sx={{
                        color:
                          round(row.spPrice, 3) !== round(row.invoiceSpPrice, 3)
                            ? `${theme.colors.infoRed}`
                            : `${theme.colors.greys.darkest}`,
                      }}
                    >
                      {row.spPrice != null ? round(row.spPrice, 3) : '-'}
                    </TableCell>
                    <TableCell>
                      {row.receivedTotal != null ? round(row.receivedTotal, 3) : '-'}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  currency: state.baseReducer.currency,
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceControlLineByLine);
