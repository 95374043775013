import GenericForm from '../GenericForm';
import PropTypes from 'prop-types';
import React from 'react';

import WhiteCard from '../WhiteCard';

const WhiteCardForm = (props) => {
  const {
    form,
    width,
    inputs,
    maxWidth,
    isEditionAllowed,
    customStyle = {},
    formCustomStyle = {},
    shouldDisplayError,
  } = props;

  const renderForm = () => {
    const formattedStyle = {
      flexWrap: 'wrap',
      gap: '24px',
      alignItems: 'flex-start',
      ...formCustomStyle,
    };

    return (
      <GenericForm
        customStyle={formattedStyle}
        form={form}
        inputs={inputs}
        isEditionAllowed={isEditionAllowed}
        shouldDisplayError={shouldDisplayError}
      />
    );
  };

  return (
    <WhiteCard
      {...props}
      customStyle={customStyle}
      maxWidth={maxWidth || '816px'}
      renderContent={renderForm()}
      width={width || '816px'}
    />
  );
};

WhiteCardForm.propTypes = {
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string,
      placeholder: PropTypes.string,
      defaultValue: PropTypes.any,
      rule: PropTypes.any,
    }).isRequired,
  ),
  form: PropTypes.shape().isRequired,
};

export default WhiteCardForm;
