import { connect } from 'react-redux';
import { get, sortBy } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useState, useEffect, useMemo } from 'react';

import { Box, Tab, Tabs } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { showErrorMessage, showMessage } from '@actions/messageconfirmation';

import { Button, Dropdown } from '@commons/utils/styledLibraryComponents';
import { DATAGRID_LOCALES } from '@commons/utils/dataGrid';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import {
  GenericPageContainer,
  GenericGapContainer,
  GenericContentContainer,
} from '@commons/Layout/styledComponents';
import { getUserTimezone } from '@commons/utils/date';
import { LeftRightSplitter } from '@commons/LeftRightSplitter';
import { PeriodDatePicker } from '@commons/DatePickers/PeriodDatePicker';
import Drawer from '@commons/Drawer';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import { usePeriodDatePickerState } from '@hooks/usePeriodDatePickerState';

import { getClientInfo } from '@selectors/client';
import { getPrevHorizon } from '@selectors/featureProps';
import { getSalesPointStores } from '@selectors/stores';

import { forecast as forecastService } from '@services/forecast';
import { product as productService } from '@services/product';
import productionPlanningService from '@services/productionPlanning';

import { COLUMN_TYPES, COLUMNS } from './datagrid/constants';
import { COMMON_DATAGRID_PROPS } from './datagrid/datagridProps';
import { exportProductionPlanning } from './export';
import { generateDates, getColumns } from './getColumns';
import { isCellEditable } from './datagrid/customCellRenders';
import PdfConfig from './PdfConfig';

const PERIOD_NB_DAYS_DEFAULT = 7;
const USER_TIMEZONE = getUserTimezone();
const TODAY = moment.tz(USER_TIMEZONE);

// Displays the content of the selected tab
const TabPanel = ({ children, value, index, ...other }) => (
  <Box
    aria-labelledby={`simple-tab-${index}`}
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    role="tabpanel"
    sx={{ height: 'calc(100% - 120px)', marginTop: '24px', marginBottom: '48px' }}
    {...other}
  >
    {children}
  </Box>
);

const PoSProductionPlanning = (props) => {
  const {
    match,
    prevHorizon,
    client: { defaultTimezone },
    user,
    stores,
    showErrorMessage,
    showMessage,
  } = props;

  const prevHorizonDate = moment.tz(USER_TIMEZONE).add(prevHorizon.future, 'days');

  /*******************
   ***** STATES *****
   *******************/

  const [isLoading, setIsLoading] = useState(false);

  // Header
  const [selectedStore, setSelectedStore] = useState(stores[0]);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const periodPickerState = usePeriodDatePickerState(
    TODAY,
    moment(TODAY).add(PERIOD_NB_DAYS_DEFAULT, 'days'),
    defaultTimezone,
  );
  const [maxFutureDate, setMaxFutureDate] = useState(null);

  const [displayPDFconfig, setDisplayPDFconfig] = useState(false);

  // Tabs management
  const [value, setValue] = useState(0);

  // Datagrid Columns
  const [productsColumns, setProductsColumns] = useState([]);
  const [recipesColumns, setRecipesColumns] = useState([]);
  const [ingredientsColumns, setIngredientsColumns] = useState([]);

  // Keep an array corresponding to the selected period
  const selectedDates = useMemo(() => {
    if (!periodPickerState.startDate || !periodPickerState.endDate) {
      return [];
    }

    return generateDates(periodPickerState.startDate, periodPickerState.endDate);
  }, [periodPickerState.startDate, periodPickerState.endDate]);

  /**
   * Each tab will have its own data, rows and columns (although columns are identical between recipes and ingredients)
   */
  const [productsData, setProductsData] = useState({});
  const [recipesData, setRecipesData] = useState({});
  const [ingredientsData, setIngredientsData] = useState({});

  const [productsRows, setProductsRows] = useState(
    Object.values(productsData).map((product) => product),
  );
  const [recipesRows, setRecipesRows] = useState(
    Object.values(recipesData).map((recipe) => recipe),
  );
  const [ingredientsRows, setIngredientsRows] = useState(
    Object.values(ingredientsData).map((ingredient) => ingredient),
  );

  /*******************
   ***** EFFECTS *****
   *******************/

  // Fetch categories
  useEffect(() => {
    (async () => {
      const { categories } = await productService.getProductsCategories();

      const formattedCategories = categories.map((category) => ({
        ...category,
        name: category.name || i18next.t('GENERAL.NONE_VALUE'),
      }));

      setCategories(formattedCategories);
      setSelectedCategories(formattedCategories);
    })();
  }, [selectedStore]);

  // Max selectable period is 2 weeks, taking max future prev horizon into account
  useEffect(() => {
    const maxEndDate = moment.tz(periodPickerState.startDate, USER_TIMEZONE).add(2, 'weeks');

    setMaxFutureDate(moment.min(prevHorizonDate, maxEndDate));
  }, [periodPickerState.startDate]);

  // Updates the columns when the selected dates change
  useEffect(() => {
    if (!selectedDates.length) {
      return;
    }

    setProductsColumns(getColumns(COLUMN_TYPES.PRODUCTS, selectedDates));
    setRecipesColumns(getColumns(COLUMN_TYPES.RECIPES, selectedDates));
    setIngredientsColumns(getColumns(COLUMN_TYPES.INGREDIENTS, selectedDates));
  }, [selectedDates]);

  // Fetch Production Planning data on store, period and categories change
  useEffect(() => {
    if (
      !selectedStore ||
      !periodPickerState.startDate ||
      !periodPickerState.endDate ||
      !selectedCategories.length
    ) {
      return;
    }

    (async () => {
      setIsLoading(true);

      try {
        const startDate = moment(periodPickerState.startDate).format(
          DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY,
        );
        const endDate = moment(periodPickerState.endDate).format(
          DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY,
        );

        const forecasts = await forecastService.getForecastsOfStore(
          selectedStore.id,
          startDate,
          endDate,
        );
        const savedForecastsDates = forecasts
          .filter(({ type }) => type === 'saved')
          .map(({ timestamp }) =>
            moment(timestamp).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
          );

        const selectedCategoryIds = selectedCategories.map(({ id }) => id);

        const data = await productionPlanningService.getProductionPlanning(selectedStore.id, {
          startDate,
          endDate,
          categoryIds: selectedCategoryIds,
          includeProductionMininum: true,
        });

        setProductsColumns(getColumns(COLUMN_TYPES.PRODUCTS, selectedDates, savedForecastsDates));
        setProductsData(data.products);
        setRecipesData(data.recipes);
        setIngredientsData(data.ingredients);
      } catch {
        setProductsData({});
        setRecipesData({});
        setIngredientsData({});
        showErrorMessage(i18next.t('FORECAST.POS_PRODUCTION_PLANNING.ERROR_FETCHING_DATA'));
      } finally {
        setIsLoading(false);
      }
    })();
  }, [selectedStore, periodPickerState.startDate, periodPickerState.endDate, selectedCategories]);

  // Update rows when data changes (either after API fetch, or after user interaction)
  useEffect(() => {
    setProductsRows(Object.values(productsData));
  }, [productsData]);
  useEffect(() => {
    setRecipesRows(Object.values(recipesData));
  }, [recipesData]);
  useEffect(() => {
    setIngredientsRows(Object.values(ingredientsData));
  }, [ingredientsData]);

  /*******************
   ***** METHODS *****
   *******************/
  const exportPoSProductionPlanning = () => {
    exportProductionPlanning(
      sortBy(productsRows, [COLUMNS.CATEGORY, COLUMNS.NAME]),
      sortBy(recipesRows, [COLUMNS.CATEGORY, COLUMNS.NAME]),
      sortBy(ingredientsRows, [COLUMNS.CATEGORY, COLUMNS.NAME]),
      {
        selectedStore,
        selectedDates,
        userLanguageCode: get(user, 'lnkLanguageAccountrel.code', 'fr'),
      },
    );
  };

  /*******************
   ***** HANDLERS *****
   *******************/
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  /*******************
   ***** OTHER *****
   *******************/

  const actions = [
    {
      label: i18next.t('FORECAST.POS_PRODUCTION_PLANNING.ACTION_XLS_EXPORT'),
      icon: `/images/inpulse/file-download-black-small.svg`,
      handleClick: () => {
        exportPoSProductionPlanning();
      },
    },
    {
      label: i18next.t('FORECAST.POS_PRODUCTION_PLANNING.ACTION_GENERATE_PRODUCTION_PLANNING_PDF'),
      icon: `/images/inpulse/file-download-black-small.svg`,
      handleClick: () => {
        setDisplayPDFconfig(true);
      },
    },
  ];

  const handleUpsertProductionPlanning = async (
    storeId,
    productId,
    modifiedDate,
    updatedQuantity,
    delta,
  ) => {
    setIsLoading(true);

    try {
      // dynamicTot will only be defined if updatedQuantity is null (i.e. the user has deleted forecast of type 'saved')
      const { recipes, ingredients, dynamicTot } =
        await productionPlanningService.upsertProductionProductForecast(storeId, productId, {
          date: modifiedDate,
          tot: updatedQuantity,
          delta,
        });

      const updatedRecipesData = recipesData;
      const updatedIngredientsData = ingredientsData;

      for (const recipeId in recipes) {
        if (!updatedRecipesData[recipeId][modifiedDate]) {
          updatedRecipesData[recipeId][modifiedDate] = recipes[recipeId].quantity;
        } else {
          updatedRecipesData[recipeId][modifiedDate] += recipes[recipeId].quantity;
        }
        updatedRecipesData[recipeId].total += recipes[recipeId].quantity;
      }
      for (const ingredientId in ingredients) {
        if (!updatedIngredientsData[ingredientId][modifiedDate]) {
          updatedIngredientsData[ingredientId][modifiedDate] = ingredients[ingredientId].quantity;
        } else {
          updatedIngredientsData[ingredientId][modifiedDate] += ingredients[ingredientId].quantity;
        }
        updatedIngredientsData[ingredientId].total += ingredients[ingredientId].quantity;
      }

      setRecipesData(updatedRecipesData);
      setIngredientsData(updatedIngredientsData);

      return dynamicTot;
    } catch (error) {
      showErrorMessage(i18next.t('FORECAST.POS_PRODUCTION_PLANNING.ERROR_UPSERTING_DATA'));
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * - Update the productsData state (which will trigger an update for the productsRows)
   *
   * See https://mui.com/x/api/data-grid/data-grid/#data-grid-prop-processRowUpdate for reference
   *
   * @param {*} updatedRow Row object with the new values
   * @param {*} originalRow Row object with the old values
   * @returns The final values to update the row.
   */
  const handleProcessRowUpdate = async (updatedRow, originalRow) => {
    // Need to do a deep copy for the catch block to work properly
    const originalRowClone = { ...originalRow };

    const modifiedDate = selectedDates.find((date) => updatedRow[date] !== originalRow[date]);

    if (!modifiedDate) {
      return originalRow;
    }

    const updatedQuantity = updatedRow[modifiedDate];

    const cellIsPFSaved = updatedRow.savedProductionPlanningDates.includes(modifiedDate);

    if (updatedQuantity === null && !cellIsPFSaved) {
      // updatedQuantity === null => user has deleted the cell's content
      showMessage(
        i18next.t('FORECAST.POS_PRODUCTION_PLANNING.DELETE_DYNAMIC_FORECAST_ERROR'),
        'warning',
      );
      return originalRowClone;
    }

    const oldQuantity = originalRowClone[modifiedDate];

    const hasChanges = updatedQuantity !== oldQuantity;

    if (hasChanges) {
      const delta = Math.round(updatedQuantity - oldQuantity);

      try {
        const dynamicTot = await handleUpsertProductionPlanning(
          selectedStore.id,
          updatedRow.id,
          modifiedDate,
          updatedQuantity,
          delta,
        );

        if (updatedQuantity === null) {
          updatedRow[modifiedDate] = dynamicTot;

          // Remove the modified date from the saved dates
          updatedRow.savedProductionPlanningDates = updatedRow.savedProductionPlanningDates.filter(
            (date) => date !== modifiedDate,
          );

          /**
           * Instead of simply adding the delta here we want to add back the 'dynamic' PF's quantity.
           * Ex:
           *  - If dynamicTot was 100 and user deleted saved of 10 => delta would be -10
           *    But, the dynamicTot should be added back to the delta to get the correct value, i.e 90
           *
           * Ex2:
           *  - If dynamicTot was 10, user deleted saved of 50 => delta would be -50
           *    But, the dynamicTot should be added back to the delta to get the correct value, i.e -40
           */
          updatedRow.total += delta + dynamicTot;
        } else {
          updatedRow[modifiedDate] = updatedQuantity;
          updatedRow.savedProductionPlanningDates.push(modifiedDate);
          updatedRow.total += delta;
        }

        return updatedRow;
      } catch {
        return originalRowClone;
      }
    }

    return originalRowClone;
  };

  return (
    <GenericPageContainer>
      <NavigationBreadCrumb featurePath={match.path} />
      <GenericContentContainer>
        <Box sx={{ paddingBottom: '24px' }}>
          <LeftRightSplitter
            left={
              <GenericGapContainer>
                <Dropdown
                  iconSrc="/images/inpulse/pin-black-small.svg"
                  isDisabled={isLoading}
                  items={stores}
                  searchPlaceholder={i18next.t('GENERAL.SEARCH')}
                  selectedItem={selectedStore}
                  isRequired
                  onSelectionChange={setSelectedStore}
                />
                <PeriodDatePicker
                  disabled={isLoading}
                  endDate={periodPickerState.endDate}
                  focusedDateInput={periodPickerState.focusedDateInput}
                  maxFutureDate={
                    // Allows to re-select a date outside of two weeks range
                    !!periodPickerState.startDate && !!periodPickerState.endDate
                      ? prevHorizonDate
                      : maxFutureDate
                  }
                  setFocusedDateInput={periodPickerState.setFocusedDateInput}
                  startDate={periodPickerState.startDate}
                  timezone={defaultTimezone}
                  onDatesChange={periodPickerState.handleSelectedDates}
                />
                <Dropdown
                  iconSrc="/images/inpulse/category-ipblack-small.svg"
                  isDisabled={isLoading || !categories.length}
                  isUniqueSelection={false}
                  items={categories}
                  searchPlaceholder={i18next.t('GENERAL.SEARCH')}
                  selectedItems={selectedCategories}
                  isRequired
                  onSelectionChange={setSelectedCategories}
                />
              </GenericGapContainer>
            }
            right={
              <Button
                actions={actions}
                color={'inpulse-outline'}
                iconDropdown={'/images/inpulse/carret-black.svg'}
                iconOnLeft={false}
                label={i18next.t('GENERAL.ACTIONS')}
              />
            }
          />
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs aria-label="production planning tabs" value={value} onChange={handleTabChange}>
            <Tab label={i18next.t('GENERAL.PRODUCT_PLURAL')} />
            <Tab label={i18next.t('GENERAL.RECIPE_PLURAL')} />
            <Tab label={i18next.t('GENERAL.INGREDIENT_PLURAL')} />
          </Tabs>
        </Box>
        <TabPanel index={0} value={value}>
          <DataGridPro
            columns={productsColumns}
            isCellEditable={(props) => {
              const {
                field,
                row: { launchDate, endDate },
              } = props;

              return isCellEditable(launchDate, endDate, field);
            }}
            loading={isLoading}
            localeText={
              DATAGRID_LOCALES[user.lnkLanguageAccountrel.code].components.MuiDataGrid.defaultProps
                .localeText
            }
            processRowUpdate={handleProcessRowUpdate}
            rows={productsRows}
            {...COMMON_DATAGRID_PROPS}
          />
        </TabPanel>
        <TabPanel index={1} value={value}>
          <DataGridPro
            columns={recipesColumns}
            loading={isLoading}
            localeText={
              DATAGRID_LOCALES[user.lnkLanguageAccountrel.code].components.MuiDataGrid.defaultProps
                .localeText
            }
            rows={recipesRows}
            {...COMMON_DATAGRID_PROPS}
          />
        </TabPanel>
        <TabPanel index={2} value={value}>
          <DataGridPro
            columns={ingredientsColumns}
            loading={isLoading}
            localeText={
              DATAGRID_LOCALES[user.lnkLanguageAccountrel.code].components.MuiDataGrid.defaultProps
                .localeText
            }
            rows={ingredientsRows}
            {...COMMON_DATAGRID_PROPS}
          />
        </TabPanel>

        <Drawer
          isOpened={displayPDFconfig}
          overlayColor={'rgba(34, 35, 33, 0.64)'}
          overlayOpacity={0.3}
          onClick={() => {
            setDisplayPDFconfig(false);
          }}
        >
          <PdfConfig
            categories={selectedCategories}
            endDate={periodPickerState.endDate}
            startDate={periodPickerState.startDate}
            storeId={selectedStore.id}
            onClose={() => setDisplayPDFconfig(false)}
          />
        </Drawer>
      </GenericContentContainer>
    </GenericPageContainer>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
  prevHorizon: getPrevHorizon(state.baseReducer.userRights),
  stores: getSalesPointStores(state.baseReducer.activeStores),
  user: state.baseReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showMessage(message, type));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PoSProductionPlanning);
