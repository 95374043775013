import axios from '../../core/http';

import { config } from '../../config';

import { APIcallerv2 } from '@services/APIcaller';

const baseApiUrl = `${config.baseApiUrl}/v1`;

export function getNewClientExcelTemplate(clientId) {
  return axios
    .get(`${baseApiUrl}/backoffice/onboarding/client/template?clientId=${clientId}`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export function getStoresExcelTemplate(clientId) {
  return axios
    .get(`${baseApiUrl}/backoffice/onboarding/stores/template?clientId=${clientId}`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export const getFeaturesOfClient = () => APIcallerv2.apiGet('/features');

export function getAllAddableFeatures() {
  return axios
    .get(`${baseApiUrl}/backoffice/features`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export function createFeatureClientMappings(clientId, featureIds) {
  return axios
    .post(`${baseApiUrl}/admin/clients/${clientId}/features`, {
      featureIds,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export function validateNewClientExcelTemplate(data) {
  return axios
    .post(
      `${baseApiUrl}/backoffice/onboarding/client/validate`,
      {
        ...data,
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
      { responseType: 'blob' },
    )
    .then(function (response) {
      return { status: 'success', data: response.data };
    })
    .catch(function (error) {
      if (error.response) {
        return { status: 'error', data: error.response.data };
      }

      throw error;
    });
}

export function validateStoresExcelTemplate(clientId, data) {
  return axios
    .post(
      `${baseApiUrl}/backoffice/onboarding/stores/validate?clientId=${clientId}`,
      {
        ...data,
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
      { responseType: 'blob' },
    )
    .then(function (response) {
      return { status: 'success', data: response.data };
    })
    .catch(function (error) {
      if (error.response) {
        return { status: 'error', data: error.response.data };
      }

      throw error;
    });
}

export function deleteFeatureClientMappingByClientIdAndFeatureId(clientId, featureId) {
  return axios
    .delete(`${baseApiUrl}/admin/clients/${clientId}/features/${featureId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export function importNewClientExcelTemplate(data) {
  return axios
    .post(`${baseApiUrl}/backoffice/onboarding/client/import`, {
      ...data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export function importStoresExcelTemplate(clientId, data) {
  return axios
    .post(`${baseApiUrl}/backoffice/onboarding/stores/import?clientId=${clientId}`, {
      ...data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export function getUsersCreationExcelTemplate(clientId) {
  return axios
    .get(`${baseApiUrl}/backoffice/users/excel?clientId=${clientId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
      responseType: 'blob',
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export function validateAccountsExcel(clientId, roles, languages, accounts, catalogs) {
  return axios
    .post(
      `${baseApiUrl}/backoffice/users/excel/validate?clientId=${clientId}`,
      {
        roles,
        languages,
        accounts,
        catalogs,
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
      { responseType: 'blob' },
    )
    .then(function (response) {
      return { status: 'success', data: response.data };
    })
    .catch(function (error) {
      if (error.response) {
        return { status: 'error', data: error.response.data };
      }

      throw error;
    });
}

export const createUserByBatchRequest = (clientId, roles, languages, accounts, catalogs) =>
  axios
    .post(`${baseApiUrl}/backoffice/users/excel/import?clientId=${clientId}`, {
      roles,
      languages,
      accounts,
      catalogs,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

export const checkEmailAvailability = (email) =>
  axios
    .get(`${baseApiUrl}/backoffice/onboarding/check-email-availability`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
      params: { email },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

export default {
  getFeaturesOfClient,
  validateAccountsExcel,
  getAllAddableFeatures,
  getStoresExcelTemplate,
  getNewClientExcelTemplate,
  importStoresExcelTemplate,
  createFeatureClientMappings,
  validateStoresExcelTemplate,
  importNewClientExcelTemplate,
  validateNewClientExcelTemplate,
  deleteFeatureClientMappingByClientIdAndFeatureId,
  createUserByBatchRequest,
  getUsersCreationExcelTemplate,
  checkEmailAvailability,
};
