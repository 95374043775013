import { connect } from 'react-redux';
import { get, sortBy } from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { Button, Dropdown, INPUT_WIDTH } from '@commons/utils/styledLibraryComponents';
import { formatTextNumberInputValue } from '@commons/utils/format';
import DropdownCountry from '@commons/dropdownCountry';
import TimeZonesDropdown from '@commons/TimezonesDropdown';

import { Input } from '@lib/inpulse/Input';

import { canUpdateAssociatedSupplier } from '@selectors/user';
import { getClientInfo } from '@selectors/client';

import theme from '@theme';

import {
  ALPHABET_DROPDOWN_ITEMS,
  QUARTER_HOURS_DROPDOWN_ITEMS,
  WEEKDAY_DROPDOWN_ITEMS,
} from '@admin/utils/DropdownItems';
import { getLimitOrderDay } from '@admin/utils/date';

import { Label } from '../components/InputEmail/styledComponents';
import ExceptionalDeliveryDays from './ExceptionalDeliveryDays';
import SupplierProfileContactDetails from '../components/SupplierProfileContactDetails';

import {
  BodyRow,
  DeliveryOptionsInputContainer,
  DeliveryOptionsRow,
  ErrorMessage,
  InputContainer,
  WildCard,
} from './styledComponents';
import { canEditSupplierFromDetailView } from '@selectors/actions/supplierActions';
import { getAuthorizedActions } from '@selectors/featureProps';

const SupplierProfileModalInformation = (props) => {
  const {
    currency,
    addDeliveryOption,
    areDeliveryOptionsValid,
    deleteDeliveryOption,
    deliveryOptions,
    handleDeliveryOptionChange,
    handleSupplierProfileChange,
    supplier,
    supplierProfile,
    countryHasUniqueTimezone,
    isEditing,
    exceptionalDeliveryDays,
    handleExceptionalDeliveryDays,
    addExceptionalDeliveryDay,
    deleteExceptionalDeliveryDay,
    client: { hasMultipleTimezones, defaultTimezone, hasLocalCatalogs },
    authorizedActions,
    user,
  } = props;

  const deliveryValidation = areDeliveryOptionsValid();

  const isCreation = !supplierProfile.id;

  const areInputsDisabled =
    !isCreation &&
    (!canUpdateAssociatedSupplier(user, supplier, { hasLocalCatalogs }) ||
      !canEditSupplierFromDetailView(authorizedActions));

  const getOrderTimesLimit = () => {
    const orderTimesLimitWithoutMidnight = QUARTER_HOURS_DROPDOWN_ITEMS.filter(
      (item) => item.value !== '00:00',
    );
    orderTimesLimitWithoutMidnight.unshift({ id: '23:59', value: '23:59' });

    return orderTimesLimitWithoutMidnight;
  };

  const selectedHour = getOrderTimesLimit().find(({ id }) => id === supplierProfile.orderTimeLimit);

  const countryAndTimezoneAreSet = !!supplierProfile.country && !!supplierProfile.timezone;

  const hasDisabledTimezoneDropdown =
    !hasMultipleTimezones ||
    (hasMultipleTimezones &&
      (!supplierProfile.country ||
        (!isCreation && countryAndTimezoneAreSet) ||
        (!!supplierProfile.country && countryHasUniqueTimezone)));

  return (
    <div className="medium-modal-form-main-container">
      <div className="medium-modal-form-category-block">
        <div className="medium-modal-form-category-header">
          {i18next.t('ADMIN.SUPPLIERS.CREATE_SUPPLIER_GENERAL_INFO')}
        </div>
        <div className="medium-modal-form-category-body">
          <BodyRow childCount={2}>
            <div className="medium-modal-form-category-body-row">
              <InputContainer>
                <Input
                  label={i18next.t('ADMIN.SUPPLIER_PRODUCTS.LIST_COLUMN_SUPPLIER')}
                  readOnly={true}
                  type="text"
                  value={supplier.name || ''}
                  disabled
                  onChange={() => {}}
                />
              </InputContainer>
              <InputContainer>
                <Input
                  label={`* ${i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_NAME')}`}
                  placeholder={i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_NAME')}
                  readOnly={areInputsDisabled}
                  type="text"
                  value={supplierProfile.name || ''}
                  onChange={(ev) => handleSupplierProfileChange(ev.target.value, 'name')}
                />
              </InputContainer>
            </div>
          </BodyRow>
        </div>
      </div>
      <div>
        <SupplierProfileContactDetails
          areInputsDisabled={areInputsDisabled}
          handleSupplierProfileChange={handleSupplierProfileChange}
          supplierProfileDetails={supplierProfile}
        />
      </div>
      <div className="medium-modal-form-category-block">
        <div className="medium-modal-form-category-header">
          {i18next.t('ADMIN.SUPPLIERS.SUPPLIER_DETAIL_LOCATION')}
        </div>
        <div className="medium-modal-form-category-body">
          <BodyRow childCount={3}>
            <div className="medium-modal-form-category-body-row">
              <InputContainer>
                <Input
                  label={i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_ADDRESS')}
                  placeholder={i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_ADDRESS')}
                  readOnly={areInputsDisabled}
                  type="text"
                  value={supplierProfile.address || ''}
                  onChange={(ev) => handleSupplierProfileChange(ev.target.value, 'address')}
                />
              </InputContainer>
              <InputContainer>
                <Input
                  label={i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_POSTAL_CODE')}
                  placeholder={i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_POSTAL_CODE')}
                  readOnly={areInputsDisabled}
                  type="text"
                  value={supplierProfile.postCode || ''}
                  onChange={(ev) => handleSupplierProfileChange(ev.target.value, 'postCode')}
                />
              </InputContainer>
              <InputContainer>
                <Input
                  label={i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_CITY')}
                  placeholder={i18next.t('ADMIN.SUPPLIERS.LIST_COLUMN_CITY')}
                  readOnly={areInputsDisabled}
                  type="text"
                  value={supplierProfile.city || ''}
                  onChange={(ev) => handleSupplierProfileChange(ev.target.value, 'city')}
                />
              </InputContainer>
            </div>
          </BodyRow>
          <BodyRow childCount={hasMultipleTimezones ? 2 : 1}>
            <div className="medium-modal-form-category-body-row">
              <InputContainer>
                <Label readOnly={!isCreation || areInputsDisabled}>
                  {hasMultipleTimezones && (
                    <WildCard
                      isDisabled={(!isCreation && countryAndTimezoneAreSet) || areInputsDisabled}
                    >
                      *
                    </WildCard>
                  )}
                  {i18next.t('GENERAL.COUNTRY')} :
                </Label>
                <DropdownCountry
                  handleSelect={(country) => handleSupplierProfileChange(country, 'country')}
                  isDisabled={(!isCreation && countryAndTimezoneAreSet) || areInputsDisabled}
                  isRequired={hasMultipleTimezones}
                  selected={supplierProfile.country}
                  fullWidth
                />
              </InputContainer>
              {hasMultipleTimezones && (
                <InputContainer>
                  <TimeZonesDropdown
                    countryCode={supplierProfile.country}
                    defaultTimezone={
                      !hasMultipleTimezones ? defaultTimezone : supplierProfile.timezone
                    }
                    handleChange={(timezoneName) =>
                      handleSupplierProfileChange(timezoneName, 'timezone')
                    }
                    isDisabled={hasDisabledTimezoneDropdown || areInputsDisabled}
                    isRequired={hasMultipleTimezones}
                    label={i18next.t('GENERAL.TIMEZONE')}
                    labelStyle={{
                      color: hasDisabledTimezoneDropdown
                        ? theme.colors.greys.dark
                        : theme.colors.greys.darkest,
                      font: theme.fonts.textSmall,
                    }}
                  />
                </InputContainer>
              )}
            </div>
          </BodyRow>
        </div>
      </div>
      <div className="medium-modal-form-category-block">
        <div className="medium-modal-form-category-header">
          {i18next.t('ADMIN.SUPPLIERS.ORDER_MINIMUM')}
        </div>
        <div className="medium-modal-form-category-body">
          <BodyRow childCount={2}>
            <div className="medium-modal-form-category-body-row">
              <InputContainer>
                <Input
                  label={`${i18next.t('ADMIN.SUPPLIERS.ORDER_MINIMUM')} (${
                    currency.alphabeticCode
                  })`}
                  placeholder="0"
                  readOnly={areInputsDisabled}
                  type="text"
                  value={supplierProfile.orderMinAmount || ''}
                  onChange={(ev) =>
                    handleSupplierProfileChange(
                      formatTextNumberInputValue(ev.target.value),
                      'orderMinAmount',
                    )
                  }
                />
              </InputContainer>
              <InputContainer>
                <Input
                  label={i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_MINIMUM_ORDER')}
                  placeholder="0"
                  readOnly={areInputsDisabled}
                  type="number"
                  value={supplierProfile.orderMinUnit || ''}
                  onChange={(ev) => handleSupplierProfileChange(ev.target.value, 'orderMinUnit')}
                />
              </InputContainer>
            </div>
          </BodyRow>
        </div>
      </div>
      <div className="medium-modal-form-category-block">
        <div className="medium-modal-form-category-header">
          {i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_DELIVERY')}
        </div>
        <div className="medium-modal-form-category-body">
          <BodyRow childCount={1}>
            <div className="medium-modal-form-category-body-row">
              <InputContainer width={'22%'}>
                <Dropdown
                  customStyle={{ marginBottom: 24 }}
                  isDisabled={areInputsDisabled}
                  isLabelTextSmall={true}
                  isRequired={false}
                  items={getOrderTimesLimit()}
                  label={`${i18next.t('ORDERS.ORDERS.FORM_INFO_MODAL_ORDERING_TIME_DEADLINE')} :`}
                  placeholder={i18next.t('GENERAL.CHOOSE_PLACEHOLDER')}
                  selectedItem={selectedHour}
                  width={INPUT_WIDTH.FULL}
                  onSelectionChange={(selectedItem) => {
                    handleSupplierProfileChange(get(selectedItem, 'value', null), 'orderTimeLimit');
                  }}
                />
              </InputContainer>
            </div>
          </BodyRow>
          {deliveryOptions.map((deliveryOption, index) => {
            const selectedDay = WEEKDAY_DROPDOWN_ITEMS().find(
              ({ id }) => id === deliveryOption.deliveryDay,
            );

            const selectedLetter = ALPHABET_DROPDOWN_ITEMS.find(
              ({ id }) => id === deliveryOption.deliveryLeadTime,
            );

            return (
              <DeliveryOptionsRow key={index}>
                <div className="medium-modal-form-category-body-row">
                  <DeliveryOptionsInputContainer
                    flex="4"
                    marginLeft="3%"
                    marginTop="20px"
                    width="22%"
                  >
                    <Dropdown
                      isDisabled={areInputsDisabled}
                      isLabelTextSmall={true}
                      isRequired={true}
                      items={WEEKDAY_DROPDOWN_ITEMS()}
                      label={`${i18next.t('ORDERS.ORDERS.FORM_INFO_MODAL_DAY_OF_DELIVERY')} :`}
                      placeholder={i18next.t('GENERAL.CHOOSE_PLACEHOLDER')}
                      selectedItem={selectedDay}
                      sortBy={(items) => sortBy(items, (item) => item.id.toLowerCase())}
                      width={INPUT_WIDTH.FULL}
                      onSelectionChange={(selectedItem) => {
                        handleDeliveryOptionChange(selectedItem.id, index, 'deliveryDay');
                      }}
                    />
                  </DeliveryOptionsInputContainer>
                  <DeliveryOptionsInputContainer
                    display="flex"
                    flex="6"
                    marginLeft="3%"
                    width="36%"
                    inpulse
                  >
                    <DeliveryOptionsInputContainer
                      display="flex"
                      flex="3"
                      marginLeft="3%"
                      width="40%"
                    >
                      <Input
                        label={i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_DELIVERY_TIME_CHOICE')}
                        readOnly={true}
                        type="text"
                        value={'A'}
                        disabled
                        onChange={() => {}}
                      />
                    </DeliveryOptionsInputContainer>
                    <DeliveryOptionsInputContainer
                      color="#A8B1C4"
                      flex="1"
                      marginLeft="6%"
                      marginRight="3%"
                      marginTop="60px"
                    >
                      <div
                        style={{
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'end',
                          alignItems: 'center',
                          paddingBottom: '10px',
                        }}
                      >
                        {i18next.t('GENERAL.FOR')}
                      </div>
                    </DeliveryOptionsInputContainer>
                    <DeliveryOptionsInputContainer
                      flex="3"
                      marginLeft="3%"
                      marginTop="45px"
                      width="40%"
                    >
                      <div
                        style={{
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'end',
                        }}
                      >
                        <Dropdown
                          isDisabled={areInputsDisabled}
                          isRequired={true}
                          items={ALPHABET_DROPDOWN_ITEMS}
                          selectedItem={selectedLetter}
                          width={INPUT_WIDTH.FULL}
                          onSelectionChange={(selectedItem, _, isUnselected) => {
                            handleDeliveryOptionChange(
                              isUnselected ? null : selectedItem.id,
                              index,
                              'deliveryLeadTime',
                            );
                          }}
                        />
                      </div>
                    </DeliveryOptionsInputContainer>
                  </DeliveryOptionsInputContainer>
                  <DeliveryOptionsInputContainer flex="4" marginLeft="3%" width="22%">
                    <Input
                      label={i18next.t('ORDERS.ORDERS.FORM_INFO_MODAL_DAY_OF_DELIVERY_DEADLINE')}
                      readOnly={true}
                      type="text"
                      value={getLimitOrderDay(
                        deliveryOption.deliveryDay,
                        deliveryOption.deliveryLeadTime,
                      )}
                      disabled
                      onChange={() => {}}
                    />
                  </DeliveryOptionsInputContainer>
                  {!areInputsDisabled && (
                    <DeliveryOptionsInputContainer>
                      <div
                        style={{
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'end',
                          marginRight: '25px',
                        }}
                      >
                        <Button
                          buttonCustomStyle={{
                            minWidth: 40,
                            width: 40,
                          }}
                          color={'red'}
                          handleClick={() => deleteDeliveryOption(index)}
                          height={40}
                          icon={'/images/inpulse/close-white-small.svg'}
                          iconCustomStyle={{ margin: 'auto', width: '18px' }}
                          label={''}
                        />
                      </div>
                    </DeliveryOptionsInputContainer>
                  )}
                </div>
              </DeliveryOptionsRow>
            );
          })}
          {!areInputsDisabled && (
            <BodyRow childCount={2}>
              <div className="medium-modal-form-category-body-row">
                <InputContainer
                  display="flex"
                  isError={!deliveryValidation.isValid && deliveryValidation.message}
                >
                  <Button
                    buttonCustomStyle={{ paddingLeft: '0px' }}
                    color={'inpulse-outline'}
                    handleClick={() => (deliveryValidation.isValid ? addDeliveryOption() : {})}
                    icon={'/images/inpulse/add-black-small.svg'}
                    isDisabled={!deliveryValidation.isValid}
                    label={i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_ADD_DELIVERY_OPTION')}
                    noBorder
                  />

                  {!deliveryValidation.isValid && deliveryValidation.message && (
                    <ErrorMessage>{deliveryValidation.message}</ErrorMessage>
                  )}
                </InputContainer>
              </div>
            </BodyRow>
          )}
        </div>
      </div>
      {isEditing && (
        <ExceptionalDeliveryDays
          addExceptionalDeliveryDay={addExceptionalDeliveryDay}
          deleteExceptionalDeliveryDay={deleteExceptionalDeliveryDay}
          exceptionalDeliveryDays={exceptionalDeliveryDays}
          handleExceptionalDeliveryDays={handleExceptionalDeliveryDays}
          isReadOnly={areInputsDisabled}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  currency: state.baseReducer.currency,
  client: getClientInfo(state.baseReducer.user),
  authorizedActions: getAuthorizedActions(
    state.baseReducer.userRights,
    '/admin/suppliers/:id/details',
  ),
  user: state.baseReducer.user,
});

export default connect(mapStateToProps)(SupplierProfileModalInformation);
