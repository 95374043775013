import { get } from 'lodash';

import { getUserTimezone } from '@commons/utils/date';

import { inventory as inventoryService } from '@services/inventory';
import { storeSupplierProductMapping as storeSupplierProductMappingService } from '@services/storeSupplierProductMapping';
import { supplierProduct as supplierProductService } from '@services/supplierProduct';
import { supplier as supplierService } from '@services/supplier';

const getStoreSupplierProductMappingsByStoreId = async (storeId, startDate) => {
  try {
    const result = await storeSupplierProductMappingService.getStoreSupplierProductMappingsOfStore(
      storeId,
      startDate,
    );

    return result;
  } catch (err) {
    return null;
  }
};

const sendInventory = async (
  inventoryList,
  inventories,
  recipeInventories,
  triggerComputeOrderAnalytics,
) => {
  const userTimezone = getUserTimezone();

  try {
    if (inventoryList.id) {
      await inventoryService.updateInventory(
        inventoryList,
        inventories,
        recipeInventories,
        triggerComputeOrderAnalytics,
        userTimezone,
      );
    } else {
      await inventoryService.createInventory(
        inventoryList,
        inventories,
        recipeInventories,
        triggerComputeOrderAnalytics,
        userTimezone,
      );
    }

    return { success: true };
  } catch (err) {
    const statusCode = get(err, 'response.data.status');

    // Duplicated inventory on creation
    if (statusCode === 409 && !inventoryList.id) {
      const message = get(err, 'response.data.message', null);

      try {
        const existingInventory = JSON.parse(message);

        if (existingInventory) {
          return {
            success: false,
            lastUpdatedAt: existingInventory.updatedAt || existingInventory.createdAt,
          };
        }

        return { success: false };
      } catch {
        return { success: false };
      }
    }

    return { success: false };
  }
};

const getSuppliersOfClient = async (withLinkedData, filterByUserCatalog) => {
  try {
    const result = await supplierService.getSuppliersOfClient(withLinkedData, filterByUserCatalog);

    return result;
  } catch (err) {
    return null;
  }
};

const getInventoriesFromInventoriesListOfStore = async (storeId, inventoryListId) => {
  try {
    const result = await inventoryService.getInventoriesFromInventoriesListOfStore(
      storeId,
      inventoryListId,
    );

    return result;
  } catch (err) {
    return null;
  }
};

const getSupplierProductsForInventories = async (storeId, inventories) => {
  try {
    const result = await supplierProductService.getSupplierProductsForInventories(
      storeId,
      inventories,
    );

    return result;
  } catch (err) {
    return null;
  }
};

export default {
  sendInventory,
  getSuppliersOfClient,
  getSupplierProductsForInventories,
  getStoreSupplierProductMappingsByStoreId,
  getInventoriesFromInventoriesListOfStore,
};
