import i18next from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';

import { Button, RadioButton, Checkbox } from '@commons/utils/styledLibraryComponents';

import { handleDeletionModal } from '../DeletionModal';

import { handleUpdateModalPackaging } from '../InputPackagingContentModal';

import { Container, Column, ButtonsContainer } from './styledComponents';

export const UPDATE_TYPES = {
  USED_IN_ORDER: 'usedInOrder',
  USED_IN_STOCK: 'usedInStock',
};

export const PACKAGING_MODAL_TYPE = {
  CREATE: 'create',
  UPDATE: 'update',
};

const ListRow = ({
  index,
  updatedPackagings,
  packaging,
  updateUsedInOrderOrInStock,
  packagingUnit,
  updatedPackagingsLength,
  setGenericModalParams,
  handleSupplierProductChange,
  isReadOnly,
  isStockDisabled,
  setCurrentPackaging,
  setPackagingModalType,
  packagings,
  isDisabled,
}) => (
  <Container key={packaging.id}>
    <Column>
      {packaging.name === 'unit' ? i18next.t('ADMIN.INGREDIENTS.LABEL_UNIT') : packaging.name}
    </Column>
    <Column>{packaging.quantity}</Column>
    <Column>
      {packagingUnit === 'unit' ? i18next.t('ADMIN.INGREDIENTS.LABEL_UNIT') : packagingUnit}
    </Column>
    <Column>{packaging.maxOrderQuantity}</Column>
    <Column isButton={true}>
      <RadioButton
        isReadOnly={isReadOnly || isDisabled}
        selected={!!packaging.isUsedInOrder && packaging.id}
        size="small"
        value={packaging.id}
        onChange={() => updateUsedInOrderOrInStock(packaging.id, UPDATE_TYPES.USED_IN_ORDER)}
      />
    </Column>
    <Column isButton={true}>
      <Checkbox
        handleClick={() => updateUsedInOrderOrInStock(packaging.id, UPDATE_TYPES.USED_IN_STOCK)}
        isChecked={packaging.isUsedInStock}
        isDisabled={isStockDisabled || isReadOnly || isDisabled}
        shape="square"
      />
    </Column>
    <ButtonsContainer>
      <Button
        color={'inpulse-outline'}
        handleClick={() => {
          setPackagingModalType(PACKAGING_MODAL_TYPE.UPDATE);
          handleUpdateModalPackaging({
            packagings,
            currentPackaging: packaging,
            setCurrentPackaging,
          });
        }}
        icon={'/images/inpulse/pen-black-small.svg'}
        iconCustomStyle={{ width: '12px', height: '12px' }}
        isDisabled={isDisabled}
      />
      <Button
        color={'inpulse-grey'}
        handleClick={() =>
          handleDeletionModal({
            updatedPackagings,
            packaging,
            setGenericModalParams,
            handleSupplierProductChange,
          })
        }
        icon={'/images/inpulse/trash-white-small.svg'}
        iconCustomStyle={{ width: '12px', height: '12px' }}
        isDisabled={index != updatedPackagingsLength - 1 || isDisabled}
      />
    </ButtonsContainer>
  </Container>
);

ListRow.propTypes = {
  packaging: PropTypes.object.isRequired,
  updateUsedInOrderOrInStock: PropTypes.func.isRequired,
  packagingUnit: PropTypes.string,
  index: PropTypes.number,
  updatedPackagingsLength: PropTypes.number.isRequired,
  isStockDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
};

ListRow.defaultProps = {
  packaging: {},
  updateUsedInOrderOrInStock: () => {},
  updatedPackagingsLength: 0,
  isStockDisabled: false,
  isReadOnly: false,
};

export default ListRow;
