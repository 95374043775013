import { capitalize, get, isEmpty, keyBy } from 'lodash';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

// Design
import {
  ButtonContainer,
  Container,
  ContentContainer,
  DragAndDropContainer,
  DropdownContainer,
  FilterContainer,
  TopContainer,
} from './styledComponents';
import { CreditInfoContainer } from '../invoiceControls/styledComponents';

// Actions
import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

// Services
import { supplier as supplierService } from '@services/supplier';
import { upload as uploadService } from '@services/upload';
import invoiceService from '@services/invoice';

// Selectors
import { getClientInfo } from '@selectors/client';

// Components
import { NavBarContainer } from '@src/routes/invoice/invoiceControlDetails/styledComponents';
import DragAndDropUploader from './components/dragAndDropUploader';
import InvoiceControlFilesList from './components/invoiceControlFilesList';
import StoresCreditDrawerContent from '../invoiceControls/components/StoresCreditDrawerContent';

// Common
import { Button, Dropdown, Tooltip } from '@commons/utils/styledLibraryComponents';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { DEFAULT_TIMEZONE, getStoresMostCommonTimezone } from '@commons/utils/timezone';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { LeftRightSplitter } from '@commons/LeftRightSplitter';
import CustomDrawer from '@commons/Drawer';
import ItemsWithTitleSubtitle from '@commons/DropdownItems/ItemsWithTitleSubtitle';
import NavigationBar from '@commons/NavigationBar';

// Constants
import { FILES_STATUS_CODES, formatBytes } from './utils/fileStatus';

import { INVOICE_STATUS } from '../commons/constants';

// 4 is the optimum batch size for the automatic store detection when we call the data in the back
const MAX_BATCH_SIZE = 4;
const CHECK_DETECTION_STATUS_INTERVAL_TIME = 3000;

/**
 * @typedef {Object} InvoiceControlList
 * @property {string} id
 * @property {string} clientId
 * @property {string} [supplierId] - Might be null if the use has not selected any
 * @property {string} [storeId] - Should be deduced by the detecting store job or selected manually
 * @property {string} link
 * @property {string} status - Match an ICL status
 * @property {string} dataJobId
 */

/**
 * @typedef {Object} InvoiceFile
 * @property {string} id - Unique identifier generated on front side to identify a file
 * @property {File} originalFile
 * @property {string} fileName
 * @property {string} fileSize
 * @property {string} fileStatus
 * @property {InvoiceControlList} [invoiceControlList] - Might be an empty object at first
 * @property {number} batchNumber - Every MAX_BATCH_SIZE files, the batch number is incremented
 * @property {string} link
 */

const InvoiceControlBatchImport = (props) => {
  const {
    // Props
    match: { path },
    // redux states
    client: { clientId, hasLocalCatalogs, storeName },
    stores,
    showErrorMessage,
    pageLoading,
    pageLoaded,
  } = props;

  const history = useHistory();
  const clientStoreNamePlural = getClientStoreNameTranslation(storeName, true);

  /* STATES */
  const [isImportButtonDisabled, setIsImportButtonDisabled] = useState(true);

  // Selectors
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState({});

  // Files
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [filesWithErrors, setFilesWithErrors] = useState([]);
  const [isImporting, setIsImporting] = useState(false);
  const [treatedFilesCount, setTreatedFilesCount] = useState(0);

  // Keep track of batch of uploaded files
  const [uploadQueue, setUploadQueue] = useState([]);

  // Keep track of files that are being uploaded and controlled
  const [tempFiles, setTempsFiles] = useState([]);

  // Drawer states
  const [displayStoresCreditDrawer, setDisplayStoresCreditDrawer] = useState(false);
  const [drawerSubTitle, setDrawerSubTitle] = useState(
    capitalize(moment.tz(DEFAULT_TIMEZONE).format(DATE_DISPLAY_FORMATS.MONTH_YEAR)),
  );
  const [formattedCreditsByStoreId, setFormattedCreditsByStoreId] = useState({});
  const [isDrawerLoading, setIsDrawerLoading] = useState(false);

  const fetchSuppliers = async (storeIds) => {
    try {
      const fetchedSuppliers = await supplierService.getSuppliersOfStores(storeIds);

      const formattedSuppliers = fetchedSuppliers.map((supplier) => ({
        ...supplier,
        ...(hasLocalCatalogs && {
          renderValue: () =>
            ItemsWithTitleSubtitle({
              title: supplier.name,
              subtitle: get(supplier, 'catalog.name', ''),
            }),
        }),
      }));

      setSuppliers(formattedSuppliers);
    } catch {
      showErrorMessage(i18next.t('INVOICE.INVOICE_CONTROLS.SUPPLIERS_FETCH_ERROR'));
    }
  };

  useEffect(() => {
    if (isEmpty(stores)) {
      return;
    }

    const mostImportantTimezone = getStoresMostCommonTimezone(stores);

    setDrawerSubTitle(
      capitalize(moment.tz(mostImportantTimezone.timezone).format(DATE_DISPLAY_FORMATS.MONTH_YEAR)),
    );

    const storeIds = stores.map(({ id }) => id);

    (async () => {
      pageLoading();

      await Promise.all([fetchSuppliers(storeIds), getStoresAvailableCredits()]);

      pageLoaded();
    })();
  }, [stores]);

  useEffect(() => {
    if (!displayStoresCreditDrawer) {
      return;
    }

    getStoresAvailableCredits();
  }, [displayStoresCreditDrawer]);

  useEffect(() => {
    // You should be able to import only if all files are pending (waiting to be imported & controlled)
    const areSomeFilesNotPending = selectedFiles.some(
      ({ fileStatus }) => fileStatus !== FILES_STATUS_CODES.PENDING,
    );

    setIsImportButtonDisabled(
      isEmpty(selectedSupplier) ||
        selectedFiles.length === 0 ||
        isImporting ||
        areSomeFilesNotPending,
    );
  }, [selectedSupplier, selectedFiles, isImporting]);

  // Prompt user before reloading the page, design is not customizable on modern browsers
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!isImporting) {
        return;
      }
      // Perform actions before the component unloads
      event.preventDefault();
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isImporting]);

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (action === 'POP' && isImporting) {
        return window.confirm(i18next.t('INVOICE.INVOICE_CONTROLS.GO_BACK_WARNING'));
      }
    });

    return () => {
      unblock();
    };
  }, [history, isImporting]);

  const getStoresAvailableCredits = async () => {
    setIsDrawerLoading(true);

    try {
      const storeKeyById = keyBy(stores, 'id');

      const creditsByStore = await invoiceService.getStoresAvailableCredits(
        Object.keys(storeKeyById),
      );

      const storeCreditsByStoreId = Object.entries(creditsByStore).reduce(
        (acc, [storeId, credits]) => {
          const store = storeKeyById[storeId];

          if (!store) {
            return acc;
          }

          acc[storeId] = {
            storeName: store.name,
            insufficientCredit: get(
              formattedCreditsByStoreId,
              `[${storeId}].insufficientCredit`,
              false,
            ),
            ...credits,
          };

          return acc;
        },
        {},
      );

      setFormattedCreditsByStoreId(storeCreditsByStoreId);
    } catch {
      showErrorMessage(i18next.t('INVOICE.INVOICE_CONTROLS.FETCH_AVAILABLE_CREDITS_ERROR'));
    } finally {
      setIsDrawerLoading(false);
    }
  };

  const removeFile = async (fileId, invoiceControlListId) => {
    const updatedFiles = selectedFiles.filter(({ id }) => id !== fileId);

    setSelectedFiles(updatedFiles);

    if (!invoiceControlListId) {
      return;
    }

    await deleteICLById(invoiceControlListId, updatedFiles);
  };

  const handleImport = async () => {
    const invoiceControlLists = selectedFiles.map(({ invoiceControlList }) => ({
      id: invoiceControlList.id,
      storeId: invoiceControlList.storeId,
      supplierId: selectedSupplier.id,
      invoiceUrl: invoiceControlList.link,
    }));

    // Set all file statuses to "in progress"
    setSelectedFiles(
      selectedFiles.map((file) => ({ ...file, fileStatus: FILES_STATUS_CODES.IN_PROGRESS })),
    );
    setIsImporting(true);

    try {
      await invoiceService.importInvoiceControlLists(clientId, invoiceControlLists);

      setSelectedFiles(
        selectedFiles.map((file) => ({ ...file, fileStatus: FILES_STATUS_CODES.SUCCESS })),
      );

      setTreatedFilesCount(selectedFiles.length);
    } catch {
      showErrorMessage(i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.IMPORT_FAILURE'));

      setSelectedFiles(
        selectedFiles.map((file) => ({ ...file, fileStatus: FILES_STATUS_CODES.ERROR })),
      );

      setFilesWithErrors(selectedFiles);
    } finally {
      setIsImporting(false);
    }
  };

  useEffect(() => {
    if (!uploadQueue.length && !tempFiles.length) {
      return;
    }

    // Not using groupBy to keep the order
    const tempFilesGroupedByBatchNumber = tempFiles.reduce((acc, file) => {
      if (!acc[file.batchNumber]) {
        acc[file.batchNumber] = [];
      }
      acc[file.batchNumber].push(file);

      return acc;
    }, {});

    const selectedFilesKeyById = keyBy(selectedFiles, 'id');

    if (!Object.keys(tempFilesGroupedByBatchNumber).length) {
      setUploadQueue([]);
      setSelectedFiles([]);
      return;
    }

    // Required to filter out files that have been deleted
    // Since the tempFilesGroupedByBatchNumber has the current files being uploaded,
    // but not necessarily previously uploaded batch, therefore we need to compare with selected files
    const updatedQueue = uploadQueue.map((files) =>
      files.reduce((acc, file) => {
        const selectedFile = selectedFilesKeyById[file.id];

        if (selectedFile) {
          acc.push(selectedFile);
        }

        return acc;
      }, []),
    );

    Object.entries(tempFilesGroupedByBatchNumber).forEach(([index, tempFiles]) => {
      updatedQueue[index] = tempFiles;
    });

    setUploadQueue(updatedQueue);

    const flattenQueue = updatedQueue.flat();

    setSelectedFiles(flattenQueue);

    const checkDetectionStatusInterval = setInterval(() => {
      checkDetectionStatus(flattenQueue);
    }, CHECK_DETECTION_STATUS_INTERVAL_TIME);

    return () => clearInterval(checkDetectionStatusInterval);
  }, [tempFiles]);

  const checkStoresCredits = (allFiles) => {
    if (isEmpty(formattedCreditsByStoreId)) {
      return;
    }

    const storeWithInsufficientCreditsSet = getStoresWithInsufficientCredits(
      allFiles,
      formattedCreditsByStoreId,
    );

    const updatedCreditsByStoreId = { ...formattedCreditsByStoreId };

    const formattedFiles = allFiles.map((file) => {
      const invoiceControlList = file.invoiceControlList;
      const storeId = invoiceControlList.storeId || null;

      if (!storeId) {
        return file;
      }

      if (storeWithInsufficientCreditsSet.has(storeId)) {
        updatedCreditsByStoreId[storeId].insufficientCredit = true;

        return {
          ...file,
          fileStatus: FILES_STATUS_CODES.INSUFFICIENT_CREDITS,
        };
      }

      updatedCreditsByStoreId[storeId].insufficientCredit = false;

      return {
        ...file,
        fileStatus:
          invoiceControlList.status === INVOICE_STATUS.UNCONTROLLED
            ? FILES_STATUS_CODES.PENDING
            : file.fileStatus,
      };
    });

    setFormattedCreditsByStoreId(updatedCreditsByStoreId);
    setTempsFiles(formattedFiles);
  };

  /**
   * Among uploaded files, get those referencing a Store with insufficient credits
   * @param {InvoiceFile[]} files
   * @param {{storeId: {remaining: number;}}}creditsByStoreId
   */
  const getStoresWithInsufficientCredits = (files, creditsByStoreId) => {
    const storeIdsOccurrences = files.reduce((acc, { invoiceControlList }) => {
      const storeId = invoiceControlList.storeId;

      if (!storeId) {
        return acc;
      }

      // Differentiate case where acc[storeId] is 0 and the case where it does not exist
      if (acc[storeId] === undefined) {
        acc[storeId] = 0;
      }

      acc[storeId]++;

      return acc;
    }, {});

    return Object.entries(storeIdsOccurrences).reduce((acc, [storeId, occurrences]) => {
      if (occurrences > creditsByStoreId[storeId].remaining) {
        acc.add(storeId);
      }

      return acc;
    }, new Set());
  };

  /**
   * Check the ICL status to see if it has been updated by the store detection job
   * @param {InvoiceFile[]} allFiles
   * @return {Promise<void>}
   */
  const checkDetectionStatus = async (allFiles) => {
    const filesToCheckStatusOn = allFiles.filter(
      ({ invoiceControlList, fileStatus }) =>
        !isEmpty(invoiceControlList) && fileStatus === FILES_STATUS_CODES.DETECTING_STORE,
    );

    if (!filesToCheckStatusOn.length) {
      return;
    }

    try {
      const iclIds = filesToCheckStatusOn.map(({ invoiceControlList }) => invoiceControlList.id);

      const updatedICLs = await invoiceService.getInvoiceControlListByIds(iclIds);

      if (!updatedICLs.length) {
        return;
      }

      const updatedICLsKeyById = keyBy(updatedICLs, 'id');

      /*
        Not iterating on filesToCheckStatusOn because it will overwrite and delete some of the files in the queue,
        We need to iterate on allFiles to prevent that and simply return the original file if it doesn't have an updated ICL
      */
      const formattedFiles = allFiles.reduce((acc, file) => {
        let fileStatus = file.fileStatus;

        const invoiceControlListId = get(file, 'invoiceControlList.id');

        const associatedUpdatedICL = updatedICLsKeyById[invoiceControlListId];

        if (!associatedUpdatedICL) {
          acc.push(file);
          return acc;
        }

        const statusIsUncontrolled = associatedUpdatedICL.status === INVOICE_STATUS.UNCONTROLLED;

        if (statusIsUncontrolled) {
          fileStatus = !!associatedUpdatedICL.storeId
            ? FILES_STATUS_CODES.PENDING // Set to pending for import
            : FILES_STATUS_CODES.FAILED_DETECTION;
        }

        acc.push({
          ...file,
          invoiceControlList: associatedUpdatedICL,
          fileStatus,
        });

        return acc;
      }, []);

      setTempsFiles(formattedFiles);

      checkStoresCredits(formattedFiles);
    } catch {
      showErrorMessage(i18next.t('INVOICE.INVOICE_CONTROLS.CHECK_DETECTION_STATUS_ERROR'));
    }
  };

  /**
   * Create ICL, which starts store detection job on Data side
   * Following this, we check for ICl statuses on intervals, to check if the status has changed
   * @param {InvoiceFile[]} newlyUploadedFiles
   * @return {Promise<void>}
   */
  const detectStores = async (newlyUploadedFiles) => {
    let filesToDetectStoresFor = [newlyUploadedFiles];

    if (newlyUploadedFiles.length > MAX_BATCH_SIZE) {
      let batchNumber = 0;

      filesToDetectStoresFor = newlyUploadedFiles.reduce((acc, file, index) => {
        if (index % MAX_BATCH_SIZE === 0 && index !== 0) {
          batchNumber++;
        }

        if (!acc[batchNumber]) {
          acc[batchNumber] = [];
        }

        acc[batchNumber].push(file);
        return acc;
      }, []);
    }

    try {
      const storeIds = stores.map(({ id }) => id);

      for (const files of filesToDetectStoresFor) {
        const invoiceUrls = files.reduce((acc, { fileStatus, link }) => {
          if (fileStatus === FILES_STATUS_CODES.ERROR) {
            return acc;
          }

          if (link) {
            acc.push(encodeURI(link));
          }

          return acc;
        }, []);

        const invoiceControlLists = await invoiceService.automaticStoresDetection(
          clientId,
          storeIds,
          selectedSupplier.id,
          invoiceUrls,
        );

        const formattedInvoiceControlLists = invoiceControlLists.map((invoiceControlList) => ({
          ...invoiceControlList,
          link: decodeURI(invoiceControlList.link),
        }));

        const invoiceControlListsByLink = keyBy(formattedInvoiceControlLists, 'link');

        const updatedFiles = files.map((file) => {
          if (file.fileStatus === FILES_STATUS_CODES.ERROR) {
            return file;
          }

          return {
            ...file,
            invoiceControlList: invoiceControlListsByLink[file.link] || {},
          };
        });

        setTempsFiles(updatedFiles);
      }
    } catch {
      const updatedFiles = newlyUploadedFiles.map((file) => ({
        ...file,
        fileStatus: FILES_STATUS_CODES.FAILED_DETECTION,
      }));

      setTempsFiles(updatedFiles);

      showErrorMessage(
        i18next.t('INVOICE.INVOICE_CONTROLS.STORE_DETECTION_ERROR', {
          storeName: clientStoreNamePlural,
        }),
      );
    }
  };

  /**
   * Uploads files to CDN and starts the store detection process
   * @param {InvoiceFile[]} formattedFiles
   * @return {Promise<void>}
   */
  const uploadToCDNAndStartStoresDetection = async (formattedFiles) => {
    const fileUploadPromises = formattedFiles.map(({ originalFile }) =>
      uploadService.uploadFile(originalFile, {
        clientId,
        keepOriginalFileName: true,
        type: 'invoice-control',
      }),
    );

    let newlyUploadedFiles = [];

    try {
      const resolvedPromises = await Promise.allSettled(fileUploadPromises);

      newlyUploadedFiles = formattedFiles.map((file, index) => {
        if (!resolvedPromises[index] || resolvedPromises[index].status === 'rejected') {
          return {
            ...file,
            fileStatus: FILES_STATUS_CODES.ERROR,
          };
        }

        // Order is preserved regardless of the promise resolution
        const { data } = resolvedPromises[index].value;
        const link = get(data, 'fileUri', null);

        return {
          ...file,
          link,
          fileStatus: FILES_STATUS_CODES.DETECTING_STORE,
        };
      });

      setTempsFiles(newlyUploadedFiles);

      detectStores(newlyUploadedFiles);
    } catch {
      newlyUploadedFiles = formattedFiles.map((file) => ({
        ...file,
        fileStatus: FILES_STATUS_CODES.ERROR,
      }));

      setTempsFiles(newlyUploadedFiles);
    }
  };

  const handleDrawerClose = () => {
    setDisplayStoresCreditDrawer(false);
  };

  const formatFile = (file, batchNumber) => ({
    id: crypto.randomUUID(),
    originalFile: file,
    fileName: file.name,
    fileSize: formatBytes(file.size),
    fileStatus: FILES_STATUS_CODES.WAITING,
    invoiceControlList: {},
    batchNumber,
  });

  const selectFiles = (files) => {
    let batchNumber = uploadQueue.length;

    let formattedFiles;

    if (files.length <= MAX_BATCH_SIZE) {
      formattedFiles = files.map((file) => formatFile(file, batchNumber));
    } else {
      formattedFiles = files.map((file, index) => {
        if (index % MAX_BATCH_SIZE === 0 && index !== 0) {
          batchNumber++;
        }

        return formatFile(file, batchNumber);
      });
    }

    setTempsFiles(formattedFiles);

    uploadToCDNAndStartStoresDetection(formattedFiles);
  };

  const deleteICLById = async (invoiceControlListById, updatedFiles) => {
    try {
      await invoiceService.deleteById(invoiceControlListById);

      // Update files status because we removed an ICL and status 'INSUFFICIENT_CREDITS' can be transformed to 'PENDING' for some other files
      checkStoresCredits(updatedFiles);
    } catch (err) {
      showErrorMessage(i18next.t('INVOICE.INVOICE_CONTROLS.ACTION_DELETE_INVOICE_ERROR'));
    }
  };

  const handleStoreSelection = (fileId, storeId) => {
    const updatedFiles = selectedFiles.map((file) => {
      if (file.id === fileId) {
        return {
          ...file,
          invoiceControlList: {
            ...file.invoiceControlList,
            storeId,
          },
        };
      }

      return file;
    });

    setSelectedFiles(updatedFiles);
    checkStoresCredits(updatedFiles);
  };

  return (
    <>
      <NavBarContainer>
        <NavigationBar
          disableGoBackButton={isImporting}
          displaySubFeatures={false}
          invoiceControlList={{ name: i18next.t('FEATURE.INVOICE.INVOICE_CONTROL_BATCH_IMPORT') }}
          path={path}
          bigTopBar
        />
      </NavBarContainer>
      <Container>
        <TopContainer>
          <LeftRightSplitter
            height={'66px'}
            left={
              <FilterContainer>
                <DropdownContainer>
                  <Dropdown
                    height={'66px'}
                    iconSrc={'/images/inpulse/app-black-small.svg'}
                    isDisabled={isImporting}
                    items={suppliers}
                    label={`${i18next.t('GENERAL.SUPPLIER')} :`}
                    searchPlaceholder={i18next.t('GENERAL.SEARCH')}
                    selectedItem={selectedSupplier}
                    isRequired
                    onSelectionChange={setSelectedSupplier}
                  />
                </DropdownContainer>

                <CreditInfoContainer>
                  <Button
                    buttonCustomStyle={{ padding: '0px' }}
                    color={'inpulse-outline'}
                    handleClick={() => setDisplayStoresCreditDrawer(true)}
                    icon={'/images/inpulse/list-bordered-black.svg'}
                    iconCustomStyle={{ width: '24px', height: '24px' }}
                    iconOnLeft={false}
                    label={i18next.t('INVOICE.INVOICE_CONTROLS.VIEW_CREDITS')}
                    noBorder
                  />
                </CreditInfoContainer>
              </FilterContainer>
            }
            right={
              <ButtonContainer>
                {isEmpty(selectedSupplier) && selectedFiles.length > 0 ? (
                  <Tooltip
                    renderTooltipTrigger={() => (
                      <Button
                        icon={'/images/inpulse/file-download-white-small.svg'}
                        isDisabled={true}
                        label={i18next.t('GENERAL.IMPORT')}
                      />
                    )}
                    text={i18next.t('INVOICE.INVOICE_CONTROLS.TOOLTIP_SELECT_A_SUPPLIER')}
                  />
                ) : (
                  <Button
                    handleClick={handleImport}
                    icon={'/images/inpulse/file-download-white-small.svg'}
                    isDisabled={isImportButtonDisabled}
                    label={i18next.t('GENERAL.IMPORT')}
                  />
                )}
              </ButtonContainer>
            }
            width={'100%'}
          />
        </TopContainer>
        <ContentContainer>
          <DragAndDropContainer>
            <DragAndDropUploader
              clientStoreName={getClientStoreNameTranslation(storeName)}
              filesWithErrors={filesWithErrors}
              hasEndedImport={
                !!treatedFilesCount && treatedFilesCount >= selectedFiles.length && !isImporting
              }
              isImporting={isImporting}
              selectedFiles={selectedFiles}
              selectFiles={selectFiles}
            />
          </DragAndDropContainer>
          <InvoiceControlFilesList
            handleStoreSelection={handleStoreSelection}
            isImporting={isImporting}
            removeFile={removeFile}
            selectedFiles={selectedFiles}
          />
        </ContentContainer>
        <CustomDrawer
          isOpened={displayStoresCreditDrawer}
          overlayOpacity={0}
          onClick={handleDrawerClose}
        >
          <StoresCreditDrawerContent
            credits={Object.values(formattedCreditsByStoreId)}
            isLoading={isDrawerLoading}
            subtitle={drawerSubTitle}
            onClose={handleDrawerClose}
          />
        </CustomDrawer>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
  stores: state.baseReducer.activeStores,
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceControlBatchImport);
