import i18next from 'i18next';
import normalizeStringValue from '@commons/utils/normalizeStringValue';

const getSupplierProfileSheetConfig = () => {
  const config = {
    SHEET: {
      ID: 'supplierProfiles',
      NAME: i18next.t('_BACKEND_TRANSLATIONS.SUPPLIER_PROFILES.IMPORTS.SHEET_NAME'),
    },
    COLUMN_IDS: {
      SUPPLIER: 'supplier',
      SUPPLIER_PROFILE_NAME: 'supplierProfileName',
      CONTACT_NAME: 'contactName',
      ORDER_EMAIL: 'orderEmail',
      CREDIT_EMAIL: 'creditEmail',
      BCC_EMAILS: 'bccEmails',
      PHONE: 'phone',
      ADDRESS: 'address',
      POSTAL_CODE: 'postalCode',
      CITY: 'city',
      COUNTRY: 'country',
      TIMEZONE: 'timezone',
      ORDER_MIN_AMOUNT: 'orderMinAmount',
      ORDER_MIN_UNIT: 'orderMinUnit',
      MONDAY_DELIVERY: 'mondayDelivery',
      TUESDAY_DELIVERY: 'tuesdayDelivery',
      WEDNESDAY_DELIVERY: 'wednesdayDelivery',
      THURSDAY_DELIVERY: 'thursdayDelivery',
      FRIDAY_DELIVERY: 'fridayDelivery',
      SATURDAY_DELIVERY: 'saturdayDelivery',
      SUNDAY_DELIVERY: 'sundayDelivery',
      ORDER_TIME_LIMIT: 'orderTimeLimit',
    },
  };

  const columns = {
    SUPPLIER: i18next.t('_BACKEND_TRANSLATIONS.SUPPLIER_PROFILES.IMPORTS.SUPPLIER'),
    SUPPLIER_PROFILE_NAME: i18next.t(
      '_BACKEND_TRANSLATIONS.SUPPLIER_PROFILES.IMPORTS.SUPPLIER_PROFILE_NAME',
    ),
    CONTACT_NAME: i18next.t('_BACKEND_TRANSLATIONS.SUPPLIER_PROFILES.IMPORTS.CONTACT_NAME'),
    ORDER_EMAIL: i18next.t('_BACKEND_TRANSLATIONS.SUPPLIER_PROFILES.IMPORTS.ORDER_EMAIL'),
    CREDIT_EMAIL: i18next.t('_BACKEND_TRANSLATIONS.SUPPLIER_PROFILES.IMPORTS.CREDIT_REQUEST_EMAIL'),
    BCC_EMAILS: i18next.t('_BACKEND_TRANSLATIONS.SUPPLIER_PROFILES.IMPORTS.BCC_EMAILS'),
    PHONE: i18next.t('_BACKEND_TRANSLATIONS.SUPPLIER_PROFILES.IMPORTS.PHONE'),
    ADDRESS: i18next.t('_BACKEND_TRANSLATIONS.SUPPLIER_PROFILES.IMPORTS.ADDRESS'),
    POSTAL_CODE: i18next.t('_BACKEND_TRANSLATIONS.SUPPLIER_PROFILES.IMPORTS.POSTAL_CODE'),
    CITY: i18next.t('_BACKEND_TRANSLATIONS.SUPPLIER_PROFILES.IMPORTS.CITY'),
    COUNTRY: i18next.t('_BACKEND_TRANSLATIONS.SUPPLIER_PROFILES.IMPORTS.COUNTRY'),
    TIMEZONE: i18next.t('_BACKEND_TRANSLATIONS.SUPPLIER_PROFILES.IMPORTS.TIMEZONE'),
    ORDER_MIN_AMOUNT: i18next.t(
      '_BACKEND_TRANSLATIONS.SUPPLIER_PROFILES.IMPORTS.MINIMUM_ORDER_AMOUNT',
    ),
    ORDER_MIN_UNIT: i18next.t(
      '_BACKEND_TRANSLATIONS.SUPPLIER_PROFILES.IMPORTS.MINIMUM_ORDER_PACKAGES',
    ),
    MONDAY_DELIVERY: i18next.t('_BACKEND_TRANSLATIONS.SUPPLIER_PROFILES.IMPORTS.DELIVERY_MONDAY'),
    TUESDAY_DELIVERY: i18next.t('_BACKEND_TRANSLATIONS.SUPPLIER_PROFILES.IMPORTS.DELIVERY_TUESDAY'),
    WEDNESDAY_DELIVERY: i18next.t(
      '_BACKEND_TRANSLATIONS.SUPPLIER_PROFILES.IMPORTS.DELIVERY_WEDNESDAY',
    ),
    THURSDAY_DELIVERY: i18next.t(
      '_BACKEND_TRANSLATIONS.SUPPLIER_PROFILES.IMPORTS.DELIVERY_THURSDAY',
    ),
    FRIDAY_DELIVERY: i18next.t('_BACKEND_TRANSLATIONS.SUPPLIER_PROFILES.IMPORTS.DELIVERY_FRIDAY'),
    SATURDAY_DELIVERY: i18next.t(
      '_BACKEND_TRANSLATIONS.SUPPLIER_PROFILES.IMPORTS.DELIVERY_SATURDAY',
    ),
    SUNDAY_DELIVERY: i18next.t('_BACKEND_TRANSLATIONS.SUPPLIER_PROFILES.IMPORTS.DELIVERY_SUNDAY'),
    ORDER_TIME_LIMIT: i18next.t('_BACKEND_TRANSLATIONS.SUPPLIER_PROFILES.IMPORTS.ORDER_DEADLINE'),
  };

  return {
    ...config,
    NORMALIZED_COLUMN_IDS: normalizeKeys(columns),
  };
};

const getSuppliersSheetConfig = () => ({
  SHEET: {
    ID: 'suppliers',
    NAME: i18next.t('_BACKEND_TRANSLATIONS.SUPPLIER_PROFILES.IMPORTS.SUPPLIERS_SHEET_NAME'),
  },
  COLUMN_IDS: {
    ID: 'id',
    NAME: 'name',
  },
  NORMALIZED_COLUMN_IDS: {
    id: 'supplierid',
    name: normalizeStringValue(
      i18next.t('_BACKEND_TRANSLATIONS.SUPPLIER_PROFILES.IMPORTS.GENERIC_NAME'),
    ),
  },
});

const normalizeKeys = (object) =>
  Object.entries(object).reduce((acc, [key, value]) => {
    acc[key] = normalizeStringValue(value);
    return acc;
  }, {});

export default {
  getSupplierProfileSheetConfig,
  getSuppliersSheetConfig,
};
