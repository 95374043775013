import i18next from 'i18next';

import { userHasRoleInpulse } from '@commons/utils/roles';

import { getClientStoreNameTranslation } from '../../../utils/translations';

export const DETAILS = {
  isFullScreen: true,
  shouldReplacePath: true,
  getText: () => i18next.t('ADMIN.SUB_FEATURES.INFORMATIONS'),
  path: '/admin/suppliers/:id/details',
  getLink: ({ supplierId }) => `/admin/suppliers/${supplierId}/details`,
};
export const SUPPLIER_PROFILES = {
  isFullScreen: true,
  shouldReplacePath: true,
  getText: () => i18next.t('FEATURE_NAME_ADMIN_SUPPLIERS_SUPPLIER_PROFILES'),
  path: '/admin/suppliers/:id/supplier-profiles',
  getLink: ({ supplierId }) => `/admin/suppliers/${supplierId}/supplier-profiles`,
};
export const STORES = {
  isFullScreen: true,
  shouldReplacePath: true,
  getText: ({ storeName }) =>
    i18next.t('ADMIN.SUB_FEATURES.MAPPED_STORES', {
      storeNamePlural: getClientStoreNameTranslation(storeName, true),
    }),
  path: '/admin/suppliers/:id/associated-stores',
  getLink: ({ supplierId }) => `/admin/suppliers/${supplierId}/associated-stores`,
};
export const CUSTOM_PROMPT = {
  isFullScreen: true,
  shouldReplacePath: true,
  getText: () => i18next.t('ADMIN.SUB_FEATURES.CUSTOM_PROMPT'),
  path: '/admin/suppliers/:id/custom-prompt',
  getLink: ({ supplierId }) => `/admin/suppliers/${supplierId}/custom-prompt`,
  isVisible: ({ user }) => userHasRoleInpulse(user),
};
