import InternalInvoiceCreation from './internalInvoices/creation';
import InternalInvoiceDetails from './internalInvoices/details';
import InternalInvoices from './internalInvoices';
import InvoiceControlBatchImport from './invoiceControlBatchImport';
import InvoiceControlDetails from './invoiceControlDetails';
import InvoiceControlLineByLine from './invoiceControlLineByLine';
import InvoiceControls from './invoiceControls';

const InvoiceModule = {
  InvoiceControls,
  InvoiceControlDetails,
  InvoiceControlLineByLine,
  InvoiceControlBatchImport,

  InternalInvoices,
  InternalInvoiceCreation,
  InternalInvoiceDetails,
};

export default InvoiceModule;
