const envApiUriMapping = {
  dev: 'http://localhost:5000',
  test: 'https://api-test.inpulse.ai',
  demo: 'https://api-demo.deepsight.io',
  staging: 'https://api-staging.inpulse.ai',
  prod: 'https://api.deepsight.io',
};

const envAppUriMapping = {
  dev: 'http://localhost:4000/',
  test: 'https://app-test.inpulse.ai/',
  staging: 'https://app-staging.inpulse.ai/',
  prod: 'https://app.inpulse.ai/',
};

const cryptrEnvMapping = {
  dev: {
    REACT_APP_CRYPTR_AUDIENCE: 'http://localhost:4000',
    REACT_APP_CRYPTR_BASE_URL: 'https://inpulse.authent.me',
    REACT_APP_CRYPTR_CLIENT_ID: '2266081f-17a6-42ed-a1c7-166a89878308',
    REACT_APP_CRYPTR_DEFAULT_REDIRECT_URI: 'http://localhost:4000/login',
    REACT_APP_CRYPTR_TENANT_DOMAIN: 'inpulse',
  },
  test: {
    REACT_APP_CRYPTR_AUDIENCE: 'https://app-test.inpulse.ai',
    REACT_APP_CRYPTR_BASE_URL: 'https://inpulse.authent.me',
    REACT_APP_CRYPTR_CLIENT_ID: '2266081f-17a6-42ed-a1c7-166a89878308',
    REACT_APP_CRYPTR_DEFAULT_REDIRECT_URI: 'https://app-test.inpulse.ai/login',
    REACT_APP_CRYPTR_TENANT_DOMAIN: 'inpulse',
  },
  staging: {
    REACT_APP_CRYPTR_AUDIENCE: 'https://app-staging.inpulse.ai',
    REACT_APP_CRYPTR_BASE_URL: 'https://inpulse.authent.me',
    REACT_APP_CRYPTR_CLIENT_ID: '2266081f-17a6-42ed-a1c7-166a89878308',
    REACT_APP_CRYPTR_DEFAULT_REDIRECT_URI: 'https://app-staging.inpulse.ai/login',
    REACT_APP_CRYPTR_TENANT_DOMAIN: 'inpulse',
  },
  prod: {
    REACT_APP_CRYPTR_AUDIENCE: 'https://app.inpulse.ai',
    REACT_APP_CRYPTR_BASE_URL: 'https://inpulse.authent.me',
    REACT_APP_CRYPTR_CLIENT_ID: 'de193f1d-d70e-443f-9c19-1c99eab6d72d',
    REACT_APP_CRYPTR_DEFAULT_REDIRECT_URI: 'https://app.inpulse.ai/login',
    REACT_APP_CRYPTR_TENANT_DOMAIN: 'inpulse',
  },
};

const sleekplanProductId = 844756167;

let baseApiUrl = '';
let baseAppUrl = '';
let cryptrConfig = {};
let sleekplanConfig = {};

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_BUILD_ENV === 'production') {
  baseApiUrl = envApiUriMapping['prod'];
  baseAppUrl = envAppUriMapping['prod'];
  cryptrConfig = cryptrEnvMapping['prod'];
  sleekplanConfig.productId = sleekplanProductId;
} else if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_BUILD_ENV === 'demo') {
  baseApiUrl = envApiUriMapping['demo'];
  sleekplanConfig.productId = sleekplanProductId;
} else if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_BUILD_ENV === 'staging') {
  baseApiUrl = envApiUriMapping['staging'];
  baseAppUrl = envAppUriMapping['staging'];
  sleekplanConfig.productId = sleekplanProductId;
  cryptrConfig = cryptrEnvMapping['staging'];
} else if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_BUILD_ENV === 'test') {
  baseApiUrl = envApiUriMapping['test'];
  baseAppUrl = envAppUriMapping['test'];
  cryptrConfig = cryptrEnvMapping['test'];
  sleekplanConfig.productId = sleekplanProductId;
} else {
  baseApiUrl = envApiUriMapping['dev']; // Change here to put staging or dev
  baseAppUrl = envAppUriMapping['dev']; // Change here to put staging or dev
  cryptrConfig = cryptrEnvMapping['dev'];
  // Uncomment for testing purposes
  // sleekplanConfig.productId = sleekplanProductId;
}

export const config = {
  baseApiUrl,
  baseAppUrl,
  isProduction:
    process.env.NODE_ENV === 'production' && process.env.REACT_APP_BUILD_ENV === 'production',
  limitResponseTime:
    process.env.HEALTH_STATUS_LIMIT_RESPONSE_TIME_TO_CONSIDER_SLOW_CONNECTION_IN_MS || 2000,
  cryptr: cryptrConfig,
  sleekplan: sleekplanConfig,
};
