import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;

  margin-top: 104px;

  width: 100%;
  height: calc(100% - 104px);

  overflow: auto;

  padding: 24px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;

  height: 40px;
`;

export const HeaderLeftContainer = styled.div`
  width: 240px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  gap: 16px;
`;

export const ContentHeaderButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const CustomPromptContainer = styled.div`
  width: 50%;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;

  background-color: ${(props) => props.theme.colors?.greys?.lightest};
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
`;
