import { sortArrayOfObjectsAlphabetically } from '@commons/utils/sorting';

import centralService from '@services/central';
import clientService from '@services/client';
import recipeService from '@services/recipe';

import { CATEGORY_TYPES_OBJECT, getPropertyNoneValue } from '@commons/constants/categoryTypes';

import utilsMethods from '../utils';

/**********************/
/* Fetch Data Methods */
/**********************/

/**
 * Retrieve the information related to recipe associated to the given recipeId
 *
 * @param {String} recipeId                         - The recipe id on which retrieve the data
 * @param {Function} onRecipeChange                 - Method to set the local state of the recipe
 * @param {Function} onCompositionSettingsChange    - Method to set the local state of the compositionSettings
 * @param {Function} onAllergensChange              - Method to set the local state of the allergens
 *
 * @returns {void}
 */
export async function fetchRecipe(
  recipeId,
  onRecipeChange,
  onCompositionSettingsChange,
  onAllergensChange,
) {
  const result = await recipeService.getRecipe(recipeId);

  onRecipeChange(result.recipe);
  onCompositionSettingsChange(utilsMethods.sortCompositionSettings(result.compositionSettings));
  onAllergensChange(result.allergens);
}

/**
 * Retrieve the information related to the recipes by categories associated to the given clientId
 *
 * @param {String} clientId                     - The client id on which retrieve the data
 * @param {Function} onRecipesAvailableChange   - Method to set the local state of the recipesAvailable
 * @param {Function} setRecipeCategories   - Method to set the local state of the recipeCategories
 *
 * @returns {void}
 */
export async function fetchRecipesByCategories(
  clientId,
  onRecipesAvailableChange,
  setRecipeCategories,
  isKitchen = false,
) {
  const result = isKitchen
    ? await centralService.getKitchenRecipesByCategoriesForRecipeComposition()
    : await recipeService.getRecipesByCategoriesForRecipeComposition();

  const { categories } = await clientService.getCategoriesAndSubCategories(
    isKitchen ? CATEGORY_TYPES_OBJECT.CENTRAL_KITCHEN_RECIPE : CATEGORY_TYPES_OBJECT.RECIPE,
  );

  const formattedCategories = categories.map((category) => ({
    ...category,
    name: category.name || getPropertyNoneValue(),
  }));

  setRecipeCategories(formattedCategories);

  onRecipesAvailableChange(result);
}

/**
 * Retrieve the parents of a recipe associated to the given recipeId
 *
 * @param {String} recipeId   - The recipe id on which retrieve the data
 *
 * @returns {void}
 */
export async function fetchRecipeParents(recipeId, onEntitiesMappedChange) {
  const result = await recipeService.getRecipeParents(recipeId);

  const resultSorted = sortArrayOfObjectsAlphabetically(result, 'name');

  onEntitiesMappedChange(resultSorted);
}
