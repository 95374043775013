import i18next from 'i18next';
import parse from 'html-react-parser';
import React, { useEffect, useState } from 'react';

import { Checkbox, Tooltip } from '@commons/utils/styledLibraryComponents';
import { DeepsightComponentLoader } from '@commons/DeepsightComponents';
import { getTheme } from '@commons/utils/theme';
import Text from '@commons/Text';
import WhiteCard from '@commons/WhiteCard';

import internalInvoiceService from '@services/internalInvoice';

import InputEmailChip from '@lib/inpulse/InputEmailChip';

import { canCreateIntraCommunityInvoice } from '@selectors/actions/internalInvoiceActions';
import {
  CheckBoxContainer,
  EmailContainer,
  OrderCartContainer,
  TitleWhiteCard,
  WhiteCardContainer,
} from './styledComponents';
import { connect } from 'react-redux';
import { getAuthorizedActions } from '@selectors/featureProps';

const InternalInvoiceSummary = (props) => {
  const {
    associatedOrders,
    emailRecipients,
    setEmailRecipients,
    emailRecipientsCopy,
    setEmailRecipientsCopy,
    showErrorMessage,
    internalInvoiceForm,
    authorizedActions,
  } = props;

  const theme = getTheme();

  const [intraCommunitySupply, setIntraCommunitySupply] = useState(false);
  const [internalInvoicePreview, setInternalInvoicePreview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const intraCommunitySupplyRight = canCreateIntraCommunityInvoice(authorizedActions);

  useEffect(() => {
    // Emails might be configured for the store, so make sure the component state AND the form state are updated.
    // if (typeof emailRecipients === 'string' && typeof emailRecipientsCopy === 'string') {
    //   // A type string implies the values were just recovered, so they need to be split and set.
    handleEmails(emailRecipients, setEmailRecipients, true);
    handleEmails(emailRecipientsCopy, setEmailRecipientsCopy, false);
    // }
  }, [emailRecipients, emailRecipientsCopy]);

  useEffect(() => {
    (async () => {
      const orderIds = associatedOrders.map(({ id }) => id);

      if (!orderIds.length) {
        return;
      }

      setIsLoading(true);

      try {
        const htmlPreview = await internalInvoiceService.generateInternalInvoicePreview(
          orderIds,
          intraCommunitySupply,
        );

        setInternalInvoicePreview(htmlPreview);
      } catch {
        showErrorMessage(i18next.t('INVOICE.INTERNAL_INVOICES.ERROR_GENERATING_PREVIEW'));
      } finally {
        setIsLoading(false);
      }
    })();
  }, [associatedOrders, intraCommunitySupply]);

  const handleEmails = (emails, setEmails, isRecipient) => {
    // InputEmailChip will return a string with the emails separated by commas so the split is left to the save process

    setEmails(emails);

    isRecipient
      ? internalInvoiceForm.setValue('recipientEmails', emails)
      : internalInvoiceForm.setValue('copyEmails', emails);
  };

  const customWhiteCardStyle = { border: `1px solid ${theme.colors.greys.light}` };

  return (
    <OrderCartContainer>
      <WhiteCardContainer width={'65%'}>
        <WhiteCard
          customStyle={customWhiteCardStyle}
          maxWidth={'inherit'}
          renderContent={
            <>
              <TitleWhiteCard>{i18next.t('INVOICE.INTERNAL_INVOICES.SUMMARY')}</TitleWhiteCard>
              {isLoading || !internalInvoicePreview ? (
                <DeepsightComponentLoader />
              ) : (
                <>{parse(internalInvoicePreview)}</>
              )}
            </>
          }
          width={'100%'}
        />
      </WhiteCardContainer>
      <WhiteCardContainer width={'35%'}>
        <WhiteCard
          customStyle={customWhiteCardStyle}
          renderContent={
            <>
              <TitleWhiteCard>{i18next.t('INVOICE.INTERNAL_INVOICES.SEND')}</TitleWhiteCard>
              <EmailContainer>
                <InputEmailChip
                  emails={emailRecipients}
                  placeholder={i18next.t('ORDERS.ORDERS.CART_RECIPIENTS')}
                  setEmails={(emails) => handleEmails(emails, setEmailRecipients, true)}
                  title={i18next.t('ORDERS.ORDERS.CART_RECIPIENTS')}
                  width={'100%'}
                  required
                />
              </EmailContainer>
              <EmailContainer>
                <InputEmailChip
                  emails={emailRecipientsCopy}
                  placeholder={i18next.t('ORDERS.ORDERS.CART_RECIPIENTS_COPIES')}
                  setEmails={(emails) => handleEmails(emails, setEmailRecipientsCopy, false)}
                  title={i18next.t('ORDERS.ORDERS.CART_RECIPIENTS_COPIES')}
                  width={'100%'}
                />
              </EmailContainer>

              {intraCommunitySupplyRight && (
                <CheckBoxContainer
                  onClick={() => {
                    setIntraCommunitySupply(!intraCommunitySupply);
                    internalInvoiceForm.setValue('intraCommunitySupply', !intraCommunitySupply);
                  }}
                >
                  <Checkbox isChecked={intraCommunitySupply} shape={'square'} noSize />
                  <Text>{i18next.t('INVOICE.INTERNAL_INVOICES.INTRA_COMMUNITY_SUPPLY')}</Text>
                  <Tooltip
                    className="tooltip"
                    text={i18next.t('INVOICE.INTERNAL_INVOICES.INTRA_COMMUNITY_SUPPLY_TOOLTIP')}
                    displayBigger
                  />
                </CheckBoxContainer>
              )}
            </>
          }
          width={'100%'}
        />
      </WhiteCardContainer>
    </OrderCartContainer>
  );
};

const mapStateToProps = (state) => ({
  authorizedActions: getAuthorizedActions(
    state.baseReducer.userRights,
    '/invoice/internal-invoices/create',
  ),
});

export default connect(mapStateToProps)(InternalInvoiceSummary);
