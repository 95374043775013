import { sortBy } from 'lodash';
import i18next from 'i18next';

import utilsXLS from '@commons/utils/makeXLS';

const createMainSheet = (bufferStocks) => {
  const columns = [
    {
      propertyKey: 'ingredientName',
      name: i18next.t('GENERAL.INGREDIENT_NAME'),
    },
    {
      propertyKey: 'category',
      name: i18next.t('GENERAL.CATEGORY'),
    },
    {
      propertyKey: 'nbDays',
      name: i18next.t('GENERAL.BUFFER_STOCK'),
    },
  ];

  const sortedBufferStocks = sortBy(bufferStocks, 'ingredientName');

  return utilsXLS.generateDefaultSheet(
    i18next.t('GENERAL.BUFFER_STOCK'),
    columns,
    sortedBufferStocks,
  );
};

export const exportBufferStocksXLS = (bufferStocks) => {
  const filename = i18next.t('ADMIN.STORES.EXPORT_BUFFER_STOCKS_LIST_FILENAME');

  const mainSheet = createMainSheet(bufferStocks);

  utilsXLS.makeXLS(filename, [mainSheet]);
};
