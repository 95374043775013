import { connect } from 'react-redux';
import { get, isEmpty, isEqual, omit, uniqBy } from 'lodash';
import i18next from 'i18next';
import React, { createRef, useEffect, useState } from 'react';

import {
  changeSuppliersStatus,
  getSuppliersOfClient,
  receiveSuppliers,
  receiveSuppliersStatus,
  requestSuppliersError,
  requestSuppliersStatusError,
} from '@actions/supplier';
import {
  closeGenericModal,
  openGenericModal,
  openMediumModal,
  refreshGenericModal,
} from '@actions/modal';
import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { Button, ListView } from '@commons/utils/styledLibraryComponents';
import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_SAVE_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { getConfirmationModal } from '@commons/Modals/ConfirmationModal';
import { handleOpenedImportFileModal } from '@commons/Modals/ImportModal/utils/config';
import { IMPORT_STATE, IMPORT_TYPE, SWITCH_STEP } from '@commons/Modals/ImportModal/utils/constant';
import { InpulseLabel } from '@commons/DeepsightComponents';
import { sortArrayOfObjectsAlphabetically } from '@commons/utils/sorting';
import { STANDARD_PRICE } from '@commons/ItemListSorter';
import GeneralEmptyStateListView from '@commons/GeneralEmptyStateListView';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';
import utilsXLS from '@commons/utils/makeXLS';

import { ENUM_QUERY_PARAMS, withListViewQueryParamHookHOC } from '@hooks/useListViewQueryParams';

import { canCreateSuppliers } from '@selectors/actions/supplierActions';
import { getAuthorizedActions } from '@selectors/featureProps';
import { getClientInfo } from '@selectors/client';

import { supplierPriceInputsService } from '@services/supplierPriceInputs';
import { supplierProduct as supplierProductService } from '@services/supplierProduct';
import { supplierProfile as supplierProfilesService } from '@services/supplierProfile';
import { supplier as supplierService } from '@services/supplier';

import theme from '@theme';

import { downloadFile, formatXlsFileToJson } from '@backoffice/utils';

import { CHOICES_DROPDOWN_ACTIVE } from '@admin/utils/DropdownItems';
import {
  getMultiPriceConfirmModalParams,
  getMultiPriceModalParams,
} from '@admin/commons/modalConfigurations';
import DeepsightFiltersButton from '@admin/components/FilterButton';

import './Suppliers.css';
import { formatSupplierProfilesCreationFile } from './utils/formatSupplierProfilesCreationFile';
import { getActions, getRowActions, isUserMapped } from './utils/actions';
import { getColumns } from './utils/columns';
import EmptyState from './components/EmptyState';
import SupplierModal from './SupplierModal/SupplierModal';

import { Container } from './styledComponents';

const ENUM_CUSTOM_FILTERS = {
  STATUS: 'active-inactive',
  CATALOGS_LIST: 'catalogs-list',
};

export const Suppliers = (props) => {
  const {
    stores,
    changeSuppliersStatus,
    user,
    listViewQueryParams,
    setListViewQueryParams,
    authorizedActions,
    client: { clientId, storeName, hasLocalCatalogs },
    modal,
    openModal,
    openGenericModal,
    refreshGenericModal,
    closeGenericModal,
    pageLoading,
    pageLoaded,
    showSuccessMessage,
    showErrorMessage,
    history,
    match: { path },
  } = props;

  const listViewRef = createRef();

  const [suppliers, setSuppliers] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [filteredSuppliersList, setFilteredSuppliersList] = useState([]);
  const [catalogs, setCatalogs] = useState([]);
  const [selectedCatalogs, setSelectedCatalogs] = useState([]);
  const [selectedSupplierId, setSelectedSupplierId] = useState(null);
  const [multiPrices, setMultiPrices] = useState({});
  const [existingPriceInputIds, setExistingPriceInputIds] = useState([]);
  const [disableSaveMultiPrices, setDisableSaveMultiPrices] = useState(true);
  const [supplierProfilesToCreate, setSupplierProfilesToCreate] = useState(null);
  const [currentStep, setCurrentStep] = useState(IMPORT_STATE.SELECT_FILE);

  const [isLoading, setIsLoading] = useState(true);

  const [actions, setActions] = useState([]);
  const [rowActions, setRowActions] = useState([]);
  const [filters, setFilters] = useState(null);
  const [advancedFilters, setAdvancedFilters] = useState(null);
  const [applyFilters, setApplyFilters] = useState(true);
  const [columns, setColumns] = useState([]);
  const [columnsFilterList, setColumnsFilterList] = useState([]);
  const [isInitializingFilters, setIsInitializingFilters] = useState(true);

  const [selectedFile, setSelectedFile] = useState(null);
  const [errorFile, setErrorFile] = useState(null);

  const markerConfiguration = {
    isHidden: ({ isKitchen }) => !isKitchen,
    backgroundColor: theme.colors.brand.secondary,
    icon: { src: '/images/inpulse/central-black-small.svg' },
  };

  const vueTitle = i18next.t('ADMIN.SUPPLIERS.EXPORT_SHEETNAME');
  const vueExportName = i18next.t('ADMIN.SUPPLIERS.EXPORT_FILENAME');

  useEffect(() => {
    reloadSuppliers();
    initializeFiltersFromQueryParams();
    updateListActions();
    updateRowActionsList();
  }, []);

  useEffect(() => {
    reloadSuppliers();
  }, [clientId, isInitializingFilters]);

  useEffect(() => {
    updateListActions();
    updateRowActionsList();
  }, [suppliers, selectedSuppliers]);

  useEffect(() => {
    processAdvancedFilter(suppliers);
  }, [advancedFilters, applyFilters, selectedCatalogs]);

  useEffect(() => {
    if (catalogs.length && user.catalog) {
      setSelectedCatalogs([user.catalog]);
    }
  }, [catalogs]);

  useEffect(() => {
    setColumns(
      getColumns(
        getClientStoreNameTranslation(storeName, true),
        hasLocalCatalogs,
        renderStoreLabel,
      ),
    );
  }, [storeName, hasLocalCatalogs]);

  useEffect(() => {
    if (!multiPrices) {
      return;
    }

    setDisableSaveMultiPrices(false);

    const params = getMultiPriceModalParams(
      multiPrices,
      existingPriceInputIds,
      handlePriceInputsChange,
      handlePriceInputsSave,
      false,
      resetMultiPriceStates,
    );

    refreshGenericModal(params);
  }, [multiPrices]);

  useEffect(() => {
    if (!selectedFile) {
      return;
    }

    handleOpenedImportFileModal(
      {
        currentStep,
        importType: IMPORT_TYPE.SUPPLIER_PROFILE,
        handleSwitchStep,
        handleFileChange,
        selectedFile,
        exportErrorData: errorFile,
        cleanImportModal: cleanUpModalImport,
      },
      openGenericModal,
      refreshGenericModal,
      get(modal, 'GenericModalBool', false),
    );
  }, [selectedFile, currentStep]);

  const handleSupplierEdit = (supplier) => {
    history.push(`/admin/suppliers/${supplier.id}/details`);
  };

  const updateColumnsFilterList = (suppliers) => {
    const nbStoresColumn = columns.find(({ propertyKey }) => propertyKey === 'nbStores');

    const categoryColumn = columns.find(({ propertyKey }) => propertyKey === 'category');

    const categories = suppliers.reduce((result, supplier, key) => {
      if (supplier.category) {
        result.push({
          id: key,
          name: supplier.category,
          category: supplier.category,
          status: supplier.category,
          value: supplier.category,
        });
      }

      return result;
    }, []);

    const filteredCategory = categories.filter(({ name }) => !!name);

    if (!suppliers.length || !filteredCategory.length) {
      setColumnsFilterList([nbStoresColumn]);

      return;
    }

    const uniqCategory = uniqBy(filteredCategory, ({ name }) => name);

    categoryColumn.list = sortArrayOfObjectsAlphabetically(uniqCategory, 'name');

    setColumnsFilterList([categoryColumn, nbStoresColumn]);

    return;
  };

  const handleNewSupplierModal = () => {
    const params = {
      component: SupplierModal,
      operationType: 'supplier',
      history,
      stores,
      user,
    };

    openModal(params);
  };

  const handleSupplierChangeModalBatch = (suppliers, updateProperties = true) => {
    const updatedDataModal = suppliers.reduce((result, supplier) => {
      if (!result[supplier.value]) {
        result[supplier.value] = {
          ...omit(supplier, 'ids'),
          associatedItems: supplier.ids.map((id) => ({
            id,
          })),
        };
      }
      return result;
    }, {});

    const updatedData = { data: updatedDataModal };

    if (updateProperties) {
      updatedData.properties = Object.keys(updatedDataModal).sort();
    }

    refreshGenericModal(
      {
        data: updatedData,
        actions: [
          GENERIC_MODAL_CANCEL_BUTTON(),
          {
            ...GENERIC_MODAL_SAVE_BUTTON(),
            handleClick: () => updateCategoriesOfSuppliers(suppliers),
            isDisabled: false,
          },
        ],
      },
      ['actions', 'data.data', 'data.properties'],
    );
  };

  const resetMultiPriceStates = () => {
    setSelectedSupplierId(null);
    setMultiPrices({});
    setExistingPriceInputIds([]);
    setDisableSaveMultiPrices(true);
  };

  const updatePriceInputs = async (clientId, inputs) => {
    pageLoading();
    try {
      closeGenericModal();
      await supplierPriceInputsService.updatePriceInputsOfSupplier(
        clientId,
        selectedSupplierId,
        inputs,
      );
      showSuccessMessage(i18next.t('GENERAL.MODAL_MULTI_PRICE_UPDATE_SUCCESS'));
    } catch (error) {
      showErrorMessage(i18next.t('GENERAL.MODAL_MULTI_PRICE_UPDATE_ERROR'));
    } finally {
      resetMultiPriceStates();
      pageLoaded();
    }
  };

  const handlePriceInputsSave = async (priceInputs, existingPriceInputIds) => {
    const formattedInputs = Object.keys(priceInputs).map((inputName) =>
      get(priceInputs[inputName], 'id', null)
        ? { name: inputName, id: priceInputs[inputName].id }
        : { name: inputName },
    );

    let atLeastOneInputDeleted = false;
    existingPriceInputIds.forEach((existingId) => {
      if (!formattedInputs.find((priceInput) => priceInput.id === existingId)) {
        atLeastOneInputDeleted = true;
      }
    });

    if (atLeastOneInputDeleted) {
      const warningActionModalParams = getMultiPriceConfirmModalParams(
        clientId,
        formattedInputs,
        updatePriceInputs,
        resetMultiPriceStates,
      );
      refreshGenericModal(warningActionModalParams);
      return;
    }
    updatePriceInputs(clientId, formattedInputs);
  };

  const handlePriceInputsChange = async (existingPriceInputIds, newPriceEntries) => {
    const updatedInputs = newPriceEntries.reduce((inputsResults, inputEntry) => {
      inputsResults[inputEntry.value] = {
        ...omit(inputEntry, 'ids'),
        associatedItems: inputEntry.ids.map((id) => id),
      };

      if (inputEntry.originalItem.id) {
        // this means the entry was retrieved from getPriceInputsOfSupplier and not manually added
        // so we want to keep its id
        inputsResults[inputEntry.value].id = inputEntry.originalItem.id;
      }

      return inputsResults;
    }, {});

    setMultiPrices(updatedInputs);
    setExistingPriceInputIds(existingPriceInputIds);

    const params = getMultiPriceModalParams(
      updatedInputs,
      existingPriceInputIds,
      handlePriceInputsChange,
      handlePriceInputsSave,
      disableSaveMultiPrices,
      resetMultiPriceStates,
    );

    refreshGenericModal(params);
  };

  const openManageMultiPriceModal = async (supplier) => {
    const supplierId = get(supplier, 'id');

    const [{ totalCount }, { inputs }] = await Promise.all([
      supplierProductService.getWithPagination({
        clientId,
        supplierIds: [supplierId],
        search: '',
        skip: 0,
        limit: 1,
        orderBy: 'name',
        orderType: 'asc',
        queryParams: '&active=true',
        filterByUserCatalog: hasLocalCatalogs,
      }),
      supplierPriceInputsService.getPriceInputsOfSupplier(clientId, supplierId),
    ]);

    const existingPriceInputIds = [];

    const multiPriceEntries = {
      // ItemListSorter component relies on the size of the associatedItems to display a number;
      // There is no need for precise data, only a number of entries
      [STANDARD_PRICE]: { associatedItems: new Array(totalCount) },
    };
    inputs.forEach((priceInput) => {
      multiPriceEntries[priceInput.name] = {
        ...priceInput,
        associatedItems: new Array(priceInput.supplierProductsCount),
      };
      existingPriceInputIds.push(priceInput.id);
    });

    setSelectedSupplierId(supplierId);
    setMultiPrices(multiPriceEntries);
    setExistingPriceInputIds(existingPriceInputIds);

    const params = getMultiPriceModalParams(
      multiPriceEntries,
      existingPriceInputIds,
      handlePriceInputsChange,
      handlePriceInputsSave,
      disableSaveMultiPrices,
      resetMultiPriceStates,
    );

    openGenericModal(params);
  };

  const updateCategoriesOfSuppliers = async (suppliersToUpdate) => {
    pageLoading();

    if (isEmpty(suppliersToUpdate)) {
      return;
    }

    try {
      await supplierService.updateSupplierPropertyByBatch(suppliersToUpdate);

      await reloadSuppliers();

      showSuccessMessage(i18next.t('GENERAL.MODAL_CATEGORIES_UPDATE_SUCCESS'));
    } catch (e) {
      showErrorMessage(i18next.t('GENERAL.MODAL_CATEGORIES_UPDATE_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const downloadSupplierProfilesTemplate = async () => {
    pageLoading();

    try {
      const template = await supplierProfilesService.getBatchCreationTemplate(clientId);

      downloadFile(template, i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_CREATE_BATCH_FILENAME'));

      showSuccessMessage(i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_DOWNLOAD_TEMPLATE_SUCCESS'));
    } catch (error) {
      showErrorMessage(i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_DOWNLOAD_TEMPLATE_FAIL'));
    } finally {
      pageLoaded();
    }
  };

  const cleanUpModalImport = () => {
    setSelectedFile(null);
    setErrorFile(null);
    setCurrentStep(IMPORT_STATE.SELECT_FILE);
    setSupplierProfilesToCreate(null);

    closeGenericModal();
  };

  const handleFileValidation = async () => {
    await postBatchSupplierProfilesToValidate();
  };

  const postBatchSupplierProfilesToValidate = async () => {
    pageLoading();

    try {
      const supplierProfiles = await formatXlsFileToJson(selectedFile);

      const formattedSupplierProfile = formatSupplierProfilesCreationFile(supplierProfiles);

      setSupplierProfilesToCreate(formattedSupplierProfile);

      await supplierProfilesService.postBatchSupplierProfilesToValidate(
        clientId,
        formattedSupplierProfile,
      );

      setCurrentStep(IMPORT_STATE.VALIDATED_FILE);
    } catch (error) {
      if (!!error.response && error.response.status === 422) {
        setErrorFile(error.response.data);
        setCurrentStep(IMPORT_STATE.ERROR_FILE);
      } else {
        cleanUpModalImport();
        showErrorMessage(i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_VALIDATION_FAIL'));
      }
    } finally {
      pageLoaded();
    }
  };

  const postBatchSupplierProfilesToCreate = async () => {
    pageLoading();

    try {
      await supplierProfilesService.postBatchSupplierProfileImport(
        clientId,
        supplierProfilesToCreate,
      );

      await reloadSuppliers();

      showSuccessMessage(i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_SUCCESS_BATCH_CREATION'));
    } catch (e) {
      showErrorMessage(i18next.t('ADMIN.SUPPLIERS.SUPPLIER_PROFILE_SUCCESS_BATCH_FAIL'));
    } finally {
      cleanUpModalImport();
      pageLoaded();
    }
  };

  const handleSwitchStep = (changeStep) => {
    if (changeStep === SWITCH_STEP.PREVIOUS) {
      cleanUpModalImport();
      return;
    }

    switch (currentStep) {
      case IMPORT_STATE.SELECT_FILE:
        handleFileValidation();
        break;
      case IMPORT_STATE.ERROR_FILE:
        cleanUpModalImport();
        break;
      case IMPORT_STATE.VALIDATED_FILE:
        postBatchSupplierProfilesToCreate();
        break;
      default:
        cleanUpModalImport();
        break;
    }
  };

  const handleFileChange = (file) => {
    setSelectedFile(file);

    openSupplierProfilesImportModal(file);
  };

  const openSupplierProfilesImportModal = (file) => {
    const supplierProfileCreationFile =
      (!!file || file == null) && currentStep === IMPORT_STATE.SELECT_FILE ? file : selectedFile;

    handleOpenedImportFileModal(
      {
        currentStep,
        importType: IMPORT_TYPE.SUPPLIER_PROFILE,
        handleSwitchStep,
        handleFileChange,
        selectedFile: supplierProfileCreationFile,
        exportErrorData: errorFile,
        cleanImportModal: cleanUpModalImport,
      },
      openGenericModal,
      refreshGenericModal,
      get(modal, 'GenericModalBool', false),
    );
  };

  const updateListActions = () => {
    const actions = getActions({
      user,
      suppliers,
      selectedItems: selectedSuppliers,
      renderExport,
      handleNewSupplierModal,
      handleSupplierChangeModalBatch,
      openGenericModal,
      openManageMultiPriceModal,
      downloadSupplierProfilesTemplate,
      openSupplierProfilesImportModal,
      authorizedActions,
      hasLocalCatalogs,
    });

    setActions(actions);
  };

  const updateRowActionsList = () => {
    const rowActions = getRowActions({
      user,
      suppliers,
      renderExport,
      handleDisableSupplier: handleSupplierStatusModal,
      handleSupplierChangeModalBatch,
      openGenericModal,
      openManageMultiPriceModal,
      authorizedActions,
      hasLocalCatalogs,
    });

    setRowActions(rowActions);
  };

  const initializeFiltersFromQueryParams = () => {
    const filters = listViewQueryParams[ENUM_QUERY_PARAMS.FILTERS];

    const defaultFilters = {
      [ENUM_CUSTOM_FILTERS.STATUS]: [CHOICES_DROPDOWN_ACTIVE()[0]],
    };

    try {
      const areFiltersValid = filters[ENUM_CUSTOM_FILTERS.STATUS].every((filter) =>
        CHOICES_DROPDOWN_ACTIVE().some((item) => isEqual(item, filter)),
      );

      if (!areFiltersValid) {
        throw new Error('Filters not valid');
      }
    } catch (err) {
      setListViewQueryParams[ENUM_QUERY_PARAMS.FILTERS](defaultFilters);
    } finally {
      setIsInitializingFilters(false);
    }
  };

  const getStatusFiltersFromQueryParams = () => {
    const filters = listViewQueryParams[ENUM_QUERY_PARAMS.FILTERS];

    if (!filters) {
      return [CHOICES_DROPDOWN_ACTIVE()[0]];
    }

    return filters[ENUM_CUSTOM_FILTERS.STATUS];
  };

  const reloadSuppliers = async () => {
    if (isInitializingFilters) {
      return;
    }

    setIsLoading(true);

    try {
      const result = await supplierService.getSuppliersOfClient(true, true);

      preProcessSuppliers(result);
    } catch (err) {
      showErrorMessage(i18next.t('ADMIN.SUPPLIERS.FETCH_FAILURE'));

      preProcessSuppliers([]);
    } finally {
      setTimeout(() => setIsLoading(false), 200);
    }
  };

  const processAdvancedFilter = (suppliers) => {
    if (!applyFilters) {
      return;
    }

    const selectedStatusFilters = getStatusFiltersFromQueryParams();

    const filteredSuppliersList = suppliers.filter(
      (supplier) =>
        selectedStatusFilters.some((key) => key.filterValue === supplier.active) &&
        (!hasLocalCatalogs || selectedCatalogs.some(({ id }) => id === supplier.catalogId)),
    );

    if ((!advancedFilters || !advancedFilters.length) && applyFilters) {
      setFilteredSuppliersList(filteredSuppliersList);
      return;
    }

    const filteredSuppliersListWithAdvancedFilters = advancedFilters.reduce(
      (result, { doFilter, list, filterType, propertyKey, value }) => {
        if (filterType === 'string' && value && value.length === 1 && value[0].id === 'none') {
          return doFilter(result, propertyKey, list, true);
        }

        return doFilter(result, propertyKey, value);
      },
      filteredSuppliersList,
    );

    setFilteredSuppliersList(filteredSuppliersListWithAdvancedFilters);
  };

  const preProcessSuppliers = (suppliers) => {
    const formattedSuppliers = suppliers.map((supplier) => ({
      ...supplier,
      status: supplier.active ? 'Actif' : 'Inactif',
      city: supplier.city ? supplier.city : i18next.t('GENERAL.SELECT_NONE_FEMININE'),
      category: supplier.isKitchen
        ? i18next.t('GENERAL.CENTRAL')
        : supplier.category || i18next.t('GENERAL.SELECT_NONE_FEMININE'),
      nbStores: supplier.storesMapped.length || 0,
      nbSupplierProducts: get(supplier, 'nbAssociatedSupplierProducts', 0),
      nbSupplierProfiles: get(supplier, 'associatedSupplierProfiles', []).length,
      isNotSelectable: supplier.isKitchen ? !isUserMapped([supplier], user) : false,
      catalogName: get(supplier, 'catalog.name'),
    }));

    setSuppliers(formattedSuppliers);

    if (hasLocalCatalogs) {
      const linkedCatalogs = formattedSuppliers.reduce((acc, { catalog }) => {
        if (catalog) {
          acc[catalog.id] = catalog;
        }

        return acc;
      }, {});

      const formattedCatalogs = Object.values(linkedCatalogs);

      if (!isEqual(catalogs, formattedCatalogs)) {
        setCatalogs(formattedCatalogs);
      }
    }

    updateColumnsFilterList(formattedSuppliers);
    processAdvancedFilter(formattedSuppliers);
  };

  const handleForActiveDropdown = (items) => {
    setListViewQueryParams[ENUM_QUERY_PARAMS.FILTERS]({
      ...listViewQueryParams[ENUM_QUERY_PARAMS.FILTERS],
      [ENUM_CUSTOM_FILTERS.STATUS]: items,
    });
  };

  const renderStoreLabel = (item) => (
    <div
      className="supplier-label-render"
      style={{ minWidth: '115px', marginLeft: '2px', padding: '5px 0px' }}
    >
      <InpulseLabel color={item === 0 ? 'red' : 'green'} text={item.toString()} />
    </div>
  );

  const renderExport = (supplier) => {
    const formattedColumns = [
      ...columns,
      {
        propertyKey: 'active',
        name: i18next.t('ORDERS.ORDERS.LIST_LABEL_STATUS'),
      },
    ];

    const data = (supplier || (selectedSuppliers.length ? selectedSuppliers : suppliers)).map(
      (item) => ({
        ...item,
        active: item.active ? 'Actif' : 'Inactif',
        catalog: hasLocalCatalogs ? item.catalog.name : null,
      }),
    );

    const sheet = utilsXLS.generateDefaultSheet(vueExportName, formattedColumns, data);

    utilsXLS.makeXLS(vueTitle, [sheet]);
  };

  /*********** HANDLING SUPPLIERS STATUS ***********/

  const updateSuppliersStatus = async (supplier, active) => {
    pageLoading();

    try {
      await changeSuppliersStatus([supplier.id], active);

      await reloadSuppliers();

      showSuccessMessage(
        i18next.t(
          active
            ? 'ADMIN.SUPPLIERS.SUPPLIER_ENABLE_SUCCESSFULLY'
            : 'ADMIN.SUPPLIERS.SUPPLIER_DISABLE_SUCCESSFULLY',
        ),
      );
    } catch {
      showErrorMessage(
        i18next.t(
          active
            ? 'ADMIN.SUPPLIERS.SUPPLIER_ENABLE_ERROR'
            : 'ADMIN.SUPPLIERS.SUPPLIER_DISABLE_ERROR',
        ),
      );
    } finally {
      pageLoaded();
    }
  };

  const handleSupplierStatusModal = (supplier) => {
    const warningModalConfig = getConfirmationModal({
      title: i18next.t(
        supplier.active
          ? 'ADMIN.SUPPLIERS.SUPPLIER_DISABLE_MODAL_TITLE'
          : 'ADMIN.SUPPLIERS.SUPPLIER_ENABLE_MODAL_TITLE',
      ),
      content: i18next.t(
        supplier.active
          ? 'ADMIN.SUPPLIERS.SUPPLIER_DISABLE_MODAL_CONTENT'
          : 'ADMIN.SUPPLIERS.SUPPLIER_ENABLE_MODAL_CONTENT',
      ),
      icon: '/images/inpulse/power-white-small.svg',
      actions: [
        GENERIC_MODAL_CANCEL_BUTTON(),
        {
          key: 1,
          color: 'inpulse-default',
          label: i18next.t(supplier.active ? 'GENERAL.DEACTIVATE' : 'GENERAL.ACTIVATE'),
          icon: '/images/inpulse/power-white-small.svg',
          handleClick: () => updateSuppliersStatus(supplier, !supplier.active),
        },
      ],
    });

    openGenericModal(warningModalConfig);
  };

  const getCustomMultipleDropdowns = () => {
    const filters = [
      {
        id: ENUM_CUSTOM_FILTERS.STATUS,
        icon: '/images/icon-dropdown-grey.svg',
        list: CHOICES_DROPDOWN_ACTIVE(),
        defaultSelectedItems: getStatusFiltersFromQueryParams(),
        selectedItems: getStatusFiltersFromQueryParams(),
        setSelectedItems: handleForActiveDropdown,
      },
    ];

    return filters;
  };

  if (hasLocalCatalogs && isEmpty(user.catalog)) {
    return (
      <>
        <NavigationBreadCrumb featurePath={path} />
        <Container>
          <GeneralEmptyStateListView
            icon={'/images/inpulse/production-planning.svg'}
            subtitle={i18next.t('ADMIN.SUPPLIER_PRODUCTS.NO_CATALOGS_EMPTY_STATE_SUBTITLE')}
            title={i18next.t('ADMIN.SUPPLIER_PRODUCTS.NO_CATALOGS_EMPTY_STATE_TITLE')}
          />
        </Container>
      </>
    );
  }

  if (!suppliers.length && !isLoading) {
    return (
      <>
        <NavigationBreadCrumb featurePath={path} />
        <GeneralEmptyStateListView
          icon={'/images/inpulse/supplier.svg'}
          renderAction={() =>
            canCreateSuppliers(authorizedActions) ? (
              <Button
                color={'inpulse-default'}
                handleClick={() => handleNewSupplierModal()}
                icon={'/images/inpulse/add-white-small.svg'}
                label={i18next.t('ADMIN.SUPPLIERS.EMPTY_STATE_ACTION')}
              />
            ) : null
          }
          subtitle={i18next.t('ADMIN.SUPPLIERS.EMPTY_STATE_SUBTITLE')}
          title={i18next.t('ADMIN.SUPPLIERS.EMPTY_STATE_TITLE')}
        />
      </>
    );
  }

  const userLanguageCode = get(user, 'lnkLanguageAccountrel.code', 'fr');

  return (
    <>
      <NavigationBreadCrumb featurePath={path} />
      <div className={'list-container'}>
        <Container>
          <ListView
            actionOnClick={(supplier) =>
              !supplier.isKitchen || isUserMapped([supplier], user)
                ? handleSupplierEdit(supplier)
                : null
            }
            actions={actions}
            columns={columns}
            data={filteredSuppliersList}
            defaultCurrentPage={listViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE]}
            defaultMaxPerPage={listViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE]}
            defaultOrderBy={listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY]}
            defaultOrderType={listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE]}
            defaultSearchInput={listViewQueryParams[ENUM_QUERY_PARAMS.SEARCH]}
            handleCurrentPageChange={(input) =>
              setListViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE](input)
            }
            handleMaxPerPageChange={(input) =>
              setListViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE](input)
            }
            handleOrderByChange={(input) =>
              setListViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY](input)
            }
            handleOrderTypeChange={(input) =>
              setListViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE](input)
            }
            handleSearchInputChange={(input) =>
              setListViewQueryParams[ENUM_QUERY_PARAMS.SEARCH](input)
            }
            isLoading={isLoading}
            languageCode={userLanguageCode}
            markerConfiguration={markerConfiguration}
            padding={'24px 24px 0px 24px'}
            placeholderShape={i18next.t('GENERAL.SEARCH')}
            ref={listViewRef}
            renderEmptyState={() => <EmptyState />}
            renderFilterButton={() => (
              <DeepsightFiltersButton
                advancedFilters={advancedFilters}
                applyFilters={applyFilters}
                catalogs={catalogs}
                columnsFilterList={columnsFilterList}
                customMultipleDropDowns={getCustomMultipleDropdowns()}
                filters={filters}
                isLoading={isLoading}
                minWidth={120}
                readOnly={isLoading}
                selectedCatalogs={selectedCatalogs}
                setAdvancedFilters={setAdvancedFilters}
                setApplyFilters={setApplyFilters}
                setFilters={setFilters}
                setSelectedCatalogs={setSelectedCatalogs}
                textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
              />
            )}
            rowActions={rowActions}
            setSelectedItems={setSelectedSuppliers}
          />
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  modal: state.modalReducer,
  message: state.confirmationMessageReducer.messageBool,
  stores: state.baseReducer.stores,
  user: state.baseReducer.user,
  userRights: state.baseReducer.userRights,
  suppliersCount: state.baseReducer.suppliersCount,
  suppliers: state.baseReducer.suppliers,
  client: getClientInfo(state.baseReducer.user),
  authorizedActions: getAuthorizedActions(
    state.baseReducer.userRights,
    '/admin/suppliers/suppliers',
  ),
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (params) => {
    dispatch(openMediumModal(params));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params, customPropertyPathsToUpdate) => {
    dispatch(refreshGenericModal(params, customPropertyPathsToUpdate));
  },
  closeGenericModal: (params) => {
    dispatch(closeGenericModal(params));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  getSuppliersOfClient: (withLinkedData, filterByUserCatalog) =>
    dispatch(getSuppliersOfClient(withLinkedData, filterByUserCatalog)).then(
      (result) => {
        dispatch(receiveSuppliers(result));
      },
      (error) => {
        dispatch(requestSuppliersError(error));
      },
    ),
  changeSuppliersStatus: (supplierIds, active) =>
    dispatch(changeSuppliersStatus(supplierIds, active)).then(
      (result) => dispatch(receiveSuppliersStatus(result)),
      (error) => {
        dispatch(requestSuppliersStatusError(error));
        throw 'Error';
      },
    ),
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withListViewQueryParamHookHOC(Suppliers));
