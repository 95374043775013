import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;
`;

export const TitleContainer = styled.div`
  margin-bottom: 8px;
  display: flex;

  gap: 4px;

  font: ${(props) => props.theme.fonts.textBigHeight16};
  color: ${(props) => props.theme.colors.greys.darkest};
`;

export const Asterisk = styled.div`
  margin-right: 2px;
  color: ${(props) => props.theme.colors.infoRed};
`;

export const TooltipContainer = styled.div`
  display: flex;
  align-items: center;
  > div {
    display: flex;
    justify-content: center;
    height: 12px;
  }
`;

export const TagsInputContainer = styled.div`
  min-height: 40px;
  width: ${(props) => (!!props.width ? props.width : 'calc((100% / 3) * 2 - (48px / 2))')};

  .react-tagsinput {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    height: 100%;
    width: 100%;
    padding: 8px 16px;

    cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'text')};
    background-color: ${(props) =>
      props.isDisabled ? props.theme.colors.greys.light : props.theme.colors.greys.lightest};

    border: 1px solid ${(props) => props.theme.colors.greys.light};
    border-radius: 8px;

    overflow: hidden;
  }

  .react-tagsinput > span {
    display: flex;

    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    gap: 8px;
  }

  .react-tagsinput-tag {
    display: flex;

    align-items: center;
    justify-content: flex-start;

    height: 24px;

    margin: 0;
    padding: 0px 8px;

    background-color: ${(props) =>
      props.isDisabled ? props.theme.colors.greys.medium : props.theme.colors.greys.lighter};
    border: 1px solid ${(props) => props.theme.colors.greys.light};

    border-radius: 4px;

    color: ${(props) => props.theme.colors.greys.darkest};
    font: ${(props) => props.theme.fonts.textSmallHeight16};
  }

  .react-tagsinput-custom {
    display: flex;

    align-items: center;
    justify-content: flex-start;

    height: 24px;

    background-color: transparent;
    border: none;

    border-radius: 4px;

    color: ${(props) => props.theme.colors.greys.darkest};
    font: ${(props) => props.theme.fonts.textSmallHeight16};
    cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'text')};

    // Make sure the placeholder is not cut if message is too long
    width: 160px;
  }

  .react-tagsinput-remove {
    cursor: pointer;
    padding-left: 8px;
  }

  .react-tagsinput-tag a::before {
    color: ${(props) => props.theme.colors.greys.darkest};
    text-decoration: none;
    font-size: 17px;
    content: ' ×';
  }

  .react-tagsinput-tag a:hover {
    color: ${(props) => props.theme.colors.greys.darkest};
    text-decoration: none;
    content: ' ×';
  }

  .react-tagsinput-tag a:active {
    color: ${(props) => props.theme.colors.greys.darkest};
    text-decoration: none;
  }
  .react-tagsinput-tag a:visited {
    color: ${(props) => props.theme.colors.greys.darkest};
    text-decoration: none;
  }
`;

export default {
  Container,
  TitleContainer,
  TagsInputContainer,
};
