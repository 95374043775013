import i18next from 'i18next';
import React from 'react';

import { ENUM_ROLES_NAME } from '@commons/utils/roles';
import { InpulseLabel } from '@commons/DeepsightComponents';

import { ACCOUNT_STATUS } from '@backoffice/BackOfficeUsersContainer/BackOfficeTeamsManagement/utils/status';

import { LabelContainer } from './styledComponents';
import { renderStatus } from '../../utils/columns';
import { Text } from '@admin/stores/components/styledComponents';

export const getColumns = (roles, storeName, hasCentralKitchens = false) => {
  const columns = [
    {
      id: 'name',
      propertyKey: 'name',
      name: i18next.t('USERS.DETAILS.PROFILE_LASTNAME_INPUT_LABEL'),
      render: (item) => <Text highlighted>{item}</Text>,
    },
    {
      id: 'email',
      propertyKey: 'email',
      name: i18next.t('USERS.DETAILS.PROFILE_EMAIL_INPUT_LABEL'),
      displayBigger: true,
    },
    {
      id: 'role',
      propertyKey: 'role',
      excludeFromSearch: true,
      name: i18next.t('USERS.DETAILS.PROFILE_ROLE_INPUT_LABEL'),
      list: Object.values(
        roles.reduce((result, role, key) => {
          if (role.name === 'inpulse') {
            return result;
          }

          if (role.name && !result[role.name] && role.name !== ENUM_ROLES_NAME.API_CONSUMER) {
            result[role.name] = {
              id: key,
              name: role.name,
              status: role.name,
              value: role.name,
              role: role.name,
            };
          }

          return result;
        }, {}),
      ),
      render: (item) => <Text>{item}</Text>,
    },
    {
      id: 'storesCount',
      propertyKey: 'storesCount',
      name: storeName,
      excludeFromSearch: true,
      render: (item) => (
        <LabelContainer>
          <InpulseLabel text={String(item)} />
        </LabelContainer>
      ),
    },
    ...(hasCentralKitchens
      ? [
          {
            id: 'kitchensCount',
            propertyKey: 'kitchensCount',
            name: i18next.t('GENERAL.CENTRALS'),
            excludeFromSearch: true,
            render: (item) => (
              <LabelContainer>
                <InpulseLabel text={String(item)} />
              </LabelContainer>
            ),
          },
        ]
      : []),
    {
      id: 'status',
      propertyKey: 'status',
      name: i18next.t('ORDERS.ORDERS.LIST_LABEL_STATUS'),
      excludeFromSearch: true,
      tooltipText: i18next.t('BACKOFFICE.TEAM.STATUS_COLUMN_TOOLTIP'),
      filterType: 'string',
      list: Object.values(ACCOUNT_STATUS).reduce((result, status, key) => {
        result.push({
          id: key,
          name: i18next.t(status),
          status: status,
          itemValue: status,
        });

        return result;
      }, []),
      render: (status, row) => renderStatus(status, row),
    },
  ];

  return columns;
};

export default { getColumns };
