import { sortBy } from 'lodash';
import i18next from 'i18next';

import utilsXLS from '@commons/utils/makeXLS';

const createMainSheet = (bufferStocks, storeName, hasCentralKitchens) => {
  const hasGroups = bufferStocks.some(({ groups }) => groups && groups.length);

  const columns = [
    {
      propertyKey: 'name',
      name: storeName,
    },
    {
      propertyKey: 'nbDays',
      name: i18next.t('GENERAL.BUFFER_STOCK'),
    },
  ];

  if (hasCentralKitchens) {
    columns.splice(1, 0, {
      propertyKey: 'isKitchen',
      name: i18next.t('GENERAL.STORE_TYPE'),
      transform: (isKitchen) =>
        isKitchen ? i18next.t('GENERAL.CENTRAL') : i18next.t('GENERAL.SALE_POINT'),
    });
  }

  if (hasGroups) {
    columns.splice(hasCentralKitchens ? 2 : 1, 0, {
      propertyKey: 'groups',
      name: i18next.t('GENERAL.GROUPS'),
      transform: (groups) => groups.map(({ name }) => name).join(', '),
    });
  }

  const sortedBufferStocks = sortBy(bufferStocks, 'ingredientName');

  return utilsXLS.generateDefaultSheet(
    i18next.t('GENERAL.BUFFER_STOCK'),
    columns,
    sortedBufferStocks,
  );
};

export const exportBufferStocksXLS = (bufferStocks, storeName, hasCentralKitchens) => {
  const filename = i18next.t('ADMIN.STORES.EXPORT_BUFFER_STOCKS_LIST_FILENAME');

  const mainSheet = createMainSheet(bufferStocks, storeName, hasCentralKitchens);

  utilsXLS.makeXLS(filename, [mainSheet]);
};
