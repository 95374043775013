import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;

  margin-top: 104px;

  width: 100%;
  height: calc(100% - 104px);

  overflow: auto;

  padding: 24px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;

  height: 40px;
`;

export const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  gap: 8px;

  :first-child {
    margin-right: 8px;
  }
`;

export const TableBorder = styled.div`
  width: 100%;
  height: 100%;

  border-radius: 8px;
  border: solid 1px ${(props) => props.theme.colors.greys.medium};

  background-color: ${(props) => props.theme.colors.greys.lightest};
`;

export const EmptyDivContainer = styled.div`
  display: flex;
  width: 100%;
  height: 48px;
`;

export const EmptyDiv = styled.div`
  min-width: 50%;
  min-height: 100%;
`;
