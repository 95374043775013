import brandService from '@services/brand';

import { ITEM_DROPDOWN_NONE_VALUE } from '@admin/utils/DropdownItems';

export const receiveBrandsOfClient = (brands) => {
  if (brands && brands.length > 0) {
    return {
      type: 'CLIENT_BRANDS_SUCCESS',
      brands: [ITEM_DROPDOWN_NONE_VALUE(), ...brands],
    };
  }

  return {
    type: 'CLIENT_BRANDS_SUCCESS',
    brands: [],
  };
};

export const requestBrandsOfClientError = () => ({
  type: 'CLIENT_BRANDS_FAILURE',
});

export const getBrandsOfClient = () =>
  function (dispatch) {
    dispatch({
      type: 'CLIENT_BRANDS_REQUEST',
    });
    return brandService.getBrandsOfClient();
  };
