import axios from '../core/http';

import { APIcaller, APIcallerv2 } from './APIcaller';
import { config } from '../config';

const baseApiUrl = `${config.baseApiUrl}/v1`;

const getSuppliersOfClient = (withLinkedData = true, filterByUserCatalog = false) =>
  APIcallerv2.apiGet(
    `/suppliers?withLinkedData=${withLinkedData}&filterByUserCatalog=${filterByUserCatalog}`,
  );

const createSupplier = (supplier) => APIcaller.apiPost(`/admin/suppliers`, supplier);

function getSupplierPriceInputs(supplierId) {
  return APIcallerv2.apiGet(`/suppliers/${supplierId}/inputs-price`);
}

// TODO: INP-1060 - Remove LB route
function patchSupplier(supplier) {
  return (
    axios
      // TODO - Remove LB route (14 logs)
      .patch(baseApiUrl + '/Suppliers', supplier, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      })
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        throw error;
      })
  );
}

const getSuppliersOfStore = (storeId) => APIcallerv2.apiGet(`/stores/${storeId}/suppliers`);

function getSuppliersOfStores(storeIds, { withLinkedData = true } = {}) {
  return APIcaller.apiPost(`/admin/stores/suppliers`, {
    storeIds,
    withLinkedData,
  });
}

function getSuppliersFromHistoricalOrdersMadeOnStores(storeIds) {
  return axios
    .post(`${baseApiUrl}/order/suppliers`, {
      storeIds,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

const getSuppliersCategories = () => APIcallerv2.apiGet('/suppliers/suppliers-categories');

function changeSuppliersStatus(supplierIds, active) {
  return axios
    .patch(
      baseApiUrl + '/admin/suppliers/status',
      {
        supplierIds,
        active,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export const getStoresByAccountIdAndSupplierId = (supplierId) =>
  APIcallerv2.apiGet(`/suppliers/${supplierId}/stores-by-supplier`);

const updateSupplierPropertyByBatch = (suppliers) =>
  axios
    .patch(
      `${baseApiUrl}/admin/suppliers`,
      {
        suppliersToUpdate: { updates: suppliers },
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

export const supplier = {
  createSupplier,
  patchSupplier,
  getSuppliersOfClient,
  getSupplierPriceInputs,
  getSuppliersOfStore,
  getSuppliersCategories,
  getSuppliersOfStores,
  changeSuppliersStatus,
  updateSupplierPropertyByBatch,
  getStoresByAccountIdAndSupplierId,
  getSuppliersFromHistoricalOrdersMadeOnStores,
};
