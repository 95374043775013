import { storeSupplierProfileMapping } from '../services/storeSupplierProfileMapping';

/****************  GET SSPM OF SUPPLIER  ****************/

export const receiveStoreSupplierProfileMappingsOfSupplier = (storeSupplierProfileMappings) => ({
  type: 'STORE_SUPPLIER_PROFILE_MAPPINGS_OF_SUPPLIER_SUCCESS',
  storeSupplierProfileMappings,
});

export const requestStoreSupplierProfileMappingsOfSupplierError = (error) => ({
  type: 'STORE_SUPPLIER_PROFILE_MAPPINGS_OF_SUPPLIER__FAILURE',
  error,
});

export const getStoreSupplierProfileMappingsOfSupplier = (supplierId) =>
  function (dispatch) {
    dispatch({
      type: 'STORE_SUPPLIER_PROFILE_MAPPINGS_OF_SUPPLIER_REQUEST',
    });
    return storeSupplierProfileMapping.getStoreSupplierProfileMappingsOfSupplier(supplierId);
  };

/****************  PATCH  ****************/

export const patchStoreSupplierProfileMappingByField = (
  storeSupplierProfileMappingId,
  fieldToUpdate,
) =>
  function (dispatch) {
    dispatch({
      type: 'PATCH_STORE_SUPPLIER_PROFILE_MAPPING_FIELD_BY_ID_REQUEST',
    });
    return storeSupplierProfileMapping.patchStoreSupplierProfileMappingFieldById(
      storeSupplierProfileMappingId,
      fieldToUpdate,
    );
  };

export const patchStoreSupplierProfileMappingByFieldSuccess = (success) => ({
  type: 'PATCH_STORE_SUPPLIER_PROFILE_MAPPING_FIELD_BY_ID_SUCCESS',
  success,
});

export const patchStoreSupplierProfileMappingByFieldError = (error) => ({
  type: 'PATCH_STORE_SUPPLIER_PROFILE_MAPPING_FIELD_BY_ID_FAILURE',
  error,
});

/****************  DELETE  ****************/

export const deleteStoreSupplierProfileMappingsSuccess = (success) => ({
  type: 'DELETE_STORE_SUPPLIER_PROFILE_MAPPINGS_SUCCESS',
  success,
});

export const deleteStoreSupplierProfileMappingsError = (error) => ({
  type: 'DELETE_STORE_SUPPLIER_PROFILE_MAPPINGS_FAILURE',
  error,
});

export const deleteStoreSupplierProfileMappings = (storeSupplierProfileMappingIds) =>
  function (dispatch) {
    dispatch({
      type: 'DELETE_STORE_SUPPLIER_PROFILE_MAPPINGS_REQUEST',
    });
    return storeSupplierProfileMapping.deleteStoreSupplierProfileMappings(
      storeSupplierProfileMappingIds,
    );
  };
