import { connect } from 'react-redux';
import { isEqual, omit } from 'lodash';
import { useForm, useWatch } from 'react-hook-form';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { buildSchema } from '@commons/GenericForm';
import { Button, HoverTooltip, ToggleButton } from '@commons/utils/styledLibraryComponents';
import { getTheme } from '@commons/utils/theme';
import { LeftRightSplitter } from '@commons/LeftRightSplitter';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';
import WhiteCardForm from '@commons/WhiteCardForm';

import { yupResolver } from '@hookform/resolvers/yup';

import { TextField } from '@mui/material';

import invoiceService from '@services/invoice';

import {
  Container,
  ContentContainer,
  HeaderContainer,
  HeaderLeftContainer,
  CustomPromptContainer,
  ContentHeaderButtonContainer,
} from './styledComponents';
import { DisabledTooltip } from '@commons/DisabledTooltip';
import { getCustomFieldPrompt } from './utils/constants';
import utilsForm from './utils/form';

const InvoiceControlCustomPrompt = (props) => {
  const {
    supplierId,
    // redux states
    showErrorMessage,
    showSuccessMessage,
    pageLoading,
    pageLoaded,
  } = props;

  const theme = getTheme();

  const MODES = [
    {
      key: 'form',
      name: 'Formulaire',
    },
    {
      key: 'prompt',
      name: 'Prompt',
    },
  ];

  const [selectedMode, setSelectedMode] = useState(MODES[0]);
  const [fullPrompt, setFullPrompt] = useState(getCustomFieldPrompt());
  const [isFullPromptUpdated, setIsFullPromptUpdated] = useState(false);
  const [fetchedCustomPrompt, setFetchedCustomPrompt] = useState(null);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const inputs = utilsForm.getInputs({
    isDisabled: isEqual(selectedMode, MODES[1]),
  });

  const form = useForm({
    defaultValues: {},
    resolver: yupResolver(buildSchema(inputs)),
  });

  const formFields = useWatch({
    control: form.control,
  });

  useEffect(() => {
    (async () => getSupplierCustomPrompt())();
  }, []);

  useEffect(() => {
    if (!fetchedCustomPrompt) {
      return;
    }

    form.reset({
      invoiceNumber: fetchedCustomPrompt.invoiceNumber,
      excludingTaxesInvoiceTotal: fetchedCustomPrompt.excludingTaxesInvoiceTotal,
      invoiceDate: fetchedCustomPrompt.invoiceDate,
      deliveryDate: fetchedCustomPrompt.deliveryDate,
      orderReference: fetchedCustomPrompt.orderReference,
      deliveryReference: fetchedCustomPrompt.deliveryReference,
      code: fetchedCustomPrompt.code,
      name: fetchedCustomPrompt.name,
      quantity: fetchedCustomPrompt.quantity,
      unitPrice: fetchedCustomPrompt.unitPrice,
      packaging: fetchedCustomPrompt.packaging,
    });

    if (fetchedCustomPrompt.fullPrompt) {
      setIsFullPromptUpdated(true);
      setFullPrompt(JSON.parse(fetchedCustomPrompt.fullPrompt));
      setSelectedMode(MODES[1]);
    }
  }, [fetchedCustomPrompt]);

  useEffect(() => {
    const fullPromptFromCustomField = getCustomFieldPrompt(formFields);

    setIsFullPromptUpdated(!isEqual(fullPrompt, fullPromptFromCustomField));
  }, [fullPrompt]);

  useEffect(() => {
    if (isFullPromptUpdated) {
      return;
    }

    setFullPrompt(getCustomFieldPrompt(formFields));
  }, [formFields]);

  useEffect(() => {
    const fetchedFields = omit(fetchedCustomPrompt, ['id', 'supplierId', 'fullPrompt']);

    const formattedFormFields = Object.entries(formFields).reduce((acc, [key, value]) => {
      if (!(value || '').trim()) {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});

    setIsSaveDisabled(
      isEqual(fetchedFields, formattedFormFields) &&
        isEqual(
          fetchedCustomPrompt && fetchedCustomPrompt.fullPrompt
            ? JSON.parse(fetchedCustomPrompt.fullPrompt)
            : getCustomFieldPrompt(formFields),
          fullPrompt,
        ),
    );
  }, [isFullPromptUpdated, formFields]);

  const getSupplierCustomPrompt = async () => {
    pageLoading();

    try {
      const customPrompt = await invoiceService.getSupplierCustomPrompt(supplierId);

      if (!customPrompt) {
        return;
      }

      setFetchedCustomPrompt(customPrompt);
    } catch {
      showErrorMessage(i18next.t('ADMIN.SUPPLIERS.CUSTOM_PROMPT_FETCH_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const handleSelectedModeChange = (mode) => {
    if (isEqual(mode, selectedMode)) {
      return;
    }

    if (isFullPromptUpdated) {
      setSelectedMode(MODES[1]);
      return;
    }

    setSelectedMode(mode);
  };

  const resetPromptToFetchData = () => {
    setFullPrompt(JSON.parse(fetchedCustomPrompt.fullPrompt));
  };

  const resetPromptToCustomFields = () => {
    setFullPrompt(getCustomFieldPrompt(formFields));
  };

  const resetCustomFieldsToFetchData = () => {
    if (!fetchedCustomPrompt) {
      resetCustomFieldsToEmpty();
      return;
    }

    form.reset({
      invoiceNumber: fetchedCustomPrompt.invoiceNumber,
      excludingTaxesInvoiceTotal: fetchedCustomPrompt.excludingTaxesInvoiceTotal,
      invoiceDate: fetchedCustomPrompt.invoiceDate,
      deliveryDate: fetchedCustomPrompt.deliveryDate,
      orderReference: fetchedCustomPrompt.orderReference,
      deliveryReference: fetchedCustomPrompt.deliveryReference,
      code: fetchedCustomPrompt.code,
      name: fetchedCustomPrompt.name,
      quantity: fetchedCustomPrompt.quantity,
      unitPrice: fetchedCustomPrompt.unitPrice,
      packaging: fetchedCustomPrompt.packaging,
    });
  };

  const resetCustomFieldsToEmpty = () => {
    form.reset({
      invoiceNumber: '',
      excludingTaxesInvoiceTotal: '',
      invoiceDate: '',
      deliveryDate: '',
      orderReference: '',
      deliveryReference: '',
      code: '',
      name: '',
      quantity: '',
      unitPrice: '',
      packaging: '',
    });
  };

  const handleSave = async () => {
    pageLoading();

    const formattedFields = Object.entries(formFields).reduce((acc, [key, value]) => {
      if (!(value || '').trim()) {
        acc[key] = null;
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});

    try {
      await invoiceService.upsertSupplierCustomPrompt(supplierId, {
        ...formattedFields,
        fullPrompt:
          isFullPromptUpdated && (fullPrompt || '').trim() ? JSON.stringify(fullPrompt) : null,
      });

      await getSupplierCustomPrompt();

      setIsSaveDisabled(true);
      showSuccessMessage(i18next.t('ADMIN.SUPPLIERS.CUSTOM_PROMPT_SAVE_SUCCESS'));
    } catch {
      showErrorMessage(i18next.t('ADMIN.SUPPLIERS.CUSTOM_PROMPT_SAVE_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  return (
    <Container>
      <HeaderContainer>
        <LeftRightSplitter
          left={
            <HeaderLeftContainer>
              <HoverTooltip
                isTooltipDisplayed={isFullPromptUpdated}
                renderContent={() => (
                  <DisabledTooltip
                    text={i18next.t('ADMIN.SUPPLIERS.CUSTOM_PROMPT_TOGGLE_DISABLED_TOOLTIP')}
                  />
                )}
                style={{ width: '240px' }}
              >
                <ToggleButton
                  choices={MODES}
                  isDisabled={isFullPromptUpdated}
                  selectedChoice={selectedMode}
                  setSelectedChoice={handleSelectedModeChange}
                />
              </HoverTooltip>
            </HeaderLeftContainer>
          }
          right={
            <Button
              color="inpulse-default"
              handleClick={handleSave}
              icon={'/images/inpulse/save-white-small.svg'}
              isDisabled={isSaveDisabled}
              label={i18next.t('GENERAL.SAVE')}
            />
          }
          width={'100%'}
        />
      </HeaderContainer>
      <ContentContainer>
        <WhiteCardForm
          customStyle={{
            width: '50%',
            overflow: 'auto',
            backgroundColor: isEqual(selectedMode, MODES[0])
              ? theme.colors.greys.lightest
              : theme.colors.greys.lighter,
          }}
          form={form}
          inputs={inputs}
          isEditionAllowed={isEqual(selectedMode, MODES[0])}
          title={
            <LeftRightSplitter
              left={
                <Text color={ENUM_COLORS.DARKEST} font={ENUM_FONTS.H2_INTER}>
                  {i18next.t('ADMIN.SUPPLIERS.CUSTOM_PROMPT_LEFT_TITLE')}
                </Text>
              }
              right={
                <ContentHeaderButtonContainer>
                  {fetchedCustomPrompt && (
                    <Button
                      color="inpulse-outline"
                      handleClick={resetCustomFieldsToFetchData}
                      icon={'/images/inpulse/sync-black-small.svg'}
                      isDisabled={isEqual(selectedMode, MODES[1])}
                    />
                  )}
                  <Button
                    color="inpulse-outline"
                    handleClick={resetCustomFieldsToEmpty}
                    icon={'/images/inpulse/delete-black-small.svg'}
                    isDisabled={isEqual(selectedMode, MODES[1])}
                  />
                </ContentHeaderButtonContainer>
              }
              width={'100%'}
            />
          }
        />
        <CustomPromptContainer
          style={{
            backgroundColor: isEqual(selectedMode, MODES[0])
              ? theme.colors.greys.lighter
              : theme.colors.greys.lightest,
          }}
        >
          <LeftRightSplitter
            left={
              <Text color={ENUM_COLORS.DARKEST} font={ENUM_FONTS.H2_INTER}>
                {i18next.t('ADMIN.SUPPLIERS.CUSTOM_PROMPT_RIGHT_TITLE')}
              </Text>
            }
            right={
              <ContentHeaderButtonContainer>
                {fetchedCustomPrompt && (
                  <Button
                    color="inpulse-outline"
                    handleClick={resetPromptToFetchData}
                    icon={'/images/inpulse/sync-black-small.svg'}
                    isDisabled={isEqual(selectedMode, MODES[0])}
                  />
                )}
                <Button
                  color="inpulse-outline"
                  handleClick={resetPromptToCustomFields}
                  icon={'/images/inpulse/delete-black-small.svg'}
                  isDisabled={isEqual(selectedMode, MODES[0]) || !isFullPromptUpdated}
                />
              </ContentHeaderButtonContainer>
            }
            width={'100%'}
          />

          <TextField
            autoComplete="off"
            disabled={isEqual(selectedMode, MODES[0])}
            sx={{
              width: '100%',
              height: '100%',
            }}
            value={fullPrompt}
            multiline
            onChange={(event) => {
              const value = event.target.value;

              setFullPrompt(value);
            }}
          />
        </CustomPromptContainer>
      </ContentContainer>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(null, mapDispatchToProps)(InvoiceControlCustomPrompt);
