import 'moment/locale/fr';
import { cloneDeep, get } from 'lodash';
import { connect } from 'react-redux';
import i18next from 'i18next';
import moment from 'moment';
import React, { Component } from 'react';

import {
  getSupplierProfilesOfSupplier,
  receiveSupplierProfilesOfSupplier,
  requestSupplierProfilesOfSupplierError,
} from '@actions/supplierProfile';
import {
  getSuppliersCategories,
  receiveSuppliersCategories,
  requestSuppliersCategoriesError,
} from '@actions/supplier';
import { openGenericModal } from '@actions/modal';
import { showConfirmationMessage, showErrorMessage } from '@actions/messageconfirmation';

import { ENUM_MODULE_NAME } from '@commons/utils/features';
import { getUserTimezone } from '@commons/utils/date';
import { isForbiddenAPIError } from '@commons/utils/errors';
import NavigationBar from '@commons/NavigationBar';
import normalizeStringValue from '@commons/utils/normalizeStringValue';

import { getClientInfo } from '@selectors/client';

import sortSupplierProductProperties from '@admin/utils/sortSupplierProductProperties';

import { getNewCategorySubcategoryModalParams } from '@admin/products/products/components/CreateProductModal/utils/modalConfigurations';

import AssociatedStores from './components/AssociatedStores';
import InvoiceControlCustomPrompt from './InvoiceControlCustomPrompt';
import SupplierDetail from './components/SupplierDetail';
import SupplierProfile from './components/SupplierProfile';

moment.locale('fr');

const PATHS_AND_PAGES = {
  '/admin/suppliers/:id/details': 'details',
  '/admin/suppliers/:id/supplier-profiles': 'supplierProfiles',
  '/admin/suppliers/:id/associated-stores': 'associated-stores',
  '/admin/suppliers/:id/custom-prompt': 'custom-prompt',
};

export class SupplierDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      supplier: {},
      inputValue: '',
      openModalAddNewItemDropdown: false,
      suppliersCategories: [],
      picture: null,
      selectedPictureFile: null,
    };
  }

  getSelectedPageFromPath(path) {
    if (!path || typeof path !== 'string') {
      return null;
    }

    const storeDetailPaths = Object.keys(PATHS_AND_PAGES);

    const matchingPath = storeDetailPaths.find((item) => path === item);

    if (!matchingPath) {
      return null;
    }

    return PATHS_AND_PAGES[matchingPath];
  }

  handleSupplierChange = (supplier) => {
    if (!supplier) {
      return;
    }

    this.setState({
      supplier: { ...this.state.supplier, ...supplier, category: get(supplier, 'category.name') },
    });
  };

  handleInputChange = (value) => {
    this.setState({ inputValue: value });

    if (!value) {
      this.setState({ errorMessage: i18next.t('GENERAL.REQUIRED_FILED_ERROR_MESSAGE') });

      return;
    }

    const alreadyExists = this.props.suppliersCategories.some(
      (name) => normalizeStringValue(name) === normalizeStringValue(value),
    );

    if (alreadyExists) {
      this.setState({ errorMessage: i18next.t('GENERAL.MODAL_CATEGORY_NAME_ALREADY_USED') });

      return;
    }

    this.setState({ errorMessage: null });
  };

  closeCleanUp = () => {
    this.setState({ errorMessage: null });
    this.setState({ inputValue: '' });

    this.setState({ openModalAddNewItemDropdown: false });
  };

  handleSaveNewItemDropdown = () => {
    const updatedCategories = cloneDeep(this.state.suppliersCategories);

    updatedCategories.push({
      id: Math.random(),
      name: this.state.inputValue.trim(),
    });

    this.setState({
      suppliersCategories: sortSupplierProductProperties(
        updatedCategories.map((field) => field.name),
        true,
      ),
    });

    this.handleSupplierChange({
      ...this.state.supplier,
      category: { name: this.state.inputValue.trim() },
    });

    return this.closeCleanUp();
  };

  handleSetOpenModalAddNewItemDropdown = (value) => {
    this.setState({ openModalAddNewItemDropdown: value });
  };

  handlePrevPage = () => {
    this.props.history.goBack();
  };

  getUserSuppliersCategories = async () => {
    try {
      const result = await this.props.getSuppliersCategories();
      const suppliersCategories = result.suppliersCategories.map((category, index) => ({
        id: index,
        name: category,
      }));
      this.setState({
        suppliersCategories: sortSupplierProductProperties(
          suppliersCategories.map((field) => field.name),
          true,
        ),
      });
    } catch {
      this.props.showMessage(i18next.t('ADMIN.SUPPLIERS.GET_SUPPLIERS_CATEGORIES_ERROR'), 'error');
    }
  };

  componentDidMount() {
    const supplierId = this.props.match.params.id;
    this.getUserSuppliersCategories();

    if (supplierId) {
      this.setState({ supplierId: supplierId });
      this.props
        .getSupplierProfilesOfSupplier(supplierId, getUserTimezone())
        .then((result) => {
          this.setState({
            supplier: result.supplier,
            supplierProfiles: result.supplierProfiles,
          });
        })
        .catch((error) => {
          this.props.showMessage(i18next.t('ADMIN.SUPPLIERS.GET_SUPPLIER_PROFILE_ERROR'), 'error');

          if (isForbiddenAPIError(error)) {
            this.props.history.goBack();
          }
        });
    }

    const path = get(this.props, 'match.path');

    this.setState({ selectedPage: this.getSelectedPageFromPath(path) });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match !== this.props.match) {
      const path = get(this.props, 'match.path');

      this.setState({
        selectedPage: this.getSelectedPageFromPath(path),
      });
    }

    if (
      prevState.openModalAddNewItemDropdown !== this.state.openModalAddNewItemDropdown ||
      prevState.inputValue !== this.state.inputValue
    ) {
      if (this.state.openModalAddNewItemDropdown) {
        const modalCreateNewCategoryParams = getNewCategorySubcategoryModalParams(
          this.state.inputValue,
          this.state.errorMessage,
          this.closeCleanUp,
          this.handleInputChange,
          'category',
          this.handleSaveNewItemDropdown,
        );

        this.props.openGenericModal(modalCreateNewCategoryParams);
      }
    }
  }

  render() {
    let { selectedPage, supplier, supplierId, picture, selectedPictureFile } = this.state;

    const path = get(this.props, 'match.path');
    const storeName = get(this.props, 'client.storeName');

    return (
      <div style={{ width: '100%', height: '100%' }}>
        <NavigationBar
          bigTopBar={true}
          enableActionBottomOrder={true}
          module={ENUM_MODULE_NAME.SUPPLIER_DETAIL}
          path={path}
          storeName={storeName}
          supplier={supplier}
          supplierId={supplierId}
          supplierName={get(supplier, 'name')}
          user={this.props.user}
        />
        {selectedPage === 'details' && (
          <SupplierDetail
            handlePrevPage={this.handlePrevPage}
            handleSetOpenModalAddNewItemDropdown={this.handleSetOpenModalAddNewItemDropdown}
            handleSupplierChange={this.handleSupplierChange}
            picture={picture}
            selectedPictureFile={selectedPictureFile}
            setPicture={(value) => this.setState({ picture: value })}
            setSelectedPictureFile={(value) => this.setState({ selectedPictureFile: value })}
            showErrorMessage={this.props.showErrorMessage}
            showMessage={this.props.showMessage}
            supplier={this.state.supplier}
            supplierId={supplierId}
            suppliersCategories={this.state.suppliersCategories}
          />
        )}
        {selectedPage === 'supplierProfiles' && (
          <SupplierProfile
            history={this.props.history}
            showMessage={this.props.showMessage}
            supplierId={supplierId}
          />
        )}
        {selectedPage === 'associated-stores' && (
          <AssociatedStores supplier={supplier} supplierId={supplierId} />
        )}
        {selectedPage === 'custom-prompt' && <InvoiceControlCustomPrompt supplierId={supplierId} />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  client: getClientInfo(state.baseReducer.user),
  suppliersCategories: state.baseReducer.suppliersCategories,
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  getSuppliersCategories: () =>
    dispatch(getSuppliersCategories()).then(
      (result) => dispatch(receiveSuppliersCategories(result)),
      (error) => dispatch(requestSuppliersCategoriesError(error)),
    ),
  getSupplierProfilesOfSupplier: (supplierId, userTimezone) =>
    dispatch(getSupplierProfilesOfSupplier(supplierId, userTimezone))
      .then((result) => {
        dispatch(receiveSupplierProfilesOfSupplier(result));
        return result;
      })
      .catch((error) => {
        dispatch(requestSupplierProfilesOfSupplierError(error));

        throw error;
      }),
});

export default SupplierDetails = connect(mapStateToProps, mapDispatchToProps)(SupplierDetails);
