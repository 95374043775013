import axios from '../core/http';

import { config } from '../config';

const baseApiUrl = `${config.baseApiUrl}/v1`;

export const createIntercomContact = (clientId, userId) =>
  axios
    .post(`${baseApiUrl}/intercom/clients/${clientId}/users/${userId}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
