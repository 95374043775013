import moment from 'moment';

import { APIcaller, APIcallerv2 } from './APIcaller';

import { config } from '../config';
import axios from '../core/http';

import { DATE_DISPLAY_FORMATS } from '../commons/DatePickers/constants';

const baseApiUrl = `${config.baseApiUrl}/v1`;

export const getById = async (storeId) => APIcaller.apiGet(`/admin/stores/${storeId}`);

export const getEventsOfStore = (storeId, startDate, endDate) => {
  const params = {
    startDate: moment(startDate).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
  };

  if (endDate) {
    params.endDate = moment(endDate).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);
  }

  return APIcaller.apiGet(`/admin/stores/${storeId}/events`, params);
};

export const getEventSubCategories = (storeId) =>
  APIcaller.apiGet(`/admin/stores/${storeId}/event-sub-categories`);

export const getStoresOfClient = () => APIcallerv2.apiGet('/stores');

export const getAccountsOfStore = (storeId) =>
  axios
    .get(`${baseApiUrl}/admin/stores/${storeId}/accounts`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response;
    });

export const patchStore = (storeId, fields) =>
  APIcaller.apiPatch(`/admin/stores/${storeId}/`, { fields });

export const getProductionProductsOfStore = (storeId) =>
  APIcaller.apiGet(`/admin/stores/${storeId}/products`);

export const updateStoresInBatch = (storeIds, fields) =>
  APIcaller.apiPatch('/admin/stores', { storeIds, fields: { ...fields } });

export const getLastInventoriesByEntityId = (storeId, entityId, date) =>
  APIcaller.apiGet(`/admin/stores/${storeId}/ingredients/${entityId}/inventories?date=${date}`);

export const getStoresByAccountIdAndSupplierId = (supplierId, { withGroups = false } = {}) =>
  APIcaller.apiPost(`/admin/stores/stores-with-supplier-profile`, {
    supplierId,
    withGroups,
  });

export const getStoreTransactions = (storeId, startDate, endDate = null) =>
  APIcaller.apiGet(
    `/admin/stores/${storeId}/transactions?startDate=${startDate}${
      endDate ? `&endDate=${endDate}` : ''
    }`,
  );

export const deleteStoreProductMappingsByProductIds = (storeId, productIds) =>
  APIcaller.apiDelete(`/admin/stores/${storeId}/products`, { productIds });

export const updateStoreProductMappingsByProductIds = (storeId, productIds, fields) =>
  APIcaller.apiPatch(`/admin/stores/${storeId}/products`, { productIds, fields });

export const getAvailableProducts = (storeId) =>
  APIcaller.apiGet(`/admin/stores/${storeId}/available-products`);

export const createStoreProductMappings = (storeId, productIds) =>
  APIcaller.apiPost(`/admin/stores/${storeId}/products`, { productIds });

export const getNotAssociatedSupplierProducts = (storeId) =>
  APIcaller.apiGet(`/admin/stores/${storeId}/not-associated-supplier-products`);

export const getStoreGeocode = (address, postalCode, country) =>
  APIcaller.apiPost(`/backoffice/stores/geocode`, {
    address,
    postalCode,
    country,
  });

export const checkStoreInvoiceInformation = (storeId) =>
  APIcaller.apiGet(`/admin/stores/${storeId}/check-store-invoice-information`);

export const getStoreBufferStocks = (storeId) =>
  APIcallerv2.apiGet(`/stores/${storeId}/buffer-stocks`);

export const upsertBufferStocks = (storeId, entityIds, nbDays) =>
  APIcaller.apiPost(`/admin/stores/${storeId}/buffer-stocks`, { entityIds, nbDays });

export default {
  getById,
  getStoresOfClient,
  patchStore,
  getAccountsOfStore,
  getProductionProductsOfStore,
  getEventsOfStore,
  getEventSubCategories,
  updateStoresInBatch,
  getLastInventoriesByEntityId,
  getStoresByAccountIdAndSupplierId,
  getStoreTransactions,
  deleteStoreProductMappingsByProductIds,
  updateStoreProductMappingsByProductIds,
  getAvailableProducts,
  createStoreProductMappings,
  getNotAssociatedSupplierProducts,
  getStoreGeocode,
  checkStoreInvoiceInformation,
  getStoreBufferStocks,
  upsertBufferStocks,
};
