import { config } from '../config';
import axios from 'axios';
import i18next from 'i18next';

const baseApiUrl = `${config.baseApiUrl}/v1`;

export const getProductionRealTime = async (storeIds, currentDate, showMessage, productIds) =>
  axios
    .post(`${baseApiUrl}/production/real-time-analytics`, {
      storeIds,
      currentDate,
      productIds,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then((result) => result.data)
    .catch(() => {
      showMessage(i18next.t('PRODUCTION.PRODUCTION.TOP_ERROR_MESSAGE_PRODUCTION'), 'error');

      return [];
    });

export default getProductionRealTime;
