import { connect } from 'react-redux';
import _ from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useState, useRef } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { openConfirmationModal } from '@actions/confirmationmodal';
import { openMediumModal } from '@actions/modal';
import { showConfirmationMessage } from '@actions/messageconfirmation';

import { DeepsightConfirmationModal } from '@commons/DeepsightComponents';
import { ENUM_MODULE_NAME } from '@commons/utils/features';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { ListView } from '@commons/utils/styledLibraryComponents';
import { sortArrayOfObjectsAlphabetically } from '@commons/utils/sorting';
import { STANDARD_LISTVIEW_PADDING } from '@commons/constants/listViewProps';
import NavigationBar from '@commons/NavigationBar';
import utilsXLS from '@commons/utils/makeXLS';

import { ENUM_QUERY_PARAMS, useListViewQueryParams } from '@hooks/useListViewQueryParams';

import { getClientInfo } from '@selectors/client';

import { user as userService } from '@services/user';

import EmptyState from '@backoffice/BackOfficeStoresContainer/BackOfficeStoresManagement/components/EmptyState';

import DeepsightFiltersButton from '@orders/components/FilterButton';

import { Container, Content } from './styledComponents';
import { getActions, getRowActions } from './utils/getActions';
import { getColumns } from './utils/formatColumns';
import StoreAssociationModal from './components/StoreAssociationModal';

/**
 * Display popup modal to let the user confirm the decision to delete the associated between
 * a user and one or several stores
 *
 * @param {String} selectedStores - The list of selected store that should be dissociated
 * @param {Props} props           - The props linked to the component
 *
 * @returns {void}
 */
export function confirmDissociation(selectedStores, refreshList, props) {
  const { profileUser, storeName } = props;

  const titleModal = i18next.t('USERS.DETAILS.STORES_DELETE_MODAL_CONFIRMATION_TITLE_STORE', {
    count: selectedStores.length,
  });
  let textModal = '';

  if (selectedStores.length === 1) {
    textModal = i18next.t('USERS.DETAILS.STORES_DELETE_MODAL_CONFIRMATION_CONTENT_SINGLE_STORE', {
      email: profileUser.email,
      name: selectedStores[0].name,
    });
  } else {
    textModal = i18next.t(
      'USERS.DETAILS.STORES_DELETE_MODAL_CONFIRMATION_CONTENT_MULTIPLE_STORES',
      {
        email: profileUser.email,
        number: selectedStores.length,
        storeName: getClientStoreNameTranslation(storeName, true).toLowerCase(),
      },
    );
  }

  const params = {
    component: DeepsightConfirmationModal,
    props,
    colorsheme: 'blue',
    eventId: selectedStores,
    handleEventDeleteConfirmation: () =>
      deleteStoresAssociation(selectedStores, refreshList, props),
    title: titleModal,
    text: textModal,
    styleTitle: { fontFamily: 'proximanovaregular' },
    deactivateBackgroundClick: true,
  };
  props.openConfirmationModal(params);
}

/**
 * Dissociated a set of stores to the consulted user
 *
 * @param {String} selectedAccounts - The list of selected accounts that should be deleted
 * @param {Props} props             - The props linked to the component
 *
 * @returns {void}
 */
export async function deleteStoresAssociation(selectedStores, refreshList, props) {
  const { storeName, showMessage, pageLoading, pageLoaded, profileUser } = props;

  const storeIds = selectedStores.map((store) => store.id);

  try {
    pageLoading();

    await userService.dissociateStoresToAccount(profileUser.id, storeIds);

    await refreshList();

    if (storeIds.length > 1) {
      showMessage(
        i18next.t('USERS.DETAILS.STORES_DELETE_MULTIPLE_STORES_SUCCESS', {
          storeName: getClientStoreNameTranslation(storeName, true).toLowerCase(),
        }),
      );
    } else {
      showMessage(i18next.t('USERS.DETAILS.STORES_DELETE_SINGLE_STORE_SUCCESS'));
    }
  } catch (err) {
    showMessage(i18next.t('USERS.DETAILS.STORES_DELETE_FAILURE'), 'error');
  } finally {
    pageLoaded();
  }
}

/**
 * Handle the fetch of the profile information from the given account id
 *
 * @param {String} accountId      - The id of the account information to retrieve
 * @param {Function} showMessage  - The method to display message relating to the request status
 *
 * @returns {ProfileUser} The formatted profile user information
 */
export async function fetchProfileInfo(accountId, showMessage) {
  try {
    const profileUser = await userService.getAccountById(accountId);

    const formattedProfile = {
      ..._.pick(profileUser, ['id', 'firstName', 'lastName', 'email']),
    };

    return formattedProfile;
  } catch (error) {
    showMessage(i18next.t('USERS.DETAILS.FETCH_PROFILE_INFO_ERROR'), 'error');

    return {};
  }
}

/**
 * Handle the fetch of the stores associated to the given account id
 *
 * @param {String} accountId            - The id of the account on which retrieve associated stores
 * @param {Function} showMessage        - The method to display message relating to the request status
 * @param {String} translatedStoreName  - The translated store name label associated to the user client
 *
 * @returns {Store[]} The formatted list of stores associated to the account id
 */
export async function fetchStoresOfAccount(accountId, showMessage, translatedStoreName) {
  try {
    const stores = await userService.getStoresOfAccount(accountId);

    const formattedStores = stores.map((store) => ({
      ..._.pick(store, ['id', 'name', 'city', 'country', 'active', 'groups']),
      brand: _.get(store, 'lnkBrandStorerel.name'),
      groupNames:
        !store.groups || !store.groups.length
          ? [i18next.t('GENERAL.SELECT_NONE_MASCULINE')]
          : store.groups.map(({ name }) => name),
    }));

    return _.orderBy(formattedStores, 'name');
  } catch (error) {
    showMessage(
      i18next.t('USERS.DETAILS.STORES_LIST_FETCH_FAILURE', {
        storeName: translatedStoreName,
      }),
      'error',
    );

    return [];
  }
}

/**
 * Render the content of the component
 *
 * @param {Props} props - The props linked to the component
 *
 * @returns {void}
 */

export const ProfileDetailStores = (props) => {
  const {
    user,
    client: { storeName, hasMultipleBrands },
    openModal,
    match,
    showMessage,
    pageLoaded,
    pageLoading,
    match: { params },
  } = props;

  const listViewRef = useRef(null);

  const path = _.get(match, 'path');

  const EMPTY_STATE_USER = { lastName: '', firstName: '', email: '' };

  const [stores, setStores] = useState([]);
  const [filteredStoresList, setFilteredStoresList] = useState([]);
  const [profileUser, setProfileUser] = useState(EMPTY_STATE_USER);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState(null);
  const [applyFilters, setApplyFilters] = useState(true);
  const [advancedFilters, setAdvancedFilters] = useState(null);
  const [actions, setActions] = useState([]);
  const [rowActions, setRowActions] = useState([]);
  const [selectedStoresList, setSelectedStoresList] = useState([]);
  const [columns, setColumns] = useState([]);

  const [activeKeyForActiveDropdown, setActiveKeyForActiveDropdown] = useState({
    id: 1,
    name: i18next.t('GENERAL.ACTIVES'),
    itemValue: true,
  });

  const [listViewQueryParams, setListViewQueryParams] = useListViewQueryParams();

  const choicesForActiveDropdown = [
    { id: 1, name: i18next.t('GENERAL.ACTIVES'), itemValue: true },
    { id: 2, name: i18next.t('GENERAL.INACTIVES'), itemValue: false },
  ];

  const removeAssociation = {
    storeName,
    profileUser: profileUser,
    showMessage: showMessage,
    pageLoading: pageLoading,
    pageLoaded: pageLoaded,
    filteredStoresList: filteredStoresList,
    setFilteredStoresList: setFilteredStoresList,
    openConfirmationModal: props.openConfirmationModal,
  };

  const userLanguageCode = _.get(user, 'lnkLanguageAccountrel.code', 'fr');

  const fetchList = async () => {
    const storesOfProfile = await fetchStoresOfAccount(
      params.id,
      showMessage,
      getClientStoreNameTranslation(storeName, true).toLowerCase(),
    );

    setStores(storesOfProfile);
    setFilteredStoresList(
      storesOfProfile.filter(({ active }) => activeKeyForActiveDropdown.itemValue === active),
    );
  };

  const handleExport = async (storesToExport) => {
    const currentStoresToExport = storesToExport.length
      ? storesToExport
      : listViewRef.current.getFilteredData();

    const storeNamePlural = getClientStoreNameTranslation(storeName, true);

    const sheet = utilsXLS.generateDefaultSheet(storeNamePlural, columns, currentStoresToExport);

    utilsXLS.makeXLS(storeNamePlural, [sheet]);
  };

  useEffect(() => {
    setColumns(getColumns(stores, hasMultipleBrands));
  }, [stores]);

  useEffect(() => {
    pageLoading();
    setIsLoading(true);

    (async function loadData() {
      const accountDetail = await fetchProfileInfo(params.id, showMessage);
      setProfileUser(accountDetail);

      await fetchList();

      pageLoaded();
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (advancedFilters && advancedFilters.length > 0) {
      advancedFilters[0].list = sortArrayOfObjectsAlphabetically(advancedFilters[0].list, 'name');
    }

    if (!applyFilters) {
      return;
    }

    const filteredStores = stores.filter(
      ({ active }) => activeKeyForActiveDropdown.itemValue === active,
    );

    if (!advancedFilters || !advancedFilters.length) {
      setFilteredStoresList(filteredStores);
      return;
    }

    const filteredStoreWithAdvancedFilters = advancedFilters.reduce(
      (result, { doFilter, list, filterType, propertyKey, value }) => {
        // Tricks to allow filter on groups since groups is an array of object and groupNames an array of string
        // And advanced filter only allows filter on array of strings
        const formattedPropertyKey = propertyKey === 'groups' ? 'groupNames' : propertyKey;

        if (filterType === 'string' && value && value.length === 1 && value[0].id === 'none') {
          return doFilter(result, formattedPropertyKey, list, true);
        }

        return doFilter(result, formattedPropertyKey, value);
      },
      filteredStores,
    );

    setFilteredStoresList(filteredStoreWithAdvancedFilters);
  }, [stores, applyFilters, advancedFilters]);

  useEffect(() => {
    const params = {
      component: StoreAssociationModal,
      storesUser: stores,
      accountId: profileUser.id,
      headers: columns,
      setStores,
    };

    const updatedActions = getActions(
      selectedStoresList,
      handleExport,
      confirmDissociation,
      fetchList,
      removeAssociation,
      openModal,
      params,
    );

    setActions(updatedActions);

    setRowActions(getRowActions(confirmDissociation, fetchList, removeAssociation));
  }, [stores, selectedStoresList]);

  return (
    <Container className="extra-margin">
      <NavigationBar
        {...props}
        module={ENUM_MODULE_NAME.USER}
        path={path}
        profileUser={profileUser}
        storeName={storeName}
      />
      <Content>
        <ListView
          actions={actions}
          columns={columns}
          data={filteredStoresList}
          defaultCurrentPage={listViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE]}
          defaultMaxPerPage={listViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE]}
          defaultOrderBy={listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY]}
          defaultOrderType={listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE]}
          defaultSearchInput={listViewQueryParams[ENUM_QUERY_PARAMS.SEARCH]}
          handleCurrentPageChange={(input) =>
            setListViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE](input)
          }
          handleMaxPerPageChange={(input) =>
            setListViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE](input)
          }
          handleOrderByChange={(input) => setListViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY](input)}
          handleOrderTypeChange={(input) =>
            setListViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE](input)
          }
          handleSearchInputChange={(input) =>
            setListViewQueryParams[ENUM_QUERY_PARAMS.SEARCH](input)
          }
          isLoading={isLoading}
          languageCode={userLanguageCode}
          padding={STANDARD_LISTVIEW_PADDING}
          placeholderShape={i18next.t('GENERAL.SEARCH')}
          ref={listViewRef}
          renderEmptyState={() => <EmptyState />}
          renderFilterButton={() => (
            <DeepsightFiltersButton
              advancedFilters={advancedFilters}
              applyFilters={applyFilters}
              columnsFilterList={columns.filter((column) => !!column.filterType)}
              customSingleDropDowns={[
                {
                  id: 'active-inactive-stores',
                  itemSelectedIcon: '/images/inpulse/power-ip-black.svg',
                  list: choicesForActiveDropdown,
                  defaultSelectedItem: choicesForActiveDropdown[0],
                  selectedItem: activeKeyForActiveDropdown,
                  setSelectedItem: (selectedItem) => setActiveKeyForActiveDropdown(selectedItem),
                },
              ]}
              filters={filters}
              isLoading={isLoading}
              minWidth={120}
              readOnly={isLoading}
              setAdvancedFilters={(it) => setAdvancedFilters(it)}
              setApplyFilters={(it) => setApplyFilters(it)}
              setFilters={(it) => setFilters(it)}
              textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
            />
          )}
          rowActions={rowActions}
          setSelectedItems={(items) => setSelectedStoresList(items)}
        />
      </Content>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (params) => {
    dispatch(openMediumModal(params));
  },
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  openConfirmationModal: (params) => {
    dispatch(openConfirmationModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetailStores);
