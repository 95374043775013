import { keyBy } from 'lodash';
import { useState, useEffect, useContext } from 'react';

import { FiltersContext } from '@context/FiltersContext';

import { getProductsByClientId } from '../services';

export const ENUM_PRODUCTS_FILTERS_NAME = {
  BRANDS: 'custom-brands-products',
  PRODUCTS: 'custom-products',
  CATEGORIES: 'custom-categories',
  SUB_CATEGORIES: 'custom-sub-categories',
};

const filterProductsWithSelectedItems = (
  products,
  selectedBrands = [],
  selectedCategories = [],
  selectedSubCategories = [],
) => {
  const selectedBrandsKeyById = keyBy(selectedBrands, 'id');

  const categories = selectedCategories.map(({ name }) => (name || '').toLowerCase());
  const subCategories = selectedSubCategories.map(({ name }) => (name || '').toLowerCase());

  return products.filter(({ brandId, category, subCategory }) => {
    let brandIdMatches = true; // default true as selected brands could be empty
    let categoryMatches = true; // default true as selected categories could be empty
    let subCategoryMatches = true; // default true as selected sub-categories could be empty

    if (selectedBrands.length) {
      const value = brandId === null ? -1 : brandId;

      brandIdMatches = !!selectedBrandsKeyById[value];
    }

    if (selectedCategories.length) {
      categoryMatches = category && categories.includes(category.toLowerCase());
    }

    if (selectedSubCategories.length) {
      subCategoryMatches = subCategory && subCategories.includes(subCategory.toLowerCase());
    }

    return brandIdMatches && categoryMatches && subCategoryMatches;
  });
};

const _buildDropdownFilters = ({
  // products
  products = [],
  filteredProducts = [],
  selectedProducts = [],
  // brands
  brands = [],
  selectedBrands = [],
  originalSelectedBrands,
  // categories
  categories = [],
  selectedCategories = [],
  originalSelectedCategories,
  // sub-cataegories
  subCategories = [],
  selectedSubCategories = [],
  originalSelectedSubCategories,
  useBrandsFilter = false,
  onChangeSelection,
}) => {
  const filters = {};

  if (useBrandsFilter && brands.length) {
    filters[ENUM_PRODUCTS_FILTERS_NAME.BRANDS] = {
      id: ENUM_PRODUCTS_FILTERS_NAME.BRANDS,
      icon: !selectedBrands.length
        ? '/images/inpulse/brand-dmgrey-small.svg'
        : '/images/inpulse/brand-black-small.svg',
      list: brands,
      defaultSelectedItems: brands,
      originalSelectedItems: originalSelectedBrands || selectedBrands,
      selectedItems: selectedBrands,
      setSelectedItems: (...parameters) =>
        onChangeSelection(ENUM_PRODUCTS_FILTERS_NAME.BRANDS, ...parameters),
      forceStateUpdateTrigger: true, // force selected items call to be able to filters Products list content accordingly
    };
  }

  if (categories.length) {
    filters[ENUM_PRODUCTS_FILTERS_NAME.CATEGORIES] = {
      id: ENUM_PRODUCTS_FILTERS_NAME.CATEGORIES,
      icon: !selectedCategories.length
        ? '/images/inpulse/category-dmgrey-small.svg'
        : '/images/inpulse/category-ipblack-small.svg',
      list: categories,
      defaultSelectedItems: categories,
      originalSelectedItems: originalSelectedCategories || selectedCategories,
      selectedItems: selectedCategories,
      setSelectedItems: (...parameters) =>
        onChangeSelection(ENUM_PRODUCTS_FILTERS_NAME.CATEGORIES, ...parameters),
      forceStateUpdateTrigger: true, // force selected items call to be able to filters Products list content accordingly
    };
  }

  if (subCategories.length) {
    filters[ENUM_PRODUCTS_FILTERS_NAME.SUB_CATEGORIES] = {
      id: ENUM_PRODUCTS_FILTERS_NAME.SUB_CATEGORIES,
      icon: !selectedSubCategories.length
        ? '/images/inpulse/sub-category-dmgrey-small.svg'
        : '/images/inpulse/sub-category-ip-black-small.svg',
      list: subCategories,
      defaultSelectedItems: subCategories,
      originalSelectedItems: originalSelectedSubCategories || selectedSubCategories,
      selectedItems: selectedSubCategories,
      setSelectedItems: (...parameters) =>
        onChangeSelection(ENUM_PRODUCTS_FILTERS_NAME.SUB_CATEGORIES, ...parameters),
      forceStateUpdateTrigger: true, // force selected items call to be able to filters Products list content accordingly
    };
  }

  filters[ENUM_PRODUCTS_FILTERS_NAME.PRODUCTS] = {
    id: ENUM_PRODUCTS_FILTERS_NAME.PRODUCTS,
    icon: !selectedProducts.length
      ? '/images/inpulse/product-dmgrey-small.svg'
      : '/images/inpulse/product-ipblack-small.svg',
    list: filteredProducts,
    defaultSelectedItems: products,
    originalSelectedItems: selectedProducts,
    selectedItems: selectedProducts,
    setSelectedItems: (...parameters) =>
      onChangeSelection(ENUM_PRODUCTS_FILTERS_NAME.PRODUCTS, ...parameters),
  };

  return filters;
};

export const useProductsFilter = ({
  clientId,
  useBrandsFilter,
  showErrorMessage,
  clientBrands = [],
}) => {
  const { filtersAnalyticsProduction, setFiltersAnalyticsProduction } = useContext(FiltersContext);

  const [isLoading, setIsLoading] = useState(true); // general state to know whether products / categories / sub-categories have been loaded

  const [brands, setBrands] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [originalSelectedBrands, setOriginalSelectedBrands] = useState(null);

  const [categories, setCategories] = useState([]); // used for dropdown choices
  const [selectedCategories, setSelectedCategories] = useState([]); // selected list of categories
  const [originalSelectedCategories, setOriginalSelectedCategories] = useState(null);

  const [subCategories, setSubCategories] = useState([]); // used for dropdown choices
  const [selectedSubCategories, setSelectedSubCategories] = useState([]); // selected list of sub-categories
  const [originalSelectedSubCategories, setOriginalSelectedSubCategories] = useState(null);

  const [products, setProducts] = useState([]); // backup of all products to iterate on when filtering on matching selected cat & sub-cat
  const [filteredProducts, setFilteredProducts] = useState([]); // used for dropdown choices after filtering on matching selected cat & sub-cat
  const [selectedProducts, setSelectedProducts] = useState([]); // selected list of products

  // Handle logic when dropdown selection has changed
  const onChangeSelection = (filterType, items) => {
    if (filterType === ENUM_PRODUCTS_FILTERS_NAME.BRANDS) {
      setOriginalSelectedBrands(selectedBrands);
      setSelectedBrands(items);
    }

    if (filterType === ENUM_PRODUCTS_FILTERS_NAME.CATEGORIES) {
      setOriginalSelectedCategories(selectedCategories);
      setSelectedCategories(items);
    }

    if (filterType === ENUM_PRODUCTS_FILTERS_NAME.SUB_CATEGORIES) {
      setOriginalSelectedSubCategories(selectedSubCategories);
      setSelectedSubCategories(items);
    }

    if (filterType === ENUM_PRODUCTS_FILTERS_NAME.PRODUCTS) {
      setSelectedProducts(items);
    }
  };

  const setItemsFromFilterContext = (fetchProducts) => {
    if (!filtersAnalyticsProduction) {
      return;
    }

    const categoryFilter = filtersAnalyticsProduction[ENUM_PRODUCTS_FILTERS_NAME.CATEGORIES] || {};

    setCategories(categoryFilter.list || []);
    setSelectedCategories(categoryFilter.selectedItems || []);

    const subCategoryFilter =
      filtersAnalyticsProduction[ENUM_PRODUCTS_FILTERS_NAME.SUB_CATEGORIES] || {};

    setSubCategories(subCategoryFilter.list || []);
    setSelectedSubCategories(subCategoryFilter.selectedItems || []);

    const brandFilter = filtersAnalyticsProduction[ENUM_PRODUCTS_FILTERS_NAME.BRANDS] || {};

    setBrands(brandFilter.list || []);
    setSelectedBrands(brandFilter.selectedItems || []);

    const productFilter = filtersAnalyticsProduction[ENUM_PRODUCTS_FILTERS_NAME.PRODUCTS] || {};

    setProducts(fetchProducts);
    setSelectedProducts(productFilter.selectedItems || []);
  };

  const propsFiltersBuilder = {
    brands,
    selectedBrands,
    originalSelectedBrands,
    categories,
    selectedCategories,
    originalSelectedCategories,
    products,
    filteredProducts,
    selectedProducts,
    subCategories,
    selectedSubCategories,
    originalSelectedSubCategories,
    onChangeSelection,
    useBrandsFilter,
  };

  const filters = _buildDropdownFilters(propsFiltersBuilder);

  const isAllProductsSelected = products.length === selectedProducts.length;

  // Retrieve data brands / products / categories / sub-categories of by clientId
  useEffect(() => {
    if (!isLoading || !clientId) {
      return;
    }

    (async function loadData() {
      // Handle brands / products / categories / sub-categories fetch
      const {
        products: fetchProducts,
        categories: fetchedCategories,
        subCategories: fetchSubCategories,
      } = await getProductsByClientId(showErrorMessage);

      if (filtersAnalyticsProduction) {
        setItemsFromFilterContext(fetchProducts);

        setIsLoading(false);

        return;
      }

      setCategories(fetchedCategories);
      setSelectedCategories(fetchedCategories);

      setSubCategories(fetchSubCategories);
      setSelectedSubCategories(fetchSubCategories);

      if (useBrandsFilter) {
        setBrands(clientBrands);
        setSelectedBrands(clientBrands);
      }

      setProducts(fetchProducts);
      setSelectedProducts(fetchProducts);

      setIsLoading(false);
    })();
  }, [clientId]);

  // When selection changes on brands or categories or sub-categories, make sure to apply changes on products states
  useEffect(() => {
    if (isLoading) {
      return;
    }

    const matchingProducts = filterProductsWithSelectedItems(
      products,
      selectedBrands,
      selectedCategories,
      selectedSubCategories,
    );

    setFilteredProducts(matchingProducts);
  }, [isLoading, products, selectedBrands, selectedCategories, selectedSubCategories]);

  // Make sure to update production filters when products dropdown content comes to change
  // NOTE: necessary as filter component does not call setFilters method when customMultipleDropdowns content changes
  useEffect(() => {
    if (isLoading || !filtersAnalyticsProduction) {
      return;
    }

    const updatedFilters = {
      ...filtersAnalyticsProduction,
      ..._buildDropdownFilters({
        ...propsFiltersBuilder,
        selectedProducts: filteredProducts, // override selection
      }),
    };

    setFiltersAnalyticsProduction(updatedFilters);
  }, [filteredProducts]);

  return {
    // state to notify consumer component
    isLoading,
    // Products selection related states
    selectedProducts,
    isAllProductsSelected,
    // Filters customMultipleDropdown purpose
    filtersKeyById: filters,
  };
};
